import { ServiceInstance, adminApiUrls } from '..';

// SUPER ADMIN LOGIN
export const adminLoginReq = (payload) => {
  return ServiceInstance({
    method: 'POST',
    url: adminApiUrls.LOGIN,
    data: { ...payload }
  });
};

// SUPER ADMIN FORGOT PASSWORD
export const forgotPasswordReq = (payload) => {
  return ServiceInstance({
    method: 'POST',
    url: adminApiUrls.FORGOT_PASSWORD,
    data: { ...payload }
  });
};

// SUPER ADMIN RESET PASSWORD
export const resetPasswordReq = (payload) => {
  return ServiceInstance({
    method: 'POST',
    url: `${adminApiUrls.RESET_PASSWORD}?token=${payload.token}`,
    data: {
      new_password: payload.new_password,
      confirm_password: payload.confirm_password
    }
  });
};
