import { okrUser, routeURLConst } from './../../config/constant/index';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import authActions from '../app/action';

import {
  login,
  resetPasswordReq,
  forgotPasswordReq,
  signup,
  signupAdminReq,
  getManagerReq,
  departmentAddReq,
  getDepartmentReq,
  patchManagerInsertReq,
  checkPortalURLinRoot,
  checkValidTokenReq,
  verifyOtpReq,
  resendOtpReq,
  comapnyInfoReq,
  checkUserLimitReq
} from '../../library/services/auth';
import actions from './actions';
import { getToken } from '../../library/helpers/utility';
import history from '../../library/helpers/history';
import { PUBLIC_ROUTE, ADMIN_ROUTES } from '../../route.constants';
import { verifyAuthLogin, manualSignup } from '../../library/services/0Auth';
import { getSubDomain } from '../../library/services/subdomain';
import { getManagerPayload } from './types';
import { SubscriptionPlanTypes } from '../SubscriptionPlan/SubscriptionPlanTypes';
import { nonAdminLoginAlert, subscriptionHistoryPayload } from '../../config/constant';

const handleRedirection = (payload) => {
  const url =
    process.env.NODE_ENV === 'development' ? `http://${getSubDomain()}.localhost:3000` : `https://${payload.state}`;
  const adminurl =
    process.env.NODE_ENV === 'development'
      ? 'http://www.localhost:3000'
      : `https://www.${process.env.REACT_APP_INSTANCE_ENV}.com`;
  if (payload.executive) {
    window.location.replace(`${adminurl}${ADMIN_ROUTES.SIGN_UP_ADMIN_2_MANUAL}`);
  } else {
    if (payload.token) {
      window.location.replace(`${url}/employeeinfo?token=${payload.token}`);
    } else {
      window.location.replace(`${url}/dashboard/mygoalslistview`);
    }
  }
};

export function* signupStep1() {
  yield takeEvery(actions.SIGNUP_STEP1, function* ({ payload }: { type: string; payload: Record<string, string> }) {
    let modifiedPayload = {
      email: payload.email,
      token: payload.token
    };
    let userInfo = {
      ...payload,
      sign_in_as: 0
    };
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(signup, modifiedPayload);
      if (response.status) {
        yield put({
          type: actions.SIGNUP_STEP1_SUCCESS,
          userSignupSuccess: response.status
        });
        yield localStorage.setItem('userInfo', JSON.stringify(userInfo));
        yield sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        history.push(`${PUBLIC_ROUTE.SIGN_UP_EMPLOYEE_2_DEPT}?token=${payload.token}`);
      } else {
        yield put({
          type: actions.SIGNUP_STEP1_FAILED,
          error: response
        });
      }
    } catch (error: unknown) {
      yield put({ type: actions.SIGNUP_STEP1_FAILED, error: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* signupAdminStep1() {
  yield takeEvery(
    actions.SIGNUP_ADMIN_STEP1,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(signupAdminReq, payload);

        if (response) {
          yield put({
            type: actions.SIGNUP_ADMIN_STEP1_SUCCESS,
            token: response.data.tokenResponse.access_token,
            userData: response.data,
            userInfo: payload
          });
          localStorage.setItem('userInfo', JSON.stringify(payload));
          sessionStorage.setItem('id_token', response.data.tokenResponse.access_token);
          localStorage.setItem(
            'id_token',
            response.data.tokenResponse.access_token
          );
        } else {
          yield put({
            type: actions.SIGNUP_ADMIN_STEP2_FAILED,
            error: response
          });
        }
      } catch (error: unknown) {
        yield put({ type: actions.SIGNUP_ADMIN_STEP2_FAILED, error: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }: { type: string; payload: Record<string, string> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(login, payload);

      if (response.data && response.data.is_email_verified !== false) {
        if (
          Object.keys(response.data.subscription)?.length > 0 &&
          response.data.subscription.cancel_at !== null &&
          response.data.user.role_id !== 1
        ) {
          yield put({
            type: actions.LOGIN_ERROR,
            error: nonAdminLoginAlert
          });
        } else {
          yield all([
            put({
              type: actions.LOGIN_SUCCESS,
              token: response.data.access_token,
              subscribed: response.data.subscription
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload
            }),
            put({
              type: SubscriptionPlanTypes.GET_ALL_PLANS
            }),
            put({
              type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION
            }),
            put({
              type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION
            }),
            put({
              type: actions.PORTAL_URL_LOADING_SET
            })
          ]);
          yield JSON.stringify(localStorage.setItem('userByRoleId', response.data.user.role_id));
          
          const pathName = sessionStorage.getItem('prevPathName');
          if (
            pathName === '/' ||
            pathName === '/resetpassword' ||
            pathName === '/client/verify' ||
            pathName === '/verify/google' ||
            pathName === '/verify/microsoft' ||
            pathName === null
          ) {
            history.push(`${routeURLConst.listView}`);
          } else {
            history.push(`${pathName}`);
          }
        }
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          error: response?.data?.message || response?.message
        });
      }
    } catch (error: any) {
      yield put({ type: actions.LOGIN_ERROR, error: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* forgotPassword() {
  yield takeEvery(actions.FORGOT_PASSWORD, function* ({ payload }: { type: string; payload: Record<string, any> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(forgotPasswordReq, payload);
      //if (response.data.user.verified) {
      if (response.data) {
        yield put({
          type: actions.FORGOT_PASSWORD_SUCCESS,
          payload: response.message
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_FAILED,
          error: response?.data?.message || response?.message
        });
      }
    } catch (error: any) {
      yield put({ type: actions.FORGOT_PASSWORD_FAILED, error: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* manualSignupSaga() {
  yield takeEvery(actions.AUTH_MANUAL_SIGNUP, function* ({ payload }: Record<string, any>) {
    yield put(authActions.globalLoaderHandler(true));
    try {
      const response = yield call(manualSignup, payload);
      if (response.status === 200 || response.status === 201) {
        yield put({
          type: actions.AUTH_MANUAL_SIGNUP_SUCCESS,
          pcSuccess: response?.data?.message
        });
        history.push('/');
      } else {
        yield put({
          type: actions.AUTH_MANUAL_SIGNUP_FAILED,
          resetPwError: response?.data?.message || response?.message
        });
        history.push(`/client/verify?token=${payload.token}`);
      }
    } catch (error: any) {
      yield put({
        type: actions.AUTH_MANUAL_SIGNUP_FAILED,
        resetPwError: error?.message
      });
      history.push(`/client/verify?token=${payload.token}`);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* oAuthVerifySaga() {
  yield takeEvery(actions.OAUTH_VERIFY, function* ({ payload }: Record<string, any>) {
    yield put(authActions.globalLoaderHandler(true));
    try {
      const response = yield call(verifyAuthLogin, payload);
      if (response.status === 200 || response.status === 201) {
        // Employee signup
        if (payload.token) {
          let userInfo = {
            first_name: response?.data?.data?.user?.firstName,
            last_name: response?.data?.data?.user?.lastName,
            email: response?.data?.data?.user?.email,
            password: response?.data?.data?.user?.firstName + response?.data?.data?.user?.lastName + 'Aa@123',
            confirm_password: response?.data?.data?.user?.firstName + response?.data?.data?.user?.lastName + 'Aa@123',
            picture: response?.data?.data?.user?.picture,
            token: payload.token,
            sign_in_as: 1
          };
          yield localStorage.setItem('userInfo', JSON.stringify(userInfo));
          yield sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
          yield localStorage.setItem('email', JSON.stringify(response?.data?.data?.user?.email));
          yield sessionStorage.setItem('email', JSON.stringify(response?.data?.data?.user?.email));
          yield localStorage.setItem('userByRoleId', okrUser);
          handleRedirection(payload);
        } else if (!response?.data?.data?.access_token) {
          // TODO: message needs to be set from backend
          yield put({
            type: actions.LOGIN_ERROR,
            error: 'You have not completed onboarding process. Please check your mail to complete signup.'
          });
          history.push('/');
          yield put(authActions.globalLoaderHandler(false));
          return;
        } else {
          // executive (company) signup
          if (getSubDomain() === 'executive') {
            yield sessionStorage.setItem('id_token', response?.data?.data?.access_token);
            yield localStorage.setItem(
              'id_token',
              response?.data?.data?.access_token
            );
            yield sessionStorage.setItem('email', response?.data?.data?.user?.created_by);
            yield localStorage.setItem('userInfo', JSON.stringify(response?.data?.data?.user));
          }
          // All types of login
          else {
            if (
              Object.keys(response.data.data.subscription)?.length > 0 &&
              response.data.data.subscription?.cancel_at !== null &&
              response?.data?.data?.user?.role_id !== 1
            ) {
              history.push('/');
              yield put({
                type: actions.LOGIN_ERROR,
                error: nonAdminLoginAlert
              });
            } else {
              yield put({
                type: actions.LOGIN_SUCCESS,
                token: response.data.data.access_token,
                userData: response.data.data.user
              });
              if (!payload.token) {
                yield JSON.stringify(localStorage.setItem('userByRoleId', response?.data?.data?.user?.role_id));
                yield localStorage.setItem('subscription', JSON.stringify(response?.data?.data?.subscription));
              }
            }
          }
          handleRedirection(payload);
        }
      } else {
        if (payload.token) {
          yield put({
            type: actions.SIGNUP_STEP1_FAILED,
            error: response?.data?.message || response?.message
          });
          history.push(`/employeesignup?token=${payload.token}`);
        } else {
          yield put({
            type: actions.LOGIN_ERROR,
            error: response?.data?.message || response?.message
          });
          history.push('/');
        }
      }
    } catch (error: any) {
      yield put({
        type: actions.LOGIN_ERROR,
        error: error.data.message
      });
      history.push('/');
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* resetPassword() {
  yield takeEvery(
    actions.RESET_PASSWORD,
    function* ({
      payload,
      history,
      from
    }: {
      type: string;
      payload: Record<string, any>;
      history: Record<string, any>;
      from: string;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(resetPasswordReq, payload, from);
        //if (response.data.user.verified) {
        if (response.data) {
          yield put({
            type: actions.RESET_PASSWORD_SUCCESS,
            token: response.data.access_token,
            payload: response.data.message
          });
          history.push('/');
        } else {
        }
      } catch (error: any) {
        yield put({ type: actions.RESET_PASSWORD_FAILED, error: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put(
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: token
        })
      );
    }
  });
}
export function* loginSuccess() {
  yield takeEvery(
    actions.LOGIN_SUCCESS,
    function* (payload: { type: string; token: string; userData?: { [key: string]: any } }) {
      yield localStorage.clear();
      yield localStorage.setItem('id_token', payload.token);
      yield localStorage.setItem('email', payload?.userData?.created_by);
    }
  );
}
export function* getManager() {
  yield takeEvery(
    actions.GET_MANAGER_LIST,
    function* ({ payload }: { type: string; isIncrement?: boolean; payload?: getManagerPayload }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getManagerReq, payload);

        if (response) {
          yield put({
            type: actions.GET_MANAGER_LIST_SUCCESS,
            payload: response.data?.data?.list
          });
        } else {
          yield put({ type: actions.GET_MANAGER_LIST_FAILED, error: response });
        }
      } catch (error: any) {
        yield put({ type: actions.GET_MANAGER_LIST_FAILED, error: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getDepartment() {
  yield takeEvery(actions.GET_DEPARTMENT_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getDepartmentReq);
      if (response) {
        yield put({
          type: actions.GET_DEPARTMENT_SUCCESS,
          payload: response.data
        });
      } else {
        yield put({ type: actions.GET_DEPARTMENT_FAILED, error: response });
      }
    } catch (error: any) {
      yield put({ type: actions.GET_DEPARTMENT_FAILED, error: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export function* departmentAdd() {
  yield takeEvery(actions.DEPARTMENT_ADD, function* ({ payload }: { type: string; payload: Record<string, any> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(departmentAddReq, payload);

      if (response) {
        yield put({
          type: actions.DEPARTMENT_ADD_SUCCESS,
          payload: response.data.department
        });
        yield put({
          type: actions.GET_DEPARTMENT_REQUEST
        });
      } else {
        yield put({
          type: actions.DEPARTMENT_ADD_FAILED,
          error: response.data.message || response.message
        });
      }
    } catch (error: any) {
      yield put({ type: actions.DEPARTMENT_ADD_FAILED, error: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* patchManagerInsertEffect() {
  yield takeEvery(actions.PATCH_MANAGER_INSERT, function* ({ payload }: { type: string; payload }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(patchManagerInsertReq, payload);
      if (response?.status) {
        yield sessionStorage.clear();
        yield all([
          put({
            type: actions.LOGIN_SUCCESS,
            token: response.data.data.access_token,
            userData: response.data.data.user
          }),
          put({
            type: SubscriptionPlanTypes.GET_ALL_PLANS
          }),
          put({
            type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
            payload: subscriptionHistoryPayload
          }),
          put({
            type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION
          }),
          put({
            type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION
          })
        ]);
        history.push(`${routeURLConst.listView}`);
      }
    } catch (error: any) {
      yield put({
        type: actions.PATCH_MANAGER_INSERT_FAILED,
        error: error?.data?.message
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* CheckPortalUrlEffect() {
  yield takeEvery(
    actions.CHECK_PORTAL_URL_ROOT,
    function* ({
      payload
    }: {
      type: string;
      payload: {
        company_slug: string;
      };
    }) {
      try {
        const response = yield call(checkPortalURLinRoot, payload);
        if (response) {
          yield put({
            type: actions.CHECK_PORTAL_URL_ROOT_SUCCESS,
            payload: response.data?.status
          });

          response.data?.status === false && history.push('/404');
        }
      } catch (error) {}
    }
  );
}

export function* CheckValidTokenEffect() {
  yield takeEvery(actions.CHECK_VALID_TOKEN, function* ({ payload }: { type: string; payload: Record<string, any> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(checkValidTokenReq, payload);
      if (response) {
        yield put({
          type: actions.CHECK_VALID_TOKEN_SUCCESS
        });
      } else {
        yield put({
          type: actions.CHECK_VALID_TOKEN_FAILED,
          tokenError: response?.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.CHECK_VALID_TOKEN_FAILED,
        tokenError: error
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* CheckUserLimitEffect() {
  yield takeEvery(actions.CHECK_USER_LIMIT, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(checkUserLimitReq);
      if (response) {
        yield put({
          type: actions.CHECK_USER_LIMIT_SUCCESS
        });
      } else {
        yield put({
          type: actions.CHECK_USER_LIMIT_FAILED,
          userLimitExceed: response?.error
        });
      }
    } catch (error) {
      yield put({
        type: actions.CHECK_USER_LIMIT_FAILED,
        userLimitExceed: error
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* VerifyOtpEffect() {
  yield takeEvery(actions.POST_VERIFY_OTP, function* ({ payload }: { type: string; payload: Record<string, any> }) {
    try {
      const response = yield call(verifyOtpReq, payload);
      if (response) {
        yield put({
          type: actions.POST_VERIFY_OTP_SUCCESS,
          otpVerificationSuccess: response?.data?.message
        });
        history.push(ADMIN_ROUTES.SIGN_UP_ADMIN_2_MANUAL);
      } else {
        yield put({
          type: actions.POST_VERIFY_OTP_FAILED,
          otpVerificationError: response?.data?.message
        });
      }
    } catch (error: any) {
      yield put({
        type: actions.POST_VERIFY_OTP_FAILED,
        otpVerificationError: error?.data?.message
      });
    }
  });
}

export function* ResendOtpEffect() {
  yield takeEvery(actions.PATCH_RESEND_OTP, function* ({ payload }: { type: string; payload: Record<string, any> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(resendOtpReq, payload);
      if (response) {
        yield put({
          type: actions.PATCH_RESEND_OTP_SUCCESS,
          otpResendSuccess: response?.data?.data?.message
        });
      } else {
        yield put({
          type: actions.PATCH_RESEND_OTP_FAILED,
          otpResendError: response?.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.PATCH_RESEND_OTP_FAILED,
        otpResendError: error
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* companyInfoEffect() {
  yield takeEvery(actions.POST_COMPANY_INFO, function* ({ payload }: { type: string; payload: Record<string, any> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(comapnyInfoReq, payload);
      if (response) {
        yield put({
          type: actions.POST_COMPANY_INFO_SUCCESS,
          companyInfoSuccess: response?.data?.data?.message
        });
        history.push(ADMIN_ROUTES.SIGN_UP_ADMIN_2);
      } else {
        yield put({
          type: actions.POST_COMPANY_INFO_FAILED,
          companyInfoError: response?.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.POST_COMPANY_INFO_FAILED,
        companyInfoError: error
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* authSaga() {
  yield all([
    fork(manualSignupSaga),
    fork(checkAuthorization),
    fork(signupStep1),
    fork(signupAdminStep1),
    fork(loginRequest),
    fork(loginError),
    fork(loginSuccess),
    fork(resetPassword),
    fork(forgotPassword),
    fork(oAuthVerifySaga),
    fork(getManager),
    fork(departmentAdd),
    fork(getDepartment),
    fork(patchManagerInsertEffect),
    fork(CheckPortalUrlEffect),
    fork(CheckValidTokenEffect),
    fork(VerifyOtpEffect),
    fork(ResendOtpEffect),
    fork(companyInfoEffect),
    fork(CheckUserLimitEffect)
  ]);
}
