import { manageUserActions } from "./manageUserActions";
import { manageUserAction, manageUserState } from "./types";
let initState: manageUserState = {
  idToken: null,
  loggedInUser: null, // User's data - Role and Redirection check
  authFlag: false,
  errorMessage: "",
  successMessage: "",
  allUserList: [],
  totalItems: 0,
  page: 1,
  take: 10,
};
export default function authReducer(
  state = initState,
  action: manageUserAction
) {
  switch (action.type) {
    case manageUserActions.POST_INVITE_USERS_LINK:
      return {
        ...state,
      };
    case manageUserActions.POST_INVITE_USERS_LINK_SUCCESS:
      return {
        ...state,
      };
    case manageUserActions.POST_INVITE_USERS_LINK_FAILED:
      return {
        ...state,
      };
    case manageUserActions.POST_ADD_MANUALLY_USER:
      return {
        ...state,
      };
    case manageUserActions.POST_ADD_MANUALLY_USER_SUCCESS:
      return {
        ...state,
      };
    case manageUserActions.POST_ADD_MANUALLY_USER_FAILED:
      return {
        ...state,
      };
    case manageUserActions.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        allUserList: action.allUserList,
        totalItems: action.totalItems,
      };
    case manageUserActions.EDIT_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case manageUserActions.EDIT_USER_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case manageUserActions.PATCH_DELETE_USER_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case manageUserActions.PATCH_DELETE_USER_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case manageUserActions.CHANGE_PAGES:
      return {
        ...state,
        page: action.payload.currentPage,
        take: action.payload.pageSize,
      };
    case manageUserActions.REMOVE_ALERT:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
}
