import { ServiceAuthInstance } from '.';

export const getSearchResultsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `search`,
    data: {
      ...payload
    }
  });
};

export const getUserProfileReq = (payload) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `/profile/profile/${payload?.id}`,
  });
};
