import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { portalRatingSettingsTypes } from "./portalRatingSettingsTypes";
import authActions from "../app/action";
import { holdActions } from "../Holds/holdActions";
import { profileAction } from "../profile/actionTypes";
import {
  postconfidencelevelReq,
  getconfidencelevelReq,
  postobjectivetypeReq,
  getobjectivetypeReq,
  postscorecardReq,
  getscorecardReq,
  putscorecardReq,
  postassistancepointsReq,
  getassistancepointsReq,
  posthelpfulnessratingReq,
  gethelpfulnessratingReq,
  getvmsbstatusReq,
} from "../../library/services/portalRatingSettingsReq";
import { ActionToString } from "redux-logger";
import appActions from "../app/action";
import { SubscriptionPlanTypes } from "../SubscriptionPlan/SubscriptionPlanTypes";
import { subscriptionHistoryPayload } from "../../config/constant";
export function* postconfidencelevelEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL,
    function* ({
      payload,
    }: {
      type: ActionToString;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postconfidencelevelReq, payload);
        if (response.data.status) {
          yield put({
            type: portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL_SUCCESS,
            confidenceLevelMessage: response.data.data,
            ConfidenceLevel: response.data.data.confidence_level,
          });
          yield put({
            type: portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        } else {
          yield put({
            type: portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL_FAILED,
            confidenceLevelMessage: response,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }
      } catch (error: any) {
        yield put({
          type: portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL_FAILED,
          payload: error.message,
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getconfidencelevelEffect() {
  yield takeEvery(portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getconfidencelevelReq);
      if (response) {
        yield put({
          type: portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL_SUCCESS,
          ConfidenceLevel: response.data.data.confidence_level,
        });
      } else {
        yield put({
          type: portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL_FAILED,
          payload: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL_FAILED,
        payload: error.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getobjectivetypeEffect() {
  yield takeEvery(portalRatingSettingsTypes.GET_OBJECTIVE_TYPE, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getobjectivetypeReq);
      if (response) {
        yield put({
          type: portalRatingSettingsTypes.GET_OBJECTIVE_TYPE_SUCCESS,
          ObjectiveType: response.data.data,
        });
      } else {
        yield put({
          type: portalRatingSettingsTypes.GET_OBJECTIVE_TYPE_FAILED,
          ObjectiveType: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: portalRatingSettingsTypes.GET_OBJECTIVE_TYPE_FAILED,
        payload: error.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postobjectivetypeEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.POST_OBJECTIVE_TYPE,
    function* ({
      payload,
    }: {
      type: ActionToString;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postobjectivetypeReq, payload);
        if (response.data.status) {
          yield put({
            type: portalRatingSettingsTypes.POST_OBJECTIVE_TYPE_SUCCESS,
            ObjectiveTypeStatus: response.data.data,
          });
          yield put({
            type: portalRatingSettingsTypes.GET_OBJECTIVE_TYPE,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        } else {
          yield put({
            type: portalRatingSettingsTypes.POST_OBJECTIVE_TYPE_FAILED,
            ObjectiveTypeStatus: response,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }
      } catch (error: any) {
        yield put({
          type: portalRatingSettingsTypes.POST_OBJECTIVE_TYPE_FAILED,
          payload: error.data.message,
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postscorecardEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.POST_SCORE_CARD,
    function* ({
      payload,
    }: {
      type: ActionToString;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postscorecardReq, payload);
        if (response) {
          yield all([
            put({
              type: portalRatingSettingsTypes.POST_SCORE_CARD_SUCCESS,
              ScoreCardStatus: response?.data?.data,
            }),
            put({
              type: portalRatingSettingsTypes.GET_SCORE_CARD,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: portalRatingSettingsTypes.POST_SCORE_CARD_FAILED,
            ScoreCardStatus: { message: error?.data?.message },
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getscorecardEffect() {
  yield takeEvery(portalRatingSettingsTypes.GET_SCORE_CARD, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getscorecardReq);
      if (response) {
        yield put({
          type: portalRatingSettingsTypes.GET_SCORE_CARD_SUCCESS,
          scorecardObj: response.data.data,
        });
        yield put({
          type: appActions.SET_INPUT_BOX,
          payload: false,
        });
      }
    } catch (error: any) {
      yield put({
        type: portalRatingSettingsTypes.GET_SCORE_CARD_FAILED,
        payload: error.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* putscorecardEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.PUT_SCORE_CARD,
    function* ({
      payload,
      scorecardId,
    }: {
      type: ActionToString;
      isIncrement: boolean;
      payload: Record<string, string | number>;
      scorecardId: number;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(putscorecardReq, payload, scorecardId);
        if (response.data.status) {
          yield put({
            type: portalRatingSettingsTypes.PUT_SCORE_CARD_SUCCESS,
            ScoreCardStatus: response.data.data,
          });
          yield put({
            type: portalRatingSettingsTypes.GET_SCORE_CARD,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }
      } catch (error: any) {
        yield put({
          type: portalRatingSettingsTypes.PUT_SCORE_CARD_FAILED,
          ScoreCardStatus: { message: error?.data?.message },
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postassistancepointsEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.POST_ASSISTANCE_POINTS,
    function* ({
      payload,
    }: {
      type: ActionToString;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postassistancepointsReq, payload);
        if (response) {
          yield put({
            type: portalRatingSettingsTypes.POST_ASSISTANCE_POINTS_SUCCESS,
            Success: response.data.data.message,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
          yield put({
            type: profileAction.GET_PROFILE_REQUEST,
            payload: { loading: true },
          });
          yield put({
            type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
            payload: subscriptionHistoryPayload
          });
          yield put({
            type: portalRatingSettingsTypes.GET_ASSISTANCE_POINTS,
          });
        } else {
          yield put({
            type: portalRatingSettingsTypes.POST_ASSISTANCE_POINTS_FAILED,
            Failure: response.data.data.message,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }
      } catch (error: any) {
        yield put({
          type: portalRatingSettingsTypes.POST_ASSISTANCE_POINTS_FAILED,
          Failure: error.data.message,
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getassistancepointsEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.GET_ASSISTANCE_POINTS,
    function* () {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getassistancepointsReq);
        if (response) {
          yield put({
            type: portalRatingSettingsTypes.GET_ASSISTANCE_POINTS_SUCCESS,
            AssistancePoint: response.data.data,
          });
        } else {
          yield put({
            type: portalRatingSettingsTypes.GET_ASSISTANCE_POINTS_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: portalRatingSettingsTypes.GET_ASSISTANCE_POINTS_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* posthelpfulnessratingEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.POST_HELPFULNESS_RATING,
    function* ({
      payload,
    }: {
      type: ActionToString;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(posthelpfulnessratingReq, payload);
        if (response) {
          yield put({
            type: portalRatingSettingsTypes.POST_HELPFULNESS_RATING_SUCCESS,
            Success: response.data.data.message,
          });
          yield put({
            type: portalRatingSettingsTypes.GET_HELPFULNESS_RATING,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        } else {
          yield put({
            type: portalRatingSettingsTypes.POST_HELPFULNESS_RATING_FAILED,
            helpfulness_rating: response.data.data.message,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }
      } catch (error: any) {
        yield all([
          put({
            type: portalRatingSettingsTypes.POST_HELPFULNESS_RATING_FAILED,
            Failure: error.data.message,
          }),
          put({
            type: portalRatingSettingsTypes.GET_HELPFULNESS_RATING,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* gethelpfulnessratingEffect() {
  yield takeEvery(
    portalRatingSettingsTypes.GET_HELPFULNESS_RATING,
    function* () {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(gethelpfulnessratingReq);
        if (response.data.status) {
          yield put({
            type: portalRatingSettingsTypes.GET_HELPFULNESS_RATING_SUCCESS,
            helpfulness_rating: response.data.data[0].helpfulness_rating,
          });
        } else {
          yield put({
            type: portalRatingSettingsTypes.GET_HELPFULNESS_RATING_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: portalRatingSettingsTypes.GET_HELPFULNESS_RATING_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getcheckVmsbEffect() {
  yield takeEvery(portalRatingSettingsTypes.GET_CHECK_VMSB, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getvmsbstatusReq);
      if (response.status) {
        yield put({
          type: portalRatingSettingsTypes.GET_CHECK_VMSB_SUCCESS,
          vmsbStatus: response.data.data.result,
        });
      } else {
        yield put({
          type: portalRatingSettingsTypes.GET_CHECK_VMSB_FAILED,
          payload: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: portalRatingSettingsTypes.GET_CHECK_VMSB_FAILED,
        payload: error.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(postconfidencelevelEffect),

    fork(getconfidencelevelEffect),

    fork(postobjectivetypeEffect),

    fork(getobjectivetypeEffect),

    fork(postscorecardEffect),

    fork(getscorecardEffect),

    fork(putscorecardEffect),

    fork(postassistancepointsEffect),

    fork(getassistancepointsEffect),

    fork(posthelpfulnessratingEffect),

    fork(gethelpfulnessratingEffect),

    fork(getcheckVmsbEffect),
  ]);
}
