import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { promoCodeTypes } from './actionTypes';
import authActions from '../../../redux/app/action';
import {
  addPromoReq,
  deletePromoCodeReq,
  generatePromoReq,
  promoCodeDetailListReq,
  promoCodeDetailReq,
  promoCodeListReq,
  updatePromoCodeReq
} from '../../../library/services/Admin/promocode';
import { defaultPagination } from '../../../config/constant';

export function* addPromoCodeEffect() {
  yield takeEvery(
    promoCodeTypes.ADD_PROMO_CODE,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(addPromoReq, payload);
        if (response) {
          yield put({
            type: promoCodeTypes.ADD_PROMO_CODE_SUCCESS
          });
          yield put({
            type: promoCodeTypes.GET_PROMO_LIST,
            payload: defaultPagination
          });
        } else {
          yield put({
            type: promoCodeTypes.ADD_PROMO_CODE_FAILURE,
            promoAddError: response.data.data
          });
        }
      } catch (error: any) {
        yield put({
          type: promoCodeTypes.ADD_PROMO_CODE_FAILURE,
          promoAddError: error.response.data.message
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* generatePromoCodeEffect() {
  yield takeEvery(promoCodeTypes.GENERATE_PROMO_CODE, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(generatePromoReq);
      if (response) {
        yield put({
          type: promoCodeTypes.GENERATE_PROMO_CODE_SUCCESS,
          generatedPromo: response.data.data.sixDigitCode
        });
      } else {
        yield put({
          type: promoCodeTypes.GENERATE_PROMO_CODE_FAILURE,
          promoAddError: response.data.data
        });
      }
    } catch (error) {
      yield put({
        type: promoCodeTypes.GENERATE_PROMO_CODE_FAILURE,
        promoAddError: error
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getPromoCodesEffect() {
  yield takeEvery(
    promoCodeTypes.GET_PROMO_LIST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(promoCodeListReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.promocodeLists.metaData?.totalPages &&
            payload.page > response.data.data.promocodeLists.metaData?.totalPages
          ) {
            yield put({
              type: promoCodeTypes.GET_PROMO_LIST,
              payload: { ...payload, page: 1 }
            });
          } else {
            yield put({
              type: promoCodeTypes.GET_PROMO_LIST_SUCCESS,
              allPromoCodes: response.data.data.promocodeLists.pagedItems,
              totalPromos: response.data.data.promocodeLists.metaData
            });
          }
        } else {
          yield put({
            type: promoCodeTypes.GET_PROMO_LIST_FAILURE,
            promoListError: response.data.data
          });
        }
      } catch (error) {
        yield put({
          type: promoCodeTypes.GET_PROMO_LIST_FAILURE,
          promoListError: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getPromoCodeDetailEffect() {
  yield takeEvery(
    promoCodeTypes.GET_PROMO_DETAIL,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(promoCodeDetailReq, payload);
        if (response) {
          yield put({
            type: promoCodeTypes.GET_PROMO_DETAIL_SUCCESS,
            promoDetails: response.data.data.promocodeDetails
          });
        } else {
          yield put({
            type: promoCodeTypes.GET_PROMO_DETAIL_FAILURE,
            promoDetailError: response.data.data
          });
        }
      } catch (error) {
        yield put({
          type: promoCodeTypes.GET_PROMO_DETAIL_FAILURE,
          promoDetailError: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getPromoCodeDetailListEffect() {
  yield takeEvery(
    promoCodeTypes.GET_PROMO_DETAIL_LIST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(promoCodeDetailListReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.promocodeDetailsLists[0].promo_id.metaData?.totalPages &&
            payload.page > response.data.data.promocodeDetailsLists[0].promo_id.metaData?.totalPages
          ) {
            yield put({
              type: promoCodeTypes.GET_PROMO_DETAIL_LIST,
              payload: { ...payload, page: 1 }
            });
          } else {
            yield put({
              type: promoCodeTypes.GET_PROMO_DETAIL_LIST_SUCCESS,
              promoDetailsList: response.data.data.promocodeDetailsLists[0],
              promoAppliedCount: response.data.data.promocodeDetailsLists[0].promo_id.metaData
            });
          }
        } else {
          yield put({
            type: promoCodeTypes.GET_PROMO_DETAIL_LIST_FAILURE,
            promoDetailError: response.data.data
          });
        }
      } catch (error) {
        yield put({
          type: promoCodeTypes.GET_PROMO_DETAIL_LIST_FAILURE,
          promoDetailError: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* editPromoCodeEffect() {
  yield takeEvery(
    promoCodeTypes.UPDATE_PROMO_CODE,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(updatePromoCodeReq, payload);
        if (response) {
          yield all([
            put({
              type: promoCodeTypes.UPDATE_PROMO_CODE_SUCCESS,
              promoUpdateSuccess: response.data.message
            }),
            put({
              type: promoCodeTypes.GET_PROMO_LIST,
              payload: defaultPagination
            }),
            put({
              type: promoCodeTypes.GET_PROMO_DETAIL,
              payload: payload.promocode_id
            })
          ]);
        } else {
          yield put({
            type: promoCodeTypes.UPDATE_PROMO_CODE_FAILURE,
            promoUpdateError: response.data.data
          });
        }
      } catch (error: any) {
        yield put({
          type: promoCodeTypes.UPDATE_PROMO_CODE_FAILURE,
          promoUpdateError: error.response.data.message
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* deletePromoCodeEffect() {
  yield takeEvery(
    promoCodeTypes.DELETE_PROMO_CODE,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(deletePromoCodeReq, payload);
        if (response) {
          yield all([
            put({
              type: promoCodeTypes.DELETE_PROMO_CODE_SUCCESS,
              deleteSuccessMessage: response.data.message
            }),
            put({
              type: promoCodeTypes.GET_PROMO_LIST,
              payload: defaultPagination
            }),
            put({
              type: promoCodeTypes.GET_PROMO_DETAIL,
              payload: payload.promocode_id
            })
          ]);
        } else {
          yield put({
            type: promoCodeTypes.DELETE_PROMO_CODE_FAILURE,
            deleteErrorMessage: response.data.data
          });
        }
      } catch (error: any) {
        yield put({
          type: promoCodeTypes.DELETE_PROMO_CODE_FAILURE,
          deleteErrorMessage: error.response.data.message
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* PromoCodeSaga() {
  yield all([
    fork(addPromoCodeEffect),
    fork(generatePromoCodeEffect),
    fork(getPromoCodesEffect),
    fork(getPromoCodeDetailEffect),
    fork(getPromoCodeDetailListEffect),
    fork(editPromoCodeEffect),
    fork(deletePromoCodeEffect)
  ]);
}
