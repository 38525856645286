import { ServiceAuthInstance } from "./index";

export const getNotificationReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "organization/notification",
  });
};

export const setNotificationSoundReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "profile/sound",
  });
};

export const chnageNotificationStatusReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `organization/notification_status/${payload.id}`,
    data: payload,
  });
};
