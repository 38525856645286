import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const defaultRolePermissionsReq = () => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.DEFAULT_ROLE_PERMISSIONS
  });
};

export const updateRolePermissionsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.UPDATE_DEFAULT_ROLE_PERMISSIONS,
    data: payload
  });
};

export const workspaceUsersListReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_USER_LIST,
    data: { ...payload }
  });
};

export const workspaceUserDetailsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'GET',
    url: `${adminApiUrls.WORKSPACE_USER_DETAIL}/${payload}`
  });
};
