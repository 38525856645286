import { keyResultTypes } from "./keyResultTypes";
import { keyResultAction, keyResultState } from "./types";
let initState: keyResultState = {
  errorMessage: "",
  successMessage: null,
  Objective_Of_User: [],
  SingleKeyResult: {
    key_results: [
      {
        ID: 0,
        name: "",
        description: "",
        Timebox: 0,
        Priority: 0,
        Status: 0,
        Objective: "",
      },
    ],
    Reminder: [{ reminder_time: "", reminder_text: "" }],
    objective: { Id: 0 },
  },
  krComments: {
    pagedItems: [
      {
        id: 0,
        description: "",
        posted_by: { id: 0, first_name: "", last_name: "" },
        email_address: "",
        objective: "",
        key_results: 0,
        tasks: "",
        vision: "",
        mission: "",
        strategy: "",
        benefit: "",
        comment_date: "",
      },
    ],
    metaData: {
      itemsPerPage: "",
      totalItems: 0,
      currentPage: "",
      totalPages: 0,
    },
  },
  krTaskList: {
    pagedItems: [],
    metaData: {
      itemsPerPage: "",
      totalItems: 0,
      currentPage: "",
      totalPages: 0,
    },
  },
};
export default function authReducer(
  state: any = initState,
  action: keyResultAction
) {
  switch (action.type) {
    case keyResultTypes.POST_KEYRESULT:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    case keyResultTypes.POST_KEYRESULT_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case keyResultTypes.POST_KEYRESULT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case keyResultTypes.GET_KEYRESULT:
      return {
        ...state,
      };
    case keyResultTypes.GET_KEYRESULT_SUCCESS:
      return {
        ...state,
        SingleKeyResult: action.SingleKeyResult,
      };
    case keyResultTypes.GET_KEYRESULT_FAILED:
      return {
        ...state,
      };
    case keyResultTypes.PATCH_KEYRESULT:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    case keyResultTypes.PATCH_KEYRESULT_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case keyResultTypes.PATCH_KEYRESULT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case keyResultTypes.DELETE_FOREGIN_LINKED_KEY_RESULT_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case keyResultTypes.DELETE_FOREGIN_LINKED_KEY_RESULT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };

    case keyResultTypes.GET_OBJ_OF_USER_SUCCESS:
      return {
        ...state,
        Objective_Of_User: action.Objective_Of_User,
      };
    case keyResultTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      };
    case keyResultTypes.GET_KR_COMMENTS_SUCCESS:
      return {
        ...state,
        krComments: action.krComments,
      };
    case keyResultTypes.GET_KR_TASK_SUCCESS:
      return {
        ...state,
        krTaskList: action.krTaskList,
      };
    default:
      return state;
  }
}
