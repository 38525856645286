import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { ExecutiveSignupStep2Types } from "./ExecutiveSignupStep2Types";
import authActions from "../app/action";
import history from "../../library/helpers/history";
import {
  createCompanyReq,
  getCompanyIndrustryReq,
  getCompanyPortalStatusReq,
  getPortalSlugAvailableReq,
  postExecutiveSignupStep2Req,
} from "../../library/services/ExecutiveSignupStep2Req";
import {
  postCreateCompanyPayload,
  getCompanySlugAvailablePayload,
} from "./types";

export function* getExecutiveSignupStep2Effect() {
  yield takeEvery(
    ExecutiveSignupStep2Types.POST_CREATE_COMPANY,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement?: boolean;
      payload: postCreateCompanyPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(createCompanyReq, payload);

        if (response?.status) {
          yield put({
            type: ExecutiveSignupStep2Types.POST_CREATE_COMPANY_SUCCESS,
            companySlug: response.data.data,
            successMessage: response?.data?.data?.message,
            companyUrl: response?.data?.data?.companySlug,
          });
          history.push('/subscriptionplan')
          // history.push("/portalsuccess");
        }
      } catch (error: any) {
        yield put({
          type: ExecutiveSignupStep2Types.POST_CREATE_COMPANY_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getPortalSlugAvailabilityEffect() {
  yield takeEvery(
    ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement?: boolean;
      payload: getCompanySlugAvailablePayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(getPortalSlugAvailableReq, payload);
        if (response?.data?.data?.status) {
          yield put({
            type: ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY_SUCCESS,
            companySlug: payload.company_slug,
          });
        } else {
          yield put({
            type: ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postExecutiveSignupStep2Effect() {
  yield takeEvery(
    ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postExecutiveSignupStep2Req, payload);
        if (response?.status) {
          yield put({
            type: ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2_SUCCESS,
            companySlug: response?.data?.data?.companySlug,
            successMessage: response?.data?.data?.message,
          });
          sessionStorage.setItem('companySlug', response?.data?.data?.companySlug);
          history.push('/subscriptionplan')
        }
      } catch (error: any) {
        yield put({
          type: ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2_FAILED,
          payload: error?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getCompanyIndrustyEffect() {
  yield takeEvery(
    ExecutiveSignupStep2Types.GET_COMPANY_INDRUSTRY,
    function* () {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getCompanyIndrustryReq);
        if (response) {
          yield put({
            type: ExecutiveSignupStep2Types.GET_COMPANY_INDRUSTRY_SUCCESS,
            CompanyIndrustry: response?.data?.data?.companyData,
          });
        }
      } catch (error: any) {
        yield put({
          type: ExecutiveSignupStep2Types.GET_COMPANY_INDRUSTRY_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getCompanyPortalStatusEffect() {
  yield takeEvery(
    ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        const response: any = yield call(getCompanyPortalStatusReq, payload);
        if (response) {
          if (response?.data?.data?.status) {
            yield put({
              type: ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS_SUCCESS,
            });
          } else {
            yield put({
              type: ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS_FAILED,
            });
          }
        }
      } catch (error: any) {
        yield put({
          type: ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS_FAILED,
        });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getExecutiveSignupStep2Effect),
    fork(getPortalSlugAvailabilityEffect),
    fork(postExecutiveSignupStep2Effect),
    fork(getCompanyIndrustyEffect),
    fork(getCompanyPortalStatusEffect),
  ]);
}
