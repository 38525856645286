import { organizationTypes } from "./organizationSettingsTypes";
import { organizationSettingsAction, organizationSettingsState } from "./types";
let initState: organizationSettingsState = {
  idToken: null,
  loggedInUser: null, // User's data - Role and Redirection check
  authFlag: false,
  errorMessage: "",
  organizationFinacialData: null,
  portalSettingData: {
    timezone: 1,
    name: null,
  },
  timezoneData: [],
  financialYearFailMessage: null,
  portalMessage: {
    type: null,
    message: "",
  },
  organizationFinacialMessage: {
    type: null,
    message: "",
  },
  settingBackBtn: true,
};
export default function authReducer(
  state = initState,
  action: organizationSettingsAction
) {
  switch (action.type) {
    case organizationTypes.POST_FINANCIAL_YEAR:
      return {
        ...state,
        organizationFinacialMessage: {
          type: null,
          message: "",
        },
      };
    case organizationTypes.POST_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        organizationFinacialMessage: {
          type: "success",
          message: action.organizationFinacialMessage,
        },
        settingBackBtn: false,
      };
    case organizationTypes.POST_FINANCIAL_YEAR_FAILED:
      return {
        ...state,
        organizationFinacialMessage: {
          type: "error",
          message: action.organizationFinacialMessage,
        },
      };
    case organizationTypes.GET_FINANCIAL_YEAR:
      return {
        ...state,
      };
    case organizationTypes.GET_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        organizationFinacialData: action.organizationFinacialData,
      };
    case organizationTypes.GET_FINANCIAL_YEAR_FAILED:
      return {
        ...state,
      };
    case organizationTypes.GET_ORGANIZATION:
      return {
        ...state,
      };
    case organizationTypes.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        portalSettingData: action.portalSettingData[0],
      };
    case organizationTypes.GET_ORGANIZATION_FAILED:
      return {
        ...state,
      };
    case organizationTypes.POST_ORGANIZATION:
      return {
        ...state,
        portalMessage: {
          type: null,
          message: "",
        },
      };
    case organizationTypes.POST_ORGANIZATION_SUCCESS:
      return {
        ...state,
        portalMessage: {
          type: "success",
          message: action.portalMessage,
        },
      };
    case organizationTypes.POST_ORGANIZATION_FAILED:
      return {
        ...state,
        portalMessage: {
          type: "error",
          message: action.portalMessage.message,
        },
      };

    case organizationTypes.PATCH_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        portalMessage: {
          type: "success",
          message: action.portalMessage,
        },
      };
    case organizationTypes.PATCH_COMPANY_LOGO_FAILED:
      return {
        ...state,
        portalMessage: {
          type: "error",
          message: action.portalMessage.message,
        },
      };
    case organizationTypes.GET_TIMEZONE:
      return {
        ...state,
        timezoneData: [],
      };
    case organizationTypes.GET_TIMEZONE_SUCCESS:
      return {
        ...state,
        timezoneData: action.timezoneData,
      };
    case organizationTypes.GET_TIMEZONE_FAILED:
      return {
        ...state,
        // timezoneData: action.timezoneData,
      };
    case organizationTypes.CLEAR_RESPONSE_MESSAGE: {
      return {
        ...state,
        portalMessage: {
          type: null,
          message: "",
        },
        organizationFinacialMessage: {
          type: null,
          message: "",
        },
      };
    }
    default:
      return state;
  }
}
