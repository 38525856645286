import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from "@sentry/react";
// import logger from "redux-logger";

import rootReducer from "./rootReducer";
import { rootSaga } from "./rootSaga";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// Mount it on the Store

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, logger)));
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware), sentryReduxEnhancer));

// Run the saga
sagaMiddleware.run(rootSaga);

export default store;
