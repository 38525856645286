import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { holdActions } from "../Holds/holdActions";
import history from "../../library/helpers/history";
import authActions from "../app/action";
import {
  postObjectiveReq,
  getObjectiveReq,
  patchObjectiveReq,
  getKrInObjReq,
  getForeignObjKRListReq,
  getForeignBenefitListReq,
  deleteForeginLinkedReq
} from "../../library/services/ObjectiveReq";
import { Objectivetypes } from "./actionTypes";
import {
  getObjectivePayload,
  getObjTaskPayload,
  patchObjectivePayload,
  postObjectivePayload,
  deleteForeginLinkedPayload
} from "./types";
export function* postObjectiveEffect() {
  yield takeEvery(
    Objectivetypes.POST_OBJECTIVE,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: postObjectivePayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postObjectiveReq, payload);
        if (response.status) {
          yield all([
            put({
              type: Objectivetypes.POST_OBJECTIVE_SUCCESS,
              successMessage: response?.data?.data?.message
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
          history.push(`viewobjective/${response?.data?.data?.objective_id}`);
        }
      } catch (error: any) {
        yield all([
          put({
            type: Objectivetypes.POST_OBJECTIVE_FAILED,
            errorMessage: error?.data?.message
          }),
          put({
            type: holdActions.SCROLL_TO_TOP
          })
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getObjectiveEffect() {
  yield takeEvery(
    Objectivetypes.GET_OBJECTIVE,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: getObjectivePayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getObjectiveReq, payload);
        if (response) {
          yield put({
            type: Objectivetypes.GET_OBJECTIVE_SUCCESS,
            singleObjective: response?.data?.data
          });
        }
      } catch (error: any) {
        yield put({
          type: Objectivetypes.GET_OBJECTIVE_FAILED,
          errorMessage: error.message
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* patchObjectiveEffect() {
  yield takeEvery(
    Objectivetypes.PATCH_OBJECTIVE,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: patchObjectivePayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const id = payload?.id;
        const response = yield call(patchObjectiveReq, payload);
        if (response?.status) {
          yield all([
            put({
              type: Objectivetypes.PATCH_OBJECTIVE_SUCCESS,
              successMessage: response?.data?.data?.message
            }),
            put({
              type: Objectivetypes.GET_OBJECTIVE,
              payload: { id: id }
            }),
            put({
              type: Objectivetypes.GET_BENEFIT_LIST,
              payload: {id: id}
            }),
            put({
              type: Objectivetypes.GET_OKR_LIST,
              payload: {id: id}
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: Objectivetypes.PATCH_OBJECTIVE_FAILED,
            errorMessage: error?.data?.message
          }),
          put({
            type: holdActions.SCROLL_TO_TOP
          })
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getForeignObjKeyResultEffect() {
  yield takeEvery(
    Objectivetypes.GET_OKR_LIST,
    function* ({ payload }: { type: string; payload: { id?: string } }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getForeignObjKRListReq, payload);
        if (response.status) {
          yield put({
            type: Objectivetypes.GET_OKR_LIST_SUCCESS,
            foreignOBjKr: response?.data?.data?.objectiveData
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getForeignBenefitEffect() {
  yield takeEvery(
    Objectivetypes.GET_BENEFIT_LIST,
    function* ({ payload }: { type: string; payload: { id?: string } }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getForeignBenefitListReq, payload);
        if (response.status) {
          yield put({
            type: Objectivetypes.GET_BENEFIT_LIST_SUCCESS,
            foreignBenefit: response?.data?.data?.benefitData
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getObjTaskEffect() {
  yield takeEvery(
    Objectivetypes.GET_OBJ_TASK,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: getObjTaskPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getKrInObjReq, payload);
        if (response.status) {
          yield put({
            type: Objectivetypes.GET_OBJ_TASK_SUCCESS,
            objTaskList: response?.data?.data?.paginatedkeyData
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* deleteForeginLinkedEffect() {
  yield takeEvery(
    Objectivetypes.DELETE_FOREGIN_LINKED_OKR,
    function* ({
      payload
    }: {
      type: string;
      payload: deleteForeginLinkedPayload;
    }) {
      const id = payload?.parent_obj;
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(deleteForeginLinkedReq, payload);
        if (response?.status) {
          yield all([
            put({
              type: Objectivetypes.DELETE_FOREGIN_LINKED_OKR_SUCCESS,
              successMessage: response?.data?.data?.message
            }),
            put({
              type: Objectivetypes.GET_OBJECTIVE,
              payload: { id: String(id) }
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
        }
      } catch (error: any) {
        if (id) {
          yield all([
            put({
              type: Objectivetypes.DELETE_FOREGIN_LINKED_OKR_FAILED,
              errorMessage: error?.data?.message
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
        }
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(postObjectiveEffect),
    fork(getObjectiveEffect),
    fork(patchObjectiveEffect),
    fork(getObjTaskEffect),
    fork(getForeignObjKeyResultEffect),
    fork(getForeignBenefitEffect),
    fork(deleteForeginLinkedEffect)
  ]);
}
