import { portalRatingSettingsTypes } from "./portalRatingSettingsTypes";
import { portalRatingSettingsAction, portalRatingSettingsState } from "./types";
let initState: portalRatingSettingsState = {
  idToken: "",
  loggedInUser: "",
  authFlag: false,
  errorMessage: "",
  Success: "",
  Failure: "",
  AssistancePointStatus: {
    success: false,
    failure: false,
  },
  HelpfulnessRatingStatus: {
    success: false,
    failure: false,
  },
  ObjectiveType: {
    aspirational: [3, 7, 10],
    committed: [3, 7, 10],
    committed_and_aspirational: [3, 7, 10],
  },
  ConfidenceLevel: [3, 7, 10],
  ObjectiveTypeStatus: {
    type: null,
    message: null,
  },
  ScoreCardStatus: {
    type: null,
    message: null,
  },
  scorecardObj: [{ id: 0, name: "", display_colour: "", type: 0 }],
  confidenceLevelMessage: {
    type: null,
    message: null,
  },
  helpfulness_rating: 0,
  AssistancePoint: [{ assistance_points: 0 }],
  vmsbStatus: true,
  ObjectiveTypeColors: {
    aspirational: {low_color: '#E02929', medium_color: '#ea9d17', high_color: '#00B95D'},
    committed: {low_color: '#E02929', medium_color: '#ea9d17', high_color: '#00B95D'},
    committed_and_aspirational: {low_color: '#E02929', medium_color: '#ea9d17', high_color: '#00B95D'},
  },
  ConfidenceLevelColors: {low_color: '#E02929', medium_color: '#ea9d17', high_color: '#00B95D'},
};
export default function authReducer(
  state = initState,
  action: portalRatingSettingsAction
): portalRatingSettingsState {
  switch (action.type) {
    case portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL:
      return {
        ...state,
        confidenceLevelMessage: {
          type: null,
          message: null,
        },
      };
    case portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL_SUCCESS:
      return {
        ...state,
        confidenceLevelMessage: {
          type: "success",
          message: action.confidenceLevelMessage.message,
        },
        ConfidenceLevel: state.ConfidenceLevel,
      };
    case portalRatingSettingsTypes.POST_CONFIDENCE_LEVEL_FAILED:
      return {
        ...state,
        confidenceLevelMessage: {
          type: "error",
          message: action.confidenceLevelMessage.message,
        },
      };
    case portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL:
      return {
        ...state,
        ConfidenceLevel: state.ConfidenceLevel,
      };
    case portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL_SUCCESS:
      return {
        ...state,
        ConfidenceLevel: [
          action.ConfidenceLevel.low,
          action.ConfidenceLevel.medium,
          action.ConfidenceLevel.high,
        ],
        ConfidenceLevelColors: {low_color: action.ConfidenceLevel.low_color, medium_color: action.ConfidenceLevel.medium_color, high_color: action.ConfidenceLevel.high_color}
      };
    case portalRatingSettingsTypes.GET_CONFIDENCE_LEVEL_FAILED:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.POST_OBJECTIVE_TYPE:
      return {
        ...state,
        ObjectiveType: state.ObjectiveType,
      };
    case portalRatingSettingsTypes.POST_OBJECTIVE_TYPE_SUCCESS:
      return {
        ...state,
        ObjectiveTypeStatus: {
          type: "success",
          message: action.ObjectiveTypeStatus.message,
        },
      };
    case portalRatingSettingsTypes.POST_OBJECTIVE_TYPE_FAILED:
      return {
        ...state,
        ObjectiveTypeStatus: {
          type: "error",
          message: action.ObjectiveTypeStatus.message,
        },
      };
    case portalRatingSettingsTypes.GET_OBJECTIVE_TYPE:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.GET_OBJECTIVE_TYPE_SUCCESS:
      const { aspirational, committed, committed_and_aspirational } =
        action.ObjectiveType;

      return {
        ...state,
        ObjectiveType: {
          aspirational: [
            aspirational.low, //aspirational.low
            aspirational.medium,
            aspirational.high
          ],
          committed: [
            committed.low, 
            committed.medium, 
            committed.high,
          ],
          committed_and_aspirational: [
            committed_and_aspirational.low,
            committed_and_aspirational.medium,
            committed_and_aspirational.high,
          ],
        },
        ObjectiveTypeColors: {
          aspirational: {low_color: aspirational.low_color, medium_color: aspirational.medium_color, high_color: aspirational.high_color},
          committed: {low_color: committed.low_color, medium_color: committed.medium_color, high_color: committed.high_color},
          committed_and_aspirational: {low_color: committed_and_aspirational.low_color, medium_color: committed_and_aspirational.medium_color, high_color: committed_and_aspirational.high_color},
        }
      };
    // If aspirational, committed, committed_and_aspirational comes as array we can use below code

    // case actions.GET_OBJECTIVE_TYPE_SUCCESS:
    //   const { aspirational, committed, committed_and_aspirational } =
    //     action.payload;

    //   return {
    //     ...state,
    //     ObjectiveType: {
    //       aspirational,
    //       committed,
    //       committed_and_aspirational,
    //     },
    //   };

    case portalRatingSettingsTypes.GET_OBJECTIVE_TYPE_FAILED:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.POST_SCORE_CARD:
      return {
        ...state,
        scorecardObj: state.scorecardObj,
      };
    case portalRatingSettingsTypes.POST_SCORE_CARD_SUCCESS:
      return {
        ...state,
        ScoreCardStatus: {
          type: "success",
          message: action.ScoreCardStatus.message,
        },
      };
    case portalRatingSettingsTypes.POST_SCORE_CARD_FAILED:
      return {
        ...state,
        ScoreCardStatus: {
          type: "error",
          message: action.ScoreCardStatus.message,
        },
      };
    case portalRatingSettingsTypes.GET_SCORE_CARD:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.GET_SCORE_CARD_SUCCESS:
      return {
        ...state,
        scorecardObj: action.scorecardObj,
      };
    case portalRatingSettingsTypes.GET_SCORE_CARD_FAILED:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.PUT_SCORE_CARD:
      return {
        ...state,
        scorecardObj: state.scorecardObj,
      };
    case portalRatingSettingsTypes.PUT_SCORE_CARD_SUCCESS:
      return {
        ...state,
        ScoreCardStatus: {
          type: "success",
          message: action.ScoreCardStatus.message,
        },
      };
    case portalRatingSettingsTypes.PUT_SCORE_CARD_FAILED:
      return {
        ...state,
        ScoreCardStatus: {
          type: "error",
          message: action.ScoreCardStatus.message,
        },
      };
    case portalRatingSettingsTypes.POST_ASSISTANCE_POINTS:
      return {
        ...state,
        Success: "",
        Failure: "",
        AssistancePointStatus: {
          ...state.AssistancePointStatus,
          success: false,
          failure: false,
        },
      };
    case portalRatingSettingsTypes.POST_ASSISTANCE_POINTS_SUCCESS:
      return {
        ...state,
        Success: action.Success,
        AssistancePointStatus: {
          ...state.AssistancePointStatus,
          success: true,
          failure: false,
        },
      };
    case portalRatingSettingsTypes.POST_ASSISTANCE_POINTS_FAILED:
      return {
        ...state,
        Failure: action.Failure,
        AssistancePointStatus: {
          ...state.AssistancePointStatus,
          success: false,
          failure: true,
        },
      };
    case portalRatingSettingsTypes.GET_ASSISTANCE_POINTS:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.GET_ASSISTANCE_POINTS_SUCCESS:
      return {
        ...state,
        AssistancePoint: action.AssistancePoint,
      };
    case portalRatingSettingsTypes.GET_ASSISTANCE_POINTS_FAILED:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.POST_HELPFULNESS_RATING:
      return {
        ...state,
        // ...action.helpfulness_rating,
        helpfulness_rating: action.helpfulness_rating,
      };
    case portalRatingSettingsTypes.POST_HELPFULNESS_RATING_SUCCESS:
      return {
        ...state,
        Success: action.Success,
        HelpfulnessRatingStatus: {
          ...state.HelpfulnessRatingStatus,
          success: true,
          failure: false,
        },
      };
    case portalRatingSettingsTypes.POST_HELPFULNESS_RATING_FAILED:
      return {
        ...state,
        Failure: action.Failure,
        helpfulness_rating: state.helpfulness_rating,
        HelpfulnessRatingStatus: {
          ...state.HelpfulnessRatingStatus,
          success: false,
          failure: true,
        },
      };
    case portalRatingSettingsTypes.GET_HELPFULNESS_RATING:
      return {
        ...state,
        helpfulness_rating: 0,
      };
    case portalRatingSettingsTypes.GET_HELPFULNESS_RATING_SUCCESS:
      return {
        ...state,
        helpfulness_rating: action.helpfulness_rating,
      };
    case portalRatingSettingsTypes.GET_HELPFULNESS_RATING_FAILED:
      return {
        ...state,
      };
    case portalRatingSettingsTypes.GET_CHECK_VMSB:
      return{
        ...state,
      };
    case portalRatingSettingsTypes.GET_CHECK_VMSB_SUCCESS:
      return{
        ...state,
        vmsbStatus: action.vmsbStatus,
      }
    case portalRatingSettingsTypes.GET_CHECK_VMSB_FAILED:
      return{
        ...state,
      }
    case portalRatingSettingsTypes.CLEAR_RESPONSE_MESSAGE: {
      return {
        ...state,
        confidenceLevelMessage: {
          type: null,
          message: null,
        },
        ObjectiveTypeStatus: {
          type: null,
          message: null,
        },
        ScoreCardStatus: {
          type: null,
          message: null,
        },
        Success: "",
        Failure: "",
        AssistancePointStatus: {
          success: false,
          failure: false,
        },
        HelpfulnessRatingStatus: {
          success: false,
          failure: false,
        },
      };
    }
    default:
      return state;
  }
}
