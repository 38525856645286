import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import store from './redux/store/store';
import AppProvider from './AppProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (!window.location.host.includes('localhost')) {
  Sentry.init({
    dsn: 'https://afe9da8e47384be1ba64223a00c5821b@o4503935362138112.ingest.sentry.io/4503935366791168',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}

ReactDOM.render(
  <BrowserRouter
    getUserConfirmation={() => {
      /* Empty callback to block the default browser prompt */
    }}
  >
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <AppProvider>
        <App />
      </AppProvider>
    </Provider>
    {/* </React.StrictMode> */}
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
