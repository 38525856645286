export enum VmsbOkrCommonTypes {

    POST_SCORECARD = "POST_SCORECARD",
    POST_SCORECARD_SUCCESS = "POST_SCORECARD_SUCCESS",
    POST_SCORECARD_FAILED = "POST_SCORECARD_FAILED",
    
    GET_CAN_ADD_VISION = 'GET_CAN_ADD_VISION',
    GET_CAN_ADD_VISION_SUCCESS = 'GET_CAN_ADD_VISION_SUCCESS',
    GET_CAN_ADD_VISION_FAILED = 'GET_CAN_ADD_VISION_FAILED',
}
