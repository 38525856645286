import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { TooltipTypes } from './actionTypes';
import authActions from '../../app/action';
import {
  getAllTooltipsReq,
  getExecutiveTooltipsReq,
  tooltipFilesReq,
  tooltipUploadReq
} from '../../../library/services/Admin/tooltip';
import { defaultPagination } from '../../../config/constant';

export function* uploadTooltipEffect() {
  yield takeEvery(
    TooltipTypes.TOOLTIP_UPLOAD_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        // yield put(authActions.globalLoaderHandler(true))
        const response = yield call(tooltipUploadReq, payload);
        if (response) {
          yield all([
            put({
              type: TooltipTypes.TOOLTIP_UPLOAD_SUCCESS,
              success: 'File uploaded successfully.'
            }),
            put({
              type: TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_REQUEST
            }),
            put({
              type: TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_REQUEST,
              payload: defaultPagination
            })
          ]);
        }
      } catch (error) {
        yield put({
          type: TooltipTypes.TOOLTIP_UPLOAD_FAILURE,
          errorMessage: error
        });
      }
    }
  );
}

export function* tooltipFilesEffect() {
  yield takeEvery(
    TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(tooltipFilesReq, payload);
        if (response) {
          if (response.data.data.filePath.pagedItems) {
            yield put({
              type: TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_SUCCESS,
              allTooltipFiles: response.data.data.filePath
            });
          } else {
            const file = response.data.data.filePath;
            yield put({
              type: TooltipTypes.CURRENT_FILE,
              currentFile: file[file.length - 1]
            });
          }
        }
      } catch (error) {
        yield put({
          type: TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_FAILURE,
          errorMessage: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* allTooltipsEffect() {
  yield takeEvery(
    TooltipTypes.ALL_TOOLTIPS_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getAllTooltipsReq, payload);
        if (response) {
          yield put({
            type: TooltipTypes.ALL_TOOLTIPS_SUCCESS,
            pageTooltip: response.data.data.value[0].page_tooltip
          });
        }
      } catch (error) {
        yield put({
          type: TooltipTypes.ALL_TOOLTIPS_FAILURE,
          errorMessage: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* executiveTooltipsEffect() {
  yield takeEvery(
    TooltipTypes.EXECUTIVE_TOOLTIPS_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getExecutiveTooltipsReq, payload);
        if (response) {
          yield put({
            type: TooltipTypes.EXECUTIVE_TOOLTIPS_SUCCESS,
            executiveTooltips: response.data.data.value[0].page_tooltip
          });
        }
      } catch (error) {
        yield put({
          type: TooltipTypes.EXECUTIVE_TOOLTIPS_FAILURE,
          errorMessage: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* TooltipSaga() {
  yield all([
    fork(uploadTooltipEffect),
    fork(tooltipFilesEffect),
    fork(allTooltipsEffect),
    fork(executiveTooltipsEffect)
  ]);
}
