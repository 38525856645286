import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const industryTypeReq = () => {
  return ServiceAdminAuthInstance({
    method: 'GET',
    url: adminApiUrls.COMPANY_INDUSTRY
  });
};

export const workspacesReq = () => {
  return ServiceAdminAuthInstance({
    method: 'GET',
    url: adminApiUrls.WORKSPACES
  });
};

export const rolesReq = () => {
  return ServiceAdminAuthInstance({
    method: 'GET',
    url: adminApiUrls.ROLES
  });
};

export const OrgRolesReq = (id) => {
  return ServiceAdminAuthInstance({
    method: 'GET',
    url: `${adminApiUrls.ROLES}/${id}`
  });
};
