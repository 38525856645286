import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import authActions from '../../app/action';
import { subscriptionActionTypes } from './actiontypes';
import {
  EditSubscriptionPlansReq,
  getSubscriptionHistoryReq,
  subscriptionPlanStatusChangeReq,
  subscriptionPlansReq
} from '../../../library/services/Admin/Subscription';
import history from '../../../library/helpers/history';

export function* getSubscriptionPlansEffect() {
  yield takeEvery(subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(subscriptionPlansReq);
      if (response) {
        yield put({
          type: subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS,
          allPlans: response.data.data.getPlanData
        });
        localStorage.setItem('allSubscriptionPlans', JSON.stringify(response.data.data.getPlanData));
      } else {
        yield put({ type: subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_FAILED, errorMessage: response.data.message });
      }
    } catch (error) {
      yield put({ type: subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_FAILED, errorMessage: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* editSubscriptionPlansEffect() {
  yield takeEvery(
    subscriptionActionTypes.EDIT_SUBSCRIPTION_PLAN_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number | boolean> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(EditSubscriptionPlansReq, payload);
        if (response) {
          yield all([
            put({
              type: subscriptionActionTypes.EDIT_SUBSCRIPTION_PLAN_SUCCESS,
              payload: response.data.data
            }),
            put({
              type: subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST
            })
          ]);
          history.push('/superadmin/dashboard/subscription-plans');
        } else {
          yield put({
            type: subscriptionActionTypes.EDIT_SUBSCRIPTION_PLAN_FAILED,
            errorMessage: response.data.message
          });
        }
      } catch (error: any) {
        yield put({ type: subscriptionActionTypes.EDIT_SUBSCRIPTION_PLAN_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getSubscriptionHistoryEffect() {
  yield takeEvery(
    subscriptionActionTypes.SUBSCRIPTION_HISTORY_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getSubscriptionHistoryReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.subscriptionHistory.metaData?.totalPages &&
            payload.page > response.data.data.subscriptionHistory.metaData?.totalPages
          ) {
            yield put({
              type: subscriptionActionTypes.SUBSCRIPTION_HISTORY_REQUEST,
              payload: { ...payload, page: 1 }
            });
          } else {
            yield put({
              type: subscriptionActionTypes.SUBSCRIPTION_HISTORY_SUCCESS,
              subsHistoryList: response.data.data.subscriptionHistory.pagedItems,
              totalSubscriptions: response.data.data.subscriptionHistory.metaData
            });
          }
        } else {
          yield put({
            type: subscriptionActionTypes.SUBSCRIPTION_HISTORY_FAILED,
            errorMessage: response.data.message
          });
        }
      } catch (error) {
        yield put({ type: subscriptionActionTypes.SUBSCRIPTION_HISTORY_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* planStatusEffect() {
  yield takeEvery(
    subscriptionActionTypes.PLAN_STATUS_CHANGE_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(subscriptionPlanStatusChangeReq, payload);
        if (response) {
          yield all([
            put({
              type: subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_REQUEST
            }),
            put({
              type: subscriptionActionTypes.PLAN_STATUS_CHANGE_SUCCESS,
              successMessage: response.data.message
            }),
          ]);
        } else {
          yield put({
            type: subscriptionActionTypes.PLAN_STATUS_CHANGE_FAILED,
            planStatusChangeError: response.data.message
          });
        }
      } catch (error) {
        yield put({ type: subscriptionActionTypes.PLAN_STATUS_CHANGE_FAILED, planStatusChangeError: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* subscriptionSaga() {
  yield all([
    fork(getSubscriptionPlansEffect),
    fork(getSubscriptionHistoryEffect),
    fork(editSubscriptionPlansEffect),
    fork(planStatusEffect)
  ]);
}
