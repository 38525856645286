import { FilterTypes } from './actionTypes';
import { FiltersState, FiltersStateAction } from './types';

const initState: FiltersState = {
  industryList: [
    {
      id: 1,
      name: ''
    }
  ],
  workspaceList: [
    {
      id: 1,
      company_name: ''
    }
  ],
  allOrgRoles: [
    {
      id: 1,
      name: ''
    }
  ],
  defaultRoles: [],
  customRoles: []
};

const defaultRolesModifier = (data, customRoles) => {
  customRoles?.length > 0 && data.push({ id: null, name: 'Other Roles', type: 0, isDisabled: true });

  const updatedData = data.map((item) => {
    if (item.id === null && item.name === 'Other Roles') {
      return {
        ...item,
        custom: customRoles
      };
    }
    return item;
  });
  return updatedData;
};

export default function AdminFiltersReducer(state = initState, action: FiltersStateAction) {
  switch (action.type) {
    case FilterTypes.COMPANY_INDUSTRY_SUCCESS:
      return {
        ...state,
        industryList: action.industryList
      };
    case FilterTypes.WORKSPACES_SUCCESS:
      return {
        ...state,
        workspaceList: action.workspaceList
      };
    case FilterTypes.GET_ROLES_BY_ID_SUCCESS:
      return {
        ...state,
        allOrgRoles: action.allOrgRoles
      };
    case FilterTypes.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        defaultRoles: defaultRolesModifier(action.defaultRoles, action.customRoles),
        // customRoles: action.customRoles
      };
    default:
      return { ...state };
  }
}
