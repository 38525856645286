import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const dashboardActiveDataReq = () => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.ACTIVE_DASHBOARD_DATA,
    method: 'GET'
  });
};

export const scorecardReportReq = (payload?) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.SCORECARD_REPORT,
    method: 'POST',
    data: { ...payload }
  });
};

export const subscriptionReportReq = (payload?) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.SUBSCRIPTION_REPORT,
    method: 'POST',
    data: { ...payload }
  });
};

export const industryReportReq = (payload?) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.INDUSTRY_REPORT,
    method: 'POST',
    data: { ...payload }
  });
};
