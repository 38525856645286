import { StarTypes } from "./StarTypes";
import { StarAction, StarState } from "./types";
let initState: StarState = {
  errorMessage: "",
  starStatus: false,
  successMessage: "",
};
export default function authReducer(state = initState, action: StarAction) {
  switch (action.type) {
    case StarTypes.POST_STAR:
      return {
        ...state,
      };
    case StarTypes.POST_STAR_SUCCESS:
      return {
        ...state,
        errorMessage: "",
        successMessage: "Dashbord report changes",
      };
    case StarTypes.POST_STAR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        starStatus: true,
      };
    case StarTypes.POST_BENEFIT_STAR:
      return {
        ...state,
        errorMessage: "",
      };
    case StarTypes.POST_BENEFIT_STAR_SUCCESS:
      return {
        ...state,
        successMessage: "Dashbord report changes",
        errorMessage: "",
      };
    case StarTypes.POST_BENEFIT_STAR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case StarTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    default:
      return state;
  }
}
