import React from 'react';
import ATag from '../AnchorTag/ATag';
import { GoogleEventObj, googleEventTracker } from '../../config/site.config';

export default function PrivacyAndTerms(props) {
  const onTermsClick = () => {
    googleEventTracker(GoogleEventObj.button_click.name, {
      category: props.pageName + '_terms_and_conditions_click',
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel()
    });
    window.open(`https://www.${process.env.REACT_APP_MARKETING_INSTANCE}.com/terms-and-conditions`, '_blank');
  };
  const onPrivacyClick = () => {
    googleEventTracker(GoogleEventObj.button_click.name, {
      category: props.pageName + '_privacy_policy_click',
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel()
    });
    window.open(`https://www.${process.env.REACT_APP_MARKETING_INSTANCE}.com/privacy-policy`, '_blank');
  };
  return (
    <>
      <p className={props.hide ? 'redirect hide-redirect' : 'redirect'}>
        <ATag
          isButton={true}
          tabIndex={0}
          className=""
          onClick={onTermsClick}
          style={{ fontWeight: 'bold', color: '#22C4F2', cursor: 'pointer', fontSize: '16px' }}
        >
          Terms & Conditions
        </ATag>{' '}
        |{' '}
        <ATag
          className=""
          tabIndex={0}
          isButton={true}
          onClick={onPrivacyClick}
          style={{ fontWeight: 'bold', color: '#22C4F2', cursor: 'pointer', fontSize: '16px' }}
        >
          Privacy Policy
        </ATag>
      </p>
      <p className={props.hide ? 'redirect redirect-margin' : 'redirect'}>
        Your data will be stored on our US Data Center
      </p>
    </>
  );
}
