import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const subscriptionPlansReq = () => {
  return ServiceAdminAuthInstance({
    method: 'GET',
    url: adminApiUrls.SUBSCRIPTION_PLANS
  });
};

export const EditSubscriptionPlansReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.EDIT_SUBSCRIPTION_PLAN,
    data: { ...payload }
  });
};

export const getSubscriptionHistoryReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.SUBSCRIPTION_HISTORY,
    data: { ...payload }
  });
};

export const subscriptionPlanStatusChangeReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'PATCH',
    url: `${adminApiUrls.SUBS_PLAN_STATUS_CHANGE}/${payload.plan_id}`,
    data: payload
  });
};
