import user1 from '../../assets/images/avatar1.png';
import user2 from '../../assets/images/avatar2.png';

// Form validation constants
export const AppConstant: Record<string, any> = {
  FormValidation: {
    firstNameValid: 'Please provide valid first name.',
    lastNameValid: 'Please provide valid last name.',
    emailInvalid: 'Please provide valid email.',
    emailsInvalid: 'Please provide valid emails.',
    passwordValid: 'Password must contain a capital & small letter, number and a special character.',
    firstnameRequired: 'First name is required.',
    lastnameRequired: 'Last name is required.',
    fullnameRequired: 'Full name is required.',
    emailRequired: 'Email is required.',
    passwordRequired: 'Password is required.',
    currentPasswordRequired: 'Current password is required.',
    newPasswordRequired: 'New password is required.',
    cnPasswordRequired: 'Confirm password is required.',
    companyNameRequired: 'Company name is required.',
    objNameRequired: 'Objective name is required.',
    keyResultNameRequired: 'Key Result name is required.',
    discriptionRequired: 'Description is required.',
    objNameValid: 'Please provide valid objective name.',
    keyResultNameValid: 'Please provide valid key result name.',
    taskNameValid: 'Please provide valid task name.',
    taskRequired: 'Task name is required.',
    dateRequired: 'Please select date.',
    dueDateRequired: 'Please select due date.',
    ownerRequired: 'Please select owner.',
    timeRequired: 'Please select time.',
    notesRequired: 'Reminder note is required.',
    sectorRequired: 'Please select sector.',
    industryGroupRequired: 'Please select industry group.',
    subIndustryGroupRequired: 'Please select sub-industry group.',
    managerRequired: 'Please select manager.',
    departmentRequired: 'Department input is required.',
    departmentExists: 'Department is already exists.',
    departmentNotExists: 'Department not found please add.',
    departmentSelectionRequired: 'Please select department or create a new department tag.',
    pwDoNotMatch: 'Two passwords that you entered do not match.',
    foreignRequired: 'Please select foreign obj/kt.',
    benefitRequired: 'Please select benefit.',
    timebox: 'Please select time-box.',
    startPeriod: 'Please select start period.',
    endPeriod: 'Please select end period.',
    acceptTerms: 'Please accept terms and condition.',
    ratingRequires: 'Star rating is required.',
    ratingCommentRequired: 'Employee helpfulness comment is required.',
    portalUrlRequired: 'Portal url is required.',
    comapnyIndustryRequired: 'Company industry is required.',
    timeZoneRequired: 'Time zone is required.',
    assistancePointsRequired: 'Assistance points is required.',
    portalUrlValdi: 'Please provide valid portal url.',
    FinancialYearStartMonthRequired: 'Fiscal year start month is required.',
    OKRPeriodDefinitionRequired: 'OKR period definition is required.',
    GracePeriodRequired: 'Grace period is required.',
    companyNameValid: 'Please provide valid company name.',
    otpRequired: 'Please provide OTP.',
    otpValid: 'OTP must contain 6 digit number.',
    numberOfEmpRequired: 'Please choose the number of employees.',
    optionIsRequired: ' Please choose any one option.',
    explainIsRequired: 'Please explain in brief.',
    scorecardRequired: 'Scorecard total value must be 100%.',
    missionNameRequired: 'Mission name is required.',
    visionNameRequired: 'Vision name is required.',
    visionDescriptionRequired: 'Vision description is required.',
    visionNameValid: 'Please provide valid vision name.',
    missionNameValid: 'Please provide valid mission name.',
    strategyNameValid: 'Please provide valid strategy name.',
    strategyDescriptionRequired: 'Strategy description is required.',
    ScoreCardNameRequired: 'Scorecard name is required.',
    strategyNameRequired: 'Strategy name is required.',
    benefitNameRequired: 'Benefit name is required.',
    benefitDescriptionRequired: 'Benefit description is required.',
    benefitNameValid: 'Please provide valid benefit name.',
    missionDescriptionRequired: 'Mission description is required.',
    noOfPeriodRequired: 'Number of periods is required.',
    validDate: 'Please select valid reminder date.',
    objDiscriptionRequired: 'Objective description is required.',
    krDiscriptionRequired: 'Key result description is required.',
    taskDiscriptionRequired: 'Task description is required.',
    typeIssueRequired: 'Type of issue is required.',
    issueCategoryRequired: 'Issue category is required.',
    messageRequired: 'Message is required.',
    fullNameValid: 'Please provide valid full name.',
    phoneNumberValid: 'Please provide valid phone number.',
    phoneNumberRequired: 'Phone number is required.',
    searchNameRequired: 'Search is required.',
    searchCharValid: 'At least 3 characters must be in the search string.',
    licenseRequired: 'Minimum 1 license should be entered.',
    addressRequired: 'Address is required.',
    cityRequired: 'City is required.',
    stateRequired: 'State is required.',
    countryRequired: 'Country is required.',
    cardHolderNameRequired: 'Card holder name is required.',
    ccRequired: 'Please enter credit card number.',
    invalidCard: 'Please enter valid card number.',
    cvvRequired: 'Please enter cvv.',
    invalidCvv: 'cvv is invalid.',
    expiryRequired: 'Please enter card expiry month and year.',
    subscriptionCancelReason: 'Please select a reason to cancel subscription.',
    subscriptionCancelOtherReason: 'Please Enter the reason to cancel subscription.',
    subscriptionCancelComment:
      'Please provide comments why you are cancelling. This will help us improve our offering to help you better in the future.',
    cancelTipAnnual: "You'll continue to have access to your account until your current billing period.",
    cancelTipMonthly:
      'By clicking Cancel Subscription button plan will be immediately canceled with payment of dues from given card.',
    licenseMaxLimit: 'The maximum number of license allowed is 10000.',
    minLicense: 'No. of license should be at least 1.',
    expYear: 'Exp. year is required.',
    expMonth: 'Exp. month is required.',
    cardAllowsDigits: 'Credit card number must only contain digits and spaces.',
    cardLength: 'Credit card number must be 16 digits long.',
    noCardError: 'No card is verified, please select a plan, then add your credit card info.',
    inviteMoreThanLicenseError:
      'You cannot invite more users than the number of licenses subscribed. Please add more licenses to your subscription before inviting more users. Please go to Profile dropdown > Subscription plan > Manage > Purchase Licenses',
    startDateRequired: 'Start date is required.',
    endDateRequired: 'End date is required.',
    promoNumber: 'Promocode number is required.',
    promocodeLength: 'Promocode is limited to 12 characters.',
    subscriptionPlan: 'Subscription plan is required.',
    subscriptionPlanType: 'Subscription plan type is required.',
    discountRequired: 'Discount is required.',
    maxRedeems: 'Maximum redeem number is required.',

    monthlyAmountRequired: 'Monthly rate could not be empty.',
    annualDiscountRequired: 'Annual discount rate could not be empty.',
    usersCount: 'Please select no of users.',
    okrHierarchy: 'Please select hierarchy level no for OKR.',
    scorecardCount: 'Scorecard could not be empty.',
    deptTags: 'Please enter no of department tags.'
  }
};

// Tooltip constants
export const ToolTipConstant: Record<string, any> = {
  ToolTipTitle: {
    managerField:
      "Please type in your manager's name exactly how it appears on the organization contacts. Lastname, firstname.",
    departmentField:
      'Create or select a department tag. This will be very useful to group your staff under the same department tag and build your org chart quickly.',
    sectorField: 'Please select your sector/ industry per Global industry classification standard.',
    finanicalYear: "Please enter your company's fiscal year start month.",
    finanicalStartMonth:
      "Please enter your organization's official fiscal year start month. Please note, once setup, it cannot be changed. All OKR period calculation will depend on the organization's fiscal year start month.",
    OKRPeriodDefination:
      'Define the OKR period. Per best practice, a OKR period is 3 months and will be the default value. However, you can choose 1 month or 2 month periods. NOTE: Once you define an OKR period, this cannot be changed.',
    OKRPeriodDefinationField:
      'The OKR period definition is the duration of 1 OKR per best practice, 1 OKR period is 3 months or 1 quarter. It is set by DEFAULT. You can select shorter or longer duration. However once confirmed, it cannot be changed. Users can select multiple periods for each Vision, Mission, Strategy, Benefit, or Objective. ',
    gracePeriod:
      'Grace period can be set up to 5 days after a OKR period ends. This will allow the users to close out their tasks, objects and key results, in case they are not able to do so at the last day of the OKR period.',
    gracePeriodFinanicalYear:
      'A grace period allows a user to close off all the app components like tasks, Key Results, Objectives, benefits, strategy, mission and vision a few days (grace period) after the official end date.',
    statusColumn: 'The average status of my Objective as rolled up from child results.',
    listView: 'List View',
    karbanView: 'Kanban View',
    globalView: 'Global View',
    globalViewSearch:
      'Please type vision, mission, strategy, benefit, objectives, key result, tasks names or description to search.  You can use coma or semi-colon to seperate search items.  ',
    globalViewOrgSearch: 'Please type user or member names. You can use coma or semi-colon to seperate search items.',
    aspirationType: 'Aspirational Objective',
    commitedType: 'Committed Objective',

    //Portal settings tab
    portalUrl: "Please bookmark this URL as your organization's Target Align OKR App URL.",

    //TODO: when add MSB scorecard tooltip add s in items compare tooltip with objective tooltip
    starIcon:
      'By clicking the star, the objective will be included into the top ranked dashboard reporting and will be given special attention during reviews.',
    editIcon: 'Edit',
    deleteIcon: 'Delete',

    //Common OKR-VMSB tooltip
    notes:
      "Please enter your working notes here. As a best practice, type your latest notes at the top, so you don't have to scroll down to find them.",
    comments:
      'Communicate with other users using the comments or chat tool here. Use @mention to send the user an email.',

    //Vision screens tooltip
    visionName:
      'The vision statement describes the desired future position of the company. Vision statement format, "Verb + future state of being." Think big, be positive and be inspirational.',
    visionIntegratedStatus:
      'The "integrated status" of this vision is the average of all the mission under the same vision.',
    visionDescription: 'Please enter more details on this vision.',
    visionOtherNotifies: 'Please select one or more persons to be notified when the vision changes.',

    //Objective screens tooltip
    objectiveName: 'Format: Verb + what are you going to do + in order to/so that to obtain business value.',
    objectiveDescription: 'Please provide more information related to this objective.',
    objectiveParentObjKr: 'Select the parent objective or parent key result this objective will align to.',
    objectiveAspirationalType:
      'Aspirational objectives are objectives with ambitious goals. If you miss, you still have achieved something remarkable.',
    objectiveCommittedType:
      'Committed objectives are objectives the individual, team or organization has agreed to achieve.',
    objectiveTeamOrIndividual:
      'Although there is a single owner of an OKR objective, you can select "team" to indicate this objective is NOT your personal objective, but rather, a team objective. However, as the owner of the objective, you are responsible for the success of this objective.',
    objectiveScoreCard:
      'What business strategy is this objective aligned to? Select the scorecard category(the business strategy) this objective is aligned to. You can choose more than one, but it must add up to 100%. The scorecard with the highest % is the major strategy you are aligned to.',
    objectiveConfidenceLevel: 'Select from 1-10 how confident you are in meeting the objective.',
    objectivePriority: 'Please select the priority of this objective. By DEFAULT, it is set to MEDIUM.',
    objectiveNativeStatus:
      'The "native status" of this objective is the average of all the key results under this objective only. It does not include any aligned child objectives.',
    objectiveIntegratedStatus:
      'The "Integrated status" of this objective is the average of all native key results and aligned child objectives.',
    objectiveIsAlignedTo: 'Please select the parent key result or parent objective to align to.',
    objectiveChildAlignedTo:
      'Here is the list of child objectives aligned to this objective. Click the child objective to review.',
    objectiveKRList: 'Key results of this objective.',
    objectiveTimeBox:
      'Please select 1 period or up to 1 year for your objective duration. Per best practice, you should select 1 period only for each objective. You are allowed to have a objective with a duration for up to 1 year or the number of periods left in a linked up benefit as long as the number of benefit periods left are less than 1 year.',
    objectivePauseIcon:
      'By clicking the pause button the status of this objective will not be rollout up to the OKR or benefits it is linked up to.',

    //Key result screens tooltip
    krName:
      'Metric key result. Format: Increase or decrease <a metric> from X to Y. The key result could also be a "milestone" key result. Format: To reach or complete <target> by <date>. As a best practice, make sure there is a follow-up metric-related key result after completing the milestone key result.',
    krTimeBox:
      'Please select a period for this key result. By best practice, a key result has a duration of 1 period even if the objective could be multiple periods. You can select a current or future period for this key result. Upon selection, this key result will be displayed in the selected time period.',
    krPriority: 'Please select the priority of this key result. By DEFAULT, it is set to MEDIUM.',
    krIsAlignedTo:
      'Here is the list of child objectives aligned to this key result. Click the child objective to review.',
    krNativeStatus:
      'The "native status" of this key result will be added and averaged with the other key result under the same objective.',
    krIntegratedStatus:
      'The "Integrated status" of this key result is average of the native status of this key result the aligned child objectives, when applicable.',
    krPauseIcon:
      'By clicking the pause button, this key result status will not be rolled up to its parent objective. You can click the pause button again to un-pause it.',

    //Task screens tooltip
    taskObjectiveName: 'Parent objective of this task.',
    taskKRName: 'Parent Key Result of this task.',
    taskStage:
      "When completed, please change the status to closed. You will receive assistance points for completing the task. If this task is not manually closed, the task will automatically close by end of the OKR period's grace period, but the task assignee will not receive the assistance points.",
    taskRating:
      'Please rate how well this task was performed by the task assignee upon task closure. The task assignee can view this feedback in their personal profile anonymously.',
    taskComments:
      'Please provide comments on how well this task was performed by the task assignee upon task closure. The task assignee can view this feedback in their personal profile anonymously.',
    taskOwner:
      'Select the owner of this task. The task is default to the key result owner. However, you can assign the task to another user. The task assignee other than yourself will be rewarded assistance points for complete the task. The task assigner can also rate the task assignee helpfulness on the task upon task completion.',

    //Mission screens tooltip
    missionName:
      'The mission statement defines the company’s business, its objective, and how to reach them? As different from a Vision statement, a Mission statement talks about the purpose of user company’s existence, the direction, and the activities to achieve that purpose. Mission statement format, "What user do + Whom user serve + How user do it + user reason for doing it".',
    missionDescription: 'Please enter more details on this mission.',
    MissionScoreCard:
      'Please select a scorecard category this mission is related to.You can select one scorecard item at 100% or multiple scorecard item adding to 100%.',
    addNewStrategy: 'Please add strategies to this mission.',
    missionIntegratedStatus:
      'The "integrated status" of this mission is the average of all the strategies under the same mission.',
    missionOtherNotifies: 'Please select one or more persons to be notified when the mission changes.',

    //Strategy screens tooltip
    strategyName:
      'A strategy describes “a plan of action or policy designed to achieve a major or overall aim.” Strategy format, "Verb + the action plan/ policy".',
    startegyDescription: 'Please enter more details on this strategy.',
    StrategyScoreCard:
      'Please select a scorecard category this strategy is related to.You can select one scorecard item at 100% or multiple scorecard item adding to 100%.',
    addNewbenefit: 'Please add benefits to this strategy.',
    strategyIntegratedStatus:
      'The "integrated status" of this strategy is the average of all the benefits under the same strategy.',
    strategyOtherNotifies: 'Please select one or more persons to be notified when the strategy changes.',

    //benefit screens tooltip
    benefitName:
      'A benefit is the direct result or outcome of executing a strategy. The key point is NOT the action, but the outcome the strategy is trying to achieve.',
    benefitDescription: 'Please enter more details on this benefit.',
    benefitScoreCard:
      'Please select a scorecard category this benefit is related to.You can select one scorecard item at 100% or multiple scorecard item adding to 100%.',
    addNewobjective: 'Please add objectives to this benefit.',
    benefitIntegratedStatus:
      'The "integrated status" of this benefit is the average of all the objectives under the same benefit.',
    benefitOtherNotifies: 'Please select one or more persons to be notified when the benefit changes.',

    //scorecard status tooltips
    scorecardStatusTitle:
      'On this page, user can review where user is at by VMSB or objectives and how user is progressing by scorecard category, e.g. improve revenue, BAU maintenance etc.',
    scorecardCategory: 'Select the VMSB component to review.',
    vmsbTitle: 'Select all the VMSB components or a single VMSB component.',
    period: 'Select the period user wants to review.',
    objectiveType: 'User can select committed, or aspirational objectives or both objective types',
    scorecardCategoryHeader:
      'The scorecard column groups the selected VMSB components bearing the major scorecard category.',
    scorecardStatusHeader:
      'The status is the average status of all the VMSB components grouped by the scorecard category.',
    scorecardNumberHeader: 'The number of VMSB components grouped by the scorecard category.',
    scorecardHighest: 'The OKR with the hightest achieved status.',
    scorecardLowest: 'The OKR with the lowest achieved status.',
    scorecardOKR: 'Number of OKR(s) on the selected VMSB component by scorecard category.',
    scorecardOwners: 'The benefits owner(s) that own the delivery of the benefits under this scorecard category.',

    //Kanban view tooltip
    benefitLinkedTo: 'My OKRs that are linked to benefits.',
    objLinkedTo: 'Foreign Objectives my OKRs are linked to.',
    krLinkedTo: 'Foreign Key Results my OKRs are linked to.',
    objNotLinkedTo: 'My independent OKRs that are not linked to any other OKRs or benefits.',
    tasks: 'Tasks I am assigned to.',

    taskName: 'Describe a task.',
    timeBox:
      'Please select 1 period or up to 1 year for your objective duration. Per best practice, you should select 1 period only for each objective. You are allowed to have an objective with a duration for up to 1 year or the number of periods left in a linked up benefit as long as the number of benefit periods left are less than 1 year.',
    BenefitNative:
      'The status of the benefit with calculated only native objectives Status of this benefit as calculated from its objectives only.',
    VisionNative:
      'The status of the vision with calculated only native missions Status of this vision as calculated from its missions only.',
    nativeStatus: 'The native status of this objective is the average of the key results status of this objective.',
    integratedStatus:
      "The integrate status of this objective is the average of the key results within this objective, plus the child objectives that is linked to this objective and/or this objective's key results.",
    removeForeginOKR:
      'You are attempting unlink a child OKR from a parent OKR.  Once unlinked, parent OKR will lose inherited integrated status from child.  Do you want to continue?',
    ForeginLinkedOKR:
      'Please click the dropdown box to select a parent OKR to link up to.  You can change your parent OKR link at anytime during an active session.  Once link up is established and period has passed, you cannot change the link.',
    addNewKr:
      'Please add Key Results to this objective. By best practice, Key Result (KR) should 1) contain a "from" and "To" metric. It could be an increase, decrease or what the final metric you need to reach. or 2) a "date" when the KR will be achieved. If a "date KR" used, this date must be within the limits of the timebox of this KR. If the KR is longer than the current timebox, consider breaking the KR to smaller KRs.',
    addNewBenefit: 'Please add benefits to this Strategy.',
    addNewObjective: 'Please add objective to this Benefit.',

    addkr: 'Go To Add Key Results',
    addobj: 'Go To Add Objective',
    addtask: 'Go To Add Task',
    addstrategy: 'Go To Add Strategy',
    addbenefit: 'Go To Add Benefit',
    addmission: 'Go To Add Mission',

    //dashboard screen tooltips
    OKRGraph:
      'This OKR graph illustrates how are OKRs performed over time based on the master filters selected at the top.',
    EmpHelpfulness:
      'This dashboard displays the employee helpfulness which is the accumulated average star rating and points accumulated for the fiscal year to date.  It is not affected by the global filter at the top.',
    ScorecardHeatmap:
      'This dashboard displays the scorecard heat map graph as a month to month scorecard performance of the VMSB-OKR selected by the global filter at the top.',
    Departments:
      'This dashboard displays VMSB-OKR status based on department.  The department here is selected by global filter at the top.',
    TopBenefits:
      'This dashboard displays the benefits status based on period selected at the global filter at the top.  Click the star on the left to sort the benefits users deemed important and put a star next to it.',
    TopOKRs: 'This dashboard lists the individual OKR status based on the global filter at the top.',

    //subscription
    annualRate: 'The rates for annual time period will be calculated in the range of multiple of 5 licenses.',
    country:
      'Only one country is permitted for testing purpose as we are using Stripe. We can enable this when we move on production.',
    monthlyRate: 'This amount is for remaining days in current month.',
    totalLicense: 'You will be charged for the range of active licenses at the time of renewal.',
    cancelSubscription:
      'You have already cancelled your subscription. You can reactivate your subscription from Manage -> Select Plan.'
  }
};

// Regx Constants
export const OtpRegex = /^[0-9]{6}$/;
export const NameRegex = /^(?!\s+$)[a-zA-Z0-9 \-.'`]*$/;
export const PhoneNumberRegex = /^\+[1-9]\d{10,14}$/;
export const countryCodeRegex = /^\+[1]$/;
export const OKRNameRegex = /^(?!\s+$)[a-zA-Z0-9 \-.'/;_!@#$%^&*(),.?":=+`‘~{}|<>]*$/;
export const PasswordStrengthRegex =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_]).{8,}$/; //eslint-disable-line

export const emailValidationRegex = /^\w+([\.+]*?\w+[\+]*)@\w+(\w+)(\.\w{2,3})+$/; //eslint-disable-line
// Comments Constants
export const mentionsRegexp = /@+\S+\s+\S+\s/g;
export const emailRegexp = /@+\S+@\S+\.\S+/g;
export const mentionUsersRegexp = /@+\w+\s+\w+\s/g;
export const ccRegexp = /(?:\d[ -]*?){13,16}/;

export const cvvRegex = /^[0-9]{3,4}$/;
// My Goles Listview constants
export const myGolePriority = ['low', 'medium', 'high'];
export const myGoleType = ['other', 'co', 'ao'];
export const subscriptionPlans = ['', 'Basic', 'Standard', 'Premium'];
export const dateFormatList = 'DD MMM, YYYY';
export const dateTimeFormat = 'DD MMM, YYYY HH:mm:ss';
export const plansCustomOrder = ['Free', 'Basic', 'Standard', 'Premium'];
export const maxLicense = 10000;
export const roleAdmin = 1;
export const fName = 'first_name';
export const lName = 'last_name';
export const email = 'created_by';
export const card = {
  savedCard: 'cardSaved',
  cardDetail: 'card'
};
export const pageSizeOptions = ['3', '5', '10', '15'];
export const defaultPagination = { page: 1, take: 10 };

export const subscriptionHistoryPayload = { page: 1, take: 3 };

export const vmsbRoutes = [
  'vmsb',
  'addvision',
  'viewvision',
  'editvision',
  'addmission',
  'viewmission',
  'editmission',
  'addstrategy',
  'viewstrategy',
  'editstrategy',
  'addbenefit',
  'viewbenefit',
  'editbenefit',
  'vmsb-detail'
];

export const AssistancePoints = [1, 5, 10];
export const ratingValue = [5, 10];
export const takeSubscriptionError =
  'Currently no subscription plan is active. To continue to use feature, take subscription. ';
export const okrUser = '3';
export const freePLan = 1;
export const monthInYear = 12;
export const daysInMonth = 30;
export const timePeriod = {
  monthly: 1,
  annually: 2
};
export const planStatus = {
  active: 1,
  in_active: 0
};
export const usersStatus = {
  active: 'active',
  inactive: 'inactive',
  invited: 'invited',
  pending: 'pending',
  deleted: 'deleted'
};
export const plan = {
  premium: 3,
  standard: 2,
  basic: 1,
  free: null
};

export const planDisableWarning =
  'You cannot disable all the plans, if you wish to disable this plan you must enable any other plan.';

// Hold and Resume Object and Key-Result Constants
export const holdObjKRData = {
  holdObjTitle: 'You are about to put this Objective on hold.',
  holdKRTitle: 'You are about to put this key result on hold.',
  resumeObjTitle: 'You are about to resume this Objective.',
  resumeKRTitle: 'You are about to resume this key result.',
  holdObjBody:
    'This objective status, its linked KR status and foreign linked OKRs will not be rollout up to the Benefit calculation. This will also send an email notification to the Benefits, Strategy and foreign OKR owners.',
  holdKRBody:
    'This key result status, its linked KR status and foreign linked OKRs will not be rollout up to the Benefit calculation. This will also send an email notification to the Benefits, Strategy and foreign OKR owners.',
  resumeObjBody:
    'This objective status, its linked KR status and foreign linked OKRs will be rollout up to the Benefit calculation. This will also send an email notification to the Benefits, Strategy and foreign OKR owners.',
  resumeKRBody:
    'This key result status, its linked KR status and foreign linked OKRs will be rollout up to the Benefit calculation. This will also send an email notification to the Benefits, Strategy and foreign OKR owners.',
  holdObjKRQue: 'Do you want to continue?',
  holdObjMessage:
    'This objective is on hold. Status of this objective will not be rolled up the linked OKR or benefits. To undo the action, click the Play button.',
  holdKRMessage:
    'This key result is on hold. Status of this key result will not be rolled up the linked OKR or benefits. To undo the action, click the Play button.',
  ToolsSettingMessage:
    "To use all of the system's features, you must fill all of the required fields in Portal Settings, Organization Fiscal Year, and Assistance Points.",
  configWithNoSubscriptionMessage: 'After the configuration settings are completed, purchase a subscription plan.'
};

export const OKREditOrNot = {
  ObjMessage: 'You can not edit this objective, as you are not the creator of this objective.',
  KeyMessage: 'You can not edit this key result, as you are not the creator of this key result.',
  TaskMessage: 'You can not edit this task, as you are not the creator or owner of this task',
  VisionMessage: 'You can not edit this vision, as you are not the creator of this vision.',
  MissionMessage: 'You can not edit this mission, as you are not the creator of this mission.',
  Strategymessage: 'You can not edit this strategy, as you are not the creator of this strategy.',
  BenefitMessage: 'You can not edit this benefit, as you are not the creator of this benefit.'
};

export const pendingPaymentAlertMsg =
  'You have not finished paying for your purchased subscription plan. Please pay now.';

export const planCancelledAlert = 'Your subscription plan has been cancelled.';
export const nonAdminLoginAlert =
  'Your organization has not yet subscribed to any plans. Contact your admin for more details.';
export const licenseInputLabel = 'How many users are in your team?';
export const alreadySavedPaymentDetail = 'Admin info is already stored';
export const userDowngradeSuccessMsg = {
  monthlyPlan: 'User is now deactivated. Deactivated users will be removed from subscription plan starting next month.',
  annualPlan: 'User is now deactivated.'
};

export const holdMSBData = {
  holdMissionTitle: 'You are about to put this Mission on hold.',
  holdBenefitTitle: 'You are about to put this Benefit on hold.',
  holdStrategyTitle: 'You are about to put this Strategy on hold.',

  resumeMissionTitle: 'You are about to resume this Mission.',
  resumeBenefitTitle: 'You are about to resume this Benefit.',
  resumeStrategyTitle: 'You are about to resume this Strategy.',

  holdMissionBody:
    'This mission status, its linked strategy status will not be rollout. This will also send an email notification to the vision owner.',
  holdBenefitBody:
    'This benefit status, its linked OKR status will not be rollout up to the mission calculation. This will also send an email notification to the vision, mission, strategy owners.',
  holdStrategyBody:
    'This strategy status, its linked benefit and OKR status will not be rollout up to the mission calculation. This will also send an email notification to the vision, mission owners.',

  resumeMissionBody:
    'This mission status, its linked strategy status will be rollout. This will also send an email notification to the vision owner.',
  resumeBenefitBody:
    'This benefit status, its linked OKR status will be rollout up to the mission calculation. This will also send an email notification to the vision, mission, strategy owners.',
  resumeStrategyBody:
    'This strategy status, its linked benefit and OKR status will be rollout up to the mission calculation. This will also send an email notification to the vision, mission owners.',

  holdMissionMessage:
    'This mission is on hold. Status of this mission will not be rolled up. To undo the action, click the play button.',
  holdBenefitMessage:
    'This benefit is on hold. Status of this benefit will not be rolled up. To undo the action, click the play button.',
  holdStrategyMessage:
    'This strategy is on hold. Status of this strategy will not be rolled up. To undo the action, click the play button.',
  holdMSBQue: 'Do you want to continue?'
};

export const holdValue = {
  viewobjective: 0,
  viewkey: 1,
  viewmission: 3,
  viewstrategy: 4,
  viewbenefit: 5,
  Mission: 3,
  Strategy: 4,
  Benefit: 5
};

export const StarBenefitData = {
  starBenefitTitle: 'You are about to add this objective to the dashboard for reporting.',
  resumeStarBenefitTitle: 'You are about to resume this objective to the dashboard for reporting.',
  starBenefitBody:
    'By adding this to the dashboard, it will push up the priority listing of this objective despite the completion status.',
  resumeStarBenefitBody:
    'By adding this to the dashboard, it will push up the priority listing of this objective despite the completion status.',
  starBenefitQue: 'Do you want to continue?'
};

export const deleteVMSB = {
  deleteVisionTitle: 'Delete Vision?',
  deleteMissionTitle: 'Delete Mission?',
  deleteBenefitTitle: 'Delete Benefit?',
  deleteStrategyTitle: 'Delete Strategy?',

  deleteVisionBody: 'You are about to delete the vision. The delete will be permanent. Do you want to continue?',
  deleteMissionBody: 'You are about to delete the mission. The delete will be permanent. Do you want to continue?',

  deleteBenefitBody: 'You are about to delete the benefit. The delete will be permanent. Do you want to continue?',

  deleteStrategyBody: 'You are about to delete the strategy. The delete will be permanent. Do you want to continue?'
};

// delete objective and delete key result data messages
export const deleteObjKeyResult = {
  deleteObjTitle: 'Delete Objective?',
  deleteObjBody: 'You are about to delete the objective. The delete will be permanent. Do you want to continue?',
  deleteKRTitle: 'Delete Key Result?',
  deleteKRBody: 'You are about to delete the key result. The delete will be permanent. Do you want to continue?',
  deleteTaskTitle: 'Delete Task?',
  deleteTaskBody: 'You are about to delete the task. The delete will be permanent. Do you want to continue?'
};

export const deletePromoCodeMsg = {
  deletePromoTitle: 'Delete Promo code?',
  deletePromoBody: 'You are about to delete the promocode. The delete will be permanent. Do you want to continue?'
};

export const filterPayload = {
  filter_data: {
    period: {},
    pagination: {},
    sort: {}
  },
  navigation_menu: 2
};

export const StarData = {
  starObjTitle: 'You are about to add this objective to the dashboard for reporting.',
  resumeStarObjTitle: 'You are about to remove this objective from the dashboard for reporting.',
  starObjBody:
    'By adding this objective to the dashboard, it will push up the priority listing of this objective despite the completion status.',
  resumeStarObjBody:
    'By removing this objective from the dashboard, it will remove the priority listing of this objective despite the completion status.',
  starObjKRQue: 'Do you want to continue?',

  starBenefitTitle: 'You are about to add this Benefit to the dashboard for reporting.',
  unStarBenefitTitle: 'You are about to remove this Benefit from the dashboard for reporting.',
  starBenefitBody:
    'By adding this benefit to the dashboard, it will push up the priority listing of this benefit despite the completion status.',
  unStarBenefitBody:
    'By removing this benefit from the dashboard, it will remove the priority listing of this benefit despite the completion status.',
  otherOkrUser: 'This Okr belongs to other user.',
  otherBenefitUser: 'This benefit belongs to other user.'
};

export const addVisionConstant = {
  addVisionModalTitle: 'You are about to save a new vision.',
  addVisionModlBody:
    'Once you determine the duration of the Vision, all downstream components including Mission, Stratregy, Benefit and OKRs that linked to this vision, the duration cannot be changed. Do you want to continue?',
  editVisionModalTitle: 'You are about to edit a vision.'
};

export const addBenefitConstant = {
  addBenefitModalTitle: 'You are about to save a new benefit.',
  addBenefitModlBody: 'Do you want to continue?',
  editBenefitModalTitle: 'You are about to edit a benefit.'
};
export const OrganizationPopupConstant = {
  confirmOrgSettingTitle: "Confirm your company's fiscal year and OKR period",
  confirmOrgSettingBody:
    "You are about to confirm your company's fiscal year start month and OKR period(duration). For OKR period, the best practice is 3 months. Once confirmed, this cannot be changed further.",
  confirmOrgSettingProceed: 'Do you want to proceed?'
};

export const scoreCardCategoryConstant = {
  confirmSCTitle: 'Confirm score card category',
  confirmSCBody: 'You are about to change the name of the scorecard custom category.',
  confirmSCProceed: 'Do you want to continue?'
};

// Month abbreviation constants
export const monthAbbreviation = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const pathConstant = [
  { pathName: 'viewvision', moduleId: 2, name: 'Vision' },
  { pathName: 'viewmission', moduleId: 3, name: 'Mission' },
  { pathName: 'viewstrategy', moduleId: 4, name: 'Strategy' },
  { pathName: 'viewbenefit', moduleId: 5, name: 'Benefit' },
  { pathName: 'viewobjective', moduleId: 6, name: 'Objective' },
  { pathName: 'viewtask', moduleId: 7, name: 'Task' },
  { pathName: 'viewkey', moduleId: 8, name: 'Key Result' }
];

export let getTypeFilter = {
  vision: 'get_vision_comments',
  mission: 'get_mission_comments',
  benefit: 'get_benefit_comments',
  strategy: 'get_strategy_comments'
};

export const teamIndividualobjective = {
  individual: 1,
  team: 2
};

export const PostPeriodConst = {
  OBJ: 1,
  KEY: 2,
  BEN: 3
};

export const MonthsList = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
};

export const PeriodDefinationList = {
  1: '1 month',
  2: '2 months',
  3: '3 months'
};

export const GracePeriod = {
  1: '1 day',
  2: '2 days',
  3: '3 days',
  4: '4 days',
  5: '5 days'
};

export const rolesModule = {
  1: 'Configuration',
  2: 'Vision',
  3: 'Mission',
  4: 'Strategy',
  5: 'Benefit',
  6: 'OKR',
  7: 'Tasks'
};

export const taskStatus = {
  0: 'Open',
  1: 'Closed',
  2: 'Reopen',
  3: 'Archived'
};

export const scoreCardCategoryColor = {
  1: '#2967E0',
  2: '#F91A78',
  3: '#10DDDD',
  4: '#5FD815',
  5: '#49C892',
  6: '#F24D4D',
  7: '#E01DCD',
  8: '#4438CF',
  9: '#CF1F9E',
  10: '#E0E403'
};

export const calendarCategory = [
  { id: 2, name: 'Vision', color: '#32386A', prefix: 'VSN', page: 'vision', disabled: true },
  { id: 3, name: 'Mission', color: '#FFA723', prefix: 'MSN', page: 'mission', disabled: true },
  {
    id: 4,
    name: 'Strategy',
    color: '#476CD2',
    prefix: 'STR',
    page: 'strategy',
    disabled: true
  },
  { id: 5, name: 'Benefits', color: '#1FB4B4', prefix: 'BEN', page: 'benefit', disabled: true },
  {
    id: 6,
    name: 'Objectives',
    color: '#FB9A99',
    prefix: 'OBJ',
    page: 'objective'
  },
  { id: 8, name: 'Key Results', color: '#ECCE37', prefix: 'KR', page: 'key' },
  { id: 7, name: 'Tasks', color: '#00B95D', prefix: 'TSK', page: 'task' }
];

export const allModules = [
  { title: 'Vision Mission Strategy and Benefits  Module', name: 'vmsb', key: 'vmsb' },
  { title: 'Google + Microsoft Sign on', name: 'socialSignIn', key: 'social_login_sign_on' },
  { title: 'OKR cycle (Periods)', name: 'okrCyclePeriod', key: 'okr_cycle_period' },
  {
    title: 'Employee Helpfulness / Reward & point system',
    name: 'helpfulnessRating',
    key: 'employee_helpfulness_reward_point'
  },
  { title: 'Confidence Measurements', name: 'confidence', key: 'confidence_measurement' },
  { title: 'Decision Making assistance', name: 'decisionMakingAssistance', key: 'decision_making_assistance' },
  { title: 'List View', name: 'listView', key: 'list_view' },
  { title: 'Kanban View', name: 'kanbanView', key: 'kanban_view' },
  { title: 'Global view', name: 'globalView', key: 'global_view' },
  { title: 'OKR heat map', name: 'okrHeatMap', key: 'okr_heat_map' }
];

export const allSubscriptionModules = [
  { title: 'Google + Microsoft Sign on', key: 'social_login_sign_on' },
  { title: 'List View', key: 'list_view' },
  { title: 'OKR Hierarchy Level', key: 'okr_hierarchy_level' },
  { title: 'Confidence Measurements', key: 'confidence_measurement' },
  { title: 'OKR cycle (Periods)', key: 'okr_cycle_period' },
  { title: 'No of Department Tags', key: 'no_of_dep_tags' },
  { title: 'Vision Mission Strategy and Benefits  Module', key: 'vmsb' },
  { title: 'Kanban View', key: 'kanban_view' },
  { title: 'No of Scorecards', key: 'scorecard' },
  {
    title: 'Employee Helpfulness / Reward & point system',
    key: 'employee_helpfulness_reward_point'
  },
  { title: 'No of Users', key: 'no_of_users' },
  { title: 'Decision Making assistance', key: 'decision_making_assistance' },
  { title: 'Global view', key: 'global_view' },
  { title: 'OKR heat map', key: 'okr_heat_map' }
];

export const promoCodeApplicable = [
  { id: 1, name: 'New Workspace', state: true },
  { id: 2, name: 'Pre Existing Workspace', state: false }
];

export const userRoles = [
  { id: 1, name: 'Org. Admin' },
  { id: 2, name: 'Executive' },
  { id: 3, name: 'OKR User' },
  { id: 4, name: 'Manager' },
  { id: 5, name: 'Associate' }
];

export const industryTypeOptions = [
  { id: 1, title: 'Medical' },
  { id: 2, title: 'Finance' },
  { id: 3, title: 'Education' },
  { id: 4, title: 'Chemical' },
  { id: 5, title: 'Computer Science' },
  { id: 6, title: 'Food' },
  { id: 7, title: 'Production' }
];

export const subscriptionPlanTypes = [
  { id: 1, type: 'Monthly' },
  { id: 2, type: 'Annually' }
];

export const planTypes = [
  { id: 1, type: 'Monthly' },
  { id: 2, type: 'Annually' },
  { id: null, type: 'Both' }
];

export const subscriptionPlanOptions = [
  { id: null, type: 'Free' },
  { id: 1, type: 'Basic' },
  { id: 2, type: 'Standard' },
  { id: 3, type: 'Premium' }
];

export const noOfUsersOptions = {
  10: '10',
  50: '10 - 50',
  100: '50 - 100',
  null: '100+'
};

export const dateRangeOptions = [
  { id: 'current', title: 'Current Month' },
  { id: 'lastMonth', title: 'Last Month' },
  { id: 'last3Month', title: 'Last 3 Month' },
  { id: 'last6Month', title: 'Last 6 Month' },
  { id: 'lastYear', title: 'Last Year' },
  { id: 'custom', title: 'Custom range' }
];

export const subscriptionPlanStatusOptions = [
  { id: 1, type: 'Active' },
  { id: 0, type: 'Inactive' }
];
export const transactionStatusOptions = [
  { id: 1, type: 'Successful' },
  { id: 0, type: 'Unsuccessful' }
];
export const freePlanOptions = [
  { id: 1, type: 'Included' },
  { id: 2, type: 'Not Included' }
];
export const WorkspaceOptions = [
  { id: 1, type: 'New', comment: 'Created Within 30 days' },
  { id: 2, type: 'Old', comment: 'Created Older than 30 days' }
];

export const userAddedByOptions = [
  { id: 1, type: 'Workspace Admin' },
  { id: 2, type: 'Other' }
];

export const MonthlyRate = {
  PLAN1: 7,
  PLAN2: 9,
  PLAN3: 12
};

export const AnnuallyRate = {
  PLAN1: 6,
  PLAN2: 8,
  PLAN3: 10
};

export const plans = [
  {
    id: null,
    name: 'Free Trial',
    monthlyRate: 0,
    annuallyRate: 0
  },
  {
    id: 1,
    name: 'Basic',
    monthlyRate: 7,
    annuallyRate: 6
  },
  {
    id: 2,
    name: 'Standard',
    monthlyRate: 9,
    annuallyRate: 8
  },
  {
    id: 3,
    name: 'Premium',
    monthlyRate: 12,
    annuallyRate: 10
  }
];

export const otherNotifiesUser = [
  {
    userImg: user1,
    userName: 'Ollie Shorts'
  },
  {
    userImg: user2,
    userName: 'Vivian Lincoln'
  }
];
export const commentList = [
  {
    user: user1,
    posted_by: {
      first_name: 'smith',
      last_name: ''
    },
    first_name: 'smith',
    last_name: '',
    comment_date: '15 August, 4:44 PM',

    description:
      ' Nunc donec egestas duis cras lacus. Elementum, nibh dolor feugiat platea malesuada. Tincidunt dui, venenatis nunc vestibulum aliquet at quis. Odio a feugiat risus phasellus.'
  },
  {
    user: user2,
    posted_by: {
      first_name: 'smith',
      last_name: ''
    },
    first_name: 'you',
    last_name: '',
    comment_date: '15 August, 4:44 PM',
    description:
      ' Nunc donec egestas duis cras lacus. Elementum, nibh dolor feugiat platea malesuada. Tincidunt dui, venenatis nunc vestibulum aliquet at quis. Odio a feugiat risus phasellus.'
  },
  {
    user: user1,
    posted_by: {
      first_name: 'smith',
      last_name: ''
    },
    first_name: 'smith',
    last_name: '',
    comment_date: '5 hour ago',
    description:
      ' Nunc donec egestas duis cras lacus. Elementum, nibh dolor feugiat platea malesuada. Tincidunt dui, venenatis nunc vestibulum aliquet at quis. Odio a feugiat risus phasellus.'
  },
  {
    user: user2,
    posted_by: {
      first_name: 'smith',
      last_name: ''
    },
    first_name: 'smith',
    last_name: '',
    comment_date: '15 August, 4:44 PM',
    description:
      ' Nunc donec egestas duis cras lacus. Elementum, nibh dolor feugiat platea malesuada. Tincidunt dui, venenatis nunc vestibulum aliquet at quis. Odio a feugiat risus phasellus.'
  }
];

export const sliderData = [
  {
    name: 'Expand new markets',
    key: 1,
    label: 'Expand new markets',
    min: 0,
    max: 100,
    percentage: 40,
    className: 'expand',
    color: '#ECCE37'
  },
  {
    name: 'Improve Revenue',
    key: 2,
    label: 'Improve Revenue',
    min: 0,
    max: 100,
    percentage: 15,
    className: 'revenue',
    color: '#476CD2'
  },
  {
    name: 'Improve Morale',
    key: 3,
    label: 'Improve Morale',
    min: 0,
    max: 100,
    percentage: 10,
    className: 'morale',
    color: '#1FB4B4'
  },
  {
    name: 'BAU Maintenance',
    key: 4,
    label: 'BAU Maintenance',
    min: 0,
    max: 100,
    percentage: 20,
    className: 'bau-maintain',
    color: '#A6CEE3'
  },
  {
    name: 'Compliance and Regulation',
    key: 5,
    label: 'Compliance and Regulation',
    min: 0,
    max: 100,
    percentage: 15,
    className: 'compliance',
    color: '#FB9A99'
  },
  {
    name: 'Operational Efficiency',
    key: 6,
    label: 'Operational Efficiency',
    min: 0,
    max: 100,
    percentage: 0,
    className: 'efficiency',
    color: '#CF1F9E'
  },
  {
    name: 'New Products and Services',
    key: 7,
    label: 'New Products and Services',
    min: 0,
    max: 100,
    percentage: 0,
    className: 'services',
    color: '#F24D4D'
  },
  {
    name: 'Self Improvement',
    key: 8,
    label: 'Self Improvement',
    min: 0,
    max: 100,
    percentage: 0,
    className: 'self-improvments',
    color: '#9547D2'
  }
];

export const routeURLConst = {
  listView: '/dashboard/mygoalslistview',
  kanbanView: '/dashboard/kanbanview',
  // TODO: Would be update in the phase-2
  globalView: '/dashboard/globalview',
  subscriptionReflect: '/dashboard/subscribeplanreflected',
  portalSetting: '/dashboard/configuration/portal-settings',
  routeMatch: '/dashboard/configuration/:tabKey',
  configuration: '/dashboard/configuration',
  profile: '/dashboard/profile',
  verifyCard:'/dashboard/verified'
};

// Scorecard status screen consant ---------

export const scorecard_category = [
  { id: 3, name: 'Mission', disabled: true },
  { id: 4, name: 'Stretagy', disabled: true },
  { id: 5, name: 'Benefit', disabled: true },
  { id: 6, name: 'Objective' }
];

export const scorecard_objective = [
  { id: 1, name: 'Committed Objectives' },
  { id: 2, name: 'Aspirational Objectives' },
  { id: 3, name: 'All Objectives' }
];

export const submenu_keyRole = {
  newobjective: 5,
  addnewkey: 5,
  addvision: 1,
  addmission: 2,
  addstrategy: 3,
  addbenefit: 4
};

export const subscriptionConstants = {
  subscribeplanreflected: 'subscribeplanreflected',
  userByRoleId: 'userByRoleId',
  purchasedPlan: 'purchasedPlan',
  allPlans: 'allPlans'
};

export const cancelSubscriptionReasons = [
  'Price',
  'Difficult to use',
  'Missing functionality',
  'Using other products',
  'Not using it',
  'Other'
];

export const userTypes = [
  { id: null, name: 'All' },
  { id: 1, name: 'In-activated Users' },
  { id: 2, name: 'Active Users' },
  { id: 3, name: 'Invited' },
  { id: 4, name: 'Pending' },
  { id: 5, name: 'Deleted' }
];

export const RoutesList = [
  'newobjective',
  'viewobjective',
  'editobjective',
  'addnewkey',
  'viewkey',
  'editkey',
  'addnewtask',
  'viewtask',
  'edittask',
  'addvision',
  'viewvision',
  'editvision',
  'addmission',
  'viewmission',
  'editmission',
  'addstrategy',
  'viewstrategy',
  'editstrategy',
  'addbenefit',
  'viewbenefit',
  'editbenefit'
];

export const kanbantab = {
  1: 'linked_benefits',
  2: 'linked_objectives',
  3: 'linked_kr',
  4: 'not_linked_objective',
  5: 'tasks'
};

export const searchComponentIndexes = {
  0: 'userData',
  1: 'visionData',
  2: 'missionData',
  3: 'strategyData',
  4: 'benefitData',
  5: 'objectiveData',
  6: 'keyResultData',
  7: 'tasksData'
};

export const bgColor2 = {
  red: '#fdbcbc',
  blue: '#a7a7ef',
  pink: '#ff1480b8',
  aqua: '#5fa3a3',
  greenyellow: '#ecfbd4',
  forestgreen: '#9dc19d',
  brown: '#efdfbb',
  magenta: '#ff00ff5c',
  purple: '#ffccff',
  yellow: '#fe7500',
  deeppink: '#ff149338'
};

export const noVisionData = [
  {
    can_add_vision: undefined,
    defaultStatus: '0.00',
    end_date: '31 DEC, 2022',
    goal: 'Vision',
    hold: false,
    key: 8,
    linked: 39,
    name: 'dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvfghbfdbhgfhgf dsfvgdvf uijhbjkbujk nkn',
    nameTag: 'VSN-8',
    objective_mode: undefined,
    owner: {},
    period: 0,
    reminder: ' - ',
    star_OR_not: false,
    start_date: '1 MAY, 2022',
    status: 'red'
  }
];

export const comp_category = {
  vision: 2,
  mission: 3,
  strategy: 4,
  Benefit: 5,
  Objective: 6,
  Task: 7,
  KeyResults: 8
};
export const add_btn = 'Add';

export const colors = [
  '#f7fbff',
  '#deebf7',
  '#c6dbef',
  '#9ecae1',
  '#6baed6',
  '#4292c6',
  '#2171b5',
  '#08519c',
  '#08306B',
  '#BCBDDC'
];

export const cat_initials = { 2: 'V', 3: 'M', 4: 'S', 5: 'B', 6: 'O', 8: 'KR' };

export const noDataExportError = 'No data to Export';

export const tooltipScreens = {
  mygoalslistview: 'OKR List',
  employeeinfo: 'Signup Emp Step 2',
  'roles-permissions': 'Add Manually',
  'organization-financial-year': 'Organisation Financial Year',
  topbar: 'TopBar',
  globalview: 'Global',
  'portal-settings': 'Portal Settings',
  objective: 'Objective',
  key: 'KR',
  task: 'Task',
  vision: 'Vision',
  mission: 'Mission',
  strategy: 'Strategy',
  benefit: 'Benefit',
  scorecardstatus: 'Scorecard Status',
  kanbanview: 'Kanban',
  home: 'Dashboard',
  subscriptionplan: 'Subscription Plan',
  subscriptionplandetails: 'Subscription Plan Details',
  subscribeplanreflected: 'Subscription Plan History',
  billinginfo: 'Billing info'
};

export const defaultMetaData = {
  totalItems: 0,
  currentPage: 0,
  itemsPerPage: 0,
  totalPages: 0
};

export const CANADA_STATES_LIST = [
  {
    label: 'Alberta',
    value: 'Alberta',
  },
  {
    label: 'British Columbia',
    value: 'British Columbia',
  },
  {
    label: 'Manitoba',
    value: 'Manitoba',
  },
  {
    label: 'New Brunswick',
    value: 'New Brunswick',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador',
  },
  {
    label: 'Northwest Territories',
    value: 'Northwest Territories',
  },
  {
    label: 'Nova Scotia',
    value: 'Nova Scotia',
  },
  {
    label: 'Nunavut',
    value: 'Nunavut',
  },
  {
    label: 'Ontario',
    value: 'Ontario',
  },
  {
    label: 'Prince Edward Island',
    value: 'Prince Edward Island',
  },
  {
    label: 'Quebec',
    value: 'Quebec',
  },
  {
    label: 'Saskatchewan',
    value: 'Saskatchewan',
  },
  {
    label: 'Yukon',
    value: 'Yukon',
  },
];

export const showStatesOfCountries=['US','CA']

//--------------
