import { Link } from "react-router-dom";
import { GoogleEventObj, googleEventTracker } from "../../config/site.config";

export default function LinkButton(props) {
  const checkLinkClick = () => {
    props.back && history.back(); // eslint-disable-line
    googleEventTracker(GoogleEventObj.button_click.name, {
      category: `${props.location?.split('/')[1]}_login_btn_click`,
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel()
    })
  }
  return (
    <p className="redirect" style={{marginTop : "10px"}}>
      {props.paraMessage}
      <Link className="anchor-tag" to={props.redirect} onClick={checkLinkClick}>
        <span>{props.linktitle}</span>
      </Link>
    </p>
  );
}
