// import { createBrowserHistory } from 'history';

// export default createBrowserHistory({
//   forceRefresh: true,
// });

import { createBrowserHistory } from "history";

export default createBrowserHistory();

export const history = createBrowserHistory();
