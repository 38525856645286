import React from 'react'
import { Button } from 'antd'
import { LinkBtn, CustomizedLevelBtn, CommentBtn, CustomPopUpBtn } from '../../assets/styles/globalStyle'
interface ATagType {
  [x: string]: any;
  className: string;
  isButton?: boolean;
  isCustomStyle? : boolean;
  isComment?: boolean;
  isCustomPopUpBtn?: boolean;
  href?: string;
  isOnlyIcon?: boolean;
  isDisabled?: boolean;
}

const ATag = ({className,isButton,isCustomStyle,isComment,href,isOnlyIcon,isDisabled,isCustomPopUpBtn, ...rest} : ATagType) => {
  return (
    isButton ?
      isCustomStyle ?
        <CustomizedLevelBtn className={className} {...rest} />   //confidence level
      : isComment ?
          <CommentBtn className={className} {...rest}/>     //comment button
      : isOnlyIcon ?
          <Button className={`${className} isOnlyIcon`} {...rest} />   
      : isCustomPopUpBtn ?
          <CustomPopUpBtn className={className} {...rest} disabled={isDisabled} />   //all popup buttons
      : isDisabled ?
          <Button className='disabled-aTag' disabled={isDisabled}/>
      :     
        <Button className={`${className} aTagBtn`} {...rest} />
    :
      <LinkBtn href={href ? href : '#'} className={className} {...rest} />
    

  )
}

export default ATag