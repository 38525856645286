import defaultTheme from "./default";

interface defaultThemeObjType {
  palette: {
    primary: string[];
    secondary: string[];
  };
  fonts: {
    primary: string;
    pre: string;
  };
}

const defaultThemeObj: defaultThemeObjType = {
  ...defaultTheme,
  palette: {
    primary: ["#f00"],
    secondary: ["#0f0"],
  },
};

export default defaultThemeObj;
