import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { benefitTypes } from "./benefitTypes";
import authActions from "../app/action";
import {
  postBenefitReq,
  getBenefitReq,
  getStrategyInBenefitReq,
  getMissionInBenefitReq,
  patchBenefitReq,
  getObjInBenefitReq,
} from "../../library/services/benefitReq";
import history from "../../library/helpers/history";
import { holdActions } from "../Holds/holdActions";

export function* getBenefitEffect() {
  yield takeEvery(benefitTypes.GET_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getBenefitReq, payload);
      if (response.status) {
        yield put({
          type: benefitTypes.GET_BENEFIT_SUCCESS,
          benefitData: response.data.data,
        });
      } else {
        yield put({
          type: benefitTypes.GET_BENEFIT_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: benefitTypes.GET_BENEFIT_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getStrategyInBenefitEffect() {
  yield takeEvery(benefitTypes.GET_STRATEGY_IN_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getStrategyInBenefitReq, payload);
      if (response.status) {
        yield put({
          type: benefitTypes.GET_STRATEGY_IN_BENEFIT_SUCCESS,
          strategyData: response.data.data.strategyOnBenefit,
        });
      } else {
        yield put({
          type: benefitTypes.GET_STRATEGY_IN_BENEFIT_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: benefitTypes.GET_STRATEGY_IN_BENEFIT_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getMissionInBenefitEffect() {
  yield takeEvery(benefitTypes.GET_MISSION_IN_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getMissionInBenefitReq, payload);
      if (response) {
        yield put({
          type: benefitTypes.GET_MISSION_IN_BENEFIT_SUCCESS,
          mission: response.data.data.getMissionOnId[0].mission,
        });
      } else {
        yield put({
          type: benefitTypes.GET_MISSION_IN_BENEFIT_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: benefitTypes.GET_MISSION_IN_BENEFIT_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postBenefitEffect() {
  yield takeEvery(benefitTypes.POST_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(postBenefitReq, payload);
      if (response.status) {
        yield all([
          put({
            type: benefitTypes.POST_BENEFIT_SUCCESS,
            successMessage: response.data.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
        localStorage.removeItem('ben_data');
        history.push(`viewbenefit/${response?.data?.data?.benefitId}`);
      } else {
        yield all([
          put({
            type: benefitTypes.POST_BENEFIT_FAILED,
            errorMessage: response,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield all([
        put({
          type: benefitTypes.POST_BENEFIT_FAILED,
          errorMessage: error.data.message,
        }),
        put({
          type: holdActions.SCROLL_TO_TOP,
        }),
      ]);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* patchBenefitEffect() {
  yield takeEvery(benefitTypes.PATCH_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(patchBenefitReq, payload);
      if (response.status) {
        yield all([
          put({
            type: benefitTypes.PATCH_BENEFIT_SUCCESS,
            successMessage: "Congratulations! You have saved your changes.",
          }),
          put({
            type: benefitTypes.GET_BENEFIT,
            payload: payload,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      } else {
        yield all([
          put({
            type: benefitTypes.PATCH_BENEFIT_FAILED,
            errorMessage: response.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield all([
        put({
          type: benefitTypes.PATCH_BENEFIT_FAILED,
          errorMessage: error.data.message,
        }),
        put({
          type: holdActions.SCROLL_TO_TOP,
        }),
      ]);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getObjInBenefitEffect() {
  yield takeEvery(benefitTypes.GET_OBJECTIVE_IN_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response: any = yield call(getObjInBenefitReq, payload);
      if (response?.status) {
        yield put({
          type: benefitTypes.GET_OBJECTIVE_IN_BENEFIT_SUCCESS,
          objData: response?.data?.data,
        });
      } else {
        yield put({
          type: benefitTypes.GET_OBJECTIVE_IN_BENEFIT_FAILED,
          errorMessage: response?.data?.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: benefitTypes.GET_OBJECTIVE_IN_BENEFIT_FAILED,
        errorMessage: error?.data?.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(postBenefitEffect),
    fork(getBenefitEffect),
    fork(getStrategyInBenefitEffect),
    fork(getMissionInBenefitEffect),
    fork(patchBenefitEffect),
    fork(getObjInBenefitEffect),
  ]);
}
