export enum notificationActions {
  GET_NOTIFICATION = "GET_NOTIFICATION",
  GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED",

  SET_NOTIFICATION_SOUND = "SET_NOTIFICATION_SOUND",
  SET_NOTIFICATION_SOUND_SUCCESS = "SET_NOTIFICATION_SOUND_SUCCESS",
  SET_NOTIFICATION_SOUND_FAILED = "SET_NOTIFICATION_SOUND_FAILED",

  SET_INT_ID = "SET_INT_ID",
  READ_NOTIFICATION = "READ_NOTIFICATION",
}
