export function getView(width: number) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

export const CLEAR_RESPONSE_MESSAGE = 'CLEAR_RESPONSE_MESSAGE';

const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  GLOBAL_LOADER: 'GLOBAL_LOADER',
  GLOBAL_LOADER_INCREMENT: 'GLOBAL_LOADER_INCREMENT',
  GLOBAL_LOADER_DECREMENT: 'GLOBAL_LOADER_DECREMENT',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLEAR_MENU: 'CLEAR_MENU',
  CHANGE_BACK: 'CHANGE_BACK',
  IS_CHANGE_BACK: 'IS_CHANGE_BACK',

  GLOBLE_MODEL_FOR_CHANGES: 'GLOBLE_MODEL_FOR_CHANGES',
  SET_INPUT_BOX: 'SET_INPUT_BOX',
  setModelStatus: (status: boolean) => ({
    type: actions.GLOBLE_MODEL_FOR_CHANGES,
    status,
  }),
  setInputBox: (payload: boolean) => ({
    type: actions.SET_INPUT_BOX,
    payload,
  }),
  globalLoaderHandler: (isIncrement: boolean = false) => ({
    type: actions.GLOBAL_LOADER,
    isIncrement,
  }),
  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),
  toggleAll: (width: number, height: number) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: (openKeys: string[]) => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current: string[]) => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),
  clearResponseMessages: () => ({
    type: CLEAR_RESPONSE_MESSAGE,
  }),
  changeBack: () => ({
    type: actions.CHANGE_BACK,
  }),
  IschangeBack: (status: boolean) => ({
    type: actions.IS_CHANGE_BACK,
    status,
  }),
};
export default actions;
