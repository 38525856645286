import { ServiceAuthInstance, apiUrl } from "./index";

export const postconfidencelevelReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/confidence_level",
    data: {
      ...payload,
    },
  });
};

export const getconfidencelevelReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: `${apiUrl.GET_CONFIDENCE_LEVEL}`,
  });
};

export const getobjectivetypeReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/tools_configuration/objective_type",
  });
};

export const postobjectivetypeReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/objective_type",
    data: {
      ...payload,
    },
  });
};


export const postscorecardReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/score_card",
    data: {
      ...payload,
    },
  });
};
export const getscorecardReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/tools_configuration/score_card",
  });
};
export const putscorecardReq = (payload, id) => {
  return ServiceAuthInstance({
    method: "PUT",
    url: `/tools_configuration/score_card/${id}`,
    data: {
      ...payload,
    },
  });
};
export const postassistancepointsReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/assistance_points",
    data: {
      ...payload,
    },
  });
};
export const getassistancepointsReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/tools_configuration/assistance_points",
  });
};
export const posthelpfulnessratingReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/helpfulness_rating",
    data: {
      ...payload,
    },
  });
};
export const gethelpfulnessratingReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/tools_configuration/helpfulness_rating",
  });
};
export const getvmsbstatusReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "organization/check_vmsb",
  });
};