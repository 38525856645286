import { benefitTypes } from "./benefitTypes";
import { benefitAction, benefitState } from "./types";
let initState: benefitState = {
  errorMessage: "",
  successMessage: "",
  benefitData: {
    getBenefitOnId: {
      benefit_scorecard: [{
        id: 0,
        benefit: 0,
        value: 0,
        scorecard_category: {
          display_colour: "",
          id: 0,
          name: "",
        }
      }],
      created_by: {
        id: 0,
        first_name: '',
        last_name: '',
        profile_photo: '',
      },
      description: "",
      end_date: "",
      id: 0,
      name: "",
      integrated_status: 0,
      note: "",
      note_updated_datetime: null,
      number_of_periods: 0,
      objective_type: "",
      on_hold: 0,
      reminders: [{
        id: 0,
        reminder_text: "",
        reminder_time: "",
      }],
      star: 0,
      start_date: "",
      strategy: {
        id: 0,
        name: "",
        okr_linked_count: 0,
        created_by: 0,
        mission: {
          id: 0,
          name: "",
          okr_linked_count: 0,
          on_hold: 0,
        }
      },
      start_period: {
        start_date: '',
      },
      end_period: {
        end_date: '',
      }
    },
      others_to_be_notified: []
  },
  strategyData: [],
  mission: {
    id: 0,
    name: "",
  },
  objData: {
    pagedItems: [],
    metaData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  }
};
export default function authReducer(state = initState, action: benefitAction) {
  switch (action.type) {
    case benefitTypes.POST_BENEFIT:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
      };
    case benefitTypes.POST_BENEFIT_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case benefitTypes.POST_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case benefitTypes.GET_BENEFIT:
      return {
        ...state,
      };
    case benefitTypes.GET_BENEFIT_SUCCESS:
      return {
        ...state,
        benefitData: action.benefitData,
      };
    case benefitTypes.GET_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case benefitTypes.GET_STRATEGY_IN_BENEFIT:
      return {
        ...state,
        mission: {id: 0, name: ""},
      };
    case benefitTypes.GET_STRATEGY_IN_BENEFIT_SUCCESS:
      return {
        ...state,
        strategyData: action.strategyData,
      };
    case benefitTypes.GET_STRATEGY_IN_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case benefitTypes.GET_MISSION_IN_BENEFIT:
      return {
        ...state,
      };
    case benefitTypes.GET_MISSION_IN_BENEFIT_SUCCESS:
      return {
        ...state,
        mission: action.mission,
      };
    case benefitTypes.GET_MISSION_IN_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case benefitTypes.PATCH_BENEFIT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case benefitTypes.PATCH_BENEFIT_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case benefitTypes.PATCH_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case benefitTypes.GET_OBJECTIVE_IN_BENEFIT:
      return {
        ...state,
      };
    case benefitTypes.GET_OBJECTIVE_IN_BENEFIT_SUCCESS:
      return {
        ...state,
        objData: action.objData,
      };
    case benefitTypes.GET_OBJECTIVE_IN_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case benefitTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    default:
      return state; 
  }
}
