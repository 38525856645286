export enum configTypes {
  GET_DEFAULT_CONFIG = 'GET_DEFAULT_CONFIG',
  GET_DEFAULT_CONFIG_SUCCESS = 'GET_DEFAULT_CONFIG_SUCCESS',
  GET_DEFAULT_CONFIG_FAILURE = 'GET_DEFAULT_CONFIG_FAILURE',

  EDIT_DEFAULT_CONFIG = 'EDIT_DEFAULT_CONFIG',
  EDIT_DEFAULT_CONFIG_SUCCESS = 'EDIT_DEFAULT_CONFIG_SUCCESS',
  EDIT_DEFAULT_CONFIG_FAILURE = 'EDIT_DEFAULT_CONFIG_FAILURE',

  REMOVE_ALERT = 'REMOVE_ALERT'
}
