import { ServiceAdminAuthInstance, ServiceAuthInstance, adminApiUrls } from '..';

export const tooltipUploadReq = (file) => {
  let formData = new FormData();
  formData.append('file', file);
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.TOOLTIP,
    data: formData
  });
};

export const tooltipFilesReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.TOOLTIP_FILES,
    data: { ...payload }
  });
};

export const getAllTooltipsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: adminApiUrls.TOOLTIP_FILE,
    data: { ...payload }
  });
};

export const getExecutiveTooltipsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: adminApiUrls.TOOLTIP_FILE,
    data: { ...payload }
  });
};
