import { useEffect, useState } from 'react';
import { Alert, Card, Form, Select, Tooltip } from 'antd';
import logo from '../../assets/images/TA-Horizontal_Logo.png';
import Input from '../../components/uielements/input';
import {
  AppConstant,
  dateFormatList,
  monthInYear,
  emailValidationRegex,
  freePLan,
  NameRegex,
  PhoneNumberRegex,
  timePeriod,
  lName,
  fName,
  email,
  tooltipScreens,
  showStatesOfCountries,
} from '../../config/constant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextArea from 'antd/lib/input/TextArea';
import { BtnWrapper } from '../../assets/styles/globalStyle';
import ATag from '../../components/AnchorTag/ATag';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/rootReducer';
import {
  checkPromoCode,
  getAllActivePlans,
  getCountryById,
  getCountryList,
  removeAlert,
  setError,
  savePaymentAddress,
  checkoutSession,
  getStatesById,
} from '../../redux/SubscriptionPlan/actions';
import moment from 'moment-timezone';
import history from '../../library/helpers/history';
import { InfoCircleFilled } from '@ant-design/icons';
import PaymentDetailsStyleWrapper from './BillingInfo.styles';
import { annualRateCalculate } from '../../library/helpers/utility';
import { plan } from '../../redux/Admin/subscription/types';
import { getExecutiveTooltips } from '../../redux/Admin/Tooltip/action';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleEventObj, googleEventTracker } from '../../config/site.config';

export default function BillingInfo() {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [showTextBox, setShowTextBox] = useState(false);
  const [countryValue, setCountryValue] = useState('');
  const [selectState, setSelectState] = useState({});
  const [taxRate, setTaxRate] = useState(0);
  const [stateDropdown, setStateDropdown] = useState<any>([]);
  const [taxAmount, setTaxAmount] = useState<any>((0).toFixed(2));
  const [showDiscount, setShowDiscount] = useState(false);
  const [value, setValue] = useState('');
  const [payableAmount, setPayableAmount] = useState(0);
  const [license, setLicense] = useState(1);
  const [planEndDate, setPlanEndDate] = useState('');
  const [discount, setDiscount] = useState<number>(0);
  const [discountedAmount, setDiscountedAmount] = useState<number>(0);
  const [taxObject, setTaxObject] = useState<any>({ pst: 0, gst: 0, hst: 0 });
  const [gst, setGst] = useState<any>((0).toFixed(2));
  const [pst, setPst] = useState<any>((0).toFixed(2));
  const [hst, setHst] = useState<any>((0).toFixed(2));
  const [billingForm] = Form.useForm();
  let stripePromise;

  const { userInfo } = useSelector((state: RootState) => state.Auth);
  //@ts-ignore
  const socialUser = JSON.parse(localStorage.getItem('userInfo'));
  //@ts-ignore
  const cardIsVerified = localStorage.getItem('cardIsVerified');

  const {
    paymentError,
    countryList,
    countryData,
    promoNotValidError,
    promoAppliedSuccess,
    appliedPromoCodeData,
    statesList,
  } = useSelector((state: RootState) => state.SubscriptionPlanReducer);

  const executiveTooltips: any[] = useSelector(
    (state: any) => state.TooltipReducer.executiveTooltips
  );

  const { allActivePlans, paymentAddressSave, checkoutSuccessSessionId } =
    useSelector((state: RootState) => state.SubscriptionPlanReducer);
  //@ts-ignore
  const planChosen = JSON.parse(localStorage.getItem('planChosen'));
  //@ts-ignore
  const paymentDetails = JSON.parse(localStorage.getItem('paymentDetails'));
  const backFromCard = localStorage.getItem('backFromCard');
  //@ts-ignore
  const selectedCountry = JSON.parse(localStorage.getItem('countryData')) ?? {};

  const changeHandler = (value) => {
    setCountryValue(value);
    const filterCountry=countryList.filter((item)=>item.country_code === value)[0]
    dispatch(getCountryById(filterCountry.id));
    setSelectState({});
    setTaxRate(0);
    setTaxAmount((0).toFixed(2));
    setPst((0).toFixed(2));
    setGst((0).toFixed(2));
    setHst((0).toFixed(2));
    setTaxObject({
      pst: (0).toFixed(2),
      gst: (0).toFixed(2),
      hst: (0).toFixed(2),
    });
    if (showStatesOfCountries.includes(value)) {
      dispatch(getStatesById(value));
    }
    billingForm.setFieldsValue({ state: '' });
  };

  const stateChangeHandler = (value) => {
    setSelectState(value);
    if (value) {
      const tax = stateDropdown?.filter((item) => item.value === value)[0];
      const rate = Number(tax?.pst) + Number(tax?.gst) + Number(tax?.hst);
      setTaxRate(rate);
      setTaxObject(tax);
    } else {
      setTaxRate(0);
      setPst((0).toFixed(2));
      setGst((0).toFixed(2));
      setHst((0).toFixed(2));
    }
  };

  const handleOnChange = (e) => {
    setShowTextBox(true);
  };

  useEffect(() => {
    if (showStatesOfCountries.includes(countryValue)) {
      setTaxAmount(
        discountedAmount > 0
          ? ((discountedAmount * Number(taxRate)) / 100).toFixed(2)
          : ((payableAmount * Number(taxRate)) / 100).toFixed(2)
      );
      setPst(taxObject?.pst);
      setGst(taxObject?.gst);
      setHst(taxObject?.hst);
    }
  }, [discountedAmount, taxRate, payableAmount, countryValue, taxObject]);

  useEffect(() => {
    if (appliedPromoCodeData) {
      const discountAmount =
        (payableAmount * Number(appliedPromoCodeData.discount)) / 100;
      setDiscount(discountAmount);
      setDiscountedAmount(payableAmount - discountAmount);
      localStorage.setItem('promo_code', value);
    }
  }, [appliedPromoCodeData]); //eslint-disable-line

  useEffect(() => {
    localStorage.setItem(
      'totalPayableAmount',
      discountedAmount > 0
        ? JSON.stringify((discountedAmount + Number(taxAmount)).toFixed(2))
        : JSON.stringify((payableAmount + Number(taxAmount)).toFixed(2))
    );
  });

  useEffect(() => {
    const start_date_subscription = moment(moment())
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const end_date_subscription = moment(moment())
      .utc()
      .endOf('months')
      .format('YYYY-MM-DD HH:mm:ss');

    const startMoment = moment.utc(start_date_subscription);
    const endMoment = moment.utc(end_date_subscription);
    const currentMonthInDays = moment().daysInMonth();
    const daysOfPaid = endMoment.diff(startMoment, 'days') + 1;
    const choosedPlanDetail: plan = allActivePlans.filter(
      (i) => i?.id === planChosen.plan_id
    )[0];
    if (planChosen.plan_name === 'Free') {
      setPayableAmount(0);
      setLicense(planChosen.no_of_license);
      setPlanEndDate(
        moment(moment())
          .utc()
          .add(choosedPlanDetail?.module?.trial_period, 'days')
          .format(dateFormatList)
      );
    } else {
      if (planChosen.type === timePeriod.annually) {
        setPayableAmount(
          annualRateCalculate(
            choosedPlanDetail?.monthly_rate,
            choosedPlanDetail?.annual_discount
          ) *
            (Math.ceil(planChosen.no_of_license / 5) * 5) *
            monthInYear
        );
        setPlanEndDate(
          moment(moment())
            .utc()
            .add(1, 'year')
            .subtract(1, 'days')
            .format(dateFormatList)
        );
        setLicense(planChosen.no_of_license);
      } else {
        if (daysOfPaid === currentMonthInDays) {
          setPayableAmount(
            planChosen.no_of_license * parseInt(choosedPlanDetail?.monthly_rate)
          );
        } else {
          setPayableAmount(
            planChosen.no_of_license *
              (parseInt(choosedPlanDetail?.monthly_rate) / currentMonthInDays) *
              daysOfPaid
          );
        }
        setPlanEndDate(moment(end_date_subscription).format(dateFormatList));
        setLicense(planChosen.no_of_license);
      }
    }
  }, [planChosen]); //eslint-disable-line

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getAllActivePlans());
    dispatch(getExecutiveTooltips({ page: tooltipScreens.billinginfo }));
    if (backFromCard === 'true') {
      billingForm.setFieldsValue({
        firstName: paymentDetails?.first_name,
        lastName: paymentDetails?.last_name,
        email: paymentDetails?.email,
        address: paymentDetails?.address,
        postalCode: paymentDetails?.postal_code,
        city: paymentDetails?.city,
        state: paymentDetails?.state,
        country: selectedCountry.id,
        phone: paymentDetails?.phone_no,
      });
      if (localStorage.getItem('promo_code')) {
        // @ts-ignore
        setValue(localStorage.getItem('promo_code'));
      }
      dispatch(removeAlert());
    }
    const onBackButtonEvent = (e) => {
      localStorage.setItem('backFromBilling', 'true');
      dispatch(removeAlert());
    };

    window.onpopstate = onBackButtonEvent;
  }, []); //eslint-disable-line

  useEffect(() => {
    setDiscount(0);
    setDiscountedAmount(0);
    value !== '' && dispatch(setError(''));
  }, [value]); //eslint-disable-line

  const onFinish = async (values) => {
    const payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      address: values.address,
      postal_code: values.postalCode === undefined ? '' : values.postalCode,
      city: values.city,
      state: values.state,
      country:
        countryData.country_code === ''
          ? selectedCountry.country_code
          : countryData.country_code,
      currency:
        countryData.currency === ''
          ? selectedCountry.currency
          : countryData.currency,
      phone_no: values.phone,
    };

    localStorage.setItem('paymentDetails', JSON.stringify(payload));
    localStorage.setItem('backFromCard', 'false');
    localStorage.setItem('taxAmount', taxAmount);
    localStorage.setItem('taxRate', String(taxRate));
    localStorage.setItem('pst', String(pst));
    localStorage.setItem('gst', String(gst));
    localStorage.setItem('hst', String(hst));
    dispatch(savePaymentAddress(payload));
    googleEventTracker(GoogleEventObj.button_click.name, {
      category: 'subscription_plan_billing_info_save_click',
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel(),
    });
  };

  const checkForErrors = (evt) => {
    if (evt?.errorFields?.length) {
      evt?.errorFields?.forEach((field) => {
        googleEventTracker(GoogleEventObj.form_error.name, {
          category:
            'subscription_plan_billing_info_' + field?.name?.[0] || 'n/a',
          action: GoogleEventObj.form_error.action,
          label: GoogleEventObj.form_error.getLabel(),
          value: field?.errors?.[0],
        });
      });
    }
  };

  useEffect(() => {
    (async () => {
      const stripe: any = await loadStripe(
        `${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`
      );
      stripe
        .redirectToCheckout({
          sessionId: checkoutSuccessSessionId,
        })
        .then(function (result) {
          console.log('result', result);
        });
    })();
  }, [checkoutSuccessSessionId]);

  const handlePhone = (e: any) => {
    return `+${e}`;
  };
  useEffect(() => {
    if (localStorage.getItem('userDetail')) {
      history.push('/portalsuccess');
    }
  }, []);
  useEffect(() => {
    const list =
      statesList && statesList.length > 0
        ? statesList.map((state) => {
            return {
              label: state?.state_name,
              value: state?.state_name,
              pst: state?.pst,
              gst: state?.gst,
              hst: state?.hst,
            };
          })
        : [];
    setStateDropdown(list);
  }, [statesList]);

  return (
    <>
      <PaymentDetailsStyleWrapper className='billing-info-page'>
        <div className='isoBillingContentWrapper'>
          <div className='isoBillingContent'>
            <div className='isoLogoWrapper'>
              <img src={logo} alt='' />
            </div>
            {paymentError && (
              <Alert
                type='error'
                showIcon
                closable
                message={paymentError}
                className='w-40'
                onClose={() => dispatch(removeAlert())}
                style={{ marginBottom: '15px', maxWidth: '800px' }}
              />
            )}
            <Card
              title={<p className='trial trial-end'>Payment Details</p>}
              className='parent-card'
            >
              <div className='billing-wrapper'>
                <div className='isoPaymentContentWrapper'>
                  <div className='isoPaymentContent'>
                    <Card
                      title={
                        <div>
                          <p className='choose-plan'>
                            <label>Plan Chosen:</label>
                            <div>
                              <span>
                                {`${planChosen.plan_name} Plan `}{' '}
                                {planChosen.plan_name !== 'Free' &&
                                  `(${
                                    planChosen.type === timePeriod.monthly
                                      ? 'Monthly'
                                      : 'Annual'
                                  }) `}
                                - <sup>USD $</sup>
                                {payableAmount.toFixed(2)}
                                {planChosen.type === timePeriod.monthly &&
                                  planChosen.is_freePlan !== freePLan && (
                                    <Tooltip
                                      placement='top'
                                      title={
                                        executiveTooltips.filter(
                                          (i) =>
                                            i.name_of_tooltip === 'monthlyRate'
                                        )[0]?.value
                                      }
                                    >
                                      <InfoCircleFilled className='tooltip-icon' />
                                    </Tooltip>
                                  )}
                              </span>
                              <span className='license'>
                                Licenses - {license}{' '}
                                {planChosen.type === timePeriod.annually ? (
                                  <p>
                                    (billing for 1-{Math.ceil(license / 5) * 5})
                                  </p>
                                ) : (
                                  ''
                                )}
                              </span>
                              <span>
                                {planChosen.is_freePlan === freePLan
                                  ? `Free trial ends on ${planEndDate}`
                                  : `Plan ending on ${planEndDate}`}
                              </span>
                            </div>
                          </p>
                        </div>
                      }
                    >
                      <div className='payment-info'>
                        <div className='count-payment'>
                          <p>
                            <span>Payment</span>
                            <span>
                              <sup>USD $</sup>
                              {payableAmount.toFixed(2)}
                            </span>
                          </p>
                          <p>
                            <span>Promo Discount</span>
                            <span>
                              {{ showDiscount } && (
                                <>
                                  <sup>USD $</sup>
                                  {discount.toFixed(2)}
                                </>
                              )}
                            </span>
                          </p>
                          <p className='redirect' onClick={handleOnChange}>
                            <p>{showTextBox}</p>
                            <div
                              className='promo-code-wrapper btn-group'
                              style={{
                                display: showDiscount ? 'none' : 'flex',
                              }}
                            >
                              <Input
                                placeholder='XXX-XXXX'
                                className={
                                  promoNotValidError
                                    ? 'invalid-promo-input promo-code'
                                    : 'promo-code'
                                }
                                size='large'
                                hasFeedback
                                disabled={
                                  planChosen.plan_name === 'Free' ? true : false
                                }
                                onChange={(e) => setValue(e.target.value)}
                                value={value}
                              />
                              <ATag
                                isButton={true}
                                isCustomPopUpBtn={true}
                                tabIndex={
                                  planChosen.plan_name === 'Free' ? 0 : 1
                                }
                                className={
                                  planChosen.plan_name === 'Free'
                                    ? 'disabled-aTag'
                                    : ''
                                }
                                onMouseLeave={(e) => e.currentTarget.blur()}
                                onClick={(event) => {
                                  event.preventDefault();
                                  value !== ''
                                    ? dispatch(
                                        checkPromoCode({
                                          promocode: value,
                                          plan_name: planChosen.plan_name,
                                          plan_type: planChosen.type,
                                        })
                                      )
                                    : dispatch(
                                        setError('Please enter promocode.')
                                      );
                                }}
                                isDisabled={
                                  planChosen.plan_name === 'Free' ? true : false
                                }
                              >
                                Apply
                              </ATag>
                            </div>
                            {promoNotValidError && (
                              <span className='invalid-code'>
                                {promoNotValidError}
                              </span>
                            )}
                            {promoAppliedSuccess && (
                              <span style={{ fontWeight: 500 }}>
                                {promoAppliedSuccess}
                              </span>
                            )}
                          </p>
                        </div>
                        {
                          <div className='total-payment taxes'>
                            <p>
                              <span>PST</span>
                              <span>
                                <sup>USD $</sup>
                                {discountedAmount > 0
                                  ? (
                                      (discountedAmount * Number(pst)) /
                                      100
                                    ).toFixed(2)
                                  : (
                                      (payableAmount * Number(pst)) /
                                      100
                                    ).toFixed(2)}
                              </span>
                            </p>
                            <p>
                              <span>GST</span>
                              <span>
                                <sup>USD $</sup>
                                {discountedAmount > 0
                                  ? (
                                      (discountedAmount * Number(gst)) /
                                      100
                                    ).toFixed(2)
                                  : (
                                      (payableAmount * Number(gst)) /
                                      100
                                    ).toFixed(2)}
                              </span>
                            </p>
                            <p>
                              <span>HST</span>
                              <span>
                                <sup>USD $</sup>
                                {discountedAmount > 0
                                  ? (
                                      (discountedAmount * Number(hst)) /
                                      100
                                    ).toFixed(2)
                                  : (
                                      (payableAmount * Number(hst)) /
                                      100
                                    ).toFixed(2)}
                              </span>
                            </p>
                            <p>
                              <span>Total Taxes Applicable</span>
                              <span>
                                <sup>USD $</sup>
                                {taxAmount}
                              </span>
                            </p>
                          </div>
                        }
                        <div className='total-payment'>
                          <p>
                            <span>Total Payment</span>
                            <span className='total'>
                              <sup>USD $</sup>
                              {discountedAmount > 0
                                ? (
                                    discountedAmount + Number(taxAmount)
                                  ).toFixed(2)
                                : (payableAmount + Number(taxAmount)).toFixed(
                                    2
                                  )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <Form
                  className='payment-form'
                  form={billingForm}
                  onFinish={onFinish}
                  onFinishFailed={checkForErrors}
                >
                  <div className='small-fields'>
                    <Form.Item
                      name='firstName'
                      label='First Name:'
                      initialValue={
                        Object.keys(userInfo).includes(fName)
                          ? userInfo.first_name
                          : socialUser.first_name
                      }
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.firstnameRequired,
                        },
                        {
                          pattern: NameRegex,
                          message: AppConstant.FormValidation.firstNameValid,
                        },
                      ]}
                    >
                      <Input
                        placeholder='First Name'
                        size='large'
                        disabled={false}
                      />
                    </Form.Item>

                    {/* last name */}
                    <Form.Item
                      name='lastName'
                      label='Last Name:'
                      initialValue={
                        Object.keys(userInfo).includes(lName)
                          ? userInfo.last_name
                          : socialUser.last_name
                      }
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.lastnameRequired,
                        },
                        {
                          pattern: NameRegex,
                          message: AppConstant.FormValidation.lastNameValid,
                        },
                      ]}
                    >
                      <Input
                        placeholder='Last Name'
                        size='large'
                        disabled={false}
                      />
                    </Form.Item>
                  </div>
                  {/* email */}
                  <div className='small-fields'>
                    <Form.Item
                      name='email'
                      label='Email:'
                      initialValue={
                        Object.keys(userInfo).includes(email)
                          ? userInfo.created_by
                          : socialUser[email]
                      }
                      rules={[
                        {
                          pattern: emailValidationRegex,
                          message: AppConstant.FormValidation.emailInvalid,
                        },
                        {
                          required: true,
                          message: AppConstant.FormValidation.emailRequired,
                        },
                      ]}
                    >
                      <Input
                        placeholder='email@example.com'
                        size='large'
                        disabled={false}
                      />
                    </Form.Item>

                    {/* last name */}
                    <Form.Item
                      name='phone'
                      label='Phone Number'
                      getValueFromEvent={handlePhone}
                      rules={[
                        {
                          required: true,
                          message:
                            AppConstant.FormValidation.phoneNumberRequired,
                        },
                        {
                          pattern: PhoneNumberRegex,
                          message: AppConstant.FormValidation.phoneNumberValid,
                        },
                      ]}
                    >
                      <PhoneInput
                        country={'us'}
                        disabled={cardIsVerified === 'true' ? true : false}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name='address'
                    label='Address:'
                    rules={[
                      {
                        required: true,
                        message: AppConstant.FormValidation.addressRequired,
                      },
                    ]}
                  >
                    <TextArea
                      placeholder='Address'
                      size='large'
                      disabled={cardIsVerified === 'true' ? true : false}
                    />
                  </Form.Item>
                  <div className='small-fields'>
                    <Form.Item
                      name='city'
                      label='City:'
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.cityRequired,
                        },
                      ]}
                    >
                      <Input
                        placeholder='City'
                        size='large'
                        disabled={cardIsVerified === 'true' ? true : false}
                      />
                    </Form.Item>

                    <Form.Item
                      name='state'
                      label='State:'
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.stateRequired,
                        },
                      ]}
                    >
                      {showStatesOfCountries.includes(countryValue) ? (
                        <Select
                          placeholder='State'
                          className='country'
                          value={selectState}
                          onChange={stateChangeHandler}
                          allowClear
                          onClear={() => {
                            setSelectState({});
                            setTaxAmount((0).toFixed(2));
                            setPst((0).toFixed(2));
                            setGst((0).toFixed(2));
                            setHst((0).toFixed(2));
                            setTaxObject({ pst: 0, gst: 0, hst: 0 });
                          }}
                          clearIcon={<i className='icon-close'></i>}
                          showSearch
                          disabled={cardIsVerified === 'true' ? true : false}
                        >
                          {stateDropdown.map((item) => (
                            <Option value={item.value} key={item.id}>
                              {item.value}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          placeholder='State'
                          size='large'
                          disabled={cardIsVerified === 'true' ? true : false}
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div className='small-fields'>
                    <Form.Item
                      name='country'
                      label='Country:'
                      className='country'
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.countryRequired,
                        },
                      ]}
                    >
                      <Select
                        placeholder='Country'
                        className='country'
                        value={countryValue}
                        onChange={changeHandler}
                        onClear={() => {
                          setSelectState({});
                          setTaxAmount((0).toFixed(2));
                          setPst((0).toFixed(2));
                          setGst((0).toFixed(2));
                          setHst((0).toFixed(2));
                          setTaxObject({ pst: 0, gst: 0, hst: 0 });
                          billingForm.setFieldsValue({ state: '' });
                        }}
                        allowClear
                        clearIcon={<i className='icon-close'></i>}
                        showSearch
                        filterOption={(input, option: any) => {
                          return (
                            option?.children
                              .toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          );
                        }}
                        disabled={cardIsVerified === 'true' ? true : false}
                      >
                        {countryList.map((item) => (
                          <Option value={item.country_code} key={item.id}>
                            {item.country_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name='postalCode' label='Postal Code:'>
                      <Input
                        placeholder='Postal Code'
                        size='large'
                        disabled={cardIsVerified === 'true' ? true : false}
                      />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </Card>
            <div className='btn-group payment-btn'>
              <BtnWrapper>
                <ATag
                  className='fill'
                  isButton={true}
                  isCustomPopUpBtn={true}
                  onClick={() => billingForm.submit()}
                  tabIndex={0}
                  onMouseLeave={(e) => e.currentTarget.blur()}
                >
                  Next
                </ATag>
                <ATag
                  onClick={() => {
                    history.push(`/subscriptionplan`);
                    localStorage.setItem('backFromBilling', 'true');
                    dispatch(removeAlert());
                  }}
                  isButton={true}
                  isCustomPopUpBtn={true}
                  tabIndex={0}
                  className=''
                  onMouseLeave={(e) => e.currentTarget.blur()}
                >
                  Back
                </ATag>
              </BtnWrapper>
            </div>
          </div>
        </div>
      </PaymentDetailsStyleWrapper>
    </>
  );
}
