import { taskTypes } from "./taskTypes";
import { TaskAction, TaskTypes } from "./types";
let initState: TaskTypes = {
  successMessage: "",
  errorMessage: "",
  taskOwners: [],
  keyonbasisofObjective: [],
  singalTaskDetails: {},
  taskComments: { pagedItems: [] },
  objParent: {
    vision_id: null,
    vision_name: '',
    mission_id: null,
    mission_name: '',
    strategy_id: null,
    strategy_name: '',
    benefit_id: null,
    benefit_name: '',
  },
};
export default function authReducer(state = initState, action: TaskAction) {
  switch (action.type) {
    case taskTypes.POST_TASKS:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    case taskTypes.POST_TASKS_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case taskTypes.POST_TASKS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case taskTypes.GET_TASKS:
      return {
        ...state,
        // successMessage: "",
        // errorMessage: "",
      };
    case taskTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        singalTaskDetails: action.singalTaskDetails,
        objParent: action.objParent,
      };
    case taskTypes.GET_TASKS_FAILED:
      return {
        ...state,
      };
    case taskTypes.PATCH_TASKS:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    case taskTypes.PATCH_TASKS_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case taskTypes.PATCH_TASKS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case taskTypes.GET_TASKS_OWNER_SUCCESS:
      return {
        ...state,
        taskOwners: action.taskOwners,
      };
    case taskTypes.GET_TASKS_KEY_RESULT_SUCCESS:
      return {
        ...state,
        keyonbasisofObjective: action.keyonbasisofObjective,
      };
    case taskTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case taskTypes.GET_TASKS_COMMENTS_SUCCESS:
      return {
        ...state,
        taskComments: action.taskComments,
      };

    case taskTypes.PATCH_TASK_STAGE:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case taskTypes.PATCH_TASK_STAGE_SUCCESS:
      return {
        ...state,
        successMessage: "Task status is updated successfully.",
      };
    case taskTypes.PATCH_TASK_STAGE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case taskTypes.PATCH_HELPFULNESS_RATING:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case taskTypes.PATCH_HELPFULNESS_RATING_SUCCESS:
      return {
        ...state,
        successMessage: "Employee helpfullness rating updated successfully.",
      };
    case taskTypes.PATCH_HELPFULNESS_RATING_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case taskTypes.DELETE_HELPFULNESS_RATING:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case taskTypes.DELETE_HELPFULNESS_RATING_SUCCESS:
      return {
        ...state,
        successMessage: "Employee helpfullness rating deleted successfully.",
      };
    case taskTypes.DELETE_HELPFULNESS_RATING_FAILED:
      return {
        ...state,
      };
    case taskTypes.DELETE_TASKS:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case taskTypes.DELETE_TASKS_SUCCESS:
      return {
        ...state,
      };
    case taskTypes.DELETE_TASKS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
}
