export enum promoCodeTypes {
  ADD_PROMO_CODE = 'ADD_PROMO_CODE',
  ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS',
  ADD_PROMO_CODE_FAILURE = 'ADD_PROMO_CODE_FAILURE',

  GENERATE_PROMO_CODE = 'GENERATE_PROMO_CODE',
  GENERATE_PROMO_CODE_SUCCESS = 'GENERATE_PROMO_CODE_SUCCESS',
  GENERATE_PROMO_CODE_FAILURE = 'GENERATE_PROMO_CODE_FAILURE',

  GET_PROMO_LIST = 'GET_PROMO_LIST',
  GET_PROMO_LIST_SUCCESS = 'GET_PROMO_LIST_SUCCESS',
  GET_PROMO_LIST_FAILURE = 'GET_PROMO_LIST_FAILURE',

  GET_PROMO_DETAIL = 'GET_PROMO_DETAIL',
  GET_PROMO_DETAIL_SUCCESS = 'GET_PROMO_DETAIL_SUCCESS',
  GET_PROMO_DETAIL_FAILURE = 'GET_PROMO_DETAIL_FAILURE',

  GET_PROMO_DETAIL_LIST = 'GET_PROMO_DETAIL_LIST',
  GET_PROMO_DETAIL_LIST_SUCCESS = 'GET_PROMO_DETAIL_LIST_SUCCESS',
  GET_PROMO_DETAIL_LIST_FAILURE = 'GET_PROMO_DETAIL_LIST_FAILURE',

  DELETE_PROMO_CODE = 'DELETE_PROMO_CODE',
  DELETE_PROMO_CODE_SUCCESS = 'DELETE_PROMO_CODE_SUCCESS',
  DELETE_PROMO_CODE_FAILURE = 'DELETE_PROMO_CODE_FAILURE',

  UPDATE_PROMO_CODE = 'UPDATE_PROMO_CODE',
  UPDATE_PROMO_CODE_SUCCESS = 'UPDATE_PROMO_CODE_SUCCESS',
  UPDATE_PROMO_CODE_FAILURE = 'UPDATE_PROMO_CODE_FAILURE',

  REMOVE_ALERT = 'REMOVE_ALERT'
}
