import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { notificationActions } from "./notificationActions";
import authActions from "../app/action";
import { profileAction } from "../profile/actionTypes";
import {
  readNotificationsPayload,
} from "./types";
import {
  chnageNotificationStatusReq,
  getNotificationReq,
  setNotificationSoundReq,
} from "../../library/services/NotificationReq";
export function* notificationsEffect() {
  yield takeEvery(notificationActions.GET_NOTIFICATION, function* () {
    try {
      const response = yield call(getNotificationReq);

      if (response) {
        yield put({
          type: notificationActions.GET_NOTIFICATION_SUCCESS,
          Notifications: response.data?.data,
        });
      }
    } catch (error: any) {
      yield put({
        type: notificationActions.GET_NOTIFICATION_FAILED,
        payload: error?.data?.message,
      });
    }
  });
}

export function* notificationSoundEffect() {
  yield takeEvery(notificationActions.SET_NOTIFICATION_SOUND, function* () {
    try {
      const response: any = yield call(setNotificationSoundReq);
      if (response.status) {
        yield put({
          type: profileAction.GET_PROFILE_REQUEST,
          payload: { loading: false },
        });
      }
    } catch (error: any) {
      yield put({
        type: notificationActions.SET_NOTIFICATION_SOUND_FAILED,
        payload: error.data.message,
      });
    }
  });
}

export function* notificationStatusEffect() {
  yield takeEvery(
    notificationActions.READ_NOTIFICATION,
    function* ({
      payload,
    }: {
      type: string;
      payload: readNotificationsPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        yield call(chnageNotificationStatusReq, payload);
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(notificationsEffect),
    fork(notificationStatusEffect),
    fork(notificationSoundEffect),
  ]);
}
