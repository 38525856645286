import { notificationActions } from "./notificationActions";
import { NotificationState, NotificationAction } from "./types";
let initState: NotificationState = {
  NotificationList: [],
  Sound: true,
  IntervalID: null,
  isNewNotificationcount: 0,
  isNewNotification: false,
  isShow: true,
};
export default function NotificationReducer(
  state = initState,
  action: NotificationAction
) {
  switch (action.type) {
    case notificationActions.GET_NOTIFICATION:
      return {
        ...state,
      };
    case notificationActions.GET_NOTIFICATION_SUCCESS:
      if (localStorage.getItem("new_notification")) {
        let newNotification: any =
          Number(localStorage.getItem("new_notification_count")) <
          action.Notifications.length
            ? true
            : false;
        localStorage.setItem("new_notification", newNotification + "");
        newNotification &&
          localStorage.setItem(
            "new_notification_count",
            action.Notifications.length + ""
          );
      } else {
        localStorage.setItem("new_notification", "true");
        localStorage.setItem(
          "new_notification_count",
          action.Notifications.length + ""
        );
      }
      return {
        ...state,
        NotificationList: action.Notifications,
        isNewNotificationcount: action.Notifications.length,
        isShow: action.Notifications.length === 0 ? false : true,
      };
    case notificationActions.GET_NOTIFICATION_FAILED:
      return state;

    case notificationActions.SET_NOTIFICATION_SOUND_SUCCESS:
      return {
        ...state,
        Sound: action.Sound,
      };
    case notificationActions.SET_NOTIFICATION_SOUND_FAILED:
      return state;
    case notificationActions.SET_INT_ID:
      return {
        ...state,
        IntervalID: action.intId,
      };

    default:
      return state;
  }
}
