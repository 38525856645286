import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { WorkspaceTypes } from './actionTypes';
import authActions from '../../app/action';
import {
  editSubscriptionDetailsReq,
  workspaceActiveLogReq,
  workspaceChangeUserRoleReq,
  workspaceDetailsReq,
  workspaceList,
  workspaceSubscriptionDetailsReq,
  workspaceSubscriptionHistoryReq,
  workspaceTransactionsReq,
  workspaceUserListReq,
  workspaceVmsbOkrDetailsReq
} from '../../../library/services/Admin/workspace';
import { defaultPagination } from '../../../config/constant';

export function* workspaceListEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_LIST_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceList, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.workspaces.metaData?.totalPages &&
            payload.page > response.data.data.workspaces.metaData?.totalPages
          ) {
            yield put({
              type: WorkspaceTypes.WORKSPACE_LIST_REQUEST,
              payload: { ...payload, page: 1 }
            });
          } else {
            yield put({
              type: WorkspaceTypes.WORKSPACE_LIST_SUCCESS,
              allWorkspaces: response.data.data.workspaces.pagedItems,
              totalCompanies: response.data.data.workspaces.metaData
            });
          }
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_LIST_FAILED, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_LIST_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceListExportEffect() {
  yield takeEvery(
    WorkspaceTypes.EXPORT_WORKSPACE_LIST_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceList, payload);
        if (response) {
          yield put({
            type: WorkspaceTypes.EXPORT_WORKSPACE_LIST_SUCCESS,
            exportableWorkspaceList: response.data.data.workspaces
          });
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_LIST_FAILED, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_LIST_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceDetailEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_DETAIL_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceDetailsReq, payload);
        if (response) {
          yield put({
            type: WorkspaceTypes.WORKSPACE_DETAIL_SUCCESS,
            workspace: response.data.data
          });
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_DETAIL_FAILED, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_DETAIL_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceSubscriptionDetailEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceSubscriptionDetailsReq, payload);
        if (response) {
          yield put({
            type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_SUCCESS,
            workspaceSubscription: response.data.data.workspacesDetails
          });
        } else {
          yield put({
            type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_FAILURE,
            errorMessage: response.data.message
          });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_FAILURE, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceVmsbOkrDetailEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceVmsbOkrDetailsReq, payload);
        if (response) {
          yield put({
            type: WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_SUCCESS,
            workspaceVmsbOkr: response.data.data.workspacesVmsbOkrCount
          });
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_FAILURE, errorMessage: response.data.message });
        }
      } catch (error: any) {
        yield put({ type: WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_FAILURE });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceChangeRoleEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_CHANGE_ROLE_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceChangeUserRoleReq, payload);
        if (response) {
          yield all([
            put({
              type: WorkspaceTypes.WORKSPACE_CHANGE_ROLE_SUCCESS,
              roleChangeSuccess: response.data.message
            }),
            put({
              type: WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_REQUEST,
              payload: { id: payload.id, page: defaultPagination.page, take: defaultPagination.take }
            })
          ]);
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_CHANGE_ROLE_FAILURE, roleChangeError: response.data.message });
        }
      } catch (error: any) {
        yield put({ type: WorkspaceTypes.WORKSPACE_CHANGE_ROLE_FAILURE, roleChangeError: error.response.data.message });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceUserListEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceUserListReq, payload);
        if (response.status === (201 || 200)) {
          if (response.data.data.metaData?.totalPages && payload.page > response.data.data.metaData?.totalPages) {
            yield put({
              type: WorkspaceTypes.WORKSPACE_DETAIL_PAGE_CHANGE,
              totalUsers: response.data.data.metaData
            });
          } else {
            yield put({
              type: WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_SUCCESS,
              allWorkspaceUsers: response.data.data,
              totalUsers: response.data.data.metaData
            });
          }
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_FAILURE, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_FAILURE, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceSubscriptionHistoryEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_SUBSCRIPTION_HISTORY_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceSubscriptionHistoryReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.subscriptionHistory.metaData?.totalPages &&
            payload.page > response.data.data.subscriptionHistory.metaData?.totalPages
          ) {
            yield put({
              type: WorkspaceTypes.WORKSPACE_DETAIL_PAGE_CHANGE,
              totalSubscriptions: response.data.data.metaData
            });
          } else {
            yield put({
              type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_HISTORY_SUCCESS,
              workspaceSubscriptions: response.data.data.subscriptionHistory.pagedItems,
              totalSubscriptions: response.data.data.subscriptionHistory.metaData
            });
          }
        } else {
          yield put({
            type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_HISTORY_FAILURE,
            errorMessage: response.data.message
          });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_HISTORY_FAILURE, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceActiveLogsEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_ACTIVE_LOG_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceActiveLogReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.activityLog.metaData?.totalPages &&
            payload.page > response.data.data.activityLog.metaData?.totalPages
          ) {
            yield put({
              type: WorkspaceTypes.WORKSPACE_DETAIL_PAGE_CHANGE,
              totalLogs: response.data.data.metaData
            });
          } else {
            yield put({
              type: WorkspaceTypes.WORKSPACE_ACTIVE_LOG_SUCCESS,
              allActiveLogs: response.data.data.activityLog.pagedItems,
              totalLogs: response.data.data.activityLog.metaData
            });
          }
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_ACTIVE_LOG_FAILURE, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_ACTIVE_LOG_FAILURE, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceTransactionsEffect() {
  yield takeEvery(
    WorkspaceTypes.WORKSPACE_TRANSACTIONS_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceTransactionsReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.transactionHistory.metaData?.totalPages &&
            payload.page > response.data.data.transactionHistory.metaData?.totalPages
          ) {
            yield put({
              type: WorkspaceTypes.WORKSPACE_DETAIL_PAGE_CHANGE,
              totalTransactions: response.data.data.metaData
            });
          } else {
            yield put({
              type: WorkspaceTypes.WORKSPACE_TRANSACTIONS_SUCCESS,
              allWorkspaceTransactions: response.data.data.transactionHistory.pagedItems,
              totalTransactions: response.data.data.transactionHistory.metaData
            });
          }
        } else {
          yield put({ type: WorkspaceTypes.WORKSPACE_TRANSACTIONS_FAILURE, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.WORKSPACE_TRANSACTIONS_FAILURE, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* editSubscriptionDetailsEffect() {
  yield takeEvery(
    WorkspaceTypes.EDIT_SUBSCRIPTION_DETAIL_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string | number> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(editSubscriptionDetailsReq, payload);
        if (response) {
          yield all([
            put({
              type: WorkspaceTypes.EDIT_SUBSCRIPTION_DETAIL_SUCCESS
            }),
            put({
              type: WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_REQUEST,
              payload: { id: payload.workspaceId }
            })
          ]);
        } else {
          yield put({ type: WorkspaceTypes.EDIT_SUBSCRIPTION_DETAIL_FAILURE, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceTypes.EDIT_SUBSCRIPTION_DETAIL_FAILURE, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* WorkspaceSaga() {
  yield all([
    fork(workspaceListEffect),
    fork(workspaceDetailEffect),
    fork(workspaceVmsbOkrDetailEffect),
    fork(workspaceSubscriptionDetailEffect),
    fork(workspaceSubscriptionHistoryEffect),
    fork(workspaceActiveLogsEffect),
    fork(workspaceUserListEffect),
    fork(workspaceTransactionsEffect),
    fork(workspaceChangeRoleEffect),
    fork(workspaceListExportEffect),
    fork(editSubscriptionDetailsEffect)
  ]);
}
