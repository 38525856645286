import { workspaceActions } from './workspaceActions';
import { workspaceAction, workspaceReducerStates } from './types';

let initState: workspaceReducerStates = {
  errorMessage: '',
  openOTPVarification: false,
  otpVerificationError: '',
  otpResendSuccess: '',
  workspaceList: [],
  loading: false
};

export default function authReducer(state = initState, action: workspaceAction) {
  switch (action.type) {
    case workspaceActions.POST_0AUTH_SIGNIN:
      return {
        ...state
      };
    case workspaceActions.POST_0AUTH_SIGNIN_SUCCESS:
      return {
        ...state
      };
    case workspaceActions.POST_0AUTH_SIGNIN_FAILED:
      return {
        ...state,
        errorMessage:action?.errorMessage
      };

    case workspaceActions.POST_WORKSPACE_EMAIL:
      return {
        ...state
      };
    case workspaceActions.POST_WORKSPACE_EMAIL_SUCCESS:
      return {
        ...state,
        openOTPVarification: true
      };
    case workspaceActions.POST_WORKSPACE_EMAIL_FAILED:
      return {
        ...state,
        errorMessage: action?.errorMessage
      };
    case workspaceActions.GET_WORKSPACE_LIST:
      return {
        ...state
      };
    case workspaceActions.GET_WORKSPACE_LIST_SUCCESS:
      return {
        ...state,
        workspaceList: action.workspaceList
      };
    case workspaceActions.GET_WORKSPACE_LIST_FAILED:
      return {
        ...state,
        errorMessage: action?.errorMessage
      };
    case workspaceActions.POST_WORKSPACE_OTP:
      return {
        ...state,
        otpVerificationError: '',
        otpResendSuccess: '',
        loading: true
      };
    case workspaceActions.POST_WORKSPACE_OTP_SUCCESS:
      return {
        ...state,
        otpResendSuccess: action?.otpResendSuccess,
        loading: false
      };
    case workspaceActions.POST_WORKSPACE_OTP_FAILED:
      return {
        ...state,
        otpVerificationError: action.otpVerificationError,
        loading: false
      };
    case workspaceActions.PATCH_WORKSPACE_RESEND_OTP:
      return {
        ...state,
        otpVerificationError: '',
        otpResendSuccess: ''
      };
    case workspaceActions.PATCH_WORKSPACE_RESEND_OTP_SUCCESS:
      return {
        ...state,
        otpVerificationError: '',
        otpResendSuccess: action.otpResendSuccess
      };
    case workspaceActions.PATCH_WORKSPACE_RESEND_OTP_FAILED:
      return {
        ...state,
        otpResendSuccess: '',
        otpVerificationError: action.otpVerificationError
      };
    case workspaceActions.CLEAR_MESSAGES:
      return {
        ...state,
        errorMessage: '',
        otpVerificationError: '',
        otpResendSuccess: ''
      };
    case workspaceActions.CLOSE_OTP_MODAL:
      return {
        ...state,
        openOTPVarification: false
      };
    default:
      return state;
  }
}
