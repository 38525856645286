export enum visionTypes {
  POST_VISION = "POST_VISION",
  POST_VISION_SUCCESS = "POST_VISION_SUCCESS",
  POST_VISION_FAILED = "POST_VISION_FAILED",

  GET_VISION = "GET_VISION",
  GET_VISION_SUCCESS = "GET_VISION_SUCCESS",
  GET_VISION_FAILED = "GET_VISION_FAILED",

  PATCH_VISION = "PATCH_VISION",
  PATCH_VISION_SUCCESS = "PATCH_VISION_SUCCESS",
  PATCH_VISION_FAILED = "PATCH_VISION_FAILED",

  GET_MISSION_IN_VISION = "GET_MISSION_IN_VISION",
  GET_MISSION_IN_VISION_SUCCESS = "GET_MISSION_IN_VISION_SUCCESS",
  GET_MISSION_IN_VISION_FAILED = "GET_MISSION_IN_VISION_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",
}
