import styled from 'styled-components';

import WithDirection from '../../library/helpers/rtl';

const SubscriptionPlanStyleWrapper = styled.div`
  position: relative;
  background-color: #f7f7f7;
  .isoPlanContentWrapper {
    height: 100%;
    display: flex;
    background-color: #f7f7f7;
    .isoPlanContent {
      width: 100%;
      margin: 40px 30px;
      background-color: #f7f7f7;
    }
  }
  .changePlanContentWrapper {
    .isoPlanContent {
      margin: 30px 30px !important;
    }
  }
  .ssform {
    display: block;
    align-items: center;
    .form-tooltip {
      display: flex;
      justify-content: center;
      gap: 10px;
      width: fit-content;
      position: relative;
      margin: 0px auto;
      padding-right: 25px;
    }
    .icon {
      color: gray;
      margin-top: 5px;
      position: absolute;
      right: 0px;
    }
    .license-input {
      margin: auto;
      width: fit-content;
      align-items: baseline;
      justify-content: center;
      display: flex;
      .ant-form-item-label {
        text-align: center;
        width: auto;
        margin-right: 20px;
        .anticon {
          position: inherit;
          right: 0px !important;
        }
        > label::after {
          right: 0px;
        }
      }
      .ant-form-item-control {
        text-align: center;
        /* width: auto; */
        flex: none;
        .ant-form-item-control-input-content {
          flex: none;
          .users-input {
            width: 70px;
          }
          .icon {
            margin-left: 4px;
            color: gray;
          }
        }
        .ant-form-item-explain {
          position: absolute;
          width: max-content;
          bottom: -28px;
        }
      }
      .ant-form-item-label > label {
        color: #2d2c2c;
      }
      .ant-input {
        width: 50px;
      }
    }
  }
  .ant-card {
    border: none;
    border-radius: 5px;
    .ant-card-head {
      border: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #22c4f2;
      .plan-title {
        margin: 0;
        color: #fff;
      }
      .ant-card-head-title {
        padding: 20px 0px;
        position: relative;
        @media only screen and (max-width: 1600px) {
          padding: 15px 0px;
        }
        .tag {
          position: absolute;
          right: 15px;
          top: 4px;
          font-size: 10px;
          line-height: 10px;
          font-weight: 600;
          color: #035592;
          background-color: #fff;
          padding: 5px 12px;
          border-radius: 19px;
          @media only screen and (max-width: 1199px) {
            right: 9px;
            top: 4px;
            font-size: 8px;
            line-height: 9px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
        .free-note {
          position: absolute;
          font-size: 10px;
          color: #035592;
          font-weight: 600;
          /* margin-left: 22%; */
          bottom: 2px;
          left: 50%;
          transform: translateX(-50%);
          @media screen and (max-width: 1600px) {
            bottom: 1px;
          }
          @media only screen and (max-width: 1199px) {
            font-size: 10px;
            /* line-height: 9px; */
            bottom: 0;
          }
        }
      }
      h1 {
        color: #fff;
        text-align: center;
        font-weight: 700;
        margin: 0px;
        sup {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          vertical-align: super;
        }
      }
    }
    .ant-card-body {
      padding: 0px 30px 40px;
      box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      @media only screen and (max-width: 1800px) {
        padding: 0px 25px 35px;
      }
      @media only screen and (max-width: 1600px) {
        padding: 0px 20px 30px;
      }
      @media only screen and (max-width: 1440px) {
        padding: 0px 15px 25px;
      }
      .price > .strike-text {
        text-decoration: line-through !important;
        font-size: 12px;
        @media screen and (max-width: 1100px) {
          display: block;
        }
      }
      .price > .time {
        font-size: 12px !important;
      }
      h1,
      p {
        text-align: center;
      }
      .plan-amount {
        margin: 5px 0px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 46px;
        line-height: 55px;
        font-family: 'Inter-Bold', sans-serif;
        span {
          &.original {
            color: #b8b8b8;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: #b8b8b8;
              right: -2px;
              top: 50%;
              transform: translate(0px, -50%);
              border-bottom: 1px solid #fff;
            }
          }
        }
        sup {
          font-family: 'Inter-Regular', sans-serif;
          font-size: 12px;
          line-height: 19px;
          vertical-align: super;
        }
        @media only screen and (max-width: 1800px) {
          font-size: 38px;
          line-height: 48px;
          margin: 20px 0px;
        }
        @media only screen and (max-width: 1600px) {
          font-size: 36px;
          line-height: 46px;
          margin: 15px 0px;
        }
        @media only screen and (max-width: 1440px) {
          font-size: 34px;
          line-height: 40px;
        }
        @media only screen and (max-width: 1199px) {
          font-size: 32px;
          line-height: 38px;
        }
      }
      .ant-list-item {
        padding: 0px;
        margin-bottom: 25px;
        border: none;
        &:last-child {
          margin-bottom: 0px;
        }
        @media only screen and (max-width: 1800px) {
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 1600px) {
          margin-bottom: 15px;
        }
        @media only screen and (max-width: 1440px) {
          margin-bottom: 10px;
        }
      }
      .ant-list-item-meta {
        align-items: baseline;
        .ant-list-item-meta-avatar {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          .custom-list-icon {
            width: 12px;
            height: 12px;
            background: #035592;
            border-radius: 4px;
          }
          @media only screen and (max-width: 1199px) {
            margin-right: 10px;
          }
        }
        .ant-list-item-meta-content {
          h4.ant-list-item-meta-title {
            font-size: 16px;
            line-height: 19px;
            color: #2d2c2c;
            margin: 0px;
            text-align: left;
            @media only screen and (max-width: 1800px) {
              font-size: 15px;
            }
            @media only screen and (max-width: 1440px) {
              font-size: 14px;
            }
          }
        }
      }
      .cmn-btn {
        cursor: pointer;
        height: auto;
        margin-top: 30px;
        border-color: #035592;
        @media only screen and (max-width: 1800px) {
          margin-top: 25px;
        }
        @media only screen and (max-width: 1600px) {
          margin-top: 20px;
        }
        @media only screen and (max-width: 1440px) {
          margin-top: 15px;
        }
        i.icon-check {
          vertical-align: middle;
          margin-right: 5px;
          &:before {
            color: #fff;
          }
        }
        &:hover,
        &:focus {
          background-color: #fff;
          color: #035592;
          i.icon-check::before {
            color: #035592 !important;
          }
        }
      }
      .disabled-cmn-btn {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        cursor: not-allowed !important;
        &:hover {
          border-color: #1890ff;
          background-color: #f5f5f5;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .plan-desc {
    color: #035592;
    margin: 85px 0px 35px;
    font-weight: 600;
    &.last {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1800px) {
      margin: 70px 0px 30px;
    }
    @media only screen and (max-width: 1600px) {
      margin: 60px 0px 25px;
    }
    @media only screen and (max-width: 1440px) {
      margin: 50px 0px 20px;
    }
  }
  .change-plan-container {
    .isoPlanContent > .plan-container {
      .ant-row {
        align-items: center !important;
        justify-content: center !important;
        .ant-col {
          @media screen and (max-width: 1400px) {
            flex: 50% !important;
            max-width: 50% !important;
            margin-bottom: 40px !important;
          }
        }
      }
    }
    &.promocode {
      padding: 30px;
      margin-top: 20px;
      .promocode-input {
        display: flex;
        margin: 10px auto 0;
        justify-content: center;
        max-width: 500px;
        input {
          width: 400px;
        }
        .invalid-promo-input {
          border: 1px solid #e02929;
        }
        .valid-promo-input {
          border: 1px solid #00b95d;
        }
        button {
          margin-left: 20px;
        }
      }
      span {
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: 600;
        width: 100%;
        text-align: left;
        display: flex;
        max-width: 500px;
        margin: 5px auto 0;
      }
      .invalid-code {
        color: #e02929;
      }
      .valid-promo {
        color: #00b95d;
      }
    }
  }
  .plan-container {
    margin: 0px auto auto;
    .selected-plan-card-body {
      border: 2px solid #035592;
    }
    .ant-row {
      align-items: center !important;
      justify-content: center !important;

      .ant-col {
        @media only screen and (min-width: 1600px) {
          max-width: 25%;
        }
        .ant-card {
          .ant-card-head {
            h1 {
              font-size: 18px;
            }
            .ant-card-head-wrapper > .ant-card-head-title {
              position: relative;
              .tag {
                /* position: absolute;
                right: 15px; */
                top: 3px;
                font-size: 9px;
                /* line-height: 12px;
                font-weight: 600;
                color: #035592;
                background-color: #fff; */
                padding: 3px 6px;
                /* border-radius: 19px; */
              }
              .free-note {
                position: absolute;
                font-size: 10px;
                color: #035592;
                font-weight: 600;
                /* margin-left: 25%; */
                bottom: 2px;
                left: 50%;
                transform: translateX(-50%);
                @media screen and (max-width: 1600px) {
                  bottom: 1px;
                  /* margin-left: 22% !important; */
                  /* margin-bottom: 2px; */
                }
              }
            }
          }
          .ant-card-body {
            .plan-amount {
              font-size: 22px;
              line-height: 40px;
              .strike-text {
                text-decoration: line-through !important;
                font-size: 16px;
              }
              .time {
                font-size: 12px !important;
              }
            }
            .ant-btn {
              font-size: 14px;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    } 
  }

  .plan-desc {
    color: #32386a;
    margin: 35px auto;
    font-family: 'Inter-Bold', sans-serif;
    font-weight: 400;
    text-align: center;
    @media only screen and (max-width: 1600px) {
      margin: 30px auto;
    }
    @media only screen and (max-width: 1440px) {
      margin: 25px auto;
    }
    @media only screen and (max-width: 1199px) {
      margin: 20px auto;
    }
  }
  .subscription-table-wrapper {
    .ant-row {
      align-items: center;
      justify-content: center;
    }
    max-width: 1252px;
    margin: auto;
    .selected-plan-card-body {
      border: 2px solid #035592;
    }
    .super-title {
      padding: 20px;
      background: #035592;
      color: white;
      text-align: center;
      margin: 0;
      @media only screen and (max-width: 1800px) {
        padding: 18px;
      }
      @media only screen and (max-width: 1600px) {
        padding: 15px;
      }
      @media only screen and (max-width: 1440px) {
        padding: 10px;
      }
    }

    .cmn-btn {
      margin-top: 45px;
      &:hover {
        background-color: #fff;
        border-color: #035592;
        color: #035592;
      }
      @media only screen and (max-width: 1800px) {
        margin-top: 40px;
      }
      @media only screen and (max-width: 1600px) {
        margin-top: 35px;
      }
    }
    .cancelPlanLink {
      color: #e02929;
      display: block;
      text-align: center;
    }
    .redirect > .anchor-tag > span {
      font-size: 15px;
    }
  }
  .redirect {
    color: #2d2c2c;

    margin: 25px 0;
    font-family: 'Inter-Regular', sans-serif;
    span {
      font-size: 26px;
      line-height: 31px;
      @media only screen and (max-width: 1800px) {
        font-size: 22px;
        line-height: 28px;
      }
      @media only screen and (max-width: 1600px) {
        font-size: 20px;
        line-height: 26px;
      }
      @media only screen and (max-width: 1440px) {
        font-size: 18px;
      }
    }
  }
  .redirect-plan {
    color: #2d2c2c;
    margin-top: 25px;
    font-weight: 400;
    @media only screen and (max-width: 1800px) {
      margin-top: 20px;
    }
    @media only screen and (max-width: 1600px) {
      margin-top: 15px;
    }
    @media only screen and (max-width: 1440px) {
      margin-top: 10px;
    }
    span {
      transition: 0.3s;
      color: #22c4f2;
      &:hover {
        color: #2d2c2c;
      }
    }
  }

  .subscription-table {
    box-shadow: 0px 1px 4px 0px #22c4f233;
    padding: 20px;
    background: white;
    padding-top: 0px !important;
    @media only screen and (max-width: 1800px) {
      padding: 15px;
    }
    @media only screen and (max-width: 1440px) {
      padding: 12px;
    }
  }

  table {
    padding: 20px;
    padding-top: 0;
    /* border-spacing:20px 0; */
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    @media only screen and (max-width: 1800px) {
      padding: 15px;
    }
    @media only screen and (max-width: 1440px) {
      padding: 12px;
    }
  }
  .ant-table {
    .ant-table-thead,
    .ant-table-tbody {
      & > tr {
        box-shadow: none;

        & > td {
          border: none;
          border: 20px solid white;
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          border: none;
          padding: 0;
          &:not(:first-child) {
            background: #32386a;
          }
          .plan-wrapper {
            background-color: #32386a;
            text-align: center;
            font-family: 'Inter-Bold', sans-serif;
            font-weight: 400;
            color: white;
            padding: 25px;
            position: relative;
            .tag {
              position: absolute;
              left: 50%;
              bottom: -12px;
              transform: translate(-50%, 0);
              background-color: #22c4f2;
              font-size: 12px;
              line-height: 12px;
              padding: 8px 14px;
              border-radius: 20px;
              font-weight: 600;
              font-family: 'Inter-Regular', sans-serif;
            }
            @media only screen and (max-width: 1600px) {
              padding: 20px;
            }
            @media only screen and (max-width: 1440px) {
              padding: 15px;
            }
            @media only screen and (max-width: 1199px) {
              padding: 10px;
            }
            .super-title {
              background: transparent;
              padding: 0;
            }
            .updated-price {
              margin: 5px 0px;
              font-size: 46px;
              line-height: 55px;
              @media only screen and (max-width: 1800px) {
                font-size: 40px;
                line-height: 50px;
              }
              @media only screen and (max-width: 1600px) {
                font-size: 38px;
                line-height: 42px;
              }
              @media only screen and (max-width: 1440px) {
                font-size: 36px;
                line-height: 40px;
              }
              @media only screen and (max-width: 1199px) {
                font-size: 34px;
                line-height: 38px;
              }
              @media only screen and (max-width: 1199px) {
                font-size: 30px;
                line-height: 32px;
              }
              color: #22c4f2;
              span {
                vertical-align: middle;
              }
              .original {
                color: #fff;
                position: relative;
                font-size: 30px;
                line-height: 36px;
                margin-right: 10px;
                sup {
                  font-size: 10px;
                  line-height: 13px;
                }
                &::before {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background-color: #22c4f2;
                  right: -2px;
                  top: 50%;
                  transform: translate(0px, -50%);
                }
                @media screen and (max-width: 1600px) {
                  font-size: 28px;
                  line-height: 34px;
                }
                @media screen and (max-width: 1440px) {
                  font-size: 26px;
                  line-height: 32px;
                  margin-right: 5px;
                }
                @media screen and (max-width: 1199px) {
                  font-size: 24px;
                  line-height: 30px;
                }
                @media screen and (max-width: 991px) {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
              sup {
                font-size: 16px;
                line-height: 20px;
                font-family: 'Inter-Regular', sans-serif;
                vertical-align: super;
                @media screen and (max-width: 1600px) {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }
            .price {
              margin: 5px 0px;
              font-size: 46px;
              line-height: 55px;
              @media only screen and (max-width: 1800px) {
                font-size: 40px;
                line-height: 50px;
              }
              @media only screen and (max-width: 1600px) {
                font-size: 38px;
                line-height: 42px;
              }
              @media only screen and (max-width: 1440px) {
                font-size: 36px;
                line-height: 40px;
              }
              @media only screen and (max-width: 1199px) {
                font-size: 34px;
                line-height: 38px;
              }
              @media only screen and (max-width: 1199px) {
                font-size: 30px;
                line-height: 32px;
              }
              &.strike-text {
                text-decoration: line-through !important;
                font-size: 16px;
              }
              color: #22c4f2;
              span {
                vertical-align: middle;
              }
              .original {
                color: #fff;
                position: relative;
                font-size: 30px;
                line-height: 36px;
                margin-right: 10px;
                sup {
                  font-size: 10px;
                  line-height: 13px;
                }
                &::before {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 2px;
                  background-color: #22c4f2;
                  right: -2px;
                  top: 50%;
                  transform: translate(0px, -50%);
                }
                @media screen and (max-width: 1600px) {
                  font-size: 28px;
                  line-height: 34px;
                }
                @media screen and (max-width: 1440px) {
                  font-size: 26px;
                  line-height: 32px;
                  margin-right: 5px;
                }
                @media screen and (max-width: 1199px) {
                  font-size: 24px;
                  line-height: 30px;
                }
                @media screen and (max-width: 991px) {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
              sup {
                font-size: 16px;
                line-height: 20px;
                font-family: 'Inter-Regular', sans-serif;
                vertical-align: super;
                @media screen and (max-width: 1600px) {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }
            p {
              font-family: 'Inter-Regular', sans-serif;
            }
          }
        }
      }
    }
    .ant-table-tbody {
      & > tr {
        &:nth-child(even) {
          background-color: #f4fdff;
        }
        &:last-child {
          td {
            background-color: white;
            border: none;
            border-top: 1px solid #dddddd;
          }
        }
        & > td {
          background-color: transparent;
          font-weight: 400;
          text-align: center;
          border: 1px solid #dddddd;
          border-top: none;
          border-bottom: none;
          .cmn-btn {
            margin: 0;
          }
          &:first-child {
            width: 300px;
            text-align: left;
            border: none;
          }
        }
      }
    }
  }
  .note {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px;
    color: #035592;
    font-weight: 600;
  }
`;
export default WithDirection(SubscriptionPlanStyleWrapper);
