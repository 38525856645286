export enum commentActions {
  POST_COMMENTS = "POST_COMMENTS",
  POST_COMMENTS_SUCCESS = "POST_COMMENTS_SUCCESS",
  POST_COMMENTS_FAILED = "POST_COMMENTS_FAILED",

  GET_COMMENTS = "GET_COMMENTS",
  GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS",
  GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED",

  GET_COMMENTS_NON_OKR_USER = "GET_COMMENTS_NON_OKR_USER",
  GET_COMMENTS_NON_OKR_USER_SUCCESS = "GET_COMMENTS_NON_OKR_USER_SUCCESS",
  GET_COMMENTS_NON_OKR_USER_FAILURE = "GET_COMMENTS_NON_OKR_USER_FAILURE",

  POST_COMMENTS_NON_OKR_USER = "POST_COMMENTS_NON_OKR_USER",
  POST_COMMENTS_NON_OKR_USER_SUCCESS = "POST_COMMENTS_NON_OKR_USER_SUCCESS",
  POST_COMMENTS_NON_OKR_USER_FAILURE = "POST_COMMENTS_NON_OKR_USER_FAILURE",

  POST_VMSB_COMMENTS = "VMSB_COMMENTS",
  POST_VMSB_COMMENTTS_SUCCESS = "VMSB_COMMENTTS_SUCCESS",
  POST_VMSB_COMMENTS_FAILED = "VMSB_COMMENTS_FAILED",

  GET_VMSB_COMMENTS = "GET_VMSB_COMMENTS",
  GET_VMSB_COMMENTS_SUCCESS = "GET_VMSB_COMMENTS_SUCCESS",
  GET_VMSB_COMMENTS_FAILED = "GET_VMSB_COMMENTS_FAILED"
}
