import { globalActionTypes } from './actiontypes';
import { globalState, globalStateAction } from './types';

const levels = {
  low: null,
  medium: null,
  high: null,
  low_color: '',
  medium_color: '',
  high_color: ''
};

const defaultConfiguration = {
  id: null,
  confidence_level: levels,
  committed: levels,
  aspirational: levels,
  committed_and_aspirational: levels,
  assistance_points: null,
  helpfulness_rating: null,
  financial_year_month: null,
  period: null,
  grace_period: null
};

const initState: globalState = {
  allTooltips: [],
  defaultConfigs: defaultConfiguration,
  defaultConfigError: ''
};

export default function globalReducer(state = initState, action: globalStateAction) {
  switch (action.type) {
    case globalActionTypes.GET_TOOLTIPS_SUCCESS:
      return {
        ...state,
        allTooltips: action.allTooltips
      };
    case globalActionTypes.DEFAULT_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        defaultConfigError: ''
      };
    case globalActionTypes.DEFAULT_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        defaultConfigs: action.defaultConfigs
      };
    case globalActionTypes.DEFAULT_CONFIGURATIONS_FAILED:
      return {
        ...state,
        defaultConfigError: 'Default configurations not added.',
        defaultConfigs: defaultConfiguration
      };
    default:
      return {
        ...state
      };
  }
}
