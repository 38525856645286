import React, { ReactNode } from "react";
import LoaderComponent from "./loader.style";

interface ILoaderPropTypes {
  children?: ReactNode;
}

const Loader = (props: ILoaderPropTypes) => (
  <LoaderComponent>
    <svg className="isoContentLoader" viewBox="0 0 50 50">
      <circle
        className="isoContentLoaderCircle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="#035592"
        strokeWidth="3.6"
      />
    </svg>
    {props.children}
  </LoaderComponent>
);

export default Loader;
