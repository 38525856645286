import { ConfigProvider } from "antd";
// import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import themes from "./config/theme/theme.config";
import { RootState } from "./redux/store/rootReducer";

// import AppLocale from "./config/translation";
// import theme from "./config/theme/default";

// Need to remove INTLProvider as we are not using language switcher
type Props = {
  children: JSX.Element
}
const AppProvider: React.FC<Props> = ({ children })=> {
  // const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(
    (state: RootState) => state.ThemeSwitcher.changeThemes
  );

  // const currentAppLocale = AppLocale;

  return (
    <ConfigProvider>
    {/* <IntlProvider messages={currentAppLocale.messages}> */ }
    < ThemeProvider theme = { themes[themeName]} > { children } </ThemeProvider>
  {/* </IntlProvider> */ }
  </ConfigProvider>
  );
}

export default AppProvider;