import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import authActions from '../app/action';
import { searchTypes } from './searchTypes';
import {
  getSearchResultsReq,
  getUserProfileReq,
} from '../../library/services/searchReq';

export function* getSearchResultEffect() {
  yield takeEvery(
    searchTypes.GET_SEARCH_RESULTS,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getSearchResultsReq, payload);
        if (response.status) {
          yield put({
            type: searchTypes.GET_SEARCH_RESULTS_SUCCESS,
            allResults: response.data.data.responseArray,
            componentResult: response.data.data.response,
          });
        } else {
          yield put({
            type: searchTypes.GET_SEARCH_RESULTS_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: searchTypes.GET_SEARCH_RESULTS_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getUserProfileEffect() {
  yield takeEvery(
    searchTypes.GET_USER_PROFILE,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getUserProfileReq, payload);
        if (response.status) {
          yield put({
            type: searchTypes.GET_USER_PROFILE_SUCCESS,
            userData: response?.data?.data,
          });
        } else {
          yield put({
            type: searchTypes.GET_USER_PROFILE_FAILED,
            payload: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: searchTypes.GET_USER_PROFILE_FAILED,
          payload: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([fork(getSearchResultEffect), fork(getUserProfileEffect)]);
}
