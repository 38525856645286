import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { configTypes } from './actionTypes';
import authActions from '../../app/action';
import { editDefaultConfigReq, getDefaultConfigReq } from '../../../library/services/Admin/configuration';

export function* getDefaultConfigEffect() {
  yield takeEvery(configTypes.GET_DEFAULT_CONFIG, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getDefaultConfigReq);
      if (response) {
        yield put({
          type: configTypes.GET_DEFAULT_CONFIG_SUCCESS,
          configData: response.data.data.defaultOrg
        });
      } else {
        yield put({ type: configTypes.GET_DEFAULT_CONFIG_FAILURE, errorMessage: response.message });
      }
    } catch (error) {
      yield put({ type: configTypes.GET_DEFAULT_CONFIG_FAILURE, errorMessage: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* editDefaultConfigEffect() {
  yield takeEvery(
    configTypes.EDIT_DEFAULT_CONFIG,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(editDefaultConfigReq, payload);
        if (response) {
          yield all([
            put({
              type: configTypes.EDIT_DEFAULT_CONFIG_SUCCESS,
              UpdateSuccessMessage: response.data.message
            }),
            put({
              type: configTypes.GET_DEFAULT_CONFIG
            })
          ]);
        } else {
          yield put({ type: configTypes.EDIT_DEFAULT_CONFIG_FAILURE, updateErrorMessage: response.message });
        }
      } catch (error) {
        yield put({ type: configTypes.EDIT_DEFAULT_CONFIG_FAILURE, updateErrorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* ConfigSaga() {
  yield all([fork(getDefaultConfigEffect), fork(editDefaultConfigEffect)]);
}
