import { ServiceAuthInstance } from "./index";

export const postfinancialyearReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/financial_year",
    data: {
      ...payload,
    },
  });
};
export const getfinancialyearReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/tools_configuration/financial_year",
  });
};
// getorganizationReq
export const getOrganizationReq = (
  payload: Record<string, string | number>
) => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/organization/get_portal_settings",
    data: {
      ...payload,
    },
  });
};

export const postOrganizationReq = (payload: Record<string, string | Blob>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/organization/portal_settings",
    data: {
      ...payload,
    },
  });
};

export const patchCompanyLogoReq = (payload: Record<string, string | Blob>) => {
  let formData = new FormData();
  formData.append("file", payload?.file);

  return ServiceAuthInstance({
    method: "PATCH",
    url: "/organization/company_logo",
    data: formData,
  });
};

export const getTimezoneReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/organization/get_timezone",
  });
};

export const postOrgFinanicalReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: "/master/organization_financial",
    data: {
      ...payload,
    },
  });
};
