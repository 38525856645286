export enum taskTypes {
  POST_TASKS = "POST_TASKS",
  POST_TASKS_SUCCESS = "POST_TASKS_SUCCESS",
  POST_TASKS_FAILED = "POST_TASKS_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",

  GET_TASKS = "GET_TASKS",
  GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS",
  GET_TASKS_FAILED = "GET_TASKS_FAILED",

  GET_TASKS_OWNER = "GET_TASKS_OWNER",
  GET_TASKS_OWNER_SUCCESS = "GET_TASKS_OWNER_SUCCESS",
  GET_TASKS_OWNER_FAILED = "GET_TASKS_OWNER_FAILED",

  GET_TASKS_KEY_RESULT = "GET_TASKS_KEY_RESULT",
  GET_TASKS_KEY_RESULT_SUCCESS = "GET_TASKS_KEY_RESULT_SUCCESS",

  GET_TASKS_COMMENTS = "GET_TASKS_COMMENTS",
  GET_TASKS_COMMENTS_SUCCESS = "GET_TASKS_COMMENTS_SUCCESS",

  PATCH_TASKS = "PATCH_TASKS",
  PATCH_TASKS_SUCCESS = "PATCH_TASKS_SUCCESS",
  PATCH_TASKS_FAILED = "PATCH_TASKS_FAILED",

  DELETE_TASKS = "DELETE_TASKS",
  DELETE_TASKS_SUCCESS = "DELETE_TASKS_SUCCESS",
  DELETE_TASKS_FAILED = "DELETE_TASKS_FAILED",

  PATCH_TASK_STAGE = "PATCH_TASK_STAGE",
  PATCH_TASK_STAGE_SUCCESS = "PATCH_TASK_STAGE_SUCCESS",
  PATCH_TASK_STAGE_FAILED = "PATCH_TASK_STAGE_FAILED",

  PATCH_HELPFULNESS_RATING = "PATCH_HELPFULNESS_RATING",
  PATCH_HELPFULNESS_RATING_SUCCESS = "PATCH_HELPFULNESS_RATING_SUCCESS",
  PATCH_HELPFULNESS_RATING_FAILED = "PATCH_HELPFULNESS_RATING_FAILED",

  DELETE_HELPFULNESS_RATING = "DELETE_HELPFULNESS_RATING",
  DELETE_HELPFULNESS_RATING_SUCCESS = "DELETE_HELPFULNESS_RATING_SUCCESS",
  DELETE_HELPFULNESS_RATING_FAILED = "DELETE_HELPFULNESS_RATING_FAILED",
}
