import { instaMessageTypes } from './instaMessageTypes';
import { addInstaMsgAction, addInstaMsgState } from './types';

let initState: addInstaMsgState = {
  errorMessage: '',
  successMessage: '',
  issues: [],
  categories: [],
};
export default function InstaMessageReducer(
  state = initState,
  action: addInstaMsgAction
) {
  switch (action.type) {
    case instaMessageTypes.ADD_INSTA_MESSAGE:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
      };
    case instaMessageTypes.ADD_INSTA_MESSAGE_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: '',
      };
    case instaMessageTypes.ADD_INSTA_MESSAGE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: '',
      };
    case instaMessageTypes.GET_ISSUE_TYPES:
      return {
        ...state,
        issues: [],
        errorMessage: '',
      };
    case instaMessageTypes.GET_ISSUE_TYPES_SUCCESS:
      return {
        ...state,
        issues: action.issues,
        errorMessage: '',
      };
    case instaMessageTypes.GET_ISSUE_TYPES_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        issues: [],
      };
    case instaMessageTypes.GET_CATEGORY_TYPES:
      return {
        ...state,
        categories: [],
        errorMessage: '',
      };
    case instaMessageTypes.GET_CATEGORY_TYPES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        errorMessage: '',
      };
    case instaMessageTypes.GET_CATEGORY_TYPES_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        categories: [],
      };
    default:
      return state;
  }
}
