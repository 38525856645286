export enum organizationTypes {
  POST_FINANCIAL_YEAR = "POST_FINANCIAL_YEAR",
  POST_FINANCIAL_YEAR_SUCCESS = "POST_FINANCIAL_YEAR_SUCCESS",
  POST_FINANCIAL_YEAR_FAILED = "POST_FINANCIAL_YEAR_FAILED",

  GET_FINANCIAL_YEAR = "GET_FINANCIAL_YEAR",
  GET_FINANCIAL_YEAR_SUCCESS = "GET_FINANCIAL_YEAR_SUCCESS",
  GET_FINANCIAL_YEAR_FAILED = "GET_FINANCIAL_YEAR_FAILED",

  GET_ORGANIZATION = "GET_ORGANIZATION",
  GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_FAILED",

  POST_ORGANIZATION = "POST_ORGANIZATION",
  POST_ORGANIZATION_SUCCESS = "POST_ORGANIZATION_SUCCESS",
  POST_ORGANIZATION_FAILED = "POST_ORGANIZATION_FAILED",

  GET_TIMEZONE = "GET_TIMEZONE",
  GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS",
  GET_TIMEZONE_FAILED = "GET_TIMEZONE_FAILED",

  POST_ORG_FINANCIAL_YEAR = "POST_ORG_FINANCIAL_YEAR",
  POST_ORG_FINANCIAL_YEAR_SUCCESS = "POST_ORG_FINANCIAL_YEAR_SUCCESS",
  POST_ORG_FINANCIAL_YEAR_FAILED = "POST_ORG_FINANCIAL_YEAR_FAILED",

  CLEAR_RESPONSE_MESSAGE = "CLEAR_RESPONSE_MESSAGE",

  PATCH_COMPANY_LOGO = "PATCH_COMPANY_LOGO",
  PATCH_COMPANY_LOGO_SUCCESS = "PATCH_COMPANY_LOGO_SUCCESS",
  PATCH_COMPANY_LOGO_FAILED = "PATCH_COMPANY_LOGO_FAILED",
}
