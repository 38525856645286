import moment from 'moment-timezone';
import { calendarCategory } from '../../config/constant';
import { calendarTypes } from './calendarTypes';
import { calendarAction, calendarState, events } from './types';
let initState: calendarState = {
  errorMessage: '',
  events: [],
  popupData: {
    days: 0,
    week: 0,
    objective_type: ''
  },
}

const eventsModifier = (data) => {
  const allEvents: events[] = [];
  if (data && data.length > 0) {
    data.forEach((item) => {
      let color = '';
      let prefix = '';
      let eventCategory = '';
      let module;
      if(Object.keys(item)?.includes('objectiveData')) {
        color= calendarCategory[4].color
        prefix= calendarCategory[4].prefix
        eventCategory= 'objective'
        module= 6
      } else if(Object.keys(item)?.includes('keyResultData')) {
        color= calendarCategory[5].color
        prefix= calendarCategory[5].prefix
        eventCategory= 'key'
        module= 8
      } else if(Object.keys(item)?.includes('benefitData')) {
        color= calendarCategory[3].color
        prefix= calendarCategory[3].prefix
        eventCategory= 'benefit'
        module= 5
      } else if(Object.keys(item)?.includes('strategyData')) {
        color= calendarCategory[2].color
        prefix= calendarCategory[2].prefix
        eventCategory= 'strategy'
        module= 4
      } else if(Object.keys(item)?.includes('missionData')) {
        color= calendarCategory[1].color
        prefix= calendarCategory[1].prefix
        eventCategory= 'mission'
        module= 3
      } else if(Object.keys(item)?.includes('visionData')) {
        color= calendarCategory[0].color
        prefix= calendarCategory[0].prefix
        eventCategory= 'vision'
        module= 2
      } else if(Object.keys(item)?.includes('tasksData')) {
        color= calendarCategory[6].color
        prefix= calendarCategory[6].prefix
        eventCategory= 'task'
        module= 7
      } 

      Object.values(item)?.forEach((category: any) => {
        category &&
          category?.forEach((categoryData) => {
            let status = '';
            if(categoryData?.integrated_status !== '0.00'){
              status = categoryData?.integrated_status
            }
            else {
              if(!categoryData?.native_status){
                status = categoryData?.integrated_status
              }
              else{
                status = categoryData?.native_status
              }
            }
            const myEve: events = {
              id: String(categoryData.id),
              name: categoryData.name,
              prefix: `${prefix}`,
              title: `${prefix}-${categoryData.id} ${categoryData.name}`,
              start: categoryData.start_date,
              end: moment.utc(categoryData.end_date).add(1, 'day').format('YYYY-MM-DD'),
              allDay: true,
              droppable: false,
              editable: false,
              clickable: true,
              overlap: false,
              backgroundColor: color,
              borderColor: color,
              category: eventCategory,
              task_status: categoryData.status,
              status: status,
              module: module, 
            }
            allEvents.push(myEve);
          })
      })
    })
  }
  return allEvents;
} 

export default function calendarReducer(state = initState, action: calendarAction) {
  switch (action.type) {
    case calendarTypes.POST_CALENDAR:
      return {
        ...state,
        events: [],
        errorMessage: ''
      };
    case calendarTypes.POST_CALENDAR_SUCCESS:
      const eve = eventsModifier(
        action.events
      )
      return {
        ...state,
        events: eve,
        errorMessage: ''
      };
    case calendarTypes.POST_CALENDAR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        events: []
      };
    case calendarTypes.POST_CALENDAR_POPUP:
      return {
        ...state
      };
    case calendarTypes.POST_CALENDAR_POPUP_SUCCESS:      
      return {
        ...state,
        popupData: action.popupData,
        errorMessage: ''
      };
    case calendarTypes.POST_CALENDAR_POPUP_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case calendarTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: '',
      };

    case calendarTypes.CLEAR_DATA:
      return {
        ...state,
        events: [],
      };
    
    default:
      return state;
  }
}
