import { getDefaultPath } from '../../library/helpers/url_sync';
import actions, { getView } from './action';
import { isServer } from '../../library/helpers/isServer';

import { AppState, AppActions } from './types';

const preKeys = getDefaultPath();
const initState: AppState = {
  modelStatus: false,
  globalLoader: 0,
  collapsed: !isServer && window.innerWidth > 1220 ? false : true,
  view: !isServer && getView(window.innerWidth),
  height: !isServer ? window.innerHeight : 0,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  inputBox: false,
  backStatus: false,
  backClicked: false,
};

export default function appReducer(state = initState, action: AppActions) {
  switch (action.type) {
    case actions.GLOBAL_LOADER_INCREMENT:
      return {
        ...state,
        globalLoader: state.globalLoader + 1,
      };
    case actions.GLOBAL_LOADER_DECREMENT:
      return {
        ...state,
        globalLoader: state.globalLoader - 1,
      };
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.GLOBLE_MODEL_FOR_CHANGES:
      return {
        ...state,
        modelStatus: action.status,
      };
    case actions.CHANGE_BACK:
      window.history.back();
      return {
        ...state,
        backStatus: false,
      };
    case actions.IS_CHANGE_BACK:
      return {
        ...state,
        backStatus: state?.modelStatus ? action.status : false,
        backClicked: action.status,
      };

    case actions.SET_INPUT_BOX: {
      return {
        ...state,
        inputBox: action.payload,
      };
    }
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: [],
      };
    default:
      return state;
  }
  return state;
}
