import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { StarTypes } from "./StarTypes";
import authActions from "../app/action";
import {
  postBenefitStarReq,
  postStarReq,
} from "../../library/services/StarReq";
import { StarPayload } from "./types";
import { Objectivetypes } from "../Objective/actionTypes";
import { GoalActionTypes } from "../Goals/actionTypes";
import { benefitTypes } from "../benefit/benefitTypes";
import { vmsbCommonTypes } from "../vmsbCommon/vmsbCommonTypes";
import { holdActions } from "../Holds/holdActions";

export function* postStarEffect() {
  yield takeEvery(StarTypes.POST_STAR, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: StarPayload;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const myGols = payload?.myGols;
      const tabKey = payload?.tabkey;
      delete payload?.myGols;
      delete payload?.tabkey;
      const response = yield call(postStarReq, payload);
      if (response) {
        yield put({
          type: StarTypes.POST_STAR_SUCCESS,
          payload: response,
        });
        if (myGols) {
          yield put({
            type:
              tabKey === "1"
                ? GoalActionTypes.POST_MYGOALS
                : GoalActionTypes.POST_MY_ORG_CHART,
            payload: myGols,
          });
        }

        yield put({
          type: Objectivetypes.GET_OBJECTIVE,
          payload: { id: payload.id },
        });
      } else {
        yield put({
          type: StarTypes.POST_STAR_FAILED,
          payload: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: StarTypes.POST_STAR_FAILED,
        payload: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postBenefitStarEffect() {
  yield takeEvery(StarTypes.POST_BENEFIT_STAR, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const my_MSB = payload?.my_MSB;
      delete payload?.my_MSB;
      const response: any = yield call(postBenefitStarReq, payload);
      if (response.status) {
        yield all([
          put({
            type: StarTypes.POST_BENEFIT_STAR_SUCCESS,
            payload: response,
          }),
          put({
            type: benefitTypes.GET_BENEFIT,
            payload: payload,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
        if (my_MSB) {
          yield put({
            type: vmsbCommonTypes.GET_MSB_LIST,
            payload: my_MSB,
          });
        }
      }
    } catch (error: any) {
      yield put({
        type: StarTypes.POST_BENEFIT_STAR_FAILED,
        errorMessage: error?.data?.message,
      });
      yield put({
        type: holdActions.SCROLL_TO_TOP,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([fork(postStarEffect), fork(postBenefitStarEffect)]);
}
