import { ServiceAuthInstance } from "./index";

export const getToBeNotifiedUsersReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/vmsb/to_be_notified",
  });
};

export const getVisionlistReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/get_vmsb_list_view",
    data: {
      page: 1,
      take: 10,
      start_date: payload?.start_date,
      end_date: payload?.end_date,
      sort_by: [],
    },
  });
};

export const getMSBlistReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/get_msb_list_view",
    data: {
      start_date: payload?.start_date,
      end_date: payload?.end_date,
      page: payload.page,
      take: payload.take,
      sort_by: payload?.sort_by,
    },
  });
};

export const deleteVisionReq = (payload) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/vision/delete_vision/${payload?.id}`,
  });
};

export const deleteStrayegyReq = (payload) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/strategy/delete_strategy/${payload?.id}`,
  });
};

export const deleteBenefitReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/benefit/delete_benefit/${payload?.id}`,
  });
};

export const deleteMissionReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/mission/delete_mission/${payload?.id}`,
  });
};

export const postVMSBPeriodListReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/vmsb/get_vmsb_period",
    data: {
      ...payload,
    },
  });
};
