export enum ExecutiveSignupStep2Types {
  POST_CREATE_COMPANY = "POST_CREATE_COMPANY",
  POST_CREATE_COMPANY_SUCCESS = "POST_CREATE_COMPANY_SUCCESS",
  POST_CREATE_COMPANY_FAILED = "POST_CREATE_COMPANY_FAILED",

  POST_EXECUTIVESIGNUPSTEP2 = "POST_EXECUTIVESIGNUPSTEP2",
  POST_EXECUTIVESIGNUPSTEP2_SUCCESS = "POST_EXECUTIVESIGNUPSTEP2_SUCCESS",
  POST_EXECUTIVESIGNUPSTEP2_FAILED = "POST_EXECUTIVESIGNUPSTEP2_FAILED",

  GET_PORTAL_SLUG_AVAILABLITY = "GET_COMPANY_URL_AVAILABLITY",
  GET_PORTAL_SLUG_AVAILABLITY_SUCCESS = "GET_PORTAL_SLUG_AVAILABLITY_SUCCESS",
  GET_PORTAL_SLUG_AVAILABLITY_FAILED = "GET_PORTAL_SLUG_AVAILABLITY_FAILED",

  GET_COMPANY_INDRUSTRY = "GET_COMPANY_INDRUSTRY",
  GET_COMPANY_INDRUSTRY_SUCCESS = "GET_COMPANY_INDRUSTRY_SUCCESS",
  GET_COMPANY_INDRUSTRY_FAILED = "GET_COMPANY_INDRUSTRY_FAILED",

  GET_COMPANY_PORTAL_STATUS = "GET_COMPANY_PORTAL_STATUS",
  GET_COMPANY_PORTAL_STATUS_SUCCESS = "GET_COMPANY_PORTAL_STATUS_SUCCESS",
  GET_COMPANY_PORTAL_STATUS_FAILED = "GET_COMPANY_PORTAL_STATUS_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",
}
