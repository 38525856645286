import { rolesModule } from '../../config/constant';
import { CLEAR_RESPONSE_MESSAGE } from '../app/action';
import { rolesAndPermissionAction } from './actionTypes';
import { rolesAndPermission, rolesAndPermissionType } from './types';

const initState: rolesAndPermissionType = {
  idToken: '',
  loggedInUser: '',
  authFlag: false,
  errorMessage: '',
  allRoles: [],
  originalRoles: {},
  addRoleMessage: {
    message: '',
    type: ''
  },
  getRolesById: []
};

const userRolePermissionModifier = (data) => {
  //Add modifier if needed
  const userRolesModule = data.role_id.rolePermissions;
  return userRolesModule;
};

const allRolesDataModifier = (data) => {
  interface dataRoleType {
    key: string;
    roles?: string;
    task: string;
    add: boolean;
    delete: boolean;
    edit: boolean;
    view: boolean;
    id: number;
  }

  const dataRole: dataRoleType[] = [];

  const module = rolesModule;
  data.forEach((x, ind) => {
    for (let i = 0; i < x.rolePermissions.length; i++) {
      const element = x.rolePermissions[i];
      if (i === 0) {
        const obj = {
          key: module[element.module] + `${ind + 1}`,
          roles: x.name,
          task: module[element.module],
          add: element.add ? true : false,
          delete: element.delete ? true : false,
          edit: element.edit ? true : false,
          view: true,
          id: x.id
        };
        dataRole.push(obj);
      } else {
        const obj = {
          key: module[element.module] + `${ind + 1}`,
          task: module[element.module],
          add: element.add ? true : false,
          delete: element.delete ? true : false,
          edit: element.edit ? true : false,
          view: true,
          id: x.id
        };
        dataRole.push(obj);
      }
    }
  });
  return dataRole;
};

export default function authReducer(state = initState, action: rolesAndPermission) {
  switch (action.type) {
    case rolesAndPermissionAction.ADD_NEW_ROLES:
      return {
        ...state
      };
    case rolesAndPermissionAction.ADD_NEW_ROLES_FAILED:
      return {
        ...state,
        addRoleMessage: {
          message: action.payload,
          type: 'error'
        }
      };
    case rolesAndPermissionAction.ADD_NEW_ROLES_SUCCESS:
      return {
        ...initState,
        addRoleMessage: {
          message: 'Added Role Successfully.',
          type: 'success'
        }
      };
    case rolesAndPermissionAction.GET_ROLE_BY_ID_SUCCESS:
      const userRolePermission = userRolePermissionModifier(action.payload);
      return {
        ...state,
        getRolesById: userRolePermission
      };
    case rolesAndPermissionAction.GET_ROLE_BY_ID_FAILED:
      return {
        ...state
      };
    case rolesAndPermissionAction.GET_ROLES_SUCCESS:
      const data = allRolesDataModifier(action.originalRoles);
      return {
        ...state,
        allRoles: data,
        originalRoles: action.originalRoles
      };
    case rolesAndPermissionAction.GET_ROLES_FAILED:
      return {
        ...initState
      };
    case CLEAR_RESPONSE_MESSAGE: {
      return {
        ...state,
        addRoleMessage: {
          message: '',
          type: ''
        }
      };
    }
    default:
      return state;
  }
}
