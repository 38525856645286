export enum calendarTypes {

    POST_CALENDAR = "POST_CALENDAR",
    POST_CALENDAR_SUCCESS = "POST_CALENDAR_SUCCESS",
    POST_CALENDAR_FAILED = "POST_CALENDAR_FAILED",

    POST_CALENDAR_POPUP = "POST_CALENDAR_POPUP",
    POST_CALENDAR_POPUP_SUCCESS = "POST_CALENDAR_POPUP_SUCCESS",
    POST_CALENDAR_POPUP_FAILED = "POST_CALENDAR_POPUP_FAILED",
    
    REMOVE_ALERT = "REMOVE_ALERT",

    CLEAR_DATA = "CLEAR_DATA",
}
