export enum benefitTypes {
  POST_BENEFIT = "POST_BENEFIT",
  POST_BENEFIT_SUCCESS = "POST_BENEFIT_SUCCESS",
  POST_BENEFIT_FAILED = "POST_BENEFIT_FAILED",

  GET_BENEFIT = "GET_BENEFIT",
  GET_BENEFIT_SUCCESS = "GET_BENEFIT_SUCCESS",
  GET_BENEFIT_FAILED = "GET_BENEFIT_FAILED",

  GET_STRATEGY_IN_BENEFIT = "GET_STRATEGY_IN_BENEFIT",
  GET_STRATEGY_IN_BENEFIT_SUCCESS = "GET_STRATEGY_IN_BENEFIT_SUCCESS",
  GET_STRATEGY_IN_BENEFIT_FAILED = "GET_STRATEGY_IN_BENEFIT_FAILED",

  GET_OBJECTIVE_IN_BENEFIT = "GET_OBJECTIVE_IN_BENEFIT",
  GET_OBJECTIVE_IN_BENEFIT_SUCCESS = "GET_OBJECTIVE_IN_BENEFIT_SUCCESS",
  GET_OBJECTIVE_IN_BENEFIT_FAILED = "GET_OBJECTIVE_IN_BENEFIT_FAILED",

  GET_MISSION_IN_BENEFIT = "GET_MISSION_IN_BENEFIT",
  GET_MISSION_IN_BENEFIT_SUCCESS = "GET_MISSION_IN_BENEFIT_SUCCESS",
  GET_MISSION_IN_BENEFIT_FAILED = "GET_MISSION_IN_BENEFIT_FAILED",

  PATCH_BENEFIT = "PATCH_BENEFIT",
  PATCH_BENEFIT_SUCCESS = "PATCH_BENEFIT_SUCCESS",
  PATCH_BENEFIT_FAILED = "PATCH_BENEFIT_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",
}
