import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { rolesAndPermissionAction } from "./actionTypes";
import authActions from "../app/action";

import {
  getrAllRoles,
  addNewRole,
  getRoleById,
} from "../../library/services/rolesAndPermissionReq";

export function* getAllRolesEffect() {
  yield takeEvery(rolesAndPermissionAction.GET_ROLES, function* () {
    yield put(authActions.globalLoaderHandler(true));

    try {
      const response = yield call(getrAllRoles);
      if (response.data.status) {
        yield put({
          type: rolesAndPermissionAction.GET_ROLES_SUCCESS,
          originalRoles: response.data.data.role,
        });
      } else {
        yield put({
          type: rolesAndPermissionAction.GET_ROLES_FAILED,
          payload: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: rolesAndPermissionAction.GET_ROLES_FAILED,
        payload: error.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export function* getRoleByIdEffect() {
  yield takeEvery(rolesAndPermissionAction.GET_ROLE_BY_ID, function* () {
    yield put(authActions.globalLoaderHandler(true));

    try {
      const response = yield call(getRoleById);
      if (response) {
        yield put({
          type: rolesAndPermissionAction.GET_ROLE_BY_ID_SUCCESS,
          payload: response.data.data.result,
        });
      } else {
        yield put({
          type: rolesAndPermissionAction.GET_ROLE_BY_ID_FAILED,
          payload: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: rolesAndPermissionAction.GET_ROLE_BY_ID_FAILED,
        payload: error,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export function* addNewRolesEffect() {
  yield takeEvery(
    rolesAndPermissionAction.ADD_NEW_ROLES,
    function* ({ payload }: { type: string; payload: any }) {
      yield put(authActions.globalLoaderHandler(true));

      try {
        const response = yield call(addNewRole, payload);
        if (response.data.status) {
          yield all([
            put({
              type: rolesAndPermissionAction.ADD_NEW_ROLES_SUCCESS,
              payload: response.data.message,
            }),
            yield put({
              type: rolesAndPermissionAction.GET_ROLES,
            }),
            yield put({
              type: rolesAndPermissionAction.GET_ROLE_BY_ID,
            }),
          ]);
        } else {
          yield put({
            type: rolesAndPermissionAction.ADD_NEW_ROLES_FAILED,
            payload: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: rolesAndPermissionAction.ADD_NEW_ROLES_FAILED,
          payload: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(addNewRolesEffect),
    fork(getAllRolesEffect),
    fork(getRoleByIdEffect),
  ]);
}
