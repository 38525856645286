/** @format */

import { kanbanActions } from './kanbanActions';
import { kanbanAction, kanbanReducerStates } from './types';
import moment from 'moment';

let initState: kanbanReducerStates = {
  // remove these two state when add 1st actual variable
  errorMessage: '',
  successMessage: '',
  page: 1,
  visionDetails: {},
  kanbanTitles: [],
  kanbanBenObjData: [],
  kanbanKRDetails: [],
  kanbanData: [{}],
  hierachyLoading: false,
  KanbanhierachyData: [],
};

const parentHierarchyModifier = (data) => {
  const parentHierarchyData: any = [];
  if (data && data.length > 0) {
    data.forEach((item, index) => {
      const listOfTitles: any = [];
      Object.keys(item)?.map((okr) => {
        return listOfTitles.push(`${okr}-${item[okr]}`);
      });
      parentHierarchyData.push(listOfTitles);
    });
  }
  return parentHierarchyData;
};

const kanbanObjDataModifier = (data) => {
  let kanbanObjectiveTotal = 0;
  data.forEach((item) => {
    if (kanbanObjectiveTotal < item.metaData.totalItems) {
      kanbanObjectiveTotal = item.metaData.totalItems;
    }
  });
  return kanbanObjectiveTotal;
};

const kanbanKrDataModifier = (data, inIt_state) => {
  const resultKrList: any[] = [];
  if (data?.[0]?.key_results?.length > 0) {
    data?.[0]?.key_results?.forEach((item, index) => {
      resultKrList.push({
        obj: data[0]?.id,
        objective_type: data[0]?.objective_type,
        krData: {
          id: item?.id + '',
          parentObjectType: 'co',
          objTag: `KR-${item?.id}`,
          objectType: item?.objective_type === 1 ? 'CO' : 'AO',
          objectName: item?.name,
          nativePercent: item?.native_status,
          nativeStatus: 'green',
          nativeBorder: 'green',
          intPercent: item?.integrated_status,
          displayInegrated: 'block',
          intStatus: 'red',
          chatCount: item?.kr_comments,
          krTaskCount: item?.tasks_count,
          foreign_objective_count: item?.foreign_keyresult_count,
          displaylocation: 'inline-block',
          displaylink: 'inline-block',
          date: moment.utc(item?.end_date).format('DD MMM, YYYY'),
          displayArrowDown: 'inline-block',
          displayArrowRight: 'inline-block',
          status: 'high',
        },
      });
    });
    // }
  }
  return resultKrList;
};

const kanbanModifier = (action, state) => {
  if (action?.page === 1) {
    return action?.kanbanBenObjData;
  } else {
    if (action.component !== 4) {
      let objectArray: any = [];
      state.kanbanBenObjData?.forEach((item) => {
        action?.kanbanBenObjData?.forEach((iii) => {
          if (Object.keys(item)[0] === Object.keys(iii)[0]) {
            let objectData = {
              //@ts-ignore
              [Object.keys(item)[0]]: [...Object.values(item)[0],...Object.values(iii)[0],],
              metaData: Object.values(iii)[1],
            };
            objectArray.push(objectData);
          }
        });
      });
      return objectArray;
    } else {
      let concatedPagedItems = state.kanbanBenObjData.pagedItems.concat(
        action.kanbanBenObjData.pagedItems
      );
      return {
        pagedItems: [...concatedPagedItems],
        metaData: action.kanbanBenObjData.metaData,
      };
    }
  }
};

export default function authReducer(state = initState, action: kanbanAction) {
  switch (action.type) {
    case kanbanActions.POST_CURRENT_VISION:
      return {
        ...state,
      };
    case kanbanActions.POST_CURRENT_VISION_SUCCESS:
      return {
        ...state,
        visionDetails: action?.visionDetails,
      };
    case kanbanActions.POST_CURRENT_VISION_FAILED:
      return {
        ...state,
      };

    case kanbanActions.POST_PARENT_HIERARCHY:
      return {
        ...state,
      };
    case kanbanActions.POST_PARENT_HIERARCHY_SUCCESS:
      const modifiedTaskData = parentHierarchyModifier(action?.kanbanData);

      return {
        ...state,
        kanbanTitles: modifiedTaskData,
      };
    case kanbanActions.POST_PARENT_HIERARCHY_FAILED:
      return {
        ...state,
      };

    case kanbanActions.POST_KANBAN_DETAILS:
      return {
        ...state,
      };
    case kanbanActions.POST_KANBAN_DETAILS_SUCCESS:
      return {
        ...state,
        kanbanBenObjData: kanbanModifier(action, state),
        kanbanTotalObjectives:
          action?.component !== 4
            ? kanbanObjDataModifier(action?.kanbanBenObjData)
            : action?.kanbanBenObjData?.metaData?.totalItems,
      };
    case kanbanActions.POST_KANBAN_DETAILS_FAILED:
      return {
        ...state,
      };

    case kanbanActions.POST_OKR_HIERARCHY:
      return {
        ...state,
        hierachyLoading: true,
      };
    case kanbanActions.POST_OKR_HIERARCHY_SUCCESS:
      return {
        ...state,
        hierachyLoading: false,
        KanbanhierachyData: action?.kanbanHierchyData,
      };
    case kanbanActions.POST_OKR_HIERARCHY_FAILED:
      return {
        ...state,
        hierachyLoading: false,
      };

    case kanbanActions.POST_KANBAN_KR_DETAILS:
      return {
        ...state,
        kanbanKRDetails: state?.kanbanKRDetails.filter(
          (item) => Number(item?.obj) !== action?.payload?.objective
        ),
      };
    case kanbanActions.POST_KANBAN_KR_DETAILS_SUCCESS:
      const kanbanKR = kanbanKrDataModifier(action?.kanbanKRDetails, state);
      return {
        ...state,
        kanbanKRDetails: [...state?.kanbanKRDetails, ...kanbanKR],
      };
    case kanbanActions.POST_KANBAN_KR_DETAILS_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}
