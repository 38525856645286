import { ServiceAuthInstance } from "./index";

export const postBenefitReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/benefit/create_benefit",
    data: {
      ...payload,
    },
  });
};

export const getBenefitReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/benefit/view_benefit/${payload.id}`,
  });
};

export const getStrategyInBenefitReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/benefit/get_strategy`,
    params: payload,
  });
};

export const getMissionInBenefitReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/benefit/benefit_mission/${payload.id}`,
  });
};

export const patchBenefitReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: "/benefit/edit_benefit",
    data: {
      ...payload,
    },
  });
};

export const getObjInBenefitReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/benefit/benefit_objective/${payload.id}?page=${payload.page}&take=${payload.take}`,
  });
};
