export enum instaMessageTypes {
  ADD_INSTA_MESSAGE = 'ADD_INSTA_MESSAGE',
  ADD_INSTA_MESSAGE_SUCCESS = 'ADD_INSTA_MESSAGE_SUCCESS',
  ADD_INSTA_MESSAGE_FAILED = 'ADD_INSTA_MESSAGE_FAILED',

  GET_ISSUE_TYPES = 'GET_ISSUE_TYPES',
  GET_ISSUE_TYPES_SUCCESS = 'GET_ISSUE_TYPES_SUCCESS',
  GET_ISSUE_TYPES_FAILED = 'GET_ISSUE_TYPES_FAILED',

  GET_CATEGORY_TYPES = 'GET_CATEGORY_TYPES',
  GET_CATEGORY_TYPES_SUCCESS = 'GET_CATEGORY_TYPES_SUCCESS',
  GET_CATEGORY_TYPES_FAILED = 'GET_CATEGORY_TYPES_FAILED',
}
