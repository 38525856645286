import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { WorkspaceUserTypes } from './actionTypes';
import authActions from '../../app/action';
import {
  defaultRolePermissionsReq,
  updateRolePermissionsReq,
  workspaceUserDetailsReq,
  workspaceUsersListReq
} from '../../../library/services/Admin/workspaceUsers';

export function* defaultRolePermissionsEffect() {
  yield takeEvery(WorkspaceUserTypes.ROLE_PERMISSIONS_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(defaultRolePermissionsReq);
      if (response) {
        yield put({ type: WorkspaceUserTypes.ROLE_PERMISSIONS_SUCCESS, rolePermissionsData: response.data.data.role });
      } else {
        yield put({ type: WorkspaceUserTypes.ROLE_PERMISSIONS_FAILED, errorMessage: response.data.message });
      }
    } catch (error) {
      yield put({ type: WorkspaceUserTypes.ROLE_PERMISSIONS_FAILED, errorMessage: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* updateDefaultPermissionsEffect() {
  yield takeEvery(
    WorkspaceUserTypes.UPDATE_ROLE_PERMISSIONS_REQUEST,
    function* ({ payload }: { type: string; payload: any }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(updateRolePermissionsReq, payload);
        if (response) {
          yield put({
            type: WorkspaceUserTypes.UPDATE_ROLE_PERMISSIONS_SUCCESS,
            successMessage: response.data.message
          });
          yield put({ type: WorkspaceUserTypes.ROLE_PERMISSIONS_REQUEST });
        } else {
          yield put({ type: WorkspaceUserTypes.UPDATE_ROLE_PERMISSIONS_FAILED, errorMessage: response.data.message });
        }
      } catch (error: any) {
        yield put({
          type: WorkspaceUserTypes.UPDATE_ROLE_PERMISSIONS_FAILED,
          errorMessage: error.response.data.message
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceUsersListEffect() {
  yield takeEvery(
    WorkspaceUserTypes.WORKSPACE_USER_LIST_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceUsersListReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.workspace_user.metaData?.totalPages &&
            payload.page > response.data.data.workspace_user.metaData?.totalPages
          ) {
            yield put({
              type: WorkspaceUserTypes.WORKSPACE_USER_LIST_REQUEST,
              payload: { ...payload, page: 1 }
            });
          } else {
            yield put({
              type: WorkspaceUserTypes.WORKSPACE_USER_LIST_SUCCESS,
              allWorkspaceUsers: response.data.data.workspace_user.pagedItems,
              totalUsers: response.data.data.workspace_user.metaData
            });
          }
        } else {
          yield put({ type: WorkspaceUserTypes.WORKSPACE_USER_LIST_FAILED, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: WorkspaceUserTypes.WORKSPACE_USER_LIST_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* ExportWorkspaceUsersListEffect() {
  yield takeEvery(
    WorkspaceUserTypes.EXPORT_WORKSPACE_USER_LIST_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceUsersListReq, payload);
        if (response) {
          yield put({
            type: WorkspaceUserTypes.EXPORT_WORKSPACE_USER_LIST_SUCCESS,
            exportableUserData: response.data.data
          });
        } else {
          yield put({
            type: WorkspaceUserTypes.EXPORT_WORKSPACE_USER_LIST_FAILED,
            errorMessage: response.data.message
          });
        }
      } catch (error) {
        yield put({ type: WorkspaceUserTypes.EXPORT_WORKSPACE_USER_LIST_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* workspaceUserDetailEffect() {
  yield takeEvery(
    WorkspaceUserTypes.WORKSPACE_USER_DETAIL_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, number | string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(workspaceUserDetailsReq, payload);
        if (response) {
          yield put({
            type: WorkspaceUserTypes.WORKSPACE_USER_DETAIL_SUCCESS,
            workspaceUser: response.data.data.user
          });
        } else {
          yield put({ type: WorkspaceUserTypes.WORKSPACE_USER_DETAIL_FAILED, errorMessage: response.data.message });
        }
      } catch (error: any) {
        yield put({ type: WorkspaceUserTypes.WORKSPACE_USER_DETAIL_FAILED, errorMessage: error.message });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* WorkspaceUserSaga() {
  yield all([
    fork(defaultRolePermissionsEffect),
    fork(updateDefaultPermissionsEffect),
    fork(workspaceUsersListEffect),
    fork(workspaceUserDetailEffect),
    fork(ExportWorkspaceUsersListEffect)
  ]);
}
