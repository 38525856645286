export enum missionTypes {
  POST_MISSION = "POST_MISSION",
  POST_MISSION_SUCCESS = "POST_MISSION_SUCCESS",
  POST_MISSION_FAILED = "POST_MISSION_FAILED",

  GET_MISSION = "GET_MISSION",
  GET_MISSION_SUCCESS = "GET_MISSION_SUCCESS",
  GET_MISSION_FAILED = "GET_MISSION_FAILED",
  REMOVE_ALERT = "REMOVE_ALERT",

  GET_STRATEGY_IN_MISSION = "GET_STRATEGY_IN_MISSION",
  GET_STRATEGY_IN_MISSION_SUCCESS = "GET_STRATEGY_IN_MISSION_SUCCESS",
  GET_VISION_IN_MISSION = "GET_VISION_IN_MISSION",
  GET_VISION_IN_MISSION_SUCCESS = "GET_VISION_IN_MISSION_SUCCESS",
  EDIT_MISSION = "EDIT_MISSION",
  EDIT_MISSION_SUCCESS = "EDIT_MISSION_SUCCESS",
  EDIT_MISSION_FAILED = "EDIT_MISSION_FAILED",
}
