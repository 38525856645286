import { commonFilters } from "./commonFilters";
import { CommonFiltersState, CommonFiltersAction } from "./types";
let initState: CommonFiltersState = {
  FiltersData: [],
  statusofChnage: 0,
};
export default function NotificationReducer(
  state = initState,
  action: CommonFiltersAction
) {
  switch (action.type) {
    case commonFilters.GET_FILTERS_SUCCESS:
      return {
        ...state,
        FiltersData: action?.payload,
        statusofChnage: state?.statusofChnage + 1,
      };
    case commonFilters.GET_FILTERS_FAILED:
      return state;

    case commonFilters.ADD_UPDATE_FILTERS_SUCCESS:
      return {
        ...state,
      };
    case commonFilters.ADD_UPDATE_FILTERS_FAILED:
      return state;

    default:
      return state;
  }
}
