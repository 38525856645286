import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { scoreCardStatusActions } from "./scoreCardStatusActions";
import authActions from "../app/action";
import { postScoreCardStatusPayload } from "./types";
import {
  postScoreCardStatusReq,
  scorecardCategoryVMSBListReq,
} from "../../library/services/scoreCardStatusReq";

export function* postScoreCardStatusEffect() {
  yield takeEvery(
    scoreCardStatusActions.POST_SCORE_CARD_STATUS,
    function* ({
      payload,
    }: {
      type: string;
      payload: postScoreCardStatusPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postScoreCardStatusReq, payload);
        if (response) {
          yield all([
            put({
              type: scoreCardStatusActions.POST_SCORE_CARD_STATUS_SUCCESS,
              scoreCardStatusData: response?.data?.data,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: scoreCardStatusActions.POST_SCORE_CARD_STATUS_FAILED,
            errorMessage: error?.data?.message,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* scoreCardCategoryVMSBListEffect() {
  yield takeEvery(
    scoreCardStatusActions.SCORE_CARD_STATUS_VMSB_LIST,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, number | string>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(scorecardCategoryVMSBListReq, payload);
        if (response) {
          yield all([
            put({
              type: scoreCardStatusActions.SCORE_CARD_STATUS_VMSB_LIST_SUCCESS,
              vmsbList: response?.data?.data,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: scoreCardStatusActions.SCORE_CARD_STATUS_VMSB_LIST_FAILED,
            errorMessage: error?.data?.message,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postScoreCardStatusEffect),
    fork(scoreCardCategoryVMSBListEffect),
  ]);
}
