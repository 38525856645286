import { ServiceAuthInstance } from "./index";

export const postScoreCardDataReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/get_scorecard",
    data: {
      ...payload,
    },
  });
};

export const getCanAddVisionReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/vision/can_add_vision',
  });
};
  