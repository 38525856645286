import styled from "styled-components";
import { variables } from "../../../assets/styles/variables";
import { palette } from "styled-theme";
import { transition, borderRadius } from "../../../library/helpers/style_utils";

export const AdminSidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    background: ${variables.purple};
    width: 280px;
    flex: 0 0 280px;

    @media only screen and (max-width: 1199px) {
      width: 250px;
    }
    @media only screen and (max-width: 991px) {
      max-width: 220px !important;
      min-width: 220px !important;
      height: 100%;
    }

    .scrollarea {
      height: calc(100vh - 114px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 90px;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding-left: 15px;
      overflow: hidden;
      ${borderRadius()};
      img {
        max-width: 180px;
        @media only screen and (max-width: 1199px) {
          max-width: 170px;
          /* height: 100px; */
        }
        @media only screen and (max-width: 991px) {
          max-width: 160px;
        }
        &.logo-icon {
          @media only screen and (max-width: 1199px) {
            max-width: 30px;
          }
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;
        justify-content: start;
        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding: 5px 10px;
      background: transparent;
      display: flex;
      flex-direction: column;
      height: 100%;
      @media only screen and (min-width: 1921px) {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        height: auto;
      }
      /* @media only screen and (max-width: 1800px) {
        padding: 25px 10px;
      }
      @media only screen and (max-width: 1600px) {
        padding: 20px 10px;
      }
      @media only screen and (max-width: 1199px) {
        padding: 20px 10px;
      }
      @media only screen and (max-width: 991px) {
        padding: 20px 10px;
      } */
      @media only screen and (max-height: 750px) {
        display: block;
      }

      a {
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        /* padding: 0 24px; */
        margin: 0px;
        height: auto;
        padding: 18px 0px 18px 18px !important;
        border-radius: 10px;
        &:not(:first-child) {
          margin: 10px 0px 0px;
          @media only screen and (max-width: 1800px) {
            margin: 5px 0px 0px;
          }

          /* @media only screen and (max-width: 1199px) {
            margin: 10px 0px 0px;
          } */
        }
        @media only screen and (max-width: 1800px) {
          padding: 12px 0px 12px 15px !important;
        }
        @media only screen and (max-width: 1440px) {
          padding: 10px 0px 10px 15px !important;
        }
        /* @media only screen and (max-width: 1199px) {
          padding: 15px 0px 15px 15px !important;
        } */
        @media only screen and (max-width: 991px) {
          padding: 10px 0px 10px 10px !important;
        }
        .isoMenuHolder {
          display: flex;
          align-items: start;
          .icon-wrapper {
            width: 24px;
            text-align: center;
            @media only screen and (max-width: 1800px) {
              width: 30px;
              /* height: auto; */
            }
            @media only screen and (max-width: 1199px) {
              width: 30px;
              /* height: auto; */
            }
            @media only screen and (max-width: 991px) {
              width: 40px;
            }
            i {
              font-size: 24px;
              margin-right: 40px;
              margin: 0;
              color: inherit;
              display: flex;
              justify-content: center;

              ${transition()};
              &:before {
                @media only screen and (max-width: 1800px) {
                  font-size: 22px;
                  color: ${variables.btn_bg_color};
                }
              }
              &:before {
                @media only screen and (max-width: 1600px) {
                  font-size: 20px;
                  color: ${variables.btn_bg_color};
                }
              }
              &:before {
                @media only screen and (max-width: 1440px) {
                  font-size: 18px;
                  color: ${variables.btn_bg_color};
                }
              }
              &.icon-list:before {
                @media only screen and (max-width: 1199px) {
                  font-size: 17px;
                }
              }
            }
          }
          span.nav-text {
            margin-left: 30px;
            font-size: 16px;
            line-height: 19px;
            align-self: center;
            color: ${variables.btn_bg_color};
            @media only screen and (min-width: 1920px) {
              margin-left: 40px;
            }
            @media only screen and (max-width: 1800px) {
              margin-left: 25px;
              font-size: 15px;
              line-height: 18px;
            }
            @media only screen and (max-width: 1440px) {
              margin-left: 20px;
            }
            @media only screen and (max-width: 1199px) {
              margin-left: 15px;
            }
            @media only screen and (max-width: 991px) {
              margin-left: 0;
            }
            .coming-badge {
              font-size: 10px;
              line-height: 12px;
              background: #f7f7f7;
              color: #32386a;
              width: fit-content;
              padding: 4px;
              position: relative;
              margin-top: 5px;
              border-radius: 5px;
              font-family: "Inter-Medium", sans-serif;
            }
          }
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
        @media screen and (max-width: 991px) {
          margin-right: 15px;
        }
      }

      .nav-text {
        font-size: 15px;
        color: inherit;
        font-weight: 400;
        line-height: 18px;
        ${transition()};
      }

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          .icon-wrapper {
            width: 30px;
            @media screen and (max-width: 991px) {
              width: 40px;
            }
          }
        }
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background-color: ${variables.btn_bg_color};
          border-radius: 10px;
          .ant-menu-title-content {
            .isoMenuHolder {
              .icon-wrapper {
                width: 30px;
                .anticon > svg {
                  /* stroke: #fff; */
                  path {
                    fill: #035592;
                    stroke: #fff;
                  }
                }
              }
              .nav-text {
                color: #fff;
              }
            }
            .anchor-tag:focus {
              text-decoration: none !important;
            }
          }
          span > a {
            color: #fff !important;
          }
          i::before {
            color: #fff !important;
          }
        }
        .children-link {
          .ant-menu-title-content {
            a {
              padding: 8px;
            }
          }
        }
        .active-children-link {
          .ant-menu-title-content {
            border-radius: 10px;
            background: #d3f3fc;
          }
          .anchor-tags {
            .nav-text,
            i::before {
              text-decoration: none;
              color: ${variables.btn_bg_color};
            }
          }
        }
      }

      .ant-menu-item-selected {
        background-color: ${variables.btn_bg_color} !important;
        @media screen and (max-width: 1440px) {
          /* justify-content: center; */
          display: flex;
          /* padding: 10px 15px !important; */
        }
        .ant-menu-title-content {
          .isoMenuHolder {
            .icon-wrapper {
              .anticon > svg {
                /* stroke: #fff; */
                path {
                  fill: #035592;
                  stroke: #fff;
                }
              }
            }
            .nav-text {
              color: #fff;
            }
          }
          .anchor-tag:focus {
            text-decoration: none !important;
          }
        }
        /* .anticon {
          color: #fff;
        }

        i {
          color: #fff;
          display: flex;
        }

        .nav-text {
          color: #fff;
        } */
        &::after {
          display: none;
        }
      }
      &.disabled {
        cursor: not-allowed;
        .ant-menu-item {
          pointer-events: none;
          cursor: not-allowed;

          .ant-menu-title-content {
            .isoMenuHolder {
              .icon-wrapper {
                i {
                  &::before {
                    color: #b1afaf;
                  }
                }
              }
              .nav-text {
                color: #b1afaf;
              }
            }
          }
          .ant-menu-item-selected {
            cursor: pointer;
            pointer-events: all;
            .ant-menu-title-content {
              .anchor-tag:focus {
                text-decoration: none !important;
              }
              .isoMenuHolder {
                .icon-wrapper {
                  .anticon > svg {
                    /* stroke: #fff; */
                    path {
                      fill: #035592;
                      stroke: #fff;
                    }
                  }
                }
                .nav-text {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette("secondary", 5)};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      &.ant-menu-submenu-open {
        .icon-table-arrow-down {
          ${transition()};
          transform: rotate(180deg);
        }
      }
      .ant-menu-item {
        padding: 10px 0px 10px 18px !important;
        @media only screen and (max-width: 1800px) {
          padding: 10px 0px 10px 15px !important;
        }
        @media only screen and (max-width: 1440px) {
          padding: 5px 0px 5px 15px !important;
        }
      }
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        height: auto;
        padding: 18px 0px 18px 18px !important;
        @media only screen and (max-width: 1800px) {
          padding: 12px 0px 12px 15px !important;
        }
        @media only screen and (max-width: 1440px) {
          padding: 10px 0px 10px 15px !important;
        }
        /* @media only screen and (max-width: 1199px) {
          padding: 15px 0px 15px 15px !important;
        } */
        @media only screen and (max-width: 991px) {
          padding: 10px 0px 10px 10px !important;
        }
        .nav-text {
          margin-left: 25px;
          @media screen and (max-width: 1440px) {
            margin-left: 20px;
          }
          @media screen and (max-width: 1199px) {
            margin-left: 15px;
          }
          @media screen and (max-width: 991px) {
            margin-left: 0;
          }
        }
        .icon-table-arrow-down {
          height: 18px;
          position: absolute;
          right: 10px;
          ${transition()};
          &::before {
            font-size: 8px;
            color: ${variables.btn_bg_color};
          }
        }
        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${(props) => (props["data-rtl"] === "rtl" ? "25px" : "auto")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "auto" : "25px")};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
            color: ${variables.btn_bg_color};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${(props) =>
            props["data-rtl"] === "rtl" ? "0px !important" : "64px !important"};
          padding-right: ${(props) =>
            props["data-rtl"] === "rtl" ? "64px !important" : "0px !important"};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          /* &:hover {
            a {
              color: #ffffff !important;
            }
          } */
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;

        .isoMenuHolder {
          align-items: center;
          .icon-wrapper {
            width: 25px;
            margin-left: 4px;
            i {
              &::before {
                font-size: 12px;
                color: ${variables.btn_bg_color};
              }
            }
          }
          span.nav-text {
            margin-left: 8px !important;
            font-size: 15px;
          }
        }
        .ant-menu-item-selected {
          .icon-wrapper {
            .anticon > svg {
              /* stroke: #fff; */
              path {
                fill: #035592;
                stroke: #fff;
              }
            }
          }
          span.nav-text {
            color: #fff;
          }
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        justify-content: center;
      }
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
`;
