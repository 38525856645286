import actions from "./action";
let initState = {
  orgFinanicalYear: "",
};
export default function orgFinancialReducer(state = initState, action) {
  switch (action.type) {
    case actions.POST_ORG_FINANCIAL_YEAR:
      return {
        ...state,
      };
    case actions.POST_ORG_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
      };
    case actions.POST_ORG_FINANCIAL_YEAR_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}
