import { ServiceAuthInstance } from "./index";

export const postMyGoalsReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/my_goals",
    data: {
      ...payload,
    },
  });
};

export const postMyOrgChartReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: `/list_view/direct_reports/${payload?.category}/${payload?.id}`,
    data: {
      ...payload,
    },
  });
};

export const postAllTaskReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/get_all_tasks",
    data: {
      ...payload,
    },
  });
};

export const deleteObjectiveReq = (payload) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/list_view/delete_objective/${payload.id}`,
  });
};

export const deleteKeyResultReq = (payload) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/list_view/delete_key_result/${payload.id}`,
  });
};

export const getKrObjCalStatusReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/kr_status/${payload.percentage}/${payload.id}`,
  });
};
