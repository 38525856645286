import ReactGA from 'react-ga4';

interface SiteCongifTypes {
  siteName: string;
  siteIcon: string;
  footerText: string;
  enableAnimatedRoute: boolean;
  apiUrl: string;
  google: {
    analyticsKey: string;
  };
  dashboard: string;
}

const siteConfig: SiteCongifTypes = {
  siteName: 'ISOMORPHIC',
  siteIcon: 'ion-flash',
  footerText: `Isomorphic @ ${new Date().getFullYear()} Created by RedQ, Inc`,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: process.env.REACT_APP_GA_ID || 'G-BRTN07QWDL'
  },
  dashboard: '/dashboard'
};

/**
 * Google Analytics Event Tracker wrapper create abstraction over current layer, event
 * tracker should only be used for this speicified function.
 */
export const googleEventTracker = (name: string, data: {
  category: string;
  action: string;
  label: string;
  value?: string | number
}) => {
  // @ts-ignore
  return ReactGA.event(name, data);
};

/**
 * GA event object would consist of pre-configured name which needs to be added here,
 * [This would be updated w.r.t to the changes in events]
 */
export const GoogleEventObj = {
  button_click: {
    name: 'button_click',
    action: 'click',
    getLabel() {
      return window.location.pathname + window.location.search
    }
  },
  form_submit: {
    name: 'form_submit',
    action: 'submit',
    getLabel() {
      return window.location.pathname + window.location.search
    },
    value: 'success'
  },
  form_error: {
    name: 'form_error',
    action: 'error',
    getLabel() {
      return window.location.pathname + window.location.search
    },
  }
}

export default siteConfig;
