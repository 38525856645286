import { ServiceAuthInstance } from './index';
export const getComplteGlobalViewReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/global-view/vmsb_tree',
    payload: {
      start_date: '2022-06-01',
      end_date: '2022-06-30',
    },
  });
};
export const postGlobalViewOrphanObjReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `/global-view/orphan_api`,
    data: { ...payload },
  });
};
export const getGlobalViewOrganizationsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/global-view/organization_view',
    data: {
      ...payload,
    },
  });
};

export const postGlobalView = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/global-view/vmsb_tree',
    data: {
      ...payload,
    },
  });
};

export const getChildNode = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/global-view/component_child',
    data: {
      ...payload,
    },
  });
};

export const searchGlobalView = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/global-view/global_view_search',
    data: {
      ...payload,
    },
  });
};

export const postVMSBStatus = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/global-view/module_status',
    data: {
      ...payload,
    },
  });
};
