export enum FilterTypes {
  COMPANY_INDUSTRY = 'COMPANY_INDUSTRY',
  COMPANY_INDUSTRY_SUCCESS = 'COMPANY_INDUSTRY_SUCCESS',

  WORKSPACES_REQUEST = 'WORKSPACES_REQUEST',
  WORKSPACES_SUCCESS = 'WORKSPACES_SUCCESS',

  GET_ALL_ROLES_REQUEST = 'GET_ALL_ROLES_REQUEST',
  GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS',

  GET_ROLES_BY_ID_REQUEST = 'GET_ROLES_BY_ID_REQUEST',
  GET_ROLES_BY_ID_SUCCESS = 'GET_ROLES_BY_ID_SUCCESS'
}
