export enum WorkspaceUserTypes {
  ROLE_PERMISSIONS_REQUEST = 'ROLE_PERMISSIONS_REQUEST',
  ROLE_PERMISSIONS_SUCCESS = 'ROLE_PERMISSIONS_SUCCESS',
  ROLE_PERMISSIONS_FAILED = 'ROLE_PERMISSIONS_FAILED',

  UPDATE_ROLE_PERMISSIONS_REQUEST = 'UPDATE_ROLE_PERMISSIONS_REQUEST',
  UPDATE_ROLE_PERMISSIONS_SUCCESS = 'UPDATE_ROLE_PERMISSIONS_SUCCESS',
  UPDATE_ROLE_PERMISSIONS_FAILED = 'UPDATE_ROLE_PERMISSIONS_FAILED',

  WORKSPACE_USER_LIST_REQUEST = 'WORKSPACE_USER_LIST_REQUEST',
  WORKSPACE_USER_LIST_SUCCESS = 'WORKSPACE_USER_LIST_SUCCESS',
  WORKSPACE_USER_LIST_FAILED = 'WORKSPACE_USER_LIST_FAILED',

  EXPORT_WORKSPACE_USER_LIST_REQUEST = 'EXPORT_WORKSPACE_USER_LIST_REQUEST',
  EXPORT_WORKSPACE_USER_LIST_SUCCESS = 'EXPORT_WORKSPACE_USER_LIST_SUCCESS',
  EXPORT_WORKSPACE_USER_LIST_FAILED = 'EXPORT_WORKSPACE_USER_LIST_FAILED',

  WORKSPACE_USER_DETAIL_REQUEST = 'WORKSPACE_USER_DETAIL_REQUEST',
  WORKSPACE_USER_DETAIL_SUCCESS = 'WORKSPACE_USER_DETAIL_SUCCESS',
  WORKSPACE_USER_DETAIL_FAILED = 'WORKSPACE_USER_DETAIL_FAILED',

  REMOVE_ALERT = 'REMOVE_ALERT'
}
