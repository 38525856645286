export enum portalRatingSettingsTypes {

    POST_CONFIDENCE_LEVEL = "POST_CONFIDENCE_LEVEL",
    POST_CONFIDENCE_LEVEL_SUCCESS = "POST_CONFIDENCE_LEVEL_SUCCESS",
    POST_CONFIDENCE_LEVEL_FAILED = "POST_CONFIDENCE_LEVEL_FAILED",

    GET_CONFIDENCE_LEVEL = "GET_CONFIDENCE_LEVEL",
    GET_CONFIDENCE_LEVEL_SUCCESS = "GET_CONFIDENCE_LEVEL_SUCCESS",
    GET_CONFIDENCE_LEVEL_FAILED = "GET_CONFIDENCE_LEVEL_FAILED",

    POST_OBJECTIVE_TYPE = "POST_OBJECTIVE_TYPE",
    POST_OBJECTIVE_TYPE_SUCCESS = "POST_OBJECTIVE_TYPE_SUCCESS",
    POST_OBJECTIVE_TYPE_FAILED = "POST_OBJECTIVE_TYPE_FAILED",

    GET_OBJECTIVE_TYPE = "GET_OBJECTIVE_TYPE",
    GET_OBJECTIVE_TYPE_SUCCESS = "GET_OBJECTIVE_TYPE_SUCCESS",
    GET_OBJECTIVE_TYPE_FAILED = "GET_OBJECTIVE_TYPE_FAILED",

    POST_SCORE_CARD = "POST_SCORE_CARD",
    POST_SCORE_CARD_SUCCESS = "POST_SCORE_CARD_SUCCESS",
    POST_SCORE_CARD_FAILED = "POST_SCORE_CARD_FAILED",

    GET_SCORE_CARD = "GET_SCORE_CARD",
    GET_SCORE_CARD_SUCCESS = "GET_SCORE_CARD_SUCCESS",
    GET_SCORE_CARD_FAILED = "GET_SCORE_CARD_FAILED",

    PUT_SCORE_CARD = "PUT_SCORE_CARD",
    PUT_SCORE_CARD_SUCCESS = "PUT_SCORE_CARD_SUCCESS",
    PUT_SCORE_CARD_FAILED = "PUT_SCORE_CARD_FAILED",

    POST_ASSISTANCE_POINTS = "POST_ASSISTANCE_POINTS",
    POST_ASSISTANCE_POINTS_SUCCESS = "POST_ASSISTANCE_POINTS_SUCCESS",
    POST_ASSISTANCE_POINTS_FAILED = "POST_ASSISTANCE_POINTS_FAILED",

    GET_ASSISTANCE_POINTS = "GET_ASSISTANCE_POINTS",
    GET_ASSISTANCE_POINTS_SUCCESS = "GET_ASSISTANCE_POINTS_SUCCESS",
    GET_ASSISTANCE_POINTS_FAILED = "GET_ASSISTANCE_POINTS_FAILED",

    POST_HELPFULNESS_RATING = "POST_HELPFULNESS_RATING",
    POST_HELPFULNESS_RATING_SUCCESS = "POST_HELPFULNESS_RATING_SUCCESS",
    POST_HELPFULNESS_RATING_FAILED = "POST_HELPFULNESS_RATING_FAILED",

    GET_HELPFULNESS_RATING = "GET_HELPFULNESS_RATING",
    GET_HELPFULNESS_RATING_SUCCESS = "GET_HELPFULNESS_RATING_SUCCESS",
    GET_HELPFULNESS_RATING_FAILED = "GET_HELPFULNESS_RATING_FAILED",

    GET_CHECK_VMSB = "GET_CHECK_VMSB",
    GET_CHECK_VMSB_SUCCESS = "GET_CHECK_VMSB_SUCCESS",
    GET_CHECK_VMSB_FAILED = "GET_CHECK_VMSB_FAILED",

    CHANGES_TRUE_GLOBLE = "CHANGES_TRUE_GLOBLE",

    CLEAR_RESPONSE_MESSAGE = "CLEAR_RESPONSE_MESSAGE",
}
