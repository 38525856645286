export enum Objectivetypes {
  POST_OBJECTIVE = "POST_OBJECTIVE",
  POST_OBJECTIVE_SUCCESS = "POST_OBJECTIVE_SUCCESS",
  POST_OBJECTIVE_FAILED = "POST_OBJECTIVE_FAILED",

  GET_OBJECTIVE = "GET_OBJECTIVE",
  GET_OBJECTIVE_SUCCESS = "GET_OBJECTIVE_SUCCESS",
  GET_OBJECTIVE_FAILED = "GET_OBJECTIVE_FAILED",

  PATCH_OBJECTIVE = "PATCH_OBJECTIVE",
  PATCH_OBJECTIVE_SUCCESS = "PATCH_OBJECTIVE_SUCCESS",
  PATCH_OBJECTIVE_FAILED = "PATCH_OBJECTIVE_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",

  GET_OBJ_COMMENTS = "GET_OBJ_COMMENTS",
  GET_OBJ_COMMENTS_SUCCESS = "GET_OBJ_COMMENTS_SUCCESS",

  POST_OBJ_COMMENTS = "POST_OBJ_COMMENTS",
  POST_OBJ_COMMENTS_SUCCESS = "POST_OBJ_COMMENTS_SUCCESS",
  POST_OBJ_COMMENTS_FAILED = "POST_OBJ_COMMENTS_FAILED",

  GET_OBJ_TASK = "GET_OBJ_TASK",
  GET_OBJ_TASK_SUCCESS = "GET_OBJ_TASK_SUCCESS",

  GET_OKR_LIST = "GET_OKR_LIST",
  GET_OKR_LIST_SUCCESS = "GET_OKR_LIST_SUCCESS",

  GET_BENEFIT_LIST = "GET_BENEFIT_LIST",
  GET_BENEFIT_LIST_SUCCESS = "GET_BENEFIT_LIST_SUCCESS",

  DELETE_FOREGIN_LINKED_OKR = "DELETE_FOREGIN_LINKED_OKR",
  DELETE_FOREGIN_LINKED_OKR_SUCCESS = "DELETE_FOREGIN_LINKED_OKR_SUCCESS",
  DELETE_FOREGIN_LINKED_OKR_FAILED = "DELETE_FOREGIN_LINKED_OKR_FAILED"
}
