import React, { ReactNode } from "react";
import { Spin } from "antd";
import Loader from "../utility/loader";

export const SpinCustom = (props: {
  fontSize?: string;
  children: ReactNode;
  spinning: boolean;
}) => <Spin className="isoSpinCustom" indicator={<Loader />} {...props} />;

export default Spin;
