import { searchComponentIndexes } from '../../config/constant';
import { searchTypes } from './searchTypes';
import { searchAction, searchState } from './types';
let initState: searchState = {
  take: 3,
  page: 1,
  component: 0,
  search: '',
  userData: {},
  searchResult: [
    {
      userData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      visionData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      missionData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      strategyData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      benefitData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      objectiveData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      keyResultData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
    {
      tasksData: {
        pagedItems: [],
        metaData: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      },
    },
  ],
};

const searchResultModifier = (action, state) => {
  if (action.allResults) {
    return action.allResults;
  } else {
    let formatedResult = state.searchResult.map((result, index) => {
      if (index === state.component - 1) {
        result[searchComponentIndexes[index]] = action.componentResult;
      }
      return result;
    });
    return formatedResult;
  }
};
export default function authReducer(state = initState, action: searchAction) {
  switch (action.type) {
    case searchTypes.GET_SEARCH_RESULTS:
      return {
        ...state,
        errorMessage: '',
        search: action.payload.search,
      };
    case searchTypes.GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searchResult: searchResultModifier(action, state),
      };
    case searchTypes.GET_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case searchTypes.GET_USER_PROFILE:
      return {
        ...state,
      };
    case searchTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userData: action.userData,
      };
    case searchTypes.GET_USER_PROFILE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case searchTypes.CHANGE_SEARCH_RESULT_PAGE:
      return {
        ...state,
        page: action.payload.page,
        component: action.payload.component,
      };
    case searchTypes.RESET_DATA:
      return {
        take: 3,
        page: 1,
        component: 0,
        search: '',
        userData: {},
        searchResult: [
          {
            userData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            visionData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            missionData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            strategyData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            benefitData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            objectiveData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            keyResultData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
          {
            tasksData: {
              pagedItems: [],
              metaData: {
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                totalPages: 0,
              },
            },
          },
        ],
      };
    case searchTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: '',
      }

    default:
      return state;
  }
}
