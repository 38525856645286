export enum TooltipTypes {
  TOOLTIP_UPLOAD_REQUEST = 'TOOLTIP_UPLOAD_REQUEST',
  TOOLTIP_UPLOAD_SUCCESS = 'TOOLTIP_UPLOAD_SUCCESS',
  TOOLTIP_UPLOAD_FAILURE = 'TOOLTIP_UPLOAD_FAILURE',

  PREVIOUS_UPLOADED_TOOLTIPS_REQUEST = 'PREVIOUS_UPLOADED_TOOLTIPS_REQUEST',
  PREVIOUS_UPLOADED_TOOLTIPS_SUCCESS = 'PREVIOUS_UPLOADED_TOOLTIPS_SUCCESS',
  PREVIOUS_UPLOADED_TOOLTIPS_FAILURE = 'PREVIOUS_UPLOADED_TOOLTIPS_FAILURE',

  ALL_TOOLTIPS_REQUEST = 'ALL_TOOLTIPS_REQUEST',
  ALL_TOOLTIPS_SUCCESS = 'ALL_TOOLTIPS_SUCCESS',
  ALL_TOOLTIPS_FAILURE = 'ALL_TOOLTIPS_FAILURE',
  
  EXECUTIVE_TOOLTIPS_REQUEST = 'EXECUTIVE_TOOLTIPS_REQUEST',
  EXECUTIVE_TOOLTIPS_SUCCESS = 'EXECUTIVE_TOOLTIPS_SUCCESS',
  EXECUTIVE_TOOLTIPS_FAILURE = 'EXECUTIVE_TOOLTIPS_FAILURE',

  CURRENT_FILE = 'CURRENT_FILE',

  REMOVE_MESSAGE = 'REMOVE_MESSAGE'
}
