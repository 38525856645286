import { CLEAR_RESPONSE_MESSAGE } from "../app/action";
import { profileAction } from "./actionTypes";

const initState = {
  idWiseMyCategoryData: {},
  profile: { profileData: { ProfilePhoto: "", role_id: "" } },
  configurationStatus: {
    assistancePoint: false,
    financialYear: false,
    portalSettings: false,
    overAll: false,
  },
  categoriesData: [],
  error: "",
  specialist: {},
  employer: {},
  updateProfileSuccess: "",
  updateProfileFailed: "",
  profileMessage: {
    type: null,
    message: null,
  },
  success: "",
  failure: "",
  loading: false,
  loadingforPassword: false,
  inviteUserSuccessfully: false,
  addManuallyMessage: {
    type: null,
    message: null,
  },
  shareLinkMessage: {
    type: null,
    message: null,
  },
  commentData: [],
  metaData: {},
  totalMetaData: "",
  helpfunessRating: {},
  CompanyIndrustry: [],
};

export default function Profile(state = initState, action) {
  switch (action.type) {
    case profileAction.GET_PROFILE_SUCCESS:
      let profileData = action.payload.profileData;
      // const idWiseMyCategoryData = {};
      // action.payload.categoriesData.forEach((data) => {
      //   idWiseMyCategoryData[data.parentCategoryData.id] = data.subCategoryData;
      // });

      return {
        ...state,
        ...action.payload,
        // idWiseMyCategoryData,
        profile: { ...state.profile, profileData },
        configurationStatus: action.payload.configurations,
      };
    case profileAction.GET_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case profileAction.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case profileAction.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
        success: null,
      };

    case profileAction.UPDATE_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        success: action?.payload,
        error: null,
        loading: false,
      };

    case profileAction.UPDATE_PROFILE_PHOTO:
      return {
        ...state,
        loading: true,
      };

    case profileAction.UPDATE_PROFILE_PHOTO_FAILED:
      return {
        ...state,
        error: action.payload,
        success: null,
        loading: false,
      };
    case profileAction.CLEAR_RESPONSE_MESSAGES:
      return {
        ...state,
        updateProfileFailed: "",
        updateProfileSuccess: "",
      };
    case profileAction.CLEAR_USER_INVITATION:
      return {
        ...state,
        inviteUserSuccessfully: false,
      };

    case profileAction.UPDATE_PASSWORD: {
      return {
        ...state,
        loadingforPassword: true,
        updateProfileSuccess: "",
      };
    }
    case profileAction.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        updateProfileSuccess: action.updateProfileSuccess,
        updateProfileFailed: null,
        loadingforPassword: false,
      };
    }
    case profileAction.UPDATE_PASSWORD_FAILED: {
      return {
        ...state,
        updateProfileSuccess: null,
        updateProfileFailed: action.payload.data.message,
        loadingforPassword: false,
      };
    }
    case profileAction.POST_USER_MANUALY_REQUEST: {
      return {
        ...state,
        loading: true,
        inviteUserSuccessfully: false,
        addManuallyMessage: {
          type: null,
          message: null,
        },
      };
    }
    case profileAction.POST_USER_MANUALY_SUCCESS: {
      return {
        ...state,
        loading: false,
        inviteUserSuccessfully: true,
        addManuallyMessage: {
          type: "success",
          message: action.payload.message,
        },
      };
    }
    case profileAction.POST_USER_MANUALY_FAILED: {
      return {
        ...state,
        loading: false,
        inviteUserSuccessfully: false,
        addManuallyMessage: {
          type: "error",
          message: action.payload.data.message,
        },
      };
    }
    case profileAction.POST_SHARED_REQUEST: {
      return {
        ...state,
        loading: true,
        inviteUserSuccessfully: false,
        shareLinkMessage: {
          type: null,
          message: null,
        },
      };
    }
    case profileAction.POST_SHARED_SUCCESS: {
      return {
        ...state,
        loading: false,
        inviteUserSuccessfully: true,
        shareLinkMessage: {
          type: "success",
          message: action.payload.message,
        },
      };
    }
    case profileAction.POST_SHARED_FAILED: {
      return {
        ...state,
        loading: false,
        inviteUserSuccessfully: false,
        shareLinkMessage: {
          type: "error",
          message: action.payload,
        },
      };
    }
    case profileAction.REMOVE_ALERT:
      return {
        ...state,
        success: action.payload === "success" ? null : state?.success,
        error: action.payload === "error" ? null : state?.error,
      };
    case profileAction.REMOVE_ALERT_CHANGE_PASSWORD:
      return {
        ...state,
        updateProfileSuccess: null,
        updateProfileFailed: null,
      };
    case profileAction.POST_RATING_SUCCESS:
      return {
        ...state,
        commentData: action?.payload?.data?.taskData,
        totalMetaData: action?.payload?.data?.metaData?.totalItems,
        helpfunessRating: action?.payload?.data?.additionalData,
      };
    case profileAction.GET_COMPANY_INDUSTRY_SUCCESS:
      return {
        ...state,
        CompanyIndrustry: action.CompanyIndrustry,
        errorMessageL: "",
      };
    case profileAction.GET_COMPANY_INDUSTRY_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case CLEAR_RESPONSE_MESSAGE: {
      return {
        ...state,
        addManuallyMessage: {
          type: null,
          message: null,
        },
        shareLinkMessage: {
          type: null,
          message: null,
        },
      };
    }
    default:
      return state;
  }
}
