import { TooltipTypes } from './actionTypes';

export const uploadTooltip = (payload) => ({
  type: TooltipTypes.TOOLTIP_UPLOAD_REQUEST,
  payload
});

export const tooltipFiles = (payload?: Record<string, number>) => ({
  type: TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_REQUEST,
  payload: payload
});

export const getAllTooltips = (payload?: Record<string, number>) => ({
  type: TooltipTypes.ALL_TOOLTIPS_REQUEST,
  payload: payload
});

export const getExecutiveTooltips = (payload?: Record<string, string>) => ({
  type: TooltipTypes.EXECUTIVE_TOOLTIPS_REQUEST,
  payload: payload
});

export const removeMessage = () => ({
  type: TooltipTypes.REMOVE_MESSAGE
});
