import { holdActions } from "./holdActions";
import { HoldAction, HoldState } from "./types";
let initState: HoldState = {
  idToken: null,
  loggedInUser: "", // User's data - Role and Redirection check
  authFlag: false,
  errorMessage: "",
  holdStatus: false,
  isScrollTop: false,
  ObjectiveTopBarStatus: "native",
  keyResultTopBarStatus: "native",
};
export default function authReducer(state = initState, action: HoldAction) {
  switch (action.type) {
    case holdActions.POST_HOLDSOKR:
      return {
        ...state,
      };
    case holdActions.POST_HOLDSOKR_SUCCESS:
      return {
        ...state,
        errorMessage: "",
      };
    case holdActions.POST_HOLDSOKR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        holdStatus: true,
      };
    case holdActions.POST_HOLD_MSB:
      return {
        ...state,
      };
    case holdActions.POST_HOLD_MSB_SUCCESS:
      return {
        ...state,
        errorMessage: "",
      };
    case holdActions.POST_HOLD_MSB_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case holdActions.SCROLL_TO_TOP:
      return {
        ...state,
        isScrollTop: true,
      };
    case holdActions.SCROLL_TO_TOP_RESET:
      return {
        ...state,
        isScrollTop: false,
      };
    case holdActions.CHANGE_OBJECTIVE_STATUS:
      return {
        ...state,
        ObjectiveTopBarStatus: action.typesOfStataus,
      };
    case holdActions.CHANGE_KEY_RESULT_STATUS:
      return {
        ...state,
        keyResultTopBarStatus: action.typesOfStataus,
      };
    case holdActions.REMOVE_HOLD_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: null,
      };
    default:
      return state;
  }
}
