import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { globalActionTypes } from './actiontypes';
import authActions from '../app/action';
import { orgDefaultConfigReq, tooltipsReq } from '../../library/services/globalReqs';

export function* allTooltipsEffect() {
  yield takeEvery(
    globalActionTypes.GET_TOOLTIPS_REQUEST,
    function* ({ payload }: { payload: Record<string, string | number>; type: string }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(tooltipsReq, payload);
        if (response) {
          yield put({
            type: globalActionTypes.GET_TOOLTIPS_SUCCESS,
            allTooltips: response.data.value
          });
        }
      } catch (error: any) {
        yield put({
          type: globalActionTypes.GET_TOOLTIPS_FAILED,
          allTooltips: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}


export function* defaultConfigEffect() {
  yield takeEvery(
    globalActionTypes.DEFAULT_CONFIGURATIONS_REQUEST,
    function* () {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(orgDefaultConfigReq);
        if (response) {
          yield put({
            type: globalActionTypes.DEFAULT_CONFIGURATIONS_SUCCESS,
            defaultConfigs: response.data.data.defaultOrg
          });
        }
      } catch (error: any) {
        yield put({
          type: globalActionTypes.DEFAULT_CONFIGURATIONS_FAILED,
          defaultConfigError: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* globalSaga() {
  yield all([fork(allTooltipsEffect), fork(defaultConfigEffect)]);
}
