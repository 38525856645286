import { ServiceAuthInstance } from "./index";

export const addUpdatesFiltersReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "organization/add_update_filter",
    data: {
      ...payload,
    },
  });
};

export const getFiltersReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `organization/get_filter/${payload?.id}`,
  });
};
