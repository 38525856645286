import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import authActions from '../../app/action';
import { DashboardActionTypes } from './DashboardActionTypes';
import {
  dashboardActiveDataReq,
  industryReportReq,
  scorecardReportReq,
  subscriptionReportReq
} from '../../../library/services/Admin/dashboard';

export function* dashboardActiveDataEffect() {
  yield takeEvery(DashboardActionTypes.GET_DASHBOARD_ACTIVE_DATA_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(dashboardActiveDataReq);
      if (response) {
        yield put({
          type: DashboardActionTypes.GET_DASHBOARD_ACTIVE_DATA_SUCCESS,
          activeData: response.data.data
        });
      } else {
        yield put({ type: DashboardActionTypes.GET_DASHBOARD_ACTIVE_DATA_FAILED, errorMessage: response.message });
      }
    } catch (error) {
      yield put({ type: DashboardActionTypes.GET_DASHBOARD_ACTIVE_DATA_FAILED, errorMessage: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* dashboardScorecardReportEffect() {
  yield takeEvery(
    DashboardActionTypes.SCORECARD_REPORT_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(scorecardReportReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response?.data?.data?.scorecardData?.metaData?.totalPages &&
            payload?.page > response?.data?.data?.scorecardData?.metaData?.totalPages
          ) {
            yield put({
              type: DashboardActionTypes.DASHBOARD_PAGE_CHANGES,
              totalScorecardItems: response?.data?.data?.scorecardData?.metaData
            });
          } else {
            yield put({
              type: DashboardActionTypes.SCORECARD_REPORT_SUCCESS,
              scoreCardData: response?.data?.data?.scorecardData?.pagedItems,
              totalScorecardItems: response?.data?.data?.scorecardData?.metaData
            });
          }
        } else {
          yield put({ type: DashboardActionTypes.SCORECARD_REPORT_FAILED, errorMessage: response.message });
        }
      } catch (error) {
        yield put({ type: DashboardActionTypes.SCORECARD_REPORT_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* dashboardSubscriptionReportEffect() {
  yield takeEvery(
    DashboardActionTypes.SUBSCRIPTION_REPORT_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(subscriptionReportReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response?.data?.data?.pagedSubscriptionReport.metaData?.totalPages &&
            payload?.page > response?.data?.data?.pagedSubscriptionReport.metaData?.totalPages
          ) {
            yield put({
              type: DashboardActionTypes.DASHBOARD_PAGE_CHANGES,
              totalSubscriptions: response?.data?.data?.pagedSubscriptionReport.metaData
            });
          } else {
            yield put({
              type: DashboardActionTypes.SUBSCRIPTION_REPORT_SUCCESS,
              subscriptionData: response?.data?.data?.pagedSubscriptionReport.pagedItems,
              totalSubscriptions: response?.data?.data?.pagedSubscriptionReport.metaData
            });
          }
        } else {
          yield put({ type: DashboardActionTypes.SUBSCRIPTION_REPORT_FAILED, errorMessage: response.message });
        }
      } catch (error) {
        yield put({ type: DashboardActionTypes.SUBSCRIPTION_REPORT_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* dashboardIndustryReportEffect() {
  yield takeEvery(
    DashboardActionTypes.INDUSTRY_REPORT_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(industryReportReq, payload);
        if (response.status === (201 || 200)) {
          if (
            response.data.data.companyIndustry.metaData?.totalPages &&
            payload?.page > response.data.data.companyIndustry.metaData?.totalPages
          ) {
            yield put({
              type: DashboardActionTypes.DASHBOARD_PAGE_CHANGES,
              totalIndustry: response.data.data.companyIndustry.metaData
            });
          } else {
            yield put({
              type: DashboardActionTypes.INDUSTRY_REPORT_SUCCESS,
              companyIndustry: response.data.data.companyIndustry.pagedItems,
              totalIndustry: response.data.data.companyIndustry.metaData
            });
          }
        } else {
          yield put({ type: DashboardActionTypes.INDUSTRY_REPORT_FAILED, errorMessage: response.message });
        }
      } catch (error) {
        yield put({ type: DashboardActionTypes.INDUSTRY_REPORT_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* ExportScorecardReportEffect() {
  yield takeEvery(DashboardActionTypes.EXPORT_SCORECARD_REPORT_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(scorecardReportReq);
      if (response) {
        yield put({
          type: DashboardActionTypes.EXPORT_SCORECARD_REPORT_SUCCESS,
          exportableScoreCardData: response?.data?.data?.scorecardData
        });
      } else {
        yield put({ type: DashboardActionTypes.EXPORT_SCORECARD_REPORT_FAILED, errorMessage: response.message });
      }
    } catch (error) {
      yield put({ type: DashboardActionTypes.EXPORT_SCORECARD_REPORT_FAILED, errorMessage: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* ExportSubscriptionReportEffect() {
  yield takeEvery(DashboardActionTypes.EXPORT_SUBSCRIPTION_REPORT_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(subscriptionReportReq);
      if (response) {
        yield put({
          type: DashboardActionTypes.EXPORT_SUBSCRIPTION_REPORT_SUCCESS,
          exportableSubscriptionData: response.data.data.pagedSubscriptionReport
        });
      } else {
        yield put({ type: DashboardActionTypes.EXPORT_SUBSCRIPTION_REPORT_FAILED, errorMessage: response.message });
      }
    } catch (error) {
      yield put({ type: DashboardActionTypes.EXPORT_SUBSCRIPTION_REPORT_FAILED, errorMessage: error });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* ExportIndustryReportEffect() {
  yield takeEvery(
    DashboardActionTypes.EXPORT_INDUSTRY_REPORT_REQUEST,
    function* ({ payload }: { type: string; payload?: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(industryReportReq, payload);
        if (response) {
          yield put({
            type: DashboardActionTypes.EXPORT_INDUSTRY_REPORT_SUCCESS,
            exportableCompanyIndustry: response.data.data.companyIndustry
          });
        } else {
          yield put({ type: DashboardActionTypes.EXPORT_INDUSTRY_REPORT_FAILED, errorMessage: response.message });
        }
      } catch (error) {
        yield put({ type: DashboardActionTypes.EXPORT_INDUSTRY_REPORT_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* DashboardSaga() {
  yield all([
    fork(dashboardActiveDataEffect),
    fork(dashboardScorecardReportEffect),
    fork(dashboardSubscriptionReportEffect),
    fork(dashboardIndustryReportEffect),
    fork(ExportIndustryReportEffect),
    fork(ExportScorecardReportEffect),
    fork(ExportSubscriptionReportEffect)
  ]);
}
