import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const addPromoReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.ADD_PROMO_CODE,
    method: 'POST',
    data: { ...payload }
  });
};

export const generatePromoReq = () => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.GENERATE_PROMO_CODE,
    method: 'POST'
  });
};

export const promoCodeListReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.PROMO_CODE_LIST,
    method: 'POST',
    data: { ...payload }
  });
};

export const promoCodeDetailReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: `${adminApiUrls.PROMO_CODE_DETAIL}/${payload}`,
    method: 'POST'
  });
};

export const promoCodeDetailListReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: `${adminApiUrls.PROMO_CODE_DETAILS_LIST}/${payload.id}`,
    method: 'POST',
    data: { ...payload }
  });
};

export const updatePromoCodeReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.UPDATE_PROMO_CODE,
    method: 'PATCH',
    data: { ...payload }
  });
};

export const deletePromoCodeReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.DELETE_PROMO_CODE,
    method: 'PATCH',
    data: { ...payload }
  });
};
