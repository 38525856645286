import {
  ServiceAuthInstance,
  ServiceInstance } from './index';

export const postWorkspaceEmailReq = (payload) => {
  return ServiceInstance({
    method: 'POST',
    url: '/master/workspace_email',
    data: {
      ...payload
    }
  });
};

export const postWorkspaceOTPReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/master/workspace_email_verify",
    data: {
      ...payload,
    },
  });
};

export const resendWorkspaceOTPReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: `/master/resend_otp_workspace/${payload.category}`,
  });
};

export const getWorkspacesReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/master/respective_workspace",
  });
};