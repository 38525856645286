import { ServiceAuthInstance } from "./index";

export const postScoreCardStatusReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/scorecard",
    data: {
      ...payload,
    },
  });
};
export const scorecardCategoryVMSBListReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/scorecard_vmsb_list",
    data: {
      ...payload,
    },
  });
};
