import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { SubscriptionPlanTypes } from './SubscriptionPlanTypes';
import authActions from '../app/action';
import history from '../../library/helpers/history';
import {
  activePlansReq,
  additionalLicenseReq,
  cancelSubscriptionOTPReq,
  cancelSubscriptionReq,
  checkAvailablePlanReq,
  checkPromoCodeReq,
  checkSubscriptionReq,
  checkUserStatusReq,
  checkoutSessionReq,
  cardVerifyReq,
  deleteInvitedUserReq,
  downgradeUser,
  getCountryByIdReq,
  getCountryDataByIdReq,
  getCountryDropdownReq,
  getCountryListReq,
  getSavedCardReq,
  makePaymentReq,
  newCardReq,
  postSubscriptionPlanReq,
  purchaseSubscriptionPlanReq,
  // renewCheckReq,
  replaceCardReq,
  saveCardReq,
  savePaymentAddressReq,
  subscriberPaymentReq,
  subscriptionHistoryReq,
  upgradePlanReq,
  upgradeUser,
  verifyPromo,
  verifySubscriptionReq,
  viewPlansReq,
  viewTransactionReq,
  cardVerifyPostSigninReq,
  checkoutSessionPostSigninReq,
  getCardDetailsReq,
  getStatesByIdReq,
  getStatesListReq,
} from '../../library/services/SubscriptionPlanReq';
import {
  alreadySavedPaymentDetail,
  card,
  freePLan,
  subscriptionHistoryPayload,
  timePeriod,
} from '../../config/constant';
import { emptyCache } from '../../library/helpers/utility';
import { holdActions } from '../Holds/holdActions';
import { manageUserActions } from '../manageUser/manageUserActions';

export function* postSubscriptionPlanEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postSubscriptionPlanReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN_SUCCESS,
            userSubscribeData: response.data.data.user_subscribe,
          });
          localStorage.setItem('planPurchased', 'true');
          if (
            payload.type === timePeriod.annually &&
            payload.is_freePlan !== freePLan
          ) {
            yield put({
              type: SubscriptionPlanTypes.MAKE_PAYMENT_REQUEST,
            });
          } else {
            history.push('/portalsuccess');
            localStorage.removeItem('userDetail');
            localStorage.removeItem('planChosen');
          }
          localStorage.setItem(
            'subscriptionPlan',
            JSON.stringify(response.data.data.user_subscribe)
          );
        } else {
          yield put({
            type: SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN_FAILED,
          errorMessage: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* saveCardEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.SAVE_CARD_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(saveCardReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.SAVE_CARD_SUCCESS,
            successMessage: response.data.message,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.SAVE_CARD_FAILED,
            errorMessage: response?.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.SAVE_CARD_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* newCardEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.NEW_CARD_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(newCardReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.NEW_CARD_SUCCESS,
            successMessage: response.data.message,
          });

          yield put({
            type: SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN,
          });
        } else {
          yield all({
            type: SubscriptionPlanTypes.NEW_CARD_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        if (error?.data?.message === alreadySavedPaymentDetail) {
          yield put({
            type: SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.NEW_CARD_FAILED,
            errorMessage: error?.data?.message,
          });
        }
      }
    }
  );
}

export function* makePaymentEffect() {
  yield takeEvery(SubscriptionPlanTypes.MAKE_PAYMENT_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(makePaymentReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.MAKE_PAYMENT_SUCCESS,
          payload: response.data,
        });
        history.push('/portalsuccess');
      } else {
        yield put({
          type: SubscriptionPlanTypes.MAKE_PAYMENT_FAILED,
          paymentError: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.MAKE_PAYMENT_FAILED,
        paymentError: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* savePaymentAddressEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(savePaymentAddressReq, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_SUCCESS,
              payload: response.data,
            }),
            put({
              type: SubscriptionPlanTypes.CHECKOUT_SESSION,
            }),
          ]);

          localStorage.setItem('userDetail', JSON.stringify(payload));
        } else {
          yield put({
            type: SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_FAILED,
            paymentError: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_FAILED,
          paymentError: error.data.message,
          paymentAddressSave: true,
        });
        yield put({
          type: SubscriptionPlanTypes.SAVE_CARD_REQUEST,
          //@ts-ignore
          payload: JSON.parse(localStorage.getItem('cardInfo')),
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* additionalLicenseEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(additionalLicenseReq, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE_SUCCESS,
              additionalLicenseMsg: response.data.data.message,
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
            put({
              type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE_FAILED,
            addLicenseError: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE_FAILED,
          addLicenseError: error.data.message,
        });
      }
    }
  );
}

export function* cancelSubscriptionEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(cancelSubscriptionReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_SUCCESS,
            payload: response.data.message,
          });
          yield put({
            type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION,
            payload: response.data,
          });
          sessionStorage.clear();
          localStorage.clear();
          emptyCache();
          history.push('/');
        } else {
          yield put({
            type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_FAILED,
            otpVerificationError: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_FAILED,
          otpVerificationError: error.data.message,
        });
      }
    }
  );
}

export function* cancelSubscriptionOTPEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_REQUEST_OTP,
    function* () {
      try {
        const response = yield call(cancelSubscriptionOTPReq);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_OTP_SUCCESS,
            token: response.data.data.token,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_OTP_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_OTP_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* savedCardEffect() {
  yield takeEvery(SubscriptionPlanTypes.GET_SAVED_CARD, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getSavedCardReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.GET_SAVED_CARD_SUCCESS,
          cardDetail: response.data.data,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.GET_SAVED_CARD_FAILED,
          errorMessage: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.GET_SAVED_CARD_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* replaceCardEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.REPLACE_CARD,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(replaceCardReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.REPLACE_CARD_SUCCESS,
            payload: response.data.data,
          });
          yield put({
            type: SubscriptionPlanTypes.GET_SAVED_CARD,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.REPLACE_CARD_FAILED,
            replaceCardError: response,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.REPLACE_CARD_FAILED,
          replaceCardError: error.data.message,
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
    }
  );
}

export function* subscriberPaymentEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_REQUEST,
    function* () {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(subscriberPaymentReq);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_SUCCESS,
              successMessage: response.data.data.message,
            }),
            put({
              type: SubscriptionPlanTypes.VERIFY_SUBSCRIPTION,
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_FAILED,
            paymentError: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_FAILED,
          paymentError: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* verifySubscriptionEffect() {
  yield takeEvery(SubscriptionPlanTypes.VERIFY_SUBSCRIPTION, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(verifySubscriptionReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_SUBSCRIPTION_SUCCESS,
          subscriptionVerifyStatus: response.data.data.subscription_done,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_SUBSCRIPTION_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.VERIFY_SUBSCRIPTION_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* transactionViewEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(viewTransactionReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.TRANSACTION_VIEW_SUCCESS,
            transactionData: response.data.data,
            totalTransactions: response.data.data.metaData.totalItems,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.TRANSACTION_VIEW_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.TRANSACTION_VIEW_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* subscriptionHistoryEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(subscriptionHistoryReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS,
            subscriptionData: response.data.data.userSubs,
            plansPageCount: response.data.data.userSubs.metaData.totalItems,
            currentPage: response.data.data.userSubs.metaData.currentPage,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* upgradePlanEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.UPGRADE_PLAN_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(upgradePlanReq, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.UPGRADE_PLAN_SUCCESS,
              userSubscribeData: response.data.data,
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
            // put({
            //   type: SubscriptionPlanTypes.RENEW_CHECK
            // }),
            put({
              type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.UPGRADE_PLAN_FAILED,
            upgradePlanErrorMsg: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.UPGRADE_PLAN_FAILED,
          upgradePlanErrorMsg: error.data.message,
        });
      }
    }
  );
}

export function* downgradeUserEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.DOWNGRADE_USER_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(downgradeUser, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.DOWNGRADE_USER_SUCCESS,
              userSubscribeData: response.data.data,
            }),
            put({
              type: manageUserActions.GET_ALL_USER,
              payload: { page: 1, take: 10 },
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
            put({
              type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.DOWNGRADE_USER_FAILED,
            upgradePlanErrorMsg: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.DOWNGRADE_USER_FAILED,
          upgradePlanErrorMsg: error.data.message,
        });
      }
    }
  );
}

export function* upgradeUserEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.UPGRADE_USER_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(upgradeUser, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.UPGRADE_USER_SUCCESS,
              userSubscribeData: response.data.data,
            }),
            put({
              type: manageUserActions.GET_ALL_USER,
              payload: { page: 1, take: 10 },
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
            put({
              type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.UPGRADE_USER_FAILED,
            upgradeUserErrorMsg: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.UPGRADE_USER_FAILED,
          upgradeUserErrorMsg: error.data.message,
        });
      }
    }
  );
}

export function* deleteInvitedUserEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.DELETE_INVITED_USER_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(deleteInvitedUserReq, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.DELETE_INVITED_USER_SUCCESS,
              deleteInvitedUserSuccessMsg: response.data.data.message,
            }),
            put({
              type: manageUserActions.GET_ALL_USER,
              payload: { page: 1, take: 10 },
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
            put({
              type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.DELETE_INVITED_USER_FAILED,
            deleteInvitedUserErrorMsg: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.DELETE_INVITED_USER_FAILED,
          deleteInvitedUserErrorMsg: error.data.message,
        });
      }
    }
  );
}

// export function* renewCheckEffect() {
//   yield takeEvery(SubscriptionPlanTypes.RENEW_CHECK, function* () {
//     try {
//       yield put(authActions.globalLoaderHandler(true));
//       const response = yield call(renewCheckReq);
//       if (response) {
//         yield put({
//           type: SubscriptionPlanTypes.RENEW_CHECK_SUCCESS
//         });
//         yield put({
//           type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
//           payload: subscriptionHistoryPayload
//         });
//       } else {
//         yield put({
//           type: SubscriptionPlanTypes.RENEW_CHECK_FAILED,
//           errorMessage: response.data
//         });
//       }
//     } catch (error: any) {
//       yield put({
//         type: SubscriptionPlanTypes.RENEW_CHECK_FAILED,
//         errorMessage: error?.data?.message
//       });
//     }
//     yield put(authActions.globalLoaderHandler(false));
//   });
// }

export function* checkSubscriptionEffect() {
  yield takeEvery(SubscriptionPlanTypes.CHECK_SUBSCRIPTION, function* () {
    try {
      const response = yield call(checkSubscriptionReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION_SUCCESS,
          subscriptionStatus: response.data,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION_FAILED,
        subscriptionStatus: error.data,
      });
    }
  });
}

export function* checkUserStatusEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.CHECK_USER_STATUS,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(checkUserStatusReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.CHECK_USER_STATUS_SUCCESS,
            userStatus: response.data.data,
            totalItems: response.data.data.metaData.totalItems,
            currentPage: response.data.data.metaData.currentPage,
            itemsPerPage: response.data.data.metaData.itemsPerPage,
          });
          yield put({
            type: SubscriptionPlanTypes.GET_STATES_LIST_REQUEST,
            payload: 'CA',
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.CHECK_USER_STATUS_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.CHECK_USER_STATUS_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* purchaseSubscriptionPlanEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(purchaseSubscriptionPlanReq, payload);
        if (response) {
          yield all([
            put({
              type: SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN_SUCCESS,
              userSubscribeData: response.data.data,
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload,
            }),
            put({
              type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION,
            }),
            // put({
            //   type: SubscriptionPlanTypes.RENEW_CHECK
            // }),
            put({
              type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
              payload: subscriptionHistoryPayload,
            }),
          ]);
        } else {
          yield put({
            type: SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN_FAILED,
            upgradePlanErrorMsg: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN_FAILED,
          upgradePlanErrorMsg: error.data.message,
        });
      }
    }
  );
}

export function* checkAvailableSubscriptionEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION,
    function* () {
      try {
        const response = yield call(checkAvailablePlanReq);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION_SUCCESS,
            isAnyPlanPurchased: response.data.data.available,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getCountryListEffect() {
  yield takeEvery(SubscriptionPlanTypes.GET_COUNTRY_LIST_REQUEST, function* () {
    try {
      const response = yield call(getCountryListReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.GET_COUNTRY_LIST_SUCCESS,
          countryList: response.data.data.countryData,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.GET_COUNTRY_LIST_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.GET_COUNTRY_LIST_FAILED,
        errorMessage: error.data.message,
      });
    }
  });
}

export function* getCountryByIdEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_COUNTRY_BY_ID_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getCountryByIdReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.GET_COUNTRY_BY_ID_SUCCESS,
            countryData: response.data.data.countryData,
          });
          localStorage.setItem(
            'countryData',
            JSON.stringify(response.data.data.countryData ?? {}) ?? {}
          );
        } else {
          yield put({
            type: SubscriptionPlanTypes.GET_COUNTRY_BY_ID_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.GET_COUNTRY_BY_ID_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getCountryDropdownEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_COUNTRY_DROPDOWN_REQUEST,
    function* () {
      try {
        const response = yield call(getCountryDropdownReq);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.GET_COUNTRY_DROPDOWN_SUCCESS,
            allCountries: response.data.data.countryData,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.GET_COUNTRY_DROPDOWN_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.GET_COUNTRY_DROPDOWN_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getCountryDataByIdEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_COUNTRY_DATA_BY_ID_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getCountryDataByIdReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.GET_COUNTRY_DATA_BY_ID_SUCCESS,
            SelectedCountryData: response.data.data.countryData,
          });
          localStorage.setItem(
            'countryData',
            JSON.stringify(response.data.data.countryData ?? {}) ?? {}
          );
        } else {
          yield put({
            type: SubscriptionPlanTypes.GET_COUNTRY_DATA_BY_ID_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.GET_COUNTRY_DATA_BY_ID_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* allPlansEffect() {
  yield takeEvery(SubscriptionPlanTypes.GET_ALL_PLANS, function* () {
    try {
      const response = yield call(viewPlansReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.GET_ALL_PLANS_SUCCESS,
          allPlans: response.data.data.getPlanData,
        });
        yield localStorage.setItem(
          'allPlans',
          JSON.stringify(response.data.data.getPlanData)
        );
      } else {
        yield put({
          type: SubscriptionPlanTypes.GET_ALL_PLANS_FAILED,
          errorMessage: response.data,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.GET_ALL_PLANS_FAILED,
        errorMessage: error.data.message,
      });
    }
  });
}

export function* allActivePlansEffect() {
  yield takeEvery(SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(activePlansReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS_SUCCESS,
          allActivePlans: response.data.getPlanData,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS_FAILED,
          errorMessage: response.data,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS_FAILED,
        errorMessage: error,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* verifyPromoCodeEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.VERIFY_PROMO_CODE,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(checkPromoCodeReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.VERIFY_PROMO_CODE_SUCCESS,
            promoCodeValid: response.data.data.status,
            appliedPromoCodeData: response.data.data.promocodeCheck,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.VERIFY_PROMO_CODE_FAILED,
            promoNotValidError: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_PROMO_CODE_FAILED,
          promoNotValidError: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

//verify promocode after company signup
export function* verifyPromoEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.VERIFY_PROMO,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(verifyPromo, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.VERIFY_PROMO_SUCCESS,
            promoCodeValid: response.data.data.status,
            appliedPromoCodeData: response.data.data.promocodeCheck,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.VERIFY_PROMO_FAILED,
            promoNotValidError: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_PROMO_FAILED,
          promoNotValidError: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* checkoutSessionEffect() {
  yield takeEvery(SubscriptionPlanTypes.CHECKOUT_SESSION, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(checkoutSessionReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.CHECKOUT_SESSION_SUCCESS,
          checkoutSuccessSessionId: response.data.data.id,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.CHECKOUT_SESSION_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.CHECKOUT_SESSION_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* cardVerifyEffect() {
  yield takeEvery(SubscriptionPlanTypes.VERIFY_CARD, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(cardVerifyReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_CARD_SUCCESS,
          checkoutSuccessSessionId: response.data.data.id,
        });
        history.push('/creditcardinfo');
      } else {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_CARD_FAILED,
          errorMessage: response.data,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.VERIFY_CARD_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* cardVerifyPostSigninEffect() {
  yield takeEvery(SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(cardVerifyPostSigninReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN_SUCCESS,
          checkoutSuccessSessionId: response.data.data.id,
        });
        if (response.data.data) {
          history.push('/dashboard/subscribeplanreflected');
        }
        yield put({
          type: SubscriptionPlanTypes.REPLACE_CARD_SUCCESS,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN_FAILED,
          errorMessage: response.data,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* checkoutSessionPostSigninEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN,
    function* () {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(checkoutSessionPostSigninReq);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN_SUCCESS,
            checkoutSuccessSessionId: response.data.data.id,
          });
        } else {
          yield put({
            type: SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getCardDetailEffect() {
  yield takeEvery(SubscriptionPlanTypes.GET_CARD_DETAILS, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getCardDetailsReq);
      if (response) {
        yield put({
          type: SubscriptionPlanTypes.GET_CARD_DETAILS_SUCCESS,
          cardDetail: response.data.data.paymentMethod.card,
        });
      } else {
        yield put({
          type: SubscriptionPlanTypes.GET_CARD_DETAILS_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: SubscriptionPlanTypes.GET_CARD_DETAILS_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getStatesByIdEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_STATES_BY_ID_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getStatesByIdReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.GET_STATES_BY_ID_SUCCESS,
            statesList: response.data.data.statesData,
          });
          localStorage.setItem(
            'statesData',
            JSON.stringify(response.data.data.statesData)
          );
        } else {
          yield put({
            type: SubscriptionPlanTypes.GET_STATES_BY_ID_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.GET_STATES_BY_ID_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getStatesListEffect() {
  yield takeEvery(
    SubscriptionPlanTypes.GET_STATES_LIST_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getStatesListReq, payload);
        if (response) {
          yield put({
            type: SubscriptionPlanTypes.GET_STATES_LIST_SUCCESS,
            statesList: response.data.data.statesData,
          });
          localStorage.setItem(
            'statesData',
            JSON.stringify(response.data.data.statesData)
          );
        } else {
          yield put({
            type: SubscriptionPlanTypes.GET_STATES_LIST_FAILED,
            errorMessage: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: SubscriptionPlanTypes.GET_STATES_LIST_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postSubscriptionPlanEffect),
    fork(saveCardEffect),
    fork(cancelSubscriptionEffect),
    fork(makePaymentEffect),
    fork(savePaymentAddressEffect),
    fork(verifySubscriptionEffect),
    fork(transactionViewEffect),
    fork(subscriptionHistoryEffect),
    fork(additionalLicenseEffect),
    fork(savedCardEffect),
    fork(replaceCardEffect),
    fork(cancelSubscriptionOTPEffect),
    fork(subscriberPaymentEffect),
    fork(upgradePlanEffect),
    fork(downgradeUserEffect),
    fork(upgradeUserEffect),
    // fork(renewCheckEffect),
    fork(checkSubscriptionEffect),
    fork(checkUserStatusEffect),
    fork(newCardEffect),
    fork(purchaseSubscriptionPlanEffect),
    fork(checkAvailableSubscriptionEffect),
    fork(deleteInvitedUserEffect),
    fork(getCountryByIdEffect),
    fork(getCountryListEffect),
    fork(getCountryDataByIdEffect),
    fork(getCountryDropdownEffect),
    fork(allPlansEffect),
    fork(allActivePlansEffect),
    fork(verifyPromoCodeEffect),
    fork(verifyPromoEffect),
    fork(checkoutSessionEffect),
    fork(cardVerifyEffect),
    fork(cardVerifyPostSigninEffect),
    fork(checkoutSessionPostSigninEffect),
    fork(getCardDetailEffect),
    fork(getStatesByIdEffect),
    fork(getStatesListEffect),
  ]);
}
