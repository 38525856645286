export enum vmsbCommonTypes {
  GET_TO_BE_NOTIFIED_USERS = "GET_TO_BE_NOTIFIED_USERS",
  GET_TO_BE_NOTIFIED_USERS_SUCCESS = "GET_TO_BE_NOTIFIED_USERS_SUCCESS",
  GET_TO_BE_NOTIFIED_USERS_FAILED = "GET_TO_BE_NOTIFIED_USERS_FAILED",

  GET_MSB_LIST = "GET_MSB_LIST",
  GET_MSB_LIST_SUCCESS = "GET_MSB_LIST_SUCCESS",
  GET_VISION_LIST = "GET_VISION_LIST",
  GET_VISION_LIST_SUCCESS = "GET_VISION_LIST_SUCCESS",

  POST_VMSB_PERIOD_LIST = "POST_VMSB_PERIOD_LIST",
  POST_VMSB_START_PERIOD_LIST_SUCCESS = "POST_VMSB_START_PERIOD_LIST_SUCCESS",
  POST_VMSB_END_PERIOD_LIST_SUCCESS = "POST_VMSB_END_PERIOD_LIST_SUCCESS",
  POST_VMSB_PERIOD_LIST_FAILED = "POST_VMSB_PERIOD_LIST_FAILED",

  CHANGE_PAGE_NUM_SIZE = "CHANGE_PAGE_NUM_SIZE",

  DELETE_VISION = "DELETE_VISION",
  DELETE_VISION_SUCCESS = "DELETE_VISION_SUCCESS",
  DELETE_VISION_FAILED = "DELETE_VISION_FAILED",

  DELETE_STRATEGY = "DELETE_STRATEGY",
  DELETE_STRATEGY_SUCCESS = "DELETE_STRATEGY_SUCCESS",
  DELETE_STRATEGY_FAILED = "DELETE_STRATEGY_FAILED",

  DELETE_BENEFIT = "DELETE_BENEFIT",
  DELETE_BENEFIT_SUCCESS = "DELETE_BENEFIT_SUCCESS",
  DELETE_BENEFIT_FAILED = "DELETE_BENEFIT_FAILED",

  DELETE_MISSION = "DELETE_MISSION",
  DELETE_MISSION_SUCCESS = "DELETE_MISSION_SUCCESS",
  DELETE_MISSION_FAILED = "DELETE_MISSION_FAILED",

  REMOVE_DEL_ALERT = "REMOVE_DEL_ALERT",
}
