import moment from 'moment-timezone';
import { promoCodeTypes } from './actionTypes';
import {
  promoCodeState,
  promoCodeAction,
  promoCode,
  promoDetailsList,
} from './types';
import {
  dateFormatList,
  defaultMetaData,
  subscriptionPlanTypes,
} from '../../../config/constant';

const initState: promoCodeState = {
  promoAddSuccess: '',
  promoAddError: '',
  generatedPromo: '',
  promoListError: '',
  allPromoCodes: [],
  totalPromos: defaultMetaData,
  promoDetails: {
    id: '',
    title: '',
    status: '',
    startDate: '',
    endDate: '',
    planType: null,
    data: {
      Code: '',
      'Subscription Plan': '',
      'Applicable to': '',
      Discount: '',
      'Number Of Redeems': null,
      'Maximum Redeems': null,
      'Starting Date': '',
      'Ending Date': '',
      'Time period': '',
    },
  },
  promoDetailError: '',
  promoAppliedCount: defaultMetaData,
  promoDetailsList: [],
  deleteSuccessMessage: '',
  promoUpdateError: '',
  promoDeleteError: '',
  promoUpdateSuccess: '',
};

const promoCodeListModifier = (data, metadata) => {
  const PromoCodes: promoCode[] = [];
  if (metadata?.totalItems > 0) {
    for (const record of data) {
      const planType = subscriptionPlanTypes.filter(
        (planType) => planType.id === record.plan_type
      );
      const list = {
        id: record.id,
        code: record.promocode,
        discount: `${record.discount}%`,
        created: moment(record.created_at).format(dateFormatList),
        validity: `${moment(record.start_date).format(
          dateFormatList
        )} - ${moment(record.end_date).format(dateFormatList)}`,
        plan: record.plan_id?.plan_name,
        status:
          record.status === 0
            ? 'expired'
            : record.status === 1
            ? 'active'
            : 'deleted',
        redemptions: record.no_of_redeems,
        planType: record.plan_type !== null ? planType[0].type : '',
        editable: moment(
          moment(record.end_date).format('YYYY-MM-DD')
        ).isSameOrAfter(moment(moment().format('YYYY-MM-DD'))),
        redemption_detail: record.promo_id,
      };
      PromoCodes.push(list);
    }
  }
  return PromoCodes;
};

const promoCodeDetailModifier = (data) => {
  const promocode = {
    title: 'Promocode Details',
    status:
      data.status === 0 ? 'expired' : data.status === 1 ? 'active' : 'deleted',
    planType: data.plan_type,
    startDate: data.start_date,
    endDate: data.end_date,
    id: data?.id,
    editable: moment(moment(data.end_date).format('YYYY-MM-DD')).isSameOrAfter(
      moment(moment().format('YYYY-MM-DD'))
    ),
    data: {
      Code: data.promocode,
      'Subscription Plan': data.plan_id.plan_name,
      Discount: `${data.discount}%`,
      'Number Of Redeems':
        data.promo_id.length > 0 ? data.promo_id.length : '-',
      'Maximum Redeems': data.max_redeems,
      'Starting Date': moment(data.start_date).format(dateFormatList),
      'Ending Date': moment(data.end_date).format(dateFormatList),
      'Time period': `${moment(data.end_date).diff(
        moment(data.start_date),
        'months'
      )} months`,
    },
  };
  return promocode;
};

const promoCodeDetailListModifier = (data) => {
  const PromoDetailsList: promoDetailsList[] = [];
  data?.promo_id?.pagedItems?.length > 0 &&
    data?.promo_id?.pagedItems?.forEach((record) => {
      const planType = subscriptionPlanTypes.filter(
        (planType) => planType.id === record.type
      );

      const list = {
        admin: `${record.user_id.first_name} ${record.user_id.last_name}`,
        email: record.user_id.created_by,
        workspace: record.user_id.company_name,
        code: data.promocode,
        discount: `${data.discount}%`,
        renewedDate: moment(data.created_at).format(dateFormatList),
        plan: data.plan_id.plan_name,
        planType: planType[0].type,
        license: record.no_licenses,
      };
      PromoDetailsList.push(list);
    });
  return PromoDetailsList;
};

export default function PromoCodeReducer(
  state = initState,
  action: promoCodeAction
) {
  switch (action.type) {
    case promoCodeTypes.ADD_PROMO_CODE:
      return {
        ...state,
        promoAddSuccess: '',
        promoAddError: '',
      };
    case promoCodeTypes.ADD_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoAddSuccess: 'PromoCode added successfully.',
      };
    case promoCodeTypes.ADD_PROMO_CODE_FAILURE:
      return {
        ...state,
        promoAddError: action.promoAddError,
      };
    case promoCodeTypes.GENERATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        generatedPromo: action.generatedPromo,
      };
    case promoCodeTypes.GET_PROMO_LIST_SUCCESS:
      return {
        ...state,
        allPromoCodes: promoCodeListModifier(
          action.allPromoCodes,
          action.totalPromos
        ),
        totalPromos: action.totalPromos,
      };
    case promoCodeTypes.GET_PROMO_LIST_FAILURE:
      return {
        ...state,
        promoListError: action.promoListError,
      };
    case promoCodeTypes.GET_PROMO_DETAIL:
      return {
        ...state,
        promoDetailError: '',
      };
    case promoCodeTypes.GET_PROMO_DETAIL_SUCCESS:
      return {
        ...state,
        promoDetails: promoCodeDetailModifier(action.promoDetails),
        promoDetailError: '',
      };
    case promoCodeTypes.GET_PROMO_DETAIL_LIST:
      return {
        ...state,
        promoDetailsList: [],
        promoAppliedCount: defaultMetaData,
        promoDetailError: '',
      };
    case promoCodeTypes.GET_PROMO_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        promoDetailsList: promoCodeDetailListModifier(action.promoDetailsList),
        promoAppliedCount: action.promoAppliedCount,
        promoDetailError: '',
      };
    case promoCodeTypes.GET_PROMO_DETAIL_LIST_FAILURE:
      return {
        ...state,
        promoDetailsList: [],
        promoAppliedCount: defaultMetaData,
      };
    case promoCodeTypes.GET_PROMO_DETAIL_FAILURE:
      return {
        ...state,
        promoDetailError: action.promoDetailError,
      };
    case promoCodeTypes.DELETE_PROMO_CODE:
      return {
        ...state,
        deleteSuccessMessage: '',
      };
    case promoCodeTypes.DELETE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.deleteSuccessMessage,
      };
    case promoCodeTypes.UPDATE_PROMO_CODE:
      return {
        ...state,
        promoUpdateSuccess: '',
        promoUpdateError: '',
      };
    case promoCodeTypes.UPDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoUpdateSuccess: action.promoUpdateSuccess,
      };
    case promoCodeTypes.UPDATE_PROMO_CODE_FAILURE:
      return {
        ...state,
        promoUpdateError: action.promoUpdateError,
      };
    case promoCodeTypes.REMOVE_ALERT:
      return {
        ...state,
        promoAddError: '',
        promoDetailError: '',
        promoAddSuccess: '',
        deleteSuccessMessage: '',
        promoUpdateError: '',
        promoDeleteError: '',
        promoUpdateSuccess: '',
      };
    default:
      return {
        ...state,
      };
  }
}
