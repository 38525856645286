import GlobalStyles from './assets/styles/globalStyle';
import './assets/icons.css';

import Routes from './router';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Page404 from './containers/Page404/Page404';
import Loader from './components/utility/loader';
import { getSubDomain } from './library/services/subdomain';
import { checkPortalURLinRoot } from './library/services/auth';
import history from './library/helpers/history';
import { emptyCache } from './library/helpers/utility';
import siteConfig from './config/site.config';

// TODO: Need to find better place to plug-in this [Abhijit]
ReactGA.initialize(siteConfig.google.analyticsKey);

const App = (): JSX.Element => {
  const location = useLocation();

  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  // GA: pageview event
  useEffect(() => {
    const scrollY = window.scrollY;
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    ReactGA.send({scroll_event: {
      scroll_position: `${scrollY}px`,
      page_path: location.pathname + location.search,
    }});
  }, [location]);

  useEffect(() => {
    sessionStorage.setItem('prevPathName', location.pathname);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const inactivity = () => {
    if (localStorage.getItem('id_token')) {
      localStorage.clear();
      sessionStorage.clear();
      emptyCache();
      history.push('/');
      window.location.reload();
    } else if( localStorage.getItem('admin_accessToken')) {
      localStorage.clear();
      sessionStorage.clear();
      emptyCache();
      history.push('/superadmin/login');
      window.location.reload();
    }
  };

  useEffect(() => {
    let time;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(inactivity, 60 * 60 * 1000);
    }
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
  }, []);

  useEffect(() => {
    let subdomain: any = getSubDomain();
    if (subdomain === 'executive') {
      setLoading(false);
      setStatus(true);
    } else {
      checkUrl(subdomain);
    }
  }, [window.location]); //eslint-disable-line react-hooks/exhaustive-deps

  const checkUrl = async (host: string) => {
    const { data } = await checkPortalURLinRoot({
      company_slug: host
    });

    setStatus(data?.status);
    setLoading(false);
    data?.status === false && history.push('/404');
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else if (status) {
    return (
      <div className="App">
        <GlobalStyles />
        <Routes />
      </div>
    );
  } else {
    return <Page404 />;
  }
};

export default App;
