import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import history from "../../library/helpers/history";
import authActions from "../app/action";
import { holdActions } from "../Holds/holdActions";
import {
  postKeyResultReq,
  getKeyResultReq,
  patchKeyResultReq,
  getObjOfUserReq,
  getKrCommetsReq,
  getTaskInKeysReq
} from "../../library/services/KeyResultReq";
import { keyResultTypes } from "./keyResultTypes";
import { deleteForeginLinkedKRPayload, keyResultPayload } from "./types";
import { deleteForeginLinkedReq } from "../../library/services/ObjectiveReq";

export function* postKeyResultEffect() {
  yield takeEvery(
    keyResultTypes.POST_KEYRESULT,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: keyResultPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postKeyResultReq, payload);
        if (response.status) {
          yield all([
            put({
              type: keyResultTypes.POST_KEYRESULT_SUCCESS,
              successMessage: response?.data?.data?.message
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
          history.push(`viewkey/${response?.data?.data?.keyresultData?.Id}`);
        }
      } catch (error: any) {
        yield all([
          put({
            type: keyResultTypes.POST_KEYRESULT_FAILED,
            errorMessage: error?.data?.message
          }),
          put({
            type: holdActions.SCROLL_TO_TOP
          })
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getKeyResultEffect() {
  yield takeEvery(
    keyResultTypes.GET_KEYRESULT,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: keyResultPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getKeyResultReq, payload);
        if (response) {
          yield put({
            type: keyResultTypes.GET_KEYRESULT_SUCCESS,
            SingleKeyResult: response?.data?.data
          });
        } else {
          yield put({
            type: keyResultTypes.GET_KEYRESULT_FAILED,
            payload: response
          });
        }
      } catch (error: any) {
        yield put({
          type: keyResultTypes.GET_KEYRESULT_FAILED,
          payload: error.message
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* patchKeyResultEffect() {
  yield takeEvery(
    keyResultTypes.PATCH_KEYRESULT,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: keyResultPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(patchKeyResultReq, payload);
        if (response) {
          yield all([
            put({
              type: keyResultTypes.PATCH_KEYRESULT_SUCCESS,
              successMessage: response?.data?.data?.message
            }),
            put({
              type: keyResultTypes.GET_KEYRESULT,
              payload: payload
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: keyResultTypes.PATCH_KEYRESULT_FAILED,
            errorMessage: error?.data?.message
          }),
          put({
            type: holdActions.SCROLL_TO_TOP
          })
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getObjOfUserEffect() {
  yield takeEvery(keyResultTypes.GET_OBJ_OF_USER, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getObjOfUserReq);
      if (response?.status) {
        yield put({
          type: keyResultTypes.GET_OBJ_OF_USER_SUCCESS,
          Objective_Of_User: response?.data?.data?.Objective
        });
      }
    } catch (error: any) {
      yield put({
        type: keyResultTypes.GET_OBJ_OF_USER_FAILED,
        payload: error.message
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getKrCommetsEffect() {
  yield takeEvery(
    keyResultTypes.GET_KR_COMMENTS,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: keyResultPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getKrCommetsReq, payload);
        if (response.status) {
          yield put({
            type: keyResultTypes.GET_KR_COMMENTS_SUCCESS,
            payload: response?.data?.data?.paginatedkeyresultData
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getKrTasksEffect() {
  yield takeEvery(
    keyResultTypes.GET_KR_TASK,
    function* ({
      payload
    }: {
      type: string;
      isIncrement: boolean;
      payload: keyResultPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getTaskInKeysReq, payload);
        if (response.status) {
          yield put({
            type: keyResultTypes.GET_KR_TASK_SUCCESS,
            krTaskList: response?.data?.data?.paginatedtaskData
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* deleteForeginLinkedKREffect() {
  yield takeEvery(
    keyResultTypes.DELETE_FOREGIN_LINKED_KEY_RESULT,
    function* ({
      payload
    }: {
      type: string;
      payload: deleteForeginLinkedKRPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const id = payload?.parent_kr;

        const response: any = yield call(deleteForeginLinkedReq, payload);
        if (response?.status) {
          yield all([
            put({
              type: keyResultTypes.DELETE_FOREGIN_LINKED_KEY_RESULT_SUCCESS,
              successMessage: response?.data?.data?.message
            }),
            put({
              type: keyResultTypes.GET_KEYRESULT,
              payload: { id: String(id) }
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: keyResultTypes.DELETE_FOREGIN_LINKED_KEY_RESULT_FAILED,
            errorMessage: error?.data?.message
          }),
          put({
            type: holdActions.SCROLL_TO_TOP
          })
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postKeyResultEffect),
    fork(getKeyResultEffect),
    fork(patchKeyResultEffect),
    fork(getObjOfUserEffect),
    fork(getKrCommetsEffect),
    fork(getKrTasksEffect),
    fork(deleteForeginLinkedKREffect)
  ]);
}
