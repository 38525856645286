export enum workspaceActions {
  POST_0AUTH_SIGNIN = 'POST_0AUTH_SIGNIN',
  POST_0AUTH_SIGNIN_SUCCESS = 'POST_0AUTH_SIGNIN_SUCCESS',
  POST_0AUTH_SIGNIN_FAILED = 'POST_0AUTH_SIGNIN_FAILED',

  POST_WORKSPACE_EMAIL = 'POST_WORKSPACE_EMAIL',
  POST_WORKSPACE_EMAIL_SUCCESS = 'POST_WORKSPACE_EMAIL_SUCCESS',
  POST_WORKSPACE_EMAIL_FAILED = 'POST_WORKSPACE_EMAIL_FAILED',

  POST_WORKSPACE_OTP = 'POST_WORKSPACE_OTP',
  POST_WORKSPACE_OTP_SUCCESS = 'POST_WORKSPACE_OTP_SUCCESS',
  POST_WORKSPACE_OTP_FAILED = 'POST_WORKSPACE_OTP_FAILED',

  PATCH_WORKSPACE_RESEND_OTP = 'PATCH_WORKSPACE_RESEND_OTP',
  PATCH_WORKSPACE_RESEND_OTP_SUCCESS = 'PATCH_WORKSPACE_RESEND_OTP_SUCCESS',
  PATCH_WORKSPACE_RESEND_OTP_FAILED = 'PATCH_WORKSPACE_RESEND_OTP_FAILED',

  GET_WORKSPACE_LIST = 'GET_WORKSPACE_LIST',
  GET_WORKSPACE_LIST_SUCCESS = 'GET_WORKSPACE_LIST_SUCCESS',
  GET_WORKSPACE_LIST_FAILED = 'GET_WORKSPACE_LIST_FAILED',

  CLEAR_MESSAGES = 'CLEAR_MESSAGES',

  CLOSE_OTP_MODAL = 'CLOSE_OTP_MODAL',
}
