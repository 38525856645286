import { ServiceAuthInstance } from "./index";

export const postMissionReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/mission/create_mission",
    data: {
      ...payload,
    },
  });
};

export const getMissionReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/mission/get_mission/${payload?.id}`,
  });
};

export const getStrategyInMissionReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/mission/mission_strategy/${payload.id}?page=${payload?.page}&take=${payload?.take}`,
  });
};

export const getVisionInMissionReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/mission/get_vision`,
    params: payload,
  });
};

export const editMissionReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: `/mission/edit_mission`,
    data: {
      ...payload,
    },
  });
};
