export enum subscriptionActionTypes {
  GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST',
  GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS',
  GET_SUBSCRIPTION_PLANS_FAILED = 'GET_SUBSCRIPTION_PLANS_FAILED',

  GET_PLAN_DETAILS = 'GET_PLAN_DETAILS',

  EDIT_SUBSCRIPTION_PLAN_REQUEST = 'EDIT_SUBSCRIPTION_PLAN_REQUEST',
  EDIT_SUBSCRIPTION_PLAN_SUCCESS = 'EDIT_SUBSCRIPTION_PLAN_SUCCESS',
  EDIT_SUBSCRIPTION_PLAN_FAILED = 'EDIT_SUBSCRIPTION_PLAN_FAILED',

  SUBSCRIPTION_HISTORY_REQUEST = 'SUBSCRIPTION_HISTORY_REQUEST',
  SUBSCRIPTION_HISTORY_SUCCESS = 'SUBSCRIPTION_HISTORY_SUCCESS',
  SUBSCRIPTION_HISTORY_FAILED = 'SUBSCRIPTION_HISTORY_FAILED',

  PLAN_STATUS_CHANGE_REQUEST = 'PLAN_STATUS_CHANGE_REQUEST',
  PLAN_STATUS_CHANGE_SUCCESS = 'PLAN_STATUS_CHANGE_SUCCESS',
  PLAN_STATUS_CHANGE_FAILED = 'PLAN_STATUS_CHANGE_FAILED',

  REMOVE_ALERT = 'REMOVE_ALERT'
}
