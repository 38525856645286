import { scoreCardStatusActions } from "./scoreCardStatusActions";
import { scoreCardStatusAction, scoreCardStatusReducerStates } from "./types";

let initState: scoreCardStatusReducerStates = {
  errorMessage: "",
  successMessage: "",
  scoreCardStatusData: {},
  vmsbList: [],
  totalItems: null,
};

const scoreCardStatusDataModifier = (data) => {
  const scoreCardStatusData: any[] = [];
  if (data && data.pagedItems.length > 0) {
    data.pagedItems.forEach((item, index) => {
      const scoreCardData: any = {
        key: item.category_id,
        scorecard: item.name,
        native_status: Number(item?.native_status ?? 0)?.toFixed(2),
        number: item.number,
        highest: `${item.highest}%`,
        lowest: `${item.lowest}%`,
        kr: Number(item.okr),
        owner: item.owner,
        total_owners: item.total_owners,
        objective_type: data.objective_type,
        integrated_status: Number(item?.integrated_status ?? 0)?.toFixed(2),
      };
      scoreCardStatusData.push(scoreCardData);
    });
  }
  return scoreCardStatusData;
};

export default function authReducer(
  state = initState,
  action: scoreCardStatusAction
) {
  switch (action.type) {
    case scoreCardStatusActions.POST_SCORE_CARD_STATUS:
      return {
        ...state,
      };
    case scoreCardStatusActions.POST_SCORE_CARD_STATUS_SUCCESS:
      let scoreCardData = scoreCardStatusDataModifier(
        action?.scoreCardStatusData
      );
      return {
        ...state,
        scoreCardStatusData: scoreCardData,
        totalItems: action?.scoreCardStatusData?.metaData?.totalItems,
      };
    case scoreCardStatusActions.POST_SCORE_CARD_STATUS_FAILED:
      return {
        ...state,
      };
    case scoreCardStatusActions.SCORE_CARD_STATUS_VMSB_LIST:
      return {
        ...state,
      };
    case scoreCardStatusActions.SCORE_CARD_STATUS_VMSB_LIST_SUCCESS:
      return {
        ...state,
        vmsbList: action?.vmsbList,
      };
    case scoreCardStatusActions.SCORE_CARD_STATUS_VMSB_LIST_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
}
