import { Form, Select, Upload, message, Checkbox, Button } from "antd";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddPhoto from "../../assets/images/add-photo.svg";
import logo from "../../assets/images/TA-Horizontal_Logo.png";
import { UploadFile } from "antd/lib/upload/interface";
import Input from "../../components/uielements/input";
import SignUpEmployeeStyleWrapper from "../SignUpEmployeeStep1/SIgnUpEmployeeStep1.styles";
import AuthPageFeatureImage from "../../components/AuthPageFeatureImage/AuthPageFeatureImage";
import { AppConstant, OKRNameRegex } from "../../config/constant";
import {
  getCompanyIndrustry,
  getCompanySlugAvailable,
  postExecutiveSignupStep2,
} from "../../redux/ExecutiveSignupStep2/actions";
import { RootState } from "../../redux/store/rootReducer";
import { getUrl } from "../../library/services/subdomain";
import PrivacyAndTerms from "../../components/PrivacyAndTerms/PrivacyAndTerms";
import ATag from "../../components/AnchorTag/ATag";
import { GoogleEventObj, googleEventTracker } from "../../config/site.config";

const { Option } = Select;

export default function SignUpAdminStep2() {
  const dispatch = useDispatch();
  const { errorMessage, CompanyIndrustry } = useSelector(
    (state: RootState) => state.ExecutiveSignupStep2Reducer
  );
  const [socialEmail, setSocialEmail] = useState<string | null>("");
  const [comSlug, setComSlug] = useState("");

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    setSocialEmail(email);
  }, []);

  useEffect(() => {
    dispatch(getCompanyIndrustry());
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const messageEndRef = useRef<HTMLHeadingElement>(null);

  const scrollToTop = () => {
    messageEndRef &&
      messageEndRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
  };
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "",
      status: "done",
      url: "",
    },
  ]);
  const slugRegex = new RegExp(`[a-zA-Z0-9]*\.(${getUrl()})`); //eslint-disable-line

  const [companysName, setCompanysName] = useState("");
  const [timer, setTimer] = useState<any>(null);

  const [signupForm] = Form.useForm();

  const setPortalUrlOnBlur = () => {
    const companyName = signupForm.getFieldValue("company_name");
    if (companyName.replace(/[^A-Z0-9]/gi, "").trim() && companyName.trim()) {
      const com_slug = companyName.replace(/[^A-Z0-9]/gi, "");
      signupForm.setFieldsValue({
        portal_url: `${com_slug.toLowerCase()}${getUrl()}`,
      });
      const payload = {
        company_slug: com_slug,
      };
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        setComSlug(com_slug);
        dispatch(getCompanySlugAvailable(payload));
      }, 1000);

      setTimer(newTimer);
    }
  };

  const handleCompanyName = (e) => {
    signupForm.setFieldsValue({
      company_name: e.target.value,
    });
    setCompanysName(e.target.value);
    if (e.target.value.trim() === "") {
      signupForm.setFieldsValue({
        portal_url: "",
      });
    }
  };

  const handlePortalUrl = (e) => {
    let com_slug = e.target.value;

    let localhostIndex = com_slug.indexOf("localhost");
    let result = com_slug.indexOf(".");
    result = e.target.value.includes(".")
      ? com_slug.slice(0, result)
      : com_slug.slice(0, localhostIndex);

    result = /[~`!@#$%\^&*()+_=\-\[\]\\';,/{}|\\":<>\?]/g.test(result) //eslint-disable-line
      ? result.slice(0, -1).replace(/\s/g, "")
      : result.replace(/\s/g, "");

    signupForm.setFieldsValue({
      portal_url: result + getUrl(),
    });
    let i = com_slug.indexOf(".");

    let url = com_slug.slice(0, i);

    const payload = {
      company_slug: url,
    };

    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      Boolean(com_slug.slice(0, i).trim()) && setComSlug(com_slug.slice(0, i));
      if (url.trim() !== "") {
        dispatch(getCompanySlugAvailable(payload));
      }
    }, 1000);

    setTimer(newTimer);
  };
  useEffect(() => {
    if (errorMessage) {
      signupForm.setFields([
        {
          name: "portal_url",
          errors: [errorMessage],
        },
      ]);
    }
  }, [errorMessage]); //eslint-disable-line react-hooks/exhaustive-deps

  const checkForErrors = (evt: any) => {
    if(evt?.errorFields?.length) {
      evt?.errorFields?.forEach(field => {
        googleEventTracker(GoogleEventObj.form_error.name, {
          category: 'admin_signup_step_three_' + field?.name?.[0] || 'n/a' ,
          action: GoogleEventObj.form_error.action,
          label: GoogleEventObj.form_error.getLabel(),
          value: field?.errors?.[0] 
        });
      })
    }
  }

  const handleSubmit = (value) => {
    googleEventTracker(GoogleEventObj.form_submit.name, {
      category: 'admin_signup_step_three',
      action: GoogleEventObj.form_submit.action,
      label: GoogleEventObj.form_submit.getLabel(),
      value: GoogleEventObj.form_submit.value
    });
    let formData: any = new FormData();
    let payload = {
      //@ts-ignore
      file: fileList[0]?.originFileObj ? fileList[0]?.originFileObj : null,
      company_name: value.company_name.trim(),
      company_slug: comSlug !== "" ? comSlug : value.company_slug,
      company_industry: value.company_industry,
      terms_conditions: value.terms_condition === true ? 1 : 0,
    };
    Object.keys(payload).forEach((key) =>
      payload[key] === undefined ? delete payload[key] : {}
    );

    Object.keys(payload).map((key) => formData.append(key + "", payload[key]));
    scrollToTop();
    localStorage.setItem(
      "company_industry",
      JSON.stringify(payload.company_industry)
    );
    localStorage.setItem("logo", JSON.stringify(fileList[0]));
    dispatch(postExecutiveSignupStep2({ payload }));
  };

  const handleChange = ({ fileList, file }) => {
    const isValidImage =
      file.type === "image/jpeg" || file.type === "image/png";
    if (!isValidImage) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const imgSzLt = file.size / 1024 / 1024 < 2;
    if (isValidImage && !imgSzLt) {
      message.error("Please upload image less than 2mb");
      return;
    }
    setFileList([fileList[fileList.length - 1]]);
    signupForm.setFields([
      {
        name: "profile",
        errors: [],
      },
    ]);

    // }
  };
  const uploadButton = (
    <div className="addphoto">
      <img src={AddPhoto} alt="" />
    </div>
  );

  const removeFile: any = () => {
    setFileList([
      {
        uid: "-1",
        name: "",
        status: "done",
      },
    ]);
  };

  const previewChange = () => {
    return false;
  };

  const onTermsClick = (e) => {
    e.preventDefault();
    googleEventTracker(GoogleEventObj.button_click.name, {
      category:'organization_signup_step_three_terms_and_conditions_click',
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel()
    });
    window.open(
      `https://www.${process.env.REACT_APP_MARKETING_INSTANCE}.com/terms-and-conditions`,
      "_blank"
    );
  };
  const onPrivacyClick = (e) => {
    e.preventDefault();
    googleEventTracker(GoogleEventObj.button_click.name, {
      category:'organization_signup_step_three_privacy_policy_click',
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel()
    });
    window.open(
      `https://www.${process.env.REACT_APP_MARKETING_INSTANCE}.com/privacy-policy`,
      "_blank"
    );
  };

  return (
    <>
      <SignUpEmployeeStyleWrapper className="isoSignUpPage add-tooltip signup-emp-step2">
        <AuthPageFeatureImage />
        <div className="isoSignUpContentWrapper" tabIndex={-1}>
          <div ref={messageEndRef} />
          <div className="isoSignUpContent">
            <div className="isoLogoWrapper">
              <img src={logo} alt="" />
            </div>
            <h3 className="title">SignUp</h3>
            <div className="signup-process">
              <div className="fill remove-border">
                <span>1</span>
              </div>
              <div className="fill remove-border">
                <span>2</span>
              </div>
              <div className="fill">
                <span>3</span>
              </div>
            </div>
            <h3 className="title company">Company Details</h3>
            <h3 className="title note">
              NOTE:Details once saved cannot be changed.
            </h3>
            <Form
              className="isoSignUpForm createCompany"
              form={signupForm}
              onFinish={handleSubmit}
              onFinishFailed={checkForErrors}
            >
              <div className="form-fields">
                {socialEmail && (
                  <h3 className="title use-id">
                    You are signing up using:<span>{socialEmail}</span>
                  </h3>
                )}
                <Form.Item
                  name="profile"
                  label="Company Logo:"
                  className="profile-item"
                >
                  <>
                  <Upload
                    name="avatar"
                    accept="image/*"
                    listType="picture-card"
                    className="profile-photo avatar-uploader"
                    fileList={fileList}
                    multiple={false}
                    onChange={handleChange}
                    onPreview={previewChange}
                    beforeUpload={() => false}
                    onRemove={removeFile}
                    showUploadList={{
                      showRemoveIcon: false,
                      showPreviewIcon: false,
                    }}
                  >
                    {uploadButton}
                  </Upload>
                  <p className="redirect remove-photo">
                  {fileList?.[0]?.originFileObj ? (
                    <ATag className={''} 
                      onClick={
                        fileList?.length > 0 ? removeFile : ""
                      }
                      tabIndex={0}
                      isButton={true}  
                    >
                      Remove Photo
                    </ATag>
                    ) : (
                      ""
                    )
                  }
                  </p>
                  </>
                </Form.Item>
                <Form.Item
                  name="company_name"
                  label="Company Name:"
                  rules={[
                    {
                      required: true,
                      message: AppConstant.FormValidation.companyNameRequired,
                    },
                    {
                      pattern: OKRNameRegex,
                      message: AppConstant.FormValidation.companyNameValid,
                    },
                  ]}
                >
                  <Input
                    placeholder="Company Name"
                    onChange={handleCompanyName}
                    onBlur={setPortalUrlOnBlur}
                  />
                </Form.Item>
                <Form.Item
                  name="portal_url"
                  label="Portal URL:"
                  normalize={(e) =>
                    /[~`!@#$%\^&*()+_=\-\[\]\\';,/{}|\\":<>\?]/g.test(e) //eslint-disable-line
                      ? e.slice(0, -1)
                      : e
                  }
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: AppConstant.FormValidation.portalUrlRequired,
                    },
                    {
                      pattern: slugRegex,
                      message: AppConstant.FormValidation.portalUrlValdi,
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => handlePortalUrl(e)}
                    size="large"
                    placeholder="companyname.targetalign.com"
                    url={getUrl()}
                    className={
                      companysName.trim() !== "" ? null : "disable-input"
                    }
                    disabled={companysName.trim() !== "" ? false : true}
                  ></Input>
                </Form.Item>
                <Form.Item
                  name="company_industry"
                  label="Company Industry:"
                  className="add-tooltip"
                  rules={[
                    {
                      required: true,
                      message:
                        AppConstant.FormValidation.comapnyIndustryRequired,
                    },
                  ]}
                >
                  <Select placeholder="Company Industry" size="large">
                    {CompanyIndrustry?.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <span className="terms-policy">
                  <Form.Item
                    className="terms-condition"
                    name="terms_condition"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined,
                        type: "boolean",
                        message: AppConstant.FormValidation.acceptTerms,
                      },
                    ]}
                    valuePropName="checked"
                    style={{ marginBottom: "0px" }}
                  >
                    <Checkbox style={{ color: "#2D2C2C" }}>
                      I agree to the
                    </Checkbox>
                  </Form.Item>
                  <ATag onClick={(e) => onTermsClick(e)} className='anchor-tag'
                    style={{
                      fontWeight: "bold",
                      color: "#22C4F2",
                      margin: "0 5px 0 0",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    Terms & Conditions
                  </ATag>
                  and
                  <ATag
                    onClick={(e) => onPrivacyClick(e)}
                    style={{
                      fontWeight: "bold",
                      color: "#22C4F2",
                      margin: "0 5px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    className='anchor-tag'
                  >
                    Privacy Policy
                  </ATag>
                </span>
              </div>
              <div className="signup-options">
                <div className="isoInputWrapper" style={{ marginBottom: 0 }}>
                  <Button className="cmn-btn" htmlType="submit">
                    Signup
                  </Button>
                </div>
              </div>
              <PrivacyAndTerms hide={true} pageName="organization_signup_step_three"/>
            </Form>
          </div>
        </div>
      </SignUpEmployeeStyleWrapper>
    </>
  );
}
