import { ServiceAuthInstance } from './index';

export const addInstaMessage = (payload) => {
  let formData: any = new FormData();
  Object.keys(payload).forEach((key) =>
    payload[key] === undefined ? delete payload[key] : {}
  );

  if (payload?.files?.length > 0) {
    for (const key of Object.keys(payload.files)) {
      formData.append('files', payload.files[key]);
    }
  }
  delete payload['files'];
  Object.keys(payload).map((key) => formData.append(key + '', payload[key]));
  return ServiceAuthInstance({
    method: 'POST',
    url: `organization/instant_message_feedback`,
    data: formData,
  });
};

export const getIssueTypesReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `organization/get_issue_types`,
  });
};

export const getCategoryTypesReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `organization/get_issue_categories`,
  });
};
