import { ServiceAuthInstance } from './index';

export const postTasksReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/list_view/add_task',
    data: {
      ...payload
    }
  });
};

export const getTasksReq = (payload) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `/list_view/get_task/${payload.id}`
  });
};

export const patchTasksReq = (payload) => {
  return ServiceAuthInstance({
    method: 'PATCH',
    url: '/list_view/update_task',
    data: {
      ...payload
    }
  });
};

export const getTasksOwnerReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/list_view/get_task_owner_from_user'
  });
};

export const getTasksKeyResultReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `/list_view/get_task_keyResult_from_objective`
    // params: {objective:payload.id}
  });
};

export const getTasksommetsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `/list_view/get_task_comments/${payload.id}?page=1&take=6`
  });
};

export const patchTaskStageReq = (payload) => {
  return ServiceAuthInstance({
    method: 'PATCH',
    url: `/list_view/add_task_stage`,
    data: {
      ...payload
    }
  });
};

export const patchHelpfulnessRatingReq = (payload) => {
  return ServiceAuthInstance({
    method: 'PATCH',
    url: '/list_view/update_helpfulness_task',
    data: {
      ...payload
    }
  });
};

export const deleteHelpfulnessRatingReq = (payload) => {
  return ServiceAuthInstance({
    method: 'PATCH',
    url: '/list_view/delete_helpfulness_task',
    data: {
      ...payload
    }
  });
};

export const deleteTaskReq = (payload) => {
  return ServiceAuthInstance({
    method: 'DELETE',
    url: `/list_view/delete_task/${payload.id}`
  })
}
