export enum GoalActionTypes {
  POST_MYGOALS = "POST_MYGOALS",
  POST_MYGOALS_SUCCESS = "POST_MYGOALS_SUCCESS",
  POST_MYGOALS_FAILED = "POST_MYGOALS_FAILED",

  POST_MY_ORG_CHART = "POST_MY_ORG_CHART",
  POST_MY_ORG_CHART_SUCCESS = "POST_MY_ORG_CHART_SUCCESS",
  POST_MY_ORG_CHART_FAILED = "POST_MY_ORG_CHART_FAILED",

  POST_ALL_TASK = "POST_ALL_TASK",
  POST_ALL_TASK_SUCCESS = "POST_ALL_TASK_SUCCESS",
  POST_ALL_TASK_FAILED = "POST_ALL_TASK_FAILED",

  DELETE_OBJECTIVE = "DELETE_OBJECTIVE",
  DELETE_OBJECTIVE_SUCCESS = "DELETE_OBJECTIVE_SUCCESS",
  DELETE_OBJECTIVE_FAILED = "DELETE_OBJECTIVE_FAILED",

  DELETE_KEY_RESULT = "DELETE_KEY_RESULT",
  DELETE_KEY_RESULT_SUCCESS = "DELETE_KEY_RESULT_SUCCESS",
  DELETE_KEY_RESULT_FAILED = "DELETE_KEY_RESULT_FAILED",

  CHANGE_PAGE = "CHANGE_PAGE",

  REMOVE_ALERT = "REMOVE_ALERT",
  GET_KR_OBJ_CAL_STATUS = "GET_KR_OBJ_CAL_STATUS",
  GET_KR_OBJ_CAL_STATUS_SUCCESS = "GET_KR_OBJ_CAL_STATUS_SUCCESS",
}
