export enum kanbanActions {
  // Action to get current vision for specific period in each tab
  POST_CURRENT_VISION = "POST_CURRENT_VISION",
  POST_CURRENT_VISION_SUCCESS = "POST_CURRENT_VISION_SUCCESS",
  POST_CURRENT_VISION_FAILED = "POST_CURRENT_VISION_FAILED",

  // Action to get parent hierarchy
  POST_PARENT_HIERARCHY = "POST_PARENT_HIERARCHY",
  POST_PARENT_HIERARCHY_SUCCESS = "POST_PARENT_HIERARCHY_SUCCESS",
  POST_PARENT_HIERARCHY_FAILED = "POST_PARENT_HIERARCHY_FAILED",

  // Actionn to get kanban okr details
  POST_KANBAN_DETAILS = "POST_KANBAN_DETAILS",
  POST_KANBAN_DETAILS_SUCCESS = "POST_KANBAN_DETAILS_SUCCESS",
  POST_KANBAN_DETAILS_FAILED = "POST_kanban_DETAILS_FAILED",

  // Action to ger OKR hierarchy
  POST_OKR_HIERARCHY = "POST_OKR_HIERARCHY",
  POST_OKR_HIERARCHY_SUCCESS = "POST_OKR_HIERARCHY_SUCCESS",
  POST_OKR_HIERARCHY_FAILED = "POST_OKR_HIERARCHY_FAILED",

  // Action to get  POST_KANBAN_KR_DETAILS
  POST_KANBAN_KR_DETAILS = "POST_KANBAN_KR_DETAILS",
  POST_KANBAN_KR_DETAILS_SUCCESS = "POST_KANBAN_KR_DETAILS_SUCCESS",
  POST_KANBAN_KR_DETAILS_FAILED = "POST_KANBAN_KR_DETAILS_FAILED",
}
