import { authActionTypes } from './actionTypes';
import { AdminAuthState, AdminAuthStateAction } from './types';

let initState: AdminAuthState = {
  errorMessage: '',
  successMessage: '',
  adminData: {
    id: 1,
    first_name: '',
    last_name: '',
    profile: null
  }
};

export default function adminAuthReducer(state = initState, action: AdminAuthStateAction) {
  switch (action.type) {
    case authActionTypes.ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    case authActionTypes.ADMIN_LOGIN_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: ''
      };
    case authActionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    case authActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage
      };
    case authActionTypes.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case authActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    case authActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage
      };
    case authActionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case authActionTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    default:
      return state;
  }
}
