import { ServiceAuthInstance } from "./index";

export const postObjectiveReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/new_objective",
    data: {
      ...payload
    }
  });
};
export const getObjectiveReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/objective/${payload?.id}`
  });
};
export const patchObjectiveReq = (payload) => {
  const id = payload?.id;
  delete payload.id;
  return ServiceAuthInstance({
    method: "PUT",
    url: `/list_view/edit_objective/${id}`,
    data: {
      ...payload
    }
  });
};

export const getObjCommetsReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/get_Objective_comments/${payload.id}?page=${payload.page}&take=${payload.take}`
  });
};

export const postObjCommentsReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/add_Objective_comments",
    data: {
      ...payload
    }
  });
};

export const getKrInObjReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/get_keyresult_in_objective/${payload.id}?page=${payload?.page}&take=${payload?.take}`
  });
};

export const getForeignObjKRListReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/okr_list`,
    params: payload
  });
};

export const getForeignBenefitListReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/benefits_list`,
    params: payload
  });
};

export const deleteForeginLinkedReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/obj_orphan`,
    params: payload
  });
};
