export enum searchTypes {
  GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS',
  GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS',
  GET_SEARCH_RESULTS_FAILED = 'GET_SEARCH_RESULTS_FAILED',

  CHANGE_SEARCH_RESULT_PAGE = 'CHANGE_SEARCH_RESULT_PAGE',
  RESET_DATA = 'RESET_DATA',
  REMOVE_ALERT = 'REMOVE_ALERT',

  GET_USER_PROFILE = 'GET_USER_PROFILE',
  GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED',
}
