const action = {
  POST_ORG_FINANCIAL_YEAR: "POST_ORG_FINANCIAL_YEAR",
  POST_ORG_FINANCIAL_YEAR_SUCCESS: "POST_ORG_FINANCIAL_YEAR_SUCCESS",
  POST_ORG_FINANCIAL_YEAR_FAILED: "POST_ORG_FINANCIAL_YEAR_FAILED",
  postOrgFinancialYear: (payload) => ({
    type: action.POST_ORG_FINANCIAL_YEAR,
    payload: payload,
  }),
};
export default action;
