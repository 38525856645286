import { Card, Form, Select, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/TA-Horizontal_Logo.png';
import Button from '../../components/Button/Button';
import { dateFormatList, monthInYear, freePLan, timePeriod } from '../../config/constant';
import { RootState } from '../../redux/store/rootReducer';
import {
  getAllActivePlans,
  getCardDetail,
  makePayment,
  postSubscriptionPlan,
  removeAlert
} from '../../redux/SubscriptionPlan/actions';
import CreditCardInfoStyleWrapper from './CreditCardInfo.styles';
import { annualRateCalculate, getMonths, getYears } from '../../library/helpers/utility';
import history from '../../library/helpers/history';
import { plan } from '../../redux/Admin/subscription/types';
import { InfoCircleFilled } from '@ant-design/icons';

export default function CreditCardInfo() {
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(false);
  const [addressSaved, setAddressSaved] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [license, setLicense] = useState(1);
  const [isError, setIsError] = useState(false);
  const [planEndDate, setPlanEndDate] = useState('');

  const { errorMessage, cardVerified, paymentAddressSave, planSubscribed, cardDetail } = useSelector(
    (state: RootState) => state.SubscriptionPlanReducer
  );
  const allActivePlans: plan[] = useSelector((state: RootState) => state.SubscriptionPlanReducer.allActivePlans);
  //@ts-ignore
  const planChosen = JSON.parse(localStorage.getItem('planChosen'));
  //@ts-ignore
  const cardIsVerified = localStorage.getItem('cardIsVerified');
  //@ts-ignore
  const totalPayableAmount = JSON.parse(localStorage.getItem('totalPayableAmount'));
  const planPurchased = localStorage.getItem('planPurchased');

  useEffect(() => {
    errorMessage && setIsError(true);
  }, [errorMessage]);

  useEffect(() => {
    dispatch(getCardDetail());
    dispatch(getAllActivePlans());
  }, []);

  useEffect(() => {
    console.log('localssssss', planChosen);
    const start_date_subscription = moment(moment()).utc().format('YYYY-MM-DD HH:mm:ss');
    const end_date_subscription = moment(moment()).utc().endOf('months').format('YYYY-MM-DD HH:mm:ss');

    const startMoment = moment.utc(start_date_subscription);
    const endMoment = moment.utc(end_date_subscription);
    const currentMonthInDays = moment().daysInMonth();
    const daysOfPaid = endMoment.diff(startMoment, 'days') + 1;
    const choosedPlanDetail: plan = allActivePlans.filter((i) => i?.id === planChosen.plan_id)[0];
    if (planChosen.plan_name === 'Free') {
      setPayableAmount(0);
      setLicense(planChosen.no_of_license);
      setPlanEndDate(
        moment(moment()).utc().add(choosedPlanDetail?.module?.trial_period, 'days').format(dateFormatList)
      );
    } else {
      if (planChosen.type === timePeriod.annually) {
        console.log('sdfd', allActivePlans);
        setPayableAmount(
          annualRateCalculate(choosedPlanDetail?.monthly_rate, choosedPlanDetail?.annual_discount) *
            (Math.ceil(planChosen.no_of_license / 5) * 5) *
            monthInYear
        );
        setPlanEndDate(moment(moment()).utc().add(1, 'year').subtract(1, 'days').format(dateFormatList));
        setLicense(planChosen.no_of_license);
      } else {
        setPayableAmount(
          planChosen.no_of_license * (parseInt(choosedPlanDetail?.monthly_rate) / currentMonthInDays) * daysOfPaid
        );
        setPlanEndDate(moment(end_date_subscription).format(dateFormatList));
        setLicense(planChosen.no_of_license);
      }
    }
    console.log('payableAmount', payableAmount);
  }, [allActivePlans]); //eslint-disable-line

  useEffect(() => {
    cardVerified && setVerified(true);
    window.history.pushState(null, '', window.location.pathname);

    const onBackButtonEvent = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.onpopstate = onBackButtonEvent;
    const timeout = setTimeout(() => {
      dispatch(removeAlert());
    }, 60 * 1000);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      clearTimeout(timeout);
    };
  }, [cardVerified]); //eslint-disable-line

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.onpopstate = onBackButtonEvent;
  }, []); //eslint-disable-line

  useEffect(() => {
    cardIsVerified === 'true' && setVerified(true);
  }, [cardIsVerified]);

  useEffect(() => {
    paymentAddressSave === true && setAddressSaved(true);
  }, [paymentAddressSave]);

  const handleMakePayment = () => {
    //@ts-ignore
    const planChosen = JSON.parse(localStorage.getItem('planChosen'));
    const payload = {
      ...planChosen,
      promo_code: localStorage.getItem('promo_code'),
      tax_amount: Number(localStorage.getItem('taxAmount')),
      tax_rate: Number(localStorage.getItem('taxRate')),
      hst: Number(localStorage.getItem('hst')),
      pst: Number(localStorage.getItem('pst')),
      gst: Number(localStorage.getItem('gst')),
    };
    if (planPurchased !== 'true') {
      //@ts-ignore
      dispatch(postSubscriptionPlan(payload));
    } else {
      dispatch(makePayment());
    }
  };

  return (
    <>
      <CreditCardInfoStyleWrapper>
        <div className='isoCardInfoContentWrapper'>
          <div className='isoCardInfoContent'>
            <div className='isoLogoWrapper'>
              <img src={logo} alt='' />
            </div>
            <Card
              title={
                <p className='trial trial-end'>
                  {planChosen.type === timePeriod.annually &&
                  planChosen.is_freePlan !== freePLan
                    ? 'Make Payment'
                    : 'Confirm Plan'}
                </p>
              }
              className='parent-card'
            >
              <div className='billing-wrapper'>
                <div className='isoPaymentContentWrapper'>
                  <div className='isoPaymentContent'>
                    <Card
                      title={
                        <div>
                          <p className='choose-plan'>
                            <label>Plan Chosen:</label>
                            <div>
                              <div>
                                <span>
                                  {`${planChosen.plan_name} Plan `}{' '}
                                  {planChosen.plan_name !== 'Free' &&
                                    `(${
                                      planChosen.type === timePeriod.monthly
                                        ? 'Monthly'
                                        : 'Annual'
                                    }) `}
                                </span>
                                <span>
                                  <sup>USD $</sup> {payableAmount.toFixed(2)}
                                </span>
                              </div>
                              <div className='license'>
                                <span>Licenses</span>
                                <span>
                                  {license}{' '}
                                  {planChosen.type === timePeriod.annually ? (
                                    <p>
                                      (billing for 1-
                                      {Math.ceil(license / 5) * 5})
                                    </p>
                                  ) : (
                                    ''
                                  )}{' '}
                                </span>
                              </div>
                              <div>
                                <span>
                                  {planChosen.is_freePlan === freePLan
                                    ? `Free trial ends on`
                                    : `Plan ending on`}
                                </span>
                                <span>
                                  {planChosen.is_freePlan === freePLan
                                    ? planEndDate
                                    : planEndDate}
                                </span>
                              </div>
                              <div>
                                <span>Total Payable Amount</span>
                                <span>
                                  <sup>USD $</sup>
                                  {localStorage.getItem('promo_code')
                                    ? totalPayableAmount
                                    : (
                                        payableAmount +
                                        Number(
                                          localStorage.getItem('taxAmount')
                                        )
                                      ).toFixed(2)}
                                  {localStorage.getItem('taxRate') && localStorage.getItem('taxRate') !== '0.00' && <span>
                                    <Tooltip
                                      placement='top'
                                      title={
                                        <div>
                                          <p>Includes sales tax as per province.</p>
                                          <p>
                                            <a
                                              href='https://www.retailcouncil.org/resources/quick-facts/sales-tax-rates-by-province/'
                                              target='_blank'
                                              className='tax-link'
                                            >
                                              Click to see tax rates.
                                            </a>
                                          </p>
                                        </div>
                                      }
                                      className='totalLicense-tooltip'
                                    >
                                      <InfoCircleFilled
                                        className='tooltip-icon'
                                        style={{ width: 'auto' }}
                                      />
                                    </Tooltip>
                                  </span>}
                                </span>
                              </div>
                            </div>
                          </p>
                        </div>
                      }
                    >
                      <div>
                        <p className='card-detail'>
                          <label>Card Details:</label>
                          <div>
                            <div>
                              <span>Card number</span>
                              <span> **** **** **** {cardDetail.last4}</span>
                            </div>
                            <div>
                              <span>Expiry</span>
                              <span>{`${cardDetail.exp_month}/${cardDetail.exp_year}`}</span>
                            </div>
                          </div>
                        </p>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Card>
            {planChosen?.is_freePlan === freePLan && (
              <div className='note'>
                <span>
                  Note:{' '}
                  {`Free Trial will end on ${planEndDate} after which, billing will apply on a premium monthly plan rate.`}
                </span>
              </div>
            )}
            <div className='btn-group verified-btn'>
              <Button
                className='cmn-btn'
                name={
                  planChosen.type === timePeriod.annually &&
                  planChosen.is_freePlan !== freePLan
                    ? 'Make Payment'
                    : 'Confirm Plan'
                }
                onMouseLeave={(e) => e.currentTarget.blur()}
                onClick={handleMakePayment}
              />
              {console.log('planSubscribed', planSubscribed)}
              <Button
                className={
                  planSubscribed
                    ? 'cmn-btn back-btn disabled-btn'
                    : 'cmn-btn back-btn'
                }
                name='Change Plan'
                onMouseLeave={(e) => e.currentTarget.blur()}
                onClick={() => {
                  history.push('/subscriptionplan');
                  localStorage.setItem('backFromCard', 'true');
                }}
                disabled={planSubscribed}
              />
            </div>
          </div>
        </div>
      </CreditCardInfoStyleWrapper>
    </>
  );
}
