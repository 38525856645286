import { calendarPayload } from "../../redux/calendar/types";
import { ServiceAuthInstance } from "./index";

export const postCalendarReq = (payload: calendarPayload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/calender-view",
    data: {
      ...payload,
    },
  });
};

export const postCalendarPopupReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/calender-view/pop-up",
    data: {
      ...payload,
    },
  });
};