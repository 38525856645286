import { periodTypes } from "./periodTypes";
import { PeriodState, PeriodActions } from "./types";
import moment from "moment";

const initState: PeriodState = {
  allPeriods: [],
  periods: [],
  periodError: "",
  currentPeriod: null,
  currentPeriodDate: {
    start_date: "",
    end_date: "",
  },
};

interface PeriodType {
  period: Number;
  month: Number;
  start_date: String;
  end_date: String;
  current: Boolean;
}

const periodDataModifier = (data) => {
  const periodData: PeriodType[] = [];
  if (data && data.length > 0) {
    data.forEach((item) => {
      const obj = {
        period: item.period,
        month: item.month,
        start_date: item.start_date,
        end_date: item.end_date,
        current: item.current,
      };
      periodData.push(obj);
    });
  }
  return periodData;
};

const currentPeriodDateModifier = (data) => {
  let currentPeriodDates: Record<string, any> = {};
  if (data && data.length > 0) {
    let index = data.findIndex((obj) => obj.current === true);
    currentPeriodDates = {
      start_date: moment.utc(data[index].start_date).format("YYYY-MM-DD"),
      end_date: moment.utc(data[index].end_date).format("YYYY-MM-DD"),
    };
  }
  return currentPeriodDates;
};

export default function periodReducer(
  state = initState,
  action: PeriodActions
) {
  switch (action.type) {
    case periodTypes.POST_PERIOD:
      return {
        ...state,
      };
    case periodTypes.POST_PERIOD_SUCCESS:
      const periodData = periodDataModifier(action.payload);
      return {
        ...state,
        periods: periodData,
      };
    case periodTypes.POST_PERIOD_FAILURE:
      return {
        ...state,
      };

    case periodTypes.GET_ALL_PERIODS:
      return {
        ...state,
      };

    case periodTypes.GET_ALL_PERIODS_SUCCESS:
      const allPeriodData = periodDataModifier(action.payload);
      // const currentPeriod = recentPeriodModifier(action.payload);
      const currentPeriodDates = currentPeriodDateModifier(action.payload);
      return {
        ...state,
        allPeriods: allPeriodData,
        // currentPeriod: currentPeriod,
        currentPeriodDate: currentPeriodDates,
      };

    case periodTypes.GET_ALL_PERIODS_FAILURE:
      return {
        ...state,
      };

    case periodTypes.CHANGE_PERIOD:
      return {
        ...state,
        currentPeriod: action.payload.currentPeriod,
        currentPeriodDate: {
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
      //@ts-ignore
    case periodTypes.CLEAR_PERIOD:
      return {
        ...state,
        periods: [],
      };
    default:
      return state;
  }
}
