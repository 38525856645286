import { ExecutiveSignupStep2Types } from "./ExecutiveSignupStep2Types";
import {
  postCreateCompanyPayload,
  getCompanySlugAvailablePayload,
  getCompanyPortalStatusPayload,
} from "./types";

export const createCompany = (payload: postCreateCompanyPayload) => ({
  type: ExecutiveSignupStep2Types.POST_CREATE_COMPANY,
  payload,
});

export const getCompanySlugAvailable = (
  payload: getCompanySlugAvailablePayload
) => ({
  type: ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY,
  payload,
});

export const postExecutiveSignupStep2 = (payload: any) => ({
  type: ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2,
  payload,
});

export const getCompanyIndrustry = () => ({
  type: ExecutiveSignupStep2Types.GET_COMPANY_INDRUSTRY,
});

export const getCompanyPortalStatus = (
  payload: getCompanyPortalStatusPayload
) => ({
  type: ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS,
  payload,
});

export const removeAlert = () => ({
  type: ExecutiveSignupStep2Types.REMOVE_ALERT,
});
