export enum globalViewTypes {
  GET_COMPLETE_GLOBAL_VIEW_DATA = 'GET_COMPLETE_GLOBAL_VIEW_DATA',
  GET_COMPLETE_GLOBAL_VIEW_SUCCESS = 'GET_COMPLETE_GLOBAL_VIEW_SUCCESS',
  GET_COMPLETE_GLOBAL_VIEW_FAILED = 'GET_COMPLETE_GLOBAL_VIEW_FAILED',
  GET_GLOBAL_VIEW_ORPHAN_OBJ_DATA = 'GET_GLOBAL_VIEW_ORPHAN_OBJ_DATA',
  GET_GLOBAL_VIEW_ORPHAN_OBJ_SUCCESS = 'GET_GLOBAL_VIEW_ORPHAN_OBJ_SUCCESS',
  GET_GLOBAL_VIEW_ORPHAN_OBJ_FAILED = 'GET_GLOBAL_VIEW_ORPHAN_OBJ_FAILED',
  GET_GLOBAL_VIEW_ORGANIZATION_DATA = 'GET_GLOBAL_VIEW_ORGANIZATION_DATA',
  GET_GLOBAL_VIEW_ORGANIZATION_SUCCESS = 'GET_GLOBAL_VIEW_ORGANIZATION_SUCCESS',
  GET_GLOBAL_VIEW_ORGANIZATION_FAILED = 'GET_GLOBAL_VIEW_ORGANIZATION_FAILED',
  POST_GLOBAL_VIEW = 'POST_GLOBAL_VIEW',
  POST_GLOBAL_VIEW_SUCCESS = 'POST_GLOBAL_VIEW_SUCCESS',
  POST_GLOBAL_VIEW_FAILED = 'POST_GLOBAL_VIEW_FAILED',
  POST_UNIQUE_ID = 'POST_UNIQUE_ID',
  POST_UNIQUE_ID_SUCCESS = 'POST_UNIQUE_ID_SUCCESS',
  POST_UNIQUE_ID_FAILED = 'POST_UNIQUE_ID_FAILED',
  GET_CHILD_NODE = 'GET_CHILD_NODE',
  GET_CHILD_NODE_SUCCESS = 'GET_CHILD_NODE_SUCCESS',
  GET_CHILD_NODE_FAILED = 'GET_CHILD_NODE_FAILED',
  SEARCH_GLOBAL_VIEW = 'SERACH_GLOBAL_VIEW',
  SERACH_GLOBAL_VIEW_SUCCESS = 'SERACH_GLOBAL_VIEW_SUCCESS',
  SEARCH_GLOBAL_VIEW_FAILED = 'SEARCH_GLOBAL_VIEW_FAILED',
  POST_VMSB_STATUS = 'POST_VMSB_STATUS',
  POST_VMSB_STATUS_SUCCESS = 'POST_VMSB_STATUS_SUCCESS',
  POST_VMSB_STATUS_FAILED = 'POST_VMSB_STATUS_FAILED',
  RESET_VMSB_STATUS = 'RESET_VMSB_STATUS',
}
