import { ServiceAuthInstance } from '.';

export const tooltipsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/tooltip/tooltip',
    data: { ...payload }
  });
};

export const orgDefaultConfigReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/tools_configuration/org_default_config_view'
  });
};
