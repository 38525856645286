type Variable = {
  title_color: string;
  redirect_color: string;
  btn_bg_color: string;
  purple: string;
  lightBlue: string;
  darkGray: string;
  btnHoverBg: string;
  focusColor: string;
};

export const variables: Variable = {
  title_color: "#32386A",
  redirect_color: "#22C4F2",
  btn_bg_color: "#035592",
  purple: "#32386A",
  lightBlue: "#22C4F2",
  darkGray: "#2D2C2C",
  btnHoverBg: "#32386a",
  focusColor: '#b7b9bb'
};
