export enum dashboardTypes {
  GET_OKR_GRAPH_DATA = 'GET_OKR_GRAPH_DATA',
  GET_OKR_GRAPH_DATA_SUCCESS = 'GET_OKR_GRAPH_DATA_SUCCESS',
  GET_OKR_GRAPH_DATA_FAILED = 'GET_OKR_GRAPH_DATA_FAILED',

  POST_HEATMAP_GRAPH_DATA = 'POST_HEATMAP_GRAPH_DATA',
  POST_HEATMAP_GRAPH_DATA_SUCCESS = 'POST_HEATMAP_GRAPH_DATA_SUCCESS',
  POST_HEATMAP_GRAPH_DATA_FAILED = 'POST_HEATMAP_GRAPH_DATA_FAILED',

  GET_CALCULATION_STATUS = 'GET_CALCULATION_STATUS',
  GET_CALCULATION_STATUS_SUCCESS = 'GET_CALCULATION_STATUS_SUCCESS',
  GET_CALCULATION_STATUS_FAILED = 'GET_CALCULATION_STATUS_FAILED',

  GET_TOP_BENEFITS = 'GET_TOP_BENEFITS',
  GET_TOP_BENEFITS_SUCCESS = 'GET_TOP_BENEFITS_SUCCESS',
  GET_TOP_BENEFITS_FAILED = 'GET_TOP_BENEFITS_FAILED',

  GET_TOP_OKRS = 'GET_TOP_OKRS',
  GET_TOP_OKRS_SUCCESS = 'GET_TOP_OKRS_SUCCESS',
  GET_TOP_OKRS_FAILED = 'GET_TOP_OKRS_FAILED',

  GET_EMPLOYEE_HELPFULLNESS = 'GET_EMPLOYEE_HELPFULLNESS',
  GET_EMPLOYEE_HELPFULLNESS_SUCCESS = 'GET_EMPLOYEE_HELPFULLNESS_SUCCESS',
  GET_EMPLOYEE_HELPFULLNESS_FAILED = 'GET_EMPLOYEE_HELPFULLNESS_FAILED',

  GET_DEPARTMENT_ACHIEVEMENT = 'GET_DEPARTMENT_ACHIEVEMENT',
  GET_DEPARTMENT_ACHIEVEMENT_SUCCESS = 'GET_DEPARTMENT_ACHIEVEMENT_SUCCESS',
  GET_DEPARTMENT_ACHIEVEMENT_FAILED = 'GET_DEPARTMENT_ACHIEVEMENT_FAILED',

  POST_DASHBOARD_STAR = 'POST_DASHBOARD_STAR',
  POST_DASHBOARD_STAR_SUCCESS = 'POST_DASHBOARD_STAR_SUCCESS',
  POST_DASHBOARD_STAR_FAILED = 'POST_DASHBOARD_STAR_FAILED',
  POST_DASHBOARD_BENEFIT_STAR = 'POST_DASHBOARD_BENEFIT_STAR',
  POST_DASHBOARD_BENEFIT_STAR_SUCCESS = 'POST_DASHBOARD_BENEFIT_STAR_SUCCESS',
  POST_DASHBOARD_BENEFIT_STAR_FAILED = 'POST_DASHBOARD_BENEFIT_STAR_FAILED',

  SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER',
  RESET_STAR_SUCCESS = 'RESET_STAR_SUCCESS',
  SET_SCORCARD_CATEGORY = 'SET_SCORCARD_CATEGORY',

  GET_PERIODS_LIST='GET_PERIODS_LIST',
  GET_PERIODS_LIST_SUCCESS='GET_PERIODS_LIST_SUCCESS',
  GET_PERIODS_LIST_FAILED='GET_PERIODS_LIST_FAILED'
}
