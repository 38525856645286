import React, { Suspense, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const routes = [
  {
    path: 'home',
    component: lazy(() => import('../Dashboard/Dashboard')),
    exact: true
  },
  {
    path: 'user-roles',
    component: lazy(() => import('../AdminRolesPermissions/AdminRolesPermissions')),
    exact: true
  },
  {
    path: 'workspaceUsers',
    component: lazy(() => import('../WorkspaceUserList/WorkspaceUserList')),
    exact: true
  },
  {
    path: 'workspaceUsers/:id',
    component: lazy(() => import('../UserProfile/UserProfile')),
    exact: true
  },
  {
    path: 'workspace',
    component: lazy(() => import('../WorkspaceList/WorkspaceList')),
    exact: true
  },
  {
    path: 'workspace/:id',
    component: lazy(() => import('../WorkspaceList/WorkspaceDetails')),
    exact: true
  },
  {
    path: 'promocodes',
    component: lazy(() => import('../PromoCode/PromoCodeList')),
    exact: true
  },
  {
    path: 'promocodes/:id',
    component: lazy(() => import('../PromoCode/PromocodeDetail')),
    exact: true
  },
  {
    path: 'subscription-plans',
    component: lazy(() => import('../Subscription/SubscriptionPlans')),
    exact: true
  },
  {
    path: 'editPlan/:id',
    component: lazy(() => import('../Subscription/EditSubscriptionPlan')),
    exact: true
  },
  {
    path: 'subscription-history',
    component: lazy(() => import('../Subscription/SubscriptionHistory')),
    exact: true
  },
  {
    path: 'configuration',
    component: lazy(() => import('../Configuration/Configuration')),
    exact: true
  },
  {
    path: 'tooltip',
    component: lazy(() => import('../Tooltip/Tooltip')),
    exact: true
  }
];

const AdminRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {routes.map((route, idx) => (
          <Route exact={route.exact} key={idx} path={`${url}/${route.path}`}>
            <route.component />
          </Route>
        ))}
      </Switch>
    </Suspense>
  );
};

export default AdminRoutes;
