import { ServiceAuthInstance } from "./index";

export const postinviteusers_linkReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/invite_users_link",
    data: {
      ...payload,
    },
  });
};
export const postaddmanually_userReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/add_manually_user",
    data: {
      ...payload,
    },
  });
};

export const getAllUserReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/tools_configuration/role_permission_allusers`,
    params: payload,
  });
};
//patchDeleteUserReq
export const patchDeleteUserReq = (payload) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/tools_configuration/delete_okr_user/${payload?.id}`,
  });
};

//edituserReq
export const edituserReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: `/tools_configuration/edit_role`,
    data: payload,
  });
};
