import { useEffect, useState } from 'react';
import { Title, ModalPera } from '../../assets/styles/globalStyle';
import SubscriptionPlanStyleWrapper from './SubscriptionPlan.styles';
import logo from '../../assets/images/TA-Horizontal_Logo.png';
import { Switch, Row, Col, Card, Form, InputNumber, Tooltip } from 'antd';
import Button from '../../components/Button/Button';
import LinkButton from '../../components/LinkButton/LinkButton';
import { PUBLIC_ROUTE } from '../../route.constants';
import {
  AppConstant,
  monthInYear,
  licenseInputLabel,
  maxLicense,
  timePeriod,
  tooltipScreens,
  dateFormatList,
} from '../../config/constant';
import history from '../../library/helpers/history';
import { InfoCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/rootReducer';
import { getAllActivePlans } from '../../redux/SubscriptionPlan/actions';
import { plan } from '../../redux/Admin/subscription/types';
import { getExecutiveTooltips } from '../../redux/Admin/Tooltip/action';
import moment from 'moment';
import { GoogleEventObj, googleEventTracker } from '../../config/site.config';

function SubscriptionPlan() {
  const dispatch = useDispatch();
  const [monthSwitch, setMonthSwitch] = useState(true);
  const [amount, setAmount] = useState<any>();
  const [users, setUsers] = useState(1);
  const [ssPlanForm] = Form.useForm();
  const { cardVerified, paymentAddressSave } = useSelector(
    (state: RootState) => state.SubscriptionPlanReducer
  );
  const allActivePlans: plan[] = useSelector(
    (state: any) => state.SubscriptionPlanReducer?.allActivePlans
  );
  const executiveTooltips: any[] = useSelector(
    (state: any) => state.TooltipReducer.executiveTooltips
  );
  const backFromBilling = localStorage.getItem('backFromBilling');
  const defaultLicense = 1;
  //@ts-ignore
  const planChosen = JSON.parse(localStorage.getItem('planChosen'));
  const onChange = (checked: boolean) => {
    const user =
      ssPlanForm.getFieldValue('license') === null
        ? defaultLicense
        : ssPlanForm.getFieldValue('license');
    setMonthSwitch(checked);
    setAmount(checked ? Math.ceil(user / 5) * 5 * monthInYear : user);
    !checked &&
      ssPlanForm.setFields([
        {
          name: 'license',
          errors: [],
        },
      ]);
  };

  useEffect(() => {
    dispatch(getAllActivePlans());
    dispatch(getExecutiveTooltips({ page: tooltipScreens.subscriptionplan }));
    ssPlanForm.setFieldsValue({ license: defaultLicense });
    setAmount(Math.ceil(defaultLicense / 5) * 5 * monthInYear);
    if (
      backFromBilling === 'true' ||
      localStorage.getItem('backFromCard') === 'true'
    ) {
      ssPlanForm.setFieldsValue({ license: planChosen?.no_of_license });
      setAmount(planChosen.totalPayable);
      planChosen.type === timePeriod.annually //eslint-disable-line
        ? (setMonthSwitch(true),
          setAmount(Math.ceil(planChosen.no_of_license / 5) * 5 * monthInYear))
        : (setMonthSwitch(false), setAmount(planChosen.no_of_license));
    }
    window.history.pushState(null, '', window.location.pathname);

    const onBackButtonEvent = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    window.onpopstate = onBackButtonEvent;
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []); //eslint-disable-line

  function handleKeyDown(event) {
    const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
    if (exceptThisSymbols.includes(event.key)) {
      event.preventDefault();
    }
    if (event.target.value > maxLicense) {
      if (event.which !== 8) {
        event.preventDefault();
      }
      ssPlanForm.setFields([
        {
          name: 'license',
          errors: [AppConstant.FormValidation.licenseMaxLimit],
        },
      ]);
    }
  }

  function annualRateCalculate(monthlyRate, annualDiscount) {
    return monthlyRate - (monthlyRate * annualDiscount) / 100;
  }

  const handleSubmit = (planId: string | number | null) => {
    const chosenPlan = allActivePlans.filter((i) => i.id === planId)[0];
    const users = ssPlanForm.getFieldValue('license');
    if (users === undefined || users === null) {
      ssPlanForm.setFields([
        {
          name: 'license',
          errors: [AppConstant.FormValidation.licenseRequired],
        },
      ]);
    } else if (ssPlanForm.getFieldValue('license') > maxLicense) {
      ssPlanForm.setFields([
        {
          name: 'license',
          errors: [AppConstant.FormValidation.licenseMaxLimit],
        },
      ]);
      return Promise.reject(AppConstant.FormValidation.licenseMaxLimit);
    } else {
      ssPlanForm.setFields([
        {
          name: 'license',
          errors: [],
        },
      ]);
      let payload;
      if (chosenPlan.plan_name === 'Free') {
        payload = {
          no_of_license: monthSwitch ? ssPlanForm.getFieldValue('license') : 1,
          is_renew: 1,
          is_freePlan: 1,
          plan_id: planId,
          totalPayable: 0,
          plan_name: chosenPlan.plan_name,
        };
      } else {
        payload = {
          no_of_license: monthSwitch ? ssPlanForm.getFieldValue('license') : 1,
          is_renew: 1,
          type: monthSwitch ? 2 : 1,
          plan_id: planId,
          is_freePlan: 0,
          totalPayable: amount,
          plan_name: chosenPlan.plan_name,
        };
      }
      if (planChosen?.plan_id !== planId) {
        localStorage.getItem('promo_code') &&
          localStorage.removeItem('promo_code');
      }
      if (localStorage.getItem('backFromCard') === 'true') {
        localStorage.setItem('planChosen', JSON.stringify(payload));
        history.push('/creditcardinfo');
      } else {
        localStorage.setItem('planChosen', JSON.stringify(payload));
        localStorage.removeItem('backFromBilling');
        if (localStorage.getItem('annualCardVerified') === 'true') {
          history.push('/creditcardinfo');
        } else {
          history.push('/billinginfo');
        }
      }
    }
    googleEventTracker(GoogleEventObj.button_click.name, {
      category: 'subscription_plan_chose_popup_save_click',
      action: GoogleEventObj.button_click.action,
      label: GoogleEventObj.button_click.getLabel(),
    });
  };

  const checkForErrors = (evt) => {
    if (evt?.errorFields?.length) {
      evt?.errorFields?.forEach((field) => {
        googleEventTracker(GoogleEventObj.form_error.name, {
          category: 'subscription_plan_chose_' + field?.name?.[0] || 'n/a',
          action: GoogleEventObj.form_error.action,
          label: GoogleEventObj.form_error.getLabel(),
          value: field?.errors?.[0],
        });
      });
    }
  };

  useEffect(() => {
    if (!monthSwitch) {
      ssPlanForm.setFieldsValue({ license: defaultLicense });
    }
  }, [monthSwitch, ssPlanForm]);

  return (
    <SubscriptionPlanStyleWrapper className='isoPlanPage'>
      <div className='isoPlanContentWrapper'>
        <div className='isoPlanContent'>
          <div className='isoLogoWrapper'>
            <img src={logo} alt='' />
          </div>
          <Form
            form={ssPlanForm}
            className='ssform'
            onFinishFailed={checkForErrors}
          >
            <Title className='plan-desc'>Purchase Plan</Title>
            <Title className='plan-desc'>
              Please choose the plan that best suits your organization's needs.
              The change will be reflected from next billing cycle.
            </Title>
            {monthSwitch ? (
              <div className='form-tooltip'>
                <Form.Item
                  name='license'
                  label={licenseInputLabel}
                  className='license-input'
                  rules={[
                    {
                      required: true,
                      message: AppConstant.FormValidation.licenseRequired,
                    },
                  ]}
                >
                  <InputNumber
                    type='number'
                    step={0}
                    min={1}
                    className='users-input'
                    onWheel={(e) => e.currentTarget.blur()}
                    onKeyDown={handleKeyDown}
                    onChange={(i) => {
                      i === null
                        ? setAmount(
                            monthSwitch
                              ? monthInYear * Math.ceil(defaultLicense / 5) * 5
                              : 1
                          )
                        : setAmount(
                            monthSwitch ? monthInYear * Math.ceil(i / 5) * 5 : i
                          );
                      setUsers(monthSwitch ? Math.ceil(i / 5) * 5 : i);
                    }}
                  />
                </Form.Item>
                <Tooltip
                  placement='top'
                  title={
                    executiveTooltips.filter(
                      (i) => i.name_of_tooltip === 'annualRate'
                    )[0]?.value
                  }
                >
                  <span className='icon'>
                    <InfoCircleFilled className='tooltip-icon' />
                  </span>
                </Tooltip>
              </div>
            ) : (
              <span className='form-tooltip' style={{ fontWeight: 'bold' }}>
                Licenses will be added as users in team increases.
              </span>
            )}

            <div className='switch-wrapper'>
              <ModalPera className='switch-text'>Monthly</ModalPera>
              <Switch
                defaultChecked
                onChange={onChange}
                checked={monthSwitch}
              />
              <ModalPera className='switch-text'>Annually</ModalPera>
            </div>
            <div className='subscription-table-wrapper'>
              <Row gutter={16}>
                {allActivePlans.map((item) => (
                  <Col span={6}>
                    <Card
                      title={
                        <div>
                          <h1>{item.plan_name}</h1>
                          {item.plan_name === 'Standard' && (
                            <div className='tag'>Popular</div>
                          )}
                          {item.plan_name === 'Free' && (
                            <div className='free-note'>{`* ${
                              item.plan_name === 'Free' &&
                              item.module.trial_period
                            }-day free trial`}</div>
                          )}
                          {item.discontinue_from !== null &&
                            !item.availability_status &&
                            moment(item.discontinue_from) >
                              moment(moment()) && (
                              <span className='free-note'>
                                This plan will be discontinued from{' '}
                                {moment(item.discontinue_from).format(
                                  dateFormatList
                                )}
                                .
                              </span>
                            )}
                        </div>
                      }
                      className={
                        planChosen
                          ? planChosen.plan_id === item.id
                            ? 'selected-plan-card-body'
                            : ''
                          : ''
                      }
                    >
                      <h1 className='price'>
                        {monthSwitch && item.plan_name !== 'Free' && (
                          <span className='strike-text'>
                            <sup>USD$</sup> {Number(item.monthly_rate) * amount}
                          </span>
                        )}
                        <sup>USD $</sup>
                        {monthSwitch
                          ? (
                              annualRateCalculate(
                                item.monthly_rate,
                                item.annual_discount
                              ) * amount
                            ).toFixed(2)
                          : (Number(item.monthly_rate) * 1).toFixed(2)}
                        {item.plan_name !== 'Free' && (
                          <span className='time'>
                            {monthSwitch ? 'per year' : 'per month'}
                          </span>
                        )}
                      </h1>
                      {monthSwitch && (
                        <p>
                          (1-
                          {Math.ceil(
                            (ssPlanForm.getFieldValue('license') ===
                              undefined ||
                            ssPlanForm.getFieldValue('license') === null
                              ? 1
                              : ssPlanForm.getFieldValue('license')) / 5
                          ) * 5}{' '}
                          licenses)
                        </p>
                      )}
                      <p>
                        <sup>USD $</sup>
                        {monthSwitch
                          ? annualRateCalculate(
                              item.monthly_rate,
                              item.annual_discount
                            )
                          : item.monthly_rate}{' '}
                        per license
                      </p>
                      {users > item.module.no_of_users ? (
                        <Tooltip
                          placement='bottom'
                          title={`This plan allows only ${item.module.no_of_users} licenses.`}
                        >
                          <Button
                            className={
                              users > item.module.no_of_users
                                ? 'disabled-cmn-btn cmn-btn'
                                : 'cmn-btn'
                            }
                            onMouseLeave={(e) => e.currentTarget.blur()}
                            onClick={() => handleSubmit(item.id)}
                            name='Choose Plan'
                          />
                        </Tooltip>
                      ) : (
                        <Button
                          className='cmn-btn'
                          onMouseLeave={(e) => e.currentTarget.blur()}
                          onClick={() => handleSubmit(item.id)}
                          name='Choose Plan'
                        />
                      )}
                    </Card>
                  </Col>
                ))}
              </Row>

              <LinkButton
                paraMessage=' For more details about plans please visit '
                redirect={PUBLIC_ROUTE.SUBSCRIPTION_PLAN_DETAILS}
                linktitle=' here'
                location={location.pathname} //eslint-disable-line
              />
            </div>
          </Form>
        </div>
      </div>
      <div className='note'>
        Note: First{' '}
        {
          allActivePlans?.filter((i) => i.plan_name === 'Free')[0]?.module
            ?.trial_period
        }{' '}
        days is free!
      </div>
    </SubscriptionPlanStyleWrapper>
  );
}

export default SubscriptionPlan;
