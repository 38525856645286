import moment from 'moment-timezone';
import { dateFormatList, defaultMetaData, rolesModule, usersStatus } from '../../../config/constant';
import { WorkspaceUserTypes } from './actionTypes';
import { WorkspaceUserState, WorkspaceUserStateAction, dataRoleType, workspaceUsers } from './types';

const initState: WorkspaceUserState = {
  rolePermissionsData: [],
  allWorkspaceUsers: [
    {
      userId: null,
      userName: '',
      email: '',
      workspace: '',
      role: '',
      status: '',
      joinedDate: ''
    }
  ],
  totalUsers: defaultMetaData,
  workspaceUser: {
    status: '',
    profile: '',
    user: {
      ID: '',
      Name: '',
      'Joined Date': '',
      'Last Login Date': '',
      Email: '',
      Workspace: '',
      'Helpfulness Point': '',
      'User Role': '',
      Manager: ''
    },
    vmsb: {
      'Created Vision': '',
      'Created Mission': '',
      'Created Strategy': '',
      'Created Benefit': '',
      'Created Objective': '',
      'Created Key Result': '',
      'Created Task': ''
    }
  },
  errorMessage: '',
  successMessage: '',
  exportableUserData: []
};

const defaultUserDetail = {
  status: '',
  profile: '',
  user: {
    ID: '',
    Name: '',
    'Joined Date': '',
    'Last Login Date': '',
    Email: '',
    Workspace: '',
    'Helpfulness Point': '',
    'User Role': '',
    Manager: ''
  },
  vmsb: {
    'Created Vision': '',
    'Created Mission': '',
    'Created Strategy': '',
    'Created Benefit': '',
    'Created Objective': '',
    'Created Key Result': '',
    'Created Task': ''
  }
};

const allRolesModifier = (data) => {
  const dataRole: dataRoleType[] = [];
  const module = rolesModule;
  data.forEach((role, index) => {
    for (let i = 0; i < role.rolePermissions.length; i++) {
      const element = role.rolePermissions[i];
      const obj = {
        id: role.id,
        key: module[element.module] + `${index + 1}`,
        roles: role.name,
        task: module[element.module],
        add: element.add ? true : false,
        edit: element.edit ? true : false,
        view: true,
        delete: element.delete ? true : false,
        permissionId: element.id
      };
      dataRole.push(obj);
    }
  });
  return dataRole;
};

const usersListModifier = (data, metaData) => {
  const usersData: workspaceUsers[] = [];
  if (metaData?.totalItems > 0) {
    data?.forEach((record) => {
      const user = {
        userId: record.id ? record.id : `Invite${record.ids}`,
        userName: record.first_name ? `${record.first_name} ${record.last_name}` : '-',
        email: record.email,
        workspace: record.organization_master_id
          ? record.organization_master_id?.company_name
          : record?.org_company_name,
        role: record.role,
        status:
          record.status !== undefined
            ? record.status === 0
              ? usersStatus.inactive
              : record.status === 1
              ? usersStatus.active
              : record.status === 3
              ? usersStatus.pending
              : usersStatus.deleted
            : record.inviteStatus === 0
            ? usersStatus.invited
            : record.inviteStatus === 4 || record.inviteStatus === 2
            ? usersStatus.deleted
            : '',
        joinedDate: record.created_at === null ? '-' : moment.utc(record.created_at).format(dateFormatList)
      };
      usersData.push(user);
    });
    return usersData;
  } else {
    return [];
  }
};

const userDataModifier = (data) => {
  const userData = {
    status:
      data.status === 0
        ? usersStatus.inactive
        : data.status === 1
        ? usersStatus.active
        : data.status === 3
        ? usersStatus.pending
        : usersStatus.deleted,
    profile: data?.profile_photo ? data?.profile_photo : data.organization_master_id.profile_photo,
    user: {
      ID: data.id,
      'Last Login Date': data.last_login_date !== null ? moment.utc(data.last_login_date).format(dateFormatList) : '-',
      Name: `${data.first_name} ${data.last_name}`,
      Workspace: data.organization_master_id.company_name,
      'User Role': data.role,
      'Joined Date': moment.utc(data.created_at).format(dateFormatList),
      // Email: data.email,
      'Helpfulness Point': data.helpfulness_rating === null ? '-' : data.helpfulness_rating,
      Manager: data.manager_name === null ? '-' : data.manager_name
    },
    vmsb: {
      'Created Vision': data.visionCount,
      'Created Mission': data.missionCount,
      'Created Strategy': data.strategyCount,
      'Created Benefit': data.benefitCount,
      'Created Objective': data.objectiveCount,
      'Created Key Result': data.keyResultCount,
      'Created Task': data.taskCount
    }
  };
  return userData;
};

export default function WorkspaceUserReducer(state = initState, action: WorkspaceUserStateAction) {
  switch (action.type) {
    case WorkspaceUserTypes.ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolePermissionsData: allRolesModifier(action.rolePermissionsData)
      };
    case WorkspaceUserTypes.UPDATE_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage
      };
    case WorkspaceUserTypes.UPDATE_ROLE_PERMISSIONS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case WorkspaceUserTypes.WORKSPACE_USER_LIST_SUCCESS:
      return {
        ...state,
        allWorkspaceUsers: usersListModifier(action.allWorkspaceUsers, action.totalUsers),
        totalUsers: action.totalUsers
      };
    case WorkspaceUserTypes.EXPORT_WORKSPACE_USER_LIST_SUCCESS:
      return {
        ...state,
        exportableUserData: action.exportableUserData
      };
    case WorkspaceUserTypes.WORKSPACE_USER_DETAIL_SUCCESS:
      return {
        ...state,
        workspaceUser: userDataModifier(action.workspaceUser)
      };
    case WorkspaceUserTypes.WORKSPACE_USER_DETAIL_FAILED:
      return {
        ...state,
        workspaceUser: defaultUserDetail,
        errorMessage: action.errorMessage
      };
    case WorkspaceUserTypes.REMOVE_ALERT:
      return {
        ...state,
        successMessage: '',
        errorMessage: ''
      };
    default:
      return {
        ...state
      };
  }
}
