import { ServiceAuthInstance, ServiceInstance } from "./index";

export const postCommentsReq = (payload) => {
  let typeFilter = {
    Objective: "add_Objective_comments",
    tasks: "add_Task_comments",
    key_result: "add_key_results_comments",
  };
  return ServiceAuthInstance({
    method: "POST",
    url: `/list_view/${typeFilter[payload.types]}`,
    data: {
      ...payload,
    },
  });
};

export const postvmsbCommentsReq = (payload) => {
  let typeFilter = {
    vision: "add_vision_comments",
    mission: "add_mission_comments",
    benefit: "add_benefit_comments",
    strategy: "add_strategy_comments",
  };
  return ServiceAuthInstance({
    method: "POST",
    url: `/${payload.types}/${typeFilter[payload.types]}`,
    data: {
      ...payload,
    },
  });
};

export const getCommentsReq = (payload) => {
  return ServiceAuthInstance({
    method: "get",
    url: `/list_view/${payload.type}/${payload.id}?page=${payload.page}&take=${payload.take}`,
  });
};

export const getvmsbCommentsReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/${payload.commentType}/${payload.type}/${payload.id}?page=${payload.page}&take=${payload.take}`,
  });
};

export const getCommentsNonOKRUserReq = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `/list_view/get_comments?token=${payload.token}`,
    data: {
      ...payload,
    },
  });
};

export const postCommentsNonOKRUserReq = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `/list_view/add_reply_comments?token=${payload.token}`,
    data: payload.email_address.length>0 ? {
      email_address:payload.email_address,
      comments: payload.comments,
    } : {
      comments: payload.comments,
    },
  });
};
