export enum manageUserActions {
  POST_INVITE_USERS_LINK = "POST_INVITE_USERS_LINK",
  POST_INVITE_USERS_LINK_SUCCESS = "POST_INVITE_USERS_LINK_SUCCESS",
  POST_INVITE_USERS_LINK_FAILED = "POST_INVITE_USERS_LINK_FAILED",

  POST_ADD_MANUALLY_USER = "POST_ADD_MANUALLY_USER",
  POST_ADD_MANUALLY_USER_SUCCESS = "POST_ADD_MANUALLY_USER_SUCCESS",
  POST_ADD_MANUALLY_USER_FAILED = "POST_ADD_MANUALLY_USER_FAILED",

  GET_ALL_USER = "GET_ALL_USER",
  GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS",

  PATCH_DELETE_USER = "PATCH_DELETE_USER",
  PATCH_DELETE_USER_SUCCESS = "PATCH_DELETE_USER_SUCCESS",
  PATCH_DELETE_USER_FAILED = "PATCH_DELETE_USER_FAILED",

  EDIT_USER = "EDIT_USER",
  EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS",
  EDIT_USER_FAILED = "EDIT_USER_FAILED",

  CHANGE_PAGES = "CHANGE_PAGES",

  REMOVE_ALERT = "REMOVE_ALERT",
}
