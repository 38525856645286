export enum keyResultTypes {
  POST_KEYRESULT = "POST_KEYRESULT",
  POST_KEYRESULT_SUCCESS = "POST_KEYRESULT_SUCCESS",
  POST_KEYRESULT_FAILED = "POST_KEYRESULT_FAILED",

  GET_KEYRESULT = "GET_KEYRESULT",
  GET_KEYRESULT_SUCCESS = "GET_KEYRESULT_SUCCESS",
  GET_KEYRESULT_FAILED = "GET_KEYRESULT_FAILED",

  PATCH_KEYRESULT = "PATCH_KEYRESULT",
  PATCH_KEYRESULT_SUCCESS = "PATCH_KEYRESULT_SUCCESS",
  PATCH_KEYRESULT_FAILED = "PATCH_KEYRESULT_FAILED",

  GET_OBJ_OF_USER = "GET_OBJ_OF_USER",
  GET_OBJ_OF_USER_SUCCESS = "GET_OBJ_OF_USER_SUCCESS",
  GET_OBJ_OF_USER_FAILED = "GET_OBJ_OF_USER_FAILED",
  REMOVE_ALERT = "REMOVE_ALERT",

  GET_KR_COMMENTS = "GET_KR_COMMENTS",
  GET_KR_COMMENTS_SUCCESS = "GET_KR_COMMENTS_SUCCESS",

  GET_KR_TASK = "GET_KR_TASK",
  GET_KR_TASK_SUCCESS = "GET_KR_TASK_SUCCESS",

  DELETE_FOREGIN_LINKED_KEY_RESULT = "DELETE_FOREGIN_LINKED_KEY_RESULT",
  DELETE_FOREGIN_LINKED_KEY_RESULT_SUCCESS = "DELETE_FOREGIN_LINKED_KEY_RESULT_SUCCESS",
  DELETE_FOREGIN_LINKED_KEY_RESULT_FAILED = "DELETE_FOREGIN_LINKED_KEY_RESULT_FAILED"
}
