import { missionTypes } from "./missionTypes";
import { missionAction, missionState } from "./types";
let initState: missionState = {
  errorMessage: "",
  successMessage: "",
  StrategyData: {},
  MissionData: {},
  VisionInMission: [],
  singleObjective: {
    missionData: [
      {
        description: "",
        startDate: "",
        endDate: "",
        noOfPeriods: "",
        remainderDate: "",
        remainderDescription: "",
      },
    ],
  },
};
export default function authReducer(state = initState, action: missionAction) {
  switch (action.type) {
    case missionTypes.POST_MISSION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case missionTypes.POST_MISSION_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case missionTypes.POST_MISSION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case missionTypes.GET_MISSION:
      return {
        ...state,
      };
    case missionTypes.GET_MISSION_SUCCESS:
      return {
        ...state,
        MissionData: action?.mission,
      };
    case missionTypes.GET_MISSION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case missionTypes.EDIT_MISSION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case missionTypes.EDIT_MISSION_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case missionTypes.EDIT_MISSION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case missionTypes.GET_STRATEGY_IN_MISSION_SUCCESS:
      return {
        ...state,
        StrategyData: action.payload,
      };
    case missionTypes.GET_VISION_IN_MISSION_SUCCESS:
      return {
        ...state,
        VisionInMission: action.payload,
      };
    case missionTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    default:
      return state;
  }
}
