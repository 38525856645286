import React from 'react';
import logo from '../../../assets/images/TA-Horizontal_Logo.png';
import Menu from '../../uielements/menu';
import AdminSidebarOptions from './AdminSidebarOptions';
import ATag from '../../AnchorTag/ATag';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/rootReducer';
import Scrollbars from '../../utility/customScrollBar';
import { Layout } from 'antd';
import { AdminSidebarWrapper } from './AdminSidebar.styles';
import appActions from '../../../redux/app/action';
import Icon from '@ant-design/icons';

const { changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;

export default function AdminSidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  let match = useRouteMatch();

  const { Sider } = Layout;
  const SubMenu = Menu.SubMenu;
  const { height, openKeys, collapsed, openDrawer, view, current } = useSelector((state: RootState) => state.App);
  const submenuColor: {
    color: string;
  } = {
    color: '#035592'
  };
  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  function handleClick(e) {
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
      }, 100);
    }
  }
  const checkRoute = React.useCallback(() => {
    AdminSidebarOptions.map((singleOption) => {
      if (singleOption.key === history.location.pathname.split('superadmin/dashboard/')[1]) {
        if (singleOption.key !== 'subscription') {
          dispatch(changeOpenKeys([singleOption.key]));
        } else {
          dispatch(changeOpenKeys([]));
        }
        dispatch(changeCurrent([singleOption.key]));
      }
      let { children } = singleOption;
      if (children) {
        let subMenuChildExist = children?.some?.(
          (child) => `${child.key}` === history.location.pathname.split('superadmin/dashboard/')[1]
        );
        if (subMenuChildExist) {
          dispatch(changeOpenKeys([singleOption.key]));
          dispatch(changeCurrent([singleOption.key]));
        }
      }
      return null;
    });
  }, [dispatch, history]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let menu = document.querySelector('.isoDashboardMenu');
    menu?.setAttribute('tabIndex', '-1');
    let submenu = document.querySelector('.ant-menu-sub');
    submenu?.setAttribute('tabIndex', '-1');
  }, []);

  React.useEffect(() => {
    checkRoute();
  }, [history.location.pathname, checkRoute]); //eslint-disable-line react-hooks/exhaustive-deps

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find((key) => !(openKeys.indexOf(key) > -1));
    const latestCloseKey = openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    if (nextOpenKeys[0] !== 'subscription') {
      dispatch(changeOpenKeys(nextOpenKeys));
    }
  }
  const getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2']
    };
    return map[key] || [];
  };
  return (
    <AdminSidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={false}
        width={240}
        className='isomorphicSidebar'
        style={{ backgroundColor: '#fff' }}
      >
        <div className='isoLogoWrapper'>
          <img src={logo} alt='' />
        </div>
        {/* <Logo collapsed={false} /> */}
        <Scrollbars style={{ height: height - 114 }} id={undefined} className={undefined}>
          <Menu
            onClick={handleClick}
            theme='light'
            className='isoDashboardMenu'
            mode={mode}
            forceSubMenuRender={true}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
          >
            {AdminSidebarOptions.map((option) =>
              option.children ? (
                <SubMenu
                  // @ts-ignore
                  expandIcon={
                    <i
                      id={option.key}
                      onClick={() => {
                        dispatch(changeOpenKeys([option.key]));
                      }}
                      className={'icon-table-arrow-down'}
                    ></i>
                  }
                  key={option.key}
                  disabled={option.disabled}
                  title={
                    <ATag
                      className={`anchor-tag isoMenuHolder ${option.key}`}
                      id={option.key}
                      style={submenuColor}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(changeOpenKeys([option.key]));
                      }}
                    >
                      <div className='icon-wrapper'>
                        <Icon component={option?.leftIcon} />
                      </div>
                      <span className='nav-text'>{option.label}</span>
                    </ATag>
                  }
                >
                  {option.children.map((child) => {
                    const linkTo = `${match.url}/${child.key}`;
                    return (
                      <Menu.Item
                        key={child.key}
                        className={
                          window.location.pathname === linkTo ? 'active-children-link children-link' : 'children-link'
                        }
                      >
                        <ATag
                          id={option.key}
                          className='anchor-tags'
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(linkTo);
                          }}
                        >
                          <span className='isoMenuHolder'>
                            <div className='icon-wrapper'>
                              <Icon component={child?.leftIcon} />
                            </div>
                            <span className='nav-text'>{child.label}</span>
                          </span>
                        </ATag>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              ) : (
                <Menu.Item key={option.key} disabled={option.disabled}>
                  {option.badge ? (
                    <span className='isoMenuHolder'>
                      <div className='icon-wrapper'>
                        <Icon component={option?.leftIcon} />
                      </div>
                      <span className='nav-text'>
                        {option.label}
                        <div className='coming-badge'>Coming Soon</div>
                      </span>
                    </span>
                  ) : (
                    <Link className='anchor-tag' id='menu-link' to={`/superadmin/dashboard/${option.key}`}>
                      <span className='isoMenuHolder'>
                        <div className='icon-wrapper'>
                          <Icon component={option?.leftIcon} />
                        </div>
                        <span className='nav-text'>{option.label}</span>
                      </span>
                    </Link>
                  )}
                </Menu.Item>
              )
            )}
          </Menu>
        </Scrollbars>
      </Sider>
    </AdminSidebarWrapper>
  );
}
