import { Objectivetypes } from "./actionTypes";
import { ObjectiveAction, objectiveState } from "./types";
let initState: objectiveState = {
  idToken: "",
  loggedInUser: "",
  authFlag: false,
  errorMessage: "",
  singleObjective: {
    objectiveData: [
      {
        obj_id: 0,
        obj_name: "",
        obj_description: "",
        users_profile_photo: "",
        users_first_name: "",
        users_last_name: "",
        obj_created_by: 0,
        obj_on_hold: false,
        obj_star: false,
        obj_periods: [],
      },
    ],
    i_linked_to: [{ id: "", name: "" }],
    keyResults: [
      {
        id: "",
        name: "",
        native_status: 0,
      },
    ],
  },
  objComments: {
    pagedItems: [],
    metaData: {
      itemsPerPage: "",
      totalItems: 0,
      currentPage: "",
      totalPages: 0,
    },
  },
  objTaskList: {
    pagedItems: [],
    metaData: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
    },
  },
  foreignOBjKr: [],
  foreignBenefit: [],
  successMessage: "",
};
export default function authReducer(
  state = initState,
  action: ObjectiveAction
) {
  switch (action.type) {
    case Objectivetypes.POST_OBJECTIVE:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case Objectivetypes.POST_OBJECTIVE_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case Objectivetypes.POST_OBJECTIVE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case Objectivetypes.GET_OBJECTIVE_SUCCESS:
      return {
        ...state,
        singleObjective: action.singleObjective,
      };
    case Objectivetypes.GET_OBJECTIVE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case Objectivetypes.PATCH_OBJECTIVE:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case Objectivetypes.PATCH_OBJECTIVE_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case Objectivetypes.PATCH_OBJECTIVE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case Objectivetypes.DELETE_FOREGIN_LINKED_OKR_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
        errorMessage: "",
      };
    case Objectivetypes.DELETE_FOREGIN_LINKED_OKR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successMessage: "",
      };
    case Objectivetypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case Objectivetypes.GET_OBJ_COMMENTS_SUCCESS:
      return {
        ...state,
        objComments: action.objComments,
      };
    case Objectivetypes.POST_OBJ_COMMENTS_SUCCESS:
      return {
        ...state,
      };
    case Objectivetypes.POST_OBJ_COMMENTS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case Objectivetypes.GET_OBJ_TASK_SUCCESS:
      return {
        ...state,
        objTaskList: action.objTaskList,
      };
    case Objectivetypes.GET_OKR_LIST_SUCCESS:
      return {
        ...state,
        foreignOBjKr: action.foreignOBjKr,
      };
    case Objectivetypes.GET_BENEFIT_LIST_SUCCESS:
      return {
        ...state,
        foreignBenefit: action.foreignBenefit,
      };
      
    default:
      return state;
  }
}
