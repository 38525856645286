import { ServiceAuthInstance } from "./index";

export const getAllPeriodReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/list_view/all_period_list",
  });
};

export const postPeriodReq = (payload) => {
  return payload
    ? ServiceAuthInstance({
        method: "POST",
        url: "/list_view/obj_period_list",
        data: { ...payload?.payload },
      })
    : ServiceAuthInstance({
        method: "POST",
        url: "/list_view/obj_period_list",
      });
};
