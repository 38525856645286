import { dashboardTypes } from './dashboardTypes';
import { dahsboardState, dashboardAction } from './types';
import moment from 'moment';
import { MonthsList } from '../../config/constant';

let initState: dahsboardState = {
  okrGraphData: [],
  filter: {
    period: 1,
    type: 3,
    scorecard_category: [],
    department: [],
  },
  calculationStatus: [],
  topBenefits: {
    pagedItems: [],
    objective_type: '',
    metaData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  totalTopBenefit: [],
  totalTopOkrs: [],
  totalDepartments: [],
  totalEmpHelpfullness: [],
  topOKRs: {
    pagedItems: [],
    objective_type: '',
    metaData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  scorecard_category: [],
  employeeHelpfullness: {
    pagedItems: [],
    objective_type: '',
    metaData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  departmentAchievement: {
    pagedItems: [],
    objective_type: '',
    metaData: {
      currentPage: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  errorMessage: '',
  departmentLoading: false,
  employeesLoading: false,
  okrLoading: false,
  benefitLoading: false,
  okrGraphLoading: false,
  calculationGraphLoading: false,
  starOkrSuccess: false,
  starBenefitSuccess: false,
  heatmapData: [],
  heatMapFileData: [],
  heatmapLoading: false,
  periods: [],
};

const okrGraphDataModifier = (action, state) => {
  let graphData = action?.payload?.response;
  let grData: any = [];
  /**
   * if period type is not two years or previous year then
   * we are looping through 0-12 for jan-dec month and if there is data from BE,
   * then we are placing that data otherwise we are providing value 0
   * that is in function formattedgraphData
   * we'll have to check for period defination(month) as we'll be only getting start date and end date form BE.
   */

  if (state.filter.period !== 5 && state.filter.period !== 4) {
    // eslint-disable-next-line
    graphData?.map((item) => {
      let month = moment(item?.start_date).month() + 1;
      let end_month = moment(item?.end_date).month();
      let betweenMonth = month + 1 === 13 ? end_month - 1 : month + 1;
      let start_year = moment(item?.start_date).year();
      let end_year = moment(item?.end_date).year();
      let betweenYear = month + 1 === 13 ? end_year : start_year;

      if (item.month === 1) {
        grData[month - 1] = {
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[month] + '-' + start_year,
        };
      } else if (item.month === 2) {
        grData[month - 1] = {
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[month] + '-' + start_year,
        };
        grData[end_month - 1] = {
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[end_month] + '-' + end_year,
        };
      } else if (item.month === 3) {
        grData[month - 1] = {
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[month] + '-' + start_year,
        };
        grData[betweenMonth - 1] = {
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[betweenMonth] + '-' + betweenYear,
        };
        grData[end_month - 1] = {
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[end_month] + '-' + end_year,
        };
      }
    });
    let start_year = moment(graphData[0]?.start_date).year();
    let formattedgraphData = [...Array(12).keys()].map((month) => {
      if (grData[month]) {
        return grData[month];
      } else {
        return { x: MonthsList[month + 1] + '-' + start_year, y: 0.0 };
      }
    });

    return [
      {
        id: 'graphdata',
        data: formattedgraphData,
      },
    ];
  } else {
    /**
     * if period is two years or previous year then we are looping through the data from BE
     * and setting the data according to period defination
     */
    // eslint-disable-next-line
    graphData?.map((item, index) => {
      let month = moment(item?.start_date).month() + 1;
      const end_month =
        moment(item?.end_date).month() === 0
          ? 12
          : moment(item?.end_date).month();
      let start_year = moment(item?.start_date).year();
      const end_year =
        moment(item?.end_date).month() === 0
          ? start_year
          : moment(item?.end_date).year();
      let betweenMonth = month + 1 === 13 ? end_month - 1 : month + 1;
      let betweenYear = month + 1 === 13 ? end_year : start_year;
      //item.month is period defination
      if (item.month === 1) {
        grData.push({
          y: item?.integrated_status
            ? parseFloat(item?.integrated_status).toFixed(2)
            : 0,
          x: MonthsList[month] + '-' + start_year,
        });
      } else if (item.month === 2) {
        grData.push(
          {
            y: item?.integrated_status
              ? parseFloat(item?.integrated_status).toFixed(2)
              : 0,
            x: MonthsList[month] + '-' + start_year,
          },
          {
            y: item?.integrated_status
              ? parseFloat(item?.integrated_status).toFixed(2)
              : 0,
            x: MonthsList[end_month] + '-' + end_year,
          }
        );
      } else if (item.month === 3) {
        grData.push(
          {
            y: item?.integrated_status
              ? parseFloat(item?.integrated_status).toFixed(2)
              : 0,
            x: MonthsList[month] + '-' + start_year,
          },
          {
            y: item?.integrated_status
              ? parseFloat(item?.integrated_status).toFixed(2)
              : 0,
            x: MonthsList[betweenMonth] + '-' + betweenYear,
          },
          {
            y: item?.integrated_status
              ? parseFloat(item?.integrated_status).toFixed(2)
              : 0,
            x: MonthsList[end_month] + '-' + end_year,
          }
        );
      }
    });
    return [{ id: 'graphdata', data: grData }];
  }
};

const dataModifier = (action, state, type) => {
  /**
   * this modifier is for dashboard infinite tables.
   */
  if (action?.payload?.metaData?.currentPage !== 1) {
    let concatedPagedItems = state[type].pagedItems.concat(
      action.payload.pagedItems
    );
    return {
      pagedItems: [...concatedPagedItems],
      objective_type: action.payload.objective_type,
      metaData: action.payload.metaData,
    };
  } else
    return {
      pagedItems: [...action?.payload?.pagedItems],
      objective_type: action.payload.objective_type,
      metaData: action.payload.metaData,
    };
};

const getCellColor = (value) => {
  if (parseFloat(value) === 100) {
    return '#035588';
  } else if (
    value === 0 ||
    (parseFloat(value) >= 0 && parseFloat(value) <= 10)
  ) {
    return '#D5DBDF';
  } else if (parseFloat(value) > 10 && parseFloat(value) <= 20) {
    return '#D9DDE0';
  } else if (parseFloat(value) > 20 && parseFloat(value) <= 30) {
    return '#CFD7DD';
  } else if (parseFloat(value) > 30 && parseFloat(value) <= 40) {
    return '#B8C8D2';
  } else if (parseFloat(value) > 40 && parseFloat(value) <= 50) {
    return '#A2BACA';
  } else if (parseFloat(value) > 50 && parseFloat(value) <= 60) {
    return '#8AABBF';
  } else if (parseFloat(value) > 60 && parseFloat(value) <= 70) {
    return '#739CB6';
  } else if (parseFloat(value) > 70 && parseFloat(value) <= 80) {
    return '#5E8FAE';
  } else if (parseFloat(value) > 80 && parseFloat(value) <= 90) {
    return '#4780A4';
  } else if (parseFloat(value) > 90 && parseFloat(value) <= 100) {
    return '#30729B';
  }
};

const heatMapDataModifier = (data, state) => {
  let updatedData;
  let scoreCardData: any = [];
  let scorecardCategories;

  /**
   * if portal was not created at that time we'll get a blank array for scorecard data
   * so this below function will provide all the scorecard categories selected in dashboard filter.
   * (in case of user wants to see previous year data but in some months it may be bot available in that case
   * we'll have to show value 0.)
   */

  if (state.filter.scorecard_category) {
    scorecardCategories = state.filter.scorecard_category.map((category) => {
      let cat = state.scorecard_category.find((c) => c.id === category);
      return cat.name;
    });
  }

  /**
   * the updated data will format scorecard data with their respective period.
   */
  updatedData = data
    .map((d, i) => {
      if (scorecardCategories?.length > 0) {
        return scorecardCategories.map((m) => {
          let sccategory = d.scorecard.find((category) => category.name === m);
          return {
            name: m,
            value: sccategory ? Math.round(sccategory.value) : 0,
            start_date: d.start_date,
            end_date: d.end_date,
            month: d.month,
          };
        });
      } else return { ...d };
    })
    .filter((d) => d?.length > 0)
    .flat();
  // eslint-disable-next-line array-callback-return
  /**
   * if period type is not two years or previous year then
   * we are looping through 0-12 for jan-dec month and if there is data from BE,
   * then we are placing that data otherwise we are providing value 0
   * that is in function formattedgraphData
   * we'll have to check for period defination(month) as we'll be only getting start date and end date form BE.
   */
  if (state.filter.period !== 5 && state.filter.period !== 4) {
    updatedData?.forEach((d) => {
      let month = moment(d?.start_date).month() + 1;
      let end_month = moment(d?.end_date).month();
      let betweenMonth = month + 1 === 13 ? end_month - 1 : month + 1;
      let start_year = moment(d?.start_date).year();
      let end_year = moment(d?.end_date).year();
      let betweenYear = month + 1 === 13 ? end_year : start_year;
      let newObj = {
        name: d.name,
      };
      /**
       * here we are checking if we have already added that scorecard category in that returning array or not.
       */
      if (!scoreCardData.map((m) => m.name).includes(d.name)) {
        for (let i = 1; i <= 12; i++) {
          const newName = MonthsList[i] + '-' + start_year;
          const endNewName = MonthsList[i] + '-' + end_year;
          const betnewName = MonthsList[i] + '-' + betweenYear;
          if (d.month === 1) {
            if (i === month) {
              newObj[newName] = parseFloat(d.value);
              newObj[newName + 'Color'] = getCellColor(d.value);
            } else {
              newObj[newName] = 0;
              newObj[newName + 'Color'] = '#D5DBDF';
            }
          } else if (d.month === 2) {
            if (i === month) {
              newObj[newName] = parseFloat(d.value);
              newObj[newName + 'Color'] = getCellColor(d.value);
            } else if (i === end_month) {
              newObj[endNewName] = parseFloat(d.value);
              newObj[endNewName + 'Color'] = getCellColor(d.value);
            } else {
              newObj[newName] = 0;
              newObj[newName + 'Color'] = '#D5DBDF';
            }
          } else if (d.month === 3) {
            if (i === month) {
              newObj[newName] = parseFloat(d.value);
              newObj[newName + 'Color'] = getCellColor(d.value);
            } else if (i === betweenMonth) {
              newObj[betnewName] = parseFloat(d.value);
              newObj[betnewName + 'Color'] = getCellColor(d.value);
            } else if (i === end_month) {
              newObj[endNewName] = parseFloat(d.value);
              newObj[endNewName + 'Color'] = getCellColor(d.value);
            } else {
              newObj[newName] = 0;
              newObj[newName + 'Color'] = '#D5DBDF';
            }
          }
        }
        scoreCardData = [
          ...scoreCardData,
          {
            ...newObj,
          },
        ];
      } else {
        /**
         * otherwise we'll be updating the existing data in returning array.
         */
        const objIndex = scoreCardData.findIndex((m) => m.name === d.name);
        const obj = scoreCardData[objIndex];
        for (let i = 1; i <= 12; i++) {
          const newName = MonthsList[i] + '-' + start_year;
          const endNewName = MonthsList[i] + '-' + end_year;
          const betnewName = MonthsList[i] + '-' + betweenYear;
          //d.month is period defination.
          if (d.month === 1) {
            //for the month we are setting data at that index.
            if (i === month) {
              obj[newName] = parseFloat(d.value);
              obj[newName + 'Color'] = getCellColor(d.value);
            } else {
              obj[newName] = 0;
              obj[newName + 'Color'] = '#D5DBDF';
            }
          } else if (d.month === 2) {
            if (i === month) {
              obj[newName] = parseFloat(d.value);
              obj[newName + 'Color'] = getCellColor(d.value);
            } else if (i === end_month) {
              obj[endNewName] = parseFloat(d.value);
              obj[endNewName + 'Color'] = getCellColor(d.value);
            } else {
              obj[newName] = 0;
              obj[newName + 'Color'] = '#D5DBDF';
            }
          } else if (d.month === 3) {
            /*
             when period defination is of 3 months
             first month will be the start month of the period
             betweenMonth will be middle of first and last month
             last month will be the end month of the period
            */
            if (i === month) {
              obj[newName] = parseFloat(d.value);
              obj[newName + 'Color'] = getCellColor(d.value);
            } else if (i === betweenMonth) {
              obj[betnewName] = parseFloat(d.value);
              obj[betnewName + 'Color'] = getCellColor(d.value);
            } else if (i === end_month) {
              obj[endNewName] = parseFloat(d.value);
              obj[endNewName + 'Color'] = getCellColor(d.value);
            }
            //need to provide color for bnk scorecard otherwise it'll show black color.
            else {
              obj[newName] = 0;
              obj[newName + 'Color'] = '#D5DBDF';
            }
          }
        }
        scoreCardData[objIndex] = {
          ...obj,
        };
      }
    });
  } else {
    /**
     * if period is two years or previous year then we are looping through the data from BE
     * and setting the data according to period defination
     */
    updatedData?.forEach((d) => {
      let month = moment(d?.start_date).month() + 1;
      const end_month =
        moment(d?.end_date).month() === 0 ? 12 : moment(d?.end_date).month();
      let start_year = moment(d?.start_date).year();
      const end_year =
        moment(d?.end_date).month() === 0
          ? start_year
          : moment(d?.end_date).year();
      let betweenMonth = month + 1 === 13 ? end_month - 1 : month + 1;
      let betweenYear = month + 1 === 13 ? end_year : start_year;

      let newObj = {
        name: d.name,
      };
      if (!scoreCardData.map((m) => m.name).includes(d.name)) {
        if (d.month === 1) {
          let newName = MonthsList[month] + '-' + start_year;
          newObj[newName] = parseFloat(d.value);
          newObj[newName + 'Color'] = getCellColor(d.value);
        } else if (d.month === 2) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          newObj[newName] = parseFloat(d.value);
          newObj[newName + 'Color'] = getCellColor(d.value);
          newObj[endName] = parseFloat(d.value);
          newObj[endName + 'Color'] = getCellColor(d.value);
        } else if (d.month === 3) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          let betName = MonthsList[betweenMonth] + '-' + betweenYear;
          newObj[newName] = parseFloat(d.value);
          newObj[newName + 'Color'] = getCellColor(d.value);
          newObj[betName] = parseFloat(d.value);
          newObj[betName + 'Color'] = getCellColor(d.value);
          newObj[endName] = parseFloat(d.value);
          newObj[endName + 'Color'] = getCellColor(d.value);
        }

        scoreCardData = [
          ...scoreCardData,
          {
            ...newObj,
          },
        ];
      } else {
        const objIndex = scoreCardData.findIndex((m) => m.name === d.name);
        const obj = scoreCardData[objIndex];
        if (d.month === 1) {
          let newName = MonthsList[month] + '-' + start_year;
          obj[newName] = parseFloat(d.value);
          obj[newName + 'Color'] = getCellColor(d.value);
        } else if (d.month === 2) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          obj[newName] = parseFloat(d.value);
          obj[newName + 'Color'] = getCellColor(d.value);
          obj[endName] = parseFloat(d.value);
          obj[endName + 'Color'] = getCellColor(d.value);
        } else if (d.month === 3) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          let betName = MonthsList[betweenMonth] + '-' + betweenYear;
          obj[newName] = parseFloat(d.value);
          obj[newName + 'Color'] = getCellColor(d.value);
          obj[betName] = parseFloat(d.value);
          obj[betName + 'Color'] = getCellColor(d.value);
          obj[endName] = parseFloat(d.value);
          obj[endName + 'Color'] = getCellColor(d.value);
        }
        scoreCardData[objIndex] = {
          ...obj,
        };
      }
    });
  }
  return scoreCardData;
};

const heatMapFileDataModifier = (data, state) => {
  /*******
   * 
this is same function as heatMapDataModifier but  for excel or CSV files data   */
  let updatedData;
  let scoreCardData: any = [];
  let scorecardCategories;

  if (state.filter.scorecard_category) {
    scorecardCategories = state.filter.scorecard_category.map((category) => {
      let cat = state.scorecard_category.find((c) => c.id === category);
      return cat.name;
    });
  }

  updatedData = data
    .map((d, i) => {
      if (scorecardCategories?.length > 0) {
        return scorecardCategories.map((m) => {
          let sccategory = d.scorecard.find((category) => category.name === m);
          return {
            name: m,
            value: sccategory ? Math.round(sccategory.value) : 0,
            start_date: d.start_date,
            end_date: d.end_date,
            month: d.month,
          };
        });
      } else return { ...d };
    })
    .filter((d) => d?.length > 0)
    .flat();
  // eslint-disable-next-line array-callback-return
  if (state.filter.period !== 5 && state.filter.period !== 4) {
    updatedData?.forEach((d) => {
      let month = moment(d?.start_date).month() + 1;
      let end_month = moment(d?.end_date).month();
      let betweenMonth = month + 1 === 13 ? end_month - 1 : month + 1;
      let start_year = moment(d?.start_date).year();
      let end_year = moment(d?.end_date).year();
      let betweenYear = month + 1 === 13 ? end_year : start_year;
      let newObj = {
        name: d.name,
      };
      if (!scoreCardData.map((m) => m.name).includes(d.name)) {
        for (let i = 1; i <= 12; i++) {
          const newName = MonthsList[i] + '-' + start_year;
          const endNewName = MonthsList[i] + '-' + end_year;
          const betnewName = MonthsList[i] + '-' + betweenYear;
          if (d.month === 1) {
            if (i === month) {
              newObj[newName + 'Month'] = newName;
              newObj[newName] = parseFloat(d.value) + '%';
            } else {
              newObj[newName + 'Month'] = newName;
              newObj[newName] = 0 + '%';
            }
          } else if (d.month === 2) {
            if (i === month) {
              newObj[newName + 'Month'] = newName;
              newObj[newName] = parseFloat(d.value) + '%';
            } else if (i === end_month) {
              newObj[endNewName + 'Month'] = endNewName;
              newObj[endNewName] = parseFloat(d.value) + '%';
            } else {
              newObj[newName + 'Month'] = newName;
              newObj[newName] = 0 + '%';
            }
          } else if (d.month === 3) {
            if (i === month) {
              newObj[newName + 'Month'] = newName;
              newObj[newName] = parseFloat(d.value) + '%';
            } else if (i === betweenMonth) {
              newObj[betnewName + 'Month'] = betnewName;
              newObj[betnewName] = parseFloat(d.value) + '%';
            } else if (i === end_month) {
              newObj[endNewName + 'Month'] = endNewName;
              newObj[endNewName] = parseFloat(d.value) + '%';
            } else {
              newObj[newName + 'Month'] = newName;
              newObj[newName] = 0 + '%';
            }
          }
        }
        scoreCardData = [
          ...scoreCardData,
          {
            ...newObj,
          },
        ];
      } else {
        const objIndex = scoreCardData.findIndex((m) => m.name === d.name);
        const obj = scoreCardData[objIndex];
        for (let i = 1; i <= 12; i++) {
          const newName = MonthsList[i] + '-' + start_year;
          const endNewName = MonthsList[i] + '-' + end_year;
          const betnewName = MonthsList[i] + '-' + betweenYear;
          if (d.month === 1) {
            if (i === month) {
              obj[newName + 'Month'] = newName;
              obj[newName] = parseFloat(d.value) + '%';
            } else {
              obj[newName + 'Month'] = newName;
              obj[newName] = 0 + '%';
            }
          } else if (d.month === 2) {
            if (i === month) {
              obj[newName + 'Month'] = newName;
              obj[newName] = parseFloat(d.value) + '%';
            } else if (i === end_month) {
              obj[endNewName + 'Month'] = endNewName;
              obj[endNewName] = parseFloat(d.value) + '%';
            } else {
              obj[newName + 'Month'] = newName;
              obj[newName] = 0 + '%';
            }
          } else if (d.month === 3) {
            if (i === month) {
              obj[newName + 'Month'] = newName;
              obj[newName] = parseFloat(d.value) + '%';
            } else if (i === betweenMonth) {
              obj[betnewName + 'Month'] = betnewName;
              obj[betnewName] = parseFloat(d.value) + '%';
            } else if (i === end_month) {
              obj[endNewName + 'Month'] = endNewName;
              obj[endNewName] = parseFloat(d.value) + '%';
            } else {
              obj[newName + 'Month'] = newName;
              obj[newName] = 0 + '%';
            }
          }
        }
        scoreCardData[objIndex] = {
          ...obj,
        };
      }
    });
  } else {
    updatedData?.forEach((d) => {
      let month = moment(d?.start_date).month() + 1;
      const end_month =
        moment(d?.end_date).month() === 0 ? 12 : moment(d?.end_date).month();
      let start_year = moment(d?.start_date).year();
      const end_year =
        moment(d?.end_date).month() === 0
          ? start_year
          : moment(d?.end_date).year();
      let betweenMonth = month + 1 === 13 ? end_month - 1 : month + 1;
      let betweenYear = month + 1 === 13 ? end_year : start_year;
      let newObj = {
        name: d.name,
      };
      if (!scoreCardData.map((m) => m.name).includes(d.name)) {
        if (d.month === 1) {
          let newName = MonthsList[month] + '-' + start_year;
          newObj[newName + 'Month'] = newName;
          newObj[newName] = parseFloat(d.value) + '%';
        } else if (d.month === 2) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          newObj[newName + 'Month'] = newName;
          newObj[newName] = parseFloat(d.value) + '%';
          newObj[endName + 'Month'] = endName;
          newObj[endName] = parseFloat(d.value) + '%';
        } else if (d.month === 3) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          let betName = MonthsList[betweenMonth] + '-' + betweenYear;
          newObj[newName + 'Month'] = newName;
          newObj[newName] = parseFloat(d.value) + '%';
          newObj[betName + 'Month'] = betName;
          newObj[betName] = parseFloat(d.value) + '%';
          newObj[endName + 'Month'] = endName;
          newObj[endName] = parseFloat(d.value) + '%';
        }

        scoreCardData = [
          ...scoreCardData,
          {
            ...newObj,
          },
        ];
      } else {
        const objIndex = scoreCardData.findIndex((m) => m.name === d.name);
        const obj = scoreCardData[objIndex];
        if (d.month === 1) {
          let newName = MonthsList[month] + '-' + start_year;
          obj[newName + 'Month'] = newName;
          obj[newName] = parseFloat(d.value) + '%';
        } else if (d.month === 2) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          obj[newName + 'Month'] = newName;
          obj[newName] = parseFloat(d.value) + '%';
          obj[endName + 'Month'] = endName;
          obj[endName] = parseFloat(d.value) + '%';
        } else if (d.month === 3) {
          let newName = MonthsList[month] + '-' + start_year;
          let endName = MonthsList[end_month] + '-' + end_year;
          let betName = MonthsList[betweenMonth] + '-' + betweenYear;
          obj[newName + 'Month'] = newName;
          obj[newName] = parseFloat(d.value) + '%';
          obj[betName + 'Month'] = betName;
          obj[betName] = parseFloat(d.value) + '%';
          obj[endName + 'Month'] = endName;
          obj[endName] = parseFloat(d.value) + '%';
        }
        scoreCardData[objIndex] = {
          ...obj,
        };
      }
    });
  }
  return scoreCardData;
};

const tableDataModifier = (action, type) => {
  let topOKRsData: any[] = [];
  let topBenefitsData: any[] = [];
  let empHelpfulnessData: any[] = [];
  let departmentsdata: any[] = [];
  if (type === 'topOKRs') {
    action?.payload?.objectiveData !== undefined &&
      action?.payload?.objectiveData.forEach((item) => {
        let obj = {
          Star: item.star === 0 ? 'False' : 'True',
          Objective: `OBJ-${item.id} ${item.name}`,
          Owner: `${item.first_name} ${item.last_name}`,
          Downstream_OKRs_Linked: item.foreign_objective_count,
          Aligned_to:
            item.benefit !== null
              ? `BEN-${item.benefit}`
              : item.foreign_keyresult !== null
              ? `KR-${item.foreign_keyresult}`
              : item.foreign_objective !== null
              ? `OBJ-${item.foreign_objective}`
              : '-',
          KR: item.key_results,
          Priority:
            item.priority === 2
              ? 'High'
              : item.priority === 1
              ? 'Medium'
              : 'Low',
          Status: parseFloat(item.integrated_status).toFixed(2) + '% ',
        };
        topOKRsData.push(obj);
      });
    return topOKRsData;
  } else if (type === 'topBenefits') {
    action?.payload?.benefitData &&
      action?.payload?.benefitData.forEach((item) => {
        topBenefitsData.push({
          Star: item.star === 0 ? 'False' : 'True',
          Benefit: `BEN-${item.id} ${item.name}`,
          Owner: `${item.first_name} ${item.last_name}`,
          Status: parseFloat(item.integrated_status).toFixed(2) + '% ',
        });
      });
    return topBenefitsData;
  } else if (type === 'employeeHelpfullness') {
    action?.payload?.tasksData &&
      action?.payload?.tasksData.forEach((item) => {
        let obj = {
          Employee: `${item.user_first_name} ${item.user_last_name}`,
          Rating: parseFloat(item.helpfulness_rating).toFixed(2),
          Points: item.assistance_points,
        };
        empHelpfulnessData.push(obj);
      });
    return empHelpfulnessData;
  } else {
    action?.payload?.departmentData &&
      action?.payload?.departmentData.forEach((item) => {
        let obj = {
          Department_Name: item.tag_name,
          Status: parseFloat(item.integrated_status).toFixed(2) + '% ',
        };
        departmentsdata.push(obj);
      });
    return departmentsdata;
  }
};

export default function dashboardReducer(
  state = initState,
  action: dashboardAction
) {
  switch (action.type) {
    case dashboardTypes.GET_OKR_GRAPH_DATA:
      return {
        ...state,
        okrGraphLoading: true,
      };
    case dashboardTypes.GET_OKR_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        okrGraphData: okrGraphDataModifier(action, state),
        okrGraphLoading: false,
      };
    case dashboardTypes.GET_OKR_GRAPH_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        okrGraphLoading: false,
      };
    case dashboardTypes.GET_CALCULATION_STATUS:
      return {
        ...state,
        calculationGraphLoading: true,
      };
    case dashboardTypes.GET_CALCULATION_STATUS_SUCCESS:
      return {
        ...state,
        calculationStatus: action?.payload,
        calculationGraphLoading: false,
      };
    case dashboardTypes.GET_CALCULATION_STATUS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        calculationGraphLoading: false,
      };
    case dashboardTypes.GET_TOP_BENEFITS:
      return {
        ...state,
        benefitLoading: true,
      };
    case dashboardTypes.GET_TOP_BENEFITS_SUCCESS:
      return {
        ...state,
        topBenefits: action?.payload?.pagedItems
          ? dataModifier(action, state, 'topBenefits')
          : { ...state.topBenefits },
        benefitLoading: false,
        totalTopBenefit:
          action?.payload?.benefitData?.length > 0
            ? tableDataModifier(action, 'topBenefits')
            : [...state.totalTopBenefit],
      };
    case dashboardTypes.GET_TOP_BENEFITS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        benefitLoading: false,
      };
    case dashboardTypes.GET_TOP_OKRS:
      return {
        ...state,
        okrLoading: true,
      };
    case dashboardTypes.GET_TOP_OKRS_SUCCESS:
      return {
        ...state,
        topOKRs: action?.payload?.pagedItems
          ? dataModifier(action, state, 'topOKRs')
          : { ...state.topOKRs },
        okrLoading: false,
        totalTopOkrs:
          action?.payload?.objectiveData?.length > 0
            ? tableDataModifier(action, 'topOKRs')
            : [...state.totalTopOkrs],
      };
    case dashboardTypes.GET_TOP_OKRS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        okrLoading: false,
      };
    case dashboardTypes.GET_EMPLOYEE_HELPFULLNESS:
      return {
        ...state,
        employeesLoading: true,
      };
    case dashboardTypes.GET_EMPLOYEE_HELPFULLNESS_SUCCESS:
      return {
        ...state,
        employeeHelpfullness: action?.payload?.pagedItems
          ? dataModifier(action, state, 'employeeHelpfullness')
          : { ...state.employeeHelpfullness },
        employeesLoading: false,
        totalEmpHelpfullness:
          action?.payload?.tasksData?.length > 0
            ? tableDataModifier(action, 'employeeHelpfullness')
            : [...state.totalEmpHelpfullness],
      };
    case dashboardTypes.GET_EMPLOYEE_HELPFULLNESS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        employeesLoading: false,
      };
    case dashboardTypes.GET_DEPARTMENT_ACHIEVEMENT:
      return {
        ...state,
        departmentLoading: true,
      };
    case dashboardTypes.GET_DEPARTMENT_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        departmentAchievement: action?.payload?.pagedItems
          ? dataModifier(action, state, 'departmentAchievement')
          : { ...state.departmentAchievement },
        departmentLoading: false,
        totalDepartments:
          action?.payload?.departmentData?.length > 0
            ? tableDataModifier(action, 'departmentAchievement')
            : [...state.totalDepartments],
      };
    case dashboardTypes.GET_DEPARTMENT_ACHIEVEMENT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        departmentLoading: false,
      };
    case dashboardTypes.SET_DASHBOARD_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case dashboardTypes.POST_DASHBOARD_BENEFIT_STAR_SUCCESS:
      return {
        ...state,
        starBenefitSuccess: true,
      };
    case dashboardTypes.POST_DASHBOARD_BENEFIT_STAR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case dashboardTypes.POST_DASHBOARD_STAR_SUCCESS:
      return {
        ...state,
        starOkrSuccess: true,
      };
    case dashboardTypes.POST_DASHBOARD_STAR_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case dashboardTypes.POST_HEATMAP_GRAPH_DATA:
      return {
        ...state,
        heatmapLoading: true,
      };
    case dashboardTypes.POST_HEATMAP_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        heatmapData: heatMapDataModifier(action.heatmapData, state),
        heatMapFileData: heatMapFileDataModifier(action.heatmapData, state),
        heatmapLoading: false,
      };
    case dashboardTypes.POST_HEATMAP_GRAPH_DATA_FAILED:
      return {
        ...state,
        errorMessage: action,
        heatmapLoading: false,
      };
    case dashboardTypes.RESET_STAR_SUCCESS:
      return {
        ...state,
        starOkrSuccess: false,
        starBenefitSuccess: false,
      };
    case dashboardTypes.SET_SCORCARD_CATEGORY:
      return {
        ...state,
        scorecard_category: action.payload,
      };
    case dashboardTypes.GET_PERIODS_LIST:
      return {
        ...state,
      };
    case dashboardTypes.GET_PERIODS_LIST_SUCCESS:
      return {
        ...state,
        periods: action.payload,
      };
    case dashboardTypes.GET_PERIODS_LIST_FAILED:
      return {
        ...state,
        errorMessage: action,
      };

    default:
      return state;
  }
}
