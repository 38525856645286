import styled from 'styled-components';
// import { palette } from "styled-theme";
// import { variables } from "../../assets/styles/variables";
// import WithDirection from "../../library/helpers/rtl";

const CreditCardInfoStyleWrapper = styled.div`
  max-width: 100%;

  .isoCardInfoContentWrapper {
    .isoCardInfoContent {
      padding: 40px 0px 45px;
      /* @media only screen and (max-width: 1199px) {
        padding: 75px 0px 45px;
      }
      @media only screen and (max-width: 991px) {
        padding: 55px 0px 45px;
      } */
      .isoLogoWrapper {
        margin-bottom: 35px;
        /* @media only screen and (max-width: 1800px) {
          margin-bottom: 30px;
        }
        @media only screen and (max-width: 1600px) {
          margin-bottom: 60px;
        }
        @media only screen and (max-width: 1440px) {
          margin-bottom: 40px;
        }
        @media only screen and (max-width: 1199px) {
          margin-bottom: 30px;
        } */
      }
      .ant-alert {
        margin: auto;
        max-width: 500px;
      }
      text-align: center;
      h1 {
        font-size: 26px;
        line-height: 31px;
        color: #32386a;
        font-weight: 700;
        margin: 0px;
        @media only screen and (max-width: 1800px) {
          font-size: 22px;
          line-height: 28px;
        }
        @media only screen and (max-width: 1440px) {
          font-size: 20px;
          line-height: 26px;
        }
        @media only screen and (max-width: 1199px) {
          font-size: 18px;
        }
      }
      .card-desc {
        color: #2d2c2c;
        font-weight: 400 !important;
        margin: 35px 0px 45px;
        @media only screen and (max-width: 1800px) {
          margin: 30px 0px 40px;
        }
        @media only screen and (max-width: 1440px) {
          margin: 25px 0px 35px;
        }
        @media only screen and (max-width: 1199px) {
          margin: 20px 0px 30px;
        }
      }
      .credit-card {
        margin-top: 20px !important;
        &.portal-success-card {
          max-width: 800px;
          .ant-card-head {
            .ant-card-head-wrapper {
              .ant-card-head-title {
                .trial {
                  font-size: 25px;
                  padding: 25px 0px;
                }
              }
            }
          }
          .ant-card-body {
            max-width: 600px;
            .card-desc {
              .isoSpinCustomPortalSuccess {
                div {
                  height: 50px;
                  position: unset;
                }
              }
              span {
                color: #22c4f2;
                cursor: pointer;
                font-weight: bold;
              }
            }
          }
          .portal-success-button {
            width: 60%;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            &:focus,
            &:hover {
              border: 1px solid #035592;
              color: #035592;
              background-color: #fff;
            }
          }
        }
        max-width: 500px;
        margin: auto;
        box-shadow: 0px 1px 4px rgb(34 196 242 / 20%);
        .ant-card-head {
          padding: 0px;
          .ant-card-head-wrapper {
            padding: 0px;
            .ant-card-head-title {
              padding: 0px;
              p {
                margin: 0px;
              }
              .trial {
                background: #035592;
                color: #fff;
                padding: 13px 0px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                span {
                  font-weight: 700;
                }
                @media only screen and (max-width: 991px) {
                  font-size: 14px;
                }
                &.trial-end {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 21px;
                  @media only screen and (max-width: 1800px) {
                    padding: 12px 0px;
                    font-size: 17px;
                  }
                  @media only screen and (max-width: 1440px) {
                    font-size: 16px;
                  }
                }
              }
              .choose-plan {
                padding: 35px;
                text-align: left;
                font-size: 20px;
                line-height: 24px;
                color: #2d2c2c;
                /* border-bottom: 0.5px solid #dbd8d8; */
                span {
                  font-weight: 700;
                  color: #035592;
                  margin-left: 15px;
                  sup {
                    margin-left: 3px;
                    font-weight: 400;
                  }
                }
                @media only screen and (max-width: 1800px) {
                  padding: 30px;
                  font-size: 18px;
                }
                @media only screen and (max-width: 1440px) {
                  padding: 25px;
                  font-size: 17px;
                }
                @media only screen and (max-width: 1199px) {
                  padding: 20px;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .ant-card-body {
          margin-top: 50px;
          max-width: 350px;
          margin: auto;
          padding: 25px 0px 45px;
          @media only screen and (max-width: 1199px) {
            padding: 25px 0px 40px;
          }
        }
        .CreditCardInfo {
          .ant-form {
            .expiry-date {
              .ant-form-item-label > label::before {
                position: absolute;
                color: #e02929;
                right: -4px;
                top: 0;
                font-family: inherit;
                content: '*';
              }
              /* .ant-form-item-control-input-content {
                height: 40px;
              } */
            }
            .ant-form-item {
              margin-bottom: 35px;
              label {
                font-size: 20px;
                line-height: 24px;
                @media only screen and (max-width: 1800px) {
                  font-size: 18px;
                  line-height: 20px;
                }
                @media only screen and (max-width: 1440px) {
                  font-size: 16px;
                  line-height: 18px;
                }
                @media only screen and (max-width: 1199px) {
                  font-size: 15px;
                  line-height: 18px;
                }
              }
              .ant-form-item-control {
                .ant-input-group-compact {
                  max-width: 100%;
                  display: flex;
                  .ant-form-item {
                    width: 100%;
                    margin-bottom: 0px !important;
                    .ant-select.date {
                      width: 98%;
                      max-width: 98%;
                      /* border-width: 1px; */
                      &.ant-select-focused {
                        border: none;
                        .ant-select-selector {
                          border-color: rgb(183, 185, 187);
                        }
                      }
                    }
                  }
                }
                .ant-input-disabled {
                  background-color: #f5f5f5;
                  cursor: not-allowed;
                  height: 38px;
                  color: darkgrey;
                  .ant-select-selector {
                    border: 1px solid #dbd8d8 !important;
                    .ant-select-selection-item {
                      color: darkgrey;
                    }
                  }
                }
                .ant-form-item-control-input-content {
                  justify-content: space-between;
                  display: flex;
                  .ant-input-number {
                    width: 100%;
                  }
                }
                .ant-select {
                  text-align: left;
                }
                .ant-select-disabled {
                  .ant-select-selector {
                    .ant-select-selection-item {
                      background-color: #f5f5f5 !important;
                    }
                  }
                }
                .ant-select.date {
                  max-width: 48%;
                  text-align: left;
                }
              }
              @media only screen and (max-width: 1199px) {
                margin-bottom: 30px;
              }
              @media only screen and (max-width: 991px) {
                margin-bottom: 25px;
              }
              &.finance-year {
                .ant-select {
                  max-width: 100%;
                  text-align: left;
                }
              }
            }
            .ant-form-item-explain-error {
              float: left;
            }
            .time-zone {
              .ant-select {
                max-width: 100% !important;
              }
            }
            .ant-form-item.security-code {
              .ant-form-item-control {
                input {
                  max-width: 100%;
                  text-align: left;
                }
              }
            }
            .btn-group {
              display: flex;
              margin-top: 10px;
              button {
                cursor: pointer;
              }
              .cmn-btn {
                padding: 10px 18px !important;
                width: auto;
                &:focus,
                &:hover {
                  background-color: #fff;
                  border-color: #035592;
                  color: #035592;
                }
                &:first-child {
                  margin-right: 15px;
                }
                @media only screen and (max-width: 991px) {
                  padding: 8px 16px;
                }
              }
              .back-btn {
                margin-right: 15px;
                background-color: #fff;
                color: #035592;
                border-color: #035592;
                &:hover {
                  background-color: #035592;
                  color: #fff;
                }
              }
              .disabled-btn {
                background-color: #f5f5f5;
                color: rgba(0, 0, 0, 0.25);
                border-color: #d9d9d9;
                cursor: not-allowed !important;
                &:hover {
                  border-color: #1890ff;
                  background-color: #f5f5f5;
                  color: rgba(0, 0, 0, 0.25);
                }
              }
            }
          }
        }
      }

      .parent-card {
        max-width: 632px;
        box-shadow: 0px 0px 4px rgb(34 196 242 / 20%);
        margin: 35px auto 0px;
        &.ant-card-bordered {
          border: none;
        }
        .ant-card-head {
          padding: 0px;
          border-bottom: none;
          .ant-card-head-wrapper {
            padding: 0px;
            .ant-card-head-title {
              padding: 0px;
              p {
                margin: 0px;
              }
              .trial {
                text-align: center;
                background: #035592;
                color: #fff;
                padding: 13px 0px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                span {
                  font-weight: 700;
                }
                @media only screen and (max-width: 991px) {
                  font-size: 14px;
                }
                &.trial-end {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 21px;
                  @media only screen and (max-width: 1800px) {
                    padding: 12px 0px;
                    font-size: 17px;
                  }
                  @media only screen and (max-width: 1440px) {
                    font-size: 16px;
                  }
                  @media only screen and (max-width: 1199px) {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
        .ant-card-body {
          padding: 0;
          max-width: 100%;
          @media only screen and (max-width: 1199px) {
            padding: 0px 55px 0px 0px;
          }
          @media only screen and (max-width: 991px) {
            padding: 0px 45px 0px 0px;
          }
          .card-detail {
            display: block;
            padding: 25px 25px 0px 25px;
            text-align: left;
            font-size: 18px;
            line-height: 20px;
            color: #2d2c2c;
            > div {
              display: flex;
              flex-direction: column;
            }
            span {
              font-family: 'Inter-Bold', sans-serif;
              color: #035592;
              /* margin-left: 15px; */
              sup {
                margin-left: 3px;
                font-family: 'Inter-Regular', sans-serif;
              }
            }
          }
          .billing-wrapper {
            display: flex;
            flex: 1;
          }
          .isoPaymentContentWrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            .isoPaymentContent {
              padding: 0px 0px;
              /* border-right: 0.5px solid #dbd8d8; */
              width: 100%;
              .title {
                text-align: center;
              }
              .ant-card {
                height: 100%;
                &.ant-card-bordered {
                  border: none;
                }
                margin: 0px auto 0px;
                .ant-card-head {
                  padding: 0px;
                  .ant-card-head-wrapper {
                    padding: 0px;
                    .ant-card-head-title {
                      padding: 0px !important;
                      p {
                        margin: 0px;
                      }

                      .choose-plan {
                        display: block;
                        padding: 35px 35px 0 !important;
                        text-align: left;
                        font-size: 20px;
                        line-height: 24px;
                        color: #2d2c2c;
                        label {
                          font-size: 20px;
                          font-weight: 600;
                          margin-bottom: 30px;
                          display: block;
                          color: #32386a;
                          font-family: 'Inter-Medium', sans-serif;
                        }
                        > div {
                          display: flex;
                          flex-wrap: wrap;
                          border-bottom: 1px solid #dbd8d8;
                          padding-bottom: 10px;
                          div {
                            width: calc(50% - 10px);
                            margin-right: 20px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            margin-bottom: 25px;
                            &:nth-child(even) {
                              margin-right: 0;
                            }
                            span {
                              font-size: 16px;
                              width: 100%;
                              font-weight: 600;
                              &:first-child {
                                color: #9e9e9e;
                                font-size: 16px;
                                margin-bottom: 10px;
                                font-weight: 300;
                                text-transform: uppercase;
                                font-family: 'Inter-Regular', sans-serif;
                              }
                              &:last-child {
                                color: #035592;
                                font-size: 20px;
                                line-height: 22px;
                                font-weight: 600;
                                font-family: 'Inter-Medium', sans-serif;
                                word-break: break-all;
                                display: flex;
                              }
                            }
                          }
                        }
                      }
                      span {
                        color: #035592;
                        sup {
                          font-family: 'Inter-Regular', sans-serif;
                          top: 5px;
                          font-size: 14px;
                        }
                      }
                      .license {
                        display: flex;
                        p {
                          font-size: 12px;
                          margin-left: 3px;
                        }
                      }
                      @media only screen and (max-width: 1800px) {
                        font-size: 18px;
                        line-height: 20px;
                        padding: 30px;
                      }
                      @media only screen and (max-width: 1440px) {
                        padding: 25px;
                        font-size: 16px;
                        line-height: 18px;
                      }
                    }
                  }
                }
              }
            }
            .ant-card-body {
              padding: 0px;
              display: block;
              height: auto;
              border: none;
              .payment-info {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .count-payment {
                  padding: 45px 35px;
                  @media only screen and (max-width: 1800px) {
                    padding: 40px 30px;
                  }
                  @media only screen and (max-width: 1440px) {
                    padding: 35px 25px;
                  }
                }

                p {
                  margin: 0px;
                  &:first-child {
                    margin-bottom: 25px;
                  }
                  font-size: 20px;
                  line-height: 24px;
                  color: #2d2c2c;
                  @media only screen and (max-width: 1800px) {
                    font-size: 18px;
                    line-height: 20px;
                  }
                  @media only screen and (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 18px;
                  }
                  @media only screen and (max-width: 1199px) {
                    font-size: 15px;
                    line-height: 18px;
                  }

                  span:last-child {
                    float: right;
                  }
                }

                .total-payment {
                  padding: 45px 35px;
                  border-top: 0.5px solid #dbd8d8 !important;
                  @media only screen and (max-width: 1800px) {
                    padding: 40px 30px;
                  }
                  @media only screen and (max-width: 1440px) {
                    padding: 35px 25px;
                  }
                  p {
                    @media only screen and (max-width: 1199px) {
                      margin-bottom: 30px;
                    }
                    @media only screen and (max-width: 991px) {
                      margin-bottom: 25px;
                    }
                    span {
                      &.total {
                        color: #035592;
                        font-family: 'Inter-Bold', sans-serif;
                      }
                      sup {
                        font-family: 'Inter-Regular', sans-serif;
                      }
                    }
                  }
                }
              }
              .card-detail {
                padding: 35px 35px 20px;
                margin-bottom: 0;
                label {
                  font-size: 20px;
                  font-weight: 600;
                  margin-bottom: 30px;
                  display: block;
                  color: #32386a;
                  font-family: 'Inter-Medium', sans-serif;
                }
                > div {
                  margin-top: 5px;
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: row;
                  div {
                    width: calc(50% - 10px);
                    margin-right: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    margin-bottom: 25px;
                    &:nth-child(even) {
                      margin-right: 0;
                    }
                    span {
                      font-size: 16px;
                      width: 100%;
                      font-weight: 600;
                      &:first-child {
                        color: #9e9e9e;
                        font-size: 16px;
                        margin-bottom: 10px;
                        font-weight: 300;
                        text-transform: uppercase;
                        font-family: 'Inter-Regular', sans-serif;
                      }
                      &:last-child {
                        color: #035592;
                        font-size: 20px;
                        line-height: 22px;
                        font-weight: 600;
                        font-family: 'Inter-Medium', sans-serif;
                        word-break: break-all;
                        display: flex;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .note {
      max-width: 820px;
      padding: 0 10px;
      margin: 25px auto 0;
      span {
        font-size: 14px;
      }
    }
    .verified-btn {
      display: inline-flex;
      margin-top: 20px;
      button {
        cursor: pointer;
      }
      .cmn-btn {
        padding: 10px 18px !important;
        width: auto;
        &:focus,
        &:hover {
          background-color: #fff;
          border-color: #035592;
          color: #035592;
        }
        &:first-child {
          margin-right: 15px;
        }
        @media only screen and (max-width: 991px) {
          padding: 8px 16px;
        }
      }
      .back-btn {
        margin-right: 15px;
        background-color: #fff;
        color: #035592;
        border-color: #035592;
        &:hover {
          background-color: #035592;
          color: #fff;
        }
      }
      .disabled-btn {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        cursor: not-allowed !important;
        &:hover {
          border-color: #1890ff;
          background-color: #f5f5f5;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .note {
    span {
      display: block;
      text-align: center;
      font-size: 11px;
      color: #035592;
      font-weight: 600;
    }
  }
`;
export default CreditCardInfoStyleWrapper;
