import { ServiceAuthInstance } from "./index";

export const getrAllRoles = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/tools_configuration/role_permission_roles",
  });
};
export const getRoleById = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/profile/user_role",
  });
};

export const addNewRole = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/add_role",
    data: {
      ...payload,
    },
  });
};


