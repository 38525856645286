export const getSubDomain = () => {
  let host = window.location.hostname;
  let urlArray: string[] = [];
  if (host.includes("www.")) {
    host = host.replace("www.", "");
  }
  urlArray = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  return urlArray.length > 0 ? urlArray[0] : "executive";
};

export const baseURL: () => string = () => {
  const subdomain = getSubDomain();
  const url =
    subdomain === "executive"
      ? `https://www.${process.env.REACT_APP_INSTANCE_ENV}.com/executive/api`
      // ? `https://${process.env.REACT_APP_INSTANCE_ENV}/executive/api`
      : `https://${subdomain}.${process.env.REACT_APP_INSTANCE_ENV}.com/api`;
  // const url = adminURL;
  return url;
};

export const getUrl = () => {
  let host = window.location.hostname;
  // let url = "";
  if (host.includes("www.")) {
    host = host.replace("www", "");
  }
  else{
    host = `.${host}`
  }

  return host;
};
