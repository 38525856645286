import { Scrollbars } from "react-custom-scrollbars";

interface PropsType {
  id: undefined;
  style: object;
  children: JSX.Element;
  className: undefined;
}

const customScrollBar = ({ id, style, children, className }: PropsType) => (
  <Scrollbars
    id={id}
    className={className}
    style={style}
    autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}
    // autoHeight
    autoHeightMin={0}
    autoHeightMax={200}
    thumbMinSize={30}
    universal={true}
  >
    {children}
  </Scrollbars>
);

export default customScrollBar;
