import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import authActions from "../app/action";
import {
  getProfileReq,
  postSharedUserReq,
  updatePasswordReq,
  postUserManuallyReq,
  updateProfilePhotoReq,
  updateProfileReq,
  postPointsRatingReq,
  getCompanyIndustryReq,
} from "../../library/services/profileReq";
import {
  postUserManuallyPayload,
  postSharedUserPayload,
  updateProfilePayload,
} from "./types";
import { holdActions } from "../Holds/holdActions";
import { profileAction } from "./actionTypes";
import { manageUserActions } from "../manageUser/manageUserActions";
import { SubscriptionPlanTypes } from "../SubscriptionPlan/SubscriptionPlanTypes";
import { subscriptionHistoryPayload } from "../../config/constant";

export function* getProfileEffect() {
  yield takeEvery(
    profileAction.GET_PROFILE_REQUEST,
    function* ({ payload }: { type: string; payload?: any }) {
      yield put({ type: profileAction.REMOVE_ALERT });
      try {
        if (payload?.loading === true) {
          yield put(authActions.globalLoaderHandler(true));
        }

        const response = yield call(getProfileReq);
        if (response) {
          yield put({
            type: profileAction.GET_PROFILE_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: profileAction.GET_PROFILE_FAILED,
            payload: response.data.message,
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put({
            type: profileAction.GET_PROFILE_FAILED,
            payload: error.message,
          });
        }
      }
      if (payload?.loading === true) {
        yield put(authActions.globalLoaderHandler(false));
      }
    }
  );
}

export function* postUserManuallyEffect() {
  yield takeEvery(
    profileAction.POST_USER_MANUALY_REQUEST,
    function* ({
      payload,
    }: {
      type: string;
      payload: postUserManuallyPayload;
    }) {
      yield put({ type: profileAction.REMOVE_ALERT });
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postUserManuallyReq, payload);
        if (response) {
          yield all([
            put({
              type: profileAction.POST_USER_MANUALY_SUCCESS,
              payload: response.data.data,
            }),
            put({
              type: manageUserActions.GET_ALL_USER,
              payload: { page: 1, take: 10 },
            }),
             put({
              type: holdActions.SCROLL_TO_TOP,
            })            
          ]);
        } else {
          yield all([
            put({
              type: profileAction.POST_USER_MANUALY_FAILED,
              payload: response.data.data.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            })
          ]) 
        }
      } catch (error) {
        yield all([
          put({
          type: profileAction.POST_USER_MANUALY_FAILED,
          payload: error,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          })
        ])
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postSharedUserEffect() {
  yield takeEvery(
    profileAction.POST_SHARED_REQUEST,
    function* ({ payload }: { type: string; payload: postSharedUserPayload }) {
      yield put({ type: profileAction.REMOVE_ALERT });
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postSharedUserReq, payload);
        if (response) {
          yield all([
            put({
              type: profileAction.POST_SHARED_SUCCESS,
              payload: response.data.data,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            })
          ]) 
        } else {
          yield all([
            put({
             type: profileAction.POST_SHARED_FAILED,
             payload: response.data.data.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            })
          ])
        }
      } catch (error: any) {
        yield all([
          put({
            type: profileAction.POST_SHARED_FAILED,
            payload: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ])
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* updateProfileEffect() {
  yield takeEvery(
    profileAction.UPDATE_PROFILE,
    function* ({ payload }: { type: string; payload: updateProfilePayload }) {
      yield put({ type: profileAction.REMOVE_ALERT });
      yield put(authActions.globalLoaderHandler(true));
      try {
        const response: any = yield call(updateProfileReq, payload);
        if (response.data) {
          yield all([
            put({
              type: profileAction.GET_PROFILE_REQUEST,
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload
            }),
            put({
              type: profileAction.UPDATE_PROFILE_SUCCESS,
              payload: "Profile update successfully!",
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield put({
          type: profileAction.UPDATE_PROFILE_FAILED,
          payload: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* updateProfilePhotoEffect() {
  yield takeEvery(
    profileAction.UPDATE_PROFILE_PHOTO,
    function* ({
      payload,
      check,
    }: {
      type: string;
      payload: Record<string, string>;
      check: boolean;
    }) {
      // if (check === "add") {
      // }
      yield put(authActions.globalLoaderHandler(true));

      try {
        const response: any = yield call(updateProfilePhotoReq, payload);

        if (response.status) {
          const responsed = yield call(getProfileReq);
          if (responsed?.status) {
            yield put({
              type: profileAction.GET_PROFILE_SUCCESS,
              payload: responsed.data.data,
            });
          }
          yield all([
            put({
              type: profileAction.UPDATE_PROFILE_PHOTO_SUCCESS,
              payload: "Profile photo update successfully!",
            }),
            yield put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ])
        }
      } catch (error: any) {
        yield put({
          type: profileAction.UPDATE_PROFILE_PHOTO_FAILED,
          payload: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));

      // if (check === "add") {
      // }
    }
  );
}

export function* postRatingEffect() {
  yield takeEvery(
    profileAction.POST_RATING_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      yield put(authActions.globalLoaderHandler(true));
      try {
        const response = yield call(postPointsRatingReq, payload);
        if (response.data) {
          yield all([
            put({
              type: profileAction.POST_RATING_SUCCESS,
              payload: response.data,
            }),
          ]);
        } else {
          put({
            type: profileAction.POST_RATING_FAILED,
            payload: response.data.message,
          });
        }
      } catch (error) {
        yield put({
          type: profileAction.POST_RATING_FAILED,
          payload: error,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* updatePassword() {
  yield takeEvery(
    profileAction.UPDATE_PASSWORD,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        const response = yield call(updatePasswordReq, payload);
        if (response.data) {
          yield all([
            put({
              type: profileAction.UPDATE_PASSWORD_SUCCESS,
              updateProfileSuccess: response.data.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        } else {
          put({
            type: profileAction.UPDATE_PASSWORD_FAILED,
            payload: response.data.message,
          });
        }
      } catch (error) {
        yield put({
          type: profileAction.UPDATE_PASSWORD_FAILED,
          payload: error,
        });
      }
    }
  );
}

export function* getCompanyIndrustyEffect() {
  yield takeEvery(profileAction.GET_COMPANY_INDUSTRY, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getCompanyIndustryReq);
      if (response) {
        yield put({
          type: profileAction.GET_COMPANY_INDUSTRY_SUCCESS,
          CompanyIndrustry: response?.data?.data?.companyData,
        });
      }
    } catch (error: any) {
      yield put({
        type: profileAction.GET_COMPANY_INDUSTRY_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProfileEffect),
    fork(postUserManuallyEffect),
    fork(updatePassword),
    fork(postSharedUserEffect),
    fork(updateProfilePhotoEffect),
    fork(updateProfileEffect),
    fork(postRatingEffect),
    fork(getCompanyIndrustyEffect),
  ]);
}
