export enum profileAction {
  GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILED = "GET_PROFILE_FAILED",
  POST_USER_MANUALY_REQUEST = "POST_USER_MANUALY_REQUEST",
  POST_USER_MANUALY_SUCCESS = "POST_USER_MANUALY_SUCCESS",
  POST_USER_MANUALY_FAILED = "POST_USER_MANUALY_FAILED",
  POST_SHARED_REQUEST = "POST_SHARED_REQUEST",
  POST_SHARED_SUCCESS = "POST_SHARED_SUCCESS",
  POST_SHARED_FAILED = "POST_SHARED_FAILED",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED",
  CLEAR_RESPONSE_MESSAGES = "CLEAR_RESPONSE_MESSAGES",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED",

  UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO",
  UPDATE_PROFILE_PHOTO_SUCCESS = "UPDATE_PROFILE_PHOTO_SUCCESS",
  UPDATE_PROFILE_PHOTO_FAILED = "UPDATE_PROFILE_PHOTO_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",
  REMOVE_ALERT_CHANGE_PASSWORD = "REMOVE_ALERT_CHANGE_PASSWORD",

  POST_RATING_REQUEST = "POST_RATING_REQUEST",
  POST_RATING_SUCCESS = "POST_RATING_SUCCESS",
  POST_RATING_FAILED = "POST_RATING_FAILED",

  CLEAR_USER_INVITATION = "CLEAR_USER_INVITATION",
  GET_COMPANY_INDUSTRY = "GET_COMPANY_INDUSTRY",
  GET_COMPANY_INDUSTRY_SUCCESS = "GET_COMPANY_INDUSTRY_SUCCESS",
  GET_COMPANY_INDUSTRY_FAILED = "GET_COMPANY_INDUSTRY_FAILED",
}
