import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const getDefaultConfigReq = () => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.GET_DEFAULT_CONFIG,
    method: 'POST'
  });
};

export const editDefaultConfigReq = (payload) => {
  return ServiceAdminAuthInstance({
    url: adminApiUrls.EDIT_DEFAULT_CONFIG,
    method: 'POST',
    data: { ...payload }
  });
};
