import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { commonFilters } from "./commonFilters";

import {
  getFiltersReq,
  addUpdatesFiltersReq,
} from "../../library/services/CommonFiltersReq";
export function* getFiltersEffect() {
  yield takeEvery(commonFilters.GET_FILTERS, function* ({ payload }: any) {
    try {
      const response = yield call(getFiltersReq, payload);

      if (response) {
        yield put({
          type: commonFilters.GET_FILTERS_SUCCESS,
          payload: response.data?.data?.filterData,
        });
      }
    } catch (error: any) {
      yield put({
        type: commonFilters.GET_FILTERS_FAILED,
        payload: error?.data?.message,
      });
    }
  });
}

export function* addUpdateFiltersEffect() {
  yield takeEvery(
    commonFilters.ADD_UPDATE_FILTERS,
    function* ({ payload }: any) {
      try {
        const id = payload?.navigation_menu;
        const response: any = yield call(addUpdatesFiltersReq, payload);
        if (response.status) {
          yield put({
            type: commonFilters.ADD_UPDATE_FILTERS_SUCCESS,
            payload: response,
          });
          yield put({
            type: commonFilters.GET_FILTERS,
            payload: { id: id },
          });
        }
      } catch (error: any) {
        yield put({
          type: commonFilters.ADD_UPDATE_FILTERS_FAILED,
          payload: error.data.message,
        });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(getFiltersEffect), fork(addUpdateFiltersEffect)]);
}
