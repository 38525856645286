export enum WorkspaceTypes {
  WORKSPACE_LIST_REQUEST = 'WORKSPACE_LIST_REQUEST',
  WORKSPACE_LIST_SUCCESS = 'WORKSPACE_LIST_SUCCESS',
  WORKSPACE_LIST_FAILED = 'WORKSPACE_LIST_FAILED',

  EXPORT_WORKSPACE_LIST_REQUEST = 'EXPORT_WORKSPACE_LIST_REQUEST',
  EXPORT_WORKSPACE_LIST_SUCCESS = 'EXPORT_WORKSPACE_LIST_SUCCESS',

  WORKSPACE_DETAIL_REQUEST = 'WORKSPACE_DETAIL_REQUEST',
  WORKSPACE_DETAIL_SUCCESS = 'WORKSPACE_DETAIL_SUCCESS',
  WORKSPACE_DETAIL_FAILED = 'WORKSPACE_USER_DETAIL_FAILED',

  WORKSPACE_SUBSCRIPTION_DETAIL_REQUEST = 'WORKSPACE_SUBSCRIPTION_DETAIL_REQUEST',
  WORKSPACE_SUBSCRIPTION_DETAIL_SUCCESS = 'WORKSPACE_SUBSCRIPTION_DETAIL_SUCCESS',
  WORKSPACE_SUBSCRIPTION_DETAIL_FAILURE = 'WORKSPACE_SUBSCRIPTION_DETAIL_FAILURE',

  EDIT_SUBSCRIPTION_DETAIL_REQUEST = 'EDIT_SUBSCRIPTION_DETAIL_REQUEST',
  EDIT_SUBSCRIPTION_DETAIL_SUCCESS = 'EDIT_SUBSCRIPTION_DETAIL_SUCCESS',
  EDIT_SUBSCRIPTION_DETAIL_FAILURE = 'EDIT_SUBSCRIPTION_DETAIL_FAILURE',

  WORKSPACE_VMSB_OKR_DETAIL_REQUEST = 'WORKSPACE_VMSB_OKR_DETAIL_REQUEST',
  WORKSPACE_VMSB_OKR_DETAIL_SUCCESS = 'WORKSPACE_VMSB_OKR_DETAIL_SUCCESS',
  WORKSPACE_VMSB_OKR_DETAIL_FAILURE = 'WORKSPACE_VMSB_OKR_DETAIL_FAILURE',

  WORKSPACE_CHANGE_ROLE_REQUEST = 'WORKSPACE_CHANGE_ROLE_REQUEST',
  WORKSPACE_CHANGE_ROLE_SUCCESS = 'WORKSPACE_CHANGE_ROLE_SUCCESS',
  WORKSPACE_CHANGE_ROLE_FAILURE = 'WORKSPACE_CHANGE_ROLE_FAILURE',

  WORKSPACE_DETAIL_USER_LIST_REQUEST = 'WORKSPACE_DETAIL_USER_LIST_REQUEST',
  WORKSPACE_DETAIL_USER_LIST_SUCCESS = 'WORKSPACE_DETAIL_USER_LIST_SUCCESS',
  WORKSPACE_DETAIL_USER_LIST_FAILURE = 'WORKSPACE_DETAIL_USER_LIST_FAILURE',
  WORKSPACE_DETAIL_PAGE_CHANGE = 'WORKSPACE_DETAIL_PAGE_CHANGE',

  WORKSPACE_SUBSCRIPTION_HISTORY_REQUEST = 'WORKSPACE_SUBSCRIPTION_HISTORY_REQUEST',
  WORKSPACE_SUBSCRIPTION_HISTORY_SUCCESS = 'WORKSPACE_SUBSCRIPTION_HISTORY_SUCCESS',
  WORKSPACE_SUBSCRIPTION_HISTORY_FAILURE = 'WORKSPACE_SUBSCRIPTION_HISTORY_FAILURE',

  WORKSPACE_ACTIVE_LOG_REQUEST = 'WORKSPACE_ACTIVE_LOG_REQUEST',
  WORKSPACE_ACTIVE_LOG_SUCCESS = 'WORKSPACE_ACTIVE_LOG_SUCCESS',
  WORKSPACE_ACTIVE_LOG_FAILURE = 'WORKSPACE_ACTIVE_LOG_FAILURE',

  WORKSPACE_TRANSACTIONS_REQUEST = 'WORKSPACE_TRANSACTIONS_REQUEST',
  WORKSPACE_TRANSACTIONS_SUCCESS = 'WORKSPACE_TRANSACTIONS_SUCCESS',
  WORKSPACE_TRANSACTIONS_FAILURE = 'WORKSPACE_TRANSACTIONS_FAILURE',

  REMOVE_ALERTS = 'REMOVE_ALERTS'
}
