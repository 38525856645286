import { all } from 'redux-saga/effects';
import SubscriptionPlanSaga from '../SubscriptionPlan/saga';
import strategySaga from '../strategy/saga';
import missionSaga from '../mission/saga';
import benefitSaga from '../benefit/saga';
import visionSaga from '../vision/saga';
import VmsbOkrCommonSaga from '../VmsbOkrCommon/saga';
import organizationSettingsSaga from '../organizationSettings/saga';
import portalRatingSettingsSaga from '../portalRatingSettings/saga';
import ExecutiveSignupStep2Saga from '../ExecutiveSignupStep2/saga';
import StarSaga from '../Star/saga';
import appSagas from '../app/saga';
import authSaga from '../../redux/auth/saga';
import keyResultSaga from '../KeyResult/saga';
import TaskSaga from '../Tasks/saga';
import profileSaga from '../profile/saga';
import ManageUserSaga from '../manageUser/saga';
import ObjectiveSaga from '../Objective/saga';
import holdSaga from '../Holds/saga';
import rolesSaga from '../rolesAndPermission/saga';
import golesSaga from '../Goals/saga';
import periodSaga from '../period/saga';
import commentSaga from '../Comments/saga';
import orgfinanicalSaga from '../OrganisationFinaicalYear/saga';
import NotificationSaga from '../Notification/saga';
import vmsbSaga from '../vmsbCommon/saga';
import FiltersSaga from '../CommenFiters/saga';
import kanbanSaga from '../kanban/saga';
import scoreCardStatusSaga from '../scoreCardStatus/saga';
import workspaceSaga from '../workspace/saga';
import searchSaga from '../search/saga';
import instaMessageSaga from '../InstaMessage/saga';
import dashboardSaga from '../Dashboard/saga';
import calendarSaga from '../calendar/saga';
import GlobalViewSaga from '../GlobalView/saga';
import adminAuthSaga from '../Admin/auth/saga';
import DashboardSaga from '../Admin/Dashboard/DashboardSaga';
import WorkspaceUserSaga from '../Admin/WorkspaceUsers/saga';
import WorkspaceSaga from '../Admin/Workspace/workspaceSaga';
import AdminFiltersSaga from '../Admin/Filters/saga';
import TooltipSaga from '../Admin/Tooltip/saga';
import PromoCodeSaga from '../Admin/Promocode/saga';
import ConfigSaga from '../Admin/Configuration/saga';
import subscriptionSaga from '../Admin/subscription/saga';
import globalSaga from '../global/saga';

export function* rootSaga() {
  yield all([
    SubscriptionPlanSaga(),
    strategySaga(),
    benefitSaga(),
    missionSaga(),
    visionSaga(),
    VmsbOkrCommonSaga(),
    organizationSettingsSaga(),
    portalRatingSettingsSaga(),
    ExecutiveSignupStep2Saga(),
    StarSaga(),
    appSagas(),
    authSaga(),
    keyResultSaga(),
    profileSaga(),
    TaskSaga(),
    ManageUserSaga(),
    rolesSaga(),
    ObjectiveSaga(),
    holdSaga(),
    golesSaga(),
    periodSaga(),
    commentSaga(),
    orgfinanicalSaga(),
    NotificationSaga(),
    vmsbSaga(),
    FiltersSaga(),
    kanbanSaga(),
    scoreCardStatusSaga(),
    workspaceSaga(),
    searchSaga(),
    instaMessageSaga(),
    dashboardSaga(),
    calendarSaga(),
    GlobalViewSaga(),
    globalSaga(),

    //!SECTION SUPER ADMIN SAGA
    adminAuthSaga(),
    DashboardSaga(),
    WorkspaceUserSaga(),
    WorkspaceSaga(),
    AdminFiltersSaga(),
    TooltipSaga(),
    PromoCodeSaga(),
    ConfigSaga(),
    subscriptionSaga()
  ]);
}
