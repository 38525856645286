import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import history from "../../library/helpers/history";
import authActions from "../app/action";
import { holdActions } from "../Holds/holdActions";

import {
  postTasksReq,
  getTasksReq,
  patchTasksReq,
  getTasksOwnerReq,
  getTasksKeyResultReq,
  getTasksommetsReq,
  patchTaskStageReq,
  patchHelpfulnessRatingReq,
  deleteHelpfulnessRatingReq,
  deleteTaskReq,
} from "../../library/services/TasksReq";
import { taskTypes } from "./taskTypes";
import {
  getTasksKeyReultpayload,
  getTasksPayload,
  patchTasksPayload,
  postTaskPayload,
} from "./types";

export function* postTasksEffect() {
  yield takeEvery(
    taskTypes.POST_TASKS,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement?: boolean;
      payload: postTaskPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postTasksReq, payload);
        if (response.status) {
          yield all([
            put({
              type: taskTypes.POST_TASKS_SUCCESS,
              successMessage: response?.data?.data?.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
          history.push(`viewtask/${response?.data?.data?.TaskData?.id}`);
        }
      } catch (error: any) {
        yield put({
          type: taskTypes.POST_TASKS_FAILED,
          payload: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getTasksEffect() {
  yield takeEvery(
    taskTypes.GET_TASKS,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement?: boolean;
      payload: getTasksPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getTasksReq, payload);
        if (response) {
          yield put({
            type: taskTypes.GET_TASKS_SUCCESS,
            singalTaskDetails: response?.data?.data?.taskData,
            objParent: response?.data?.data?.objParent?.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: taskTypes.GET_TASKS_FAILED,
          errorMessage: error?.data?.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* patchTasksEffect() {
  yield takeEvery(
    taskTypes.PATCH_TASKS,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement?: boolean;
      payload: patchTasksPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(patchTasksReq, payload);
        if (response?.status) {
          yield all([
            put({
              type: taskTypes.PATCH_TASKS_SUCCESS,
              successMessage: response?.data?.data?.message,
            }),
            put({
              type: taskTypes.GET_TASKS,
              payload: payload,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: taskTypes.PATCH_TASKS_FAILED,
            errorMessage: error?.data?.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getTasksOwnerEffect() {
  yield takeEvery(taskTypes.GET_TASKS_OWNER, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getTasksOwnerReq);
      if (response) {
        yield put({
          type: taskTypes.GET_TASKS_OWNER_SUCCESS,
          taskOwners: response?.data?.data?.Owner,
        });
      }
    } catch (error: any) {
      yield put({
        type: taskTypes.GET_TASKS_OWNER_FAILED,
        payload: error?.data?.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getTasksCommetsEffect() {
  yield takeEvery(
    taskTypes.GET_TASKS_COMMENTS,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: getTasksPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getTasksommetsReq, payload);
        if (response.status) {
          yield put({
            type: taskTypes.GET_TASKS_COMMENTS_SUCCESS,
            taskComments: response?.data?.data?.paginatedTaskData,
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getTasksKeyResultFromObjEffect() {
  yield takeEvery(
    taskTypes.GET_TASKS_KEY_RESULT,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: getTasksKeyReultpayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getTasksKeyResultReq);
        if (response?.status) {
          yield put({
            type: taskTypes.GET_TASKS_KEY_RESULT_SUCCESS,
            keyonbasisofObjective: response?.data?.data?.keyonbasisofObjective,
          });
        }
      } catch (error: any) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* patchTaskStageEffect() {
  yield takeEvery(
    taskTypes.PATCH_TASK_STAGE,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(patchTaskStageReq, payload);
        let payloadData = {
          id: payload.id,
          type: "get_task_comments",
          page: 1,
          take: 3,
        };
        if (response.status) {
          yield all([
            put({
              type: taskTypes.GET_TASKS,
              payload: payloadData,
            }),
            put({
              type: taskTypes.PATCH_TASK_STAGE_SUCCESS,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: taskTypes.PATCH_TASK_STAGE_FAILED,
            errorMessage: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* patchTaskHelpfulnessEffect() {
  yield takeEvery(
    taskTypes.PATCH_HELPFULNESS_RATING,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(patchHelpfulnessRatingReq, payload);
        let payloadData = {
          id: payload.id,
          type: "get_task_comments",
          page: 1,
          take: 3,
        };
        if (response) {
          yield all([
            put({
              type: taskTypes.GET_TASKS,
              payload: payloadData,
            }),
            put({
              type: taskTypes.PATCH_HELPFULNESS_RATING_SUCCESS,
              payload: response,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: taskTypes.PATCH_HELPFULNESS_RATING_FAILED,
            payload: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* deleteTaskHelpfulnessEffect() {
  yield takeEvery(
    taskTypes.DELETE_HELPFULNESS_RATING,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(deleteHelpfulnessRatingReq, payload);
        let payloadData = {
          id: payload.id,
          type: "get_task_comments",
          page: 1,
          take: 3,
        };
        if (response) {
          yield all([
            put({
              type: taskTypes.GET_TASKS,
              payload: payloadData,
            }),
            put({
              type: taskTypes.DELETE_HELPFULNESS_RATING_SUCCESS,
              payload: response,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          yield put({
            type: taskTypes.DELETE_HELPFULNESS_RATING_FAILED,
            payload: error.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* deleteTaskEffect() {
  yield takeEvery(
    taskTypes.DELETE_TASKS,
    function* ({
      payload,
    }: {
      type: string;
      inIncrement: boolean;
      payload: getTasksKeyReultpayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(deleteTaskReq, payload);
        if (response) {
          yield put({
            type: taskTypes.DELETE_TASKS_SUCCESS,
          })
          history.goBack();
        }
      } catch (error: any) {
        yield put({
            type: taskTypes.DELETE_TASKS_FAILED,
            errorMessage: error.data.message,
          })
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postTasksEffect),
    fork(getTasksEffect),
    fork(patchTasksEffect),
    fork(getTasksOwnerEffect),
    fork(getTasksKeyResultFromObjEffect),
    fork(getTasksCommetsEffect),
    fork(patchTaskStageEffect),
    fork(patchTaskHelpfulnessEffect),
    fork(deleteTaskHelpfulnessEffect),
    fork(deleteTaskEffect),
  ]);
}
