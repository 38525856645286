import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  getAllPeriodReq,
  postPeriodReq,
} from "../../library/services/periodReq";
import { periodTypes } from "./periodTypes";
import authActions from "../app/action";

export function* getAllPeriodEffect() {
  yield takeEvery(periodTypes.GET_ALL_PERIODS, function* () {
    yield put(authActions.globalLoaderHandler(true));
    try {
      const response = yield call(getAllPeriodReq);
      if (response) {
        yield put({
          type: periodTypes.GET_ALL_PERIODS_SUCCESS,
          payload: response.data.data.periods,
        });
      } else {
        yield put({
          type: periodTypes.GET_ALL_PERIODS_FAILURE,
          // payload: response.data.message,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        yield put({
          type: periodTypes.POST_PERIOD_FAILURE,
          // payload: error.message,
        });
      }
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postPeriodEffect() {
  yield takeEvery(periodTypes.POST_PERIOD, function* (payload) {
    yield put(authActions.globalLoaderHandler(true));
    try {
      const response = yield call(postPeriodReq, payload);
      if (response) {
        yield put({
          type: periodTypes.POST_PERIOD_SUCCESS,
          payload: response.data.data.periods,
        });
      } else {
        yield put({
          type: periodTypes.POST_PERIOD_FAILURE,
          // payload: response.data.message,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        yield put({
          type: periodTypes.POST_PERIOD_FAILURE,
          // payload: error.message,
        });
      }
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([fork(getAllPeriodEffect), fork(postPeriodEffect)]);
}
