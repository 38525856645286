export enum periodTypes {
  POST_PERIOD = "POST_PERIOD",
  POST_PERIOD_SUCCESS = "POST_PERIOD_SUCCESS",
  POST_PERIOD_FAILURE = "POST_PERIOD_FAILURE",

  GET_ALL_PERIODS = "GET_ALL_PERIODS",
  GET_ALL_PERIODS_SUCCESS = "GET_ALL_PERIODS_SUCCESS",
  GET_ALL_PERIODS_FAILURE = "GET_ALL_PERIODS_FAILURE",

  CHANGE_PERIOD = "CHANGE_PERIOD",

  CLEAR_PERIOD = "CLEAR_PERIOD",
}
