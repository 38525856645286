import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { calendarTypes } from "./calendarTypes";
import authActions from "../app/action";
import { postCalendarPopupReq, postCalendarReq } from "../../library/services/calendarReq";
import { calendarPayload } from "./types";

export function* postCalendarEffect() {
  yield takeEvery(calendarTypes.POST_CALENDAR, function* ({ payload }: { type: string, isIncrement: boolean, payload: calendarPayload }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(postCalendarReq, payload);
      if (response) {
        yield put({
          type: calendarTypes.POST_CALENDAR_SUCCESS,
          events: response.data.data,
        });
      }
      else {
        yield put({
          type: calendarTypes.POST_CALENDAR_FAILED,
          errorMessage: response?.data?.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: calendarTypes.POST_CALENDAR_FAILED,
        errorMessage: error?.data?.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postCalendarPopupEffect() {
  yield takeEvery(calendarTypes.POST_CALENDAR_POPUP, function* ({ payload }: { type: string, isIncrement: boolean, payload: Record<string, string | number> }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(postCalendarPopupReq, payload);
      if (response) {
        yield put({
          type: calendarTypes.POST_CALENDAR_POPUP_SUCCESS,
          popupData: response.data.data,
        });
      }
      else {
        yield put({
          type: calendarTypes.POST_CALENDAR_POPUP_FAILED,
          errorMessage: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: calendarTypes.POST_CALENDAR_POPUP_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(postCalendarEffect),
    fork(postCalendarPopupEffect),
  ])
}