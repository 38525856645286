import { globalViewTypes } from './globalViewTypes';
import { globalViewAction, globalViewState } from './types';
let initState: globalViewState = {
  completeData: [],
  orphanObjData: [],
  organizationData: [],
  errorMessage: '',
  globalViewData: {},
  status: {},
  orphanUser: {},
  nodeData: null,
  searchedNodes: null,
};

export default function globalViewReducer(
  state = initState,
  action: globalViewAction
) {
  switch (action.type) {
    case globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_DATA:
      return {
        ...state,
      };
    case globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_SUCCESS:
      return {
        ...state,
        completeData: action?.payload,
      };
    case globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_DATA:
      return {
        ...state,
      };
    case globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_SUCCESS:
      return {
        ...state,
        orphanObjData: action?.payload,
      };
    case globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_DATA:
      return {
        ...state,
      };
    case globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationData: action?.payload,
      };
    case globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case globalViewTypes.POST_GLOBAL_VIEW:
      return {
        ...state,
      };
    case globalViewTypes.POST_GLOBAL_VIEW_SUCCESS:
      return {
        ...state,
        globalViewData: action?.payload,
      };
    case globalViewTypes.POST_GLOBAL_VIEW_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case globalViewTypes.POST_VMSB_STATUS:
      return {
        ...state,
      };
    case globalViewTypes.POST_VMSB_STATUS_SUCCESS:
      return {
        ...state,
        status: action?.payload,
      };
    case globalViewTypes.POST_VMSB_STATUS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case globalViewTypes.GET_CHILD_NODE:
      return {
        ...state,
      };
    case globalViewTypes.GET_CHILD_NODE_SUCCESS:
      return {
        ...state,
        nodeData: action?.payload?.nodeComponent,
      };
    case globalViewTypes.GET_CHILD_NODE_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case globalViewTypes.RESET_VMSB_STATUS:
      return {
        ...state,
        status: {},
      };
    case globalViewTypes.SEARCH_GLOBAL_VIEW:
      return {
        ...state,
      };
    case globalViewTypes.SERACH_GLOBAL_VIEW_SUCCESS:
      return {
        ...state,
        searchedNodes: action?.payload,
      };
    case globalViewTypes.SEARCH_GLOBAL_VIEW_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}
