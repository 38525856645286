import axios from 'axios';

export const verifyAuthLogin = async (payload) => {
  let response;
  try {
    if (payload.executive) {
      response = await axios({
        method: 'get',
        url: `https://${payload.state}/executive/api/master/executive${payload.method}/redirect?&code=${payload.code}`
      });
    } else {
      if (payload.token) {
        response = await axios({
          method: 'get',
          url: `https://${payload.state}/api/auth/${payload.method}/redirect?state=${payload.state}|${payload.token}&code=${payload.code}`
        });
      } else {
        response = await axios({
          method: 'get',
          url: `https://${payload.state}/api/auth/${payload.method}/redirect?state=${payload.state}&code=${payload.code}`
        });
      }
    }
    return response;
  } catch (error) {
    //@ts-ignore
    return error.response;
  }
};

export const manualSignup = async (payload) => {
  let response;
  try {
    response = await axios({
      method: 'GET',
      url: `https://${payload.state}/api/auth/${payload.method}/redirect?state=${payload.state}|${payload.token}&code=${payload.code}`
    });
    return response;
  } catch (error) {
    //@ts-ignore
    return error.response;
  }
};

export const workspaceSignin = async (payload) => {
  let response;
  try {
    response = await axios({
      method: 'GET',
      url: `https://${payload.state}/executive/api/master/${payload.method}/redirect?&code=${payload.code}`
    });
    return response;
  } catch (error) {
    //@ts-ignore
    return error.response;
  }
};
