import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { workspaceActions } from './workspaceActions';
import authActions from '../app/action';
import {
  getWorkspacesReq,
  postWorkspaceEmailReq,
  postWorkspaceOTPReq,
  resendWorkspaceOTPReq
} from '../../library/services/workspaceReq';
import history from '../../library/helpers/history';
import { postWorkspaceEmailPayload, postWorkspaceOtpPayload } from './types';
import { workspaceSignin } from '../../library/services/0Auth';

export function* postWorkspaceEmailEffect() {
  yield takeEvery(
    workspaceActions.POST_WORKSPACE_EMAIL,
    function* ({ payload }: { type: string; payload: postWorkspaceEmailPayload }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(postWorkspaceEmailReq, payload);
        if (response) {
          yield all([
            put({
              type: workspaceActions.POST_WORKSPACE_EMAIL_SUCCESS
            })
          ]);
          sessionStorage.setItem('id_token', response?.data?.access_token);
          localStorage.setItem('id_token', response?.data?.access_token);
        }
      } catch (error: any) {
        yield all([
          put({
            type: workspaceActions.POST_WORKSPACE_EMAIL_FAILED,
            errorMessage: 'Something went wrong, please try after sometime.'
          })
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getWorkspaceListEffect() {
  yield takeEvery(workspaceActions.GET_WORKSPACE_LIST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getWorkspacesReq);
      if (response) {
        yield put({
          type: workspaceActions.GET_WORKSPACE_LIST_SUCCESS,
          workspaceList: response.data.data.listOfWorkspace
        });
      }
    } catch (error: any) {
      yield put({
        type: workspaceActions.GET_WORKSPACE_LIST_FAILED,
        errorMessage: error.data.message
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postWorkspaceOTPEffect() {
  yield takeEvery(
    workspaceActions.POST_WORKSPACE_OTP,
    function* ({ payload }: { type: string; payload: postWorkspaceOtpPayload }) {
      try {
        const response = yield call(postWorkspaceOTPReq, payload);
        if (response) {
          yield all([
            put({
              type: workspaceActions.POST_WORKSPACE_OTP_SUCCESS,
              otpResendSuccess: response.data.message
            }),
            put({
              type: workspaceActions.CLOSE_OTP_MODAL
            })
          ]);
          history.push('/yourworkspaces');
        } else {
          yield put({
            type: workspaceActions.POST_WORKSPACE_OTP_FAILED,
            otpVerificationError: response.data.message
          });
        }
      } catch (error: any) {
        yield put({
          type: workspaceActions.POST_WORKSPACE_OTP_FAILED,
          otpVerificationError: error.data.message
        });
      }
    }
  );
}

export function* WorkspaceResendOtpEffect() {
  yield takeEvery(
    workspaceActions.PATCH_WORKSPACE_RESEND_OTP,
    function* ({ payload }: { type: string; payload: Record<string, any> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(resendWorkspaceOTPReq, payload);
        if (response) {
          yield put({
            type: workspaceActions.PATCH_WORKSPACE_RESEND_OTP_SUCCESS,
            otpResendSuccess: response?.data?.data?.message
          });
        } else {
          yield put({
            type: workspaceActions.PATCH_WORKSPACE_RESEND_OTP_FAILED,
            otpVerificationError: response?.message
          });
        }
      } catch (error) {
        yield put({
          type: workspaceActions.PATCH_WORKSPACE_RESEND_OTP_FAILED,
          otpVerificationError: error
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* authWorkspaceSigninEffect() {
  yield takeEvery(workspaceActions.POST_0AUTH_SIGNIN, function* ({ payload }: { type: string; payload: any }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response: any = yield call(workspaceSignin, payload);
      yield JSON.stringify(sessionStorage.setItem('id_token', response?.data?.data?.access_token));
      yield JSON.stringify(
        localStorage.setItem('id_token', response?.data?.data?.access_token)
      );
      history.push('/yourworkspaces');
    } catch (error: any) {
      yield put({
        type: workspaceActions.POST_0AUTH_SIGNIN_FAILED,
        errorMessage: error?.message
      });
      history.push(`/workspacesearch`);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(postWorkspaceEmailEffect),
    fork(getWorkspaceListEffect),
    fork(postWorkspaceOTPEffect),
    fork(WorkspaceResendOtpEffect),
    fork(authWorkspaceSigninEffect)
  ]);
}
