import targetImg from "../../assets/images/target-left-img.png";

export default function AuthPageFeatureImage(){
    return(
        <>
            <div className="left-img">
                <img src={targetImg} alt="Target-align poster"></img>
            </div>
        </>
    )
}