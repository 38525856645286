import styled from "styled-components";
import { palette } from "styled-theme";
import { borderRadius, transition } from "../../../library/helpers/style_utils";
import { variables } from "../../../assets/styles/variables";

export const TopBarWrapper = styled.div`
  .isomorphicTopBar {
    &.ant-layout-header {
      line-height: 70px !important;
      padding: 0px 40px 0px 268px !important;
      @media only screen and (max-width: 1199px) {
        padding: 0px 20px 0px 268px !important;
      }
    }

    display: flex;
    height: 114px !important;
    justify-content: space-between;
    background-color: #035592;
    filter: drop-shadow(0px 1px 4px rgba(34, 196, 242, 0.2));
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 50px 0px 280px !important;
    z-index: 1000;
    /* ${transition()}; */
    @media only screen and (max-width: 1600px) {
      height: 75px !important;
      padding: 0px 40px 0px 268px !important;
    }

    @media only screen and (max-width: 991px) {
      padding: 0px 30px 0px 280px !important;
    }
    @media only screen and (max-width: 767px) {
      padding: 0px 15px 0px 260px !important;
    }

    /* &.collapsed {
      padding: ${(props) =>
      props["data-rtl"] === "rtl" ? "0 109px 0 31px" : "0 50px 0 109px"};
      @media only screen and (max-width: 1199px) {
        padding: ${(props) =>
      props["data-rtl"] === "rtl" ? "0 109px 0 31px" : "0 30px 0 105px"};
      }
      @media only screen and (max-width: 991px) {
        padding: ${(props) =>
      props["data-rtl"] === "rtl" ? "0 109px 0 31px" : "0 25px 0 100px"};
      }
      @media only screen and (max-width: 767px) {
        padding: ${(props) =>
      props["data-rtl"] === "rtl"
        ? "0px 15px !important"
        : "0px 15px !important"};
      }
    } */

    .isoRight {
      float: right;
      display: flex;
      align-items: center;
      /* margin: 0px; */
      padding-inline-start: 0px;
      margin-left: auto;
      li {
        margin-left: 0;
        margin-right: 30px;
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: 0px;
          margin-right: 25px;
        }
        @media only screen and (max-width: 1199px) {
          margin-right: 0;
        }
        @media only screen and (max-width: 991px) {
          margin-right: 15px;
        }
        /* &.isoNotify {
          .isoIconWrapper {
            .ant-badge-multiple-words {
              padding: 0px 3px;
            }
            .one-digit .ant-badge-count {
              transform: translate(50%, -50%);
            }
            .two-digit .ant-badge-count {
              transform: translate(60%, -50%);
            }
            .three-digit .ant-badge-count {
              transform: translate(70%, -50%);
            }
            .ant-badge-count {
              font-size: 10px;
            }
            .icon-bell {
              font-size: 36px;
              @media only screen and (max-width: 1800px) {
                font-size: 30px;
              }
              @media only screen and (max-width: 1440px) {
                font-size: 24px;
              }
            }
            &:focus {
              span:before {
                color: ${variables.btn_bg_color};
              }
            }
          }
          .icon-bell {
            position: relative;
            .ant-badge {
              position: absolute;
            }
            .ant-badge-dot {
              width: 12px;
              height: 12px;
              min-width: 10px;
              background: #22c4f2;
              border: 3px solid white;
              top: 5px;
              right: 2px;
            }
          }
        } */
        &.isoUser {
          display: flex;
          align-items: center;
          margin: 0;
          h3 {
            margin: 0px;
            font-size: 18px;
            line-height: 21px;
            font-family: "Inter-Bold", sans-serif;
            color: #fff !important;
            margin-right: 5px;
            order: 1;
            @media only screen and (max-width: 1800px) {
              font-size: 16px;
              line-height: 19px;
            }
            @media only screen and (max-width: 1600px) {
              font-size: 15px;
              line-height: 18px;
            }
          }
          .isoImgWrapper {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            ${borderRadius("50%" as any)}
            margin-right: 15px;
            @media only screen and (max-width: 1199px) {
              height: 38px;
              width: 38px;
            }
            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
              object-fit: cover;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${palette("color", 3)};
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
              ${borderRadius("50%" as any)};
            }
            .isOnlyIcon:focus span:before {
              color: #000 !important;
            }
            button {
              cursor: pointer !important;
              .icon-arrow-down {
                cursor: pointer !important;
                &::before {
                  color: #fff !important;
                }
              }
              &:focus {
                .icon-arrow-down {
                  &::before {
                    color: #fff !important;
                  }
                }
              }
            }
            &:last-child {
              order: 3;
              margin-right: 0;
            }
          }
          span {
            display: flex;
            align-items: center;
            font-size: 8px;
            position: relative;
          }
        }
      }
    }
  }
`;
