import { vmsbCommonTypes } from "./vmsbCommonTypes";
import { vmsbCommonAction, vmsbCommonState } from "./types";
import { monthAbbreviation } from "../../config/constant/index";
import moment from "moment";
let initState: vmsbCommonState = {
  errorMessage: "",
  successMessage: "",
  toBeNotifiedUsers: [],
  myVisionData: [],
  myMissionData: [],
  myStrategyData: [],
  currentPage: 1,
  pageSize: 10,
  deleteSuccessMessage: "",
  deleteErrorMessage: "",
  vmsbStartPeriodData: [],
  vmsbEndPeriodData: [],
  totalMission: null,
  canAddVision: false,
};

const dateModifier = (date) => {
  const originalDate = new Date(date);
  const modifiedDate = `${originalDate.getDate()} ${
    monthAbbreviation[originalDate.getMonth()]
  }, ${originalDate.getFullYear()}`;
  return modifiedDate;
};

const myVisionDataModifier = (data) => {
  const myVisionData: any[] = [];
  if (data && data.length > 0) {
    data.forEach((item, index) => {
      const visionObj = {
        key: item.id,
        start_date: dateModifier(item.start_date),
        end_date: dateModifier(item.end_date),
        goal: item.goal_type || "Vision",
        nameTag: `VSN-${item.id}`,
        name: item.name,
        linked: item.okr_linked_count,
        period: item.number_of_periods,
        hold: false,
        star_OR_not: false,
        can_add_vision: item.can_add_vision,
        status:
          item?.period_status?.[0]?.integrated_status < 31
            ? "red"
            : item?.period_status?.[0]?.integrated_status > 70
            ? "green"
            : "yellow",
        defaultStatus: item?.period_status?.[0]?.integrated_status || "0.00",
        objective_mode: item.objective_mode,
        owner: {
          id: item.created_by.id,
          name: `${item.created_by.first_name} ${item.created_by.last_name}`,
          profile: item.created_by.profile_photo,
        },
        reminder:
          item?.reminders.length > 0
            ? moment
                .utc(item?.reminders?.[0]?.reminder_time)
                .format("DD MMM, YYYY")
            : " - ",
      };
      myVisionData.push(visionObj);
    });
  }
  return myVisionData;
};

const myMissionDataModifier = (data) => {
  const myMissionData: any[] = [];
  if (data && data.length > 0) {
    data.forEach((item, index) => {
      const missionObj = {
        key: item.id,
        start_date: dateModifier(item.start_date),
        end_date: dateModifier(item.end_date),
        goal: item.goal_type || "Mission",
        nameTag: `MSN-${item.id}`,
        name: item.name,
        linked: item.okr_linked_count,
        period: item.number_of_periods,
        on_hold: item?.period_status?.[0]?.on_hold,
        star_OR_not: false,
        hold: true,
        status:
          item?.period_status?.[0]?.integrated_status < 31
            ? "red"
            : item?.period_status?.[0]?.integrated_status > 70
            ? "green"
            : "yellow",
        defaultStatus: item?.period_status?.[0]?.integrated_status || "0.00",
        objective_mode: item.objective_mode,
        owner: {
          id: item.created_by.id,
          name: `${item.created_by.first_name} ${item.created_by.last_name}`,
          profile: item.created_by.profile_photo,
        },
        reminder:
          item?.reminders
            ? moment.utc(item?.reminders?.reminder_time).format("DD MMM, YYYY")
            : " - ",
        isStrategy: item?.strategy.length > 0 ? true : false,
        strMoreThanOne: item?.strategy.length === 1 ? false : true,
      };
      myMissionData.push(missionObj);
    });
  }
  return myMissionData;
};

const myStratagyDataModifier = (data) => {
  if (data && data.length > 0) {
    const myStratagyData: any[] = [];
    data.forEach((item, index) => {
      let singleStrategyData: any[] = [];
      const STRData = item.strategy;
      if (STRData && STRData.length > 0) {
        STRData.forEach((str, ind) => {
          let singleBenifitData: any[] = [];
          if (str.benefit && str.benefit.length > 0) {
            str.benefit.forEach((singlebenefit, ind) => {
              const benifitObj = {
                key: singlebenefit.id,
                start_date: dateModifier(singlebenefit.start_date),
                end_date: dateModifier(singlebenefit.end_date),
                goal: singlebenefit.goal_type || "Benefit",
                nameTag: `BEN-${singlebenefit.id}`,
                name: singlebenefit.name,
                linked: singlebenefit.okr_linked_count,
                period: singlebenefit.number_of_periods,
                on_hold: singlebenefit?.period_status?.[0]?.on_hold,
                star: singlebenefit?.star || 0,
                star_OR_not: true,
                hold: true,
                status:
                  singlebenefit?.period_status?.[0]?.integrated_status < 31
                    ? "red"
                    : singlebenefit?.period_status?.[0]?.integrated_status > 70
                    ? "green"
                    : "yellow",
                defaultStatus:
                  singlebenefit?.period_status?.[0]?.integrated_status || "0.00",
                objective_mode: singlebenefit.objective_mode,
                owner: {
                  id: singlebenefit.created_by.id,
                  name: `${singlebenefit.created_by.first_name} ${singlebenefit.created_by.last_name}`,
                  profile: singlebenefit.created_by.profile_photo,
                },
                reminder:
                  singlebenefit?.reminders
                    ? moment
                        .utc(singlebenefit?.reminders?.reminder_time)
                        .format("DD MMM, YYYY")
                    : "-",
                    
              };
              singleBenifitData.push(benifitObj);
            });
          } else {
            singleBenifitData = [];
          }
          const STRObj = {
            benefit: singleBenifitData,
            key: str.id,
            start_date: dateModifier(str.start_date),
            end_date: dateModifier(str.end_date),
            goal: str.goal_type || "Strategy",
            nameTag: `STR-${str.id}`,
            name: str.name,
            linked: str.okr_linked_count,
            period: str.number_of_periods,
            on_hold: str?.period_status?.[0]?.on_hold,
            star_OR_not: false,
            hold: true,
            status:
              str?.period_status?.[0]?.integrated_status < 31
                ? "red"
                : str?.period_status?.[0]?.integrated_status > 70
                ? "green"
                : "yellow",
            defaultStatus: str?.period_status?.[0]?.integrated_status || "0.00",
            objective_mode: str.objective_mode,
            owner: {
              id: str.created_by.id,
              name: `${str.created_by.first_name} ${str.created_by.last_name}`,
              profile: str.created_by.profile_photo,
            },
            reminder:
              str?.reminders
                ? moment
                    .utc(str?.reminders?.reminder_time)
                    .format("DD MMM, YYYY")
                : "-",
                isBenefit: str.benefit.length > 0 ? true : false
          };
          singleStrategyData.push(STRObj);
        });
      } else {
        singleStrategyData = [];
      }
      myStratagyData.push(singleStrategyData);
    });
    return myStratagyData;
  }
};

export default function authReducer(
  state = initState,
  action: vmsbCommonAction
) {
  switch (action.type) {

    case vmsbCommonTypes.GET_VISION_LIST_SUCCESS:
      const visionData = myVisionDataModifier(
        action?.payload?.visionData !== undefined ? [action?.payload?.visionData] : []
      );
      const canAddVision = action?.payload?.can_add_vision;

      return {
        ...state,
        canAddVision: canAddVision,
        myVisionData: visionData,
      };
    case vmsbCommonTypes.GET_MSB_LIST_SUCCESS:
      const missionData = myMissionDataModifier(action?.payload?.pagedItems);
      const strategyData = myStratagyDataModifier(action?.payload?.pagedItems);
      return {
        ...state,
        myMissionData: missionData,
        myStrategyData: strategyData,
        totalMission: action?.payload?.metaData?.totalItems,
      };

    case vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS:
      return {
        ...state,
      };
    case vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_SUCCESS:
      return {
        ...state,
        toBeNotifiedUsers: action?.toBeNotifiedUsers,
      };
    case vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_FAILED:
      return {
        ...state,
      };
    case vmsbCommonTypes.CHANGE_PAGE_NUM_SIZE:
      return {
        ...state,
        currentPage: action?.payload?.currentPage,
        pageSize: action?.payload?.pageSize,
      };

    case vmsbCommonTypes.DELETE_VISION_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action?.successMessage,
        deleteErrorMessage: "",
      };
    case vmsbCommonTypes.DELETE_VISION_FAILED:
      return {
        ...state,
        deleteErrorMessage: action?.errorMessage,
        deleteSuccessMessage: "",
      };

    case vmsbCommonTypes.DELETE_STRATEGY_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.successMessage,
        deleteErrorMessage: "",
      };
    case vmsbCommonTypes.DELETE_STRATEGY_FAILED:
      return {
        ...state,
        deleteErrorMessage: action.errorMessage,
        deleteSuccessMessage: "",
      };

    case vmsbCommonTypes.DELETE_BENEFIT_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.successMessage,
        deleteErrorMessage: "",
      };
    case vmsbCommonTypes.DELETE_BENEFIT_FAILED:
      return {
        ...state,
        deleteErrorMessage: action.errorMessage,
        deleteSuccessMessage: "",
      };

    case vmsbCommonTypes.DELETE_MISSION_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.successMessage,
        deleteErrorMessage: "",
      };
    case vmsbCommonTypes.DELETE_MISSION_FAILED:
      return {
        ...state,
        deleteErrorMessage: action.errorMessage,
        deleteSuccessMessage: "",
      };

    case vmsbCommonTypes.POST_VMSB_START_PERIOD_LIST_SUCCESS:
      return {
        ...state,
        vmsbStartPeriodData: action?.vmsbStartPeriodData,
      };
    case vmsbCommonTypes.POST_VMSB_END_PERIOD_LIST_SUCCESS:
      return {
        ...state,
        vmsbEndPeriodData: action?.vmsbEndPeriodData,
      };
    case vmsbCommonTypes.POST_VMSB_PERIOD_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case vmsbCommonTypes.REMOVE_DEL_ALERT:
      return {
        ...state,
        deleteSuccessMessage: "",
        deleteErrorMessage: "",
      };

    default:
      return state;
  }
}
