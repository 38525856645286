import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import authActions from "../app/action";

import {
  postinviteusers_linkReq,
  postaddmanually_userReq,
  getAllUserReq,
  patchDeleteUserReq,
  edituserReq,
} from "../../library/services/manageUserReq";
import { manageUserActions } from "./manageUserActions";
import { holdActions } from "../Holds/holdActions";
import { SubscriptionPlanTypes } from "../SubscriptionPlan/SubscriptionPlanTypes";

export function* postinviteusers_linkEffect() {
  yield takeEvery(
    manageUserActions.POST_INVITE_USERS_LINK,
    function* (payload) {
      try {
        const response = yield call(postinviteusers_linkReq, payload);
        if (response) {
          yield put({
            type: manageUserActions.POST_INVITE_USERS_LINK_SUCCESS,
            payload: response,
          });
        } else {
          yield put({
            type: manageUserActions.POST_INVITE_USERS_LINK_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: manageUserActions.POST_INVITE_USERS_LINK_FAILED,
          payload: error.message,
        });
      }
    }
  );
}
export function* postaddmanually_userEffect() {
  yield takeEvery(
    manageUserActions.POST_ADD_MANUALLY_USER,
    function* (payload) {
      try {
        const response = yield call(postaddmanually_userReq, payload);
        if (response) {
          yield put({
            type: manageUserActions.POST_ADD_MANUALLY_USER_SUCCESS,
            payload: response,
          });
        } else {
          yield put({
            type: manageUserActions.POST_ADD_MANUALLY_USER_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: manageUserActions.POST_ADD_MANUALLY_USER_FAILED,
          payload: error.message,
        });
      }
    }
  );
}

export function* getAllUserEffect() {
  yield takeEvery(
    manageUserActions.GET_ALL_USER,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, number | string>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getAllUserReq, payload);
        if (response?.data?.status) {
          yield put({
            type: manageUserActions.GET_ALL_USER_SUCCESS,
            allUserList: response?.data?.data.pagedItems,
            totalItems: response?.data?.data.metaData?.totalItems,
          });          
          yield put({
            type: SubscriptionPlanTypes.CHECK_USER_STATUS,
            payload: { page: 1, take: 10, status: null }
          });
        }
      } catch (error) {}
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* patchDeleteUserEffect() {
  yield takeEvery(
    manageUserActions.PATCH_DELETE_USER,
    function* ({ payload }: { type: string; payload: any }) {
      try {
        yield put(authActions.globalLoaderHandler(true));

        const allUserPayload = {
          page: payload?.page,
          take: payload?.take,
        };

        const response = yield call(patchDeleteUserReq, payload);
        if (response?.data?.status) {
          yield put({
            type: manageUserActions.PATCH_DELETE_USER_SUCCESS,
            successMessage: response.data.data.message,
          });
          yield put({
            type: manageUserActions.GET_ALL_USER,
            payload: allUserPayload,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        } else {
          yield put({
            type: manageUserActions.PATCH_DELETE_USER_FAILED,
            errorMessage: response.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: manageUserActions.PATCH_DELETE_USER_FAILED,
          errorMessage: error.data.message,
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* edituserEffect() {
  yield takeEvery(
    manageUserActions.EDIT_USER,
    function* ({ payload }: { type: string; payload }) {
      try {
        yield put(authActions.globalLoaderHandler(true));

        const allUserPayload = {
          page: payload?.page,
          take: payload?.take,
        };
        delete payload?.page;
        delete payload?.take;

        const response = yield call(edituserReq, payload);
        if (response) {
          yield put({
            type: manageUserActions.EDIT_USER_SUCCESS,
            successMessage: response.data.message,
          });
          yield put({
            type: manageUserActions.GET_ALL_USER,
            payload: allUserPayload,
          });
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        } else {
          yield put({
            type: manageUserActions.EDIT_USER_FAILED,
            errorMessage: response.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: manageUserActions.EDIT_USER_FAILED,
          errorMessage: error.data.message,
        });
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(postinviteusers_linkEffect),
    fork(postaddmanually_userEffect),
    fork(getAllUserEffect),
    fork(patchDeleteUserEffect),
    fork(edituserEffect),
  ]);
}
