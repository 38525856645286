 import { ServiceInstance, ServiceAuthInstance, apiUrl } from "./index";

// ANCHOR - Login

export const login = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `${apiUrl.LOGIN}`,
    data: {
      ...payload,
    },
  });
};

export const emailVerify = (payload) => {
  return ServiceInstance({
    method: "GET",
    url: `${apiUrl.EMAIL_VERIFY}?token=${payload}`,
  });
};

export const oAUthVerifyReq = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: apiUrl.OAUTH_VERIFY_URL,
    data: {
      ...payload,
    },
  });
};

export const resetPasswordReq = (payload, from) => {
  return ServiceInstance({
    method: "POST",
    url: `${apiUrl.RESET_PASSWORD}?token=${payload.link}`,
    data: {
      new_password: payload.new_password,
      confirm_password: payload.new_confirm_password,
      add_manual: payload.add_manual,
    },
  });
};

export const forgotPasswordReq = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: apiUrl.FORGOT_PASSWORD,
    data: {
      ...payload,
    },
  });
};

// ANCHOR - Signup
export const signup = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `${apiUrl.SIGNUP}?token=${payload.token}`,
    data: {
      email: payload.email,
    },
  });
};

//Admin - Signup
export const signupAdminReq = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: "/master/signUp_executive",
    data: {
      ...payload,
    },
  });
};
//google login
export const googleLogin = (payload) => {
  return ServiceInstance({
    method: "GET",
    url: payload.type.link,
  });
};
//facebook login
export const facebookLogin = (payload) => {
  return ServiceInstance({
    method: "GET",
    url: payload.type.link,
  });
};
// ANCHOR - Nemid
export const nemIdLogin = (payload) => {
  return ServiceInstance({
    method: "GET",
    url: payload.type.link,
    withCredentials: true,
  });
};

// ANCHOR - Forgot Password
export const forgotpassword = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: apiUrl.FORGOT_PASSWORD,
    data: {
      ...payload,
    },
  });
};

// ANCHOR - Verify
export const verify = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: apiUrl.VERIFY_EMAIL,
    data: {
      ...payload.type,
    },
  });
};

// ANCHOR - Reset Password
export const resetpassword = (payload) => {
  return ServiceInstance({
    method: "POST",
    data: {
      ...payload,
    },
  });
};

//change password
export const changepassword = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: apiUrl.CHANGE_PASSWORD,
    data: {
      ...payload,
    },
  });
};

// ANCHOR - Logout
export const logout = () => {
  return ServiceAuthInstance({
    method: "POST",
  });
};

// ANCHOR - Reset Forgot Password
export const resetForgotPassword = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `${apiUrl.RESET_FORGOT_PASSWORD}${payload.link}`,
    data: {
      new_password: payload.new_password,
      new_confirm_password: payload.new_confirm_password,
    },
  });
};

export const verifyAccountLink = (payload) => {
  return ServiceInstance({
    method: "GET",
    url: `${apiUrl.VERIFY_ACCOUNT_LINK}${payload.link}`,
  });
};

//Get manager list
export const getManagerReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `${apiUrl.MANAGER_LIST}`,
    params: payload,
  });
};

export const getDepartmentReq = () => {
  return ServiceInstance({
    method: "GET",
    url: `${apiUrl.DEPARTMENT_LIST}`,
  });
};
//departmentAddReq
export const departmentAddReq = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `${apiUrl.DEPARTMENT_ADD}`,
    data: {
      ...payload,
    },
  });
};
export const patchManagerInsertReq = (payload) => {
  let formData: any = new FormData();
  Object.keys(payload).forEach((key) =>
    payload[key] === undefined ? delete payload[key] : {}
  );
  Object.keys(payload).map((key) => formData.append(key + "", payload[key]));
  return ServiceAuthInstance({
    method: "POST",
    url: `${apiUrl.MANAGER_INSERT}?token=${payload.token}`,
    data: formData,
  });
};

// check portal_url exixst or not in sysytem
export const checkPortalURLinRoot = (payload) => {
  return ServiceInstance({
    method: "POST",
    url: `https://www.${process.env.REACT_APP_INSTANCE_ENV}.com/executive/api/master/portal_url_check`,
    data: { ...payload },
  });
};

//Check valid token for signup and reset password
export const checkValidTokenReq = (payload) => {
  return ServiceInstance({
    method: "post",
    url: `${apiUrl.VALIDATE_TOKEN}`,
    data: { ...payload },
  });
};

//Check user limit
export const checkUserLimitReq = () => {
  return ServiceInstance({
    method: 'POST',
    url: `${apiUrl.VALIDATE_USER_LIMIT}`
  });
};

// verify OTP and resend OPT
export const verifyOtpReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: `${apiUrl.VERIFY_OTP}`,
    data: {
      ...payload,
    },
  });
};

export const resendOtpReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: `${apiUrl.RESEND_OTP}/${payload.category}`,
  });
};

//Company info admin signup
export const comapnyInfoReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: `${apiUrl.COMPANY_INFO}`,
    data: {
      ...payload,
    },
  });
};
