import moment from 'moment-timezone';
import {
  dateFormatList,
  defaultMetaData,
  subscriptionPlanStatusOptions,
  subscriptionPlanTypes,
  transactionStatusOptions
} from '../../../config/constant';
import { subscriptionActionTypes } from './actiontypes';
import { subscriptionState, subscriptionStateAction } from './types';

let initState: subscriptionState = {
  errorMessage: '',
  successMessage: '',
  allPlans: [],
  activePlans: [],
  planDetails: {},
  subsHistoryList: [],
  totalSubscriptions: defaultMetaData
};

const activePlansModifier = (data) => {
  return data.filter((plan) => plan.status === 1);
};

const planDetailModifier = (data, id) => {
  return data.filter((plan) => plan.id === id)[0];
};

const subsHistoryModifier = (data, metaData) => {
  const subscriptions: any[] = [];
  metaData?.totalItems > 0 &&
    data.forEach((record) => {
      const planType = subscriptionPlanTypes.filter((planType) => planType.id === record.type);
      const transactionStatus =
        record.payment_id.length > 0 && transactionStatusOptions.filter((i) => i.id === record.payment_id[0].status);
      let subscription = {
        key: record.id,
        plan: record.plan_id,
        planType: record.type === null ? '-' : planType[0].type,
        license: record.total_licenses,
        amount: `$${record.total_amount}`,
        workspace: record.user_id !== null ? record.user_id.company_name : '',
        admin: record.user_id !== null ? record.user_id.created_by : '',
        joinedDate: moment(record.start_date).format(dateFormatList),
        paymentStatus: record.payment_id.length > 0 ? transactionStatus[0].type : 'pending',
        status: subscriptionPlanStatusOptions.filter((status) => status.id === record.status)[0].type,
        url: record.payment_id.length > 0 ? record.payment_id[0].receipt_url : ''
      };
      subscriptions.push(subscription);
    });
  return subscriptions;
};

export default function subscriptionReducer(state = initState, action: subscriptionStateAction) {
  switch (action.type) {
    case subscriptionActionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        allPlans: action.allPlans,
        activePlans: activePlansModifier(action.allPlans)
      };
    case subscriptionActionTypes.GET_PLAN_DETAILS:
      return {
        ...state,
        planDetails: planDetailModifier(state.activePlans, action.payload)
      };
    case subscriptionActionTypes.EDIT_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        successMessage: 'Plan updated successfully.'
      };
    case subscriptionActionTypes.SUBSCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        subsHistoryList: subsHistoryModifier(action.subsHistoryList, action.totalSubscriptions),
        totalSubscriptions: action.totalSubscriptions
      };
    case subscriptionActionTypes.PLAN_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        successMessage: ''
      };
    case subscriptionActionTypes.PLAN_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage
      };
    case subscriptionActionTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    default:
      return state;
  }
}
