import { ExecutiveSignupStep2Types } from "./ExecutiveSignupStep2Types";
import { ExecutiveSignupStep2Action, ExecutiveSignupStep2State } from "./types";
let initState: ExecutiveSignupStep2State = {
  errorMessage: "",
  companySlug: "",
  successMessage: "",
  companyUrl: "",
  CompanyIndrustry: [],
  isPortalSetupCompleted: false,
};
export default function authReducer(
  state = initState,
  action: ExecutiveSignupStep2Action
) {
  switch (action.type) {
    case ExecutiveSignupStep2Types.POST_CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companySlug: action.companySlug,
        successMessage: action.successMessage,
        companyUrl: action.companyUrl,
      };
    case ExecutiveSignupStep2Types.POST_CREATE_COMPANY_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ExecutiveSignupStep2Types.GET_COMPANY_INDRUSTRY_SUCCESS:
      return {
        ...state,
        CompanyIndrustry: action.CompanyIndrustry,
        errorMessageL: "",
      };
    case ExecutiveSignupStep2Types.GET_COMPANY_INDRUSTRY_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY:
      return {
        ...state,
        errorMessage: "",
      };

    case ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY_SUCCESS:
      return {
        ...state,
        companySlug: action.companySlug,
        errorMessage: "",
      };
    case ExecutiveSignupStep2Types.GET_PORTAL_SLUG_AVAILABLITY_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2:
      return {
        ...state,
      };
    case ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2_SUCCESS:
      sessionStorage.setItem("companySlug", action.companySlug);
      sessionStorage.setItem("successMessage", action.successMessage);
      return {
        ...state,
        companySlug: action.companySlug,
        successMessage: action.successMessage,
      };
    case ExecutiveSignupStep2Types.POST_EXECUTIVESIGNUPSTEP2_FAILED:
      return {
        ...state,
      };

    case ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS:
      return {
        ...state,
        isPortalSetupCompleted: false,
      };
    case ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS_SUCCESS:
      return {
        ...state,
        isPortalSetupCompleted: true,
      };
    case ExecutiveSignupStep2Types.GET_COMPANY_PORTAL_STATUS_FAILED:
      return {
        ...state,
        isPortalSetupCompleted: false,
      };
      
    case ExecutiveSignupStep2Types.REMOVE_ALERT:
      return {
        ...state,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
}
