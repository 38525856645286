import { combineReducers } from 'redux';
import SubscriptionPlanReducer from '../SubscriptionPlan/reducer';
import stretegyReducer from '../strategy/reducer';
import benefitReducer from '../benefit/reducer';
import missionReducer from '../mission/reducer';
import visionReducer from '../vision/reducer';
import VmsbOkrCommonReducer from '../VmsbOkrCommon/reducer';
import organizationSettings from '../organizationSettings/reducer';
import portalRatingSettings from '../portalRatingSettings/reducer';
import ExecutiveSignupStep2Reducer from '../ExecutiveSignupStep2/reducer';
import StarReducer from '../Star/reducer';
import ThemeSwitcher from '../themeSwitcher/reducer';
import App from '../app/reducer';
import authReducer from '../auth/reducer';
import KeyResult from '../KeyResult/reducer';
import Task from '../Tasks/reducer';
import ProfileReducer from '../profile/reducer';
import ManageUser from '../manageUser/reducer';
import ObjectiveReducer from '../Objective/reducer';
import periodReducer from '../period/reducer';
import holdReducer from '../Holds/reducer';
import roles from '../rolesAndPermission/reducer';
import golesReducer from '../Goals/reducer';
import commentReducer from '../Comments/reducer';
import organisationFiancialReducer from '../OrganisationFinaicalYear/reducer';
import NotificationReducer from '../Notification/reducer';
import vmsbReducer from '../vmsbCommon/reducer';
import CommonFiltersReducer from '../CommenFiters/reducer';
import kanbanReducer from '../kanban/reducer';
import scoreCardStatusReducer from '../scoreCardStatus/reducer';
import workspaceReducer from '../workspace/reducer';
import searchReducer from '../search/reducer';
import InstaMessageReducer from '../InstaMessage/reducer';
import dashboardReducer from '../Dashboard/reducer';
import calendarReducer from '../calendar/reducer';
import globalViewReducer from '../GlobalView/reducer';
import adminAuthReducer from '../Admin/auth/reducer';
import AdminDashboardReducer from '../Admin/Dashboard/dashboardReducer';
import WorkspaceUserReducer from '../Admin/WorkspaceUsers/reducer';
import WorkspaceReducer from '../Admin/Workspace/workspaceReducer';
import AdminFiltersReducer from '../Admin/Filters/reducer';
import TooltipReducer from '../Admin/Tooltip/reducer';
import PromoCodeReducer from '../Admin/Promocode/reducer';
import configReducer from '../Admin/Configuration/reducer';
import subscriptionReducer from '../Admin/subscription/reducer';
import globalReducer from '../global/reducer';

const rootReducer = combineReducers({
  SubscriptionPlanReducer,
  stretegyReducer,
  missionReducer,
  benefitReducer,
  visionReducer,
  VmsbOkrCommonReducer,
  portalRatingSettings,
  ExecutiveSignupStep2Reducer,
  StarReducer,
  App,
  Auth: authReducer,
  ThemeSwitcher,
  organizationSettings,
  KeyResult,
  ProfileReducer,
  Task,
  ManageUser,
  ObjectiveReducer,
  periodReducer,
  holdReducer,
  golesReducer,
  commentReducer,
  organisationFiancialReducer,
  roles,
  NotificationReducer,
  vmsbReducer,
  CommonFiltersReducer,
  kanbanReducer,
  scoreCardStatusReducer,
  workspaceReducer,
  InstaMessageReducer,
  searchReducer,
  dashboardReducer,
  calendarReducer,
  globalViewReducer,
  globalReducer,

  //!SECTION SUPER ADMIN REDUCERS
  adminAuthReducer,
  AdminDashboardReducer,
  WorkspaceUserReducer,
  WorkspaceReducer,
  AdminFiltersReducer,
  TooltipReducer,
  PromoCodeReducer,
  configReducer,
  subscriptionReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
