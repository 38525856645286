interface PUBLIC_ROUTE_TYPE {
  LANDING: string;
  VERIFY_OAUTH_GOOGLE: string;
  VERIFY_OAUTH_MICROSOFT: string;
  SIGN_UP_EMPLOYEE_1: string;
  SIGN_UP_EMPLOYEE_2_DEPT: string;
  CREDIT_CARD_INFO: string;
  FORGET_PASSWORD: string;
  RESET_PASSWORD: string;
  SUBSCRIPTION_PLAN: string;
  SUBSCRIPTION_PLAN_DETAILS: string,
  SETTING: string;
  PORTAL_SUCCESS: string;
  PAYMENT: string;
  BILLING: string;
  VERIFY_CARD: string;
  VERIFY_EMAIL: string;
  SIGNIN: string;
  OBJECTIVE: string;
  KEYRESULT: string;
  TASK: string;
  VISION: string;
  MISSION: string;
  BENEFIT: string;
  STRATEGY: string;
  PAGE_404: string;
  SET_PASSWORD: string;
  PRIVACY_POLICY: string;
  TERMS_POLICY: string;
  CALENDAR: string;
}

interface SUPER_ADMIN_PUBLIC_ROUTE_TYPE {
    ADMIN_SIGN_IN: string;
    FORGET_PASSWORD: string;
    RESET_PASSWORD: string;
}

interface PRIVATE_ROUTE_TYPE {
  DASHBOARD: string;
  LISTVIEW: string;
  GLOBAL_VIEW:string;
  TOOLS_FINANCIAL_YEAR: string;
  PROFILE: string;
  VIEW_PROFILE: string;
  VMSB: string;
  KANBANVIEW: string;
}

interface ADMIN_ROUTE_TYPE {
  WORKSPACE_SEARCH: string;
  WORKSPACE_RESULT: string;
  SIGN_UP_ADMIN: string;
  SIGN_UP_ADMIN_1: string;
  SIGN_UP_ADMIN_2_MANUAL: string;
  SIGN_UP_ADMIN_2: string;
  PAGE_404: string;
}

export const PUBLIC_ROUTE: PUBLIC_ROUTE_TYPE = {
  LANDING: '/',
  VERIFY_OAUTH_GOOGLE: '/auth/:authid/redirect',
  VERIFY_OAUTH_MICROSOFT: '/auth/:authid/redirect',
  VERIFY_EMAIL: '/auth/:authid',
  SIGN_UP_EMPLOYEE_1: '/employeesignup',
  SIGN_UP_EMPLOYEE_2_DEPT: '/employeeinfo',
  CREDIT_CARD_INFO: '/creditcardinfo',
  FORGET_PASSWORD: '/forgetpassword',
  RESET_PASSWORD: '/resetpassword',
  SUBSCRIPTION_PLAN: '/subscriptionplan',
  SUBSCRIPTION_PLAN_DETAILS: '/subscriptionPlanDetails',
  SETTING: '/setting',
  PORTAL_SUCCESS: '/portalsuccess',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_POLICY: '/terms-and-conditions',
  PAYMENT: '/payment',
  BILLING: '/billinginfo',
  VERIFY_CARD: '/verified/:sessionid/:authorization',
  SIGNIN: '/login',
  OBJECTIVE: '/comments/viewobjective/:id',
  KEYRESULT: '/comments/viewkey/:id',
  TASK: '/comments/viewtask/:id',
  VISION: '/comments/viewvision/:id',
  MISSION: '/comments/viewmission/:id',
  BENEFIT: '/comments/viewbenefit/:id',
  STRATEGY: '/comments/viewstrategy/:id',
  PAGE_404: '/404',
  SET_PASSWORD: '/client/verify',
  CALENDAR: '/calendar',
};

export const ADMIN_ROUTES: ADMIN_ROUTE_TYPE = {
  WORKSPACE_SEARCH: "/workspacesearch",
  WORKSPACE_RESULT: "/yourworkspaces",
  SIGN_UP_ADMIN: "/",
  SIGN_UP_ADMIN_1: "/admindetails",
  SIGN_UP_ADMIN_2_MANUAL: "/companyinfo",
  SIGN_UP_ADMIN_2: "/createcompany",
  PAGE_404: "/404",
};

export const PRIVATE_ROUTE: PRIVATE_ROUTE_TYPE = {
  DASHBOARD: "/",
  LISTVIEW: "/mygoalslistview",
  GLOBAL_VIEW:"/globalview",
  TOOLS_FINANCIAL_YEAR: "./configuration",
  PROFILE: "./profile",
  VIEW_PROFILE: "/viewprofile",
  VMSB: "./vmsb",
  KANBANVIEW: "./kanbanview",
  // EDITOBJECT: "/editobjective",
};

export const SUPER_ADMIN_PUBLIC_ROUTE: SUPER_ADMIN_PUBLIC_ROUTE_TYPE = {
  ADMIN_SIGN_IN: '/superadmin/login',
  FORGET_PASSWORD: '/superadmin/forgetPassword',
  RESET_PASSWORD: "/superadmin/resetpassword",
}
