import { ServiceAuthInstance } from "./index";

export const getProfileReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/profile/profile",
  });
};

export const getCompanyIndustryReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/organization/company_industry",
  });
};

export const postUserManuallyReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/add_manually_user",
    data: {
      ...payload,
    },
  });
};

export const postSharedUserReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/invite_users_link",
    data: {
      ...payload,
    },
  });
};

export const updateSharedProfileReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/tools_configuration/invite_users_link",
    data: {
      ...payload,
    },
  });
};

export const updatePasswordReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: "/profile/changepassword",
    data: {
      ...payload,
    },
  });
};

export const updateProfilePhotoReq = (payload) => {
  let formData = new FormData();
  formData.append("file", payload.file);
  return ServiceAuthInstance({
    method: "PATCH",
    url: "/profile/profilePhotoUpdate",
    data: formData,
  });
};
export const updateProfileReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: "/profile/profileUpdate",
    data: {
      ...payload,
    },
  });
};
export const postPointsRatingReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/profile/points_rating",
    data: {
      ...payload,
    },
  });
};
