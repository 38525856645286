import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authActionTypes } from './actionTypes';
import authActions from '../../app/action';
import { adminLoginReq, forgotPasswordReq, resetPasswordReq } from '../../../library/services/Admin/auth';
import history from '../../../library/helpers/history';
import { SUPER_ADMIN_PUBLIC_ROUTE } from '../../../route.constants';

export function* adminLoginEffect() {
  yield takeEvery(
    authActionTypes.ADMIN_LOGIN_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(adminLoginReq, payload);
        if (response) {
          yield put({
            type: authActionTypes.ADMIN_LOGIN_SUCCESS,
            accessToken: response.data.access_token
          });
          localStorage.setItem('admin_accessToken', response.data.access_token);
          localStorage.setItem('adminData', JSON.stringify(response.data.user));
          history.push('/superadmin/dashboard/home');
        } else {
          yield put({ type: authActionTypes.ADMIN_LOGIN_FAILED, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: authActionTypes.ADMIN_LOGIN_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* adminForgotPassEffect() {
  yield takeEvery(
    authActionTypes.FORGOT_PASSWORD_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(forgotPasswordReq, payload);
        if (response) {
          yield put({ type: authActionTypes.FORGOT_PASSWORD_SUCCESS, successMessage: response.message });
        } else {
          yield put({ type: authActionTypes.FORGOT_PASSWORD_FAILED, errorMessage: response.data.message });
        }
      } catch (error) {
        yield put({ type: authActionTypes.FORGOT_PASSWORD_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* adminResetPasswordEffect() {
  yield takeEvery(
    authActionTypes.RESET_PASSWORD_REQUEST,
    function* ({ payload }: { type: string; payload: Record<string, string> }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(resetPasswordReq, payload);
        if (response) {
          yield put({ type: authActionTypes.RESET_PASSWORD_SUCCESS, successMessage: response.data.message });
          history.push(`${SUPER_ADMIN_PUBLIC_ROUTE.ADMIN_SIGN_IN}`);
        } else {
          yield put({ type: authActionTypes.RESET_PASSWORD_FAILED, errorMessage: response.message });
        }
      } catch (error) {
        yield put({ type: authActionTypes.RESET_PASSWORD_FAILED, errorMessage: error });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* adminAuthSaga() {
  yield all([fork(adminLoginEffect), fork(adminForgotPassEffect), fork(adminResetPasswordEffect)]);
}
