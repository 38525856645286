import moment from "moment";
import {
  myGolePriority,
  myGoleType,
} from "../../config/constant/index";
import { GoalActionTypes } from "./actionTypes";
import {
  golesObj,
  GoalsState,
  keyResultObj,
  GoalAction,
  tasksDataTypes,
} from "./types";
let initState: GoalsState = {
  idToken: null,
  loggedInUser: "",
  authFlag: false,
  errorMessage: "",
  myGolesData: [],
  myGolesKeyResult: [],
  currentPage: 1,
  pageSize: 10,
  totalObjectives: null,
  userDetail: [],
  taskData: [],
  totalTaskItems: null,
  deleteSuccessMessage: null,
  deleteErrorMessage: null,
  statusChages: false,
  socket: false,
};

const myGolesDataModifier = (data) => {
  const myGolesData: golesObj[] = [];
  if (data && data.length > 0) {
    data.forEach((item, index) => {
      const golesObj: golesObj = {
        key: item.id,
        date: moment.utc(item.end_date).format("DD MMM, YYYY"),
        type: myGoleType[item.objective_type],
        objectiveTag: `OBJ-${item.id}`,
        objective: item.name,
        linked: item.i_linked_to
          ? `${item.i_linked_to_prefix}${item.i_linked_to}`
          : "-",
        priority: myGolePriority[item.priority],
        result: item.key_results_count,
        task: item.tasks_count,
        on_hold: item.on_hold,
        star: item.star,
        status:
          item.native_status < 31
            ? "red"
            : item.native_status > 70
            ? "green"
            : "yellow",
        defaultStatus: item.native_status,
        objective_mode: item.objective_mode,
        integratedStatus: item.integrated_status
      };
      myGolesData.push(golesObj);
    });
  }
  return myGolesData;
};

const myGolesKRDataModifier = (data) => {
  if (data && data.length > 0) {
    const myGolesKR: keyResultObj[][] = [];
    data.forEach((item, index) => {
      let singleKRData: keyResultObj[] = [];
      const keyResultData = item.key_results;
      if (keyResultData && keyResultData.length > 0) {
        keyResultData.forEach((kr, ind) => {
          const keyResultObj: keyResultObj = {
            key: kr.id,
            obj_id: item?.id,
            date: moment.utc(kr.end_date).format("DD MMM, YYYY"),
            type: "",
            objectiveTag: `KR-${kr.id}`,
            objective: kr.name,
            objective_type:item.objective_type,
            priority: myGolePriority[kr.priority],
            result: "",
            task: kr.tasks_count,
            on_hold: kr.on_hold,
            status:
              kr.native_status < 31
                ? "red"
                : kr.native_status > 70
                ? "green"
                : "yellow",
            defaultStatus: kr.native_status,
            KrDummyValue: 0,
            isChangeable: kr.isChangeable,
          };
          singleKRData.push(keyResultObj);
        });
      } else {
        singleKRData = [];
      }
      myGolesKR.push(singleKRData);
    });
    return myGolesKR;
  }
};

const taskDataModifier = (data) => {
  const tasksData: tasksDataTypes[] = [];
  if (data && data.length > 0) {
    data.forEach((item, index) => {
      const signleTaskObj: tasksDataTypes = {
        key: item.id,
        date: moment.utc(item.due_date).format("DD MMM, YYYY"),
        type: "",
        task: `TSK-${item.id}`,
        disp: item.title,
        linked: `KR-${item.key_result}`,
        comment: item.comments,
        assign: {
          name: `${item.created_by.first_name} ${item.created_by.last_name}`,
          profile: item.created_by.profile_photo,
        },
        status: item.status
      };
      tasksData.push(signleTaskObj);
    });
  }
  return tasksData;
};

export default function golesReducer(state = initState, action: GoalAction) {
  switch (action.type) {
    case GoalActionTypes.POST_MYGOALS:
      return {
        ...state,
        socket: false,
      };
    case GoalActionTypes.POST_MYGOALS_SUCCESS:
      const golesData = myGolesDataModifier(
        action.payload.data.data.objectiveData
      );
      const golesKRData = myGolesKRDataModifier(
        action.payload.data.data.objectiveData
      );
      return {
        ...state,
        myGolesData: golesData,
        myGolesKeyResult: golesKRData,
        totalObjectives: action.payload.data.data.metaData.totalItems,
        statusChages: false,
      };
    case GoalActionTypes.POST_MYGOALS_FAILED:
      return {
        ...state,
      };

    case GoalActionTypes.POST_MY_ORG_CHART:
      return {
        ...state,
      };
    case GoalActionTypes.POST_MY_ORG_CHART_SUCCESS:
      const orgData = myGolesDataModifier(
        action.payload.data.data.objectiveData
      );
      const orgKRData = myGolesKRDataModifier(
        action.payload.data.data.objectiveData
      );
      return {
        ...state,
        myGolesData: orgData,
        myGolesKeyResult: orgKRData,
        totalObjectives: action.payload.data.data.metaData.totalItems,
        userDetail: action.payload.data.data.usersList,
        statusChages: false,
      };
    case GoalActionTypes.POST_MY_ORG_CHART_FAILED:
      return {
        ...state,
      };

    case GoalActionTypes.POST_ALL_TASK:
      return {
        ...state,
      };
    case GoalActionTypes.POST_ALL_TASK_SUCCESS:
      const modifiedTaskData = taskDataModifier(
        action.payload.data.data.tasksData
      );
      return {
        ...state,
        taskData: modifiedTaskData,
        totalTaskItems: action.payload.data.data.metaData.totalItems,
      };
    case GoalActionTypes.POST_ALL_TASK_FAILED:
      return {
        ...state,
      };

    case GoalActionTypes.DELETE_OBJECTIVE:
      return {
        ...state,
        deleteSuccessMessage: "",
        deleteErrorMessage: "",
      };
    case GoalActionTypes.DELETE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.payload.data.data.message,
      };
    case GoalActionTypes.DELETE_OBJECTIVE_FAILED:
      return {
        ...state,
        deleteErrorMessage: action.payload,
      };

    case GoalActionTypes.DELETE_KEY_RESULT:
      return {
        ...state,
        deleteSuccessMessage: "",
        deleteErrorMessage: "",
      };
    case GoalActionTypes.DELETE_KEY_RESULT_SUCCESS:
      return {
        ...state,
        deleteSuccessMessage: action.payload.data.data.message,
      };
    case GoalActionTypes.DELETE_KEY_RESULT_FAILED:
      return {
        ...state,
        deleteErrorMessage: action.payload,
      };

    case GoalActionTypes.CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
      };

    case GoalActionTypes.REMOVE_ALERT:
      return {
        ...state,
        deleteSuccessMessage: "",
        deleteErrorMessage: "",
      };

    case GoalActionTypes.GET_KR_OBJ_CAL_STATUS:
      return {
        ...state,
        statusChages: true,
      };

    case GoalActionTypes.GET_KR_OBJ_CAL_STATUS_SUCCESS:
      return {
        ...state,
        socket: true,
        // statusChages: action.payload.message,
      };
    default:
      return state;
  }
}
