import styled, { css } from 'styled-components';
import { variables } from '../../assets/styles/variables';
import WithDirection from '../../library/helpers/rtl';

const hasValidNum = css`
  li:nth-child(3) {
    color: #00b95d;
    list-style-type: none;
    span {
      display: inline;
    }
  }
`;
const hasValidChar = css`
  li:nth-child(2) {
    color: #00b95d;
    list-style-type: none;
    span {
      display: inline;
    }
  }
`;

const passMatched = css`
  li:first-child {
    color: #00b95d;
    list-style-type: none;
    span {
      display: inline;
    }
  }
`;

const SignUpEmployeeStyleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  & > div {
    flex: 0 0 50%;
  }
  &.admin-step1 {
    .or-section {
      margin: 35px 0px;
      @media only screen and (max-width: 1600px) {
        margin: 30px 0px;
      }
      @media only screen and (max-width: 1440px) {
        margin: 25px 0px;
      }
      @media only screen and (max-width: 1199px) {
        margin: 20px 0px;
      }
    }
    .signup-process {
      width: 180px !important;
    }
  }
  &.signup-emp-step2 {
    .isoSignUpContent {
      max-width: 100%;
      &.admin-step2-manual {
        max-width: 100%;
      }
      .signup-process.last-step {
        width: 16%;
      }
      .form-fields {
        padding: 0px 120px;
        @media only screen and (max-width: 1199px) {
          padding: 0px 60px;
        }
        @media only screen and (max-width: 991px) {
          padding: 0px 30px;
        }
        .ant-form-item:last-child {
          margin-bottom: 35px;
          @media only screen and (max-width: 1199px) {
            margin-bottom: 30px;
          }
          // @media only screen and (max-width: 991px)
          // {
          //   margin-bottom: 25px;
          // }
        }
        .sector {
          width: 48%;
          @media only screen and (max-width: 991px) {
            width: 100%;
          }
        }
        .ind-grp {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-between;
            .ant-select {
              width: 48%;
              text-align: left;
            }
          }
        }
      }
      .signup-options {
        width: 380px;
        margin: auto;
        position: relative;
        @media only screen and (max-width: 1199px) {
          width: 350px;
        }
        @media only screen and (max-width: 991px) {
          width: 320px;
        }
        p {
          display: flex;
          align-items: baseline;
          & > .ant-form-item {
          }
          .terms-condition.privacy {
            width: auto;
            white-space: nowrap;
            .ant-form-item-explain {
              position: absolute;
              width: max-content;
              bottom: -20px;
              left: 60%;
              @media only screen and (max-width: 1199px) {
                bottom: -37px;
              }
            }
          }
        }
      }
      .small-fields {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @media only screen and (max-width: 991px) {
          flex-direction: column;
        }
        .ant-form-item {
          width: 48%;
          @media only screen and (max-width: 991px) {
            width: 100%;
          }
          .ant-select {
            width: 100% !important;
          }
        }
      }
    }
    &.signup-emp-step3 {
      p.redirect {
        justify-content: center;
        span {
          margin: 0px 5px;
        }
      }
    }
  }

  .isoSignUpContentWrapper {
    width: 50%;
    height: 100%;
    overflow-y: auto;
    /* overflow-x: hidden; */
    z-index: 10;
    position: relative;
    background-color: #fff;
    padding: 50px 0px;
    box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .isoSignUpContent {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: #ffffff;
    max-width: 380px;
    margin: auto;
    &.admin-step2-manual {
      max-width: 100%;
      margin: auto 120px;
      @media only screen and (max-width: 1600px) {
        margin: auto 80px;
      }
      @media only screen and (max-width: 1440px) {
        margin: auto 60px;
      }
      @media only screen and (max-width: 1199px) {
        margin: auto 40px;
      }
      @media only screen and (max-width: 991px) {
        margin: auto 30px;
      }
      .ant-radio-group .ant-radio-wrapper {
        margin-right: 27px !important;
      }
      .ant-form-item-label {
        white-space: normal;
      }
      .ant-radio-inner {
        border-color: #b8b8b8;
      }
      .ant-radio-wrapper-checked {
        .ant-radio-inner {
          border-color: #035592;
        }
      }
      .cmn-btn {
        max-width: 352px;
        margin: auto;
      }
    }
    @media only screen and (max-width: 1199px) {
      max-width: 330px;
    }
    @media only screen and (max-width: 767px) {
      max-width: 300px;
      padding: 0px 15px;
    }

    .isoLogoWrapper {
      margin-bottom: 45px;
      @media only screen and (max-width: 1199px) {
        margin-bottom: 35px;
      }
      @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
    .title {
      text-align: center;
      margin: 0px 0px 35px;
      @media only screen and (max-width: 1440px) {
        margin-bottom: 30px;
      }
      @media only screen and (max-width: 1199px) {
        margin-bottom: 25px;
      }
      &.company {
        color: #2d2c2c;
      }
      &.use-id {
        text-align: left;
        span {
          color: #2d2c2c;
          margin-left: 5px;
        }
      }
      &.note {
        color: #9e9e9e;
        font-family: 'Inter Regular', sans-serif;
        font-style: italic;
        margin-bottom: 45px;
        @media only screen and (max-width: 1440px) {
          margin-bottom: 40px;
        }
        @media only screen and (max-width: 1199px) {
          margin-bottom: 35px;
        }
      }
    }

    .signup-process {
      display: flex;
      justify-content: space-between;
      width: 180px;
      margin: 0 auto 35px;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        width: calc(100% - 20px);
        height: 1px;
        top: 50%;
        background-color: #dbd8d8;
        transform: translate(0%, -50%);
        left: 20px;
      }
      div {
        &.fill {
          border: 1px solid #035592;
          span {
            background-color: #035592;
            color: #fff;
          }
        }
        &.remove-border {
          border: none;
        }
      }
      & > div {
        width: 32px;
        height: 32px;
        background: #fff;
        vertical-align: middle;
        border-radius: 100%;
        position: relative;
        border: 1px solid #fff;
        @media only screen and (max-width: 991px) {
          width: 30px;
          height: 30px;
        }

        span {
          background-color: #fff;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #035592;
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          border: 1px solid #035592;
          @media only screen and (max-width: 991px) {
            font-size: 13px !important;
            width: 23px;
            height: 23px;
          }
        }
      }
      @media only screen and (max-width: 1440px) {
        margin-bottom: 30px;
      }
      @media only screen and (max-width: 1199px) {
        margin-bottom: 25px;
      }
      &.last-step {
        &::before {
          background-color: #035592;
        }
        &.fill {
          background-color: #035592;
          span {
            color: #fff;
          }
        }
      }
    }

    .ant-alert {
      margin-bottom: 15px;
    }

    .isoSignUpForm {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      .hide-redirect {
        display: none;
      }
      &.createCompany {
        .terms-policy {
          display: flex;
          align-items: center;
          margin-bottom: 35px;
          position: relative;
          .privacy {
            width: auto;
          }
          .terms-condition {
            width: auto;
            margin-bottom: 0 !important;
            .ant-form-item-control {
              position: unset;
              .ant-form-item-explain-connected {
                position: absolute;
                bottom: -20px;
              }
            }
            .ant-checkbox-wrapper {
              .ant-checkbox {
                + span {
                  white-space: nowrap;
                  padding-right: 4px;
                }
              }
            }
          }
        }
        p {
          &:last-child {
            margin: 45px 0px 30px;
          }
        }
      }
      .profile-item {
        .ant-form-item-content {
          position: relative;
        }
        .redirect.remove-photo {
          margin: -12px;
          display: inline-block;
          position: absolute;
          right: calc(100% - 188px);
          bottom: 10px;
        }
      }
      .profile-photo {
        border-radius: 100%;
        .ant-upload-picture-card-wrapper {
          width: auto;
        }
        .ant-upload.ant-upload-select {
          vertical-align: bottom;
          margin-left: -40px;
          z-index: 9;
          position: relative;
        }
        .ant-upload-list-picture-card {
          display: inline-block;
          cursor: default;
        }
        .ant-upload-list-picture-card-container {
          margin: 0;
          border-radius: 100%;
          height: 80px;
          width: 80px;
          position: relative;
          z-index: 0;
          .ant-tooltip {
            display: none;
          }
          .ant-upload-list-item {
            padding: 0px;
            border: none;
            background-color: #e9e9e9;
            border-radius: 100%;
            .ant-upload-list-item-info {
              cursor: default;
              &:before {
                display: none;
              }
            }
            .ant-upload-list-item-thumbnail,
            .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
              margin: auto;
              border-radius: 100%;
              object-fit: cover;
              object-position: center;

              img {
                margin: auto;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 100%;
              }
            }

            .anticon {
              width: 100%;
              height: 100%;
              background: url(/static/media/user.0f2a2170.svg) no-repeat center;
              background-size: 30px;
              svg {
                display: none;
              }
            }
          }
        }
      }
      .ant-upload {
        margin: 0px;
      }
      .redirect {
        margin: 45px 0px 0px;
        @media only screen and (max-width: 1600px) {
          margin: 40px 0px 0px;
        }
        @media only screen and (max-width: 1440px) {
          margin: 35px 0px 0px;
        }
        @media only screen and (max-width: 1199px) {
          margin: 30px 0px 0px;
        }
        &:last-child {
          margin: 10px 0px 30px;
          @media only screen and (max-width: 1440px) {
            margin: 10px 0px 25px;
          }
          @media only screen and (max-width: 1199px) {
            margin: 10px 0px 20px;
          }
        }
        &.redirect-margin {
          margin: 45px 0px 0px;
          @media only screen and (max-width: 1600px) {
            margin: 40px 0px 0px;
          }
          @media only screen and (max-width: 1440px) {
            margin: 35px 0px 0px;
          }
          @media only screen and (max-width: 1199px) {
            margin: 30px 0px 0px;
          }
        }
      }
    }
    .ant-upload.ant-upload-select {
      display: inline-block;
      width: 40px;
      height: 40px;
      background-color: #035592;
      text-align: center;
      border-radius: 100%;
      position: absolute;
      left: 25%;
      top: 60%;
      border: none;
      img {
        width: 15px;
        vertical-align: text-top;
      }
    }
    .profile-photo-wrapper {
      position: relative;
      margin-bottom: 35px;
      .profile-photo {
        display: inline-block;
        background-color: #e9e9e9;
        border-radius: 100%;
        text-align: center;
        span.icon {
          text-align: center;
          font-size: 36px;
        }
      }
      .addphoto {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: #035592;
        text-align: center;
        border-radius: 100%;
        position: absolute;
        right: -20%;
        top: 60%;
        img {
          width: 15px;
          transform: translate(4%, 50%);
        }
      }
    }
    .form_label {
      span.anticon {
        margin-left: 5px;
        color: #9e9e9e;
      }
    }
  }
  .isoInputWrapper {
    .redirect-text {
      float: right;
    }

    .cmn-btn {
      margin-top: 10px;
      height: auto;
      &:focus,
      &:hover {
        border: 1px solid ${variables.btn_bg_color};
        color: ${variables.btn_bg_color};
        background-color: #fff;
      }

      &.btnOffice {
        @media only screen and (max-width: 1199px) {
          margin-bottom: 5px;
        }
      }
      &.microsoft {
        background-color: #2c75e8;
        border: 1px solid #2c75e8 !important;
        &:focus,
        &:hover {
          background-color: #fff;
          color: #2c75e8 !important;
          span:before {
            color: #2c75e8 !important;
          }
        }
        &:focus {
          text-decoration: none !important;
          span {
            text-decoration: none;
          }
        }
      }
      &.google {
        background-color: #db4c3e;
        border: 1px solid #db4c3e !important;
        &:hover,
        &:focus {
          background-color: #fff;
          color: #db4c3e !important;
          span:before {
            color: #db4c3e !important;
          }
        }
        &:focus {
          text-decoration: none !important;
          span {
            text-decoration: none;
          }
        }
      }
    }
    &.google-brand,
    &.ms-brand {
      a {
        width: 238px;
        height: 40px;
        text-align: left;
      }
    }
    &.google-brand {
      a {
        padding: 0 8px 0px 0px !important;
        border-radius: 2px;
        background-color: #4285f4;
        border: 1px solid #4285f4 !important;
        color: #fff;
        font-size: 14px;
        margin: 10px auto 5px;
        &:hover,
        &:focus {
          filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.084))
            drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.168));
          background: #fff;
          border-color: #fff !important;
          color: rgb(0 0 0 / 54%) !important;
          text-decoration: none;
        }
        img {
          background-color: #fff;
          padding: 10px;
          margin-right: 22px;
        }
      }
    }
    &.ms-brand {
      margin-bottom: 20px !important;
      a {
        padding: 0 12px 0 0 !important;
        border-radius: 0;
        background-color: #fff;
        border: 1px solid #8c8c8c !important;
        color: #5e5e5e;
        font-size: 14px;
        margin: 10px auto 5px;
        img {
          padding: 9px 20px 9px 12px;
        }
        &:hover,
        &:focus {
          background-color: #2f2f2f;
          color: #fff !important;
          border: 1px solid #2f2f2f !important;
          text-decoration: none;
        }
      }
    }
    .authSubtext {
      text-align: center;
      margin-bottom: 3px;
      color: #32386a;
      margin-top: 0px;
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      color: #b1b1b1;
    }
  }
  .ant-form-item {
    margin-bottom: 35px;
    &.department {
      margin-bottom: 25px;
    }
    &.manager-email label:after {
      left: 0px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 25px;
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      height: fit-content;
    }
    .ant-form-item-explain-error {
      top: 48px;
      left: 15px;
    }
    .validation {
      padding-left: 20px;
      margin-top: 30px;
      li {
        color: #9e9e9e;
        font-size: 16px;
        line-height: 19px;
        @media only screen and (max-width: 1600px) {
          font-size: 15px;
          line-height: 18px;
        }
        @media only screen and (max-width: 1440px) {
          font-size: 14px;
          line-height: 16px;
        }
        span {
          position: absolute;
          left: 0;
          display: none;
          &:before {
            font-size: 9px;
          }
        }
      }
      li:first-child {
        margin: 15px 0px;
      }
      li:nth-child(2) {
        margin: 15px 0px;
      }
    }
    &.confirm-password.ant-form-item-has-error,
    &.confirm-password.ant-form-item-has-success {
      @media only screen and (max-width: 1199px) {
      }
      @media only screen and (max-width: 991px) {
      }
      .ant-input {
        border-color: #919191;
      }

      .ant-form-item-explain.ant-form-item-explain-error,
      .ant-form-item-explain.ant-form-item-explain-success {
        top: 40px;
      }
      .validation.hasNum {
        ${hasValidNum}
      }
      .validation.validChar {
        ${hasValidChar}
      }
      .validation.passMatch {
        ${passMatched}
      }
    }
  }
  .or-section {
    text-align: center;
    position: relative;
    margin: 0px 0px 35px;
    @media only screen and (max-width: 1600px) {
      margin: 0px 0px 30px;
    }
    @media only screen and (max-width: 1440px) {
      margin: 0px 0px 25px;
    }
    @media only screen and (max-width: 1199px) {
      margin: 0px 0px 20px;
    }
    span {
      display: inline-block;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: #b8b8b8;
      background-color: #fff;
      padding: 0px 5px;
      @media only screen and (max-width: 1199px) {
        font-size: 15px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 45%;
      height: 2px;
      border-bottom: 0.5px solid #dbd8d8;
      top: 50%;
      transform: translate(0%, -50%);
    }
    @media only screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 25px;
    }
  }
  .ant-form-item-has-error {
    .password-grp {
      border: 1px solid red !important;
      & > .ant-input {
        border: none !important;
      }
    }
  }
  .password-grp {
    border: 1px solid #d5d5d5;
    padding: 1px 0px;
    border-radius: 5px;
    width: 100%;
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #d5d5d5;
    }
    &:focus {
      box-shadow: none !important;
    }
    &:focus-within {
      outline: 0.5px solid ${variables.focusColor};
    }
    input {
      width: 89%;
      padding: 10px;
      border: none !important;
      transform: translate(0px, -0.5px);
      &:focus {
        outline: none !important;
      }
      @media only screen and (max-width: 1199px) {
        width: 88%;
      }
    }
    a {
      width: 10%;
      border: none;
      vertical-align: sub;
      padding: 0px !important;
      background: transparent;
      transition: 0.5 linear;
      display: inline-block;
      width: 20px;
      transform: translate(10px, 3px);
      span {
        display: block;
        height: 24px;

        &.icon-show {
          transform: translate(0px, 4px);
          &:before {
            font-size: 15px;
          }
          // @media only screen and (max-width: 991px)
          // {
          //   transform: translate(0px, 5px);
          // }
        }
        &.icon-hide {
          transform: translate(0px, 5px);
          &:before {
            font-size: 19px;
          }
        }
        @media only screen and (max-width: 991px) {
          transform: translate(0px, 2px);
        }
      }
      &:hover {
        border: none !important;
        span {
          &:before {
            color: #919191;
          }
        }
      }
      &:focus {
        outline: none !important;
        span {
          &:before {
            color: #035592;
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        width: 11%;
      }
    }
  }
  .or-section:before {
    left: 0;
  }
  .or-section:after {
    right: 0;
  }

  a {
    span.icon {
      vertical-align: middle;
      margin-right: 15px;
      font-size: 20px;
    }
    &.btnOffice {
      background-color: #ff3300;
      margin-bottom: 5px;

      &:hover {
        background-color: #fff;
        border: 1px solid #ff3300 !important;
        color: #ff3300 !important;
        span {
          &:before {
            color: #ff3300 !important;
          }
        }
      }
    }

    &.btnGoogle {
      background-color: #4285f4;
      margin-top: 15px;

      &:hover {
        background-color: white;
        border: 1px solid #4285f4 !important;
        color: #4285f4 !important;
        span {
          &:before {
            color: #4285f4 !important;
          }
        }
      }
    }
  }

  .acceptTerm {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    .privacy {
      width: auto;
      /* white-space: nowrap; */
      .ant-checkbox-wrapper {
        .ant-checkbox {
          + span {
            padding-right: 4px;
          }
        }
      }
    }
  }
`;

export default WithDirection(SignUpEmployeeStyleWrapper);
