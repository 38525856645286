import { ServiceAuthInstance } from "./index";

export const postCurrentVisionReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/get_vision",
    data: {
      ...payload,
    },
  });
};

export const posParentHierarchyReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/get_kanban_view_titles",
    data: {
      ...payload,
    },
  });
};
export const postKanbanDetailsReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/get_kanban_view_details",
    data: {
      ...payload,
    },
  });
};
export const postOKRHierarchyReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/hierarchy",
    data: {
      ...payload,
    },
  });
};
export const postKanbanKRDetailsReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "kanban_view/get_key_results",
    data: {
      ...payload,
    },
  });
};
