import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { organizationTypes } from "./organizationSettingsTypes";
import authActions from "../app/action";
import {
  postfinancialyearReq,
  getfinancialyearReq,
  getOrganizationReq,
  postOrganizationReq,
  getTimezoneReq,
  postOrgFinanicalReq,
  patchCompanyLogoReq,
} from "../../library/services/organizationSettingsReq";
import { profileAction } from "../profile/actionTypes";
import { holdActions } from "../Holds/holdActions";
import { ExecutiveSignupStep2Types } from "../ExecutiveSignupStep2/ExecutiveSignupStep2Types";
import { SubscriptionPlanTypes } from "../SubscriptionPlan/SubscriptionPlanTypes";
import { subscriptionHistoryPayload } from "../../config/constant";
export function* postfinancialyearEffect() {
  yield takeEvery(
    organizationTypes.POST_FINANCIAL_YEAR,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postfinancialyearReq, payload);

        if (response) {
          yield all([
            put({
              type: organizationTypes.POST_FINANCIAL_YEAR_SUCCESS,
              organizationFinacialMessage: response.data?.data?.message,
            }),
            put({
              type: organizationTypes.GET_FINANCIAL_YEAR,
            }),
            put({
              type: profileAction.GET_PROFILE_REQUEST,
              payload: { loading: true },
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: organizationTypes.POST_FINANCIAL_YEAR_FAILED,
            organizationFinacialMessage: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getfinancialyearEffect() {
  yield takeEvery(organizationTypes.GET_FINANCIAL_YEAR, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getfinancialyearReq);
      if (response) {
        yield put({
          type: organizationTypes.GET_FINANCIAL_YEAR_SUCCESS,
          organizationFinacialData: response.data?.data,
        });
      } else {
        yield put({
          type: organizationTypes.GET_FINANCIAL_YEAR_FAILED,
          payload: response,
        });
      }
    } catch (error: any) {
      yield put({
        type: organizationTypes.GET_FINANCIAL_YEAR_FAILED,
        payload: error.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getOrganizationEffect() {
  yield takeEvery(
    organizationTypes.GET_ORGANIZATION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getOrganizationReq, payload);
        if (response) {
          yield all([
            put({
              type: organizationTypes.GET_ORGANIZATION_SUCCESS,
              portalSettingData: response.data.data.Perticular_user_data,
            }),
            put({
              type: organizationTypes.GET_TIMEZONE,
            }),
          ]);
        } else {
          yield put({
            type: organizationTypes.GET_ORGANIZATION_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: organizationTypes.GET_ORGANIZATION_FAILED,
          payload: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* postOrganizationEffect() {
  yield takeEvery(
    organizationTypes.POST_ORGANIZATION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | Blob>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postOrganizationReq, payload);
        if (response) {
          yield all([
            put({
              type: organizationTypes.POST_ORGANIZATION_SUCCESS,
              portalMessage: response?.data?.data?.message,
            }),
            put({
              type: organizationTypes.GET_ORGANIZATION,
            }),
            put({
              type: profileAction.GET_PROFILE_REQUEST,
              payload: { loading: true },
            }),
            put({
              type: SubscriptionPlanTypes.RENEW_CHECK
            }),
            put({
              type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
              payload: subscriptionHistoryPayload
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: organizationTypes.POST_ORGANIZATION_FAILED,
            portalMessage: error?.data,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getTimezoneEffect() {
  yield takeEvery(organizationTypes.GET_TIMEZONE, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getTimezoneReq);
      if (response) {
        yield put({
          type: organizationTypes.GET_TIMEZONE_SUCCESS,
          timezoneData: response.data.data.timezone,
        });
      }
    } catch (error: any) {
      yield put({
        type: organizationTypes.GET_TIMEZONE_FAILED,
        payload: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postorgfinancialyearEffect() {
  yield takeEvery(
    organizationTypes.POST_ORG_FINANCIAL_YEAR,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | any>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postOrgFinanicalReq, payload);
        if (response) {
          yield all([
            put({
              type: organizationTypes.POST_ORG_FINANCIAL_YEAR_SUCCESS,
              organizationFinacialMessage: response.data?.data.message,
            }),
            put({
              type: ExecutiveSignupStep2Types.POST_CREATE_COMPANY,
              payload: {
                created_by: localStorage.getItem("created_by"),
                company_slug: localStorage.getItem("company_slug"),
              },
            }),
          ]);
        }
      } catch (error: any) {
        yield put({
          type: organizationTypes.POST_ORG_FINANCIAL_YEAR_FAILED,
          payload: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* patchCompanyLogoEffect() {
  yield takeEvery(
    organizationTypes.PATCH_COMPANY_LOGO,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | Blob>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(patchCompanyLogoReq, payload);
        if (response) {
          yield all([
            put({
              type: organizationTypes.PATCH_COMPANY_LOGO_SUCCESS,
              portalMessage: response?.data?.data?.message,
            }),
            put({
              type: organizationTypes.GET_ORGANIZATION,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: organizationTypes.PATCH_COMPANY_LOGO_FAILED,
            portalMessage: error?.data,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postfinancialyearEffect),
    fork(getfinancialyearEffect),
    fork(getOrganizationEffect),
    fork(postOrganizationEffect),
    fork(getTimezoneEffect),
    fork(postorgfinancialyearEffect),
    fork(patchCompanyLogoEffect),
  ]);
}
