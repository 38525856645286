import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import action from "./action";
import authActions from "../app/action";
import { postOrgFinanicalReq } from "../../library/services/organizationSettingsReq";

export function* postorgfinancialyearEffect() {
  yield takeEvery(
    action.POST_ORG_FINANCIAL_YEAR,
    function* ({ type, payload }: { type: string; payload: any }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postOrgFinanicalReq, payload);
        if (response) {
          yield put({
            type: action.POST_ORG_FINANCIAL_YEAR_SUCCESS,
            payload: response.data?.data.message,
          });
        } else {
          yield put({
            type: action.POST_ORG_FINANCIAL_YEAR_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: action.POST_ORG_FINANCIAL_YEAR_FAILED,
          payload: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* orgfinanicalSaga() {
  yield all([fork(postorgfinancialyearEffect)]);
}
