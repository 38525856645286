import { VmsbOkrCommonTypes } from "./VmsbOkrCommonTypes";
import {VmsbOkrCommonAction, VmsbOkrCommonState} from "./types";
let initState: VmsbOkrCommonState = {
  errorMessage: "",
  scoreCardData : [],
  vision: '',
  canAddVision: false,
}
export default function authReducer(state = initState, action: VmsbOkrCommonAction) {
  switch (action.type) {
    case VmsbOkrCommonTypes.POST_SCORECARD:
     return {
       ...state
      };
    case VmsbOkrCommonTypes.POST_SCORECARD_SUCCESS:
     return {
       ...state,
       scoreCardData: action.scoreCardData,
      };
    case VmsbOkrCommonTypes.POST_SCORECARD_FAILED:
     return {
       ...state
      } ;
    case VmsbOkrCommonTypes.GET_CAN_ADD_VISION:
      return {
        ...state,
      };
    case VmsbOkrCommonTypes.GET_CAN_ADD_VISION_SUCCESS:
      return {
        ...state,
        vision: action.vision,
        canAddVision: action.canAddVision,
      };
    case VmsbOkrCommonTypes.GET_CAN_ADD_VISION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}
