import { visionTypes } from "./visionTypes";
import { visionAction, visionState } from "./types";
let initState: visionState = {
  errorMessage: "",
  successmessage: "",
  visionDetails: {},
  mission: {},
};
export default function authReducer(state = initState, action: visionAction) {
  switch (action.type) {
    case visionTypes.POST_VISION:
      return {
        ...state,
        successmessage: "",
        errorMessage: "",
      };
    case visionTypes.POST_VISION_SUCCESS:
      return {
        ...state,
        successmessage: action.successmessage,
      };
    case visionTypes.POST_VISION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case visionTypes.GET_VISION:
      return {
        ...state,
      };
    case visionTypes.GET_VISION_SUCCESS:
      return {
        ...state,
        visionDetails: action.visionDetails,
      };
    case visionTypes.GET_VISION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case visionTypes.PATCH_VISION:
      return {
        ...state,
        successmessage: "",
        errorMessage: "",
      };
    case visionTypes.PATCH_VISION_SUCCESS:
      return {
        ...state,
        successmessage: action.successmessage,
      };
    case visionTypes.PATCH_VISION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case visionTypes.GET_MISSION_IN_VISION:
      return {
        ...state,
      };
    case visionTypes.GET_MISSION_IN_VISION_SUCCESS:
      return {
        ...state,
        mission: action.mission,
      };
    case visionTypes.GET_MISSION_IN_VISION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case visionTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successmessage: "",
      };

    default:
      return state;
  }
}
