import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { commentActions } from "./actionTypes";
import authActions from "../app/action";
import {
  postCommentsReq,
  getCommentsReq,
  getCommentsNonOKRUserReq,
  postCommentsNonOKRUserReq,
  postvmsbCommentsReq,
  getvmsbCommentsReq,
} from "../../library/services/CommentsReq";
import { getTypeFilter } from "../../config/constant";
export function* postCommentsEffect() {
  yield takeEvery(
    commentActions.POST_COMMENTS,
    function* ({
      payload,
    }: {
      type: typeof commentActions.POST_COMMENTS;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const getData = {
          id: payload[payload.types],
          type: payload.url,
          page: 1,
          take: 5,
        };
        const response = yield call(postCommentsReq, payload);
        if (response.data.status) {
          yield put({
            type: commentActions.GET_COMMENTS,
            payload: getData,
          });
        } else {
          yield put({
            type: commentActions.POST_COMMENTS_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: commentActions.POST_COMMENTS_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postvmsbCommentEffect() {
  yield takeEvery(
    commentActions.POST_VMSB_COMMENTS,
    function* ({
      payload,
    }: {
      type: typeof commentActions.POST_VMSB_COMMENTS;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));

        const getData = {
          id: payload[payload.types],
          commentType: payload.types,
          type: getTypeFilter[payload.types],
          page: 1,
          take: 5,
        };
        const response = yield call(postvmsbCommentsReq, payload);
        if (response.data.status) {
          yield put({
            type: commentActions.GET_VMSB_COMMENTS,
            payload: getData,
          });
        } else {
          yield put({
            type: commentActions.POST_VMSB_COMMENTS_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: commentActions.POST_VMSB_COMMENTS_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getvmsbCommentEffect() {
  yield takeEvery(
    commentActions.GET_VMSB_COMMENTS,
    function* ({
      payload,
    }: {
      type: typeof commentActions.GET_VMSB_COMMENTS;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getvmsbCommentsReq, payload);
        if (response) {
          yield put({
            type: commentActions.GET_VMSB_COMMENTS_SUCCESS,
            payload: response?.data?.data,
            name: payload.type,
          });
        } else {
          yield put({
            type: commentActions.GET_VMSB_COMMENTS_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: commentActions.GET_VMSB_COMMENTS_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getCommentsEffect() {
  yield takeEvery(
    commentActions.GET_COMMENTS,
    function* ({
      payload,
    }: {
      type: typeof commentActions.GET_COMMENTS;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getCommentsReq, payload);
        if (response) {
          yield put({
            type: commentActions.GET_COMMENTS_SUCCESS,
            payload: response?.data?.data,
            name: payload.type,
          });
        } else {
          yield put({
            type: commentActions.GET_COMMENTS_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: commentActions.GET_COMMENTS_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getCommentsNonOKRUserEffect() {
  yield takeEvery(
    commentActions.GET_COMMENTS_NON_OKR_USER,
    function* ({
      payload,
    }: {
      type: typeof commentActions.GET_COMMENTS_NON_OKR_USER;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getCommentsNonOKRUserReq, payload);
        if (response) {
          yield put({
            type: commentActions.GET_COMMENTS_NON_OKR_USER_SUCCESS,
            name: response?.data?.responseResult?.name,
            description: response?.data?.responseResult?.description,
            comments: response?.data?.commentsData,
          });
        }
      } catch (error: any) {
        yield put({
          type: commentActions.GET_COMMENTS_NON_OKR_USER_FAILURE,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postObjCommentsOutsideUserEffect() {
  yield takeEvery(
    commentActions.POST_COMMENTS_NON_OKR_USER,
    function* ({
      payload,
    }: {
      type: typeof commentActions.POST_COMMENTS_NON_OKR_USER;
      payload: any;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postCommentsNonOKRUserReq, payload);
        if (response) {
          let getCommentNonUserTypePayload = {
            token: payload.token,
            id: payload.id,
            category: payload.category,
            page: payload.page,
            take: payload.take,
          };
          yield put({
            type: commentActions.GET_COMMENTS_NON_OKR_USER,
            payload: getCommentNonUserTypePayload,
          });
        }
      } catch (error: any) {
        yield put({
          type: commentActions.GET_COMMENTS_NON_OKR_USER_FAILURE,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postCommentsEffect),
    fork(getCommentsEffect),
    fork(getCommentsNonOKRUserEffect),
    fork(postObjCommentsOutsideUserEffect),
    fork(getvmsbCommentEffect),
    fork(postvmsbCommentEffect),
  ]);
}
