import { ServiceAuthInstance } from "./index";

export const postStarReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: `/list_view/add_star/${payload.id}`,
    data: {
      ...payload,
    },
  });
};

export const postBenefitStarReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: `/mission/add_star/${payload.id}`,
    data: {
      ...payload,
    },
  });
};
