import { ServiceAuthInstance } from "./index";

export const postKeyResultReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/list_view/add_key_results",
    data: {
      ...payload,
    },
  });
};
export const getKeyResultReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/get_key_results/${payload?.id}`,
  });
};
export const patchKeyResultReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: "/list_view/update_key_results",
    data: {
      ...payload,
    },
  });
};

export const getObjOfUserReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: "/list_view/get_objective_key_results",
  });
};

export const getKrCommetsReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/get_key_comments/${payload.id}?page=1&take=6`,
  });
};

export const getTaskInKeysReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/list_view/get_Task_in_keyresults/${payload.id}?page=${payload?.page}&take=${payload?.take}`,
  });
};
