export enum DashboardActionTypes {
  GET_DASHBOARD_ACTIVE_DATA_REQUEST = 'GET_DASHBOARD_ACTIVE_DATA_REQUEST',
  GET_DASHBOARD_ACTIVE_DATA_SUCCESS = 'GET_DASHBOARD_ACTIVE_DATA_SUCCESS',
  GET_DASHBOARD_ACTIVE_DATA_FAILED = 'GET_DASHBOARD_ACTIVE_DATA_FAILED',

  SCORECARD_REPORT_REQUEST = 'SCORECARD_REPORT_REQUEST',
  SCORECARD_REPORT_SUCCESS = 'SCORECARD_REPORT_SUCCESS',
  SCORECARD_REPORT_FAILED = 'SCORECARD_REPORT_FAILED',

  EXPORT_SCORECARD_REPORT_REQUEST = 'EXPORT_SCORECARD_REPORT_REQUEST',
  EXPORT_SCORECARD_REPORT_SUCCESS = 'EXPORT_SCORECARD_REPORT_SUCCESS',
  EXPORT_SCORECARD_REPORT_FAILED = 'EXPORT_SCORECARD_REPORT_FAILED',

  SUBSCRIPTION_REPORT_REQUEST = 'SUBSCRIPTION_REPORT_REQUEST',
  SUBSCRIPTION_REPORT_SUCCESS = 'SUBSCRIPTION_REPORT_SUCCESS',
  SUBSCRIPTION_REPORT_FAILED = 'SUBSCRIPTION_REPORT_FAILED',
  
  EXPORT_SUBSCRIPTION_REPORT_REQUEST = 'EXPORT_SUBSCRIPTION_REPORT_REQUEST',
  EXPORT_SUBSCRIPTION_REPORT_SUCCESS = 'EXPORT_SUBSCRIPTION_REPORT_SUCCESS',
  EXPORT_SUBSCRIPTION_REPORT_FAILED = 'EXPORT_SUBSCRIPTION_REPORT_FAILED',

  INDUSTRY_REPORT_REQUEST = 'INDUSTRY_REPORT_REQUEST',
  INDUSTRY_REPORT_SUCCESS = 'INDUSTRY_REPORT_SUCCESS',
  INDUSTRY_REPORT_FAILED = 'INDUSTRY_REPORT_FAILED',

  EXPORT_INDUSTRY_REPORT_REQUEST = 'EXPORT_INDUSTRY_REPORT_REQUEST',
  EXPORT_INDUSTRY_REPORT_SUCCESS = 'EXPORT_INDUSTRY_REPORT_SUCCESS',
  EXPORT_INDUSTRY_REPORT_FAILED = 'EXPORT_INDUSTRY_REPORT_FAILED',

  DASHBOARD_PAGE_CHANGES = 'DASHBOARD_PAGE_CHANGES'
}
