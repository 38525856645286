import { SubscriptionPlanTypes } from './SubscriptionPlanTypes';
import { SubscriptionPlanPayload } from './types';

export const postSubscriptionPlan = (payload: SubscriptionPlanPayload) => ({
  type: SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN,
  payload
});

export const saveCardAction = (payload: Record<string, string | number>) => ({
  type: SubscriptionPlanTypes.SAVE_CARD_REQUEST,
  payload
});

export const newCardAction = (payload: Record<string, string | number>) => ({
  type: SubscriptionPlanTypes.NEW_CARD_REQUEST,
  payload
});

export const cancelSubscriptionAction = (payload) => ({
  type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_REQUEST,
  payload
});

export const cancelSubscriptionOTP = () => ({
  type: SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_REQUEST_OTP
});

export const makePayment = () => ({
  type: SubscriptionPlanTypes.MAKE_PAYMENT_REQUEST
});

export const subscriberPayment = () => ({
  type: SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_REQUEST
});

export const savePaymentAddress = (payload) => ({
  type: SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_REQUEST,
  payload
});

export const additionalLicenseAction = (payload) => ({
  type: SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE,
  payload
});

export const subscriptionVerify = () => ({
  type: SubscriptionPlanTypes.VERIFY_SUBSCRIPTION
});

export const getSavedCard = () => ({
  type: SubscriptionPlanTypes.GET_SAVED_CARD
});

export const replaceCard = (payload) => ({
  type: SubscriptionPlanTypes.REPLACE_CARD,
  payload
});

export const getTransactionView = (payload) => ({
  type: SubscriptionPlanTypes.GET_TRANSACTION_VIEW,
  payload
});

export const subscriptionHistory = (payload) => ({
  type: SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY,
  payload
});

export const upgradePlan = (payload) => ({
  type: SubscriptionPlanTypes.UPGRADE_PLAN_REQUEST,
  payload
});

export const removeAlert = () => ({
  type: SubscriptionPlanTypes.REMOVE_ALERT
});

export const subscriptionOnlyAction = () => ({
  type: SubscriptionPlanTypes.SUBSCRIPTION_ONLY
});

export const inActiveUsers = (payload) => ({
  type: SubscriptionPlanTypes.DOWNGRADE_USER_REQUEST,
  payload
});

export const upgradeUsers = (payload) => ({
  type: SubscriptionPlanTypes.UPGRADE_USER_REQUEST,
  payload
});

export const renewCheck = () => ({
  type: SubscriptionPlanTypes.RENEW_CHECK
});

export const checkSubscription = () => ({
  type: SubscriptionPlanTypes.CHECK_SUBSCRIPTION
});

export const checkUserStatus = (payload) => ({
  type: SubscriptionPlanTypes.CHECK_USER_STATUS,
  payload
});

export const checkAvailablePlan = () => ({
  type: SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION
});

export const purchaseSubscriptionPlan = (payload) => ({
  type: SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN,
  payload: payload
});

export const deleteInvitedUsers = (payload) => ({
  type: SubscriptionPlanTypes.DELETE_INVITED_USER_REQUEST,
  payload
});

export const getCountryList = () => ({
  type: SubscriptionPlanTypes.GET_COUNTRY_LIST_REQUEST
});

export const getCountryById = (payload) => ({
  type: SubscriptionPlanTypes.GET_COUNTRY_BY_ID_REQUEST,
  payload
});

export const getStatesById = (payload) => ({
  type: SubscriptionPlanTypes.GET_STATES_BY_ID_REQUEST,
  payload,
});

export const getStatesList = (payload) => ({
  type: SubscriptionPlanTypes.GET_STATES_LIST_REQUEST,
  payload,
});

export const getCountryDropdown = () => ({
  type: SubscriptionPlanTypes.GET_COUNTRY_DROPDOWN_REQUEST
});

export const getCountryDataById = (payload) => ({
  type: SubscriptionPlanTypes.GET_COUNTRY_DATA_BY_ID_REQUEST,
  payload
});

export const getAllPlans = () => ({
  type: SubscriptionPlanTypes.GET_ALL_PLANS
});

export const getAllActivePlans = () => ({
  type: SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS
});

export const checkPromoCode = (payload) => ({
  type: SubscriptionPlanTypes.VERIFY_PROMO_CODE,
  payload
});

export const setError = (payload) => ({
  type: SubscriptionPlanTypes.SET_ERROR,
  payload
});

//verify promocode after company signup
export const verifyPromoCode = (payload) => ({
  type: SubscriptionPlanTypes.VERIFY_PROMO,
  payload
});

export const checkoutSession = (payload) => ({
  type: SubscriptionPlanTypes.CHECKOUT_SESSION,
  payload
})

export const verifyCard = () => ({
  type: SubscriptionPlanTypes.VERIFY_CARD,
})

export const verifyCardPostSignin = () => ({
  type: SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN,
})

export const checkoutSessionPostSignin = () => ({
  type: SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN,
})

export const getCardDetail = () => ({
  type: SubscriptionPlanTypes.GET_CARD_DETAILS,
})