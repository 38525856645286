/** @format */

import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { kanbanActions } from './kanbanActions';
import authActions from '../app/action';
import {
  postCurrentVisionPayload,
  posParentHierarchyPayload,
  postKanbanDetailsPayload,
  postOKRHierarchyPayload,
  postKanbanKRDetailsPayload,
} from './types';
import {
  postCurrentVisionReq,
  posParentHierarchyReq,
  postKanbanDetailsReq,
  postOKRHierarchyReq,
  postKanbanKRDetailsReq,
} from '../../library/services/kanbanReq';

export function* postCurrentVisionEffect() {
  yield takeEvery(
    kanbanActions.POST_CURRENT_VISION,
    function* ({
      payload,
    }: {
      type: string;
      payload: postCurrentVisionPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postCurrentVisionReq, payload);
        if (response) {
          yield all([
            put({
              type: kanbanActions.POST_CURRENT_VISION_SUCCESS,
              visionDetails: response?.data?.data?.visionData,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: kanbanActions.POST_CURRENT_VISION_FAILED,
            errorMessage: error?.data?.message,
          }),
          //   put({
          //     type: holdActions.SCROLL_TO_TOP,
          //   }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* posParentHierarchyEffect() {
  yield takeEvery(
    kanbanActions.POST_PARENT_HIERARCHY,
    function* ({
      payload,
    }: {
      type: string;
      payload: posParentHierarchyPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(posParentHierarchyReq, payload);
        if (response) {
          yield all([
            put({
              type: kanbanActions.POST_PARENT_HIERARCHY_SUCCESS,
              kanbanData: response?.data?.data,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: kanbanActions.POST_PARENT_HIERARCHY_FAILED,
            errorMessage: error?.data?.message,
          }),
          //   put({
          //     type: holdActions.SCROLL_TO_TOP,
          //   }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postKanbanDetailsEffect() {
  yield takeEvery(
    kanbanActions.POST_KANBAN_DETAILS,
    function* ({
      payload,
    }: {
      type: string;
      payload: postKanbanDetailsPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postKanbanDetailsReq, payload);
        if (response) {
          yield all([
            put({
              type: kanbanActions.POST_KANBAN_DETAILS_SUCCESS,
              kanbanBenObjData: response?.data?.data,
              page: payload?.page,
              component: payload?.component,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: kanbanActions.POST_KANBAN_DETAILS_FAILED,
            errorMessage: error?.data?.message,
          }),
          //   put({
          //     type: holdActions.SCROLL_TO_TOP,
          //   }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postOKRHierarchyEffect() {
  yield takeEvery(
    kanbanActions.POST_OKR_HIERARCHY,
    function* ({
      payload,
    }: {
      type: string;
      payload: postOKRHierarchyPayload;
    }) {
      try {
        // yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postOKRHierarchyReq, payload);
        if (response) {
          yield all([
            put({
              type: kanbanActions.POST_OKR_HIERARCHY_SUCCESS,
              kanbanHierchyData: response?.data?.data,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: kanbanActions.POST_OKR_HIERARCHY_FAILED,
            errorMessage: error?.data?.message,
          }),
          //   put({
          //     type: holdActions.SCROLL_TO_TOP,
          //   }),
        ]);
      }
      // yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postKanbanKRDetailsEffect() {
  yield takeEvery(
    kanbanActions.POST_KANBAN_KR_DETAILS,
    function* ({
      payload,
    }: {
      type: string;
      payload: postKanbanKRDetailsPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postKanbanKRDetailsReq, payload);
        if (response) {
          yield all([
            put({
              type: kanbanActions.POST_KANBAN_KR_DETAILS_SUCCESS,
              kanbanKRDetails: response?.data?.data,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: kanbanActions.POST_KANBAN_KR_DETAILS_FAILED,
            errorMessage: error?.data?.message,
          }),
          //   put({
          //     type: holdActions.SCROLL_TO_TOP,
          //   }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postCurrentVisionEffect),
    fork(posParentHierarchyEffect),
    fork(postKanbanDetailsEffect),
    fork(postOKRHierarchyEffect),
    fork(postKanbanKRDetailsEffect),
  ]);
}
