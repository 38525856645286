import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { vmsbCommonTypes } from "./vmsbCommonTypes";
import authActions from "../app/action";
import {
  getToBeNotifiedUsersReq,
  deleteVisionReq,
  getVisionlistReq,
  getMSBlistReq,
  postVMSBPeriodListReq,
  deleteBenefitReq,
  deleteStrayegyReq,
  deleteMissionReq,
} from "../../library/services/vmsbCommonReq";
import { holdActions } from "../Holds/holdActions";
import { VmsbOkrCommonTypes } from "../VmsbOkrCommon/VmsbOkrCommonTypes";

export function* getOthersNotifiedEffect() {
  yield takeEvery(vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getToBeNotifiedUsersReq);
      if (response) {
        yield put({
          type: vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_SUCCESS,
          toBeNotifiedUsers: response?.data?.data?.list,
        });
      } else {
        yield put({
          type: vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_FAILED,
          errorMessage: response.data.vision.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getVisionlistEffect() {
  yield takeEvery(vmsbCommonTypes.GET_VISION_LIST, function* (payload: any) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getVisionlistReq, payload?.payload);
      if (response) {
        yield put({
          type: vmsbCommonTypes.GET_VISION_LIST_SUCCESS,
          payload: response?.data?.data,
        });
      }
    } catch (error: any) {
      // yield put({
      //   type: vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_FAILED,
      //   errorMessage: error.data.message,
      // });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getMSBlistEffect() {
  yield takeEvery(vmsbCommonTypes.GET_MSB_LIST, function* (payload: any) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getMSBlistReq, payload?.payload);
      if (response) {
        yield put({
          type: vmsbCommonTypes.GET_MSB_LIST_SUCCESS,
          payload: response?.data?.data?.paginatedMsbData,
        });
      }
    } catch (error: any) {
      // yield put({
      //   type: vmsbCommonTypes.GET_TO_BE_NOTIFIED_USERS_FAILED,
      //   errorMessage: error.data.message,
      // });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export function* deleteVisionEffect() {
  yield takeEvery(vmsbCommonTypes.DELETE_VISION, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const listViewPayload = payload?.msb_ayload;
      const response = yield call(deleteVisionReq, payload);
      if (response) {
        yield all([
          put({
            type: vmsbCommonTypes.DELETE_VISION_SUCCESS,
            successMessage: response?.data?.data?.message,
          }),
          put({
            type: vmsbCommonTypes.GET_VISION_LIST,
            payload: listViewPayload,
          }),
          put({
            type: VmsbOkrCommonTypes.GET_CAN_ADD_VISION,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield put({
        type: vmsbCommonTypes.DELETE_VISION_FAILED,
        errorMessage: error?.data?.message,
      });
      yield put({
        type: holdActions.SCROLL_TO_TOP,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* deleteMissionEffect() {
  yield takeEvery(vmsbCommonTypes.DELETE_MISSION, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const listViewPayload = payload?.msb_ayload;

      const response = yield call(deleteMissionReq, payload);
      if (response) {
        yield all([
          put({
            type: vmsbCommonTypes.DELETE_MISSION_SUCCESS,
            successMessage: response?.data?.data?.message,
          }),
          put({
            type: vmsbCommonTypes.GET_MSB_LIST,
            payload: listViewPayload,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield put({
        type: vmsbCommonTypes.DELETE_MISSION_FAILED,
        errorMessage: error?.data?.message,
      });
      yield put({
        type: holdActions.SCROLL_TO_TOP,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* deleteStrategyEffect() {
  yield takeEvery(vmsbCommonTypes.DELETE_STRATEGY, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const listViewPayload = payload?.msb_ayload;
      const response = yield call(deleteStrayegyReq, payload);
      if (response) {
        yield all([
          put({
            type: vmsbCommonTypes.DELETE_STRATEGY_SUCCESS,
            successMessage: response?.data?.data?.message,
          }),
          put({
            type: vmsbCommonTypes.GET_MSB_LIST,
            payload: listViewPayload,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield put({
        type: vmsbCommonTypes.DELETE_STRATEGY_FAILED,
        errorMessage: error?.data?.message,
      });
      yield put({
        type: holdActions.SCROLL_TO_TOP,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export function* deleteBenefitEffect() {
  yield takeEvery(vmsbCommonTypes.DELETE_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const listViewPayload = payload?.msb_ayload;

      const response = yield call(deleteBenefitReq, payload);
      if (response) {
        yield all([
          put({
            type: vmsbCommonTypes.DELETE_BENEFIT_SUCCESS,
            successMessage: response?.data?.data?.message,
          }),
          put({
            type: vmsbCommonTypes.GET_MSB_LIST,
            payload: listViewPayload,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield put({
        type: vmsbCommonTypes.DELETE_BENEFIT_FAILED,
        errorMessage: error?.data?.message,
      });
      yield put({
        type: holdActions.SCROLL_TO_TOP,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postVMSBPeriodListEffect() {
  yield takeEvery(vmsbCommonTypes.POST_VMSB_PERIOD_LIST, function* ({
    payload,
  }: {
    type: string;
    payload: Record<string, any>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(postVMSBPeriodListReq, payload);
      if (response) {
        if (response?.data?.data?.operation === 0) {
          yield put({
            type: vmsbCommonTypes.POST_VMSB_START_PERIOD_LIST_SUCCESS,
            vmsbStartPeriodData: response?.data?.data?.responseArray,
          });
        } else {
          yield put({
            type: vmsbCommonTypes.POST_VMSB_END_PERIOD_LIST_SUCCESS,
            vmsbEndPeriodData: response?.data?.data?.responseArray,
          });
        }
      } else {
        yield all([
          put({
            type: vmsbCommonTypes.POST_VMSB_PERIOD_LIST_FAILED,
            errorMessage: response?.data?.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield all([
        put({
          type: vmsbCommonTypes.POST_VMSB_PERIOD_LIST_FAILED,
          errorMessage: error?.data?.message,
        }),
        put({
          type: holdActions.SCROLL_TO_TOP,
        }),
      ]);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOthersNotifiedEffect),
    fork(getMSBlistEffect),
    fork(getVisionlistEffect),
    fork(postVMSBPeriodListEffect),
    fork(getVisionlistEffect),
    fork(deleteVisionEffect),
    fork(deleteStrategyEffect),
    fork(deleteBenefitEffect),
    fork(deleteMissionEffect),
  ]);
}
