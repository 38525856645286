import { Map } from "immutable";
import { pathConstant } from "../../config/constant";
import history from "./history";
import { getAllTooltips } from "../../redux/Admin/Tooltip/action";
let moment_tz = require("moment-timezone");

export function clearToken() {
  localStorage.removeItem("id_token");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    // @ts-ignore
    let x = new Map({ idToken });
    return x;
  } catch (err) {
    clearToken();
    // @ts-ignore

    return new Map();
  }
}

export function emptyCache() {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  return;
}

export function getModuleId(path) {
  let indexOfPathConst = pathConstant.findIndex((obj) =>
    path.includes(obj.name.toLowerCase())
  );
  return pathConstant[indexOfPathConst].moduleId;
}

export function getActiveApp() {
  try {
    const activeApp =
      localStorage.getItem("activeApp") || localStorage.getItem("activeApp");
    //@ts-ignore
    return new Map({ activeApp });
  } catch (err) {
    clearToken();
    //@ts-ignore
    return new Map();
  }
}

export function arrayEqual(array1: string[], array2: string[]) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime: string | number | Date) {
  const givenTimeDate = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTimeDate.getTime();
  const numberEnding = (number: number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num: string | number) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTimeDate.getUTCMonth() + 1);
      const day = number(givenTimeDate.getUTCDate());
      const year = givenTimeDate.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTimeDate.getUTCMonth()];
        const day = number(givenTimeDate.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value: any, defValue = 0): number {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value: number, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

/**
 * Scroll to view of the ScoreCard, as its always on top of the view
 * so we are targeting the main parent scroll container
 */
export const scoreCardErrorScrollToView = () => {
  const mainContainer = document.querySelector(".isomorphicContent");
  if (mainContainer) mainContainer.scrollTop = 0;
};

/**
 * Will pick the error field input from the ID and scroll to specific
 * position along keeping fixed height of the TopHeaderBar + Label height of input
 */
export const formInputErrorScroll = (id: string) => {
  const element = document.querySelector(`#${id}`);
  const parentContainer = document.querySelector(".isomorphicContent");
  const topBarElementHeight =
    document.querySelector(".innertopbarheight")?.clientHeight || 242;

  if (parentContainer)
    parentContainer.scrollTop = !element
      ? 0
      : parentContainer.scrollTop -
        Math.abs(
          element.getBoundingClientRect().top - topBarElementHeight - 44
        );
};

// udpate search params for syncing up the system
export const addQuery = (key, value) => {
  let pathname = history.location.pathname;
  let searchParams = new URLSearchParams(history.location.search);
  searchParams.set(key, value);
  history.push({
    pathname: pathname,
    search: searchParams.toString(),
  });
};

// remove the existing search params in order to reflect changes to system
export const removeQuery = (key) => {
  let pathname = history.location.pathname;
  let searchParams = new URLSearchParams(history.location.search);
  searchParams.delete(key);
  history.push({
    pathname: pathname,
    search: searchParams.toString(),
  });
};

export const timeConverter = (time, timezone) => {
  if (time)
    return moment_tz(time)?.tz(timezone)?.format("DD MMMM, YYYY - hh:mm A ");
};

// function for months list dropdown
export function getMonths(start: number, end: number, renderOption: (value: number) => JSX.Element) {
  const options: any[] = [];
  for (let i = start; i <= end; i++) {
    options.push(renderOption(i));
  }
  return options;
}

// function for years list dropdown
export function getYears(startYear: number, numYears: number, renderOption: (value: number) => JSX.Element) {
  const options: any[] = [];
  for (let i = 0; i <= numYears; i++) {
    const year = startYear + i;
    options.push(renderOption(year));
  }
  return options;
}

// annual plan rate calculation 
export function annualRateCalculate(monthlyRate, annualDiscount) {
  return monthlyRate - (monthlyRate * annualDiscount) / 100;
}

export function getTooltipForPage(page, dispatch) {
  return dispatch(getAllTooltips({page: page})); 
}