import { commentActions } from "./actionTypes";
import { commentPayload, commentAction } from "./types";
const initState: commentPayload = {
  idToken: "",
  loggedInUser: "",
  authFlag: false,
  errorMessage: "",
  get_Objective_comments: {
    paginatedObjectivekData: {
      pagedItems: [],
    },
    paginatedkeyresultData: {
      pagedItems: [],
    },
    paginatedTaskData: {
      pagedItems: [],
    },
  },
  get_key_comments: {
    paginatedkeyresultData: {
      pagedItems: [],
    },
  },
  comment: {
    paginatedkeyresultData: {
      pagedItems: [],
    },
  },
  get_task_comments: {
    paginatedTaskData: {
      pagedItems: [],
    },
  },
  non_user_comments_data: {
    name: "",
    description: "",
    comments: {},
  },
  get_vision_comments: {
    paginatedVisionData: {
      pagedItems: [],
    },
  },
  get_mission_comments: {
    paginatedMissionData: {
      pagedItems: [],
    },
  },
  get_strategy_comments: {
    paginatedStrategyData: {
      pagedItems: [],
    },
  },
  get_benefit_comments: {
    paginatedBenfitData: {
      pagedItems: [],
    },
  },
};
export default function authReducer(state = initState, action: commentAction) {
  switch (action.type) {
    case commentActions.POST_COMMENTS:
      return {
        ...state,
      };
    case commentActions.POST_COMMENTS_SUCCESS:
      return {
        ...state,
      };
    case commentActions.POST_COMMENTS_FAILED:
      return {
        ...state,
      };
    case commentActions.GET_COMMENTS:
      return {
        ...state,
      };
    case commentActions.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        [action.name]: action.payload,
      };
    case commentActions.GET_COMMENTS_FAILED:
      return {
        ...state,
      };

    case commentActions.GET_COMMENTS_NON_OKR_USER:
      return {
        ...state,
      };
    case commentActions.GET_COMMENTS_NON_OKR_USER_SUCCESS:
      return {
        ...state,
        non_user_comments_data: {
          name: action.name,
          description: action.description,
          comments: action.comments,
        },
      };
    case commentActions.GET_COMMENTS_NON_OKR_USER_FAILURE:
      return {
        ...state,
      };

    case commentActions.POST_COMMENTS_NON_OKR_USER:
      return {
        ...state,
      };
    case commentActions.POST_COMMENTS_NON_OKR_USER_SUCCESS:
      return {
        ...state,
      };
    case commentActions.POST_COMMENTS_NON_OKR_USER_FAILURE:
      return {
        ...state,
      };

    case commentActions.GET_VMSB_COMMENTS:
      return {
        ...state,
      };

    case commentActions.GET_VMSB_COMMENTS_SUCCESS:
      return {
        ...state,
        [action.name]: action.payload,
      };
    case commentActions.GET_VMSB_COMMENTS_FAILED:
      return {
        ...state,
      };
    case commentActions.POST_VMSB_COMMENTS:
      return {
        ...state,
      };
    case commentActions.POST_VMSB_COMMENTTS_SUCCESS:
      return {
        ...state,
      };
    case commentActions.POST_VMSB_COMMENTS_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
}
