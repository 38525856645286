import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import authActions from '../app/action';
import { instaMessageTypes } from './instaMessageTypes';
import {
  addInstaMessage,
  getCategoryTypesReq,
  getIssueTypesReq,
} from '../../library/services/instamessageReq';

export function* addInstaMessageEffect() {
  yield takeEvery(
    instaMessageTypes.ADD_INSTA_MESSAGE,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(addInstaMessage, payload);
        if (response.data?.status) {
          yield all([
            put({
              type: instaMessageTypes.ADD_INSTA_MESSAGE_SUCCESS,
              successMessage: response.data?.data?.message,
            }),
          ]);
        } else {
          yield all([
            put({
              type: instaMessageTypes.ADD_INSTA_MESSAGE_FAILED,
              successMessage: response.data?.message,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: instaMessageTypes.ADD_INSTA_MESSAGE_FAILED,
            errorMessage: error?.data?.message,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getIssueTypesEffect() {
  yield takeEvery(instaMessageTypes.GET_ISSUE_TYPES, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getIssueTypesReq);
      if (response) {
        yield put({
          type: instaMessageTypes.GET_ISSUE_TYPES_SUCCESS,
          issues: response?.data?.data?.issueTypes,
        });
      }
    } catch (error: any) {
      yield put({
        type: instaMessageTypes.GET_ISSUE_TYPES_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getCategoryTypesEffect() {
  yield takeEvery(instaMessageTypes.GET_CATEGORY_TYPES, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getCategoryTypesReq);
      if (response) {
        yield put({
          type: instaMessageTypes.GET_CATEGORY_TYPES_SUCCESS,
          categories: response?.data?.data?.categories,
        });
      }
    } catch (error: any) {
      yield put({
        type: instaMessageTypes.GET_CATEGORY_TYPES_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export default function* rootSaga() {
  yield all([fork(addInstaMessageEffect)]);
  yield all([fork(getCategoryTypesEffect)]);
  yield all([fork(getIssueTypesEffect)]);
}
