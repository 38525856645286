export enum strategyTypes {
  POST_STRATEGY = "POST_STRATEGY",
  POST_STRATEGY_SUCCESS = "POST_STRATEGY_SUCCESS",
  POST_STRATEGY_FAILED = "POST_STRATEGY_FAILED",

  GET_STRATEGY = "GET_STRATEGY",
  GET_STRATEGY_SUCCESS = "GET_STRATEGY_SUCCESS",
  GET_STRATEGY_FAILED = "GET_STRATEGY_FAILED",

  GET_VISION_ON_MISSION = "GET_VISION_ON_MISSION",
  GET_VISION_ON_MISSION_SUCCESS = "GET_VISION_ON_MISSION_SUCCESS",
  GET_VISION_ON_MISSION_FAILED = "GET_VISION_ON_MISSION_FAILED",

  GET_VISION_STRATEGY = "GET_VISION_STRATEGY",
  GET_VISION_STRATEGY_SUCCESS = "GET_VISION_STRATEGY_SUCCESS",
  GET_VISION_STRATEGY_FAILED = "GET_VISION_STRATEGY_FAILED",

  GET_MISSION_STRATEGY = "GET_MISSION_STRATEGY",
  GET_MISSION_STRATEGY_SUCCESS = "GET_MISSION_STRATEGY_SUCCESS",
  GET_MISSION_STRATEGY_FAILED = "GET_MISSION_STRATEGY_FAILED",

  GET_MISSION_LIST = "GET_MISSION_LIST",
  GET_MISSION_LIST_SUCCESS = "GET_MISSION_LIST_SUCCESS",
  GET_MISSION_LIST_FAILED = "GET_MISSION_LIST_FAILED",

  GET_STRATEGY_BENEFIT = "GET_STRATEGY_BENEFIT",
  GET_STRATEGY_BENEFIT_SUCCESS = "GET_STRATEGY_BENEFIT_SUCCESS",
  GET_STRATEGY_BENEFIT_FAILED = "GET_STRATEGY_BENEFIT_FAILED",

  UPDATE_STRATEGY = "UPDATE_STRATEGY",
  UPDATE_STRATEGY_SUCCESS = "UPDATE_STRATEGY_SUCCESS",
  UPDATE_STRATEGY_FAILED = "UPDATE_STRATEGY_FAILED",

  REMOVE_ALERT = "REMOVE_ALERT",
}
