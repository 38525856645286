import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardContainer,
  DashboardGlobalStyles,
} from '../../Dashboard/Dashboard.styles';
import { Layout } from 'antd';
import appActions from '../../../redux/app/action';
import useWindowSize from '../../../library/hooks/useWindowSize';
import AdminSidebar from '../../../components/Admin/Sidebar/AdminSidebar';
import { RootState } from '../../../redux/store/rootReducer';
import { Content } from 'antd/lib/layout/layout';
import AdminRoutes from './AdminRoutes';
import TopBar from '../../../components/Admin/TopBar/TopBar';
import jwtDecode from 'jwt-decode';
import moment, { unitOfTime } from 'moment';
import { emptyCache } from '../../../library/helpers/utility';
import history from '../../../library/helpers/history';

const { toggleAll } = appActions;

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const [innerHeight, setInnerHeight] = useState(
    document.getElementById('header-height')?.clientHeight
  );
  const appHeight = useSelector((state: RootState) => state.App.height);
  const messagesEndRef: any = useRef(null);
  const styles = {
    layout: { flexDirection: 'row' as 'row', overflow: 'hidden' as 'hidden' },
    content: {
      // padding: '114px 0 0' as '114px 0 0',
      // flexShrink: "0" as "0",
      background: '#f7f7f7' as '#f7f7f7',
      position: 'relative' as 'relative',
      height: '100%',
      overflow: 'auto',
    },
  };

  useEffect(() => {
    updateHeight();
  });

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  });

  const updateHeight = () => {
    const innerheight = document.getElementById('header-height')?.clientHeight;
    setInnerHeight(innerheight);
  };

  useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);

  useEffect(() => {
    const tokenTimer = setInterval(() => {
      const decodeJwtToken: any = jwtDecode(
        localStorage.getItem('admin_accessToken') ?? ''
      );

      if (
        moment().diff(
          moment(decodeJwtToken?.logged_at),
          process.env.REACT_APP_AUTH_TOKEN_EXPIRY_UNIT as unitOfTime.Diff
        ) > Number(process.env.REACT_APP_AUTH_TOKEN_EXPIRY)
      ) {
        localStorage.clear();
        sessionStorage.clear();
        emptyCache();
        history.push('/superadmin/login');
      }
    }, 5000);

    return () => {
      clearInterval(tokenTimer);
    };
  }, []);

  return (
    <DashboardContainer>
      <DashboardGlobalStyles />
      <Layout style={{ height: height - 1 }}>
        <TopBar />
        <Layout style={styles.layout}>
          <AdminSidebar />
          <Layout
            className='isoContentMainLayout'
            style={{
              height: appHeight,
            }}
          >
            <div
              ref={messagesEndRef}
              className='isomorphicContent'
              style={{
                ...styles.content,
                margin: `${innerHeight}px 0 0 0`,
                overflowY: 'scroll',
                scrollBehavior: 'smooth',
              }}
            >
              <Content>
                <div ref={messagesEndRef} />
                <AdminRoutes />
              </Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </DashboardContainer>
  );
};

export default AdminDashboard;
