export enum SubscriptionPlanTypes {
  POST_SUBSCRIPTIONPLAN = 'POST_SUBSCRIPTIONPLAN',
  POST_SUBSCRIPTIONPLAN_SUCCESS = 'POST_SUBSCRIPTIONPLAN_SUCCESS',
  POST_SUBSCRIPTIONPLAN_FAILED = 'POST_SUBSCRIPTIONPLAN_FAILED',

  CHECK_AVAILABLE_SUBSCRIPTION = 'CHECK_AVAILABLE_SUBSCRIPTION',
  CHECK_AVAILABLE_SUBSCRIPTION_SUCCESS = 'CHECK_AVAILABLE_SUBSCRIPTION_SUCCESS',
  CHECK_AVAILABLE_SUBSCRIPTION_FAILED = 'CHECK_AVAILABLE_SUBSCRIPTION_FAILED',

  PURCHASE_SUBSCRIPTION_PLAN = 'PURCHASE_SUBSCRIPTION_PLAN',
  PURCHASE_SUBSCRIPTION_PLAN_SUCCESS = 'PURCHASE_SUBSCRIPTION_PLAN_SUCCESS',
  PURCHASE_SUBSCRIPTION_PLAN_FAILED = 'PURCHASE_SUBSCRIPTION_PLAN_FAILED',

  POST_ADDITIONAL_LICENSE = 'POST_ADDITIONAL_LICENSE',
  POST_ADDITIONAL_LICENSE_SUCCESS = 'POST_ADDITIONAL_LICENSE_SUCCESS',
  POST_ADDITIONAL_LICENSE_FAILED = 'POST_ADDITIONAL_LICENSE_FAILED',

  SAVE_CARD_REQUEST = 'SAVE_CARD_REQUEST',
  SAVE_CARD_SUCCESS = 'SAVE_CARD_SUCCESS',
  SAVE_CARD_FAILED = 'SAVE_CARD_FAILED',

  NEW_CARD_REQUEST = 'NEW_CARD_REQUEST',
  NEW_CARD_SUCCESS = 'NEW_CARD_SUCCESS',
  NEW_CARD_FAILED = 'NEW_CARD_FAILED',

  CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED',

  CANCEL_SUBSCRIPTION_REQUEST_OTP = 'CANCEL_SUBSCRIPTION_REQUEST_OTP',
  CANCEL_SUBSCRIPTION_OTP_SUCCESS = 'CANCEL_SUBSCRIPTION_OTP_SUCCESS',
  CANCEL_SUBSCRIPTION_OTP_FAILED = 'CANCEL_SUBSCRIPTION_OTP_FAILED',

  GET_SAVED_CARD = 'GET_SAVED_CARD',
  GET_SAVED_CARD_SUCCESS = 'GET_SAVED_CARD_SUCCESS',
  GET_SAVED_CARD_FAILED = 'GET_SAVED_CARD_FAILED',

  REPLACE_CARD = 'REPLACE_CARD',
  REPLACE_CARD_SUCCESS = 'REPLACE_CARD_SUCCESS',
  REPLACE_CARD_FAILED = 'REPLACE_CARD_FAILED',

  MAKE_PAYMENT_REQUEST = 'MAKE_PAYMENT_REQUEST',
  MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS',
  MAKE_PAYMENT_FAILED = 'MAKE_PAYMENT_FAILED',

  SUBSCRIBER_PAYMENT_REQUEST = 'SUBSCRIBER_PAYMENT_REQUEST',
  SUBSCRIBER_PAYMENT_SUCCESS = 'SUBSCRIBER_PAYMENT_SUCCESS',
  SUBSCRIBER_PAYMENT_FAILED = 'SUBSCRIBER_PAYMENT_FAILED',

  SAVE_PAYMENT_ADDRESS_REQUEST = 'SAVE_PAYMENT_ADDRESS_REQUEST',
  SAVE_PAYMENT_ADDRESS_SUCCESS = 'SAVE_PAYMENT_ADDRESS_SUCCESS',
  SAVE_PAYMENT_ADDRESS_FAILED = 'SAVE_PAYMENT_ADDRESS_FAILED',

  VERIFY_SUBSCRIPTION = 'VERIFY_SUBSCRIPTION',
  VERIFY_SUBSCRIPTION_SUCCESS = 'VERIFY_SUBSCRIPTION_SUCCESS',
  VERIFY_SUBSCRIPTION_FAILED = 'VERIFY_SUBSCRIPTION_FAILED',

  GET_TRANSACTION_VIEW = 'GET_TRANSACTION_VIEW',
  TRANSACTION_VIEW_SUCCESS = 'TRANSACTION_VIEW_SUCCESS',
  TRANSACTION_VIEW_FAILED = 'TRANSACTION_VIEW_FAILED',

  GET_SUBSCRIPTION_HISTORY = 'GET_SUBSCRIPTION_HISTORY',
  GET_SUBSCRIPTION_HISTORY_SUCCESS = 'GET_SUBSCRIPTION_HISTORY_SUCCESS',
  GET_SUBSCRIPTION_HISTORY_FAILED = 'GET_SUBSCRIPTION_HISTORY_FAILED',

  UPGRADE_PLAN_TIME_PERIOD_REQUEST = 'UPGRADE_PLAN_TIME_PERIOD_REQUEST',
  UPGRADE_PLAN_TIME_PERIOD_SUCCESS = 'UPGRADE_PLAN_TIME_PERIOD_SUCCESS',
  UPGRADE_PLAN_TIME_PERIOD_FAILED = 'UPGRADE_PLAN_TIME_PERIOD_FAILED',

  UPGRADE_PLAN_REQUEST = 'UPGRADE_PLAN_REQUEST',
  UPGRADE_PLAN_SUCCESS = 'UPGRADE_PLAN_SUCCESS',
  UPGRADE_PLAN_FAILED = 'UPGRADE_PLAN_FAILED',

  DOWNGRADE_USER_REQUEST = 'DOWNGRADE_USER_REQUEST',
  DOWNGRADE_USER_SUCCESS = 'DOWNGRADE_USER_SUCCESS',
  DOWNGRADE_USER_FAILED = 'DOWNGRADE_USER_FAILED',

  UPGRADE_USER_REQUEST = 'UPGRADE_USER_REQUEST',
  UPGRADE_USER_SUCCESS = 'UPGRADE_USER_SUCCESS',
  UPGRADE_USER_FAILED = 'UPGRADE_USER_FAILED',

  DELETE_INVITED_USER_REQUEST = 'DELETE_INVITED_USER_REQUEST',
  DELETE_INVITED_USER_SUCCESS = 'DELETE_INVITED_USER_SUCCESS',
  DELETE_INVITED_USER_FAILED = 'DELETE_INVITED_USER_FAILED',

  SUBSCRIPTION_ONLY = 'SUBSCRIPTION_ONLY',

  REMOVE_ALERT = 'REMOVE_ALERT',

  RENEW_CHECK = 'RENEW_CHECK',
  RENEW_CHECK_SUCCESS = 'RENEW_CHECK_SUCCESS',
  RENEW_CHECK_FAILED = 'RENEW_CHECK_FAILED',

  CHECK_SUBSCRIPTION = 'CHECK_SUBSCRIPTION',
  CHECK_SUBSCRIPTION_SUCCESS = 'CHECK_SUBSCRIPTION_SUCCESS',
  CHECK_SUBSCRIPTION_FAILED = 'CHECK_SUBSCRIPTION_FAILED',

  CHECK_USER_STATUS = 'CHECK_USER_STATUS',
  CHECK_USER_STATUS_SUCCESS = 'CHECK_USER_STATUS_SUCCESS',
  CHECK_USER_STATUS_FAILED = 'CHECK_USER_STATUS_FAILED',

  GET_COUNTRY_LIST_REQUEST = 'GET_COUNTRY_LIST_REQUEST',
  GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS',
  GET_COUNTRY_LIST_FAILED = 'GET_COUNTRY_LIST_FAILED',

  GET_COUNTRY_BY_ID_REQUEST = 'GET_COUNTRY_BY_ID_REQUEST',
  GET_COUNTRY_BY_ID_SUCCESS = 'GET_COUNTRY_BY_ID_SUCCESS',
  GET_COUNTRY_BY_ID_FAILED = 'GET_COUNTRY_BY_ID_FAILED',

  GET_COUNTRY_DROPDOWN_REQUEST = 'GET_COUNTRY_DROPDOWN_REQUEST',
  GET_COUNTRY_DROPDOWN_SUCCESS = 'GET_COUNTRY_DROPDOWN_SUCCESS',
  GET_COUNTRY_DROPDOWN_FAILED = 'GET_COUNTRY_DROPDOWN_FAILED',

  GET_COUNTRY_DATA_BY_ID_REQUEST = 'GET_COUNTRY_DATA_BY_ID_REQUEST',
  GET_COUNTRY_DATA_BY_ID_SUCCESS = 'GET_COUNTRY_DATA_BY_ID_SUCCESS',
  GET_COUNTRY_DATA_BY_ID_FAILED = 'GET_COUNTRY_DATA_BY_ID_FAILED',

  GET_ALL_PLANS = 'GET_ALL_PLANS',
  GET_ALL_PLANS_SUCCESS = 'GET_ALL_PLANS_SUCCESS',
  GET_ALL_PLANS_FAILED = 'GET_ALL_PLANS_FAILED',

  GET_ALL_ACTIVE_PLANS = 'GET_ALL_ACTIVE_PLANS',
  GET_ALL_ACTIVE_PLANS_SUCCESS = 'GET_ALL_ACTIVE_PLANS_SUCCESS',
  GET_ALL_ACTIVE_PLANS_FAILED = 'GET_ALL_ACTIVE_PLANS_FAILED',

  VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE',
  VERIFY_PROMO_CODE_SUCCESS = 'VERIFY_PROMO_CODE_SUCCESS',
  VERIFY_PROMO_CODE_FAILED = 'VERIFY_PROMO_CODE_FAILED',

  //verify promocode after company signup
  VERIFY_PROMO = 'VERIFY_PROMO',
  VERIFY_PROMO_SUCCESS = 'VERIFY_PROMO_SUCCESS',
  VERIFY_PROMO_FAILED = 'VERIFY_PROMO_FAILED',

  SET_ERROR = 'SET_ERROR',

  CHECKOUT_SESSION = 'CHECKOUT_SESSION',
  CHECKOUT_SESSION_SUCCESS = 'CHECKOUT_SESSION_SUCCESS',
  CHECKOUT_SESSION_FAILED = 'CHECKOUT_SESSION_FAILED',

  CHECKOUT_SESSION_POST_SIGNIN = 'CHECKOUT_SESSION_POST_SIGNIN',
  CHECKOUT_SESSION_POST_SIGNIN_SUCCESS = 'CHECKOUT_SESSION_POST_SIGNIN_SUCCESS',
  CHECKOUT_SESSION_POST_SIGNIN_FAILED = 'CHECKOUT_SESSION_POST_SIGNIN_FAILED',

  VERIFY_CARD = 'VERIFY_CARD',
  VERIFY_CARD_SUCCESS = 'VERIFY_CARD_SUCCESS',
  VERIFY_CARD_FAILED = 'VERIFY_CARD_FAILED',

  VERIFY_CARD_POST_SIGNIN = 'VERIFY_CARD_POST_SIGNIN',
  VERIFY_CARD_POST_SIGNIN_SUCCESS = 'VERIFY_CARD_POST_SIGNIN_SUCCESS',
  VERIFY_CARD_POST_SIGNIN_FAILED = 'VERIFY_CARD_POST_SIGNIN_FAILED',

  GET_CARD_DETAILS = 'GET_CARD_DETAILS',
  GET_CARD_DETAILS_SUCCESS = 'GET_CARD_DETAILS_SUCCESS',
  GET_CARD_DETAILS_FAILED = 'GET_CARD_DETAILS_FAILED',

  GET_STATES_BY_ID_REQUEST = 'GET_STATES_BY_ID_REQUEST',
  GET_STATES_BY_ID_SUCCESS = 'GET_STATES_BY_ID_SUCCESS',
  GET_STATES_BY_ID_FAILED = 'GET_STATES_BY_ID_FAILED',

  GET_STATES_LIST_REQUEST = 'GET_STATES_LIST_REQUEST',
  GET_STATES_LIST_SUCCESS = 'GET_STATES_LIST_SUCCESS',
  GET_STATES_LIST_FAILED = 'GET_STATES_LIST_FAILED',
}
