import { strategyTypes } from "./strategyTypes";
import { strategyAction, strategyState } from "./types";
let initState: strategyState = {
  errorMessage: "",
  successMessage: "",
  strategyData: {
    others_to_be_notified: [],
    strategyView: {
      created_by: {
        id: 0,
        first_name: "",
      },
      mission: {
        id: null,
        name: "",
        note_updated_datetime: "",
        okr_linked_count: 0,
        on_hold: 0,
        vision: null,
      },
      name: "",
      integrated_status: 0,
      note: "",
      note_updated_datetime: null,
      number_of_periods: 0,
      objective_type: "",
      okr_linked_count: 0,
      on_hold: 0,
    },
  },
  visionStrategyData: {},
  missionStrategyData: {},
  visionOnMission: null,
  benefitStrategyData: {},
};
export default function authReducer(state = initState, action: strategyAction) {
  switch (action.type) {
    case strategyTypes.POST_STRATEGY:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
      };
    case strategyTypes.POST_STRATEGY_SUCCESS:
      return {
        ...state,
        successMessage: action.payload,
      };
    case strategyTypes.POST_STRATEGY_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case strategyTypes.GET_STRATEGY:
      return {
        ...state,
      };
    case strategyTypes.GET_STRATEGY_SUCCESS:
      return {
        ...state,
        strategyData: action.strategy,
      };
    case strategyTypes.GET_STRATEGY_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case strategyTypes.GET_VISION_STRATEGY:
      return {
        ...state,
      };
    case strategyTypes.GET_VISION_STRATEGY_SUCCESS:
      return {
        ...state,
        visionStrategyData: action.visionData,
      };
    case strategyTypes.GET_VISION_STRATEGY_FAILED:
      return {
        ...state,
      };
    case strategyTypes.GET_MISSION_STRATEGY:
      return {
        ...state,
      };
    case strategyTypes.GET_MISSION_STRATEGY_SUCCESS:
      return {
        ...state,
        missionStrategyData: action.missionData,
      };
    case strategyTypes.GET_MISSION_STRATEGY_FAILED:
      return {
        ...state,
      };
    case strategyTypes.GET_VISION_ON_MISSION:
      return {
        ...state,
      };
    case strategyTypes.GET_VISION_ON_MISSION_SUCCESS:
      return {
        ...state,
        visionOnMission: action.visionData[0]?.vision,
      };
    case strategyTypes.GET_VISION_ON_MISSION_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case strategyTypes.GET_STRATEGY_BENEFIT:
      return {
        ...state,
      };
    case strategyTypes.GET_STRATEGY_BENEFIT_SUCCESS:
      return {
        ...state,
        benefitStrategyData: action.benefitStrategyData,
      };
    case strategyTypes.GET_STRATEGY_BENEFIT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case strategyTypes.UPDATE_STRATEGY:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    case strategyTypes.UPDATE_STRATEGY_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case strategyTypes.UPDATE_STRATEGY_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case strategyTypes.GET_MISSION_LIST:
      return {
        ...state,
        visionOnMission: null,
      };
    case strategyTypes.GET_MISSION_LIST_SUCCESS:
      return {
        ...state,
        missionData: action.missionData,
      };
    case strategyTypes.GET_MISSION_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case strategyTypes.REMOVE_ALERT:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
      };
    default:
      return state;
  }
}
