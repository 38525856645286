export enum holdActions {
  POST_HOLDSOKR = "POST_HOLDSOKR",
  POST_HOLDSOKR_SUCCESS = "POST_HOLDSOKR_SUCCESS",
  POST_HOLDSOKR_FAILED = "POST_HOLDSOKR_FAILED",
  SCROLL_TO_TOP = "SCROLL_TO_TOP",
  SCROLL_TO_TOP_RESET = "SCROLL_TO_TOP_RESET",
  CHANGE_OBJECTIVE_STATUS = "CHANGE_OBJECTIVE_STATUS",
  CHANGE_KEY_RESULT_STATUS = "CHANGE_KEY_RESULT_STATUS",
  REMOVE_HOLD_ALERT = "REMOVE_HOLD_ALERT",
  POST_HOLD_MSB = "POST_HOLD_MSB",
  POST_HOLD_MSB_SUCCESS = "POST_HOLD_MSB_SUCCESS",
  POST_HOLD_MSB_FAILED = "POST_HOLD_MSB_FAILED",
}
