import actions from './actions';

const initState = {
  idToken: localStorage.getItem('id_token'),
  loggedInUser: null, // User's data - Role and Redirection check
  authFlag: false,
  statusOfURL: true,
  portalURLcheckLoading: true,
  profileObj: {},
  userReport: {
    daily_report: false,
    weekly_report: false,
  },
  userNotification: {
    direct_message: true,
    document_upload: false,
    app_download_from_link: false,
  },
  userNotificationList: [],
  IntID: null,
  signUpMessage: null,
  userEmail: null,
  loginMessage: null,
  verificationMessage: null,
  forgotMessage: null,
  resetForgotPasswordMessage: null,
  signup: '',
  managerList: [],
  departmentList: [],
  loginError: '',
  forgotPwError: '',
  signupError: '',
  resetPwError: '',
  fpSuccess: '',
  pcSuccess: '',
  departmentSuccessMessage: '',
  userRoleId: '',
  userData: {},
  userInfo: {},
  tokenValid: false,
  tokenError: '',
  openOTPVarification: false,
  otpVerificationError: '',
  otpVerificationSuccess: '',
  otpResendSuccess: '',
  otpResendError: '',
  loading: false,
  companyInfoSuccess: '',
  companyInfoError: '',
  userSignupSuccess: '',
  departmentsLoading: false,
  deptAddError: '',
  subscription: {},
  userLimitExceed: '',
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        payload: action.payload,
      };
    case actions.CLEAR_ERROR:
      return {
        ...state,
        signUpMessage: null,
        userEmail: null,
        loginMessage: null,
        verificationMessage: null,
        forgotMessage: null,
        resetForgotPasswordMessage: null,
        signup: '',
        /* TODO: This may needs to be removed */
        managerList: [],
        departmentList: [],
        loginError: '',
        forgotPwError: '',
        signupError: '',
        resetPwError: '',
        fpSuccess: '',
        pcSuccess: '',
        departmentSuccessMessage: '',
        userRoleId: '',
        otpVerificationError: '',
        otpResendError: '',
        otpResendSuccess: '',
      };
    case actions.LOGIN_REQUEST:
      return {
        ...initState,
      };
    case actions.RESET_PASSWORD:
      return {
        ...initState,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...initState,
      };
    case actions.SIGNUP_STEP1:
      return {
        ...initState,
      };
    case actions.SIGNUP_STEP1_SUCCESS:
      return {
        ...state,
        userSignupSuccess: action.userSignupSuccess,
      };
    case actions.SIGNUP_ADMIN_STEP1:
      return {
        ...state,
        openOTPVarification: false,
      };
    case actions.SIGNUP_ADMIN_STEP1_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        subscription: action.subscibed,
        userData: action.userData,
        userInfo: action.userInfo,
        openOTPVarification: true,
        otpVerificationError: '',
      };
    case actions.SIGNUP_ADMIN_STEP2_FAILED:
      return {
        ...state,
        signupError: action.error,
        openOTPVarification: false,
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...initState,
        fpSuccess: action.payload,
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pcSuccess: action.payload,
      };
    case actions.GET_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        managerList: action.payload,
      };
    case actions.SIGNUP_STEP1_FAILED:
      return {
        ...state,
        signupError:
          action.error === 'exception.getResponse is not a function'
            ? ''
            : action.error,
      };
    case actions.DEPARTMENT_ADD_SUCCESS:
      return {
        ...state,
        departmentList: [...state.departmentList, action.payload],
      };
    case actions.SIGNUP_STEP2_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.error,
      };
    case actions.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPwError: action.error,
      };
    case actions.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPwError: action.error,
      };
    case actions.OAUTH_VERIFY_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case actions.AUTH_MANUAL_SIGNUP_FAILED:
      return {
        ...state,
        resetPwError: action.resetPwError,
      };
    case actions.AUTH_MANUAL_SIGNUP_SUCCESS:
      return {
        ...state,
        pcSuccess: action.pcSuccess,
      };
    case actions.GET_MANAGER_LIST_FAILED:
      return {
        ...state,
      };
    case actions.GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        departmentsLoading: true,
      };
    case actions.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departmentList: action.payload,
        departmentsLoading: false,
      };
    case actions.GET_DEPARTMENT_FAILED:
      return {
        ...state,
        departmwentList: '',
        departmentsLoading: false,
      };
    case actions.DEPARTMENT_ADD_FAILED:
      return {
        ...state,
        deptAddError: action.error,
      };
    case actions.CHECK_PORTAL_URL_ROOT:
      return {
        ...state,
        portalURLcheckLoading: true,
      };

    case actions.PORTAL_URL_LOADING_SET:
      return {
        ...state,
        portalURLcheckLoading: false,
      };
    case actions.CHECK_PORTAL_URL_ROOT_SUCCESS:
      return {
        ...state,
        statusOfURL: action.payload,
        portalURLcheckLoading: false,
      };
    case actions.CHECK_VALID_TOKEN:
      return {
        ...state,
        tokenValid: false,
        tokenError: '',
      };
    case actions.CHECK_VALID_TOKEN_SUCCESS:
      return {
        ...state,
        tokenValid: true,
        tokenError: '',
      };
    case actions.CHECK_VALID_TOKEN_FAILED:
      return {
        ...state,
        tokenValid: false,
        tokenError: action.tokenError,
      };

    case actions.CHECK_USER_LIMIT:
      return {
        ...state,
        userLimitExceed: '',
      };
    case actions.CHECK_USER_LIMIT_SUCCESS:
      return {
        ...state,
        userLimitExceed: '',
      };
    case actions.CHECK_USER_LIMIT_FAILED:
      return {
        ...state,
        userLimitExceed: action.userLimitExceed,
      };

    case actions.POST_VERIFY_OTP:
      return {
        ...state,
        otpVerificationError: '',
        otpVerificationSuccess: '',
        otpResendSuccess: '',
        otpResendError: '',
        loading: true,
      };
    case actions.POST_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpVerificationError: '',
        otpVerificationSuccess: action.otpVerificationSuccess,
        loading: false,
        openOTPVarification: false,
      };
    case actions.POST_VERIFY_OTP_FAILED:
      return {
        ...state,
        otpVerificationSuccess: '',
        otpVerificationError: action.otpVerificationError,
        loading: false,
      };

    case actions.PATCH_RESEND_OTP:
      return {
        ...state,
        otpVerificationError: '',
        otpVerificationSuccess: '',
        otpResendSuccess: '',
        otpResendError: '',
      };
    case actions.PATCH_RESEND_OTP_SUCCESS:
      return {
        ...state,
        otpResendError: '',
        otpResendSuccess: action.otpResendSuccess,
      };
    case actions.PATCH_RESEND_OTP_FAILED:
      return {
        ...state,
        otpResendSuccess: '',
        otpResendError: action.otpResendError,
      };

    case actions.POST_COMPANY_INFO:
      return {
        ...state,
      };
    case actions.POST_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfoSuccess: action.companyInfoSuccess,
      };
    case actions.POST_COMPANY_INFO_FAILED:
      return {
        ...state,
        companyInfoError: action.companyInfoError,
      };
    case actions.PATCH_MANAGER_INSERT_FAILED:
      return {
        ...state,
        signupError: action.error,
      };
    case actions.AUTO_LOGOUT:
      return {
        ...state,
        idToken: null,
      };

    default:
      return { ...state };
  }
}
