import React, { useState } from 'react';
import { TopBarWrapper } from './TopBar.styles';
import {  Layout, Popover } from 'antd';
import TopbarDropdownWrapper from '../../Topbar/TopbarDropdown.styles';
import ATag from '../../AnchorTag/ATag';
import AvtarImage from '../../../assets/images/avatar.png';
import { useHistory } from 'react-router-dom';
import { emptyCache } from '../../../library/helpers/utility';

const { Header } = Layout;

const TopBar = () => {
  let history = useHistory();
  const [visible, setVisible] = useState(false);
  //@ts-ignore
  const adminData = JSON.parse(localStorage.getItem('adminData'));
  const styling: any = {
    background: '#035592',
    position: 'fixed',
    width: '100%',
    height: 70
  };

  function handleVisibleChange() {
    setVisible((visible) => !visible);
  }

  const handleLogout = () => {
    setVisible((visible) => !visible);
      sessionStorage.clear();
      localStorage.clear();
      emptyCache();
      history.push('/superadmin/login');
      window.location.reload();
  };

  const content = (
    <TopbarDropdownWrapper className='isoUserDropdown'>
      <ATag className='isoDropdownLink' tabIndex={0} onClick={handleLogout}>
        <span className='icon icon-power-off' />
        Logout
      </ATag>
    </TopbarDropdownWrapper>
  );

  return (
    <TopBarWrapper>
      <Header
        id='header-height'
        style={styling}
        className='isomorphicTopBar'
      >
        <ul className='isoRight'>
          <li className='isoUser'>
            <h3>{`${adminData.first_name} ${adminData.last_name}`}</h3>
            <div className='isoImgWrapper'>
              <img src={`${adminData.profile_photo !== null ? adminData.profile_photo : AvtarImage}`} alt='user' />
            </div>
            <Popover
              getPopupContainer={(node): HTMLElement => document.getElementById('arrow-down') as HTMLElement}
              content={content}
              trigger='click'
              visible={visible}
              onVisibleChange={handleVisibleChange}
              arrowPointAtCenter={true}
              placement='bottomRight'
              overlayClassName='user-profile-dropdown'
            >
              <div className='isoImgWrapper'>
                <ATag isButton={true} isOnlyIcon={true} tabIndex={0} className=''>
                  <span id='arrow-down' className='icon-arrow-down'></span>
                </ATag>
              </div>
            </Popover>
          </li>
        </ul>
      </Header>
    </TopBarWrapper>
  );
};

export default TopBar;
