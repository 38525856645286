import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  getComplteGlobalViewReq,
  postGlobalViewOrphanObjReq,
  getGlobalViewOrganizationsReq,
  postGlobalView,
  getChildNode,
  searchGlobalView,
  postVMSBStatus,
} from '../../library/services/GlobalViewReq';
import authActions from '../app/action';
import { globalViewTypes } from './globalViewTypes';
export function* getCompleteGlobalViewEffect() {
  yield takeEvery(
    globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_DATA,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getComplteGlobalViewReq, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.GET_COMPLETE_GLOBAL_VIEW_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getGlobalViewForeignObjEffect() {
  yield takeEvery(
    globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_DATA,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postGlobalViewOrphanObjReq, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.GET_GLOBAL_VIEW_ORPHAN_OBJ_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export function* getGlobalViewOrganizationEffect() {
  yield takeEvery(
    globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_DATA,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getGlobalViewOrganizationsReq, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.GET_GLOBAL_VIEW_ORGANIZATION_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postGlobalViewEffect() {
  yield takeEvery(
    globalViewTypes.POST_GLOBAL_VIEW,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postGlobalView, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.POST_GLOBAL_VIEW_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.POST_GLOBAL_VIEW_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.POST_GLOBAL_VIEW_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getChildNodeEffect() {
  yield takeEvery(
    globalViewTypes.GET_CHILD_NODE,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getChildNode, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.GET_CHILD_NODE_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.GET_CHILD_NODE_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.GET_CHILD_NODE_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* searchGlobalViewEffect() {
  yield takeEvery(
    globalViewTypes.SEARCH_GLOBAL_VIEW,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(searchGlobalView, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.SERACH_GLOBAL_VIEW_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.SEARCH_GLOBAL_VIEW_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.SEARCH_GLOBAL_VIEW_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* postVMSBStatusEffect() {
  yield takeEvery(
    globalViewTypes.POST_VMSB_STATUS,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postVMSBStatus, payload);
        if (response.status) {
          yield put({
            type: globalViewTypes.POST_VMSB_STATUS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: globalViewTypes.POST_VMSB_STATUS_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: globalViewTypes.POST_VMSB_STATUS_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* rootSaga() {
  yield all([fork(getCompleteGlobalViewEffect)]);
  yield all([fork(getGlobalViewOrganizationEffect)]);
  yield all([fork(getGlobalViewForeignObjEffect)]);
  yield all([fork(postGlobalViewEffect)]);
  yield all([fork(getChildNodeEffect)]);
  yield all([fork(searchGlobalViewEffect)]);
  yield all([fork(postVMSBStatusEffect)]);
}
