import { ServiceAuthInstance } from "./index";

    export const postHoldsOKRReq = (payload) => {
        return ServiceAuthInstance({
          method: "POST",
          url: "list_view/on_hold",
          data: {
            ...payload,
          },
        });
      };

  export const postHoldsMSBReq = (payload) => {
    return ServiceAuthInstance({
      method: "POST",
      url: "/mission/on_hold_msb",
      data: {
        ...payload,
      },
    });
  };
      