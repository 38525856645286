import { ServiceAuthInstance } from '.';

export const getOKRGraphDataReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/okr_graph`,
    data: payload,
  });
};

export const postHeatmapGraphDataReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/heatmap_scorecard`,
    data: payload,
  });
};

export const getCalculationStatusReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/calculation_graph`,
    data: payload,
  });
};

export const getTopBenefitsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/benefits`,
    data: payload,
  });
};

export const getTopOKRsReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/okrs`,
    data: payload,
  });
};

export const getEmployeeHelpfullnessReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/employee_helpfulness`,
    data: payload,
  });
};

export const getDepartmentAchievementReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `dashboard/department`,
    data: payload,
  });
};

export const getPeriodsList = (payload) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `/dashboard/drop_down?five=${payload.is_five}`,
  });
};
