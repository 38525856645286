import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { VmsbOkrCommonTypes } from "./VmsbOkrCommonTypes";
import authActions from "../app/action";
import {
postScoreCardDataReq ,
getCanAddVisionReq,
} from "../../library/services/VmsbOkrCommonReq";

export function* postScoreCardDataEffect() {
  yield takeEvery(VmsbOkrCommonTypes.POST_SCORECARD, function* ({ payload }: {type: string, isIncrement: boolean, payload: Record<string, string | number>}) {
    try {
    yield put(authActions.globalLoaderHandler(true));
      const response = yield call(postScoreCardDataReq, payload);
      if (response.status) {
        yield put({
          type: VmsbOkrCommonTypes.POST_SCORECARD_SUCCESS,
          scoreCardData: response.data.data.categoryData,
        });
      } 
      else {
        yield put({
          type: VmsbOkrCommonTypes.POST_SCORECARD_FAILED,
          payload: response,
        });
      }
    } catch (error: any) {
        yield put({
          type: VmsbOkrCommonTypes.POST_SCORECARD_FAILED,
          payload: error.message,
        });
    }
  yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getCanAddVisionEffect() {
  yield takeEvery(VmsbOkrCommonTypes.GET_CAN_ADD_VISION, function* () {
    try {
    yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getCanAddVisionReq);
      if (response.status) {
        yield put({
          type: VmsbOkrCommonTypes.GET_CAN_ADD_VISION_SUCCESS,
          vision: response.data.message,
          canAddVision: response.data.data.can_add_vision,
        });
      } 
      else {
        yield put({
          type: VmsbOkrCommonTypes.GET_CAN_ADD_VISION_FAILED,
          errorMessage: response,
        });
      }
    } catch (error: any) {
        yield put({
          type: VmsbOkrCommonTypes.GET_CAN_ADD_VISION_FAILED,
          errorMessage: error,
        });
    }
  yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([ 
    fork(postScoreCardDataEffect),
    fork(getCanAddVisionEffect),
    ])
}