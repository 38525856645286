import moment from 'moment-timezone';
import {
  allSubscriptionModules,
  dateFormatList,
  dateTimeFormat,
  planStatus,
} from '../../config/constant';
import { SubscriptionPlanTypes } from './SubscriptionPlanTypes';
import {
  SubscriptionPlanAction,
  SubscriptionPlanState,
  purchasedPlan,
  subscriptionData,
  transactionData,
  userStatus,
} from './types';
import { getPlanName } from '../../library/helpers/planName';
import { plan } from '../Admin/subscription/types';
let initState: SubscriptionPlanState = {
  checkoutSuccessSessionId: '',
  errorMessage: '',
  upgradeUserErrorMsg: '',
  successMessage: '',
  addLicenseError: '',
  replaceCardError: '',
  replaceCardSuccess: false,
  loading: false,
  upgradePlanErrorMsg: '',
  deleteInvitedUserErrorMsg: '',
  deleteInvitedUserSuccessMsg: '',
  inviteDeleteSucceed: false,
  planUpgradeSucceed: false,
  userInActiveSucceed: false,
  userActiveSucceed: false,
  licensesAdded: false,
  additionalLicenseMsg: '',
  cardVerified: false,
  openOTPVerification: false,
  otpVerificationError: '',
  paymentError: '',
  paymentAddressSave: false,
  totalTransactions: 0,
  plansPageCount: 1,
  totalItems: 0,
  itemsPerPage: 0,
  currentPage: 1,
  subscriptionOnly: false,
  isAnyPlanPurchased: false,
  purchasedPlan: {
    total_license: 0,
    plan_id: 0,
    type: 0,
    is_freePlan: 0,
    is_paid: 0,
    status: 0,
    startDate: '',
    lastDate: '',
    plan_name: '',
  },
  cardDetail: {
    exp_month: 0,
    exp_year: 0,
    name: '',
    last4: 0,
    brand: '',
    addressFilled: false,
  },
  subscriptionVerifyStatus: false,
  subscriptionStatus: {
    data: {
      valid: true,
    },
    error: '',
    status: true,
  },
  subscriptionData: {
    currentPlan: [],
    previousPlans: [],
  },
  transactionData: [],
  userSubscribeData: {
    user_id: 0,
    plan_id: 0,
    no_licenses: 0,
    is_renew: 0,
    is_freePlan: 0,
    company_slug: '',
    type: 0,
    start_date: '',
    end_date: '',
    buffer_date_time: '',
    status: 0,
    amount: '',
    is_paid: 0,
    cancel_at: null,
    id: 0,
    created_at: '',
  },
  userStatus: {
    users: [],
  },
  countryData: {
    id: null,
    country_name: '',
    country_code: '',
    currency: '',
  },
  countryList: [
    {
      id: null,
      country_name: '',
      country_code: '',
      currency: '',
    },
  ],
  allCountries: [
    {
      id: null,
      country_name: '',
      country_code: '',
      currency: '',
    },
  ],
  SelectedCountryData: {
    id: null,
    country_name: '',
    country_code: '',
    currency: '',
  },
  allPlans: [],
  activePlans: [],
  allActivePlans: [],
  appliedPromoCodeData: {
    discount: 0,
  },
  promoCodeValid: false,
  promoNotValidError: '',
  promoAppliedSuccess: '',
  planSubscribed: false,
  statesData: {
    id: null,
    state_name: '',
    country_code: '',
    hst: '',
    pst: '',
    gst: '',
  },
  statesList: [],
};

const usersListModifier = (data) => {
  const list: userStatus = {
    users: data.pagedItems,
  };
  return list;
};

const subscriptionHistoryModifier = (data) => {
  const subscription: subscriptionData = {
    currentPlan: data.pagedItems.filter((item) => item.status === 1),
    previousPlans: data.pagedItems.filter((item) => item.status === 0),
  };
  return subscription;
};

const purchasedPlanModifier = (data: any) => {
  const plan = data.pagedItems.filter(
    (item) =>
      !(moment() < moment(item.start_date)) && item.status === planStatus.active
  );

  if (plan?.length > 0) {
    let myPlan: purchasedPlan = {
      total_license: plan[0].total_licenses === 0 ? 1 : plan[0].total_licenses,
      plan_id: plan[0].plan_id.id,
      type: plan[0].type,
      is_freePlan: plan[0].plan_id?.plan_name === 'Free' ? 1 : 0,
      is_paid: plan[0].is_paid,
      status: plan[0].status,
      startDate: moment(plan[0].start_date).format(dateFormatList),
      lastDate: plan[0].end_date,
      plan_name: plan[0].plan_id.plan_name,
    };
    localStorage.setItem('purchasedPlan', JSON.stringify(myPlan));
    //@ts-ignore
    return JSON.parse(localStorage.getItem('purchasedPlan'));
  }
  localStorage.setItem('purchasedPlan', JSON.stringify(plan));
  //@ts-ignore
  return JSON.parse(localStorage.getItem('purchasedPlan'));
};

const transactionDataModifier = (data) => {
  const list: transactionData[] = [];
  data?.pagedItems?.forEach((item, index) => {
    const transaction: transactionData = {
      invoice: item.invoice_id,
      tid: item.charge_id,
      amount: `USD $${item.price}`,
      // @ts-ignore
      planType: getPlanName(
        item.user_sub_id.plan_id,
        JSON.parse(localStorage.getItem('allPlans') ?? JSON.stringify([]))
      ),
      licenses: item.license,
      mode: item.method,
      status: item.status === 1 ? 'success' : 'failed',
      date: moment(item.created_at).format(dateFormatList),
      paymentMadeOn: moment(item.created_at).format(dateTimeFormat),
      duration: moment(item.user_sub_id.start_date).format(dateFormatList),
      nextDueDate: moment(item.user_sub_id.end_date).format(dateFormatList),
      receipt: item.receipt_url,
    };
    list.push(transaction);
  });
  return list;
};

const activePlansModifier = (data) => {
  const plans: any = [];
  data.forEach((i) => {
    if (i.availability_status && i.status === 1) {
      plans.push(i);
    } else if (i.availability_status == false) {
      if (moment(i.discontinue_from) >= moment(moment())) {
        plans.push(i);
      }
    }
  });
  return plans;
};

const activePlanDetailsModifier = (data) => {
  let planNames: string[] = [];
  data.forEach((i) => {
    if (i.availability_status && i.status === 1) {
      planNames.push(i.plan_name);
    } else if (i.availability_status == false) {
      if (moment(i.discontinue_from) >= moment(moment())) {
        planNames.push(i.plan_name);
      }
    }
  });
  let planDetails: plan[] = [];
  let plan;
  allSubscriptionModules.forEach((key) => {
    plan = {
      key: key.key,
      feature: key.title,
    };
    planNames.forEach((item) => {
      const active = data.filter((i) => i.plan_name === item)[0];
      plan[item] = active.module[key.key];
    });
    planDetails.push(plan);
  });
  return planDetails;
};

export default function subscriptionPlanReducer(
  state = initState,
  action: SubscriptionPlanAction
) {
  switch (action.type) {
    case SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN_SUCCESS:
      return {
        ...state,
        userSubscribeData: action.userSubscribeData,
        planSubscribed: true,
      };
    case SubscriptionPlanTypes.POST_SUBSCRIPTIONPLAN_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        planSubscribed: false,
      };
    case SubscriptionPlanTypes.SAVE_CARD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        cardVerified: false,
      };
    case SubscriptionPlanTypes.SAVE_CARD_SUCCESS:
      return {
        ...state,
        cardVerified: true,
        errorMessage: '',
        successMessage: action.successMessage,
      };
    case SubscriptionPlanTypes.SAVE_CARD_FAILED:
      return {
        ...state,
        successMessage: '',
        errorMessage: action.errorMessage,
        cardVerified: false,
      };
    case SubscriptionPlanTypes.NEW_CARD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        cardVerified: false,
        loading: true,
      };
    case SubscriptionPlanTypes.NEW_CARD_SUCCESS:
      return {
        ...state,
        cardVerified: true,
        errorMessage: '',
        successMessage: action.successMessage,
        loading: false,
      };
    case SubscriptionPlanTypes.NEW_CARD_FAILED:
      return {
        ...state,
        successMessage: '',
        errorMessage: action.errorMessage,
        cardVerified: false,
        loading: false,
      };
    case SubscriptionPlanTypes.VERIFY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionVerifyStatus: action.subscriptionVerifyStatus,
      };
    case SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE:
      return {
        ...state,
        licensesAdded: false,
        addLicenseError: '',
        additionalLicenseMsg: '',
        loading: true,
      };
    case SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE_SUCCESS:
      return {
        ...state,
        licensesAdded: true,
        additionalLicenseMsg: action.additionalLicenseMsg,
        loading: false,
      };
    case SubscriptionPlanTypes.POST_ADDITIONAL_LICENSE_FAILED:
      return {
        ...state,
        licensesAdded: false,
        addLicenseError: action.addLicenseError,
        loading: false,
      };
    case SubscriptionPlanTypes.MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        paymentError: '',
      };
    case SubscriptionPlanTypes.MAKE_PAYMENT_FAILED:
      return {
        ...state,
        paymentError: action.paymentError,
      };
    case SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_REQUEST:
      return {
        ...state,
        paymentError: '',
        paymentAddressSave: false,
      };
    case SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_SUCCESS:
      return {
        ...state,
        paymentAddressSave: true,
      };
    case SubscriptionPlanTypes.SAVE_PAYMENT_ADDRESS_FAILED:
      return {
        ...state,
        paymentError: action.paymentError,
        paymentAddressSave: action.paymentAddressSave,
      };
    case SubscriptionPlanTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        subscriptionData: subscriptionHistoryModifier(action.subscriptionData),
        purchasedPlan:
          action.currentPage === 1
            ? purchasedPlanModifier(action.subscriptionData)
            : //@ts-ignore
              JSON.parse(localStorage.getItem('purchasedPlan')),
        plansPageCount: action.plansPageCount,
      };
    case SubscriptionPlanTypes.TRANSACTION_VIEW_SUCCESS:
      return {
        ...state,
        transactionData: transactionDataModifier(action.transactionData),
        totalTransactions: action.totalTransactions,
      };
    case SubscriptionPlanTypes.GET_SAVED_CARD_SUCCESS:
      return {
        ...state,
        cardDetail: action.cardDetail,
      };
    case SubscriptionPlanTypes.REPLACE_CARD:
      return {
        ...state,
        replaceCardError: '',
        replaceCardSuccess: false,
        loading: true,
      };
    case SubscriptionPlanTypes.REPLACE_CARD_SUCCESS:
      return {
        ...state,
        replaceCardSuccess: true,
        loading: false,
      };
    case SubscriptionPlanTypes.REPLACE_CARD_FAILED:
      return {
        ...state,
        replaceCardError: action.replaceCardError,
        replaceCardSuccess: false,
        loading: false,
      };
    case SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_REQUEST_OTP:
      return {
        ...state,
        openOTPVerification: false,
        loading: true,
      };
    case SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_OTP_SUCCESS:
      return {
        ...state,
        openOTPVerification: true,
        otpVerificationError: '',
        loading: false,
      };
    case SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SubscriptionPlanTypes.CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        otpVerificationError: action.otpVerificationError,
        loading: false,
      };
    case SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_REQUEST:
      return {
        ...state,
        paymentError: '',
      };
    case SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_FAILED:
      return {
        ...state,
        paymentError: action.paymentError,
      };
    case SubscriptionPlanTypes.SUBSCRIBER_PAYMENT_SUCCESS:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case SubscriptionPlanTypes.UPGRADE_PLAN_REQUEST:
      return {
        ...state,
        planUpgradeSucceed: false,
        loading: true,
        upgradePlanErrorMsg: '',
      };
    case SubscriptionPlanTypes.UPGRADE_PLAN_SUCCESS:
      return {
        ...state,
        planUpgradeSucceed: true,
        loading: false,
        upgradePlanErrorMsg: '',
      };
    case SubscriptionPlanTypes.UPGRADE_PLAN_FAILED:
      return {
        ...state,
        upgradePlanErrorMsg: action.upgradePlanErrorMsg,
        planUpgradeSucceed: false,
        loading: false,
      };
    case SubscriptionPlanTypes.DOWNGRADE_USER_REQUEST:
      return {
        ...state,
        userInActiveSucceed: false,
        loading: true,
      };
    case SubscriptionPlanTypes.DOWNGRADE_USER_SUCCESS:
      return {
        ...state,
        userInActiveSucceed: true,
        loading: false,
      };
    case SubscriptionPlanTypes.DOWNGRADE_USER_FAILED:
      return {
        ...state,
        userInActiveSucceed: false,
        loading: false,
      };

    case SubscriptionPlanTypes.UPGRADE_USER_REQUEST:
      return {
        ...state,
        userActiveSucceed: false,
        loading: true,
      };
    case SubscriptionPlanTypes.UPGRADE_USER_SUCCESS:
      return {
        ...state,
        upgradeUserErrorMsg: '',
        userActiveSucceed: true,
        loading: false,
      };
    case SubscriptionPlanTypes.UPGRADE_USER_FAILED:
      return {
        ...state,
        userActiveSucceed: false,
        upgradeUserErrorMsg: action.upgradeUserErrorMsg,
        loading: false,
      };

    case SubscriptionPlanTypes.DELETE_INVITED_USER_REQUEST:
      return {
        ...state,
        inviteDeleteSucceed: false,
        loading: true,
        deleteInvitedUserSuccessMsg: '',
        deleteInvitedUserErrorMsg: '',
      };
    case SubscriptionPlanTypes.DELETE_INVITED_USER_SUCCESS:
      return {
        ...state,
        deleteInvitedUserSuccessMsg: action.deleteInvitedUserSuccessMsg,
        deleteInvitedUserErrorMsg: '',
        inviteDeleteSucceed: true,
        loading: false,
      };
    case SubscriptionPlanTypes.DELETE_INVITED_USER_FAILED:
      return {
        ...state,
        inviteDeleteSucceed: false,
        deleteInvitedUserErrorMsg: action.deleteInvitedUserErrorMsg,
        loading: false,
      };
    case SubscriptionPlanTypes.CHECK_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionStatus: action.subscriptionStatus,
      };
    case SubscriptionPlanTypes.CHECK_SUBSCRIPTION_FAILED:
      return {
        ...state,
        subscriptionStatus: action.subscriptionStatus,
      };
    case SubscriptionPlanTypes.CHECK_USER_STATUS_SUCCESS:
      return {
        ...state,
        userStatus: usersListModifier(action.userStatus),
        totalItems: action.totalItems,
      };
    case SubscriptionPlanTypes.CHECK_USER_STATUS_FAILED:
      return {
        ...state,
        error: action.errorMessage,
      };
    case SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN:
      return {
        ...state,
        loading: true,
      };
    case SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        planUpgradeSucceed: true,
      };
    case SubscriptionPlanTypes.PURCHASE_SUBSCRIPTION_PLAN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case SubscriptionPlanTypes.CHECK_AVAILABLE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isAnyPlanPurchased: action.isAnyPlanPurchased,
      };
    case SubscriptionPlanTypes.GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.countryList,
      };

    case SubscriptionPlanTypes.GET_COUNTRY_BY_ID_SUCCESS:
      return {
        ...state,
        countryData: action.countryData,
      };
    case SubscriptionPlanTypes.GET_COUNTRY_DROPDOWN_SUCCESS:
      return {
        ...state,
        allCountries: action.allCountries,
      };
    case SubscriptionPlanTypes.GET_COUNTRY_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        SelectedCountryData: action.SelectedCountryData,
      };
    case SubscriptionPlanTypes.GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        allPlans: action.allPlans,
        activePlans: activePlansModifier(action.allPlans),
      };
    case SubscriptionPlanTypes.GET_ALL_PLANS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS_SUCCESS:
      return {
        ...state,
        allActivePlans: activePlansModifier(action.allActivePlans),
        activePlanDetails: activePlanDetailsModifier(action.allActivePlans),
      };
    case SubscriptionPlanTypes.GET_ALL_ACTIVE_PLANS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case SubscriptionPlanTypes.VERIFY_PROMO_CODE:
      return {
        ...state,
        promoNotValidError: '',
      };
    case SubscriptionPlanTypes.VERIFY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoNotValidError: '',
        promoCodeValid: action.promoCodeValid,
        promoAppliedSuccess: 'Promocode applied successfully.',
        appliedPromoCodeData: action.appliedPromoCodeData,
      };
    case SubscriptionPlanTypes.VERIFY_PROMO_CODE_FAILED:
      return {
        ...state,
        promoNotValidError: action.promoNotValidError,
      };
    case SubscriptionPlanTypes.VERIFY_PROMO:
      return {
        ...state,
        promoNotValidError: '',
        promoAppliedSuccess: '',
      };
    case SubscriptionPlanTypes.VERIFY_PROMO_SUCCESS:
      return {
        ...state,
        promoNotValidError: '',
        promoAppliedSuccess: 'Promocode applied successfully.',
        promoCodeValid: action.promoCodeValid,
        appliedPromoCodeData: action.appliedPromoCodeData,
      };
    case SubscriptionPlanTypes.VERIFY_PROMO_FAILED:
      return {
        ...state,
        promoNotValidError: action.promoNotValidError,
        promoAppliedSuccess: '',
      };
    case SubscriptionPlanTypes.SET_ERROR:
      return {
        ...state,
        promoNotValidError: action.payload?.promoNotValidError,
        promoAppliedSuccess: action.payload.promoAppliedSuccess,
      };
    case SubscriptionPlanTypes.CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        checkoutSuccessSessionId: action.checkoutSuccessSessionId,
      };
    case SubscriptionPlanTypes.VERIFY_CARD:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        cardVerified: false,
      };
    case SubscriptionPlanTypes.VERIFY_CARD_SUCCESS:
      return {
        ...state,
        cardVerified: true,
        errorMessage: '',
        successMessage: action.successMessage,
      };
    case SubscriptionPlanTypes.VERIFY_CARD_FAILED:
      return {
        ...state,
        successMessage: '',
        errorMessage: action.errorMessage,
        cardVerified: false,
      };

    case SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        cardVerified: false,
      };
    case SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN_SUCCESS:
      return {
        ...state,
        cardVerified: true,
        errorMessage: '',
      };
    case SubscriptionPlanTypes.VERIFY_CARD_POST_SIGNIN_FAILED:
      return {
        ...state,
        successMessage: '',
        errorMessage: action.errorMessage,
        cardVerified: false,
      };
    case SubscriptionPlanTypes.CHECKOUT_SESSION_POST_SIGNIN_SUCCESS:
      return {
        ...state,
        checkoutSuccessSessionId: action.checkoutSuccessSessionId,
      };
    case SubscriptionPlanTypes.GET_CARD_DETAILS_SUCCESS:
      console.log('action', action.cardDetail);
      return {
        ...state,
        cardDetail: action.cardDetail,
      };
    case SubscriptionPlanTypes.GET_STATES_BY_ID_SUCCESS:
      return {
        ...state,
        statesList: action.statesList,
      };
    case SubscriptionPlanTypes.GET_STATES_BY_ID_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case SubscriptionPlanTypes.GET_STATES_LIST_SUCCESS:
      return {
        ...state,
        statesList: action.statesList,
      };
    case SubscriptionPlanTypes.GET_STATES_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case SubscriptionPlanTypes.REMOVE_ALERT:
      return {
        ...state,
        successMessage: '',
        upgradePlanErrorMsg: '',
        errorMessage: '',
        paymentError: '',
        paymentAddressSave: false,
        replaceCardError: '',
        addLicenseError: '',
        otpVerificationError: '',
        upgradeUserErrorMsg: '',
        deleteInvitedUserErrorMsg: '',
        deleteInvitedUserSuccessMsg: '',
        planUpgradeSucceed: false,
        userInActiveSucceed: false,
        userActiveSucceed: false,
        inviteDeleteSucceed: false,
        promoNotValidError: '',
        promoAppliedSuccess: '',
      };
    default:
      return state;
  }
}
