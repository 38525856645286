import { ServiceAdminAuthInstance, adminApiUrls } from '..';

export const workspaceList = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_LIST,
    data: { ...payload }
  });
};

export const workspaceDetailsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_DETAIL,
    data: { ...payload }
  });
};

export const workspaceSubscriptionDetailsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_SUBSCRIPTION_DETAIL,
    data: { ...payload }
  });
};

export const workspaceVmsbOkrDetailsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_VMSB_OKR_DETAIL,
    data: { ...payload }
  });
};

export const workspaceChangeUserRoleReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'PATCH',
    url: adminApiUrls.WORKSPACE_CHANGE_USER_ROLE,
    data: { ...payload }
  });
};

export const workspaceUserListReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_DETAIL_USER_LIST,
    data: { ...payload }
  });
};

export const workspaceSubscriptionHistoryReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_SUBSCRIPTION_HISTORY,
    data: { ...payload }
  });
};

export const workspaceActiveLogReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_ACTIVE_LOG,
    data: { ...payload }
  });
};

export const workspaceTransactionsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'POST',
    url: adminApiUrls.WORKSPACE_TRANSACTIONS,
    data: { ...payload }
  });
};

export const editSubscriptionDetailsReq = (payload) => {
  return ServiceAdminAuthInstance({
    method: 'PATCH',
    url: adminApiUrls.EDIT_SUBSCRIPTION_DETAILS,
    data: { ...payload }
  });
};
