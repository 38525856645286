import { defaultMetaData } from '../../../config/constant';
import { DashboardActionTypes } from './DashboardActionTypes';
import { AdminDashboardState, AdminDashboardStateAction, activeData } from './DashboardTypes';
import { UserAddOutlined, DollarOutlined, UserOutlined } from '@ant-design/icons';

let initState: AdminDashboardState = {
  activeData: [
    {
      title: '',
      number: '',
      icon: UserAddOutlined
    }
  ],
  scoreCardData: [],
  subscriptionData: [],
  totalSubscriptions: defaultMetaData,
  companyIndustry: {},
  exportableScoreCardData: [],
  exportableSubscriptionData: [],
  exportableCompanyIndustry: [],
  totalScorecardItems: defaultMetaData,
  totalIndustry: defaultMetaData
};

const activeDataModifier = (data) => {
  let card: activeData[] = [
    { title: 'Total Active Subscription', number: data.activeOrganizationCount, icon: UserAddOutlined },
    { title: 'Revenue Generated', number: `$${data.revenueGenerated === null ? 0 : data.revenueGenerated}`, icon: DollarOutlined },
    { title: 'Total Active System User', number: data.activeOrganizationUserCount, icon: UserOutlined }
  ];
  return card;
};

const scoreCardModifier = (data, metaData) => {
  const categoryList: any[] = [];
  metaData?.totalItems > 0 &&
    data?.forEach((item) => {
      let scorecard = {
        category: item.category,
        workspace: item.company_name,
        orgAdmin: `${item.first_name} ${item.last_name}`,
        mission: item.mission,
        strategy: item.strategy,
        benefit: item.benefit,
        objective: item.objective
      };
      categoryList.push(scorecard);
    });
  return categoryList;
};

const exportableScoreCardModifier = (data) => {
  const categoryList: any[] = [];
  data?.forEach((item) => {
    let scorecard = {
      'Workspace Name': item.company_name,
      'Category Name': item.category,
      Missions: item.mission,
      Strategies: item.strategy,
      Benefits: item.benefit,
      Objectives: item.objective,
      'Org. Admin Name': `${item.first_name} ${item.last_name}`
    };
    categoryList.push(scorecard);
  });
  return categoryList;
};

const subscriptionsModifier = (data, metaData) => {
  const subscriptionsList: any[] = [];
  metaData?.totalItems > 0 &&
    data?.forEach((item) => {
      const plan = item.name.split('_');
      let subscription = {
        SubsPackages: plan[0],
        planType: plan[1] ? plan[1] : '-',
        totalWorkspaces: item.totalWorkspace,
        totalUsers: item.totalUsers
      };
      subscriptionsList.push(subscription);
    });
  return subscriptionsList;
};

const exportableSubscriptionsModifier = (data) => {
  const subscriptionsList: any[] = [];
  data.length > 0 &&
    data?.forEach((item) => {
      const plan = item.name.split('_');
      let subscription = {
        SubsPackages: plan[0],
        planType: plan[1],
        totalWorkspaces: item.totalWorkspace,
        totalUsers: item.totalUsers
      };
      subscriptionsList.push(subscription);
    });
  return subscriptionsList;
};

// const scoreCardModifier = (data) => {
//   const categoryList: any[] = [];
//   data?.forEach((item) => {
//     if (categories.some((i) => Object.keys(item).includes(i))) {
//       Object.keys(item.benefit).forEach((category) => {
//         let scorecard = {
//           category: category,
//           workspace: item.company_name,
//           orgAdmin: `${item.first_name} ${item.last_name}`
//         };
//         categories.forEach((i) => {
//           scorecard[i] = item[i][category];
//         });
//         categoryList.push(scorecard);
//       });
//     }
//   });
//   return categoryList;
// };

export default function AdminDashboardReducer(state = initState, action: AdminDashboardStateAction) {
  switch (action.type) {
    case DashboardActionTypes.GET_DASHBOARD_ACTIVE_DATA_SUCCESS:
      return {
        ...state,
        activeData: activeDataModifier(action.activeData)
      };
    case DashboardActionTypes.INDUSTRY_REPORT_SUCCESS:
      return {
        ...state,
        companyIndustry: action.companyIndustry,
        totalIndustry: action.totalIndustry
      };
    case DashboardActionTypes.DASHBOARD_PAGE_CHANGES:
      return {
        ...state,
        totalSubscriptions: action.totalSubscriptions,
        totalScorecardItems: action.totalScorecardItems,
        totalIndustry: action.totalIndustry
      };
    case DashboardActionTypes.SCORECARD_REPORT_SUCCESS:
      return {
        ...state,
        scoreCardData: scoreCardModifier(action.scoreCardData, action.totalScorecardItems),
        totalScorecardItems: action.totalScorecardItems
      };
    case DashboardActionTypes.SUBSCRIPTION_REPORT_SUCCESS:
      return {
        ...state,
        subscriptionData: subscriptionsModifier(action.subscriptionData, action.totalSubscriptions),
        totalSubscriptions: action.totalSubscriptions
      };
    case DashboardActionTypes.EXPORT_SCORECARD_REPORT_SUCCESS:
      return {
        ...state,
        exportableScoreCardData: exportableScoreCardModifier(action.exportableScoreCardData)
      };
    case DashboardActionTypes.EXPORT_SUBSCRIPTION_REPORT_SUCCESS:
      return {
        ...state,
        exportableSubscriptionData: exportableSubscriptionsModifier(action.exportableSubscriptionData)
      };
    case DashboardActionTypes.EXPORT_INDUSTRY_REPORT_SUCCESS:
      return {
        ...state,
        exportableCompanyIndustry: action.exportableCompanyIndustry
      };
    default:
      return { ...state };
  }
}
