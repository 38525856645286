import styled from 'styled-components';
import { variables } from '../../assets/styles/variables';
// import { palette } from "styled-theme";
// import { variables } from "../../assets/styles/variables";
// import WithDirection from "../../library/helpers/rtl";
const PaymentDetailsStyleWrapper = styled.div`
  max-width: 100%;
  .isoBillingContentWrapper {
    .ant-form-item-has-error {
      .react-tel-input > .form-control,
      .flag-dropdown {
        border: 1px solid #ff4d4f !important;
      }
    }
    .react-tel-input {
      .form-control {
        width: 100%;
        border-color: ${variables.btn_bg_color};
        /* height: 40px; */
        color: ${variables.darkGray};
      }
      .flag-dropdown {
        border-color: ${variables.btn_bg_color};
      }
    }
    .react-tel-input .form-control {
      height: 40px;
      width: 100%;
      padding-left: 65px;
    }
    .react-tel-input .selected-flag {
      width: 60px;
      padding: 0 0 0 15px;
    }
    .react-tel-input .selected-flag .arrow {
      border: none;
      &::after {
        content: '\\e91b';
        font-family: 'icomoon' !important;
        color: #22c4f2;
      }
    }
    .flag-dropdown {
      .arrow {
        transform: rotate(90deg);
        display: flex;
        align-items: center;
        font-size: 8px;
        left: 28px;
      }
    }
    .react-tel-input .country-list .country:hover,
    .react-tel-input .country-list .country.highlight {
      background-color: #e6f7ff;
    }
    ul.country-list {
      padding: 0px;
      border-radius: 2px;
    }
    ul.country-list .country-name,
    .react-tel-input {
      color: #2d2c2c;
    }

    .ant-select-arrow {
      svg {
        display: none;
      }
      & > span {
        transform: rotate(90deg);
        &::after {
          content: '\\e91b';
          font-family: 'icomoon' !important;
          color: #22c4f2;
        }
      }
    }
    .ant-select-open {
      .ant-select-arrow {
        & > span {
          transform: rotate(-90deg);
        }
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .isoBillingContent {
      padding: 40px;
      .isoLogoWrapper {
        margin-bottom: 40px;
        @media only screen and (max-width: 1800px) {
          margin-bottom: 35px;
        }
        @media only screen and (max-width: 1600px) {
          margin-bottom: 25px;
        }
        @media only screen and (max-width: 1440px) {
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 1199px) {
          margin-bottom: 15px;
        }
      }
      .ant-alert {
        max-width: 100% !important;
      }
      .title {
        text-align: center;
      }
      .ant-card {
        &.parent-card {
          box-shadow: 0px 0px 4px rgb(34 196 242 / 20%);
        }
        // width: 880px;
        margin: 35px auto 0px;
        // @media only screen and (max-width: 1199px) {
        //   max-width: 750px;
        // }
        // @media only screen and (max-width: 991px) {
        //   max-width: 650px;
        // }
        &.ant-card-bordered {
          border: none;
        }
        .ant-card-head {
          padding: 0px;
          .ant-card-head-wrapper {
            padding: 0px;
            .ant-card-head-title {
              padding: 0px;
              p {
                margin: 0px;
              }
              .trial {
                text-align: center;
                background: #035592;
                color: #fff;
                padding: 13px 0px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                span {
                  font-weight: 700;
                }
                @media only screen and (max-width: 991px) {
                  font-size: 14px;
                }
                &.trial-end {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 21px;
                  @media only screen and (max-width: 1800px) {
                    padding: 12px 0px;
                    font-size: 17px;
                  }
                  @media only screen and (max-width: 1440px) {
                    font-size: 16px;
                  }
                  @media only screen and (max-width: 1199px) {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
        .ant-card-body {
          padding: 0px 75px 0px 0px;
          @media only screen and (max-width: 1199px) {
            padding: 0px 55px 0px 0px;
          }
          @media only screen and (max-width: 991px) {
            padding: 0px 45px 0px 0px;
          }
          border-bottom: 0.5px solid #dbd8d8;
          .billing-wrapper {
            display: flex;
          }
          .isoPaymentContentWrapper {
            display: flex;
            // align-items: center;
            justify-content: center;
            width: 500px;
            .isoPaymentContent {
              padding: 0px 0px;
              border-right: 0.5px solid #dbd8d8;
              width: 100%;
              .title {
                text-align: center;
              }
              .ant-card {
                height: 100%;
                &.ant-card-bordered {
                  border: none;
                }
                margin: 0px auto 0px;
                // @media only screen and (max-width: 1199px) {
                //   margin: 50px auto 0px;
                // }
                // @media only screen and (max-width: 991px) {
                //   margin: 40px auto 0px;
                // }
                .ant-card-head {
                  padding: 0px;
                  .ant-card-head-wrapper {
                    padding: 0px;
                    .ant-card-head-title {
                      padding: 0px;
                      p {
                        margin: 0px;
                      }

                      .choose-plan {
                        display: block;
                        padding: 26px;
                        text-align: left;
                        font-size: 20px;
                        line-height: 24px;
                        color: #2d2c2c;
                        border-bottom: 0.5px solid #dbd8d8;
                        > div {
                          margin-top: 5px;
                          display: flex;
                          flex-direction: column;
                        }
                        span {
                          font-family: 'Inter-Bold', sans-serif;
                          color: #035592;
                          sup {
                            margin-left: 3px;
                            font-family: 'Inter-Regular', sans-serif;
                          }
                          .anticon {
                            color: #9e9e9e;
                            margin-left: 7px;
                          }
                        }
                        .license {
                          display: flex;
                          p {
                            font-size: 12px;
                            margin-left: 3px;
                          }
                        }
                        @media only screen and (max-width: 1800px) {
                          font-size: 18px;
                          line-height: 20px;
                          padding: 30px;
                        }
                        @media only screen and (max-width: 1440px) {
                          padding: 25px;
                          font-size: 16px;
                          line-height: 18px;
                        }
                      }
                    }
                  }
                }
              }
              .ant-card-body {
                padding: 0px;
                display: block;
                height: calc(100% - 19%);
                @media only screen and (max-width: 1800px) {
                  height: calc(100% - 20%);
                }
                @media only screen and (max-width: 1440px) {
                  height: calc(100% - 18%);
                }
                border: none;
                .payment-info {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .count-payment {
                    padding: 45px 35px;
                    @media only screen and (max-width: 1800px) {
                      padding: 40px 30px;
                    }
                    @media only screen and (max-width: 1440px) {
                      padding: 35px 25px;
                    }
                  }

                  .count-payment {
                    p {
                      &.redirect {
                        margin-top: 15px;
                        text-align: left;
                        span {
                          font-size: 20px;
                          line-height: 24px;
                          float: none;
                          @media only screen and (max-width: 1199px) {
                            font-size: 18px;
                            line-height: 20px;
                          }
                          @media only screen and (max-width: 991px) {
                            font-size: 17px;
                            line-height: 19px;
                          }
                        }
                      }
                    }
                    .promo-code-wrapper {
                      .ant-input-disabled {
                        background-color: #f5f5f5;
                        cursor: not-allowed;
                        color: rgb(0, 0, 0, 0.25);
                      }
                      .ant-form-item-control-input-content {
                        display: flex;
                        justify-content: space-between;
                      }
                      &.btn-group {
                        justify-content: space-between;
                      }
                      .promo-code {
                        max-width: 77%;
                        margin-right: 10px;
                      }
                      .invalid-promo-input {
                        border: 1px solid #e02929;
                      }
                      .disabled-aTag {
                        margin-right: 0px;
                      }
                    }
                    .invalid-code {
                      font-size: 12px !important;
                      line-height: 16px !important;
                      color: #e02929;
                      margin-top: 5px;
                    }
                  }

                  p {
                    margin: 0px;
                    &:first-child {
                      margin-bottom: 25px;
                    }
                    font-size: 20px;
                    line-height: 24px;
                    color: #2d2c2c;
                    @media only screen and (max-width: 1800px) {
                      font-size: 18px;
                      line-height: 20px;
                    }
                    @media only screen and (max-width: 1440px) {
                      font-size: 16px;
                      line-height: 18px;
                    }
                    @media only screen and (max-width: 1199px) {
                      font-size: 15px;
                      line-height: 18px;
                    }

                    span:last-child {
                      float: right;
                    }
                  }

                  .total-payment {
                    padding: 45px 35px;
                    border-top: 0.5px solid #dbd8d8 !important;
                    @media only screen and (max-width: 1800px) {
                      padding: 40px 30px;
                    }
                    @media only screen and (max-width: 1440px) {
                      padding: 35px 25px;
                    }
                    p {
                      @media only screen and (max-width: 1199px) {
                        margin-bottom: 30px;
                      }
                      @media only screen and (max-width: 991px) {
                        margin-bottom: 25px;
                      }
                      span {
                        &.total {
                          color: #035592;
                          font-family: 'Inter-Bold', sans-serif;
                        }
                        sup {
                          font-family: 'Inter-Regular', sans-serif;
                        }
                      }
                    }
                  }
                  .taxes {
                    padding: 15px 25px;
                    p {
                      margin-bottom: 15px;
                    }
                  }
                }
              }
            }
          }

          .ant-form {
            .ant-form-item-control
              .ant-form-item-explain.ant-form-item-explain-error {
              position: absolute;
              margin-top: 42px;
              // bottom: -35px;
            }
            .ant-form-item-explain {
              position: absolute;
              bottom: -30px;
              min-height: 30px;
            }
            padding: 25px 0px 25px 75px;
            @media only screen and (max-width: 1800px) {
              padding: 25px 0px 25px 55px;
            }
            @media only screen and (max-width: 1440px) {
              padding: 20px 0px 20px 45px;
            }
            .ant-form-item {
              label {
                font-size: 20px;
                line-height: 24px;
                @media only screen and (max-width: 1800px) {
                  font-size: 18px;
                  line-height: 20px;
                }
                @media only screen and (max-width: 1440px) {
                  font-size: 16px;
                  line-height: 18px;
                }
                @media only screen and (max-width: 1199px) {
                  font-size: 15px;
                  line-height: 18px;
                }
              }
              &:not(:last-child) {
                margin-bottom: 35px;
                @media only screen and (max-width: 1199px) {
                  margin-bottom: 30px;
                }
                @media only screen and (max-width: 991px) {
                  margin-bottom: 25px;
                }
              }
            }
            &.payment-form {
              max-width: 800px;
              @media only screen and (max-width: 1199px) {
                max-width: 650px;
              }
              @media only screen and (max-width: 991px) {
                margin-bottom: 25px;
              }
            }
            .small-fields {
              display: flex;
              justify-content: space-between;
              width: 100%;
              @media only screen and (max-width: 991px) {
                flex-direction: column;
                width: 100%;
              }
              .ant-form-item:last-child {
                margin-bottom: 20px !important;
                @media only screen and (min-width: 1800px) {
                  margin-bottom: 36px !important;
                }
                @media only screen and (max-width: 1600px) {
                  margin-bottom: 25px !important;
                }
                @media only screen and (max-width: 1200px) {
                  margin-bottom: 17px !important;
                }
              }
              .ant-form-item {
                width: 48%;
                margin-bottom: 35px;
                @media only screen and (max-width: 1199px) {
                  margin-bottom: 30px;
                }
                @media only screen and (max-width: 991px) {
                  margin-bottom: 25px;
                  width: 100%;
                }
                .ant-input-disabled {
                  background-color: #f5f5f5;
                  cursor: not-allowed;
                  color: rgb(0, 0, 0, 0.25);
                }
              }
            }
            .postal-code {
              .ant-form-item-control-input {
                width: 23%;
                @media only screen and (max-width: 991px) {
                  width: 50%;
                }
              }
              margin-bottom: 0px;
            }
          }
        }
      }
      .btn-group.payment-btn {
        div {
          justify-content: center;
          button {
            cursor: pointer;
          }
        }
        margin: 0px;
        background: #fff;
        justify-content: center;
        padding: 20px 0px;
        box-shadow: 0px 1px 4px rgb(34 196 242 / 20%);
        /* @media only screen and (max-width: 1800px) {
          padding: 20px 0px;
        }
        @media only screen and (max-width: 1440px) {
          padding: 35px 0px;
        }
        @media only screen and (max-width: 1199px) {
          padding: 30px 0px;
        }
        @media only screen and (max-width: 991px) {
          padding: 25px 0px;
        } */
      }
    }
  }
`;
export default PaymentDetailsStyleWrapper;
