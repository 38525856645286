import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { strategyTypes } from "./strategyTypes";
import authActions from "../app/action";
import {
  postStrategyReq,
  getStrategyReq,
  getVisionOnMissionReq,
  getMissionStrategyReq,
  getMissionListReq,
  getStrategyBenefitReq,
  editStrategyReq,
} from "../../library/services/strategyReq";
import { holdActions } from "../Holds/holdActions";
import history from "../../library/helpers/history";

export function* getStrategyEffect() {
  yield takeEvery(strategyTypes.GET_STRATEGY, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getStrategyReq, payload);
      if (response) {
        yield put({
          type: strategyTypes.GET_STRATEGY_SUCCESS,
          strategy: response?.data?.data,
        });
      } else {
        yield put({
          type: strategyTypes.GET_STRATEGY_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: strategyTypes.GET_STRATEGY_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getMissionListEffect() {
  yield takeEvery(strategyTypes.GET_MISSION_LIST, function* ({payload}: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getMissionListReq, payload);
      if (response.status) {
        yield put({
          type: strategyTypes.GET_MISSION_LIST_SUCCESS,
          missionData: response?.data?.data?.missionOnStrategy,
        });
      } else {
        yield put({
          type: strategyTypes.GET_MISSION_LIST_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: strategyTypes.GET_MISSION_LIST_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getStrategyBenefitEffect() {
  yield takeEvery(strategyTypes.GET_STRATEGY_BENEFIT, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getStrategyBenefitReq, payload);
      if (response.status) {
        yield put({
          type: strategyTypes.GET_STRATEGY_BENEFIT_SUCCESS,
          benefitStrategyData: response?.data?.data,
        });
      } else {
        yield put({
          type: strategyTypes.GET_STRATEGY_BENEFIT_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: strategyTypes.GET_STRATEGY_BENEFIT_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getVisionOnMissionEffect() {
  yield takeEvery(strategyTypes.GET_VISION_ON_MISSION, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getVisionOnMissionReq, payload);
      if (response) {
        yield put({
          type: strategyTypes.GET_VISION_ON_MISSION_SUCCESS,
          visionData: response?.data?.data?.getVisionOnId,
        });
      } else {
        yield put({
          type: strategyTypes.GET_VISION_ON_MISSION_FAILED,
          errorMessage: response.data.message,
        });
      }
    } catch (error: any) {
      yield put({
        type: strategyTypes.GET_VISION_ON_MISSION_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* getMissionStratefyEffect() {
  yield takeEvery(strategyTypes.GET_MISSION_STRATEGY, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(getMissionStrategyReq);
      if (response) {
        yield put({
          type: strategyTypes.GET_MISSION_STRATEGY_SUCCESS,
          missionData: response.data.data,
        });
      } else {
        yield put({
          type: strategyTypes.GET_MISSION_STRATEGY_FAILED,
          errorMessage: response.data.errorMessage,
        });
      }
    } catch (error: any) {
      yield put({
        type: strategyTypes.GET_MISSION_STRATEGY_FAILED,
        errorMessage: error.data.message,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postStrategyEffect() {
  yield takeEvery(strategyTypes.POST_STRATEGY, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(postStrategyReq, payload);

      if (response) {
        yield all([
          put({
            type: strategyTypes.POST_STRATEGY_SUCCESS,
            payload: response?.data?.data?.message,
          }),
        ]);
        localStorage.removeItem('str_data');
        history.push(
          `/dashboard/viewstrategy/${response?.data?.data?.strategyId}`
        );
      } else {
        yield all([
          put({
            type: strategyTypes.POST_STRATEGY_FAILED,
            payload: response?.data?.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield all([
        put({
          type: strategyTypes.POST_STRATEGY_FAILED,
          payload: error?.data?.message,
        }),
        put({
          type: holdActions.SCROLL_TO_TOP,
        }),
      ]);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* editStrategyEffect() {
  yield takeEvery(strategyTypes.UPDATE_STRATEGY, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response: any = yield call(editStrategyReq, payload);
      if (response.data?.status) {
        yield all([
          put({
            type: strategyTypes.UPDATE_STRATEGY_SUCCESS,
            successMessage: "Congratulations! You have saved your changes",
          }),
          put({
            type: strategyTypes.GET_STRATEGY,
            payload: response?.data?.data?.editStrategy?.id,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
    } catch (error: any) {
      yield all([
        put({
          type: strategyTypes.UPDATE_STRATEGY_FAILED,
          errorMessage: error?.data?.message,
        }),
        put({
          type: holdActions.SCROLL_TO_TOP,
        }),
      ]);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export default function* rootSaga() {
  yield all([
    fork(postStrategyEffect),
    fork(getStrategyEffect),
    fork(getVisionOnMissionEffect),
    fork(getMissionStratefyEffect),
    fork(getMissionListEffect),
    fork(getStrategyBenefitEffect),
    fork(editStrategyEffect),
  ]);
}
