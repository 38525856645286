import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { dashboardTypes } from './dashboardTypes';
import {
  getOKRGraphDataReq,
  getCalculationStatusReq,
  getTopBenefitsReq,
  getTopOKRsReq,
  getEmployeeHelpfullnessReq,
  getDepartmentAchievementReq,
  postHeatmapGraphDataReq,
  getPeriodsList,
} from '../../library/services/dashboardReq';
import {
  postBenefitStarReq,
  postStarReq,
} from '../../library/services/StarReq';
import authActions from '../app/action';

export function* getOKRGraphDataEffect() {
  yield takeEvery(
    dashboardTypes?.GET_OKR_GRAPH_DATA,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(getOKRGraphDataReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_OKR_GRAPH_DATA_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_OKR_GRAPH_DATA_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_OKR_GRAPH_DATA_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* postHeatmapGraphEffect() {
  yield takeEvery(
    dashboardTypes?.POST_HEATMAP_GRAPH_DATA,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(postHeatmapGraphDataReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.POST_HEATMAP_GRAPH_DATA_SUCCESS,
            heatmapData: response.data.data.response,
          });
        } else {
          yield put({
            type: dashboardTypes?.POST_HEATMAP_GRAPH_DATA_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.POST_HEATMAP_GRAPH_DATA_FAILED,
          errorMessage: error,
        });
      }
    }
  );
}

export function* getCalculationStatusEffect() {
  yield takeEvery(
    dashboardTypes?.GET_CALCULATION_STATUS,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(getCalculationStatusReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_CALCULATION_STATUS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_CALCULATION_STATUS_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_CALCULATION_STATUS_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getTopBenefitsEffect() {
  yield takeEvery(
    dashboardTypes?.GET_TOP_BENEFITS,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(getTopBenefitsReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_TOP_BENEFITS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_TOP_BENEFITS_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_TOP_BENEFITS_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getTopOKRsEffect() {
  yield takeEvery(
    dashboardTypes?.GET_TOP_OKRS,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(getTopOKRsReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_TOP_OKRS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_TOP_OKRS_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_TOP_OKRS_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getEmployeeHelpfullnessEffect() {
  yield takeEvery(
    dashboardTypes?.GET_EMPLOYEE_HELPFULLNESS,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(getEmployeeHelpfullnessReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_EMPLOYEE_HELPFULLNESS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_EMPLOYEE_HELPFULLNESS_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_EMPLOYEE_HELPFULLNESS_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getDepartmentAchievementEffect() {
  yield takeEvery(
    dashboardTypes?.GET_DEPARTMENT_ACHIEVEMENT,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(getDepartmentAchievementReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_DEPARTMENT_ACHIEVEMENT_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_DEPARTMENT_ACHIEVEMENT_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_DEPARTMENT_ACHIEVEMENT_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* postStarEffect() {
  yield takeEvery(
    dashboardTypes?.POST_DASHBOARD_STAR,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      try {
        const response = yield call(postStarReq, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.POST_DASHBOARD_STAR_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.POST_DASHBOARD_STAR_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.POST_DASHBOARD_STAR_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* postBenefitStarEffect() {
  yield takeEvery(
    dashboardTypes.POST_DASHBOARD_BENEFIT_STAR,
    function* ({ payload }: { type: string; isIncrement: boolean; payload }) {
      try {
        const response: any = yield call(postBenefitStarReq, payload);
        if (response.status) {
          yield all([
            put({
              type: dashboardTypes.POST_DASHBOARD_BENEFIT_STAR_SUCCESS,
              payload: payload,
            }),
          ]);
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.POST_DASHBOARD_BENEFIT_STAR_FAILED,
          errorMessage: error.data.message,
        });
      }
    }
  );
}

export function* getPeriodsListEffect() {
  yield takeEvery(
    dashboardTypes?.GET_PERIODS_LIST,
    function* ({
      payload,
    }: {
      type: string;
      payload: Record<string, string | number>;
    }) {
      yield put(authActions.globalLoaderHandler(true));
      try {
        const response = yield call(getPeriodsList, payload);
        if (response.status) {
          yield put({
            type: dashboardTypes?.GET_PERIODS_LIST_SUCCESS,
            payload: response.data.data,
          });
        } else {
          yield put({
            type: dashboardTypes?.GET_PERIODS_LIST_FAILED,
            errorMessage: response.data.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: dashboardTypes?.GET_PERIODS_LIST_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(getOKRGraphDataEffect),
    fork(postHeatmapGraphEffect),
    fork(getCalculationStatusEffect),
    fork(getTopBenefitsEffect),
    fork(getTopOKRsEffect),
    fork(getEmployeeHelpfullnessEffect),
    fork(getDepartmentAchievementEffect),
    fork(postBenefitStarEffect),
    fork(postStarEffect),
    fork(getPeriodsListEffect),
  ]);
}
