import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import authActions from "../app/action";
import {
  postMyGoalsReq,
  postMyOrgChartReq,
  postAllTaskReq,
  deleteObjectiveReq,
  deleteKeyResultReq,
  getKrObjCalStatusReq,
} from "../../library/services/GoalsReq";
import { GoalActionTypes } from "./actionTypes";
import {
  postAllTaskPayload,
  postMyGoalsPayload,
  postMyOgChartPayload,
  deleteObjectivePayload,
  deleteKeyResultPayload,
  GetKrObjStatusPayload,
} from "./types";
import { holdActions } from "../Holds/holdActions";
import { keyResultTypes } from "../KeyResult/keyResultTypes";
import { Objectivetypes } from "../Objective/actionTypes";
import history from "../../library/helpers/history";

export function* postMyGoalsEffect() {
  yield takeEvery(
    GoalActionTypes.POST_MYGOALS,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement?: boolean;
      payload: postMyGoalsPayload;
    }) {
      try {
        if (payload?.loading) {
          yield put(authActions.globalLoaderHandler(true));
        }
        const response = yield call(postMyGoalsReq, payload);
        if (response) {
          yield put({
            type: GoalActionTypes.POST_MYGOALS_SUCCESS,
            payload: response,
          });
        } else {
          yield put({
            type: GoalActionTypes.POST_MYGOALS_FAILED,
            payload: response.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: GoalActionTypes.POST_MYGOALS_FAILED,
          payload: error.message,
        });
      }
      if (payload?.loading) {
        yield put(authActions.globalLoaderHandler(false));
      }
    }
  );
}

export function* postMyOrgChartReqEffect() {
  yield takeEvery(
    GoalActionTypes.POST_MY_ORG_CHART,
    function* ({
      payload,
    }: {
      type: string;
      inIncrement: boolean;
      payload: postMyOgChartPayload;
    }) {
      try {
        if (payload?.loading) {
          yield put(authActions.globalLoaderHandler(true));
        }
        const response = yield call(postMyOrgChartReq, payload);
        if (response) {
          yield put({
            type: GoalActionTypes.POST_MY_ORG_CHART_SUCCESS,
            payload: response,
          });
        } else {
          yield put({
            type: GoalActionTypes.POST_MY_ORG_CHART_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: GoalActionTypes.POST_MY_ORG_CHART_FAILED,
          payload: error.message,
        });
      }
      if (payload?.loading) {
        yield put(authActions.globalLoaderHandler(false));
      }
    }
  );
}

export function* postAllTaskReqEffect() {
  yield takeEvery(
    GoalActionTypes.POST_ALL_TASK,
    function* ({
      payload,
    }: {
      type: string;
      inIncrement: boolean;
      payload: postAllTaskPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(postAllTaskReq, payload);
        if (response) {
          yield put({
            type: GoalActionTypes.POST_ALL_TASK_SUCCESS,
            payload: response,
          });
        } else {
          yield put({
            type: GoalActionTypes.POST_ALL_TASK_FAILED,
            payload: response,
          });
        }
      } catch (error: any) {
        yield put({
          type: GoalActionTypes.POST_ALL_TASK_FAILED,
          payload: error.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* deleteObjectiveReqEffect() {
  yield takeEvery(
    GoalActionTypes.DELETE_OBJECTIVE,
    function* ({
      payload,
    }: {
      type: string;
      inIncrement: boolean;
      payload: deleteObjectivePayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(deleteObjectiveReq, payload);
        const listViewPayload = {
          page: payload.page,
          take: payload.take,
          start_date: payload.start_date,
          end_date: payload.end_date,
          sort_by: payload.sort_by,
          loading: payload?.loading,
        };
        if (response) {
          yield all([
            put({
              type: GoalActionTypes.DELETE_OBJECTIVE_SUCCESS,
              payload: response,
            }),
            put({
              type: GoalActionTypes.POST_MYGOALS,
              payload: listViewPayload,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: GoalActionTypes.DELETE_OBJECTIVE_FAILED,
            payload: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* deleteKeyResultReqEffect() {
  yield takeEvery(
    GoalActionTypes.DELETE_KEY_RESULT,
    function* ({
      payload,
    }: {
      type: string;
      inIncrement: boolean;
      payload: deleteKeyResultPayload;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const listViewPayload = {
          page: payload.page,
          take: payload.take,
          start_date: payload.start_date,
          end_date: payload.end_date,
          sort_by: payload.sort_by,
          loading: payload?.loading,
        };
        const response = yield call(deleteKeyResultReq, payload);
        if (response) {
          yield all([
            put({
              type: GoalActionTypes.DELETE_KEY_RESULT_SUCCESS,
              payload: response,
            }),
            put({
              type: GoalActionTypes.POST_MYGOALS,
              payload: listViewPayload,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: GoalActionTypes.DELETE_KEY_RESULT_FAILED,
            payload: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getKrObjCalStatusEffect() {
  yield takeEvery(
    GoalActionTypes.GET_KR_OBJ_CAL_STATUS,
    function* ({ payload }: { type: string; payload: GetKrObjStatusPayload }) {
      try {
        if (payload?.load) {
          yield put(authActions.globalLoaderHandler(true));
        }
        const keyId = payload.id;
        const ObjId = payload.obj_id;
        const myGoalsPayload = payload?.my_goals;

        const response = yield call(getKrObjCalStatusReq, payload);
        if (response.status) {
          yield put({
            type: GoalActionTypes.GET_KR_OBJ_CAL_STATUS_SUCCESS,
            payload: response?.data?.data?.message,
          });

          if (history.location.pathname === "/dashboard/mygoalslistview") {
            yield put({
              type: GoalActionTypes.POST_MYGOALS,
              payload: { ...myGoalsPayload, loading: false },
            });
          } else {
            yield put({
              type: keyResultTypes.GET_KEYRESULT,
              payload: { id: String(keyId) },
            });
            yield put({
              type: Objectivetypes.GET_OBJECTIVE,
              payload: { id: String(ObjId) },
            });
          }
        }
      } catch (error: any) {}
      if (payload?.load) {
        yield put(authActions.globalLoaderHandler(false));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(postMyGoalsEffect),
    fork(postMyOrgChartReqEffect),
    fork(postAllTaskReqEffect),
    fork(deleteObjectiveReqEffect),
    fork(deleteKeyResultReqEffect),
    fork(getKrObjCalStatusEffect),
  ]);
}
