import { configTypes } from './actionTypes';
import { configState, configStateAction } from './types';

const defaultConfigs = {
  objective_type: {
    aspirational: {
      low: {
        aspirational_low_min: 0,
        aspirational_low_max: 30,
        aspirational_low_color: '#e02929'
      },
      medium: {
        aspirational_medium_min: 30,
        aspirational_medium_max: 70,
        aspirational_medium_color: '#ea9d17'
      },
      high: {
        aspirational_high_min: 70,
        aspirational_high_max: 100,
        aspirational_high_color: '#00b95d'
      }
    },
    committed: {
      low: {
        committed_low_min: 0,
        committed_low_max: 30,
        committed_low_color: '#e02929'
      },
      medium: {
        committed_medium_min: 30,
        committed_medium_max: 70,
        committed_medium_color: '#ea9d17'
      },
      high: {
        committed_high_min: 70,
        committed_high_max: 100,
        committed_high_color: '#00b95d'
      }
    },
    aspirational_and_committed: {
      low: {
        aspCom_low_min: 0,
        aspCom_low_max: 30,
        aspCom_low_color: '#e02929'
      },
      medium: {
        aspCom_medium_min: 30,
        aspCom_medium_max: 70,
        aspCom_medium_color: '#ea9d17'
      },
      high: {
        aspCom_high_min: 70,
        aspCom_high_max: 100,
        aspCom_high_color: '#00b95d'
      }
    }
  },
  confidence_level: {
    confidence_level: {
      low: {
        confidence_low_min: 0,
        confidence_low_max: 3,
        confidence_low_color: '#e02929'
      },
      medium: {
        confidence_medium_min: 3,
        confidence_medium_max: 7,
        confidence_medium_color: '#ea9d17'
      },
      high: {
        confidence_high_min: 7,
        confidence_high_max: 10,
        confidence_high_color: '#00b95d'
      }
    }
  },
  assistancePoints: 1,
  helpfulnessRating: 5,
  periodDuration: 3,
  gracePeriod: 5,
  fiscalYear: 2
};

const initState: configState = {
  // @ts-ignore
  configData: defaultConfigs,
  errorMessage: '',
  UpdateSuccessMessage: '',
  UpdateErrorMessage: ''
};

const configDataModifier = (data) => {
  const dataObj = {
    objective_type: {
      aspirational: {
        low: {
          aspirational_low_min: 0,
          aspirational_low_max: data.aspirational.low,
          aspirational_low_color: data.aspirational.low_color
        },
        medium: {
          aspirational_medium_min: data.aspirational.low,
          aspirational_medium_max: data.aspirational.medium,
          aspirational_medium_color: data.aspirational.medium_color
        },
        high: {
          aspirational_high_min: data.aspirational.medium,
          aspirational_high_max: data.aspirational.high,
          aspirational_high_color: data.aspirational.high_color
        }
      },
      committed: {
        low: {
          committed_low_min: 0,
          committed_low_max: data.committed.low,
          committed_low_color: data.committed.low_color
        },
        medium: {
          committed_medium_min: data.committed.low,
          committed_medium_max: data.committed.medium,
          committed_medium_color: data.committed.medium_color
        },
        high: {
          committed_high_min: data.committed.medium,
          committed_high_max: data.committed.high,
          committed_high_color: data.committed.high_color
        }
      },
      aspirational_and_committed: {
        low: {
          aspCom_low_min: 0,
          aspCom_low_max: data.committed_and_aspirational.low,
          aspCom_low_color: data.committed_and_aspirational.low_color
        },
        medium: {
          aspCom_medium_min: data.committed_and_aspirational.low,
          aspCom_medium_max: data.committed_and_aspirational.medium,
          aspCom_medium_color: data.committed_and_aspirational.medium_color
        },
        high: {
          aspCom_high_min: data.committed_and_aspirational.medium,
          aspCom_high_max: data.committed_and_aspirational.high,
          aspCom_high_color: data.committed_and_aspirational.high_color
        }
      }
    },
    confidence_level: {
      confidence_level: {
        low: {
          confidence_low_min: 0,
          confidence_low_max: data.confidence_level.low,
          confidence_low_color: data.confidence_level.low_color
        },
        medium: {
          confidence_medium_min: data.confidence_level.low,
          confidence_medium_max: data.confidence_level.medium,
          confidence_medium_color: data.confidence_level.medium_color
        },
        high: {
          confidence_high_min: data.confidence_level.medium,
          confidence_high_max: data.confidence_level.high,
          confidence_high_color: data.confidence_level.high_color
        }
      }
    },
    assistancePoints: data.assistance_points,
    helpfulnessRating: data.helpfulness_rating,
    periodDuration: data.period,
    gracePeriod: data.grace_period,
    fiscalYear: data.financial_year_month
  };
  return dataObj;
};

export default function configReducer(state = initState, action: configStateAction) {
  switch (action.type) {
    case configTypes.GET_DEFAULT_CONFIG_SUCCESS:
      return {
        ...state,
        configData: configDataModifier(action.configData)
      };
    case configTypes.GET_DEFAULT_CONFIG_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        configData: defaultConfigs
      };
    case configTypes.EDIT_DEFAULT_CONFIG_SUCCESS:
      return {
        ...state,
        UpdateSuccessMessage: action.UpdateSuccessMessage,
        UpdateErrorMessage: ''
      };
    case configTypes.EDIT_DEFAULT_CONFIG_FAILURE:
      return {
        ...state,
        UpdateSuccessMessage: '',
        UpdateErrorMessage: action.UpdateErrorMessage
      };
    case configTypes.REMOVE_ALERT:
      return {
        ...state,
        UpdateSuccessMessage: '',
        UpdateErrorMessage: '',
        errorMessage: ''
      };
    default:
      return {
        ...state
      };
  }
}
