import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FilterTypes } from './actionTypes';
import authActions from '../../app/action';
import { OrgRolesReq, industryTypeReq, rolesReq, workspacesReq } from '../../../library/services/Admin/filters';

export function* companyIndustryEffect() {
  yield takeEvery(FilterTypes.COMPANY_INDUSTRY, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(industryTypeReq);
      if (response) {
        yield put({
          type: FilterTypes.COMPANY_INDUSTRY_SUCCESS,
          industryList: response.data.data.companyData
        });
      }
    } catch (error) {
      Promise.reject();
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* workspaceFilterEffect() {
  yield takeEvery(FilterTypes.WORKSPACES_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(workspacesReq);
      if (response) {
        yield put({
          type: FilterTypes.WORKSPACES_SUCCESS,
          workspaceList: response.data.data.companyWorkspaces
        });
      }
    } catch (error) {
      Promise.reject();
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* rolesEffect() {
  yield takeEvery(FilterTypes.GET_ALL_ROLES_REQUEST, function* () {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const response = yield call(rolesReq);
      if (response) {
        yield put({
          type: FilterTypes.GET_ALL_ROLES_SUCCESS,
          defaultRoles: response.data.data.defaultRole,
          customRoles: response.data.data.others
        });
      } else {
      }
    } catch (error: any) {
      Promise.reject();
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* OrgRolesEffect() {
  yield takeEvery(
    FilterTypes.GET_ROLES_BY_ID_REQUEST,
    function* ({ payload }: { type: string; payload: string | number }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(OrgRolesReq, payload);
        if (response) {
          yield put({
            type: FilterTypes.GET_ROLES_BY_ID_SUCCESS,
            allOrgRoles: response.data.data.role
          });
        }
      } catch (error) {
        Promise.reject();
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* AdminFiltersSaga() {
  yield all([fork(companyIndustryEffect), fork(workspaceFilterEffect), fork(OrgRolesEffect), fork(rolesEffect)]);
}
