import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { missionTypes } from "./missionTypes";
import authActions from "../app/action";
import {
  getMissionReq,
  postMissionReq,
  getStrategyInMissionReq,
  getVisionInMissionReq,
  editMissionReq,
} from "../../library/services/missionReq";
import history from "../../library/helpers/history";
import { holdActions } from "../Holds/holdActions";

export function* addMissionEffect() {
  yield takeEvery(
    missionTypes.POST_MISSION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(postMissionReq, payload);
        if (response.status) {
          yield all([
            put({
              type: missionTypes.POST_MISSION_SUCCESS,
              successMessage: response?.data?.data?.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
          localStorage.removeItem('vsn_data');
          history.push(`viewmission/${response?.data?.data?.mission}`);
        }
      } catch (error: any) {
        yield all([
          put({
            type: missionTypes.POST_MISSION_FAILED,
            errorMessage: error?.data?.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getMissionEffect() {
  yield takeEvery(
    missionTypes.GET_MISSION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(getMissionReq, payload);
        if (response?.status) {
          yield put({
            type: missionTypes.GET_MISSION_SUCCESS,
            mission: response?.data?.data,
          });
        }
      } catch (error: any) {
        yield put({
          type: missionTypes.GET_MISSION_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getStrategyInMissionEffect() {
  yield takeEvery(
    missionTypes.GET_STRATEGY_IN_MISSION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(getStrategyInMissionReq, payload);
        if (response?.status) {
          yield put({
            type: missionTypes.GET_STRATEGY_IN_MISSION_SUCCESS,
            payload: response?.data?.data,
          });
        }
      } catch (error: any) {
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getVisionInMissionEffect() {
  yield takeEvery(missionTypes.GET_VISION_IN_MISSION, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: Record<string, string | number>;
   }) {
    try {
      yield put(authActions.globalLoaderHandler(true));

      const response: any = yield call(getVisionInMissionReq, payload);
      if (response?.status) {
        yield put({
          type: missionTypes.GET_VISION_IN_MISSION_SUCCESS,
          payload: response?.data?.data?.vision,
        });
      }
    } catch (error: any) {
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* editMissionEffect() {
  yield takeEvery(
    missionTypes.EDIT_MISSION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response: any = yield call(editMissionReq, payload);
        if (response.data?.status) {
          yield all([
            put({
              type: missionTypes.EDIT_MISSION_SUCCESS,
              successMessage: "Congratulations! You have saved your changes.",
            }),
            put({
              type: missionTypes.GET_MISSION,
              payload: {id: response?.data?.data?.editMission?.id },
            }),
            put({
              type: holdActions.SCROLL_TO_TOP
            })
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: missionTypes.EDIT_MISSION_FAILED,
            errorMessage: error?.data?.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(addMissionEffect),
    fork(getMissionEffect),
    fork(getStrategyInMissionEffect),
    fork(getVisionInMissionEffect),
    fork(editMissionEffect),
  ]);
}
