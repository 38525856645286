/** @format */

import styled, { createGlobalStyle } from "styled-components";
import interRegular from "../fonts/Inter-Regular.ttf";
import interMedium from "../fonts/Inter-Medium.ttf";
import interBold from "../fonts/Inter-Bold.ttf";
import interBlack from "../fonts/Inter-Black.ttf";
import { variables } from "./variables";
import errorIcon from "../images/error.svg";
import WithDirection from "../../library/helpers/rtl";
import "../icons.css";

import "antd/dist/antd.css";

export const ModalTitle = styled.div`
  color: #e02929;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 15px;
  @media screen and (max-width: 1800px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 21px;
    line-height: 25px;
  }
  @media screen and (max-width: 1199px) {
    font-size: 20px;
    line-height: 22px;
  }
  &.saveTitle {
    color: ${variables.btn_bg_color};
  }
`;
export const ModalPera = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;
  color: ${variables.darkGray};
  font-weight: 400;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &.percentage {
    font-weight: 600;
  }
  &.red {
    color: #e02929;
  }
  &.yellow {
    color: #ea9d17;
  }
  &.green {
    color: #00b95d;
  }
  &.Open {
    color: #00f727;
  }
  &.Closed {
    color: #fe0038;
  }
  &.Reopen {
    color: #184393;
  }
  &.Archived {
    color: #e5c12e;
  }
  .promo-add-alert {
    max-width: 100% !important;
  }
`;

export const ModalLink = styled.a`
  color: ${variables.darkGray};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  transition: 0.2s all;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &:hover {
    color: #32386a;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  // font-weight: 700;
  color: ${variables.title_color};
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  font-weight: 700;
  @media screen and (max-width: 1600px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1199px) {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  &.scorecard-title {
    font-family: "Inter-Bold", sans-serif;
  }
  &.title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
    @media screen and (max-width: 1600px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media screen and (max-width: 1440px) {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &.comment-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    @media screen and (max-width: 1600px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.commentTitle {
    color: #2d2c2c;
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    @media screen and (max-width: 1600px) {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .timeTrack {
      color: #b8b8b8;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin-left: 15px;
      @media screen and (max-width: 1600px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &.score-title {
    color: #035592;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    @media screen and (max-width: 1440px) {
      font-size: 16px;
      line-height: 20px;
    }
    /* @media screen and (max-width: 1440px) {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
    } */
  }
  .icon {
    margin-left: 5px;
  }
  .anticon {
    &.anticon-info-circle {
      /* position: absolute; */
      top: 3px;
      right: -20px !important;
      color: #9e9e9e;
      @media screen and (max-width: 1600px) {
        font-size: 16px;
      }
    }
  }
  .object-title {
    .anchor-tag:hover {
      text-decoration: underline;
      color: #1249a7;
    }
  }
`;
export const ParaText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: #2d2c2c;
  @media screen and (max-width: 1400px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
export const SmallParaText = styled.p`
  font-size: 14px !important;
  line-height: 17px !important;
  color: #9e9e9e;
  @media screen and (max-width: 1400px) {
    font-size: 13px !important;
    line-height: 17px !important;
  }
`;
export const LinkBtn = styled.a`
  color: #1249a7;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  /* @media screen and (max-width: 1440px) {
    font-size: 13px;
    line-height: 17px;
  } */
  .icon {
    float: left;
    margin-right: 15px;
    font-size: 22px;
  }
  .linkBtn {
    margin-right: 20px;
  }
  &:hover {
    color: #32386a;
    a {
      color: #32386a;
    }
    .icon {
      .path2,
      .path3 {
        &::before {
          color: #32386a;
        }
      }
    }
  }
  a {
    color: #1249a7;
  }
  &:focus {
    .isoIconWrapper {
      text-decoration: none;
    }
    span {
      text-decoration: none;
    }
  }
`;

export const LinkBtns = styled.span`
  color: #1249a7;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  /* @media screen and (max-width: 1440px) {
    font-size: 13px;
    line-height: 17px;
  } */
  .icon {
    float: left;
    margin-right: 15px;
    font-size: 22px;
  }
  .linkBtn {
    margin-right: 20px;
  }
  &:hover {
    color: #32386a;
    span {
      color: #32386a;
    }
    .icon {
      .path2,
      .path3 {
        &::before {
          color: #32386a;
        }
      }
    }
  }
  span {
    color: #1249a7;
  }
`;

export const SubTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: ${variables.darkGray};
  /* margin-bottom: 35px; */
  @media screen and (max-width: 1600px) {
    font-size: 14px;
  }
  &.keySubtitle {
    float: left;
  }
`;
export const Avatar = styled.div`
  max-width: 30px;
  width: 100%;
  height: 30px;
  border-radius: 50%;
  background-color: #f4f4f4;
  margin-right: 20px;
  &.taskAvatar {
    margin-right: 10px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`;
export const Pagination = styled.div`
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      margin-right: 5px;
      list-style-type: none;
      &.pageNumber {
        color: #b8b8b8;
        font-size: 14px;
        line-height: 17px;
      }
      &.btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
        cursor: pointer;
        &:hover {
          color: #b8b8b8;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const LevelBtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const LevelBtn = styled.div`
  background: #b8b8b8;
  padding: 8px 10px;
  margin-right: 15px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  @media screen and (max-width: 1800px) {
    font-size: 15px;
    line-height: 19px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &:last-child {
    margin-right: 0;
  }
  .icon {
    float: left;
    margin-right: 5px;
    display: none;
    &::before {
      font-size: 12px;
      color: #fff;
    }
  }
  &.high {
    color: #e02929;
    background: #ffe2e2;
    .icon {
      display: inline-block;
      &::before {
        color: #e02929;
      }
    }
  }
  &.medium {
    color: #ea9d17;
    background: #fff2db;
    .icon {
      display: inline-block;
      &::before {
        color: #ea9d17;
      }
    }
  }
  &.low {
    color: #00b95d;
    background: #e3ffe3;
    .icon {
      display: inline-block;
      &::before {
        color: #00b95d;
      }
    }
  }
`;

export const CustomizedLevelBtn = styled.button`
  background: #b8b8b8;
  padding: 8px 10px;
  margin-right: 15px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
    outline: 0.5px solid #666666;
  }
  @media screen and (max-width: 1800px) {
    font-size: 15px;
    line-height: 19px;
  }
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &:last-child {
    margin-right: 0;
  }
  .icon {
    float: left;
    margin-right: 5px;
    display: none;
    &::before {
      font-size: 12px;
      color: #fff;
    }
  }
  &.high {
    color: #e02929;
    background: #ffe2e2;
    .icon {
      display: inline-block;
      &::before {
        color: #e02929;
      }
    }
    &:focus {
      background-color: #ffe2e2;
      outline: 1px solid #e02929;
    }
  }
  &.medium {
    color: #ea9d17;
    background: #fff2db;
    .icon {
      display: inline-block;
      &::before {
        color: #ea9d17;
      }
    }
    &:focus {
      background-color: #fff2db;
      outline: 1px solid #ea9d17;
    }
  }
  &.low {
    color: #00b95d;
    background: #e3ffe3;
    .icon {
      display: inline-block;
      &::before {
        color: #00b95d;
      }
    }
    &:focus {
      background-color: #e3ffe3;
      outline: 1px solid #00b95d;
    }
  }
`;

export const Box = styled.div`
  position: relative;
  padding: 35px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
  margin-bottom: 15px;
  overflow: hidden;
  transition: 0.4s all;
  @media screen and (max-width: 1600px) {
    padding: 30px;
  }
  @media screen and (max-width: 1440px) {
    padding: 25px;
  }
  @media screen and (max-width: 991px) {
    padding: 25px 20px;
  }

  &.paddon {
    padding: 35px;
    @media screen and (max-width: 1800px) {
      padding: 25px;
    }
    @media screen and (max-width: 991px) {
      padding: 20px;
    }
  }
  &.paddoff {
    padding: 12px 35px 35px;
    @media screen and (max-width: 1800px) {
      padding: 10px 25px 30px;
    }
    @media screen and (max-width: 991px) {
      padding: 12px 20px 30px;
    }
  }

  > .icon {
    float: right;
    display: flex;
    /* align-items: center; */
    position: absolute;
    right: 35px;
    z-index: 1;
    @media screen and (max-width: 1199px) {
      right: 25px;
    }
    .link {
      color: #22c4f2;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-right: 25px;
      @media screen and (max-width: 1600px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 13px;
      }
      &:focus {
        text-decoration: underline;
      }
    }
    .icon-table-arrow-up {
      cursor: pointer;
      /* margin-top: 5px; */
      &::before {
        font-size: 10px;
        @media screen and (max-width: 1600px) {
          font-size: 8px;
        }
      }
    }
    .icon-table-arrow-down {
      cursor: pointer;
      margin-top: 1px;
      &::before {
        font-size: 10px;
        @media screen and (max-width: 1600px) {
          font-size: 8px;
        }
      }
    }
    .tooltip-icon {
      svg {
        fill: #9e9e9e;
        font-size: 20px;
        @media screen and (max-width: 1600px) {
          font-size: 16px;
        }
      }
    }
    .ant-spin {
      height: 450px;
      @media screen and (max-width: 1800px) {
        height: 400px;
      }
      @media screen and (max-width: 1600px) {
        height: 350px;
      }
      @media screen and (max-width: 1199px) {
        height: 300px;
      }
      @media screen and (max-width: 991px) {
        height: 250px;
      }
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
  }

  .form,
  .billing-info-page form {
    &.key-timebox {
      .ant-form-item-label label::after {
        display: inline-block;
        content: ":";
      }
    }
    .ant-form-item {
      .ant-form-item-label {
        padding: 0 0 10px;
        @media screen and (max-width: 991px) {
          padding: 0 0 10px;
        }
        label {
          font-size: 20px;
          line-height: 25px;
          position: relative;
          @media screen and (max-width: 1600px) {
            font-size: 18px;
            line-height: 22px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 16px;
            line-height: 20px;
          }
          // &::after{
          //   content: ":";
          //   position: absolute;
          //   right: -15px;
          // }
        }
      }
      /* .ant-form-item.reminder {
        margin-bottom: 15px !important;
      } */
      .date-time-group {
        display: table-cell;
        padding: 11.5px;
        border: 1px solid #dbd8d8;
        border-radius: 5px;
        &:focus-within {
          outline: 1px solid ${variables.focusColor};
        }
        .task-date input:focus {
          outline: none !important;
        }
        .ant-form-item-with-help .ant-form-item-explain {
          position: absolute;
          bottom: -35px;
          @media screen and (max-width: 1440px) {
            bottom: -30px;
          }
        }
        @media screen and (max-width: 1600px) {
          padding: 9.5px;
        }
        @media screen and (max-width: 1440px) {
          padding: 8px;
        }
        .ant-form-item {
          margin-bottom: 0px !important;
          &.reminder {
            width: 156px;
          }
        }
        & > div {
          width: 240px;
          @media screen and (max-width: 1199px) {
            width: 210px;
          }
          margin-bottom: 0px;
          .task-date {
            width: 100%;
            &.time {
              width: 156px;
              border-left: 1px solid #dbd8d8 !important;
              border-radius: 0px;
            }
          }
        }

        .date-time {
          height: 43px !important;
          border: none !important;
          @media screen and (max-width: 1600px) {
            height: 39px !important;
          }
          @media screen and (max-width: 1600px) {
            height: 29px !important;
          }
          &.date {
            padding-left: 0px;
          }
          &.time {
            padding-right: 0px;
          }
          .ant-picker-input {
            flex-direction: row-reverse;
            .ant-picker-suffix {
              margin-right: 14px;
            }
          }
          &.ant-picker-focused {
            box-shadow: none;
          }
          .ant-picker {
            &.ant-picker-focused {
              box-shadow: none !important;
            }
          }
          &:focus .ant-picker-panel {
            .ant-picker-footer > .ant-picker-now > a {
              text-decoration: underline;
            }
          }
        }
        .task-date.ant-form-item-has-error .ant-picker {
          border: 1px solid red !important;
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          bottom: -35px;
          left: -8px;
        }
      }
      .ant-form-item-control-input-content {
        .date-time {
          height: 64px;
          border: 1px solid #dbd8d8;
          border-radius: 5px;
          max-width: 400px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 1600px) {
            height: 60px;
          }
          @media screen and (max-width: 1199px) {
            height: 50px;
          }
          /* padding: 0; */

          .ant-picker {
            border: none;
            height: 100%;
            background: transparent;
            &.date {
              width: 60%;
              padding: 20px 10px 20px 15px;
              @media screen and (max-width: 991px) {
                padding: 20px 10px 20px 10px;
              }
            }
            .ant-picker-input {
              input {
                order: 2;
                @media screen and (max-width: 1600px) {
                  font-size: 13px;
                  line-height: 17px;
                }
                &::placeholder {
                  font-style: italic;
                  font-size: 16px;
                  line-height: 20px;
                  color: #b8b8b8;
                  @media screen and (max-width: 1600px) {
                    font-size: 13px;
                    line-height: 18px;
                  }
                }
              }
              .ant-picker-suffix {
                margin-right: 14px;
                color: #b8b8b8;
                font-size: 18px;
                .anticon-calendar {
                  display: flex;
                  &::before {
                    content: "\\e922";
                    font-family: "icomoon" !important;
                    display: block;
                    color: #b8b8b8;
                  }
                  svg {
                    display: none;
                  }
                }
              }
            }
            &.time {
              width: 40%;
              /* border-left: 1px solid #dbd8d8; */
              padding: 20px 15px 20px 10px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 40px;
                width: 1px;
                background: #dbd8d8;
                @media screen and (max-width: 1199px) {
                  height: 30px;
                }
              }
            }
          }

          &.task-date {
            max-width: 240px;
            .ant-picker {
              width: 100%;
            }
            &.single-date:focus-within {
              outline: 0.5px solid ${variables.focusColor} !important;
            }
            input:focus {
              outline: none !important;
            }
          }
          .float-label {
            margin-bottom: 0;
            height: 100%;
            .ant-picker {
              border: none;
              height: 100%;
              background: transparent;
              &.date {
                width: 100%;
                &.focused {
                  input {
                    margin-top: 20px;
                  }
                }
              }
              &.time {
                width: 100%;
                &.focused {
                  input {
                    margin-top: 20px;
                  }
                }
              }
            }
            .label {
              display: none;
            }
            &.focus {
              &.float-label {
                position: relative;
                width: 100%;
                .label {
                  display: block;
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: normal;
                  position: absolute;
                  pointer-events: none;
                  left: 50px;
                  top: 10px;
                  transition: 0.2s ease all;
                  color: #b8b8b8;
                  @media screen and (max-width: 1600px) {
                    top: 10px;
                    font-size: 12px;
                    line-height: 14px;
                  }
                  &.label-float {
                    top: 10px;
                    @media screen and (max-width: 1600px) {
                      top: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.select-benifit-wrapper {
        &.scorecard-validation-error {
          position: relative;
          .ant-form-item-explain-error {
            position: absolute;
            top: -55px;
            right: -35px;
            background: #ffe2e2;
            padding: 8px 16px;
            border-radius: 8px 0px 0px 8px;
            max-width: 280px;
            color: #e02929;
            @media screen and (max-width: 1600px) {
              right: -30px;
              top: -40px;
            }
            @media screen and (max-width: 1440px) {
              right: -25px;
              top: -40px;
              padding: 6px 12px;
              font-size: 13px;
            }
          }
        }
        .ant-checkbox-wrapper {
          .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 5px;
            color: #035592 !important;
            @media screen and (max-width: 1600px) {
              font-size: 15px;
              line-height: 18px;
              margin-bottom: 0;
            }
            @media screen and (max-width: 1440px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
          .subtitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color: #2d2c2c;
            @media screen and (max-width: 1600px) {
              font-size: 13px;
              line-height: 15px;
              margin-top: 3px;
            }
          }

          .ant-checkbox {
            display: none;
            &.ant-checkbox-checked {
              display: none;
            }
          }
        }
        .ant-select {
          max-width: 420px;
          .ant-select-selector {
            height: 64px !important;
            @media screen and (max-width: 1600px) {
              height: 60px !important;
            }
            @media screen and (max-width: 991px) {
              height: 50px !important;
            }
            .ant-select-selection-item {
              line-height: 20px;
              .ant-checkbox {
                .ant-checkbox-checked {
                  .ant-checkbox-inner {
                    border-color: ${variables.btn_bg_color};
                    &::after {
                      border-color: ${variables.btn_bg_color};
                    }
                  }
                }
              }
            }
            .ant-select-selection-overflow-item {
              &.ant-select-selection-overflow-item-rest {
                display: none !important;
              }
            }
          }

          &.ant-select-disabled {
            .ant-select-selector {
              height: auto !important;
            }
            .anticon {
              &.anticon-info-circle {
                right: -15px;
                @media screen and (max-width: 1600px) {
                  right: -10px;
                }
              }
            }
          }
        }
        &.vmsb-period {
          align-content: baseline;
          .ant-select-disabled {
            .ant-select-selector {
              border: 1px solid #dbd8d8 !important;
              // span.ant-select-selection-placeholder
              // {
              //   color: #d5d5d5;
              // }
              height: 64px !important;
              @media screen and (max-width: 1600px) {
                height: 60px !important;
              }
              @media screen and (max-width: 991px) {
                height: 50px !important;
              }
            }
          }
        }
      }
    }
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 991px) {
    margin-top: 5px;
  }
  &.KeyBtnWrapper {
    justify-content: space-between;
  }
  &.modal-footer-btn {
    .red {
      background: #e02929;
      border: 1px solid #e02929;
      color: #fff;
      &:hover {
        color: #e02929;
        background: none;
        border: 1px solid #e02929;
      }
    }
    justify-content: center;
  }
  &.addKrBtn {
    a > span {
      .path2::before,
      .path3:before {
        color: #808080;
      }
    }
  }
`;
export const CustomBtn = styled.a`
  border: 1px solid ${variables.btn_bg_color};
  padding: 9px 10px;
  margin-right: 15px;
  border-radius: 10px;
  color: ${variables.btn_bg_color};
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  transition: 0.2s all;
  display: table;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &.reset-btn {
    color: #22c4f2;
    border: none;
    &:hover {
      border: none;
      color: #22c4f2;
      background: none;
    }
  }
  &.fill {
    background: ${variables.btn_bg_color};
    color: #fff;
    box-shadow: 0px 1px 4px rgba(3, 85, 146, 0.2);
    &:hover {
      color: ${variables.btn_bg_color};
      background: none;
      border: 1px solid ${variables.btn_bg_color};
    }
  }
  &:hover {
    color: #fff;
    background: ${variables.btn_bg_color};
    /* border: 1px solid ${variables.btnHoverBg}; */
  }
  &:last-child() {
    margin-right: 0;
  }
  &.m-0 {
    margin-right: 0;
  }
  &.filter-btn {
    .anticon {
      margin-right: 10px;
    }
    :hover,
    :focus {
      .anticon > svg {
        path {
          stroke: ${variables.btn_bg_color} !important;
        }
      }
    }
  }
`;

export const CustomPopUpBtn = styled.button`
  border: 1px solid ${variables.btn_bg_color};
  padding: 9px 10px;
  margin-right: 15px;
  border-radius: 10px;
  color: ${variables.btn_bg_color};
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  transition: 0.2s all;
  display: table;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &.reset-btn {
    color: #22c4f2;
    border: none;
    &:hover {
      border: none;
      color: #22c4f2;
      background: none;
    }
  }
  &.fill {
    background: ${variables.btn_bg_color};
    color: #fff;
    box-shadow: 0px 1px 4px rgba(3, 85, 146, 0.2);
    &:hover,
    &:focus,
    &:focus-visible {
      outline: none;
      background-color: #fff;
      border-color: #035592;
      color: #035592;
      /* color: ${variables.btn_bg_color}; */
      background: none;
      /* border: 1px solid ${variables.btn_bg_color}; */
    }
  }
  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    color: #fff;
    background: ${variables.btn_bg_color};
    /* border: 1px solid ${variables.btnHoverBg}; */
  }
  &:last-child() {
    margin-right: 0;
  }
  &.m-0 {
    margin-right: 0;
  }
`;

export const CommentBtn = styled.button`
  border: 1px solid ${variables.btn_bg_color};
  padding: 9px 10px;
  margin-right: 15px;
  border-radius: 10px;
  color: ${variables.btn_bg_color};
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  transition: 0.2s all;
  display: table;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
    line-height: 18px;
  }
  &.reset-btn {
    color: #22c4f2;
    border: none;
    &:hover {
      border: none;
      color: #22c4f2;
      background: none;
    }
  }
  &.fill {
    background: ${variables.btn_bg_color};
    color: #fff;
    box-shadow: 0px 1px 4px rgba(3, 85, 146, 0.2);
    &:hover,
    &:focus {
      color: ${variables.btn_bg_color};
      background: none;
      outline: 0.5px solid ${variables.btn_bg_color};
      border-color: transparent;
    }
  }
  &:hover {
    color: #fff;
    background: ${variables.btn_bg_color};
    /* border: 1px solid ${variables.btnHoverBg}; */
  }
  &:last-child() {
    margin-right: 0;
  }
  &.m-0 {
    margin-right: 0;
  }
`;
export const WrongContainer = styled.div`
  height: 100vh;
  position: relative;
  text-align: center;
  .wrong-wrapper-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      max-width: 300px;
      @media screen and (max-width: 1440px) {
        max-width: 280px;
      }
      @media screen and (max-width: 1199px) {
        max-width: 250px;
      }
    }
    h1 {
      margin: 20px 0px 10px;
      font-family: "Inter-Bold", sans-serif;
      font-size: 26px;
      line-height: 31px;
      color: #035592;
      @media screen and (max-width: 1800px) {
        font-size: 24px;
        line-height: 30px;
      }
      @media screen and (max-width: 1600px) {
        font-size: 22px;
        line-height: 28px;
      }
      @media screen and (max-width: 1440px) {
        margin: 15px 0px 5px;
      }
      @media screen and (max-width: 1199px) {
        margin: 10px 0px 5px;
      }
    }
    p {
      font-size: 16px;
      line-height: 19px;
      color: #2d2c2c;
      @media screen and (max-width: 1600px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

export const AdminTitle = styled.div`
  .title {
    width: 100%;
    font-size: 20px;
    color: ${variables.btn_bg_color};
    display: flex;
    span {
      &:first-child {
        color: #2d2c2c;
      }
      &:last-child {
        color: #9e9e9e;
        margin-left: 5px;
      }
    }
  }
  margin-bottom: 25px;
`;
const GlobalStyles = createGlobalStyle`
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 90vh !important;
}
@font-face {
    font-family: inter-Regular;
    src: url(${interRegular});
  }
  @font-face {
    font-family: inter-Medium;
    src: url(${interMedium});
  }
@font-face {
    font-family: inter-Bold;
    src: url(${interBold});
  }
  @font-face {
    font-family: inter-Black;
    src: url(${interBlack});
  }
  html 
  {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body 
  {
    font-family: 'Inter-Regular', sans-serif;
    background-color: #f7f7f7;
  }
  .isoLogoWrapper {
    width: 100%;
    display: flex;
    margin-bottom: 0px;
    justify-content: center;
    flex-shrink: 0;
    
    img 
    {
      @media only screen and (max-width: 1800px)
      {
        max-width: 250px;
      }
      @media only screen and (max-width: 1440px)
      {
        max-width: 200px;
      }
    }
  }
  .title 
  {
    font-family: 'Inter-Bold', sans-serif;
    color: ${variables.title_color};
    font-size: 20px;
    line-height: 24px;
    
    @media only screen and (max-width: 1600px)
    {
      font-size: 18px;
      line-height: 20px;
    }
    @media only screen and (max-width: 1440px)
    {
      font-size: 16px;
      line-height: 18px;
    }
  }
  h1.super-title 
  {
    font-size: 26px;
    line-height: 31px;
    white-space: nowrap;
    @media screen and (max-width: 1800px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media screen and (max-width: 1600px) {
      font-size: 22px;
      line-height: 28px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 21px;
      line-height: 25px;
    }
    @media screen and (max-width: 1199px) {
      font-size: 20px;
      line-height: 22px;
    }
  }
  h1.price {
    .strike-text {
      text-decoration: line-through !important;
      font-size: 16px;
    }
  }
  /* modal mask */
  .ant-modal-mask{
    background: rgba(78, 78, 78, 0.8);
  }
  //checkbox
  .ant-checkbox-wrapper{
    cursor: pointer !important;
    .ant-checkbox-checked {
      &::after
      {
        border-color: #035592!important;
      }
    }
    .ant-checkbox{
      &.ant-checkbox-checked{
        input[checked] {
          cursor: pointer !important;
          &+.ant-checkbox-inner{
            border-color: #035592 !important;
            &::after{
              border-color: #035592 !important;
            }
          }
        }
        &:hover 
        {
          border-color: #035592;
        }
        .ant-checkbox-inner{
          width: 18px;
          height: 18px;
          background-color: white;
          border-color: #035592;
          @media screen and (max-width: 1440px){
            width: 16px;
            height: 16px;
          }
          &::after{
            border-color: #035592;
            /* left: 3px;
            top: 7px; */
            @media screen and (max-width: 1440px){
              /* left: 2px;
              top: 6px; */
          }
          }
        }
      }
      .ant-checkbox-inner{
          width: 18px;
          height: 18px;
          background-color: white;
          border-color: #B8B8B8;
          @media screen and (max-width: 1440px){
            width: 16px;
            height: 16px;
          }
          &::after{
            border-color: #B8B8B8;
          }
        }
    }
                    
    &.ant-checkbox-wrapper-checked  
    {
      cursor: default;
      .ant-checkbox,.ant-checkbox-inner
      {
        cursor: default;
        &.ant-checkbox-checked, .ant-checkbox-input
        {
          cursor: default;
          &::after
          {
            border: none;
          }
          
        }
      }
      
      input[checked]
      {
        cursor: not-allowed!important;
        .ant-checkbox-checked,.ant-checkbox-input
        {
          cursor: not-allowed!important;
        }
        .ant-checkbox-inner
        {
          cursor: default!important;
          &::after{
              border-color: #B8B8B8!important;
            }
        }
        & + .ant-checkbox-inner
        {
          border-color: #B8B8B8!important;
          &::after{
              border-color: #B8B8B8!important;
            }
        }
      }
      
      
    }
    
    
    
  }
form{
  display: flex;
  flex-wrap: wrap;
  .ant-form-item{
    margin-bottom: 35px;
    width: 100%;
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before
      {
        position: absolute;
        color: #e02929;
        right: -4px;
        top: 0;
        font-family: inherit;
      }
    @media screen and (max-width: 1800px){  
      margin-bottom: 25px!important;
    }
    @media screen and (max-width: 1600px){  
      margin-bottom: 20px!important;
    }
    @media screen and (max-width: 1199px){  
      margin-bottom: 18px!important;
    }
    .ant-select-clear, .ant-picker-clear 
          {
            width: 20px;
            height: 20px;
            background: #ffe2e2;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top:0px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 10px;
            right: 11px;
            i 
            {
              &::before 
              {
                color: #e02929;
                font-size: 10px;;
              }
            }
          }
          .ant-picker-clear 
          {
            
   
    margin-top: 0px;
    right: 11px;
    top: 11.5px;

          }
    &.ant-form-item-has-error
    {
      .ant-input 
      {
        border: 1px solid #ff4d4f!important;
      }
    }
    .ant-form-item-control{
      .ant-form-item-control-input-content{
        .users-input {
          width: auto;
        }
        .ant-input,.PhoneInputInput{
          border: 1px solid #DBD8D8;
          height: 40px;
          border-radius: 5px;
          font-size: 16px;
          line-height: 20px;
          @media screen and (max-width: 1600px){
              font-size: 14px;
              line-height: 18px;
          }
          &::placeholder{
            font-size: 16px;
            font-style: italic;
            @media screen and (max-width: 1600px){
              font-size: 14px;
            }
          }
          &.disable-input{
            background-color: #f3f3f3 !important;
            cursor: not-allowed;
          }
        }
        textarea.ant-input{
          min-height: 80px;
          border-radius: 5px;
          /* font-style: italic; */
          font-size: 16px;
          line-height: 20px;
          padding: 10px 12px;
          &.size-bg
          {
            min-height: 160px;
          }
          @media screen and (max-width: 1600px){
              font-size: 14px;
              line-height: 18px;
          }
          &::placeholder{
            font-size: 16px;
            font-style: italic;
            @media screen and (max-width: 1600px){
              font-size: 14px;
            }
          }
          &.ant-input-disabled{
            background: none;
            border: none;
            cursor: revert;
            height: auto !important;
            resize: none;
            color: ${variables.darkGray};
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            overflow: visible;
            min-height: unset;
          }
        }
        .ant-radio-group{
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .ant-radio-wrapper{
            color: ${variables.darkGray};
            font-size: 16px;
            font-weight: 400;
            display: flex;
            align-items: center;
            width: fit-content;
            position: relative;
            margin-right: 70px;
            margin-bottom: 5px;
            line-break: anywhere;
            @media screen and (max-width: 1600px){
              /* width: fit-content; */
              /* line-break: anywhere; */
              margin-right: 50px;
            }
            @media screen and (max-width: 1600px){
              font-size: 14px;
            }
            @media screen and (max-width: 1199px){
              margin-right: 30px;
            }
            @media screen and (max-width: 991px){
              /* width: fit-content; */
            }
            .ant-radio{
              top: 0;
              &:hover{
                .ant-radio-inner{
                  border-color: ${variables.btn_bg_color};
                }
              }
            }
            .icon{display: unset;
              margin-left: 5px;
            }
            .anticon{
              &.anticon-info-circle{
                right: -15px;
                top: 2px;
                color: #9e9e9e;
                font-size: 20px;
                position: absolute;
                @media screen and (max-width: 1600px) {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
    .ant-select{
      .ant-select-selector{
        .ant-select-selection-placeholder{
          font-size: 16px;
          @media screen and (max-width: 1600px){
              font-size: 14px;
            }
        }
      }
    }
    
    &.editable-benefit{
      .ant-select{
        .ant-select-selector{
          .ant-select-selection-placeholder{
            font-size: 16px;
            line-height: 20px;
            color: #22C4F2;
            font-weight: 600;
            font-style: unset;
            @media screen and (max-width: 1600px){
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
    &:last-child:not(.two-fields){
      margin-bottom: 0!important;
    }
    &.reminder{
      margin-bottom: 20px;
      @media screen and (max-width: 1600px){
        margin-bottom: 15px;
      }
      .ant-form-item-has-error{
        .ant-form-item-explain{
          margin-left: -5px;
        }
      }
    }
    &.textarea-field{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
      
    }
   
    &.ownerselect{
      .ant-select{
        max-width: 350px;
      }
    }
    &.other-notified
    {
      .ant-select-selector 
      {
        height: auto!important;
        min-height: 40px;
      }
    }
    &.disable{
      .task-date{
        border: none !important;
        height: auto !important;
        .ant-picker{
          padding: 0 !important;
          .ant-picker-input{
            input{
              color: #2D2C2C;
            }
          }
        }
      }
    }
    
    &.inline-form-item{
      width: 48%;
      margin-left: 4%;
      &:first-child{
        margin-left: 0;
      }
    }
    &.slider{
      margin-bottom: 5px !important;
      .ant-slider{
        .ant-slider-handle{
          @media screen and (max-width: 1600px){
            margin-top: -5px;
          }
        }
      }
    }

    .ant-picker{ 
    .ant-picker-input{
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 14px;
        color: #b8b8b8;
        font-size: 18px;
        @media screen and (max-width: 1440px){
          font-size: 16px;
          margin-right: 10px !important;
        }
        .anticon-calendar {
          display: flex;
          &::before {
            content: "\\e922";
            font-family: "icomoon" !important;
            display: block;
            color: #b8b8b8;
          }
          svg {
            display: none;
          }
        }
      }
    }
  }

  }
  .rateWrapper{
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      line-height: 0;
      .ant-rate{
        .ant-rate-star-full,.ant-rate-star-half.ant-rate-star-active .ant-rate-star-first{
          .anticon{
            svg{
              fill: #EA9D17;
            }
          }
        }
       
        .ant-rate-star-zero,.ant-rate-star-half.ant-rate-star-active .ant-rate-star-second{
          .anticon{
            svg{
              fill: #B8B8B8;
            }
          }
        }
      }
      .ant-rate-text{
        color: #B8B8B8;
        margin-left: 0;
      }
    }
  .ant-input{
    box-shadow: none !important;
  }
  .ant-picker{
    &.ant-picker-focused{
      box-shadow: none !important;
    }
  }
}
//switch button 

.switch-wrapper 
{
  .icon {
    color: gray;
    margin: 5px;
  }
  margin: 35px 0 45px;
  text-align: center;
  @media screen and (max-width: 1800px)
  {
    margin: 30px 0 40px;
  }
  @media screen and (max-width: 1600px)
  {
    margin: 25px 0 30px;
  }
  @media screen and (max-width: 1440px)
  {
    margin: 20px 0 25px;
  }
  @media screen and (max-width: 1199px)
  {
    margin: 15px 0 20px;
  }
  .switch-text 
  {
    display: inline;
  }
  .ant-switch 
  {
    margin: 0 10px;
    min-width: 34px;
    height: 20px;
    background-color: #DBD8D8;

    .ant-switch-handle 
    {
      height: 13px;
      width: 13px;
      top: 3px;
      left: 3px;
      &::before 
      {
        background-color: #9E9E9E;
      }
      
    }
    &.ant-switch-checked
    {
      background-color: #035592;
      .ant-switch-handle 
      {
        left: calc(100% - 18px - -1px);
        &::before 
        {
          background-color: white;
        }
      }
    }
  }
}

//radio button
.ant-radio{
      &.ant-radio-checked{
        .ant-radio-inner{
          border-color: ${variables.btn_bg_color};
          width: 22px;
          height: 22px;
          border-width: 2px;
          @media screen and (max-width: 1600px){
            width: 20px;
            height: 20px;
          }
          @media screen and (max-width: 991px){
            width: 18px;
            height: 18px;
            border-width: 2px;
          }
          &::after{
            background-color: ${variables.btn_bg_color};
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            transform: scale(1);
            margin-left: 0px;
            margin-top: 0px;
            /* @media screen and (max-width: 1800px)
            {
              width: 24px;
              height: 24px;
              top: 5px;
              left: 5px;
            } */
            @media screen and (max-width: 1600px){
              width: 12px;
              height: 12px;
             
            }
            @media screen and (max-width: 1440px){
              width: 10px;
              height: 10px;
              top: 3px;
              left: 3px;
              
            }
            @media screen and (max-width: 991px){
              width: 8px;
              height: 8px;
              
            }
          }
        }
      }
      .ant-radio-inner{
          width: 22px;
          height: 22px;
          /* top: -1px; */
          @media screen and (max-width: 1600px){
            width: 20px;
            height: 20px;
          }
          @media screen and (max-width: 991px){
            width: 18px;
            height: 18px;
          }
      }
    }

.ant-select{
  .ant-select-selector{
    .ant-select-selection-item{
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1600px){
        font-size: 14px;
        line-height: 18px;
      }
      
       .treeContent{  
              .ant-radio-wrapper{
                color: #2D2C2C;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                @media screen and (max-width: 1600px){
                  font-size: 14px;
                  line-height: 18px;
                }
                .sm-title{
                  color: #9E9E9E;
                  margin-right: 5px;
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 400;
                  @media screen and (max-width: 1600px){
                  font-size: 12px;
                  line-height: 15px;
                }
                }
                .ant-radio{
                  display: none;
                  .ant-radio-inner{
                    border-color: #DBD8D8;
                  }
                  &.ant-radio-checked{
                    .ant-radio-inner{
                    border-color: #035592;
                  }
                  }
                }
                span{
                  padding-left: 0;
                }
              }
            }
      .code-id{
          color: #9E9E9E;
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          margin-right: 5px;
          white-space: nowrap;
          @media screen and (max-width: 1600px){
          font-size: 12px;
          line-height: 15px;
        }
      }
      .status{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 5px;
        background: #dbd8d8;
        display: inline-block;
        &.open{background: #00DC00;}
        &.close{background: #E9374C;}
        &.archive{background: #E4C003;}
        &.reopen{background: #1A5592;}
      }
    }
    input:focus
      {
        outline: none!important;
      } 
  }
  
  &.ant-select-disabled :not(.select-month) :not(.country){
    cursor: auto;
    .ant-select-selector{
      background: none !important;
      border: none !important;
      padding: 0 !important;
      height: auto !important;
      .ant-checkbox-wrapper{
        cursor: auto;
      }
    }
    .ant-select-arrow{
      display: none!important;
    }
    &.taskSelect{
      .ant-select-selector{
        height: auto!important;
    min-height: 40px;
        .ant-select-selection-item{
          font-weight: 600;
          color: #22C4F2;
          white-space: normal;
          word-break: break-word;;
        }
      }
    }
    .ant-select-selection-item{
      color: #22C4F2;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      @media screen and (max-width: 1600px){
        font-size: 14px;
        line-height: 18px;
      }
      .code-id{
        color: #9E9E9E;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        white-space: nowrap;
        @media screen and (max-width: 1600px){
        font-size: 12px;
        line-height: 15px;
      }
      }
    }
  }
  .ant-select-arrow{
    .anticon-search
      {
        transform: rotate(180deg);
      }
    }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input
  {
    height: 100%;
  }
  &.task-new-select{
    .ant-select-selector{
      .ant-select-selection-item{
        display: flex;
        align-items: center;
      }
    }
  }
  &.ant-select-multiple{
    .ant-select-selection-overflow{
      .ant-select-selection-overflow-item{
        height: 100%;
        .ant-select-selection-item{
          height: 32px;
          display: flex;
          align-items: center;
          padding: 3px 5px;
          margin: 3px;
          border-radius: 5px;
          background: #E9E9E9;
          .ant-select-selection-item-content{
            display: flex;
            align-items: center;
            .taskAvatar{
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
  &.key-link-select 
  {
    .ant-select-selection-item
    {
      cursor: pointer!important;
    }
    
  }
  &.taskSelect{
      .ant-select-selector{
        height: auto!important;
    min-height: 40px;
        .ant-select-selection-item{
          
          white-space: normal;
          word-break: break-word;
        }
      }
    }
}
.key-link-select-dropdown 
{
  .ant-select-item-option-content
  {
    white-space: normal;
    align-items: baseline!important;
    font-size: 14px;
    line-height: 17px;
    color: #2d2c2c;
    
  }
  .code-id 
  {
    white-space: nowrap;
    color: #9E9E9E;
    margin-right: 5px;
  }
}
.show {
    height: auto;
    transition: 4s all;
  }
  .hide {
    height: 0;
    /* overflow: hidden; */
    transition:  4s all;
    /* .KeyBtnWrapper{
      display: none;
    } */
  }

  /* collapse */
  .ant-collapse {
    .ant-collapse-header{
      /* padding: 0 !important; */
      background: #fff;
      .ant-collapse-arrow{
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
    .ant-collapse-content{
      border: none;
    }
  }

.ant-radio{
  .ant-radio-inner{
    border-color: #035592;
    &::after{
      background-color: #035592;
    }
  }
}

/* Select Dropdown */
.ant-select-dropdown {
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      .title-wrapper 
      {
        margin-left: 13px;
      }
      
      @media screen and (max-width: 1600px){
        font-size: 13px;
      }
      .ant-checkbox-wrapper, .title-wrapper {
        .title {
          font-family: 'Inter-Regular',sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 5px;
          color: ${variables.darkGray};;
          @media screen and (max-width: 1600px){
            font-size: 15px;
            line-height: 18px;
          }
        }
        .subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #2d2c2c;
          @media screen and (max-width: 1600px){
            font-size: 13px;
            line-height: 15px;
            margin-top: 2px;
          }
        }
      }
      .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: ${variables.btn_bg_color};
          background: none;
          &::after {
            border-color: ${variables.btn_bg_color};
          }
        }
      }
    }
    .status{
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 5px;
      background: #dbd8d8;
      display: inline-block;
      &.open{background: #00DC00;}
      &.close{background: #E9374C;}
      &.archive{background: #E4C003;}
      &.reopen{background: #1A5592;}
    }
    
    }
    .ant-select-tree{
      .ant-select-tree-list{
        .ant-select-tree-treenode{
          .ant-select-tree-title{
            .treeContent{  
              .ant-radio-wrapper{
                color: #2D2C2C;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                @media screen and (max-width: 1600px){
                  font-size: 14px;
                  line-height: 18px;
                }
                .sm-title{
                  color: #9E9E9E;
                  margin-right: 5px;
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 400;
                  @media screen and (max-width: 1600px){
                  font-size: 12px;
                  line-height: 15px;
                }
                }
                .ant-radio{
                  .ant-radio-inner{
                    border-color: #DBD8D8;
                  }
                  &.ant-radio-checked{
                    .ant-radio-inner{
                    border-color: #035592;
                  }
                  }
                }
              }
            }
          }
          &.ant-select-tree-treenode-switcher-close{
            .ant-select-tree-switcher{
              .anticon{
                position: relative;
                &::before{
                  content: "\\e918";
                  font-family: 'icomoon' !important;
                  position: absolute;
                  top: -5px;
                  left: -3px;
                  display: block;
                  color: #22C4F2;
                  font-size: 6px;
                  /* margin-bottom: 5px; */
                }
                svg{
                  display: none;
                }
              }
            }
          }
          &.ant-select-tree-treenode-switcher-open{
            .ant-select-tree-switcher{
              .anticon{
                position: relative;
                &::before{
                  content: "\\e91e";
                  font-family: 'icomoon' !important;
                  position: absolute;
                  top: -5px;
                  left: -3px;
                  display: block;
                  color: #22C4F2;
                  font-size: 6px;
                }
                svg{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .task-item{
      .ant-select-item-option-content{
        display: flex;
        align-items: center;
        .taskAvatar{
        /* float: left; */
        }
      }
    }

    /* TimeBoxPeriod Select dropdown */
    &.select-dropdown-period-wrapper {
      .ant-select-item-option-disabled {
        .ant-checkbox-inner, .ant-checkbox-inner::after {
          border-color: #d9d9d9 !important;
        }
        .title-wrapper {
          .title, .subtitle {
            color: #ada7a7;
          }
        }
      }
  }
  }



  .dashboard-scorecard{
    padding: 10px 15px;
    .title{
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #22C4F2;
      margin: 10px 0 20px;
      @media screen and (max-width: 991px){
        margin: 10px 0 15px;
      }
    }
    .rc-virtual-list{
      .rc-virtual-list-holder{
        padding: 0 0 10px;
        overflow-y: hidden !important;
        height: 100%;
        & > div {
          height: 100% !important;
          overflow: initial !important;
        }
        .rc-virtual-list-holder-inner{
          position: inherit !important;
          flex-direction: row !important;
          width: 100%;
          .ant-select-item-option{
            border: 1px solid #9E9E9E;
            border-radius: 20px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #9E9E9E;
            padding: 8px 15px;
            margin-right: 10px;
            @media screen and (max-width: 1600px){
              font-size: 12px;
              line-height: 16px;
              padding: 7px 12px;
            }
            &.ant-select-item-option-selected{
              background: #E9E9E9;
              border: none;
              color: #2D2C2C;
              .ant-select-item-option-state{
                display: none;
              }
            }
          }
        }
        &::-webkit-scrollbar{
              width: 6px;
              height: 5px !important;
              padding: 5px;
          }
          ::-webkit-scrollbar-track{
            height: 10px;
          }
      }
    }
  }

  .ant-input-group
  {
    margin-bottom: 0px;
  }
  
   .ant-form-item-label, .profile-page .ant-form-item-label
  {
    width: 100%;
    text-align: left;
    
    label 
    {
      font-family: 'Inter-Bold', sans-serif;
      color: ${variables.title_color};
      font-size: 16px;
      line-height: 20px;
      height: auto;
      margin-bottom: 15px!important;
      @media only screen and (max-width: 1600px)
      {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 10px!important;
      }
      .anticon{
        &.anticon-info-circle{
          position: absolute;
          right: -20px;
          color: #9e9e9e;
          font-size: 20px;
          @media screen and (max-width: 1600px) {
            font-size: 16px;
            right: -15px;
          }
        }
      }
    }
  }

  .ant-form-item-label > label .ant-form-item-tooltip
  {
    cursor: default;
   
  }
  
  .ant-form
  {
    .ant-form-item 
    {
      &::placeholder 
      {
        color: #B1B1B1;
        font-style: italic;
      }      
      .ant-form-item-control-input{
        .ant-input-number-handler-wrap{
          display:none !important;
        }
      }  
      .ant-input
      {
        font-size: 16px;
        line-height: 20px;
        color: #2D2C2C;
        @media only screen and (max-width: 1600px) {
          font-size:15px;
        }
        @media only screen and (max-width: 991px) {
          font-size:14px;
        }
      }
      &.select-benifit-wrapper {
        .ant-checkbox-wrapper {
          .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 5px;
            color: #035592 !important;
            @media screen and (max-width: 1600px) {
              font-size: 15px;
              line-height: 18px;
              margin-bottom: 0;
            }
            @media screen and (max-width: 1440px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
          .subtitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color: #2d2c2c;
            @media screen and (max-width: 1600px) {
              font-size: 13px;
              line-height: 15px;
              margin-top: 3px;
            }
          }

          .ant-checkbox {
            display: none;
            &.ant-checkbox-checked {
              display: none;
            }
          }
        }
        .ant-select {
          max-width: 420px;
          .ant-select-selector {
            height: 64px !important;
            @media screen and (max-width: 1600px) {
              height: 60px !important;
            }
            @media screen and (max-width: 991px) {
              height: 50px !important;
            }
            .ant-select-selection-item {
              line-height: 20px;
              .ant-checkbox {
                .ant-checkbox-checked {
                  .ant-checkbox-inner {
                    border-color: ${variables.btn_bg_color};
                    &::after {
                      border-color: ${variables.btn_bg_color};
                    }
                  }
                }
              }
            }
          }
          &.ant-select-disabled {
            .ant-select-selector {
              height: auto !important;
            }
          }
        }
        // .anticon.anticon-info-circle 
        // {
        //   right: -12px;
        // }
      }
      
    }
    .ant-form-item-control
    {
      position: relative;
      .ant-form-item-explain.ant-form-item-explain-error
      {
        position:absolute;
        bottom: -27px;
        color: #E02929;
        font-size:14px;
        line-height: 16px;
      }
      .ant-form-item-explain-success
      {
        position:absolute;
        bottom: -25px;
      }
      .ant-form-item-children-icon
      {
        right: -22px;
        font-size: 20px;
        width: 20px;
        @media screen and (max-width: 1600px){
          font-size: 18px;
        }
      }
      .ant-form-item-children-icon svg {
        display: none;
    }
     
    }
    
    .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
      background: url('${errorIcon}');
      
      display: inline-block;
      
      width: 20px;
      height: 20px;
    }
    .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon
    {
      color: #fff;
    }
  }
  

  .redirect 
  {
    margin: 15px 0px 35px;
    text-align:center;
    color: #2D2C2C;
    font-size: 16px;
    line-height: 19px;
    @media only screen and (max-width: 1600px)
    {
      margin: 15px 0px 30px;
    }
    @media only screen and (max-width: 1440px)
    {
      margin: 10px 0px 25px;
    }
    @media only screen and (max-width: 1199px)
    {
      margin: 10px 0px 20px;
    }
    span 
    {
      color: ${variables.redirect_color};
      // font-size: 16px;
      // line-height: 19px;
      font-weight: 600;
    }
    @media only screen and (max-width: 1600px)
    {
      font-size: 15px;
      line-height: 18px;
    }
    @media only screen and (max-width: 1440px)
    {
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (max-width: 1199px)
    {
      font-size: 15px;
      margin: 15px 0px 30px;
      span 
      {
        font-size: 15px;
      }
    }
    @media only screen and (max-width: 991px)
    {
      margin: 10px 0px 25px;
    }
  }
  // left image for login-page
  .left-img 
  {
    width: 50%;
    height: 100%;
    img 
    {
      width: 100%;
      height: 100%;
      object-fit: cover;
  
    }
  }
  .cmn-btn
  {
    background-color: ${variables.btn_bg_color};
    display: block;
    padding: 15px 0px;
    width: 100%;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    border: 1px solid transparent;
    transition: 0.3s linear;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    @media screen and (max-width: 1600px){
      font-size: 15px;
      line-height: 18px;
    }
    span 
    {
      
      &.icon 
      {
        font-weight: 400;
      }
      @media only screen and (max-width: 1600px)
      {
        font-size: 15px;
      }
    }
    @media only screen and (max-width: 1600px)
    {
      padding: 12px 0px!important;
      font-size: 15px;
      line-height: 18px;
      
      span.icon
      {
        font-size: 15px!important;
      }
     
    }
    @media only screen and (max-width: 1600px)
    {
      padding: 10px 0px!important;
      font-size: 14px;
      line-height: 16px;
      
      span.icon
      {
        font-size: 14px!important;
      }
     
    }
    
    &:focus 
    {
      outline: none !important;
    }
    &.ant-popover-disabled-compatible-wrapper{
      cursor: not-allowed;
      color: rgba(0,0,0,.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      &:hover{
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: rgb(217, 217, 217) !important;
        background: rgb(245, 245, 245) !important;
        text-shadow: none;
        box-shadow: none;
      }
      button{
        padding: 0;
        border: none !important;
        line-height: 1;
        height: auto;
        background: none !important;
      }
    }
  }
  [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node
    {
      box-shadow: none;
    }
  a 
  {
    text-decoration: none;
  }
  .ant-select
  {
    font-size: 16px;
    line-height: 20px;
    @media screen and (max-width: 1600px) {
     
      font-size: 15px;
      line-height: 18px;
    }
    @media screen and (max-width: 991px) {
      
      font-size: 14px;
      line-height: 16px;
    }
    .ant-select-selector
    {
      width: 100%!important;
      border: 0.5px solid #DBD8D8!important;
      border-radius: 5px!important;
      height: 40px!important;
      align-items: center!important;
    }
    .ant-select-arrow 
    {
      svg
      {
        color: ${variables.redirect_color};
        transition: transform .3s,-webkit-transform .3s;
      }
    }
    &.ant-select-open 
    {
      svg
        {
          
          transform: rotate(180deg);
        }
      }
    }
  
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus
  {
    box-shadow: none !important;
  }
   .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder
   {
    color: #B1B1B1;
    font-style: italic;
   }
  // .ant-select:not(.ant-select-customize-input) .ant-select-selector
  // {
  //   border:none;
  // }
//   .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
//     position: absolute;
//     width: 100%;
// }

  // tooltip 
  .ant-tooltip
  {
    max-width: 240px;
    transition: 0.3s linear;
    .ant-tooltip-arrow
    {
      width: 30px;
      .ant-tooltip-arrow-content
      {
        width: 15px;
        height: 15px;
        background-color: ${variables.title_color};
      }
    }
    .ant-tooltip-inner
    {
      background-color: ${variables.title_color};
      box-shadow: none!important;
      text-align:center;
      padding:12px 16px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 3px;
      @media only screen and (max-width: 1600px)
      {
          padding: 10px 14px;
          font-size: 13px;
          line-height: 18px;
      }
      .title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: #fff;
        text-align: left;
        margin-bottom: 5px;
      }
      .subtitle {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #fff;
        text-align: left;
      }
    }
    &.header-task-tooltip-wrapper{
      .ant-tooltip-inner{
        background-color: rgba(50, 56, 106, .95);
        .title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: #fff;
          text-align: left;
          margin-bottom: 5px;
        }
        .subtitle {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #fff;
          text-align: left;
        }
      }
    }
    &.trans-id-tooltip {
      max-width: 300px;
      .ant-tooltip-arrow {
        left: 30% !important;
      }
      .ant-tooltip-inner {
        .trans-id {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span{
            margin-right: 10px;
          }
          .ant-btn-text {
            padding-left: 10px;
            color: #fff !important;
          }
        }
      }
    }
    &.invoice {
      .ant-tooltip-inner {
        .receipt-tooltip {
          cursor: pointer;
        }
      }
    }
  }
  .isoSignUpPage.add-tooltip , .isoCardWrapper.add-tooltip
  {
    .ant-form-item-label > label .ant-form-item-tooltip
    {
      cursor: default;
      // margin: 2px 0px 0px 13px;
      margin: 0;
    }
    .ant-form-item.add-tooltip .ant-form-item-label > label::after
    {
      top: 0px;
      left: 0px;
    }
  }
  .ant-form-item-label > label::after
  {
    margin: 0 8px 0 0;
  }
 
  // department section dropdown
  .dept-dropdown
  {
    padding: 0px!important;
    .rc-virtual-list-holder-inner
    {
      flex-direction: row!important;
      flex-wrap: wrap;
      padding: 5px;
      .ant-select-item-option
      {
        padding: 5px;
        &.ant-select-item-option-active {
          .ant-select-item-option-content{
            border: 1px solid black;
            border-radius: 5px;
          }
        }
        .ant-select-item-option-content
      {
       
        div 
        {
          width: 20px;
          height: 20px;
          border-radius: 5px;
        }
      }
      }
      
    }
  }

  .link-foreign {
    display: flex;
    margin: 0 0 -24px auto;
    position: relative;
    z-index: 1;
    color: ${variables.redirect_color};
    float: right;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    @media screen and (max-width: 1600px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 12px;
      margin-bottom: -20px;
    }
    &:hover{
      color: #32386A;
    }
  }

  .link-foreign-disabled {
    color: darkgrey;
    pointer-events: none;
    cursor: not-allowed;
  }

  .add-colon{
    .ant-form-item{
      .ant-form-item-label{
        label{
          &::after{
            content: ":";
            display: inline-block;
          }
        }
      }
      &.slider{
        .ant-form-item-label{
        label{
          &::after{
            /* content: ""; */
            display: none;
          }
        }
      }
      }
    }
  }

//---------------------- custo/edit popover------------------------------
.edit-popover
{
  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
  border-radius: 10px;
  margin-top: -5px;
  width:127px;
  font-family: 'Inter-Regular',sans-serif;
  &.tab-popover 
  {
    width: 167px;
    .ant-popover-inner-content 
    {
      padding: 15px 0px 12px 15px!important;
      .ant-btn
      {
        height:auto;
        &:not(:last-child)
        {
          margin-bottom: 25px!important;
        }
      }
    }
  }
  .ant-popover-arrow 
  {
    right:7px;
  }
  .ant-popover-inner 
  {
    border-radius: 5px;
    .ant-popover-inner-content 
    {
      padding: 20px 0px 20px 15px;
      @media only screen and (max-width: 1440px)
        {
          padding: 15px 0px 15px 12px;
        }
        @media only screen and (max-width: 1199px)
        {
          padding: 10px 0px 10px 10px;
        }
      a > .ant-btn 
      {
        margin-bottom: 15px;
        @media only screen and (max-width: 1440px)
        {
          margin-bottom: 12px;
        }
        @media only screen and (max-width: 1199px)
        {
          margin-bottom: 10px;
        }
      }
      a, .ant-btn
      {
        display: block;
        padding: 0;
        width: 100%;
        text-align: left;
        border: none;
        box-shadow: none;
        height: auto !important;
        span.anticon 
        {
          display: inline-block;
          vertical-align: text-top;
        }
        i 
        {
          margin-right: 15px;
          width: 16px;
        }
        .icon-edit
        {
          
          &::before
          {
            color: #2D2C2C;
          font-size: 14px;
          }
          
        }
        &:not(:last-child)
        {
          margin-bottom: 15px;
          @media only screen and (max-width: 1440px)
          {
            margin-bottom: 12px;
          }
          @media only screen and (max-width: 1199px)
          {
            margin-bottom: 10px;
          }
        }
        svg 
        {
          height: auto;
          margin-right: 15px;
          vertical-align: middle;
          color: #2D2C2C;
          
        }
        span 
        {
          color: #2D2C2C;
          font-size:16px !important;
          line-height: 19px !important;
        }
        &:hover 
        {
          color: #000 !important;
          i::before , span,
          svg, .icon-edit:before
          {
            color: #000 !important;

          }
          
        }
      }
    }
  }
} 
.ant-notification 
{
  .ant-notification-notice-description
  {
    margin-right: 15px;
  }
  .ant-notification-notice-close
  {
    
    top: 20px;
  }
}
//------------------edit popup/ ant-message--------------
.ant-message 
{
  top: 0px;
  div
  
  {
    div
    {
      top:0px;
    }
    
  }
  .edit-popup 
  {
    padding: 0px;
    .ant-message-notice-content 
    {
      background: rgba(50,56,106,0.9);
      color: #fff;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;
      @media only screen and (max-width: 1199px)
      {
        padding: 15px;
      }
      
    span 
    {
      .edit-content 
      {
        font-size: 18px;
        line-height: 21px;
        margin-right: 25px;
        @media only screen and (max-width: 1600px)
        {
         font-size: 16px;
         line-height: 20px;
         margin-right: 20px;
        }
        @media only screen and (max-width: 991px)
        {
         font-size: 14px;
         line-height: 18px;
         margin-right: 15px;
        }
      }
      .ant-btn
      {
        background: transparent;
        padding: 0;
        border: none;
        height: 14px;
        vertical-align: sub;
        @media only screen and (max-width: 991px)
          {
            height: 12px;
          }
        i 
        {
          vertical-align: top;
          
          @media only screen and (max-width: 991px)
          {
            font-size: 12px;
          }
        }
      }
    }
    
    }
  }
  .ant-message-info
  {
    span.anticon 
    {
      display: none;
    }
  }
}
// -------------- tabs--------------
.ant-tabs {
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 0px;
    .ant-tabs-tab {
      align-items: baseline;
      margin: 0px 35px 0px 0px!important;
      padding: 0px 0px 11px;
      @media only screen and (max-width: 1600px) {
        margin: 0px 30px 0px 0px!important;
        padding: 0px 0px 8px;
      }
      @media only screen and (max-width: 1440px) {
        margin: 0px 25px 0px 0px!important;
        padding: 0px 0px 6px;
      }
      @media only screen and (max-width: 1199px) {
        margin: 0px 20px 0px 0px!important;
        padding: 0px 0px 6px;
      }
      @media only screen and (max-width: 991px) {
        margin: 0px 15px 0px 0px!important;
        padding: 0px 0px 6px;
      }
    }
  }
  .ant-tabs-tab-active {
    i {
      visibility: visible !important;
      &::before{
      color: #035592 !important;
      }
    }
  }
  .ant-tabs-tab-btn {
    font-size: 18px;
    font-weight: 600;
    color: #9e9e9e;
    letter-spacing: 0.5px;
    @media only screen and (max-width: 1800px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1440px) {
      font-size: 14px;
    }
    /* @media only screen and (max-width: 1199px) {
      font-size: 14px;
    } */
    &:focus 
    {
      color: #035592 !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #035592 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    
  }
  .ant-tabs-tab.ant-tabs-tab-active .org-chart i {
    visibility: hidden;
  }
  .ant-tabs-tab .org-chart:hover i::before {
    visibility: visible;
  }
  .ant-tabs-tab.ant-tabs-tab-active .org-chart:hover i::before {
    visibility: visible;
    color: #035592 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-select-selection-item,
  .ant-tabs-tab.ant-tabs-tab-active .ant-select-arrow i::before {
    color: #035592 !important;
    font-weight: 600;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px!important;
    @media only screen and (max-width: 1199px) {
      height: 3px;
    }
  }
  .ant-tabs-ink-bar {
    background: #035592;
    height: 5px!important;
    border-radius: 10px 10px 0px 0px;
    @media only screen and (max-width: 1199px) {
      height: 3px;
    }
  }
}
//Buttons side by side
.btn-group {
  display: flex;
  margin-top: 10px;
  .cmn-btn {
    padding: 10px 18px !important;
    width: auto;
    height:auto;
    &:hover,&:focus {
      background-color: #fff;
      border-color: #035592;
      color: #035592;
    }
    &:first-child {
      margin-right: 15px;
    }
    @media only screen and (max-width: 991px) {
      padding: 8px 16px;
    }
  }
  .back-btn {
    background-color: #fff;
    color: #035592;
    border-color: #035592;
    &:hover {
      background-color: #035592;
      color: #fff;
    }
  }
  &.save-cancel-group 
  {
    position: fixed;
        background-color: #F7F7F7;
        bottom: 56px;
        width: 100%;
        margin-left: -48px;
        box-shadow: 0px -2px 4px 0px #b8b8b84d;
        padding: 10px 0px;
        margin-top: 5px;
        @media only screen and (max-width: 1600px)
      {
        bottom: 42px;
      }
      @media only screen and (max-width: 991px)
      {
        left: 50px;
        margin-left: 0px;
      }
      @media only screen and (max-width: 767px)
      {
        left: 0;
        .cmn-btn:first-child
        {
          margin-left: 20px!important;
        }
      }
  }
}
//------Modal--------
.ant-modal-mask
{
  background: rgba(78, 78, 78, 0.8);
}
.ant-modal
{
  width: 700px!important;
  box-shadow: -10px 3px 20px rgba(0, 0, 0, 0.16);
  padding: 0;
  &.add-license-modal {
    @media only screen and (min-width: 1800px)
    {
      width: 762px !important;
    }
    width: 400px !important;
    .btn-group {
      margin-top: 20px !important;
      .disabled-btn {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        cursor: not-allowed !important;
        &:hover {
          border-color: #1890ff;
          background-color: #f5f5f5;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    .instruct-text {
      margin-top: 15px;
      display: block;
      > div {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
      }
      span {
        font-family: 'Inter-Bold', sans-serif;
        color: #035592;
        sup {
          margin-left: 3px;
          font-family: 'Inter-Regular', sans-serif;
        }
        .anticon {
          color: #9e9e9e;
          margin-left: 7px;
        }
      }
      /* font-size: 12px;
      font-weight: 400; */
    }
    .addLicenseform {
      display: inline-flex;
      a {
        margin: 10px;
      }
      /* .license-input {
        display: contents;
        .ant-form-item-control {
          width: 50px;
          .ant-form-item-explain {
            width: max-content;
          }
        }
      } */
      .icon {
        height: 30px;
        color: gray;
        margin-top: 5px;
        margin-left: 2px;
        position: relative;
        right: 0px;
      }
      .license-input {
      margin-bottom: 0px !important;
      margin: auto;
      width: fit-content;
      align-items: baseline;
      justify-content: center;
      display: flex;
      .ant-form-item-label {
        text-align: center;
        width: auto;
        margin-right: 20px;
        .anticon {
          position: inherit;
          right: 0px !important;
        }
        > label::after {
          right: 0px;
        }
      }
      .ant-form-item-control {
        text-align: center;
        /* width: auto; */
        flex: none;
        .ant-form-item-control-input-content {
          flex: none;
          .users-input {
            width: 70px;
          }
          .icon {
            margin-left: 4px;
            color: gray;
          }
        }
        .ant-form-item-explain {
          position: absolute;
          width: max-content;
          bottom: -28px;
        }
      }
      .ant-form-item-label > label {
        color: #2d2c2c;
      }
      .ant-input {
        width: 50px;
      }
    }
    }
    .ant-modal-content {
      .ant-modal-body {
        .purchase-license-note {
          color: #035592;
          font-size: 11px;
        }
      }
      .ant-modal-close {
        display: inline-block;
        .anticon {
          color: #e02929;
        }
      }
    }
    .cancel-sub 
    {
      transition: 0.3s;
      padding: 20px 0px 10px;
      margin: 0 32% 0 33%;
      
      p
      {
        font-weight: 700;
        color:#E02929!important;
      }
      &:hover 
      {
        p 
        {
          color: #2d2c2c!important;
        }
      }    
      
    }
    @media only screen and (max-width: 1800px)
    {
      width: 650px !important;
    }
  }
  &.downgrade-user-modal { 
    .ant-modal-content{
      width: 1000px;
      height: 670px;
      @media screen and (max-width: 1150px) {
        width: 850px !important;
        .ant-pagination {
          bottom: -90px;
        }
      }
      @media screen and (max-width: 768px) {
        width: 700px !important;
      }
      .ant-modal-close {
        display: block !important;
        .anticon {
          color: #e02929;
        }
      }
      .ant-modal-body {
        .ant-select-selector {
          .ant-select-selection-placeholder {
            text-align: left;
          }
        }         
      }
    }  
    @media screen and (min-width: 1838px) {
      width: 1240px !important;
    }
    @media screen and (max-width: 1395px) {
      width: 1150px !important;
    }
    @media screen and (min-width: 767px) {
      width: 650px !important;
      .ant-table {
        height: 385px;
    overflow-y: auto;
        .ant-table-thead, .ant-table-tbody {
          .ant-table-cell {
            font-size: 12px;
            line-height: 12px;
            padding: 6px 4px;
            &.status, &.date{
              text-align: center;
            }
          }
        }
      }
      .ant-pagination  {
        display: inline-flex;
        position: absolute;
        /* z-index: 1; */
        justify-content: flex-end;
        /* float: right; */
        /* margin: 73px 0 0; */
        bottom: -78px;
        right: 0;
    /* position: relative;
    z-index: 1;
    margin: 73px 0 0; */
      }
    }
    @media screen and (min-width: 991px) {
      width: 850px !important;
    }
    @media screen and (min-width: 1200px) {
      width: 1000px !important;
    }
    .ant-table {
      .ant-table-tbody {
        .ant-table-cell {
          &.status {
            text-transform: uppercase;
            .active {
              color: #00f727;
              background: #dbffe1;
              width: 90px;
              text-align: center;
              padding: 2px;
              font-size: 12px;
            }
            .inactive {
              color: #fe0038;
              background: #ffd6df;
              width: 90px;
              text-align: center;
              padding: 2px;
              font-size: 12px;
            }
            .invited {
              color: #FF8C00;
              background: #FFFACD;
              width: 90px;
              text-align: center;
              padding: 2px;
              font-size: 12px;
            }           
            .pending {
              color: #ea9d17;
              background: #fff2db;
              width: 90px;
              text-align: center;
              padding: 2px;
              font-size: 12px;
            }
            .deleted {
              color: #d12525;
              background: #f9d9d9;
              width: 90px;
              text-align: center;
              padding: 2px;
              font-size: 12px;
            }
          }
          &.date {
            min-width: 250px;
            text-align: center;
          }
        }
      }
    }
    .btn-group {
      justify-content: flex-start !important;
    padding-left: 40px;
    display: flex;
    bottom: 20px;
      position: absolute;
      /* bottom: 30px; */
      width: 100%;
      left:0;
      .cmn-btn {
        &:first-child {
          margin-right: 0px;
        }
      }
      .ant-btn[disabled] {
        &:hover {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
        }
      }
    }
  }
  &.makePaymentPopup {
    .ant-modal-content
    {
      padding-bottom: 10px;
      .ant-modal-close {
        display: block !important;
        .anticon {
          color: #e02929;
        }
      }
    }
  }
  &.change-plan-modal 
  {
    .ant-modal-body{
        .ant-alert{
          + p {
            margin-bottom: 15px;
          }
        }
      }
    @media screen and (min-width: 767px) {
      width: 700px !important;
    }
    @media screen and (min-width: 991px) {
      width: 750px !important;
    }
    @media screen and (min-width: 1395px) {
      width: 1510px !important;
    }
    .upgradePlanForm {
    display: block;
    align-items: center;
    .form-tooltip {
      display: flex;
      justify-content: center;
      gap: 10px;
      width: fit-content;
      position: relative;
      margin: 0px auto;
      padding-right: 25px;
    }
    .icon {
      color: gray;
      margin-top: 5px;
      position: absolute;
      right: 0px;
    }
    .license-input {      
      margin: auto;
      width: fit-content;
      align-items: baseline;
      justify-content: center;
      display: flex;
      .ant-form-item-label {
        text-align: center;
        width: auto;
        margin-right: 20px;
        .anticon {
          position: inherit;
          right: 0px !important;
        }
        > label::after {
          right: 0px;
        }
      }
      .ant-form-item-control {
        text-align: center;
        flex: none;
        .ant-form-item-control-input-content {
          flex: none;
          .users-input {
            width: 70px;
          }
          .icon {
            margin-left: 4px;
            color: gray;
          }
        }
        .ant-form-item-explain {
          position: absolute;
          width: max-content;
          bottom: -28px;
        }
      }
      .ant-form-item-label > label {
        color: #2d2c2c;
      }
      .ant-input {
        width: 50px;
      }
    }
  }
    /* width: 1390px!important; */
    .redirect-plan 
    {
      margin: 20px auto 0;
      a 
      {
        margin-left: 5px;
      }
    }
    /* .addLicenseform {
      display: inline-flex;
      a {
        margin: 10px;
      }
      .license-input {
        display: contents;
        .ant-form-item-control {
          width: 50px;
        }
      }
    } */
    .switch-wrapper 
    {
      .disabled {
        background: grey;
        cursor: not-allowed;
      }
      margin: 25px 0 35px;
      @media screen and (max-width: 1440px)
      {
        margin: 20px 0 25px;
      }
      @media screen and (max-width: 1199px)
      {
        margin: 15px 0 20px;
      }
    }
    .change-plan-container
    {
      background: #fff;
      .ant-row {
        .ant-col {
          @media screen and (max-width: 1395px) {
            flex: 50%;
            max-width: 50%;
            margin-bottom: 40px;
          }
          flex: 25%;
          max-width: 25%;
        }
      }
      .promocode-input {
        display: flex;
        margin-top: 50px;
        button {
          margin-left: auto;
        }
      }
    }
    .ant-modal-content
    {
      padding-bottom: 10px;
      .ant-modal-close {
        display: block !important;
        .anticon {
          color: #e02929;
        }
      }
    }
    .btn-group 
    {
      padding-bottom: 20px;
      margin-top: 20px!important;
      
    }
    .cancel-sub 
    {
      transition: 0.3s;
      padding: 20px 0px 10px;
      border-top: 0.5px solid #DBD8D8;
      
      @media only screen and (max-width: 1440px)
      {
        padding: 10px 0 10px;
      }
      
      p
      {
        font-weight: 700;
        color:#E02929!important;
      }
      &:hover 
      {
        p 
        {
          color: #2d2c2c!important;
        }
      }   
      
    }
    .all-user-table {
      .ant-table-body {
        > table {
          tr {
            td {
              @media screen and (max-width: 1440px) {
                padding: 8px 10px;
              }
            }
          }
        }
        
      }
    }
  
  }
  &.otp-modal
  {
    width: 650px !important;;
    .ant-modal-close
    {
      display:inline-block;
    }
    .ant-form
    {
      .ant-form-item-explain-error{
        text-align: left;
      }
      max-width: 352px;
      display: flex;
      flex-direction: column;
      margin: 35px auto;
      @media only screen and (max-width: 1440px)
      {
        margin: 30px auto;
      }
      @media only screen and (max-width: 1199px)
      {
        margin: 25px auto;
      }
      @media only screen and (max-width: 991px)
      {
        margin: 20px auto;
      }
    }
    .resendWrapper{
      margin: 15px auto 0px !important;
      .redirect.resend{
        margin:0px 5px;
        .reSendOTP{
          span{
            color: #035592 !important;
            cursor: not-allowed !important;
            opacity: 0.5 !important;
          }
        } 
        .onFocusResendOTP {
          span{
            color: #035592 !important;
          }
          &:focus {
            text-decoration: underline;
            outline: none;
          }
        }
      }
    }
    .time
    {
      color: #9E9E9E!important;
    }
    .btn-group
    {
      margin-top: 35px;
      .cmn-btn 
      {
        margin: auto;
      }
    }
  }
  &.calendar-modal{
    width: 560px !important;
    .ant-modal-close, .ant-modal-footer{
      display: block !important;
    }
    .ant-modal-close:focus
    {
      box-shadow: none!important;
    }
    .ant-modal-body{
      text-align: left !important;
      .obj-header{
        border-bottom: 0.5px solid #DBD8D8;
      }
      .obj-tag {
        margin-bottom: 8px !important;
        font-weight: 400;
        font-family: 'inter-Bold',sans-serif;
     
      }
      .obj-name {
        color: #000;
        font-weight: 400;
        font-family: 'inter-Regular', sans-serif;
        margin: 8px 0 15px;
        font-size: 18px !important;
        word-break: break-all;
      }
      .owner{
        margin-top: 15px;
      }
      .owner-section {
        display: flex;        
        align-items: center;
        .avatar{
          max-width: 40px !important;
          height: 40px !important;
          @media only screen and (max-width: 1800px)
          {
            max-width: 36px !important;
            height: 36px !important;
          }
          @media only screen and (max-width: 1600px)
          {
            max-width: 34px !important;
            height: 34px !important;
          }
          @media only screen and (max-width: 1440px)
          {
            max-width: 30px !important;
            height: 30px !important;
          }
        }
        .obj-name{
          margin: 0px;
        }
      }
    }
    .ant-modal-footer {
      border: none;
      padding: 10px 0 0;
      .modal-footer-btn
      {
        button 
        {
          cursor: pointer;
          &:last-child 
          {
            background: #fff;
            &:hover 
            {
              background-color: #035592;
            }
          }
         
          
        }
      }
    }
    .modal-footer-btn{
      justify-content: left !important;
    }
    .calendar-detail {
      margin-bottom: 25px;
      @media only screen and (max-width: 1600px)
      {
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 1440px)
      {
        margin-bottom: 15px;
      }
      
    }
  }
  &.modal-tab
  {
    .ant-tabs
    {
      .ant-tabs-tabpane:not(.manual):not(.replace-card-tab)      {
        .ant-form 
        {
          justify-content: center;
          .ant-form-item 
          {
            margin-bottom: 0px;
          }
        }
      }
      
      .ant-tabs-nav-wrap
      {
        justify-content: center;
        
      }
      .ant-form-item-explain-error
      {
        text-align: left;
        padding-left: 38px!important;
        @media only screen and (max-width: 1199px)
        {
          padding-left: 18px!important;
        }
      }
      .ant-tabs-content
      {
        .ant-tabs-tabpane.manual
        {
          .ant-form
          {
            width: 352px;
            margin: 35px auto 0px;
            .ant-form-item-explain-error
            {
              text-align: left;
              padding-left: 0px!important;
            }
            .ant-select
            {
              text-align: left;
            }
            .ant-form-item {
              margin-bottom: 35px;
              width: 100%!important;
              @media only screen and (max-width: 1199px)
              {
                margin-bottom: 30px;
              }
              @media only screen and (max-width: 991px)
              {
                margin-bottom: 25px;
              }
          }
          }
        }
        .tab-content
        {
          margin-top: 25px!important;
        }
        .email-list
        {
          min-height: 100px;
          max-width: 533px;
          margin-top: 35px!important;
          font-size: 16px;
          line-height: 19px;
          &::placeholder
          {
            font-size: 16px;
            line-height: 19px;
            font-style: italic;
          }
          &:focus
          {
            box-shadow: none;
            border-color: #919191;
          }
        }
      }
    }
  }
  &.change-password-modal 
  {
    .password-grp 
    {
      a 
      {
        transform: translate(0px,3px)!important;
      }
      input 
      {
        &:focus 
        {
          outline: none!important;
        }
      }
      &:focus-within 
        {
          outline: 0.5px solid ${variables.focusColor} !important;
        }
    }
   
  }
  &.password-modal
  {
    .btn-group 
    {
      margin-top: 0px!important;
    }
    .ant-form
    {
      max-width: 352px;
      margin: auto;
    }
    .ant-modal-header 
    {
      padding: 0px 0px 45px!important;
      @media only screen and (max-width: 1440px)
      {
        padding: 0px 0px 40px!important;
      }
      @media only screen and (max-width: 1199px)
      {
        padding: 0px 0px 30px!important;
      }
    }
  }
  &.send-invite-modal
  {
    
    .ant-modal-header
    {
      padding: 0px!important;
      img 
      {
        width: 83px;
        @media only screen and (max-width: 1440px)
          {
            width: 70px;
          }
          @media only screen and (max-width: 991px)
          {
            width: 75px;
          }
      }
      .send-title
      {
        p
        {
          margin: 35px 0px 15px;
          @media only screen and (max-width: 1199px)
          {
            margin: 30px 0px 10px;
          }
          @media only screen and (max-width: 991px)
          {
            margin: 25px 0px 5px;;
          }
        }
      }
    }
   
    .ant-modal-body
    {
      p
      {
        .name
        {
          font-family: 'Inter-Bold',sans-serif;
        }
      }
    }
    
  }
  &.subscribe-modal 
  {
    height: 650px;
    .ant-modal-content{
      .ant-modal-body {
        max-height: 530px;
        overflow-y: auto;
        ::-webkit-scrollbar {
          width: 6px;
        }
      }
        height: 650px;
        .saved-card-tab {
          .btn-group {
            margin-top: 35% !important;
            .disabled-btn {
              background-color: #f5f5f5;
              color: rgba(0, 0, 0, 0.25);
              border-color: #d9d9d9;
              cursor: not-allowed !important;
              &:hover {
                border-color: #1890ff;
                background-color: #f5f5f5;
                color: rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
        .replace-card-tab {
          .ant-alert-error {
            max-width: 500px !important;
            margin: 2px 50px;
          }
          .btn-group {
            margin-top: 20px!important;
          }
        }
        .replace-card-form {
          max-width: 420px !important;
          .two-fields {
            .ant-form-item:last-child {
            margin-bottom: 20px !important;            
            @media only screen and (min-width: 1800px) {
              margin-bottom: 36px !important;
            }
            @media only screen and (max-width: 1600px) {
              margin-bottom: 25px !important;
            }
            @media only screen and (max-width: 1200px) {
              margin-bottom: 17px !important;
            }
          }
          }
          .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error {
              position: absolute;
              margin-top: 42px;
              // bottom: -35px;
            }
            .ant-form-item-explain {
              position: absolute;
              bottom: -30px;
              min-height: 30px;
            }
            ul.country-list .country-name,
              .react-tel-input {
                color: #2d2c2c;
                .country {
                  display: flex;
                }
              }
        }
        .ant-form {
          margin: 25px auto 25px !important;
          .ant-form-item-has-error {
            .react-tel-input > .form-control,
            .flag-dropdown {
              border: 1px solid #ff4d4f !important;
            }
          }
          .ant-form-item {
            margin-bottom: 20px;
            .ant-form-item-control {
                .ant-input-group-compact {
                  max-width: 100%;
                  display: flex;
                  .ant-form-item {
                    width: 100%;
                    margin-bottom: 0px !important;
                    .ant-select.date {
                      width: 98%;
                      max-width: 98%;
                    }
                  }
                }
                .ant-input-disabled {
                  background-color: #f5f5f5;
                  cursor: not-allowed;
                  height: 38px;
                  color: darkgrey;
                  .ant-select-selector {
                    border: 1px solid #dbd8d8 !important;
                    .ant-select-selection-item {
                      color: darkgrey;
                    }
                  }
                }
                .ant-form-item-control-input-content {
                  justify-content: space-between;
                  display: flex;
                  .ant-input-number {
                    width: 100%;
                  }
                }
                .ant-select {
                  text-align: left;
                }
                .ant-select-disabled {
                  .ant-select-selector {
                    .ant-select-selection-item {
                      background-color: #f5f5f5 !important;
                    }
                  }
                }
                .ant-select.date {
                  max-width: 48%;
                  text-align: left;
                }
              }
          }
          .expiry-date {
            .ant-form-item-label > label::before {
              position: absolute;
              color: #e02929;
              right: -4px;
              top: 0;
              font-family: inherit;
              content: '*';
            }
          }
        }
        .two-fields {
          display:flex;
          gap: 10px;
          /* .ant-form-item-has-error:last-child {
            margin-bottom: 17px;
          } */
          .ant-form-item:last-child {
            /* margin-bottom: 20px !important;             */
            @media only screen and (min-width: 1801px) {
              margin-bottom: 36px !important;
            }
            @media only screen and (min-width: 1600px) {
              margin-bottom: 25px;
            }
            @media only screen and (max-width: 1200px) {
              margin-bottom: 17px !important;
            }
          }
          .ant-form-item {
            width: 48%;
            margin-bottom: 35px;
            @media only screen and (max-width: 1199px) {
              margin-bottom: 30px;
            }
            @media only screen and (max-width: 991px) {
              margin-bottom: 18px !important;
              width: 100%;
            }
            .ant-input-group-compact {
              max-width: 100%;
              display: flex;
              .ant-form-item {
                width: 100%;
                margin-bottom: 0px !important;
                .ant-select.date {
                  width: 98%;
                  max-width: 98%;
                }
              }
            }
            .ant-input-disabled {
              background-color: #f5f5f5;
              cursor: not-allowed;
              color: rgb(0, 0, 0, 0.25);
            }
          }
        }
    }
    .ant-radio-group
    {
      width: 100%;
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper 
      {
        
        padding: 35px 35px 45px 25px;
        border-radius: 5px;
        margin: 20px 0px ;
        max-width: 550px;
        &.ant-radio-wrapper-checked
        {
          background: #F4FDFF;
        }
        &:first-child
        {
          margin-top: 45px;
        }
        .delete-icon-btn 
        {
          position: absolute;
          right: calc(-21px - 17px);
          top: 50%;
          transform: translate(0px, -50%);
          border: none;
          padding:0px;
          .icon-delete::before
          {
            color: #DBD8D8;
            font-size: 20px;
          }
        }
        span.ant-radio + *
        {
          width: 100%;
        }
        span:last-child
        {
          .card-wrapper 
          {
            display: flex;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;
            .card-user 
            {
              text-align: left;
              .card
              {
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 2px;
                display: inline-block;
                img 
                {
                  padding: 2px 4px;
                }
              }
            }
            p.card-username 
            {
              color: #2D2C2C;
              font-size: 18px;
              line-height: 21px;
              margin-top: 5px;
              font-family: "Inter-Bold",sans-serif;
            }
            p.select 
            {
              font-size: 16px;
              line-height: 19px;
              color: #9E9E9E;
              font-style: italic;
              margin-top: 15px;
              visibility: hidden;
              &.default
              {
                visibility: visible;
              }
            }
            .card-number
            {
              font-family: 'inter-Bold', sans-serif;
            }
            .expiry-date
            {
              margin-top: 15px;
            }
          }
        }
      }
    }
    .ant-modal-content {
      .ant-modal-close {
        display: inline-block;
        .anticon {
          color: #e02929;
        }
      }
    }
    .ant-form
    {
      .react-tel-input {
      .form-control {
        width: 100%;
        border-color: ${variables.btn_bg_color};
        /* height: 40px; */
        color: ${variables.darkGray};
      }
      .flag-dropdown {
        border-color: ${variables.btn_bg_color};
      }
    }
    .react-tel-input .form-control {
      height: 40px;
      width: 100%;
      padding-left: 65px;
    }
    .react-tel-input .selected-flag {
      width: 60px;
      padding: 0 0 0 15px;
    }
    .react-tel-input .selected-flag .arrow {
      border: none;
      &::after {
        content: '\\e91b';
        font-family: 'icomoon' !important;
        color: #22c4f2;
      }
    }
    .flag-dropdown {
      .arrow {
        transform: rotate(90deg);
        display: flex;
        align-items: center;
        font-size: 8px;
        left: 28px;
      }
    }
    .react-tel-input .country-list .country:hover,
    .react-tel-input .country-list .country.highlight {
      background-color: #e6f7ff;
    }
      max-width: 352px;
      margin: 45px auto 25px;
      .ant-input-number {
        width: 100%;
      }
      .short-fields
      {
        display: flex;
        justify-content: space-between;
        .ant-select
        {
          width: 48%;
          text-align: left;
        }
      }
      .security-code 
      {
        max-width: 48%;
      }
      .ant-form-item-explain {
        .ant-form-item-explain-error {
          padding: 0px !important;
        }
      }
    }
    /* .btn-group 
      {
        margin-top: 20px!important;
      } */
  }
  &.cancelSubscription {
    .ant-modal-content {
      .ant-modal-close {
        display: inline-block;
        .anticon {
          color: #e02929;
        }
      }
      .ant-form-item {
        .ant-select-selection-placeholder, .ant-form-item-explain  {
          text-align: left;
        }
      }
      .ant-modal-footer {
        .modal-footer-btn > button {
          cursor: pointer;
        }
      }
    }
  }
  &.subscribe-alert {
    .ant-modal-body {
      p {
        line-height: 25px !important;
      }
      .error {
        font-size: 14px;
        color: red;
      }
      .ant-radio-group {
        display: block;
        align-items: center;
        justify-content: center;
      }
      .ant-picker {
        width: 50%;
      }
      .ant-picker-input {
        input:focus {
          outline: none !important;
          border: none !important;
        }
      }
    }
    .note {
      display: block;
      text-align: center;
      font-size: 11px;
      color: #035592;
      font-weight: 600;
      position: absolute;
      bottom: 0;
      width: 560px;
    }
  }
  &.confidence-modal {
    width: 650px !important;
    .ant-modal-content 
    {
      width: 650px;
      margin: auto;
    }
    .btn-group
    {
      margin-top: 25px!important;
    }
  }
  &.Admin-dashboard-filter-modal {
    max-width: 650px;
    background: #fff;
    border-radius: 10px;
    .ant-modal-content {
      max-height: 95vh;
      overflow-y: auto;
      @media screen and (max-width: 991px){
        padding: 40px 20px;
      }
      .ant-modal-close {
        display: block;
      }
      .ant-modal-header {
        .ant-modal-title {
          font-size: 18px;
          text-align: left;
          border-bottom: 1px solid #DBD8D8;
        }
      }
      .ant-modal-body {
        .ant-form {
          .title {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #000;
            text-align: initial;
          }
          .ant-form-item:not(:last-child) {
            border-bottom: 1px solid #DBD8D8;
          }
          .ant-form-item {
            padding-bottom: 10px;
            .ant-checkbox-group {
              width: 100%;
              display: flex;
              flex: 1 0 0;
              flex-direction: row;
              gap: 15px;
              label {
                width: 100%;
                .ant-checkbox-input:focus+.ant-checkbox-inner {
                    border-color: #2d2c2c !important;
                  }
              }
            }
          }
        }
        .btn-group {
          justify-content: flex-start;
          .cmn-btn:not(.apply-btn) {
            background-color: #fff;
            color: ${variables.btn_bg_color};
            border: 1px solid ${variables.btn_bg_color};
            &:hover, :focus {
              background-color: ${variables.btn_bg_color};
              color:#fff;
            }
          }
        }
      }
    }
  }
  &.add-promo-modal {
    .ant-modal-content{
      .ant-modal-close {
        display: inline-block;
        .anticon {
          color: #e02929;
        }
      }
    }
    .ant-modal-footer {
      display: block !important;
      border-top: none !important;
      .modal-footer-btn {
        button {
          cursor: pointer;
        }
        .cancel-btn {
          background: #fff;
          :hover, :focus {
            color: #fff;
            background: #035592;
          }
        }
      }
    }
    .ant-modal-body{
      .ant-alert-error {
        max-width: 100%;
      }
    }
    .validity {
      .ant-form-item-label > label::before {
        position: absolute;
        color: #e02929;
        right: -4px;
        top: 0;
        font-family: inherit;
        content: '*';
      }
      margin-bottom: 25px !important;
      @media screen and (max-width: 1199px){
        margin-bottom: 20px !important;
      }
      @media screen and (max-width: 991px){
        margin-bottom: 15px !important;
      }
    }
    .promocode-form{
      .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-error {
        position: absolute;
        margin-top: 42px;
      }
      .ant-form-item-explain {
        position: absolute;
        bottom: -30px;
        min-height: 30px;
      }
      .short-fields {
        justify-content: space-between;
        width: 100%;
        display: flex;
        @media only screen and (max-width: 991px) {
          flex-direction: column;
          width: 100%;
        }
        .ant-input{
          outline: none;
          &:focus{
            outline: none !important;
          }
        }
        .promocode-generator{
          margin-bottom: 10px;
          padding: 5px 10px;
          background: #035592;
          border-radius: 5px;
          color: #fff;
          font-family: 'Inter-Bold',sans-serif;
          cursor: pointer !important;
        }
        .ant-form-item {
          width: 48%;
          margin-bottom: 25px !important;
          @media screen and (max-width: 1199px){
            margin-bottom: 20px !important;
          }
          @media screen and (max-width: 991px){
            margin-bottom: 15px !important;
          }
          .ant-form-item-explain {
            text-align: left;
            
          }
          .ant-input-group {
            border-radius: 5px;
            border: 1px solid #DBD8D8;
            .ant-input-group-addon {
              color: #2d2c2c;
              border: none !important;
              border-right: 1px solid #DBD8D8 !important;
              background: #EFEFEF;
            }
            .ant-input {
              border: none !important;
            }
          }
          .ant-select{
            .ant-select-selector {
              .ant-select-selection-placeholder {
                text-align: left;
              }
            }
            &.ant-select-focused{
              border: none !important;
            }
          }
          .ant-picker-disabled {
            background: #EFEFEF !important;
          }
        }
        .ant-form-item-has-error {
          .ant-input-group,.ant-form-item-control-input-content {
            border-color: #ff4d4f !important;
          }
        }
      }
    }
    .ant-form-item {
      .ant-checkbox-group {
          width: 100%;
          display: flex;
          .ant-checkbox-wrapper {
            width: 50%;
          }
        }
      .ant-input-group-compact {
        width: 100%;
        display: flex;
        .ant-form-item {
          :first-child{
            margin-right: 4%;
          }
          width: 48%;
          margin-bottom: 0px !important;
          .ant-picker {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #dbdbdb;
            input:focus {
              outline: none !important;
            }
            &.ant-picker-focused, &:hover{
              border-color: #d9d9d9;
            }
          }
        .ant-form-item-explain {
          text-align: left;
          .ant-form-item-control-input-content {
            border-color: #ff4d4f !important;
          }
        }
        }
      }
    }
  }
  &.redemption-list-modal {
    width: 800px;
    .ant-modal-content {
      max-height: 95vh;
      overflow-y: auto;
      @media screen and (max-width: 991px){
        padding: 40px 20px;
      }
      .ant-modal-close {
        display: block;
        .anticon {
          color: #e02929;
        }
      }
      .ant-modal-body {
        .detail {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    width: 650px!important;
  }
  @media screen and (max-width: 991px) {
    width: 600px!important;
  }
  .ant-modal-close
  {
    display:none;
  }
  .ant-modal-content
  {
    padding: 45px;
    box-shadow: -10px 3px 20px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    @media screen and (max-width: 1199px) {
      padding: 40px;
    }
    @media screen and (max-width: 991px) {
      padding: 35px;
    }
    .ant-modal-header
    {
      border:none;
      text-align: center;
      padding: 0px 0px 25px;
      @media screen and (max-width: 1199px) {
        padding:  0px 0px 20px;
      }
      @media screen and (max-width: 991px) {
        padding:  0px 0px 15px;
      }
      .ant-modal-title
      {
        font-size: 26px;
        line-height: 31px;
        font-family: 'Inter-Bold',sans-serif;
        color:#035592;
        @media screen and (max-width: 1600px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media screen and (max-width: 991px) {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    .ant-modal-body {
      padding: 0px;
      text-align: center;
      border: 0px;
      p 
      {
        max-width: 540px;
        margin: auto;
        font-size: 16px;
        line-height: 19px;
        color: #2D2C2C;
        @media screen and (max-width: 1440px){
          font-size: 15px;
          line-height: 18px;
        }
      }
      .btn-group
      {
        margin-top: 45px;
        justify-content: center;
        @media screen and (max-width: 1199px) {
          margin-top: 35px;
        }
        @media screen and (max-width: 991px) {
          margin-top: 30px;
        }
      }
      .plan-detail {
        div {
          gap: 10px;
          display: flex;
          p, span {
            color: #035592;
            font-weight: 700;
            width: 50%;
            flex: 1;
          }
          p {
            text-align: right;
          }
          span {
            text-align: left;
          }
          .tax-link {
            font-weight: 300;
            margin-left: -110px;
          }
        }
      }
    }
    .ant-modal-footer
    {
     display:none;
    }
  }
  &.role-permission-modal{
    max-width: 970px;
    width: 100% !important;
    padding: 10px;
    .ant-form-item-explain-error
    {
      text-align: left;
    }
    .ant-form-item-required::before 
    {
      right:-17px!important;
    }
    .ant-modal-content{
      padding: 35px;
      @media screen and (max-width: 1600px){
        padding: 25px;
      }
      .ant-modal-header{
        border-bottom: 1px solid #DBD8D8 !important;
        @media screen and (max-width: 1440px){
          padding: 0px 0px 20px;
        }
      }
      .ant-modal-body{
        padding: 25px 0 10px;
        @media screen and (max-width: 1600px){
          padding: 25px 0 5px;
        }
        .role-permission-modal-table{
          .ant-form-item{
            &:first-child{
              max-width: 352px;
              margin: 35px auto 25px;
              @media screen and (max-width: 1600px){
                margin: 25px auto 20px;
              }
              .ant-form-item-label{
                label{
                  position: relative;
                  &::after{
                    content: ":";
                    display: inline-block;
                    position: absolute;
                    right: -13px;
                  }
                }
              }
            }
            .roles-table{
              .ant-table-header{
                .ant-table-thead{
                  > tr{
                    box-shadow: 0px 1px 4px rgb(34 196 242 / 20%);
                    >th{
                      text-align: center;
                    }
                  }
                }
              }
              .ant-table-body{
                .ant-table-tbody{
                  > tr{
                    box-shadow: none;
                   
                    > td{
                      border-bottom: 0;
                      padding: 15px 20px;
                      text-align: center;
                      @media screen and (max-width: 1440px){
                        padding: 12px 20px;
                      }
                      &:first-child{
                        border-radius: 5px 0 0 5px;
                        text-align: left;
                      }
                      &:last-child{
                        border-radius: 0 5px 5px 0;
                      }
                    }
                    &:nth-child(odd){
                      >td{
                        background: #F4FDFF;
                      }
                    }
                  }
                .icon-wrapper{
                  .ant-checkbox-wrapper{
                    .ant-checkbox{
                      &.ant-checkbox-checked{
                        .ant-checkbox-inner{
                          width: 18px;
                          height: 18px;
                          background-color: white;
                          border-color: #035592;
                          @media screen and (max-width: 1440px){
                            width: 16px;
                            height: 16px;
                          }
                          &::after{
                            border-color: #035592;
                            left: 3px;
                            top: 7px;
                            @media screen and (max-width: 1440px){
                              left: 2px;
                              top: 6px;
                          }
                          }
                        }
                      }
                      .ant-checkbox-inner{
                          width: 18px;
                          height: 18px;
                          background-color: white;
                          border-color: #B8B8B8;
                          @media screen and (max-width: 1440px){
                            width: 16px;
                            height: 16px;
                          }
                          &::after{
                            border-color: #B8B8B8;
                          }
                        }
                    }
                    
                    &.ant-checkbox-wrapper-checked  
                    {
                      cursor: default;
                      .ant-checkbox,.ant-checkbox-inner
                      {
                        cursor: default;
                        &.ant-checkbox-checked, .ant-checkbox-input
                        {
                          cursor: default;
                          &::after
                          {
                            border: none;
                          }
                         
                        }
                      }
                     
                      input[checked]
                      {
                        cursor: not-allowed!important;
                        .ant-checkbox-checked,.ant-checkbox-input
                        {
                          cursor: not-allowed!important;
                        }
                        .ant-checkbox-inner
                        {
                          cursor: default!important;
                          &::after{
                              border-color: #B8B8B8!important;
                            }
                        }
                        & + .ant-checkbox-inner
                        {
                          border-color: #B8B8B8!important;
                          &::after{
                              border-color: #B8B8B8!important;
                            }
                        }
                      }
                     
                      
                    }
                    
                    
                   
                  }
                }
              }
              }
            }
          }
        }
        .btn-group{
          @media screen and (max-width: 1600px){
            margin-top: 10px;
          }
        }
      }
    }
    .btn-group{
      position: unset;
    }
  }
}
.modal-tab{
  form{
  .emailList{
    .ant-form-item-control{
      .ant-form-item-explain-error{
        padding-left: 38px;
      }
    }
  }
}
}
//----------------------------pagination---------------------------------------
    .ant-pagination {
      margin: 70px 0px 25px;
      @media only screen and (max-width: 1800px) {
       margin: 50px 0px 20px;
      }
      @media only screen and (max-width: 1440px) {
        margin: 40px 0px 15px;
       }
       @media only screen and (max-width: 1199px) {
        margin: 35px 0px 15px;
       }
       @media only screen and (max-width: 991px) {
        margin: 30px 0px 10px;
       }
      float: right;
      .ant-pagination-item {
        &:focus {
          background-color: #fff !important;
          a{
            color: #035592 !important;
          }
        }
        margin-right: 5px;
        border-radius: 10px;
        border: none;
        background-color: white;
        color: #2d2c2c;
        height: 40px;
        min-width: 40px;
        line-height: 40px;
       
        box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
        @media only screen and (max-width: 1199px) {
          min-width: 34px;
          height: 34px;
          line-height: 34px;
        }
        a {
          color: #2d2c2c;
          font-size: 14px;
        }
        &.ant-pagination-item-active, &.ant-pagination-item-active:focus {
          background-color: #035592 !important;
          a {
            color: #fff !important;
          }
        }
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        height: 40px;
        @media only screen and (max-width: 1199px) {
          height: 34px;
        }
        .ant-pagination-item-link {
          border: none;
          background: #fff;
          
          box-shadow:0px 1px 4px rgba(34, 196, 242, 0.2); 
          height: 40px;
          width: 40px;
          border-radius: 10px;
          @media only screen and (max-width: 1199px) {
            width: 34px;
            height: 34px;
          }
          &:focus 
          {
            box-shadow: none!important;
            outline: 0.5px solid ${variables.focusColor} !important;
          }
        }
        &:focus {
          .ant-pagination-item-link {
            span{

              color: #035592;
            }
          }
        }
      }
      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis {
        color: #2d2c2c;
        width: 40px;
        @media only screen and (max-width: 1199px) {
          width: 34px;
        }
      }
      .ant-pagination-options {
        .ant-select-selector {
          padding: 10px;
          border: none!important;
          background-color: #fff;
          height: 40px;
          padding-right: 18px;
          line-height: 40px;
          box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
          border-radius: 10px!important;
          @media only screen and (max-width: 1199px) {
            height: 34px!important;
          }
          .ant-select-selection-item {
              font-size: 14px!important;
              line-height: 16px;
              color: #2d2c2c;
              font-weight: 400;
              padding-bottom: 0px;
              @media only screen and (max-width: 1199px) {
                /* padding-bottom: 10px; */
              }
            }
          
          .ant-select-arrow {
            right: 10px;
          }
        }
      }
    }
    //buttongroup
    .new-obj-wrapper {
      text-align: end;

      .ant-btn-group {
        border-radius: 10px;
        align-items: center;
        box-shadow: 0px 1px 4px rgb(34 196 242 / 20%);
        height: 40px;
        span.new-obj {
          padding: 10.5px 15px;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          color: #035592;
          background-color: #fff;
          border-radius: 10px 0px 0px 10px;
          height: 40px;
          display: flex;
          align-items: center;
          @media only screen and (max-width: 1440px) {
            padding: 9px 13px;
            font-size: 15px;
          }
          @media only screen and (max-width: 1199px) {
            padding: 8px 12px;
            font-size: 15px;
          }
        }
        .ant-btn {
          height: auto!important;
          padding:8.5px 10px !important;
          width: auto;
          line-height: normal;
          span {
            line-height: normal;
            height: 18px;
            font-size: 18px;
            @media only screen and (max-width: 1199px) {
              font-size: 16px;
            }
          }
          &:focus{
            a{
              text-decoration: none !important;
            }
          }
          &:hover, &:active,&:focus {
            border-color: #035592!important;
            color: #035592!important;
            i::before {
              color: #035592!important;
            }
          }
          @media only screen and (max-width: 1440px) {
            padding:8.5px 10px !important;
          }
          @media only screen and (max-width: 1199px) {
            padding: 6px 9px !important;
          }
          @media only screen and (max-width: 991px) {
            padding: 6px 8px !important;
          }
        }
        & > .ant-btn:last-child:not(:first-child) {
          border-radius: 0px 10px 10px 0px!important;
          height: 45px !important;
        }
      }
    }
    .new-obj-wrapper.last {
      float: left;
      margin-top: -55px;
      @media only screen and (max-width: 1199px) {
        margin-top: -50px;
      }
    }
  // ------------table-----------------------
  .ant-table {
    background: transparent;
    .ant-table-header {
      .ant-table-column-sorters {
        width: 20px;

        .ant-table-column-sorter-inner {
          // display: flex;
          // flex-direction: row;
          // align-items: center;
          .ant-table-column-sorter-up + .ant-table-column-sorter-down {
            // margin-top: 0px;
          }
          span:first-child {
            &::after {
              content: '';
              // content: "\\e92c";
              font-family: "icomoon";
            }
            svg {
              // display: none;
            }
          }
          span:last-child {
            &::after {
              content: '';
              // content: "\\e92d";
              font-family: "icomoon";
            }
            svg {
              // display: none;
            }
          }
        }
      }
    }
    table {
      border-spacing: 0px 5px;
      margin-top: -5px;
    }
    .ant-table-thead > tr > th {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding: 19px 10px 22px;
      color: #2d2c2c;
      background-color: white;
      outline: none;
      &:before {
        display: none;
      }
      &:focus{
        background: rgba(0, 0, 0, 0.04);
      }
      @media only screen and (max-width: 1800px) {
        padding: 15px 10px;
        font-size: 15px;
        line-height: 18px;
      }
      @media only screen and (max-width: 1600px) {
        padding: 13px 5px;
      }
      @media only screen and (max-width: 1440px) {
        padding: 12px 5px;
        font-size: 15px;
        line-height: 16px;
      }
      @media only screen and (max-width: 1199px) {
        padding: 10px 5px;
        font-size: 15px;
        line-height: 18px;
      }
      @media only screen and (max-width: 991px) {
        padding: 8px 5px;
        font-size: 14px;
        line-height: 16px;
      }
        /* &.focus-visible {
          outline: none;
        } */

    }
    thead tr,
    tbody tr {
      box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
    }
    .ant-table-tbody > tr > td {
      padding: 26px 15px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      background-color: #fff;
      color: #2D2C2C;
      @media only screen and (max-width: 1800px) {
        padding: 20px 12px;
        font-size: 15px;
        line-height: 18px;
      }
      @media only screen and (max-width: 1600px) {
        
        padding: 18px 5px;
      }
      @media only screen and (max-width: 1440px) {
        padding: 15px 10px;
      }
      @media only screen and (max-width: 1199px) {
        
        font-size: 14px;
        line-height: 17px;
      }
    }
    
    .ant-table-cell {
      .ant-table-column-sorters {
        justify-content: start;
        .ant-table-column-title {
          flex: none;
          margin-right: 4px;
        }
      }
    }

    .ant-table-expanded-row {
      .ant-table-wrapper::before {
        display: none;
      }
      .ant-table {
        margin: 5px 0px 25px 36px;
        .ant-table-tbody > tr > td {
          /* padding: 17px 15px; */
        }
      }
      thead {
        display: none;
      }
      &.ant-table-expanded-row-level-1 {
        & > td:first-child {
          padding: 0px;
          background-color: #fff;
        }
      }
      .ant-table-cell {
        &:first-child {
          padding-left: 43px;
        }
      }
      tr {
        box-shadow: none;
      }
      .ant-table-cell {
        background-color: #f4fdff;
      }
    }
    &.ant-table-empty{
      margin-bottom: 70px;
        @media only screen and (max-width: 1440px)
        {
          margin-bottom: 65px;
        }
        @media only screen and (max-width: 1199px)
        {
          margin-bottom: 60px;
        }
      .ant-table-body{
        overflow: hidden !important;
      .ant-table-placeholder{
        box-shadow: none;
        > td{
          background-color: transparent;
          border-bottom: 0;
        }
        .ant-table-expanded-row-fixed{
          padding: 0;
          .ant-empty{
            background: #fff;
            margin-top: 0;
            margin-bottom: 65px;
            padding: 30px 0;
          }
        }
      }
    }
    }
  }

  /* modal */
  .ant-modal{
      &.onHoldPopup{
      box-shadow: -10px 3px 20px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      .ant-modal-content{
        .ant-modal-body{
          padding: 0 25px 45px;
          @media screen and (max-width: 991px) {
            padding: 30px 20px;
          }
        }
        .ant-modal-footer{
          display: block;
          border: none;
          text-align: center;
          padding: 0 25px 0;
          @media screen and (max-width: 991px) {
            padding: 0 20px 30px;
          }
        }
      }
    }
  }

.action-dropdown{
  .ant-dropdown-menu{
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.161);
      &::before{
        content: '';
        width: 10px;
        height: 10px;
        background: #fff;
        position: absolute;
        right: 3px;
        top: -4px;
        z-index: 0;
        bottom: 100%;
        transform: rotate(45deg);
        box-shadow: -2px -2px 7px 0px rgb(0 0 0 / 8%);
    }
  }  
}

/* Drawer */
.ant-drawer{
  .ant-drawer-content-wrapper{
    max-width: 500px;
    width: 100% !important;
    @media screen and (max-width: 1600px){
      max-width: 450px;
    }
    @media screen and (max-width: 1440px){
      max-width: 400px;
    }
    .ant-drawer-header{
      padding: 25px 25px;
      border-bottom: 1px solid #DBD8D8;
      @media screen and (max-width: 1600px){
        padding: 20px;
      }
      @media screen and (max-width: 1440px){
        padding: 15px 20px;
      }
      .ant-drawer-extra 
      {
        position: absolute;
        right: 60px;
        height: 18px;
        width: 18px;
        span
        {
          &::before 
          {
            font-size: 18px;
          }
        }
      }
      .ant-drawer-header-title 
      {
        flex-direction: row-reverse;
        width: 100%;
      }
      .ant-drawer-title{
        color: #035592;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        font-family: 'Inter-Bold',sans-serif;
        @media screen and (max-width: 1199px){
          font-size: 16px;
          line-height: 20px;
        }
      }
      .ant-drawer-close{
        margin: 0;
        padding: 0;
        .icon-close{
          &::before{
            color: #B8B8B8;
            font-size: 14px;
            @media screen and (max-width: 1199px){
              padding: 12px;
            }
          }
        }
      }
    }
    .ant-drawer-body{
      padding: 25px;
      @media screen and (max-width: 1600px){
        padding: 20px;
      }
      @media screen and (max-width: 1440px){
        padding: 15px 20px;
      }
      .topbarNotification{
        .isoDropdownBody{
          .isoDropdownListItem{
            display: flex;
            padding: 25px 0;
            border-bottom: 1px solid #DBD8D8;
            text-decoration: none;
            &:focus{
              p.comment{
                text-decoration: underline;
              }
            }
            @media screen and (max-width: 1600px){
              padding: 20px 0;
            }
            @media screen and (max-width: 1440px){
              padding: 15px 0;
            }
            &:first-child{
              padding-top: 0;
            }
            .user{
              margin-right: 10px;
            }
            .notify-msg{
              .msg-content{
                .title{
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #2D2C2C;
                  font-family: 'inter-Regular';
                  margin-bottom: 15px;
                  word-break: break-word;
                  @media screen and (max-width: 1600px){
                    font-size: 15px;
                    line-height: 19px;
                    margin-bottom: 10px;
                  }
                  strong{
                    font-weight: 600;
                    margin-right: 5px;
                    &:last-child{
                      margin-left: 5px;
                    }
                  }
                }
                .time{
                  color: #B8B8B8;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  @media screen and (max-width: 1199px){
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.kanban-view-drawer{
  .ant-drawer-content-wrapper{
    max-width: 500px;
    width: 100% !important;
    @media screen and (max-width: 1600px){
      max-width: 450px;
    }
    .ant-drawer-content{
      .ant-drawer-header{
        @media screen and (max-width: 1600px){
          padding: 20px 25px;
        }
        .ant-drawer-close{
          padding: 0;
          margin: 0;
          @media screen and (max-width: 1199px){
            padding:0;
          }
          @media screen and (max-width: 991px){
            padding: 0;
          }
        }
        .ant-drawer-extra {
          left: 190px;
          right: 0px !important;
          color: #9e9e9e;

          svg{
            height: 17px;
            width: 17px;
          }
        }
      }
      .ant-drawer-body{
        padding: 35px 25px 25px;
        @media screen and (max-width: 1600px){
          padding: 20px 20px 20px;
        }
        ul{
          padding-inline-start: 0px;
          li{
            &.item{
              display: flex;
              padding: 0 0 25px;
              position: relative;
              @media screen and (max-width: 1600px){
                padding: 0 0 20px;
              }
              @media screen and (max-width: 1440px){
                padding: 0 0 15px;
              }
              &:last-child{
                padding-bottom: 0;
                &::before{
                  display: none;
                }
                .item-detail{
                  border: none;
                  padding-bottom: 0;
                }
              }
              .item-track{
                margin-right: 20px;
                .ant-progress{
                  .ant-progress-inner{
                    width: 65px !important;
                    height: 65px !important;
                    .ant-progress-text{
                      font-size: 12px;
                      line-height: 15px;
                      font-weight: 600;
                    }
                  }
                }
              }
              .item-detail{
                min-width: 350px;
                border-bottom: 1px solid #DBD8D8;
                padding-bottom: 25px;
                @media screen and (max-width: 1600px){
                  padding-bottom: 20px;
                }
                @media screen and (max-width: 1440px){
                  padding-bottom: 15px;
                }
                .title{
                  font-size: 20px;
                  line-height: 25px;
                  font-weight: 700;
                  margin-bottom: 15px;
                  color: #035592;
                  @media screen and (max-width: 1600px){
                    font-size: 18px;
                    line-height: 23px;
                  }
                  @media screen and (max-width: 1440px){
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 10px;
                  }
                }
                p{
                  color: #2D2C2C;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  margin-bottom: 15px;
                  @media screen and (max-width: 1600px){
                    font-size: 15px;
                    line-height: 19px;
                  }
                  @media screen and (max-width: 1440px){
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
                .user-title{
                  color: #2D2C2C;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  margin: 0;
                  @media screen and (max-width: 1600px){
                    font-size: 15px;
                    line-height: 19px;
                  }
                  @media screen and (max-width: 1440px){
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
                .avatar{
                  width: 25px;
                  height: 25px;
                }
              }
              &::before{
                content: '';
                position: absolute;
                left: 32px;
                top: 70px;
                height: calc(100% - 75px);
                border-left: 1px dashed #DBD8D8;
              }
            }
          }
        }
      }
    }
  }
}
}


.rateWrapper{
  position:relative;
  .ant-rate{
      margin-left: 50px;
  }
  .ant-rate-text{
    height: 100%;
      position: absolute;
      top: 66%;
      left: 0;
  }
}

/*Upload photo*/
.profile-item {
  .ant-form-item-content {
    position: relative;
  }
  .redirect.remove-photo {
    margin: -12px;
    display: inline-block;
    position: absolute;
    right: calc(100% - 188px) !important;
    bottom: 10px;
  }

  .profile-photo {
    border-radius: 100%;
    .ant-upload-picture-card-wrapper {
      width: auto;
    }
    .ant-upload.ant-upload-select {
      vertical-align: bottom;
      margin-left: -40px;
      z-index: 9;
      position: relative;
      &:focus-within {
        background-color: #32386a !important;
      }
    }
    .ant-upload-list-picture-card {
      display: inline-block;
      cursor: default;
    }
    .ant-upload-list-picture-card-container {
      margin: 0;
      border-radius: 100%;
      height: 80px;
      width: 80px;
      position: relative;
      z-index: 0;
      .ant-tooltip {
        display: none;
      }
      .ant-upload-list-item {
        padding: 0px;
        border: none;
        background-color: #e9e9e9;
        border-radius: 100%;
        .ant-upload-list-item-info {
          cursor: default;
          &:before {
            display: none;
          }
        }
        .ant-upload-list-item-thumbnail,
        .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
          margin: auto;
          border-radius: 100%;
          object-fit: cover;
          object-position: center;

          img {
            margin: auto;
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
        }

        .anticon {
          width: 100%;
          height: 100%;
          background: url(/static/media/user.0f2a2170.svg) no-repeat center;
          background-size: 30px;
          svg {
            display: none;
          }
        }
      }
    }
  }
  .ant-upload {
    margin: 0px;
  }

  .ant-upload.ant-upload-select {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #035592;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    left: 25%;
    top: 60%;
    border: none;
    img {
      width: 15px;
      vertical-align: text-top;
    }
  }
}
::-webkit-scrollbar{
    background: transparent;
    width: 10px;
    height: 10px !important;;
    padding: 10px;
}
::-webkit-scrollbar-thumb{
    background: #B8B8B8;
    border-radius: 40px;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-corner { display: none; }
::-webkit-scrollbar-track{margin: 2px; height: 50px;}

.ant-table,.ant-table-wrapper{
  ::-webkit-scrollbar
  {
    height: 5px;
  }
 
  .ant-table-body{
    ::-webkit-scrollbar{
    background: transparent;
    /* width: 6px; */
    height: 5px;
    /* padding: 10px; */
}
  }
}

.user-profile-dropdown{
  font-family: 'Inter-Regular', sans-serif;
  .ant-popover-content{
    .ant-popover-arrow{
      right: 5px;
      .ant-popover-arrow-content{
        box-shadow: -2px -2px 5px rgb(0 0 0 / 4%);
      }
    }
    .ant-popover-inner{
      box-shadow: -2px -2px 5px rgb(0 0 0 / 1%);
      .ant-popover-inner-content{
        .isoUserDropdown{
          box-shadow: 0 0 10px rgb(0 0 0 / 10%);;
          .isoDropdownLink{
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #2D2C2C;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1440px){
              font-size: 15px;
            }
            .icon{
              margin-right: 15px;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

.anchor-tag {
  &:focus {
    text-decoration: underline;
    span{
        display: inline-block;
       .icon-add-tag{
         .path1{
         text-decoration: none; }
       }
     }
  }
  &.object-name{
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${variables.darkGray} !important;
    /* margin-bottom: 35px; */
    @media screen and (max-width: 1600px) {
      font-size: 14px;
    }
    &.keySubtitle {
      float: left;
    }
  }
}

input, textarea {
  &:focus {
    outline: 0.5px solid ${variables.focusColor} !important;
  }
}

.ant-select-focused {
  border: 0.5px solid ${variables.focusColor} !important;
  border-radius: 5px;
}

.ant-slider-handle {
  &:focus {
    outline: 0.5px solid ${variables.btn_bg_color} !important;
  }
}

button {
  &:focus {
    box-shadow: 4px 6px 8px ${variables.focusColor} !important;
  }
}

.aTagBtn {
  border: 0px none !important;
  padding: 0px;
  &:focus{
    box-shadow: none !important;
    span {
      text-decoration: underline;
    }
    span.path1, span.path2 , span.path3 
    {
      text-decoration: none;
    }
  }
}

.loginSignupButton {
  width: 238px; 
  height: 40px !important;
  font-size: 14px;
  border-radius: 0;
  padding: 0px!important;
  margin: 10px auto 5px;
}

.disabled-aTag{
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed !important;
  &:hover {
    border-color: #1890ff;
    background: #f5f5f5;
    color: rgba(0,0,0,0.25);
  }
}

.isOnlyIcon{
  border: 0px none !important;
  padding: 0px;
  background: transparent;
  &.sound-btn {
    height: 18px;
  }
  &:hover, &:focus {
    background: transparent;
    box-shadow: none !important;
    text-decoration: none !important;
    .icon-star, .icon-link, .icon-hold, .icon-delete, .icon-edit,.icon-child-component {
      /* filter: drop-shadow(1px 1px 1px #000); */
      &:before{
        color: #c4e6ff !important;
      }
    }
    .icon-fill-star, .icon-media-play {
      filter: drop-shadow(1px 1px 1px #000);
    }
    .icon-fill-star:before, .icon-media-play:before {
      color: #c4e6ff !important;
    } 
    span{
      &:before{
        color: ${variables.btn_bg_color} !important;
      }
    }
  }
}

/* for firefox  */
.ant-table-content, .isomorphicContent
{
  &:focus-visible 
  {
    outline: none;
  }
}

.hide-scrollbar {
  overflow: hidden !important;
}

.new-obj-wrapper {
    &.vmsb {
      .ant-btn-group {
        @media screen and (max-width: 1440px) {
          height: 35px;
        }
        @media screen and (max-width: 1199px) {
          height: 32px;
        }
        button {
          margin-left: 0px !important;
        }
        span.new-obj {
          flex-direction: column;
          min-width: 120px;
          cursor: pointer;
        }
      }
    }
  }

  .new-obj-wrapper, .last {
    position: relative;
  }
  .ant-btn-group.ant-popover-open {
    .ant-btn.cmn-btn {
      position: inherit;
    }
  }

  &.first-vmsb-popover {
    top: 50px !important;
  }
  &.subscription-popover {
    top: 155px !important;
    @media only screen and (min-width: 1600px) {
      top: 200px !important;
    }
  }
  &.manage-popover {
    width: 200px !important;
  }

  &.ant-popover-placement-topLeft{
    top: unset !important;
    left: -118px!important;
    bottom: 10px; 
    @media only screen and (max-width: 1440px) {
      bottom: 90px;
    }
  }

  .new-vmsb-btn
  {
    .ant-btn-group
    {
    height: auto;
    }
    .fill-red {
      background: #e02929;
      border: 1px solid #e02929 !important;
    }
    .fill-disabled {
      .ant-btn > span {
        color: #fff;
      }
    }
  }
  
  .invite-user-popconfirm {
    width: 460px !important;
    .ant-popover-inner{
      .ant-popover-message-title {
        color: #035592;
        font-weight: 700;
      }
      .ant-popover-buttons {
        .ant-btn-primary {
          background: #035592 !important;
        }
      }
    }
  }
  .new-vmsb-popover
{
  width: 157px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(34, 196, 242, 0.2);
  padding: 0;
  .ant-popover-inner
  {
    border-radius: 10px;
  }
  .ant-popover-inner-content 
  {
    padding:0px;
    .ant-btn-group
    {
      display: flex;
      flex-direction: column;
      .ant-btn 
      {
        width: 100%;
        border: 0!important;
        padding: 15px 25px!important;
        height: auto !important;
        &:first-child 
        {
          border-radius: 10px 10px 0px 0px!important;
        }
        &:last-child 
        {
          border-radius: 0px 0px 10px 10px!important;
        }
        &:only-child{
          border-radius: 10px 10px 10px 10px!important;
        }
        @media screen and (max-width: 1440px){
          padding: 10px 20px!important
          }
          @media screen and (max-width: 1199px){
          padding: 8px 16px!important;
          }
        span 
        {
          color: #035592;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          @media screen and (max-width: 1440px){
          font-size: 15px;
          line-height: 17px;
          }
          @media screen and (max-width: 1199px){
          font-size: 14px;
          line-height: 16px;
          }
        }
        &:hover, &:focus
        {
          
          background-color: #035592;
          .fill-red {
            background-color: #fff;
            border: 1px solid #e02929 !important;
          }
          span 
          {
            color: #fff;
          }
        }
      }
      .ant-btn[disabled] {
          cursor: not-allowed;
          border: 1px solid #999999;
          background-color: #cccccc;
          color: #666666;
          span {
            color: #fff !important;
          }
          cursor: not-allowed;
          &:hover {
            background-color: #cccccc !important;
            border: none !important;
            span {
              color: #fff !important;
            }
          }
        }
      .fill-red {
        &:hover {
          background-color: #fff;
          background: #fff;
          border: 1px solid #e02929 !important;
          span {
            color: #e02929 !important;
          }
        }
            span {
              color: #fff;
              &:hover {
                color: #e02929 !important;
              }
            }
          }
    }
  }
  .ant-popover-arrow 
  {
    display: none;
  }
} 
`;
export default WithDirection(GlobalStyles);
