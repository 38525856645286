import moment from 'moment-timezone';
import { dateFormatList, defaultMetaData, subscriptionPlanTypes, usersStatus } from '../../../config/constant';
import { WorkspaceTypes } from './actionTypes';
import {
  WorkspaceState,
  WorkspaceStateAction,
  subscriptionHistory,
  transactionData,
  userData,
  workspaces
} from './types';

const defaultWorkspaceSubscription = {
  title: '',
  subscriptionId: 0,
  status: '',
  data: {
    'Subscription Plan': '',
    'Purchased Date': '',
    'Renew Date': '',
    'Plan Type': '',
    'Next Payment Amount': '',
    'Purchased License': ''
  }
};

const defaultWorkspace = [
  {
    id: null,
    org_id: null,
    orgAdmin: '',
    workspace: '',
    industry: '',
    usersCount: null,
    status: '',
    plan: '',
    planType: ''
  }
];

const initState: WorkspaceState = {
  allWorkspaces: defaultWorkspace,
  exportableWorkspaceList: defaultWorkspace,
  totalCompanies: defaultMetaData,
  workspace: {
    title: '',
    status: '',
    data: {
      id: '',
      name: '',
      email: '',
      sector: '',
      'No. of Users': '',
      'Total Revenue': ''
    }
  },
  workspaceSubscription: defaultWorkspaceSubscription,
  workspaceVmsbOkr: {
    title: '',
    data: {
      'Created Vision': '',
      'Created Mission': '',
      'Created Strategies': '',
      'Created Benefits': '',
      'Created Objectives': '',
      'Created Key Results': '',
      'Created Tasks': ''
    }
  },
  vmsbokr: false,
  allWorkspaceUsers: [],
  workspaceSubscriptions: [],
  allActiveLogs: [],
  allWorkspaceTransactions: [],
  roleChangeSuccess: '',
  roleChangeError: '',
  totalUsers: defaultMetaData,
  totalSubscriptions: defaultMetaData,
  totalTransactions: defaultMetaData,
  totalLogs: defaultMetaData
};

const workspaceListModifier = (data, metaData) => {
  const companyData: workspaces[] = [];
  if (metaData?.totalItems > 0) {
    data.forEach((record) => {
      const planType = subscriptionPlanTypes.filter(
        (planType) =>
          record.organization_master_id.subscription.length > 0 &&
          planType.id === record.organization_master_id.subscription[0].type
      );
      const company = {
        id: record.id,
        org_id: record.organization_master_id.id,
        orgAdmin: record.email,
        workspace: record.organization_master_id.company_name,
        industry: record.organization_master_id.company_industry.name,
        usersCount: record.countOfUsers,
        status: record.status === 1 ? usersStatus.active : usersStatus.inactive,
        plan:
          record.organization_master_id !== null &&
          record.organization_master_id.subscription.length > 0 ?
          record.organization_master_id.subscription[0].plan_id.plan_name
          : 'Not subscribed yet',
        planType:
          record.organization_master_id.subscription.length > 0
            ? record.organization_master_id.subscription[0].type !== null
              ? planType[0].type
              : ''
            : '-'
      };
      companyData.push(company);
    });
    return companyData;
  } else {
    return [];
  }
};

const subscriptionHistoryListModifier = (data) => {
  const subsData: subscriptionHistory[] = [];
  if (data.length > 0) {
    data.forEach((record) => {
      const planType = subscriptionPlanTypes.filter((planType) => planType.id === record.type);
      const subscription = {
        plan: record.plan_id.plan_name,
        planType: record.type !== null ? planType[0].type : '',
        license: record.total_licenses,
        amount: `$${record.total_amount}`,
        admin: record.user_id.created_by,
        renewedDate: moment(record.renew_date).format(dateFormatList),
        promoCode: record.promo_id ? record.promo_id?.promocode : '-',
        url: record.payment_id.length > 0 ? record.payment_id[0].receipt_url : ''
      };
      subsData.push(subscription);
    });
    return subsData;
  } else {
    return [];
  }
};

const workspaceSubscriptionModifier = (data) => {
  const planType = subscriptionPlanTypes.filter(
    (planType) => planType.id === data.organization_master_id.subscription[0].type
  );
  return {
    title: 'Subscription details',
    subscriptionId: data.organization_master_id.subscription[0].id,
    // status: data.status === 0 ? usersStatus.inactive : usersStatus.active,
    data: {
      'Subscription Plan': data.organization_master_id.subscription[0].plan_id.plan_name,
      'Purchased Date': moment(data.organization_master_id.subscription[0].created_at).format(dateFormatList),
      'Renew Date': moment(data.renew_date).format(dateFormatList),
      'Plan Type': data.organization_master_id.subscription[0].type !== null ? planType[0].type : '-',
      'Next Payment Amount': data.next_payment_amount ? `$${data.next_payment_amount}` : '-',
      'Purchased License': data.purchased_licenses
    }
  };
};

const workspaceVmsbOkrModifier = (data) => {
  return {
    title: 'VMSB - OKR details',
    // status: data.status === 0 ? usersStatus.inactive : usersStatus.active,
    data: {
      'Created Vision': data.visionCount,
      'Created Mission': data.missionCount,
      'Created Strategies': data.strategyCount,
      'Created Benefits': data.benefitCount,
      'Created Objectives': data.objectiveCount,
      'Created Key Results': data.keyResultCount,
      'Created Tasks': data.taskCount
    }
  };
};

const workspaceModifier = (data) => {
  return {
    title: 'Workspace details',
    status: data.status === 0 ? usersStatus.inactive : usersStatus.active,
    data: {
      id: data.organization_master_id.id,
      name: data.organization_master_id.company_name,
      email: data.email,
      sector: data.organization_master_id.company_industry.name,
      'No. of Users': data.countOfUsers,
      'Total Revenue': data.total_revenue ? data.total_revenue : '$0'
    }
  };
};

const workspaceUsersModifier = (data) => {
  const userDetails: userData[] = [];
  if (data.metaData.totalItems > 0) {
    data.pagedItems.forEach((user) => {
      const userData = {
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        role: user.role,
        status:
          user.status === 0
            ? usersStatus.inactive
            : user.status === 1
            ? usersStatus.active
            : user.status === 3
            ? usersStatus.pending
            : usersStatus.deleted,
        joiningDate: moment(user.created_at).format(dateFormatList),
        isAdmin: user.is_admin ? true : false
      };
      userDetails.push(userData);
    });
  }
  return userDetails;
};

const activeLogsModifier = (data) => {
  const allLogs: any[] = [];
  data?.forEach((log) => {
    let list = {
      user: log.user_id !== null && `${log.user_id.first_name} ${log.user_id.last_name}`,
      action: log.action_details,
      license: log.action,
      date: moment(log.created_at).format(dateFormatList)
    };
    allLogs.push(list);
  });
  return allLogs;
};

const transactionsModifier = (data) => {
  let transactionData: transactionData[] = [];
  data.forEach((record) => {
    const planType = subscriptionPlanTypes.filter((planType) => planType.id === record.user_sub_id.type);
    const transactions = {
      plan: record.user_sub_id.plan_id,
      planType: record.user_sub_id.type !== null ? planType[0].type : '',
      amount: `$${record.price}`,
      paymentMode: record.method,
      date: moment(record.created_at).format(dateFormatList),
      status: record.status === 1 ? 'successful' : 'unsuccessful'
    };
    transactionData.push(transactions);
  });
  return transactionData;
};

export default function WorkspaceReducer(state = initState, action: WorkspaceStateAction) {
  switch (action.type) {
    case WorkspaceTypes.WORKSPACE_LIST_REQUEST:
      return {
        ...state,
        roleChangeError: '',
        roleChangeSuccess: ''
      };
    case WorkspaceTypes.WORKSPACE_LIST_SUCCESS:
      return {
        ...state,
        allWorkspaces: workspaceListModifier(action.allWorkspaces, action.totalCompanies),
        totalCompanies: action.totalCompanies
      };
    case WorkspaceTypes.EXPORT_WORKSPACE_LIST_SUCCESS:
      return {
        ...state,
        exportableWorkspaceList: action.exportableWorkspaceList
      };
    case WorkspaceTypes.WORKSPACE_DETAIL_SUCCESS:
      return {
        ...state,
        workspace: workspaceModifier(action.workspace)
      };
    case WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_REQUEST:
      return {
        ...state,
        workspaceSubscription: defaultWorkspaceSubscription
      };
    case WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_SUCCESS:
      return {
        ...state,
        workspaceSubscription: workspaceSubscriptionModifier(action.workspaceSubscription)
      };
    case WorkspaceTypes.WORKSPACE_SUBSCRIPTION_DETAIL_FAILURE:
      return {
        ...state,
        workspaceSubscription: defaultWorkspaceSubscription
      };
    case WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_REQUEST:
      return {
        ...state,
        vmsbokr: true
      };
    case WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_SUCCESS:
      return {
        ...state,
        workspaceVmsbOkr: workspaceVmsbOkrModifier(action.workspaceVmsbOkr),
        vmsbokr: true
      };
    case WorkspaceTypes.WORKSPACE_VMSB_OKR_DETAIL_FAILURE:
      return {
        ...state,
        vmsbokr: false
      };
    case WorkspaceTypes.WORKSPACE_CHANGE_ROLE_REQUEST:
      return {
        ...state,
        roleChangeSuccess: '',
        roleChangeError: ''
      };
    case WorkspaceTypes.WORKSPACE_CHANGE_ROLE_SUCCESS:
      return {
        ...state,
        roleChangeSuccess: action.roleChangeSuccess,
        roleChangeError: ''
      };
    case WorkspaceTypes.WORKSPACE_CHANGE_ROLE_FAILURE:
      return {
        ...state,
        roleChangeError: action.roleChangeError,
        roleChangeSuccess: ''
      };
    case WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_REQUEST:
      return {
        ...state,
        roleChangeSuccess: '',
        roleChangeError: ''
      };
    case WorkspaceTypes.WORKSPACE_DETAIL_USER_LIST_SUCCESS:
      return {
        ...state,
        totalUsers: action.totalUsers,
        allWorkspaceUsers: workspaceUsersModifier(action.allWorkspaceUsers)
      };
    case WorkspaceTypes.WORKSPACE_DETAIL_PAGE_CHANGE:
      return {
        ...state,
        totalUsers: action.totalUsers,
        totalSubscriptions: action.totalSubscriptions,
        totalLogs: action.totalLogs,
        totalTransactions: action.totalTransactions
      };
    case WorkspaceTypes.WORKSPACE_SUBSCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        workspaceSubscriptions: subscriptionHistoryListModifier(action.workspaceSubscriptions),
        totalSubscriptions: action.totalSubscriptions
      };
    case WorkspaceTypes.WORKSPACE_ACTIVE_LOG_SUCCESS:
      return {
        ...state,
        allActiveLogs: activeLogsModifier(action.allActiveLogs),
        totalLogs: action.totalLogs
      };
    case WorkspaceTypes.WORKSPACE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        allWorkspaceTransactions: transactionsModifier(action.allWorkspaceTransactions),
        totalTransactions: action.totalTransactions
      };
    case WorkspaceTypes.REMOVE_ALERTS:
      return {
        ...state,
        roleChangeSuccess: '',
        roleChangeError: ''
      };
    default:
      return { ...state };
  }
}
