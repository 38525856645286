import { TooltipTypes } from './actionTypes';

export interface tooltipState {
  allTooltipFiles: {
    pagedItems: [];
    metaData: {
      totalItems: number;
    };
  };
  currentFile: {
    csv_url: string;
    name_of_file: string;
    id: number;
    created_at: string;
  };
  pageTooltip: any[];
  executiveTooltips: any[];
  success: '';
  errorMessage: '';
}

export interface ActionType {
  type: string;
}
type tooltipStateAction = tooltipState & ActionType;

const initState: tooltipState = {
  allTooltipFiles: {
    pagedItems: [],
    metaData: {
      totalItems: 0
    }
  },
  currentFile: {
    csv_url: '',
    name_of_file: '',
    id: 1,
    created_at: ''
  },
  pageTooltip: [],
  executiveTooltips: [],
  success: '',
  errorMessage: ''
};

export default function TooltipReducer(state = initState, action: tooltipStateAction) {
  switch (action.type) {
    case TooltipTypes.PREVIOUS_UPLOADED_TOOLTIPS_SUCCESS:
      return {
        ...state,
        allTooltipFiles: action.allTooltipFiles
      };
    case TooltipTypes.TOOLTIP_UPLOAD_SUCCESS:
      return {
        ...state,
        success: action.success
      };
    case TooltipTypes.CURRENT_FILE:
      return {
        ...state,
        currentFile: action.currentFile
      };
    case TooltipTypes.ALL_TOOLTIPS_SUCCESS:
      return {
        ...state,
        pageTooltip: action.pageTooltip
      };
    case TooltipTypes.EXECUTIVE_TOOLTIPS_SUCCESS:
      return {
        ...state,
        executiveTooltips: action.executiveTooltips
      };
    case TooltipTypes.REMOVE_MESSAGE:
      return {
        ...state,
        success: '',
        errorMessage: ''
      };
    default:
      return { ...state };
  }
}
