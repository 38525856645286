import { ServiceAuthInstance } from "./index";

export const addVisionReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/vision/create_vision",
    data: {
      ...payload,
    },
  });
};



export const getVisionReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `vision/view_vision/${payload.id}`,
  });
};

export const getMissionInVisionReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `vision/get_mission/${payload.id}?page=${payload?.page}&take=${payload?.take}`,
  });
};

export const patchVisionReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: `/vision/edit_vision`,
    data: {
      ...payload,
    }
  });
};
