export enum rolesAndPermissionAction {
  GET_ROLE_PERMISSION_ALLUSERS = "GET_ROLE_PERMISSION_ALLUSERS",
  GET_ROLE_PERMISSION_ALLUSERS_SUCCESS = "GET_ROLE_PERMISSION_ALLUSERS_SUCCESS",
  GET_ROLE_PERMISSION_ALLUSERS_FAILED = "GET_ROLE_PERMISSION_ALLUSERS_FAILED",
  GET_ROLE_BY_ID = "GET_ROLE_BY_ID",
  GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS",
  GET_ROLE_BY_ID_FAILED = " GET_ROLE_BY_ID_FAILED",
  GET_ROLES = "GET_ROLES",
  GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS",
  GET_ROLES_FAILED = "GET_ROLES_FAILED",
  ADD_NEW_ROLES = "ADD_NEW_ROLES",
  ADD_NEW_ROLES_SUCCESS = "ADD_NEW_ROLES_SUCCESS",
  ADD_NEW_ROLES_FAILED = "ADD_NEW_ROLES_FAILED",
}
