import { ServiceAuthInstance, ServiceInstance, apiUrl } from './index';

/* executive */

// get active plans
export const activePlansReq = () => {
  return ServiceInstance({
    method: 'GET',
    url: '/master/active_plans'
  });
};

// purchase subscription
export const postSubscriptionPlanReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/user_subscribe',
    data: {
      ...payload
    }
  });
};

// verify promo code
export const checkPromoCodeReq = (payload: Record<string, string>) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/promocode_check',
    data: {
      ...payload
    }
  });
};

// verify credit card
export const saveCardReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/owner_card_save',
    data: {
      ...payload
    }
  });
};

// country list
export const getCountryListReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: apiUrl.COUNTRY_LIST
  });
};

// country by id
export const getCountryByIdReq = (id) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `${apiUrl.COUNTRY_BY_ID}/${id}`
  });
};

export const getStatesByIdReq = (id) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `${apiUrl.STATES_BY_ID}/${id}`,
  });
};

export const getStatesListReq = (id) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `${apiUrl.STATES_LIST}/${id}`,
  });
};

// save payment address detail
export const newCardReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/payment_address_detail',
    data: {
      ...payload
    }
  });
};

// make subscription payment
export const makePaymentReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/subscriber_payment'
  });
};

/* subscription after sign in */

// save payment address detail after sign in
export const savePaymentAddressReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/payment_address_detail',
    data: { ...payload }
  });
};

// purchase license
export const additionalLicenseReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/user_additional_license',
    data: { ...payload }
  });
};

// country list
export const getCountryDropdownReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: apiUrl.COUNTRY_DROPDOWN
  });
};

// country by id
export const getCountryDataByIdReq = (id) => {
  return ServiceAuthInstance({
    method: 'GET',
    url: `${apiUrl.COUNTRY_DATA_BY_ID}/${id}`
  });
};

// subscriber payment
export const subscriberPaymentReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/subscriber_payment'
  });
};

// cancel subscription verify otp
export const cancelSubscriptionReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/cancel_subscription',
    data: payload
  });
};

// cancel subscription request otp
export const cancelSubscriptionOTPReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/cancel_subscription_otp'
  });
};

// saved card detail
export const getSavedCardReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/auth/retrieve_card_details'
  });
};

// card replace
export const replaceCardReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/replace_card',
    data: { ...payload }
  });
};

export const verifySubscriptionReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/auth/subscription_verify'
  });
};

// transaction history
export const viewTransactionReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/view_transaction',
    data: { ...payload }
  });
};

// subscription plans history
export const subscriptionHistoryReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/payment_details',
    data: { ...payload }
  });
};

// plan upgrade
export const upgradePlanReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/upgrade_plan',
    data: { ...payload }
  });
};

// user de-activate
export const downgradeUser = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/downgrade_users',
    data: { ...payload }
  });
};

// user re-activate
export const upgradeUser = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/upgrade_users',
    data: { ...payload }
  });
};

// time update from month/year to hours/minutes
export const renewCheckReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/renew_check'
  });
};

// verify subscription is active or not
export const checkSubscriptionReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/check_subscription'
  });
};

// users filter acc. to status
export const checkUserStatusReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: `/auth/user_status/${payload.status}`,
    data: { ...payload }
  });
};

// purchase subscription after sign in
export const purchaseSubscriptionPlanReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/user_subscribe',
    data: {
      ...payload
    }
  });
};

// check available subscription
export const checkAvailablePlanReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/check_available_subscription'
  });
};

// delete invited users
export const deleteInvitedUserReq = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/delete_invited_user',
    data: { ...payload }
  });
};

export const viewPlansReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/auth/view_plan'
  });
};

// check promo code
export const verifyPromo = (payload) => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/promocode_check',
    data: { ...payload }
  });
};

export const checkoutSessionReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/create-checkout-session'
  });
};

export const cardVerifyReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/master/verify_card'
  });
};

export const cardVerifyPostSigninReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/verify_card'
  });
};

export const checkoutSessionPostSigninReq = () => {
  return ServiceAuthInstance({
    method: 'POST',
    url: '/auth/create-checkout-session'
  });
};

export const getCardDetailsReq = () => {
  return ServiceAuthInstance({
    method: 'GET',
    url: '/master/retrieve_card'
  });
};
