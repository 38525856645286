import axios, { AxiosInstance } from 'axios';
import { getSubDomain, baseURL } from './subdomain';
import { history } from '../helpers/history';
// SECTION - AXIOS INSTANCE

// Axios NoAuth Instance

export const ServiceInstance: AxiosInstance = axios.create({
  baseURL: baseURL(),
  headers: {
    'content-type': 'application/json',
    accept: 'application/json'
  }
});

// Axios Auth Instance
export const ServiceAuthInstance: any = axios.create({
  baseURL: baseURL(),
  headers: {
    'content-type': 'application/json',
    accept: 'application/json'
  }
});

// Admin Axios Auth Instance
export const ServiceAdminAuthInstance: any = axios.create({
  baseURL: baseURL(),
  headers: {
    'content-type': 'application/json',
    accept: 'application/json'
  }
});

// SECTION - API URLS
export const apiUrl = {
  LOGIN: '/auth/login',
  MANAGER_LIST: '/auth/manager_data',
  DEPARTMENT_LIST: '/organization/department',
  MANAGER_INSERT: '/auth/manager_insert',
  DEPARTMENT_ADD: '/auth/department_add',
  ADMIN_LOGIN: '/admin/login',
  OAUTH_VERIFY_URL: 'auth/verifycode',
  EMAIL_VERIFY: 'auth/verify',
  SIGNUP: '/auth/signup',
  FORGOT_PASSWORD: '/auth/forgotpassword',
  VALIDATE_TOKEN: '/auth/check_token',
  VALIDATE_USER_LIMIT: '/auth/max_users_check',
  CHANGE_PASSWORD: '/auth/changepassword',
  RESET_PASSWORD: '/auth/resetPassword',
  RESET_FORGOT_PASSWORD: '/auth/verify/forgotPassword/',
  VERIFY_ACCOUNT_LINK: 'auth/verify/account/',
  USER_PROFILE: 'users/profile',
  LOGOUT: '/logout',
  GOOGLE_LOGIN: '/auth/google',
  FACEBOOK_LOGIN: '/auth/facebook',
  VERIFY_EMAIL: '/auth/resend/verificationLink',
  NEMID_LOGIN: '/auth/oidc',
  CREATE_TASK: '/tasks/create-update-repost',
  MY_TASK: '/tasks/my-tasks',
  SINGLE_TASK: '/tasks/my-tasks/',
  SINGLE_BID: '/tasks/bid/details/',
  CANCEL_TASK: 'tasks/cancel',
  COMPLETE_TASK: 'tasks/complete',
  REPORT_TASK: 'tasks/report',
  REVIEW_TASK: '/tasks/review',
  FIND_TASK: '/tasks/find',
  INVITE_TASK_TO_SPECIALIST: '/tasks/invite',
  TASK_ACCEPT_REJECT: '/tasks/cancellation/accept-reject',
  GET_CATEGORIES: 'users/view/categories',
  ADD_CATEGORY: '/add-category', // : userid
  ADD_SUBCATEGORY: '/add-sub-category',
  ADD_TYPEOF_SERVICE: '/add-type-of-service',
  ADD_SERVICES: '/albums/add-services', // : service id
  GET_ALBUMS: '/albums/list', // :userid
  CREATE_ALBUM: '/albums/create',
  ADD_ALBUM_MEDIA: '/albums/media',
  DELETE_ALBUM: '/albums/delete', // albumid
  GET_ALBUM_DETAILS: '/albums', // albumid
  CHANGE_ALBUM_VISIBILITY: '/visibility', // /:albumid
  PLACE_BID: 'tasks/bid/create-update',
  ADD_NEW_CARD: '/users/stripe/create/card',
  LIST_CARD: '/users/stripe/view/cards',
  DELETE_CARD: '/users/stripe/remove/card',
  DEFAULT_CARD: '/users/stripe/default/card',
  GET_PAYMENT_HISTORY: 'users/payment/history',
  FIND_SPECIALIST: 'users/specialists',
  ADD_TYPE_OF_SERVICE: '/add-type-of-service',
  UPDATE_PROFILE: '/users/profile/settings',
  GET_INVITED: 'tasks/invited',
  ACCEPT_REJECT: 'tasks/bid/accept-reject',
  REPORT_PROFILE: 'users/report/profile',
  TRANSFER_PAYMENT: 'users/stripe/transfer/payment',
  //admin
  VIEW_DASHBOARD: 'admin/view/dashboard',
  VIEW_TASK: '/admin/view/tasks',
  VIEW_TASK_DETAIL: '/admin/view/task',
  TASK_PROOFS: '/admin/view/task/proofs',
  GET_CATEGORY: '/users/view/categories',
  VIEW_REPORTED_TASK: '/admin/view/tasks',
  VIEW_PROOF: '/admin/view/task/proofs',
  VIEW_PLACE_BID: '/tasks/bid/details',

  GET_CONFIDENCE_LEVEL: '/tools_configuration/confidence_level',
  VIEW_USER: '/admin/view/users',
  VIEW_USER_DETAIL: 'users/profile',
  SUSPEND_USER: 'admin/suspend/user',
  VIEW_USER_PROFILE: 'admin/my-account',
  SAVE_USER_PROFILE: 'admin/my-account',

  VIEW_ATTRIBUTE: 'admin/view/attributes',
  VIEW_ATTRIBUTE_DETAIL: 'admin/view/category',
  ADD_ATTRIBUTE: 'admin/add/category',
  EDIT_ATTRIBUTE: 'admin/edit/category',
  VIEW_TRANSACTION: 'admin/view/transactions',
  VIEW_TRANSACTION_DETAIL: '',
  VIEW_REPORTED_PROFILE: 'admin/view/users',

  VIEW_NOTIFICATION: 'users/view/notifications',
  MARK_NOTIFICATION: 'users/update/notification',
  CHAT_HISTORY_LISTING: 'chat/list',
  CHAT_HISTORY: 'chat/history',
  CHAT_READ: 'chat/mark/read',
  MEDIA_HISTORY: 'chat/view/attachments',
  DELETE_CHAT: 'chat/move/trash',
  CHAT_HISTORY_LISTING_ADMIN: 'admin/chat/list',
  GET_NOTIFICATION: 'users/view/notifications',
  READ_NOTIFICATION: 'users/update/notification',
  CHECKOUT_BID_DETAIL: 'tasks/checkout/details',
  LIST_STRIPE_CARD: 'users/stripe/check/connect',
  ADD_NEW_CARD_STRIPE: 'users/stripe/create/connect',
  REFRESH_STRIPE_CARD: 'users/stripe/refresh/connect-link',
  DISPUTE_SETTLE_API: 'admin/transactions/dispute',
  VERIFY_OTP: '/master/verify_otp',
  RESEND_OTP: '/master/resend_otp',
  COMPANY_INFO: '/master/company_details',

  COUNTRY_LIST: '/master/country',
  COUNTRY_BY_ID: '/master/country',
  STATES_BY_ID: '/master/states',
  STATES_LIST: '/auth/states',
  COUNTRY_DROPDOWN: '/auth/country',
  COUNTRY_DATA_BY_ID: '/auth/country',
};

// SECTION - ADMIN API URLS
export const adminApiUrls = {
  LOGIN: 'super-admin/login',
  FORGOT_PASSWORD: 'super-admin/forgot_password',
  RESET_PASSWORD: 'super-admin/resetPassword',
  ACTIVE_DASHBOARD_DATA: 'super-admin-dashboard/dashboard_active_data',
  SCORECARD_REPORT: 'super-admin-dashboard/scorecard_report',
  SUBSCRIPTION_REPORT: 'super-admin-dashboard/subscription_report',
  INDUSTRY_REPORT: 'super-admin-dashboard/industry_report',
  DEFAULT_ROLE_PERMISSIONS: 'super-admin-workspace-users/default_roles_and_permissions',
  UPDATE_DEFAULT_ROLE_PERMISSIONS: 'super-admin-workspace-users/update_default_roles_and_permissions',
  WORKSPACE_USER_LIST: 'super-admin-workspace-users/user_list',
  WORKSPACE_USER_DETAIL: 'super-admin-workspace-users/user_information',
  WORKSPACE_LIST: 'super-admin-workspace/workspaces',
  WORKSPACE_DETAIL: 'super-admin-workspace/workspaces_details',
  WORKSPACE_SUBSCRIPTION_DETAIL: 'super-admin-workspace/subscription_details',
  WORKSPACE_VMSB_OKR_DETAIL: 'super-admin-workspace/vmsb_okr_details',
  WORKSPACE_DETAIL_USER_LIST: 'super-admin-workspace/user_details',
  WORKSPACE_SUBSCRIPTION_HISTORY: 'super-admin-workspace/subscription_history',
  WORKSPACE_ACTIVE_LOG: 'super-admin-workspace/active_log',
  WORKSPACE_TRANSACTIONS: 'super-admin-workspace/transaction_history',
  WORKSPACE_CHANGE_USER_ROLE: 'super-admin-workspace/users_role',
  EDIT_SUBSCRIPTION_DETAILS: 'super-admin-workspace/subscription_details',
  TOOLTIP: 'tooltip/tooltip_file',
  TOOLTIP_FILES: 'tooltip/tooltip_files',
  TOOLTIP_FILE: 'tooltip/tooltip',
  ADD_PROMO_CODE: 'promocode/promocode_add',
  GENERATE_PROMO_CODE: 'promocode/promocode_generate',
  PROMO_CODE_LIST: 'promocode/promocode_view',
  PROMO_CODE_DETAIL: 'promocode/promocode_details',
  PROMO_CODE_DETAILS_LIST: 'promocode/promocode_details_lists',
  UPDATE_PROMO_CODE: 'promocode/promocode_update',
  DELETE_PROMO_CODE: 'promocode/promocode_delete',
  GET_DEFAULT_CONFIG: 'tooltip/org_default_config_view',
  EDIT_DEFAULT_CONFIG: 'tooltip/org_default_config',
  SUBSCRIPTION_PLANS: 'super-admin/view_plan',
  SUBS_PLAN_STATUS_CHANGE: 'super-admin/change_status',
  EDIT_SUBSCRIPTION_PLAN: 'super-admin/add_price',
  SUBSCRIPTION_HISTORY: 'subscription-history/subscription_history',

  //filters
  COMPANY_INDUSTRY: 'super-admin-filter/get_company_industry',
  WORKSPACES: 'super-admin-filter/get_workspaces',
  ROLES: 'super-admin-filter/get_roles',
};

// Axios NoAuth Instance - Response
ServiceInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    const { data } = error?.response;
    if (data && data.message) {
    }
    return Promise.reject(data.message);
  }
);

ServiceInstance.interceptors.request.use((config) => {
  const host = window.location.host;
  let arr: string[] = [];
  arr = host.split('.').slice(0, host.includes('localhost') ? -1 : -2); //eslint-disable-line
  config.headers['subdomain'] = getSubDomain();
  return config;
});
const pathSegments = window.location.pathname.split('/');
const sessionidIndex = pathSegments.indexOf('verified') + 1;
const authorization = pathSegments[sessionidIndex + 1];
const tokenFromUrl = pathSegments[1] === 'verified' ? authorization : undefined;

ServiceAuthInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('id_token') || sessionStorage.getItem('id_token') || tokenFromUrl;
  const selectedlanguage = localStorage.getItem('selectedlanguage') || localStorage.getItem('selectedlanguage');
  config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';
  config.headers['Accept-Language'] = selectedlanguage ? JSON.parse(selectedlanguage).locale : 'en';
  config.headers['subdomain'] = getSubDomain();

  return config;
});

ServiceAdminAuthInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('admin_accessToken');
  const selectedlanguage = localStorage.getItem('selectedlanguage') || localStorage.getItem('selectedlanguage');
  config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';
  config.headers['Accept-Language'] = selectedlanguage ? JSON.parse(selectedlanguage).locale : 'en';
  // config.headers["subdomain"] = getSubDomain();

  return config;
});

// TODO: Will be removed in future
function _getRefreshToken() {
  const refreshToken = localStorage.getItem('refresh_token') || localStorage.getItem('refresh_token');
  return refreshToken;
}

// TODO: Will be removed in future
function _setToken(tokenObj) {
  if (localStorage.getItem('remember_me')) {
    localStorage.clear();
    localStorage.setItem('id_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  } else {
    localStorage.clear();
    localStorage.setItem('id_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  }
}

/* Axios refresh token logic - will be used auth instance of axios */

ServiceAuthInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Reject promise if usual error
    if (!localStorage.getItem('id_token')) {
      if (!sessionStorage.getItem('id_token')) {
        localStorage.clear();
        history.push('/');
        history.go(0);
        return Promise.reject(error);
      }
    }

    if (error.response.status !== 401) {
      return Promise.reject(error.response);
    }

    // return Promise.reject(error.response);
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    ServiceAuthInstance.interceptors.response.eject(ServiceAuthInstance);

    return ServiceInstance.post(`/refreshtoken`, {
      refresh_token: _getRefreshToken()
    })
      .then((response) => {
        _setToken(response.data);
        error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
        return axios(error.response.config);
      })
      .catch((error) => {
        localStorage.clear();
        history.push('/');
        history.go(0);
        return Promise.reject(error);
      });
  }
);
