import { ServiceAuthInstance } from "./index";

export const postStrategyReq = (payload: Record<string, string | number>) => {
  return ServiceAuthInstance({
    method: "POST",
    url: "/strategy/create_strategy",
    data: {
      ...payload,
    },
  });
};

export const getStrategyReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `strategy/view_strategy/${payload}`,
  });
};

export const getMissionListReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/strategy/get_missionlist`,
    params: payload
  });
};

export const getStrategyBenefitReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/strategy/strategy_benefit/${payload.id}?page=${payload.page}&take=${payload.take}`,
  });
};

export const getVisionOnMissionReq = (payload) => {
  return ServiceAuthInstance({
    type: "GET",
    url: `/strategy/vision_strategy/${payload}`,
  });
}

export const getMissionStrategyReq = () => {
  return ServiceAuthInstance({
    type: "GET",
    url: `/mission/get_vision`,
  });
};

export const deleteStrayegyReq = (payload) => {
  return ServiceAuthInstance({
    method: "DELETE",
    url: `/strategy/delete_strategy/${payload}`,
  });
};

export const editStrategyReq = (payload) => {
  return ServiceAuthInstance({
    method: "PATCH",
    url: `/strategy/edit_strategy`,
    data: {
      ...payload,
    },
  });
};
