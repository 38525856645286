import { ServiceAuthInstance } from "./index";

export const getExecutiveSignupStep2Req = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/master/portal_url_check/${payload.company_slug}`,
    data: {
      ...payload,
    },
  });
};

export const createCompanyReq = (payload) => {
  return ServiceAuthInstance({
    method: "POST",
    url: `/master/create_company`,
    data: {
      ...payload,
    },
  });
};

export const getPortalSlugAvailableReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/master/check_portal_url/${payload?.company_slug}`,
    data: {
      ...payload,
    },
  });
};

export const postExecutiveSignupStep2Req = (
  payload: Record<string, string | number>
) => {
  const payloads = payload?.payload;
  let formData: any = new FormData();
  Object.keys(payloads).forEach((key) =>
    payloads[key] === undefined ? delete payloads[key] : {}
  );

  Object.keys(payloads).map((key) => formData.append(key + "", payloads[key]));

  return ServiceAuthInstance({
    method: "PATCH",
    url: "/master/sign_up_second_step",
    data: formData,
  });
};

export const getCompanyIndrustryReq = () => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/master/get_company_industry`,
  });
};

export const getCompanyPortalStatusReq = (payload) => {
  return ServiceAuthInstance({
    method: "GET",
    url: `/master/organization_setup/${payload?.company_slug}`,
  });
};
