/** @format */
import { ReactComponent as ConfigurationIcon } from '../../../assets/images/setting.svg';
import { ReactComponent as WorkspaceIcon } from '../../../assets/images/workspace.svg';
import { ReactComponent as UserRoleIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/people.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/images/dashboard.svg';
import { ReactComponent as PromoIcon } from '../../../assets/images/promocode.svg';
import { ReactComponent as TooltipIcon } from '../../../assets/images/tooltip.svg';
import { ReactComponent as SubscriptionIcon } from '../../../assets/images/Subscription.svg';
import { ReactComponent as PlansIcon } from '../../../assets/images/subscriptionPlans.svg';
import { ReactComponent as HistoryIcon } from '../../../assets/images/subscriptionHistory.svg';

type OptionsType = {
  key: string;
  label: string;
  leftIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  className?: string;
  badge: boolean;
  disabled: boolean;
  children?: {
    key: string;
    label: string;
    leftIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  }[];
}[];

const AdminSidebarOptions: OptionsType = [
  {
    key: 'home',
    label: 'Dashboard',
    leftIcon: DashboardIcon,
    badge: false,
    disabled: false,
  },
  {
    key: 'user-roles',
    label: 'User roles',
    leftIcon: UserRoleIcon,
    badge: false,
    disabled: false,
  },
  {
    key: 'workspaceUsers',
    label: 'Workspace users',
    leftIcon: UsersIcon,
    badge: false,
    disabled: false
  },
  {
    key: 'workspace',
    label: 'Workspace',
    leftIcon: WorkspaceIcon,
    badge: false,
    disabled: false
  },
  {
    key: 'promocodes',
    label: 'Promocode',
    leftIcon: PromoIcon,
    badge: false,
    disabled: false
  },
  {
    key: 'subscription',
    label: 'Subscription',
    leftIcon: SubscriptionIcon,
    badge: false,
    disabled: false,
    children: [
      {
        key: 'subscription-plans',
        label: 'Plans',
        leftIcon: PlansIcon
      },
      {
        key: 'subscription-history',
        label: 'History',
        leftIcon: HistoryIcon
      }
    ]
  },
  {
    key: 'tooltip',
    label: 'Tooltip',
    leftIcon: TooltipIcon,
    badge: false,
    disabled: false
  },
  {
    key: 'configuration',
    label: 'Configuration',
    leftIcon: ConfigurationIcon,
    badge: false,
    disabled: false
  }
];
export default AdminSidebarOptions;
