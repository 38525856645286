import { LOGOUT } from "./actionTypes";
import { getManagerPayload, Logout } from "./types";

export const logout = (): Logout => ({
  type: LOGOUT,
});

const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  AUTO_LOGOUT: "AUTO_LOGOUT",

  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

  SIGNUP_STEP1: "SIGNUP_STEP1",
  SIGNUP_STEP1_SUCCESS: "SIGNUP_STEP1_SUCCESS",
  SIGNUP_STEP1_FAILED: "SIGNUP_STEP1_FAILED",

  SIGNUP_STEP2: "SIGNUP_STEP2",
  SIGNUP_STEP2_SUCCESS: "SIGNUP_STEP2_SUCCESS",
  SIGNUP_STEP2_FAILED: "SIGNUP_STEP2_FAILED",

  SIGNUP_ADMIN_STEP1: "SIGNUP_ADMIN_STEP1",
  SIGNUP_ADMIN_STEP1_SUCCESS: "SIGNUP_ADMIN_STEP1_SUCCESS",
  SIGNUP_ADMIN_STEP2_FAILED: "SIGNUP_ADMIN_STEP2_FAILED",

  OAUTH_VERIFY: "OAUTH_VERIFY",
  OAUTH_VERIFY_FAILED: "OAUTH_VERIFY_FAILED",
  GET_MANAGER_LIST: "GET_MANAGER_LIST",
  GET_MANAGER_LIST_SUCCESS: "GET_MANAGER_LIST_SUCCESS",
  GET_MANAGER_LIST_FAILED: "GET_MANAGER_LIST_FAILED",

  GET_DEPARTMENT_REQUEST: "GET_DEPARTMENT_REQUEST",
  GET_DEPARTMENT_SUCCESS: "GET_DEPARTMENT_SUCCESS",
  GET_DEPARTMENT_FAILED: "GET_DEPARTMENT_FAILED",

  DEPARTMENT_ADD: "DEPARTMENT_ADD",
  DEPARTMENT_ADD_SUCCESS: "DEPARTMENT_ADD_SUCCESS",
  DEPARTMENT_ADD_FAILED: "DEPARTMENT_ADD_FAILED",

  PATCH_MANAGER_INSERT: "PATCH_MANAGER_INSERT",
  PATCH_MANAGER_INSERT_SUCCESS: "PATCH_MANAGER_INSERT_SUCCESS",
  PATCH_MANAGER_INSERT_FAILED: "PATCH_MANAGER_INSERT_FAILED",

  CHECK_PORTAL_URL_ROOT: "CHECK_PORTAL_URL_ROOT",
  CHECK_PORTAL_URL_ROOT_SUCCESS: "CHECK_PORTAL_URL_ROOT_SUCCESS",
  PORTAL_URL_LOADING_SET: "PORTAL_URL_LOADING_SET",

  CHECK_VALID_TOKEN: "CHECK_VALID_TOKEN",
  CHECK_VALID_TOKEN_SUCCESS: "CHECK_VALID_TOKEN_SUCCESS",
  CHECK_VALID_TOKEN_FAILED: "CHECK_VALID_TOKEN_FAILED",

  CHECK_USER_LIMIT: "CHECK_USER_LIMIT",
  CHECK_USER_LIMIT_SUCCESS: "CHECK_USER_LIMIT_SUCCESS",
  CHECK_USER_LIMIT_FAILED: "CHECK_USER_LIMIT_FAILED",

  POST_VERIFY_OTP: "POST_VERIFY_OTP",
  POST_VERIFY_OTP_SUCCESS: "POST_VERIFY_OTP_SUCCESS",
  POST_VERIFY_OTP_FAILED: "POST_VERIFY_OTP_FAILED",

  PATCH_RESEND_OTP: "PATCH_RESEND_OTP",
  PATCH_RESEND_OTP_SUCCESS: "PATCH_RESEND_OTP_SUCCESS",
  PATCH_RESEND_OTP_FAILED: "PATCH_RESEND_OTP_FAILED",

  POST_COMPANY_INFO: "POST_COMPANY_INFO",
  POST_COMPANY_INFO_SUCCESS: "POST_COMPANY_INFO_SUCCESS",
  POST_COMPANY_INFO_FAILED: "POST_COMPANY_INFO_FAILED",

  AUTH_MANUAL_SIGNUP: "AUTH_MANUAL_SIGNUP",
  AUTH_MANUAL_SIGNUP_SUCCESS: "AUTH_MANUAL_SIGNUP_SUCCESS",
  AUTH_MANUAL_SIGNUP_FAILED: "AUTH_MANUAL_SIGNUP_FAILED",

  CLEAR_ERROR: "CLEAR_ERROR",

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  // login: (token = false) => ({
  //   type: actions.LOGIN_REQUEST,
  //   payload: { token },
  // }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  autoLogout: () => ({
    type: actions.AUTO_LOGOUT,
  }),
  getManager: (payload?: getManagerPayload) => ({
    type: actions.GET_MANAGER_LIST,
    payload: payload,
  }),
  getDepartment: () => ({
    type: actions.GET_DEPARTMENT_REQUEST,
  }),
  departmentAdd: (payload) => ({
    type: actions.DEPARTMENT_ADD,
    payload: payload,
  }),
  forgotPassword: (
    payload: Record<string, string>,
    history?: Record<string, any>
  ) => ({
    type: actions.FORGOT_PASSWORD,
    payload: payload,
    history: history,
  }),
  resetPassword: (
    payload: Record<string, string | number>,
    history?: Record<string, any>
  ) => ({
    type: actions.RESET_PASSWORD,
    payload: payload,
    history: history,
  }),
  signupStep1: (payload: Record<string, string>) => ({
    type: actions.SIGNUP_STEP1,
    payload: payload,
  }),
  signupAdminstep1: (payload: any) => ({
    type: actions.SIGNUP_ADMIN_STEP1,
    payload: payload,
  }),
  patchManagerInsert: (payload) => ({
    type: actions.PATCH_MANAGER_INSERT,
    payload: payload,
  }),
  clearError: (payload) => ({
    type: actions.CLEAR_ERROR,
    payload: payload,
  }),
  oAuthVerify: (payload) => ({
    type: actions.OAUTH_VERIFY,
    payload,
  }),
  authManualSignup: (payload) => ({
    type: actions.AUTH_MANUAL_SIGNUP,
    payload,
  }),
  checkPortalURLinLoad: (payload) => ({
    type: actions.CHECK_PORTAL_URL_ROOT,
    payload,
  }),
  setPortalURLloading: () => ({
    type: actions.PORTAL_URL_LOADING_SET,
  }),
  checkValidToken: (payload) => ({
    type: actions.CHECK_VALID_TOKEN,
    payload,
  }),
  checkUserLimit: () => ({
    type: actions.CHECK_USER_LIMIT,
  }),
  verifyOtp: (payload) => ({
    type: actions.POST_VERIFY_OTP,
    payload,
  }),
  resendOtp: (payload) => ({
    type: actions.PATCH_RESEND_OTP,
    payload,
  }),
  companyInfo: (payload) => ({
    type: actions.POST_COMPANY_INFO,
    payload,
  }),
};

export default actions;
