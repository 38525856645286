import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { holdActions } from "./holdActions";
import { keyResultTypes } from "../KeyResult/keyResultTypes";
import authActions from "../app/action";
import { postHoldMSBPayload, PostHoldsOKRPayload } from "./types";
import {
  postHoldsMSBReq,
  postHoldsOKRReq,
} from "../../library/services/HoldsReq";
import { Objectivetypes } from "../Objective/actionTypes";
import { GoalActionTypes } from "../Goals/actionTypes";
import { vmsbCommonTypes } from "../vmsbCommon/vmsbCommonTypes";
import { missionTypes } from "../mission/missionTypes";
import { strategyTypes } from "../strategy/strategyTypes";
import { benefitTypes } from "../benefit/benefitTypes";
export function* postHoldsOKREffect() {
  yield takeEvery(holdActions.POST_HOLDSOKR, function* ({
    payload,
  }: {
    type: string;
    isIncrement?: boolean;
    payload: PostHoldsOKRPayload;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const myGols = payload?.myGols;
      const tabKey = payload?.tabkey;
      delete payload?.myGols;
      delete payload?.tabkey;

      const response = yield call(postHoldsOKRReq, payload);
      if (response) {
        yield all([
          put({
            type: holdActions.POST_HOLDSOKR_SUCCESS,
            payload: response,
          }),
        ]);

        if (myGols) {
          yield put({
            type:
              tabKey === "1"
                ? GoalActionTypes.POST_MYGOALS
                : GoalActionTypes.POST_MY_ORG_CHART,
            payload: myGols,
          });
        }

        if (!myGols) {
          yield put({
            type: holdActions.SCROLL_TO_TOP,
          });
        }

        if (payload.category === 0) {
          yield put({
            type: Objectivetypes.GET_OBJECTIVE,
            payload: { id: payload.id },
          });
        } else if (payload.category === 1) {
          yield put({
            type: keyResultTypes.GET_KEYRESULT,
            payload: { id: payload.id },
          });
        }
      }
    } catch (error: any) {
      yield all([
        put({
          type: holdActions.POST_HOLDSOKR_FAILED,
          errorMessage: error?.data?.message,
        }),
        put({
          type: holdActions.SCROLL_TO_TOP,
        }),
      ]);
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}

export function* postHoldMSBEffect() {
  yield takeEvery(holdActions.POST_HOLD_MSB, function* ({
    payload,
  }: {
    type: string;
    isIncrement: boolean;
    payload: postHoldMSBPayload;
  }) {
    try {
      yield put(authActions.globalLoaderHandler(true));
      const my_MSB = payload?.my_MSB;
      delete payload?.my_MSB;
      const response: any = yield call(postHoldsMSBReq, payload);
      if (response.status) {
        yield put({
          type: holdActions.POST_HOLD_MSB_SUCCESS,
          payload: response,
        });
      }
      if (my_MSB) {
        yield put({
          type: vmsbCommonTypes.GET_MSB_LIST,
          payload: my_MSB,
        });
      }

      if (!my_MSB) {
        yield put({
          type: holdActions.SCROLL_TO_TOP,
        });
      }

      if (payload.category === 3) {
        yield put({
          type: missionTypes?.GET_MISSION,
          payload: { id: payload.id },
        });
      } else if (payload.category === 4) {
        yield put({
          type: strategyTypes?.GET_STRATEGY,
          payload: payload.id ,
        });
      } else if (payload.category === 5) {
        yield put({
          type: benefitTypes?.GET_BENEFIT,
          payload: payload,
        });
      }
    } catch (error: any) {
      yield put({
        type: holdActions.POST_HOLD_MSB_FAILED,
        errorMessage: error?.data?.message,
      });
      yield put({
        type: holdActions.SCROLL_TO_TOP,
      });
    }
    yield put(authActions.globalLoaderHandler(false));
  });
}
export default function* rootSaga() {
  yield all([fork(postHoldsOKREffect), fork(postHoldMSBEffect)]);
}
