import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { visionTypes } from "./visionTypes";
import { holdActions } from "../Holds/holdActions";
import history from "../../library/helpers/history";
import authActions from "../app/action";
import {
  addVisionReq,
  getMissionInVisionReq,
  getVisionReq,
  patchVisionReq,
} from "../../library/services/visionReq";
import { VmsbOkrCommonTypes } from "../VmsbOkrCommon/VmsbOkrCommonTypes";

export function* addVisionEffect() {
  yield takeEvery(
    visionTypes.POST_VISION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, any>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(addVisionReq, payload);
        if (response.status) {
          yield all([
            put({
              type: visionTypes.POST_VISION_SUCCESS,
              successmessage: response?.data?.data?.message,
            }),
            put({
              type: VmsbOkrCommonTypes.GET_CAN_ADD_VISION
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
          history.push(`viewvision/${response?.data?.data?.visionId}`);
        } else {
          yield all([
            put({
              type: visionTypes.POST_VISION_FAILED,
              errorMessage: response?.data?.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: visionTypes.POST_VISION_FAILED,
            errorMessage: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getVisionEffect() {
  yield takeEvery(
    visionTypes.GET_VISION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getVisionReq, payload);

        if (response.status) {
          yield put({
            type: visionTypes.GET_VISION_SUCCESS,
            visionDetails: response?.data?.data,
            // vision: response.data.data.vision,
          });
        } else {
          yield put({
            type: visionTypes.GET_VISION_FAILED,
            errorMessage: response.data.vision.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: visionTypes.GET_VISION_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* editVisionEffect() {
  yield takeEvery(
    visionTypes.PATCH_VISION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, any>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(patchVisionReq, payload);
        const id = payload?.id;
        if (response.status) {
          yield all([
            put({
              type: visionTypes.PATCH_VISION_SUCCESS,
              successmessage: response?.data?.data?.message,
            }),
            put({
              type: visionTypes.GET_VISION,
              payload: { id: id },
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        } else {
          yield all([
            put({
              type: visionTypes.PATCH_VISION_FAILED,
              errorMessage: response?.data?.message,
            }),
            put({
              type: holdActions.SCROLL_TO_TOP,
            }),
          ]);
        }
      } catch (error: any) {
        yield all([
          put({
            type: visionTypes.PATCH_VISION_FAILED,
            errorMessage: error.data.message,
          }),
          put({
            type: holdActions.SCROLL_TO_TOP,
          }),
        ]);
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}

export function* getMissionInVisionEffect() {
  yield takeEvery(
    visionTypes.GET_MISSION_IN_VISION,
    function* ({
      payload,
    }: {
      type: string;
      isIncrement: boolean;
      payload: Record<string, string | number>;
    }) {
      try {
        yield put(authActions.globalLoaderHandler(true));
        const response = yield call(getMissionInVisionReq, payload);

        if (response.status) {
          yield put({
            type: visionTypes.GET_MISSION_IN_VISION_SUCCESS,
            mission: response?.data?.data,
          });
        } else {
          yield put({
            type: visionTypes.GET_MISSION_IN_VISION_FAILED,
            errorMessage: response.data.vision.message,
          });
        }
      } catch (error: any) {
        yield put({
          type: visionTypes.GET_MISSION_IN_VISION_FAILED,
          errorMessage: error.data.message,
        });
      }
      yield put(authActions.globalLoaderHandler(false));
    }
  );
}



export default function* rootSaga() {
  yield all([
    fork(addVisionEffect),
    fork(getVisionEffect),
    fork(getMissionInVisionEffect),
    fork(editVisionEffect),
  ]);
}
